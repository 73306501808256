import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { PlusIcon } from 'ui/icons';

interface UploadButtonProps {
  onClick: () => void;
  title?: string;
  className?: string;
}

const UploadButton: FC<UploadButtonProps> = ({ onClick, title, className }) => {
  return (
    <Wrapper type="button" onClick={onClick} className={className}>
      <Icon />
      {title || 'Upload'}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  transition: 0.3s ease-in;

  &:hover {
    opacity: 0.7;
  }
`;

const Icon = styled(PlusIcon)`
  transform: rotate(90deg);
  margin-right: ${getSize(9)};
  stroke: var(--black3);
  width: ${getSize(16)};
  height: ${getSize(16)};
`;

export default UploadButton;
