import {
  FULL_DATE,
  FULL_DATE_WITH_SHORT_MONTH,
  ISO_DATE_FORMAT,
} from 'lib/constants/date';
import { getSize, TimeUtil } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';

interface SessionStatisticProps {
  isGoalsEmpty?: boolean;
  isSmallScreen?: boolean;
  attendedSessionsCount?: number;
  firstSessionDateUtc?: string;
}

const SessionStatistic: FC<SessionStatisticProps> = ({
  isGoalsEmpty,
  attendedSessionsCount,
  firstSessionDateUtc,
  isSmallScreen,
}) => {
  const firstSessionDateFormat = isSmallScreen
    ? FULL_DATE_WITH_SHORT_MONTH
    : FULL_DATE;
  const firstSessionDate = firstSessionDateUtc
    ? TimeUtil.parse(firstSessionDateUtc, ISO_DATE_FORMAT).format(
        firstSessionDateFormat,
      )
    : 'No session';

  return (
    <Wrapper $isGoalsEmpty={isGoalsEmpty} $isSmallScreen={isSmallScreen}>
      <div>
        <Value>{attendedSessionsCount || 0}</Value>
        <Subtitle>Attended sessions</Subtitle>
      </div>
      <div>
        <Value>{firstSessionDate}</Value>
        <Subtitle>1st session</Subtitle>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.dl<{
  $isGoalsEmpty?: boolean;
  $isSmallScreen?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${getSize(35)};
  margin: 0 0 ${getSize(26)};
  padding: 0 0 ${getSize(24)};
  border-bottom: ${getSize(1)} solid var(--purple3);

  ${({ $isSmallScreen }) =>
    !$isSmallScreen &&
    `
       grid-template-columns: 1fr;
       grid-gap: ${getSize(16)};
       margin: 0;
       border: 0;
       padding: 0;
    `}
`;

const Value = styled.dd`
  margin: 0 0 ${getSize(5)};
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};
`;

const Subtitle = styled.dt`
  font-weight: 400;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: var(--gray2);
`;

export default SessionStatistic;
