import { getSize } from 'lib/utils';
import React from 'react';
import { NavbarElementProps } from 'react-day-picker';
import styled from 'styled-components';

import { ArrowDownIcon } from 'ui/icons';

function Navbar({
  onPreviousClick,
  onNextClick,
  localeUtils,
  month,
}: NavbarElementProps) {
  const date = localeUtils.formatMonthTitle(month);
  return (
    <Root>
      <Button type="button" onClick={() => onPreviousClick()}>
        <LeftIcon />
      </Button>
      <DateDisplay>{date}</DateDisplay>
      <Button type="button" onClick={() => onNextClick()}>
        <RightIcon />
      </Button>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 ${getSize(25)} ${getSize(30)};
`;

const Button = styled.button`
  &:hover {
    svg {
      stroke: var(--purple);
    }
  }
`;

const Icon = styled(ArrowDownIcon)`
  stroke: var(--gray2);
  transition: 0.3s ease-out;
`;

const LeftIcon = styled(Icon)`
  transform: rotate(90deg);
`;

const RightIcon = styled(Icon)`
  transform: rotate(-90deg);
`;

const DateDisplay = styled.p`
  flex-grow: 1;
  text-align: center;
  font-weight: 600;
  font-size: ${getSize(20)};
  line-height: ${getSize(32)};
  color: var(--black3);
`;

export default Navbar;
