import React from 'react';

function MasterCardIcon({ ...rest }) {
  return (
    <svg width={51} height={35} viewBox="0 0 51 35" fill="none" {...rest}>
      <rect
        x={0.5}
        y={0.5}
        width={50}
        height={34}
        rx={3.5}
        fill="#fff"
        stroke="#F3F3F3"
      />
      <path d="M30.17 25.355h-9.103V9.198h9.102v16.157z" fill="#ED6C6C" />
      <path
        d="M21.65 17.275c0-3.277 1.554-6.197 3.974-8.078A10.44 10.44 0 0019.194 7C13.45 7 8.794 11.6 8.794 17.275c0 5.675 4.657 10.275 10.402 10.275 2.426 0 4.66-.821 6.428-2.197-2.419-1.881-3.973-4.8-3.973-8.078z"
        fill="#ED6C6C"
      />
      <path
        d="M42.445 17.275c0 5.675-4.657 10.275-10.402 10.275-2.427 0-4.66-.821-6.43-2.197 2.42-1.881 3.974-4.8 3.974-8.078 0-3.277-1.553-6.197-3.973-8.078A10.443 10.443 0 0132.044 7c5.744 0 10.4 4.6 10.4 10.275z"
        fill="#F79E1B"
      />
    </svg>
  );
}

export default MasterCardIcon;
