import React, { FC, useMemo } from 'react';
import styled, { CSSProp } from 'styled-components';
import { PopUpMessage } from 'ui/pop-up-message';
import { getSize } from 'lib/utils';

const POPUP_CSS: CSSProp = { whiteSpace: 'nowrap' };

interface Item {
  id: string;
  label?: string;
  explanation?: string | null;
}

interface TextBlockListProps {
  title: string;
  itemsList?: Item[];
  wrapperCSS?: CSSProp;
  listWrapperCSS?: CSSProp;
  itemCSS?: CSSProp;
  titleCSS?: CSSProp;
  isShortInfo?: boolean;
  onMoreClick?: () => void;
}

const TextBlockList: FC<TextBlockListProps> = ({
  title,
  itemsList = [],
  isShortInfo,
  wrapperCSS,
  listWrapperCSS,
  itemCSS,
  titleCSS,
  onMoreClick,
}) => {
  const items = useMemo(
    () =>
      isShortInfo && itemsList.length > 3 ? itemsList.slice(0, 3) : itemsList,
    [isShortInfo, itemsList],
  );

  return (
    <Wrapper CSS={wrapperCSS}>
      <Head>
        <Title CSS={titleCSS}>{title}</Title>
        {onMoreClick && itemsList.length > 3 && (
          <MoreButton onClick={onMoreClick}>More</MoreButton>
        )}
      </Head>
      <TextListWrapper CSS={listWrapperCSS}>
        {items?.map((item) => (
          <TextItem key={item.id} CSS={itemCSS}>
            {item?.label}
            {item?.explanation && (
              <PopUpMessage rootCSS={POPUP_CSS}>
                {item.explanation}
              </PopUpMessage>
            )}
          </TextItem>
        ))}
      </TextListWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ CSS?: CSSProp }>`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: ${getSize(20)};
  }

  ${({ CSS }) => CSS}
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${getSize(2)};
`;

const Title = styled.h3<{ CSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  margin-right: ${getSize(12)};

  ${({ CSS }) => CSS}
`;

const MoreButton = styled.button`
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--purple);
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.7;
  }
`;

const TextListWrapper = styled.ul<{ CSS?: CSSProp }>`
  display: flex;
  flex-wrap: wrap;

  ${({ CSS }) => CSS}
`;

const TextItem = styled.li<{ CSS?: CSSProp }>`
  position: relative;
  padding: ${getSize(9)} ${getSize(16)};
  margin: ${getSize(12)} ${getSize(12)} 0 0;
  border-radius: 10px;
  border: none;
  cursor: default;
  text-decoration: none;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  background-color: var(--purple11);
  &:hover > p {
    display: block;
    animation: showTooltip 0.3s ease-out forwards;
  }
  ${({ CSS }) => CSS}
`;

export default TextBlockList;
