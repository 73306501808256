import React from 'react';

function SendPlaneIcon({ ...rest }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2853 4.93009L1.60344 0.912668C1.19556 0.743402 0.734752 0.817863 0.400885 1.10689C0.0670177 1.39597 -0.0726229 1.84138 0.0365021 2.26928L0.898276 5.64878H5.11763C5.31179 5.64878 5.46922 5.80618 5.46922 6.00036C5.46922 6.19452 5.31181 6.35195 5.11763 6.35195H0.898276L0.0365021 9.73143C-0.0726229 10.1593 0.0669942 10.6048 0.400885 10.8938C0.735432 11.1834 1.19628 11.257 1.60346 11.088L11.2854 7.07064C11.7262 6.88773 12 6.47762 12 6.00036C12 5.52311 11.7262 5.11297 11.2853 4.93009Z"
        fill="white"
      />
    </svg>
  );
}

export default SendPlaneIcon;
