import React, { FC, useMemo } from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { Material } from '../materials-section/components/material-item/material-item';
import { MaterialsSection } from '../materials-section';
import { ShadowWrapper, UploadButton, Link } from 'ui';
import {
  MaterialIndicatorsModel,
  MaterialTag,
} from '../../../__generated__/types';

export interface MaterialsSmallSectionProps {
  showAllLink: string;
  className?: string;
  materials: Material[];
  isTherapist?: boolean;
  isIndividualScreen?: boolean;
  isVisibleModal?: boolean;
  onUploadClick?: () => void;
  activeTab: MaterialTag;
  setActiveTab: React.Dispatch<React.SetStateAction<MaterialTag>>;
  loading: boolean;
  wrapperCSS?: CSSProp;
  headerCSS?: CSSProp;
  onDeleteClick?: (materialId: string) => void;
  patientId?: string;
  materialIndicators?: MaterialIndicatorsModel;
}

const MaterialsSmallSection: FC<MaterialsSmallSectionProps> = ({
  showAllLink,
  className,
  materials,
  isTherapist,
  isIndividualScreen,
  onUploadClick,
  activeTab,
  setActiveTab,
  loading,
  wrapperCSS,
  headerCSS,
  onDeleteClick,
  patientId,
  materialIndicators,
}) => {
  const currentMaterials = useMemo(() => materials.slice(0, 3), [materials]);

  return (
    <Wrapper
      $CSS={wrapperCSS}
      className={className}
      $isTherapist={isTherapist}
      asElement="section"
    >
      <Header $CSS={headerCSS}>
        <Title>{isTherapist ? 'Materials' : 'My materials'}</Title>
        {isTherapist && onUploadClick && (
          <UploadButton onClick={() => onUploadClick()} />
        )}
      </Header>

      <MaterialsWrapper
        materials={currentMaterials}
        isTherapist={isTherapist}
        isSmall
        isIndividualScreen={isIndividualScreen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        loading={loading}
        onDeleteClick={onDeleteClick}
        patientId={patientId}
        materialIndicators={materialIndicators}
      />

      {materials.length > 3 && (
        <ShowAllLink
          to={{ pathname: showAllLink, state: { initialTab: activeTab } }}
        >
          Show all
        </ShowAllLink>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(ShadowWrapper)<{
  $CSS?: CSSProp;
  $isTherapist?: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${({ $isTherapist }) =>
    $isTherapist &&
    `box-shadow: none;
    padding: 0;`};

  ${({ $CSS }) => $CSS}
`;

const Header = styled.header<{ $CSS?: CSSProp }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${getSize(24)};

  ${({ $CSS }) => $CSS}
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};
  color: var(--black3);
`;

const MaterialsWrapper = styled(MaterialsSection)`
  margin: 0 0 ${getSize(9)};
`;

const ShowAllLink = styled(Link)`
  margin: 0 auto;
`;

export default MaterialsSmallSection;
