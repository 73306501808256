import {
  TherapyApproachesFragment,
  TreatmentAreaFragment,
} from 'common/query/__generated__/get-therapist-approach';
import { IPersonalInfoInit } from 'features/onboarding/personal-info/personal-info';
import { SORT_TYPE } from 'interfaces/common';
import { TherapistEditPersonalInfoInput } from '__generated__/types';
import { sortedByType } from './sort-helper';

export function compareObjects<I, V>(firstObject: I, secondObject: V) {
  return JSON.stringify(firstObject) === JSON.stringify(secondObject);
}

export function compareObjectFieldsPersonalInfo(
  input: TherapistEditPersonalInfoInput,
  initValues: IPersonalInfoInit,
) {
  if (
    input.title === initValues.title &&
    input.firstName === initValues.firstName &&
    input.lastName === initValues.lastName &&
    input.gender === initValues.gender &&
    input.phoneNumber === initValues.phoneNumber &&
    input.country === initValues.address?.country &&
    input.city === initValues.address?.city &&
    input.postcode === initValues.address?.postcode &&
    input.correspondenceAddress === initValues.address?.fullAddress
  ) {
    return true;
  }
  return false;
}

export const sortApproachesOrTreatmentAreas = (
  arr?: (TherapyApproachesFragment | TreatmentAreaFragment)[],
) => {
  return (
    arr &&
    [...arr].sort((a, b) => sortedByType(a.order, b.order, SORT_TYPE.ASC))
  );
};
