import React from 'react';
import styled from 'styled-components';

/** Custom components */
import { Loader } from 'ui/loader';

export interface LoadingContentProps {
  isLoading?: boolean;
  children: any;
  className?: string;
}

/**
 * @description This is a simple component to either render something or replace it with loading.
 */
function LoadingContent(props: LoadingContentProps) {
  /** Props */
  const { isLoading, children, className, ...rest } = props;

  if (isLoading) {
    return (
      <LoadingContainer className={className} {...rest}>
        <Loader />
      </LoadingContainer>
    );
  }

  return <>{children}</>;
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LoadingContent;
