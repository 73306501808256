import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function GmailIcon({ ...rest }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
      {...rest}
    >
      <g clipPath="url(#gmail-icon-svg)">
        <path
          fill="#FBBB00"
          d="M4.04 10.859l-.62 2.315-2.267.048A8.87 8.87 0 01.09 9c0-1.477.36-2.87.996-4.097l2.019.37.884 2.006A5.296 5.296 0 003.704 9c0 .654.118 1.28.336 1.859z"
        />
        <path
          fill="#518EF8"
          d="M17.753 7.336a8.93 8.93 0 01-.04 3.525 8.907 8.907 0 01-3.135 5.087h-.001l-2.542-.13-.36-2.246a5.31 5.31 0 002.285-2.712H9.196V7.336h8.557z"
        />
        <path
          fill="#28B446"
          d="M14.577 15.947A8.871 8.871 0 019 17.91a8.908 8.908 0 01-7.847-4.687l2.887-2.364a5.297 5.297 0 007.635 2.713l2.902 2.376z"
        />
        <path
          fill="#F14336"
          d="M14.687 2.142L11.8 4.505a5.299 5.299 0 00-7.81 2.774L1.087 4.903A8.907 8.907 0 019 .09c2.162 0 4.144.77 5.687 2.05z"
        />
      </g>
      <defs>
        <clipPath id="gmail-icon-svg">
          <path
            fill="#fff"
            d="M0 0h17.818v17.818H0z"
            transform="translate(.09 .09)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(18)};
  height: ${getSize(18)};
`;

export default GmailIcon;
