import React from 'react';

function HideSelfVideoIcon({ ...rest }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.9995 11C12.6502 11 13.9995 9.65066 13.9995 8C13.9995 6.34934 12.6502 5 10.9995 5C9.34883 5 7.99951 6.34934 7.99951 8C7.99951 9.65066 9.34885 11 10.9995 11Z"
        fill="white"
      />
      <path
        d="M15.9781 13.0438C15.9021 12.8598 15.8008 12.6881 15.6868 12.5286C15.1042 11.6945 14.2049 11.1425 13.1916 11.0076C13.0649 10.9953 12.9256 11.0198 12.8242 11.0934C12.2923 11.4737 11.659 11.67 11.0003 11.67C10.3416 11.67 9.70833 11.4737 9.17634 11.0934C9.075 11.0198 8.93567 10.983 8.80902 11.0076C7.79571 11.1425 6.88375 11.6945 6.31377 12.5286C6.19977 12.6881 6.09843 12.8721 6.02245 13.0438C5.98446 13.1174 5.99712 13.2033 6.03511 13.2769C6.13645 13.4486 6.2631 13.6203 6.3771 13.7675C6.55442 14.0006 6.74442 14.2091 6.95975 14.4054C7.13707 14.5771 7.33973 14.7366 7.54241 14.896C8.54304 15.6198 9.74634 16 10.9876 16C12.2289 16 13.4322 15.6197 14.4329 14.896C14.6355 14.7488 14.8382 14.5771 15.0155 14.4054C15.2182 14.2091 15.4208 14.0006 15.5982 13.7675C15.7248 13.6081 15.8389 13.4486 15.9402 13.2769C16.0035 13.2033 16.0161 13.1174 15.9781 13.0438Z"
        fill="white"
      />
      <rect
        x="1.29175"
        y="1.29102"
        width="19.4167"
        height="19.4167"
        rx="4"
        stroke="white"
        strokeWidth="2"
      />
      <rect
        x="19.3628"
        y="2"
        width="1.80944"
        height="24.5547"
        transform="rotate(45 19.3628 2)"
        fill="white"
      />
    </svg>
  );
}

export default HideSelfVideoIcon;
