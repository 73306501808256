import React, { useState } from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import MaterialItem, {
  Material,
} from './components/material-item/material-item';
import { useWindowClick } from 'hooks';
import { MaterialIndicatorsModel, MaterialTag } from '__generated__/types';
import { EmptyFilesIcon, Loader } from '../../../ui';
import FilesPreviewModal, {
  PreviewedFile,
} from 'ui/files-preview-modal/files-preview-modal';
import { TabsMaterial } from '../tabs-material';
import { Indicators } from 'common/query/__generated__/get-material-indicators';
import { useMaterialSubscription } from 'features/patient/dashboard/dashboard-home/utils/use-materials-subscription';

export interface MaterialsSectionProps {
  isSmall?: boolean;
  materials?: Material[];
  className?: string;
  isTherapist?: boolean;
  isIndividualScreen?: boolean;
  activeTab: MaterialTag;
  setActiveTab: React.Dispatch<React.SetStateAction<MaterialTag>>;
  loading: boolean;
  onDeleteClick?: (materialId: string) => void;
  isFetchMoreLoading?: boolean;
  patientId?: string;
  materialIndicators?: MaterialIndicatorsModel;
  fetchMaterials?: () => void;
  fileNameLimit?: number;
}

export const TABS = {
  [MaterialTag.Worksheet]: 'Worksheets',
  [MaterialTag.Recording]: 'Recordings',
  [MaterialTag.Diary]: 'Info',
};
export const MATERIAL_COUNTS: Record<MaterialTag, keyof Indicators> = {
  [MaterialTag.Worksheet]: 'worksheetsCount',
  [MaterialTag.Recording]: 'recordingCount',
  [MaterialTag.Diary]: 'diaryCount',
};

function MaterialsSection({
  isSmall,
  materials,
  className,
  isTherapist,
  isIndividualScreen,
  setActiveTab,
  activeTab,
  loading,
  onDeleteClick,
  isFetchMoreLoading,
  patientId,
  materialIndicators,
  fetchMaterials,
  fileNameLimit,
}: MaterialsSectionProps) {
  const [materialIdWithOpenedDropdown, setMaterialIdWithOpenedDropdown] =
    useState('');
  const [previewMaterial, setPreviewMaterial] = useState<
    PreviewedFile | undefined
  >();

  useWindowClick(() => setMaterialIdWithOpenedDropdown(''));

  const currentMaterials = materials || [];

  const renderStubContent = () => (
    <>
      <ImageWrapper>
        <EmptyFilesIcon />
      </ImageWrapper>

      <EmptyText $isSmall={isSmall}>{`Any materials ${
        isTherapist
          ? 'you share with this patient'
          : 'your therapist shares with you'
      } will be shown here.`}</EmptyText>
    </>
  );

  const renderMaterials = () => (
    <ul>
      {currentMaterials.map((material) => {
        const materialId = material.id;

        return (
          <StyledMaterialItem
            key={materialId}
            material={material}
            isSmall={isSmall}
            isTherapist={isTherapist}
            onPreview={() => setPreviewMaterial(material)}
            patientId={patientId}
            isDropdownVisible={materialIdWithOpenedDropdown === materialId}
            onDropdownTriggerClick={() =>
              setMaterialIdWithOpenedDropdown(materialId)
            }
            setIsVisible={setMaterialIdWithOpenedDropdown}
            onDeleteClick={
              onDeleteClick ? () => onDeleteClick(material.id) : undefined
            }
            isBubbleShow={!material?.isRead}
            fetchMaterials={fetchMaterials}
            fileNameLimit={fileNameLimit}
          />
        );
      })}
      {isFetchMoreLoading && <Loader />}
    </ul>
  );

  const switcher = (loading: boolean, materialsLength: number) => {
    if (loading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }

    if (materialsLength) {
      return renderMaterials();
    } else {
      return renderStubContent();
    }
  };

  useMaterialSubscription();

  const onTabClick = (newTab: string) => {
    setActiveTab && setActiveTab(newTab as MaterialTag);

    const currentTab = MATERIAL_COUNTS[newTab as MaterialTag];
    let currentCount;
    if (materialIndicators) {
      currentCount = materialIndicators?.[currentTab];
    }
  };
  return (
    <Wrapper className={className}>
      <TabsStylized
        tabs={TABS}
        isSmall={!isIndividualScreen && isSmall}
        $isSmall={isSmall}
        activeTab={activeTab || ''}
        onTabClick={(newTab) => onTabClick(newTab)}
        materialIndicators={materialIndicators}
      />
      {switcher(loading || false, currentMaterials.length)}
      <FilesPreviewModal
        isVisible={Boolean(previewMaterial)}
        file={previewMaterial}
        onClose={() => setPreviewMaterial(undefined)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const StyledMaterialItem = styled(MaterialItem)`
  padding: 15px 0 14px 0;
  border-bottom: 1px solid #eeeeee;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${getSize(200)};
`;

const TabsStylized = styled(TabsMaterial)<{ $isSmall?: boolean }>`
  margin-bottom: 24px;

  font-family: Poppins;
  font-size: ${getSize(15)};
  line-height: ${getSize(24)};
`;

const ImageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 83px;
`;

const EmptyText = styled.p<{ $isSmall?: boolean }>`
  font-size: ${getSize(18)};
  line-height: ${getSize(24)};
  text-align: center;
  max-width: 330px;
  margin: 14px auto 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;

export default MaterialsSection;
