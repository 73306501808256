import React from 'react';

function HideEyeIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="#B4B0BB"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path d="M9.551 12.854a2.896 2.896 0 01-2.403-2.404L5.669 8.972a4.455 4.455 0 005.36 5.36l-1.478-1.479zM19.376 10.901c.107-.139.304-.43.327-.463l.296-.438-.296-.438c-.023-.033-.22-.324-.327-.463-.498-.65-1.663-2.17-3.27-3.5C14.094 3.935 12.04 3.09 10 3.09c-1.483 0-2.983.45-4.465 1.34L1.124.02.02 1.124 18.875 19.98l1.104-1.104-4.207-4.208c1.786-1.396 3.08-3.083 3.604-3.767zm-6.858.514l-1.042-1.043a1.522 1.522 0 00-1.849-1.849L8.585 7.481a2.892 2.892 0 013.934 3.934zm1.132 1.132c.52-.742.8-1.624.8-2.547A4.455 4.455 0 0010 5.55c-.922 0-1.805.28-2.547.8l-.776-.776C7.8 4.96 8.913 4.652 10 4.652c2.642 0 5.367 1.8 8.1 5.348-.568.74-1.78 2.3-3.44 3.555l-1.009-1.008z" />
      <path d="M11.756 15.086a6.184 6.184 0 01-1.756.262c-2.642 0-5.367-1.8-8.1-5.348.392-.511 1.18-1.526 2.24-2.53L3.035 6.364C1.868 7.476 1.024 8.577.623 9.099c-.106.14-.304.43-.326.463L0 10l.297.438c.022.033.22.324.326.463.498.65 1.663 2.17 3.27 3.5C5.905 16.065 7.96 16.91 10 16.91c.99 0 1.988-.2 2.982-.599l-1.226-1.225z" />
    </svg>
  );
}

export default HideEyeIcon;
