import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { GenerateDownloadLinkService, getSize, Option } from 'lib/utils';

import NoteItem, { Note } from '../note-item/note-item';
import { NoteModal } from '../note-modal';
import { NoteModalFormValues } from '../note-modal/note-modal';
import { DashboardSectionHeader } from '../dashoard-sections-header';

export interface NotesSmallSectionProps {
  notes: Note[];
  isTherapist?: boolean;
  showAllLink: string;
  onAddNoteSubmit: (values: NoteModalFormValues) => void;
  setIsVisibleNoteModal: (value: boolean) => void;
  isVisibleNoteModal: boolean;
  onDeleteClick: (id: string) => void;
  setNoteInitialValues: (values?: NoteModalFormValues) => void;
  noteInitialValues?: NoteModalFormValues;
  connectedTherapistsOptions?: Option[];
}

function NotesDashboardSection({
  notes,
  isTherapist,
  showAllLink,
  isVisibleNoteModal,
  setIsVisibleNoteModal,
  onAddNoteSubmit,
  onDeleteClick,
  setNoteInitialValues,
  noteInitialValues,
  connectedTherapistsOptions,
}: NotesSmallSectionProps) {
  const currentNotes = useMemo(() => notes.slice(0, 3), [notes]);
  const notesLength = currentNotes.length;
  const { getOnePatientNote, getOneTherapistNote } =
    GenerateDownloadLinkService;

  const onCloseNoteModal = useCallback(() => {
    setIsVisibleNoteModal(false);
    setNoteInitialValues(undefined);
  }, [setIsVisibleNoteModal, setNoteInitialValues]);
  return (
    <Wrapper>
      <DashboardSectionHeader
        title="Notes"
        showAllLink={showAllLink}
        setIsVisibleModal={setIsVisibleNoteModal}
      />
      <List $notesLength={notesLength}>
        {currentNotes.map((note, index) => {
          const noteId = note.id;

          return (
            <NoteItem
              key={noteId}
              note={note}
              isSmall
              asElement="div"
              hasShortText={index > 0}
              onDeleteClick={() => onDeleteClick(noteId)}
              downloadLink={
                isTherapist
                  ? getOneTherapistNote(noteId)
                  : getOnePatientNote(noteId)
              }
              onEditClick={setNoteInitialValues}
              isTherapist={isTherapist}
            />
          );
        })}
      </List>

      <NoteModal
        isVisible={isVisibleNoteModal}
        onCloseNoteModal={onCloseNoteModal}
        onAddNoteSubmit={onAddNoteSubmit}
        isTherapist={isTherapist}
        initialValues={noteInitialValues}
        connectedTherapistsOptions={connectedTherapistsOptions}
      />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 23px;
`;

const List = styled.div<{ $notesLength: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${({ $notesLength }) => Math.max($notesLength, 2)},
    1fr
  );
  grid-column-gap: 20px;
  & > div {
    padding: ${({ $notesLength }) =>
      $notesLength > 2
        ? `${getSize(30)} ${getSize(28)} ${getSize(28)}`
        : `${getSize(34)}`};
  }
`;

export default NotesDashboardSection;
