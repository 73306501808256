import React, { UIEvent, RefObject } from 'react';
import styled from 'styled-components';
import { ApolloQueryResult } from '@apollo/client';

import { getSize } from 'lib/utils';
import {
  ChatAttachmentFragment,
  ChatMessageEdgeFragment,
  ChatMessages,
} from 'components/chat/query/__generated__/chat-messages';

import { DeleteMessages, Message } from './components';
import { useFetchAvailableMessageInView } from './utils';
import { getScrollPosition } from 'components/chat/utils';

export interface DialogProps {
  messages?: ChatMessageEdgeFragment[];
  userId?: string;
  isSideBarExpanded: boolean;
  isTherapist?: boolean;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
  fetchPreviousMessages: () =>
    | Promise<ApolloQueryResult<ChatMessages>>
    | undefined;
  fetchNextMessages: (
    oldScrollHeight?: number,
  ) => Promise<ApolloQueryResult<ChatMessages>> | undefined;
  isFetchingNewMessages: boolean;
  unreadMessagesCount?: number;
  readChat: () => void;
  messageListRef: RefObject<HTMLDivElement>;
  hasTextForSearchMessages: boolean;
  onMessageClick: (cursor: string, id: string) => void;
  isGroupSelectedChat: boolean;
  onAttachmentClick: (attachment: ChatAttachmentFragment) => void;
  isMessagesLoading: boolean;
  onSelectMessageForDeleting: (messageId: string) => void;
  onResetSelectedMessagesForDeleting: () => void;
  selectedMessagesForDeleting: string[];
  onOpenDeleteMessageModal: () => void;
}

function Dialog({
  messages,
  userId,
  isSideBarExpanded,
  isTherapist,
  hasPreviousPage,
  hasNextPage,
  unreadMessagesCount,
  readChat,
  fetchPreviousMessages,
  fetchNextMessages,
  isFetchingNewMessages,
  messageListRef,
  hasTextForSearchMessages,
  onMessageClick,
  isGroupSelectedChat,
  onAttachmentClick,
  isMessagesLoading,
  onSelectMessageForDeleting,
  onResetSelectedMessagesForDeleting,
  selectedMessagesForDeleting,
  onOpenDeleteMessageModal,
}: DialogProps) {
  const hasUnreadMessages = Number(unreadMessagesCount) > 0;

  useFetchAvailableMessageInView({
    messageListRef,
    hasPreviousPage,
    hasNextPage,
    isLoading: isFetchingNewMessages || isMessagesLoading,
    fetchNextMessages,
    fetchPreviousMessages,
  });

  function handleScroll({
    currentTarget: messageList,
  }: UIEvent<HTMLDivElement>) {
    if (isFetchingNewMessages) return;

    const { isOnBottom, isOnTop } = getScrollPosition(messageList);

    if (isOnBottom) {
      if (hasPreviousPage) {
        fetchPreviousMessages();
      } else if (hasUnreadMessages) {
        readChat();
      }
    } else if (hasNextPage && isOnTop) {
      fetchNextMessages(messageList.scrollHeight);
    }
  }

  return (
    <Wrapper $hasMessages={Boolean(messages?.length)}>
      {Boolean(selectedMessagesForDeleting.length) && (
        <DeleteMessages
          onResetSelectedMessagesForDeleting={
            onResetSelectedMessagesForDeleting
          }
          onDeleteSelectedMessages={onOpenDeleteMessageModal}
          selectedMessagesCount={selectedMessagesForDeleting.length}
        />
      )}
      {!isMessagesLoading && (
        <List
          ref={messageListRef}
          onScroll={handleScroll}
          className="primary-scrollbar"
        >
          {messages?.map(({ cursor, node }, index) => (
            <Message
              key={node.id}
              messageIndex={index}
              allMessages={messages}
              isSideBarExpanded={isSideBarExpanded}
              isTherapist={isTherapist}
              unreadCount={unreadMessagesCount}
              hasTextForSearchMessages={hasTextForSearchMessages}
              isUserMessage={userId === node.sender.id}
              onMessageClick={() => onMessageClick(cursor, node.id)}
              isGroupSelectedChat={isGroupSelectedChat}
              onAttachmentClick={onAttachmentClick}
              onSelectMessageForDeleting={() =>
                userId === node.sender.id && onSelectMessageForDeleting(node.id)
              }
              isSelectedSessionForDeleting={selectedMessagesForDeleting.includes(
                node.id,
              )}
            />
          ))}
        </List>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $hasMessages: boolean }>`
  position: relative;
  z-index: 1;
  ${({ $hasMessages }) => $hasMessages && 'flex-grow: 1;'}
  margin: 0 ${getSize(6)} ${getSize(28)} 0;
  height: 100%;
  overflow: hidden;
`;

const List = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 ${getSize(26)} 0 ${getSize(34)};
  height: 100%;
  overflow-y: overlay;

  @supports not (overflow-y: overlay) {
    overflow-y: auto;
  }
`;

export default Dialog;
