import { PATIENTS_ACCOUNT_SETTINGS } from 'lib/routes';

const { PERSONAL, EMERGENCY_CONTACTS, PAYMENT, TRANSACTIONS, INSURANCE } =
  PATIENTS_ACCOUNT_SETTINGS;

export const PATIENT_SETTINGS = [
  {
    href: PERSONAL,
    text: 'Personal Information',
  },
  {
    href: EMERGENCY_CONTACTS,
    text: 'Emergency Contacts',
  },
  {
    href: PAYMENT,
    text: 'Payment Method',
  },
  {
    href: INSURANCE,
    text: 'Insurance',
  },
  {
    href: TRANSACTIONS,
    text: 'Transactions',
  },
];
