import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import { css } from 'styled-components';
import Input, { InputProps } from '../input/input';

const InputBorder: FC<InputBorderProps> = ({ ...props }) => {
  return <Input inputCSS={INPUT_CSS} {...props} />;
};

type InputBorderProps = InputProps;

const INPUT_CSS = css`
  border: 1px solid var(--gray36);
  border-radius: ${getSize(8)};
  padding: ${getSize(6)} ${getSize(12)};
  min-height: ${getSize(46)};
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};

  transition: 0.3s ease-out;

  &:focus {
    border-color: var(--purple);
  }

  &:hover:not(:focus) {
    border-color: var(--black10);
  }

  &:disabled {
    &:hover {
      border-color: var(--gray36);
    }
  }

  &:-webkit-autofill:focus {
    transition: 0.3s ease-out;
    border: 1px solid var(--purple) !important;
  }
  &:-webkit-autofill:hover:not(&:-webkit-autofill:focus) {
    transition: 0.3s ease-out;
    border: 1px solid var(--black10) !important;
  }
  &:-webkit-autofill {
    transition: 0.3s ease-out;
    border: 1px solid var(--gray36) !important;
  }
`;

export default InputBorder;
