import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelTherapistAllFutureSessionVariables = Types.Exact<{
  input: Types.SessionCancelAllFuture;
}>;


export type CancelTherapistAllFutureSession = (
  { __typename: 'Mutation' }
  & { therapistSessionCancelAllFuture: (
    { __typename: 'SessionCommonPayload' }
    & Pick<Types.SessionCommonPayload, 'success'>
    & { userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const CancelTherapistAllFutureSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelTherapistAllFutureSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SessionCancelAllFuture"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistSessionCancelAllFuture"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type CancelTherapistAllFutureSessionMutationFn = Apollo.MutationFunction<CancelTherapistAllFutureSession, CancelTherapistAllFutureSessionVariables>;

/**
 * __useCancelTherapistAllFutureSession__
 *
 * To run a mutation, you first call `useCancelTherapistAllFutureSession` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTherapistAllFutureSession` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTherapistAllFutureSession, { data, loading, error }] = useCancelTherapistAllFutureSession({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelTherapistAllFutureSession(baseOptions?: Apollo.MutationHookOptions<CancelTherapistAllFutureSession, CancelTherapistAllFutureSessionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTherapistAllFutureSession, CancelTherapistAllFutureSessionVariables>(CancelTherapistAllFutureSessionDocument, options);
      }
export type CancelTherapistAllFutureSessionHookResult = ReturnType<typeof useCancelTherapistAllFutureSession>;
export type CancelTherapistAllFutureSessionMutationResult = Apollo.MutationResult<CancelTherapistAllFutureSession>;
export type CancelTherapistAllFutureSessionMutationOptions = Apollo.BaseMutationOptions<CancelTherapistAllFutureSession, CancelTherapistAllFutureSessionVariables>;