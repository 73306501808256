import * as React from 'react';

function MapFullscreenIcon({ ...props }) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="12" width="8" height="5" fill="white" />
      <rect
        x="1.5"
        y="1.5"
        width="20"
        height="19"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
}

export default MapFullscreenIcon;
