import * as Types from '../../../__generated__/types';

import { SessionsNeededFragment } from './get-sessions-need-feedback';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSessionsNeedAppFeedbackVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSessionsNeedAppFeedback = (
  { __typename: 'Query' }
  & { patientAppNeededFeedback: Array<(
    { __typename: 'SessionMetaModel' }
    & SessionsNeededFragment
  )> }
);


export const GetSessionsNeedAppFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSessionsNeedAppFeedback"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientAppNeededFeedback"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SessionsNeededFragment"}}]}}]}},...SessionsNeededFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetSessionsNeedAppFeedback__
 *
 * To run a query within a React component, call `useGetSessionsNeedAppFeedback` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsNeedAppFeedback` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsNeedAppFeedback({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionsNeedAppFeedback(baseOptions?: Apollo.QueryHookOptions<GetSessionsNeedAppFeedback, GetSessionsNeedAppFeedbackVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionsNeedAppFeedback, GetSessionsNeedAppFeedbackVariables>(GetSessionsNeedAppFeedbackDocument, options);
      }
export function useGetSessionsNeedAppFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionsNeedAppFeedback, GetSessionsNeedAppFeedbackVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionsNeedAppFeedback, GetSessionsNeedAppFeedbackVariables>(GetSessionsNeedAppFeedbackDocument, options);
        }
export type GetSessionsNeedAppFeedbackHookResult = ReturnType<typeof useGetSessionsNeedAppFeedback>;
export type GetSessionsNeedAppFeedbackLazyQueryHookResult = ReturnType<typeof useGetSessionsNeedAppFeedbackLazyQuery>;
export type GetSessionsNeedAppFeedbackQueryResult = Apollo.QueryResult<GetSessionsNeedAppFeedback, GetSessionsNeedAppFeedbackVariables>;