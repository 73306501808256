import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

export interface ProgressListProps {
  pages: string[];
  currentPageCount: number;
}

function ProgressList({ pages, currentPageCount }: ProgressListProps) {
  return (
    <List>
      {pages.map((page, index) => (
        <Item key={index} $isActive={index <= currentPageCount}>
          {page}
        </Item>
      ))}
    </List>
  );
}

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${getSize(100)}, 1fr));
  grid-gap: ${getSize(10)};
  margin: 0 0 ${getSize(34)};
`;

const Item = styled.li<{ $isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 ${getSize(8)};
  text-align: center;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(${({ $isActive }) => ($isActive ? '--black3' : '--gray7')});

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${getSize(2)};
    background: var(
      ${({ $isActive }) => ($isActive ? '--purple' : '--purple9')}
    );
    border-radius: ${getSize(4)};
  }
`;

export default ProgressList;
