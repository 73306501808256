import { useGetPatientPaymentMethods } from 'common/query/__generated__/get-patient-payment-methods';
import { useMemo } from 'react';

const usePatientPaymentMethods = () => {
  const { data: paymentMethodsData, loading: paymentMethodsLoading } =
    useGetPatientPaymentMethods();

  const paymentMethodOptions = useMemo(() => {
    return (paymentMethodsData?.patientPaymentMethods.paymentMethods || []).map(
      (paymentMethod) => ({
        value: paymentMethod.id,
        label: paymentMethod.last4,
        brand: paymentMethod.brand,
      }),
    );
  }, [paymentMethodsData]);

  const defaultPaymentMethod =
    paymentMethodsData?.patientPaymentMethods.defaultPaymentMethod || '';

  return { defaultPaymentMethod, paymentMethodOptions, paymentMethodsLoading };
};

export default usePatientPaymentMethods;
