import React from 'react';

function StarDiscoverIcon({ ...rest }) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.9861 7.20773C18.0761 6.73608 17.7163 6.17009 17.2667 6.17009L12.1406 5.41545L9.80244 0.510283C9.71251 0.321623 9.62258 0.227292 9.44272 0.132962C8.99306 -0.150028 8.45348 0.038632 8.18369 0.510283L5.93542 5.41545L0.809376 6.17009C0.539584 6.17009 0.359723 6.26442 0.269792 6.45309C-0.0899306 6.83041 -0.0899306 7.39639 0.269792 7.77371L3.95695 11.5469L3.05764 16.9237C3.05764 17.1124 3.05764 17.3011 3.14757 17.4897C3.41736 17.9614 3.95695 18.15 4.4066 17.867L8.99306 15.3201L13.5795 17.867C13.6695 17.9614 13.8493 17.9614 14.0292 17.9614C14.1191 17.9614 14.1191 17.9614 14.209 17.9614C14.6587 17.867 15.0184 17.3954 14.9285 16.8294L14.0292 11.4526L17.7163 7.67938C17.8962 7.58505 17.9861 7.39639 17.9861 7.20773Z"
        fill="#674FDE"
      />
    </svg>
  );
}

export default StarDiscoverIcon;
