import React, { useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';

import { getSize } from 'lib/utils';
import { BubbleCheckboxTheme } from '../bubble-checkbox/bubble-checkbox';
import { Filters } from 'amplitude-analytics/enums';

import { BubbleCheckbox } from 'ui';

export interface Option {
  value?: string | null;
  label?: string | null;
}
export interface BubbleCheckboxGroupProps {
  name: string;
  theme?: BubbleCheckboxTheme;
  options: Option[];
  label?: string;
  className?: string;
  contentCSS?: CSSProp;
  buttonCSS?: Record<string, unknown>;
  buttonTextCSS?: CSSProp;
  validate?: FieldValidator<any>;
  filterType?: Filters;
}

const CheckboxRootCSS = { margin: '0 12px 12px 0' };

function BubbleCheckboxGroup({
  name,
  theme,
  options,
  label,
  className,
  contentCSS,
  buttonCSS,
  buttonTextCSS,
  validate,
  filterType,
}: BubbleCheckboxGroupProps) {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Wrapper className={className}>
      {label && <Label>{label}</Label>}

      <Inner $CSS={contentCSS}>
        {options.map(({ value, label }) => (
          <Field
            key={value}
            name={name}
            label={label}
            type="checkbox"
            value={value}
            rootCSS={{ ...CheckboxRootCSS, ...buttonCSS }}
            labelCSS={buttonTextCSS}
            theme={theme}
            validate={validate}
            component={BubbleCheckbox}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            filterType={filterType}
          />
        ))}
      </Inner>

      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const Label = styled.p`
  margin: 0 0 ${getSize(10)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray2);
`;

const Inner = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 ${getSize(-12)} ${getSize(-12)} 0;

  ${({ $CSS }) => $CSS}
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default BubbleCheckboxGroup;
