import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled, { CSSProp } from 'styled-components';

interface NotificationsCountProps {
  count: number;
  wrapperCSS?: CSSProp;
  className?: string;
}

const NotificationsCount: FC<NotificationsCountProps> = ({
  count,
  className,
  wrapperCSS,
}) => {
  return (
    <Wrapper $CSS={wrapperCSS} className={className}>
      {count}
    </Wrapper>
  );
};

const Wrapper = styled.span<{ $CSS?: CSSProp }>`
  width: ${getSize(23)};
  height: ${getSize(15)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${getSize(8)};
  background-color: var(--red);
  font-weight: normal;
  font-size: ${getSize(10)};
  line-height: ${getSize(15)};
  color: var(--white);

  ${({ $CSS }) => $CSS}
`;

export default NotificationsCount;
