import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TherapistTitlesVariables = Types.Exact<{ [key: string]: never; }>;


export type TherapistTitles = (
  { __typename: 'Query' }
  & { therapistTitles: Array<(
    { __typename: 'TherapistTitleModel' }
    & Pick<Types.TherapistTitleModel, 'id' | 'label' | 'explanation'>
  )> }
);


export const TherapistTitlesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TherapistTitles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistTitles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useTherapistTitles__
 *
 * To run a query within a React component, call `useTherapistTitles` and pass it any options that fit your needs.
 * When your component renders, `useTherapistTitles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistTitles({
 *   variables: {
 *   },
 * });
 */
export function useTherapistTitles(baseOptions?: Apollo.QueryHookOptions<TherapistTitles, TherapistTitlesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistTitles, TherapistTitlesVariables>(TherapistTitlesDocument, options);
      }
export function useTherapistTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistTitles, TherapistTitlesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistTitles, TherapistTitlesVariables>(TherapistTitlesDocument, options);
        }
export type TherapistTitlesHookResult = ReturnType<typeof useTherapistTitles>;
export type TherapistTitlesLazyQueryHookResult = ReturnType<typeof useTherapistTitlesLazyQuery>;
export type TherapistTitlesQueryResult = Apollo.QueryResult<TherapistTitles, TherapistTitlesVariables>;