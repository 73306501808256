import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TherapistNoteAddVariables = Types.Exact<{
  input: Types.TherapistAddNoteInput;
}>;


export type TherapistNoteAdd = (
  { __typename: 'Mutation' }
  & { therapistNoteAdd: (
    { __typename: 'NotePayload' }
    & { note?: Types.Maybe<(
      { __typename: 'NoteModel' }
      & TherapistNote
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);

export type TherapistNote = (
  { __typename: 'NoteModel' }
  & Pick<Types.NoteModel, 'id' | 'text' | 'date' | 'shared' | 'createdAt' | 'createdForPatientId' | 'sessionMetaId'>
);

export const TherapistNote = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TherapistNote"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"NoteModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"shared"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdForPatientId"}},{"kind":"Field","name":{"kind":"Name","value":"sessionMetaId"}}]}}]} as unknown as DocumentNode;
export const TherapistNoteAddDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"TherapistNoteAdd"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistAddNoteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistNoteAdd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"note"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TherapistNote"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}},...TherapistNote.definitions]} as unknown as DocumentNode;
export type TherapistNoteAddMutationFn = Apollo.MutationFunction<TherapistNoteAdd, TherapistNoteAddVariables>;

/**
 * __useTherapistNoteAdd__
 *
 * To run a mutation, you first call `useTherapistNoteAdd` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTherapistNoteAdd` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [therapistNoteAdd, { data, loading, error }] = useTherapistNoteAdd({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTherapistNoteAdd(baseOptions?: Apollo.MutationHookOptions<TherapistNoteAdd, TherapistNoteAddVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TherapistNoteAdd, TherapistNoteAddVariables>(TherapistNoteAddDocument, options);
      }
export type TherapistNoteAddHookResult = ReturnType<typeof useTherapistNoteAdd>;
export type TherapistNoteAddMutationResult = Apollo.MutationResult<TherapistNoteAdd>;
export type TherapistNoteAddMutationOptions = Apollo.BaseMutationOptions<TherapistNoteAdd, TherapistNoteAddVariables>;