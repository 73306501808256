import { useMemo } from 'react';
import { sortOptionsByAlphaBet, transformToOptions } from 'lib/utils';
import { useLanguages } from 'common/query/__generated__/get-languages';
import { LanguageModel } from '../../__generated__/types';

function languageSort(a: Partial<LanguageModel>, b: Partial<LanguageModel>) {
  if (a.name === 'English') return -1;
  if (b.name === 'English') return 1;
  if (a.name && b.name && a.name > b.name) {
    return 1;
  } else {
    return -1;
  }
}

export function useLanguagesOptions() {
  const languagesQuery = useLanguages();

  return useMemo(
    () =>
      sortOptionsByAlphaBet(
        transformToOptions(
          languagesQuery.data?.languages
            .map((l) => ({ ...l, label: l.name }))
            .sort(languageSort),
        ),
      ),
    [languagesQuery.data],
  );
}
