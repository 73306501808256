import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { css, CSSProp } from 'styled-components';
import { amplitudeService } from 'lib/amplitude';
import { getSize } from 'lib/utils';
import { Filters } from 'amplitude-analytics/enums';
import { AmplitudeEvents } from '../../../lib/amplitude';

export interface CheckboxWithIconProps extends FieldRenderProps<string> {
  label?: string;
  rootCSS?: CSSProp;
  iconCSS?: CSSProp;
  disabled?: boolean;
  Icon: (props: any) => JSX.Element;
}

interface WrapperProps {
  $isChecked?: boolean;
  $isDisabled?: boolean;
  $CSS?: CSSProp;
}

type IndicatorProps = WrapperProps;

const ICON_CSS = {
  margin: `0 ${getSize(8)} 0 0`,
  fill: '#8b9ba9',
};

function CheckboxWithIcon({
  input,
  meta,
  label,
  rootCSS,
  disabled,
  Icon,
  iconCSS,
}: CheckboxWithIconProps) {
  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;
  const isChecked = input.checked;

  useEffect(() => {
    if (isChecked) {
      amplitudeService.logEvent(AmplitudeEvents.FILTER_ADDED, {
        type: Filters.how,
        value: label,
      });
    }
  }, [isChecked]);

  return (
    <Wrapper $CSS={rootCSS} $isDisabled={disabled} $isChecked={isChecked}>
      <Inner $isChecked={isChecked} $iconCSS={iconCSS}>
        <IndicatorStylized $isChecked={isChecked} $isDisabled={disabled} />
        <Input {...input} disabled={disabled} />
        <Icon style={ICON_CSS} />
        {label && <Text $isChecked={isChecked}>{label}</Text>}
      </Inner>

      {hasError && <ErrorText>{error || submitError || data?.error}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.label<WrapperProps>`
  display: block;
  ${({ $isDisabled }) => ($isDisabled ? '' : 'cursor: pointer;')}

  ${({ $isChecked }) =>
    !$isChecked &&
    `
    &:hover {
      span{
        color: var(--purple);
      }
    }
  `}

  ${({ $CSS }) => $CSS}
`;

const Inner = styled.div<{ $isChecked?: boolean; $iconCSS?: CSSProp }>`
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;

  & > svg {
    width: ${getSize(30)};
    height: ${getSize(30)};

    ${({ $iconCSS }) => $iconCSS}
  }
`;

const Input = styled.input`
  display: none;
`;

const Text = styled.span<{ $isChecked?: boolean }>`
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: var(--black3);
  margin: ${getSize(1)} 0 0;
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

const IndicatorStylized = styled.span<IndicatorProps>`
  position: relative;
  flex-shrink: 0;
  width: ${getSize(19)};
  height: ${getSize(19)};
  border: ${({ $isChecked }) =>
    $isChecked ? 'none' : '1px solid var(--gray20)'};
  border-radius: ${getSize(4)};
  background: var(${({ $isChecked }) => ($isChecked ? '--black4' : '--white')});
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-right: ${getSize(14)};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css<IndicatorProps>`
      cursor: pointer;

      &:hover {
        &::before {
          transform: rotate(-45deg) scale(1.2);
        }
      }
    `}

  &::before {
    content: '';
    position: absolute;
    top: ${getSize(4)};
    left: ${getSize(4)};
    width: ${getSize(11)};
    height: ${getSize(7)};
    border: 2px solid white;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg);
    transition: 0.3s ease-out;
  }

  ${({ $CSS }) => $CSS}
`;

export default CheckboxWithIcon;
