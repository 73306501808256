import { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

export const useSelectContinueApp = () => {
  const [isShowSelectAppModal, setIsShowSelectAppModal] = useState(false);

  useEffect(() => {
    if (isAndroid || isIOS) {
      setIsShowSelectAppModal(true);
    }
  }, []);

  const onCloseModal = () => {
    setIsShowSelectAppModal(false);
  };

  return {
    onCloseModal,
    isShowSelectAppModal,
  };
};
