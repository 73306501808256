import amplitude from 'amplitude-js';
import { config } from 'lib/utils';

const enabled = false;

export const amplitudeService = {
  init() {
    if (enabled) {
      amplitude.getInstance().init(config.AMPLITUDE_API_KEY, undefined, {
        includeUtm: true,
        deviceIdFromUrlParam: true,
      });
    }
  },
  logEvent(event: string, params: Record<string, unknown> = {}) {
    if (enabled) {
      amplitude.getInstance().logEvent(event, params);
    }
  },
  setUserId(id: string | null) {
    if (enabled) {
      amplitude.getInstance().setUserId(id);
    }
  },
  regenerateDeviceId() {
    if (enabled) {
      amplitude.getInstance().regenerateDeviceId();
    }
  },
  identify(
    key: string,
    value: string | number | boolean | Record<string, unknown> | any[],
  ) {
    if (enabled) {
      const identify = new amplitude.Identify().set(key, value);
      amplitude.getInstance().identify(identify);
    }
  },
};

export enum AmplitudeEvents {
  // Onboarding
  CREATE_ACCOUNT = 'create_account',
  // EMAIL_CONFIRMED = 'email_confirmed',
  LOGIN = 'login',

  // Browse ( Find my Therapist )
  FIND_MY = 'find_my',
  GUIDED_MATCHING_START = 'guided_matching completed',
  GUIDED_MATCHING_COMPLETED = 'initial_assessment completed',
  FILTER_ADDED = 'search_filter added',
  PROFILE_CLICK = 'profile_click',
  EMPTY_SEARCH_FILTERS_CLEARED = 'empty_search_filters cleared',

  // Booking
  BOOKING_GATE_1 = 'booking_gate_1',
  BOOKING_GATE_2 = 'booking_gate_2',

  // Session Control
  SESSION_IS_BOOKED = 'session is booked',
  SESSION_CANCELED = 'session cancel',
  SESSION_CREATED = 'session_created',
  AO_COMPLETED = '',

  // Session Experience
  SESSION_START = 'session_start',
  SESSION_COMPLETED = 'session_completed',

  // Tools
  NOTE_ADDED = 'patient_note created',
  MTA_MESSAGES = 'mta_messages',
  MTA_MESSAGE_SEND = 'mta_messages_send',
  GOAL_ADDED = 'goal created',
  MATERIAL_DOWNLOAD = 'material_download',

  // Rating
  RATING = 'rating',

  // Questionnaire
  QUESTIONNARE_STARTED = 'questionnaire started',
  QUESTIONNARE_SEND_TO_PATIENT = 'questionnaire sent to patient',
  QUESTIONNARE_COMPLETED = 'questionnaire completed',

  // Admin
  // ADMIN_LOGIN_COMPLETED = '',

  // Outcome
  SESSION_OUTCOME_SUBMIT = 'session_outcome submit',
  THERAPIST_LOGIN_COMPLETED = 'therapist_login completed',
  THERAPIST_REGISTRATION_COMPLETED = 'therapist_registration_completed',
  THERAPIST_SESSION_ADDED = 'therapist_session added',
  THERAPIST_CHAT_MESSAGE_SENT = 'therapists_chat message_sent',
  BOOKING_REJECTED = 'booking rejected',
}

export enum TherapistProfileClickType {
  CARD_CLICK = 'card_click',
  BUTTON_CLICK = 'button_click',
}
