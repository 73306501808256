import React from 'react';

function MaterialWorksheet({ ...rest }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.20801 17.7188C4.16787 17.7188 0.0673828 21.8192 0.0673828 26.8594C0.0673828 31.8995 4.16787 36 9.20801 36C14.2481 36 18.3486 31.8995 18.3486 26.8594C18.3486 21.8192 14.2481 17.7188 9.20801 17.7188ZM13.2438 25.7494L8.50425 30.4889C8.306 30.6873 8.04607 30.7865 7.78613 30.7865C7.5262 30.7865 7.26627 30.6873 7.06802 30.4889L5.03677 28.4577C4.64013 28.0611 4.64013 27.418 5.03677 27.0214C5.43334 26.6248 6.07643 26.6248 6.47307 27.0214L7.78613 28.3345L11.8076 24.3131C12.2042 23.9165 12.8473 23.9165 13.2439 24.3131C13.6405 24.7097 13.6405 25.3528 13.2438 25.7494Z"
        fill="white"
      />
      <path
        d="M26.8122 10.1361C26.2513 10.1361 25.7965 9.68134 25.7965 9.12044V1.33398H11.2393C9.55921 1.33398 8.19238 2.70081 8.19238 4.38086V15.7348C8.527 15.7045 8.86561 15.6882 9.20801 15.6882C12.6698 15.6882 15.7687 17.2709 17.8196 19.7507H28.8434C29.4043 19.7507 29.859 20.2054 29.859 20.7663C29.859 21.3272 29.4043 21.7819 28.8434 21.7819H19.1576C19.7924 23.0207 20.2018 24.3931 20.3332 25.8444H28.8434C29.4043 25.8444 29.859 26.2991 29.859 26.86C29.859 27.4209 29.4043 27.8757 28.8434 27.8757H20.3332C20.0295 31.2299 18.2374 34.1607 15.6239 36.0007H31.5518C33.2318 36.0007 34.5986 34.6338 34.5986 32.9538V10.1361H26.8122ZM28.8434 15.6882H13.9476C13.3867 15.6882 12.932 15.2334 12.932 14.6725C12.932 14.1116 13.3867 13.6569 13.9476 13.6569H28.8434C29.4043 13.6569 29.859 14.1116 29.859 14.6725C29.859 15.2334 29.4043 15.6882 28.8434 15.6882Z"
        fill="white"
      />
      <path
        d="M27.8281 1.92773V8.10389H34.0039L27.8281 1.92773Z"
        fill="white"
      />
    </svg>
  );
}

export default MaterialWorksheet;
