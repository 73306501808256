import React, { RefObject } from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

export interface TimeIndicatorProps {
  topPosition: number;
  leftPosition?: number;
  rightPosition?: number;
  className?: string;
  rootRef?: RefObject<HTMLDivElement>;
}

function TimeIndicator({
  topPosition,
  leftPosition,
  rightPosition,
  className,
  rootRef,
}: TimeIndicatorProps) {
  return (
    <Wrapper
      ref={rootRef}
      className={className}
      $topPosition={topPosition}
      $leftPosition={leftPosition}
      $rightPosition={rightPosition}
    />
  );
}

const Wrapper = styled.div<{
  $topPosition: number;
  $leftPosition?: number;
  $rightPosition?: number;
}>`
  position: absolute;
  z-index: 99;
  top: ${({ $topPosition }) => getSize($topPosition)};
  ${({ $leftPosition }) =>
    Number.isInteger($leftPosition) && `left: ${getSize($leftPosition)};`}
  ${({ $rightPosition }) =>
    Number.isInteger($rightPosition) && `right: ${getSize($rightPosition)};`}
  width: 100%;
  height: ${getSize(2)};
  background-color: var(--purple);

  &::before {
    content: '';
    position: absolute;
    left: ${getSize(-6)};
    top: ${getSize(-5)};
    width: ${getSize(12)};
    height: ${getSize(12)};
    border-radius: 100%;
    background-color: var(--purple);
    z-index: 99;
  }
`;

export default TimeIndicator;
