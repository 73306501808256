import React, { UIEvent } from 'react';
import styled from 'styled-components';

import { getSize } from 'lib/utils';
import { ChatFragment } from 'components/chat/query/__generated__/chats-list';

import { WriteMessageButton, ChatItem } from './components';
import { SingleArrowIcon, Loader } from 'ui';
import { getScrollPosition } from 'components/chat/utils';

export interface SideBarProps {
  onWriteMessageClick?: () => void;
  isTherapist?: boolean;
  chats: ChatFragment[];
  selectedChatId?: string;
  isExpanded: boolean;
  onExpandClick: () => void;
  isChatsLoadaing: boolean;
  onChatClick: (id: string) => void;
  fetchMoreChats: () => void;
  hasMoreChats?: boolean;
  userId?: string;
  isFetchingNewChats: boolean;
}

function SideBar({
  onWriteMessageClick,
  isTherapist,
  chats,
  selectedChatId,
  isExpanded,
  onExpandClick,
  isChatsLoadaing,
  onChatClick,
  fetchMoreChats,
  hasMoreChats,
  userId,
  isFetchingNewChats,
}: SideBarProps) {
  function handleScroll({
    currentTarget: chatsList,
  }: UIEvent<HTMLUListElement>) {
    if (isFetchingNewChats) return;

    const { isOnBottom } = getScrollPosition(chatsList);

    if (hasMoreChats && isOnBottom) {
      fetchMoreChats();
    }
  }

  return (
    <Wrapper $isExpanded={isExpanded} $isTherapist={isTherapist}>
      <Header $isTherapist={isTherapist}>
        {isTherapist && (
          <WriteMessageButton
            isExpanded={isExpanded}
            onClick={onWriteMessageClick}
          />
        )}
        {!isTherapist && <Title isExpanded={isExpanded}>Conversations</Title>}
        <ExpandButton
          $isExpanded={isExpanded}
          $isTherapist={isTherapist}
          onClick={onExpandClick}
        >
          <span className="visually-hidden">Expand interlocutor list</span>
          <Icon className="expand-icon" />
        </ExpandButton>
      </Header>

      {isChatsLoadaing ? (
        <Loader size={50} hasFillWholeBlock />
      ) : (
        <ChatsWrapper>
          <ChatsList $isTherapist={isTherapist} onScroll={handleScroll}>
            {chats?.map((chat) => {
              const chatId = chat.id;

              return (
                <ChatItem
                  key={chatId}
                  chat={chat}
                  userId={userId}
                  isExpanded={isExpanded}
                  onClick={() => onChatClick(chatId)}
                  isActive={chatId === selectedChatId}
                />
              );
            })}
          </ChatsList>
        </ChatsWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $isExpanded: boolean; $isTherapist?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  ${({ $isTherapist }) => $isTherapist && `padding: ${getSize(22)} 0 0;`}
  background: var(--purple11);
  width: ${({ $isExpanded }) => getSize($isExpanded ? 90 : 378)};
  transition: 0.3s ease-out;
  overflow: hidden;
  border: 1px solid #e8e8e8;
  border-radius: 8px 0 0 8px;
`;

const Header = styled.div<{ $isTherapist?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  ${({ $isTherapist }) => $isTherapist && `margin: 0 0 ${getSize(10)};`}
  padding: ${getSize(18)} ${getSize(20)};
  border-bottom: 1px solid #e9e9e9;
`;

const ExpandButton = styled.button<{
  $isExpanded: boolean;
  $isTherapist?: boolean;
}>`
  position: ${({ $isTherapist }) => ($isTherapist ? 'static' : 'absolute')};
  padding-left: ${({ $isTherapist }) => ($isTherapist ? '6px' : 0)};
  right: ${({ $isExpanded, $isTherapist }) =>
    $isExpanded && !$isTherapist ? '50%' : '26px'};
  transition: 0.3s ease-out;
  ${({ $isExpanded, $isTherapist }) =>
    $isExpanded &&
    `transform: scale(-1) ${$isTherapist ? '' : 'translateX(-50%)'};`}

  &:hover {
    .expand-icon {
      fill: var(--purple);
    }
  }
`;

const Icon = styled(SingleArrowIcon)`
  transition: 0.3s ease-out;
`;

const ChatsWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const ChatsList = styled.ul<{ $isTherapist?: boolean }>`
  ${({ $isTherapist }) => $isTherapist && `padding: ${getSize(10)} 0 0;`}
  height: 100%;
  overflow-y: overlay;

  @supports not (overflow-y: overlay) {
    overflow-y: auto;
  }
`;

interface TitleProps {
  isExpanded: boolean;
}

const Title = styled.p<TitleProps>`
  font-weight: 500;
  font-size: ${getSize(16)};
  line-height: 143%;
  color: #46494b;
  transition: 0.3s ease-out;
  opacity: ${({ isExpanded }) => (isExpanded ? 0 : 1)};
  user-select: none;
`;

export default SideBar;
