import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function NewSearchIcon({ ...rest }) {
  return (
    <Icon
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      {...rest}
    >
      <path
        d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
        stroke="#A9A9A9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7498 15.75L12.4873 12.4875"
        stroke="#A9A9A9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(18)};
  height: ${getSize(18)};
`;

export default NewSearchIcon;
