import React from 'react';

function ChevronRightIcon({ fill, ...rest }: any) {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.2878 8.29764L2.27754 0.287559C2.09227 0.102146 1.84496 0 1.58125 0C1.31755 0 1.07023 0.102146 0.884966 0.287559L0.295068 0.87731C-0.0887827 1.2616 -0.0887827 1.88618 0.295068 2.26988L7.02145 8.99627L0.287604 15.7301C0.102338 15.9155 4.57764e-05 16.1627 4.57764e-05 16.4263C4.57764e-05 16.6901 0.102338 16.9373 0.287604 17.1228L0.877502 17.7124C1.06292 17.8979 1.31008 18 1.57379 18C1.83749 18 2.08481 17.8979 2.27008 17.7124L10.2878 9.69504C10.4735 9.50905 10.5755 9.26071 10.5749 8.99671C10.5755 8.73169 10.4735 8.48349 10.2878 8.29764Z"
        fill={fill || '#6B4EE6'}
      />
    </svg>
  );
}

export default ChevronRightIcon;
