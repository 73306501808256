import React from 'react';
import styled from 'styled-components';

import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import { getSize } from 'lib/utils';
import { ChatFragment } from 'components/chat/query/__generated__/chats-list';

import { Button } from 'ui';
import { Search } from './components';

export interface DialogHeaderProps {
  selectedChat?: ChatFragment;
  isExpanded: boolean;
  hasVideoCallButtonVisible: boolean;
  onVideoCallClick: () => void;
  onSearchFormSubmit: (search?: string) => void;
  onSearchCancelClick: () => void;
  isSearchActive: boolean;
  onSearchTriggerClick: () => void;
  onParticipantsClick: () => void;
  participantsCount?: number;
}

function DialogHeader({
  selectedChat,
  isExpanded,
  hasVideoCallButtonVisible,
  onVideoCallClick,
  onSearchFormSubmit,
  onSearchCancelClick,
  isSearchActive,
  onSearchTriggerClick,
  onParticipantsClick,
  participantsCount,
}: DialogHeaderProps) {
  return (
    <Wrapper $isExpanded={isExpanded}>
      <Inner>
        <InfoWrapper $isSearcActive={isSearchActive}>
          <UserWrapper>
            {/* <Avatar src={selectedChat?.imageUrl || DEFAULT_AVATAR} /> */}
            <div>
              <Name className="text-line-clamp-1">
                {selectedChat?.title || 'User'}
              </Name>
              {participantsCount && (
                <ParticipantsButton type="button" onClick={onParticipantsClick}>
                  {`${participantsCount} participants`}
                </ParticipantsButton>
              )}
            </div>
          </UserWrapper>
          {hasVideoCallButtonVisible && (
            <PrimaryButton theme="primary" onClick={onVideoCallClick}>
              Video Call
            </PrimaryButton>
          )}
        </InfoWrapper>

        <Search
          isActive={isSearchActive}
          onTriggerClick={onSearchTriggerClick}
          onCancelClick={onSearchCancelClick}
          onFormSubmit={onSearchFormSubmit}
        />
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $isExpanded: boolean }>`
  padding: ${getSize(18)} ${getSize(13)} ${getSize(18)} ${getSize(11)};
  border-bottom: 1px solid #e9e9e9;
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${getSize(40)} 0 0;
`;

const InfoWrapper = styled.div<{ $isSearcActive: boolean }>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  transition: 0.3s ease-out;
  ${({ $isSearcActive }) => $isSearcActive && 'opacity: 0;'}
`;

const UserWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0 ${getSize(16)} 0 0;
`;

const Avatar = styled.img`
  margin: 0 ${getSize(12)} 0 0;
  width: ${getSize(40)};
  height: ${getSize(40)};
  object-fit: cover;
  border-radius: 50%;
`;

const Name = styled.p`
  font-weight: 500;
  font-size: ${getSize(16)};
  line-height: 143%;
  color: var(--black3);
  margin-left: ${getSize(21)};
`;

const ParticipantsButton = styled.button`
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);

  &:hover {
    text-decoration: underline;
  }
`;

const PrimaryButton = styled(Button)`
  padding: ${getSize(11)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  min-height: ${getSize(43)};
  min-width: ${getSize(113)};
`;

export default DialogHeader;
