import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ToggleButtonProps {
  isActive?: boolean;
  onChangeIsActive?: () => void;
  className?: string;
}

const ToggleButton: FC<ToggleButtonProps> = ({
  isActive,
  onChangeIsActive,
  className,
}) => {
  return (
    <Label
      className={className}
      $isActive={isActive}
      onClick={onChangeIsActive}
    >
      <Slider $isActive={isActive} />
    </Label>
  );
};

export default ToggleButton;

const Label = styled.label<{ $isActive?: boolean }>`
  position: relative;
  display: inline-block;
  width: ${getSize(44)};
  height: ${getSize(24)};
  border-radius: ${getSize(19)};
  flex-shrink: 0;

  border: 1px solid
    ${({ $isActive }) => ($isActive ? `var(--purple)` : ` var(--gray4)`)};
`;

const Slider = styled.span<{ $isActive?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--pink2);
  border-radius: ${getSize(19)};
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    height: ${getSize(21)};
    width: ${getSize(21)};
    left: ${getSize(1)};
    border-radius: 100%;
    background-color: var(--gray4);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  ${({ $isActive }) =>
    $isActive
      ? `background-color: var(--purple);

      &:: before {
        background: var(--white);
        -webkit-transform: translateX(${getSize(18)});
        -ms-transform: translateX(${getSize(18)});
        transform: translateX(${getSize(18)});
      }`
      : ''}
`;
