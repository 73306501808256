import React, { useEffect } from 'react';
import styled, { CSSProp } from 'styled-components';
import { FieldRenderProps } from 'react-final-form';
import { StarIcon } from 'ui/icons';

export interface StartRadioButtonProps extends FieldRenderProps<string> {
  label: string;
  radioRootCSS: CSSProp;
  errorMessage: string;
  setErrorMessage: (hasError: string) => void;
  selectedRating?: string;
}

function StartRadioButton({
  meta,
  input,
  radioRootCSS,
  errorMessage,
  setErrorMessage,
  selectedRating,
}: StartRadioButtonProps): JSX.Element {
  useEffect(() => {
    const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
    const hasError =
      ((error || submitError) && touched && !dirtySinceLastSubmit) ||
      data?.error;

    if (!errorMessage && hasError) {
      const newErrorMessage = error || submitError || data?.error;
      setErrorMessage(newErrorMessage);
    } else if (errorMessage && !hasError) {
      setErrorMessage('');
    }
  }, [errorMessage, meta, setErrorMessage]);

  const isActive =
    selectedRating && Number(input.value) <= Number(selectedRating);

  return (
    <Wrapper $CSS={radioRootCSS}>
      <Input {...input} />
      <Star $isActive={isActive} />
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS: CSSProp }>`
  cursor: pointer;

  ${({ $CSS }) => $CSS}
`;

const Input = styled.input`
  display: none;
`;

const Star = styled(StarIcon)<{ $isActive?: boolean }>`
  fill: ${({ $isActive }) => ($isActive ? 'var(--yellow)' : 'var(--purple5)')};
`;
export default StartRadioButton;
