import React from 'react';

function AppleIcon({ ...rest }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.655 6.6203C9.85202 6.6203 8.60902 5.7073 7.30002 5.7403C5.57302 5.7623 3.98902 6.7413 3.09802 8.2923C1.30502 11.4053 2.63602 16.0033 4.38502 18.5333C5.24302 19.7653 6.25502 21.1513 7.59702 21.1073C8.88402 21.0523 9.36802 20.2713 10.93 20.2713C12.481 20.2713 12.921 21.1073 14.285 21.0743C15.671 21.0523 16.551 19.8203 17.398 18.5773C18.377 17.1473 18.784 15.7613 18.806 15.6843C18.773 15.6733 16.111 14.6503 16.078 11.5703C16.056 8.9963 18.179 7.7643 18.278 7.7093C17.068 5.9383 15.209 5.7403 14.56 5.6963C12.866 5.5643 11.447 6.6203 10.655 6.6203ZM13.515 4.0243C14.23 3.1663 14.703 1.9673 14.571 0.779297C13.548 0.823297 12.316 1.4613 11.579 2.3193C10.919 3.0783 10.347 4.2993 10.501 5.4653C11.634 5.5533 12.8 4.8823 13.515 4.0243Z"
        fill="#000"
      />
    </svg>
  );
}

export default AppleIcon;
