import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPatientPaymentMethodsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPatientPaymentMethods = (
  { __typename: 'Query' }
  & { patientPaymentMethods: (
    { __typename: 'PatientPaymentMethodsPayload' }
    & Pick<Types.PatientPaymentMethodsPayload, 'defaultPaymentMethod'>
    & { paymentMethods: Array<(
      { __typename: 'PatientPaymentMethodModel' }
      & PatientPaymentMethodFragment
    )> }
  ) }
);

export type PatientPaymentMethodFragment = (
  { __typename: 'PatientPaymentMethodModel' }
  & Pick<Types.PatientPaymentMethodModel, 'id' | 'type' | 'brand' | 'last4' | 'country' | 'expMonth' | 'expYear'>
);

export const PatientPaymentMethodFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PatientPaymentMethodFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PatientPaymentMethodModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"brand"}},{"kind":"Field","name":{"kind":"Name","value":"last4"}},{"kind":"Field","name":{"kind":"Name","value":"country"}},{"kind":"Field","name":{"kind":"Name","value":"expMonth"}},{"kind":"Field","name":{"kind":"Name","value":"expYear"}}]}}]} as unknown as DocumentNode;
export const GetPatientPaymentMethodsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPatientPaymentMethods"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientPaymentMethods"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paymentMethods"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PatientPaymentMethodFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"defaultPaymentMethod"}}]}}]}},...PatientPaymentMethodFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetPatientPaymentMethods__
 *
 * To run a query within a React component, call `useGetPatientPaymentMethods` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientPaymentMethods` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientPaymentMethods({
 *   variables: {
 *   },
 * });
 */
export function useGetPatientPaymentMethods(baseOptions?: Apollo.QueryHookOptions<GetPatientPaymentMethods, GetPatientPaymentMethodsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientPaymentMethods, GetPatientPaymentMethodsVariables>(GetPatientPaymentMethodsDocument, options);
      }
export function useGetPatientPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientPaymentMethods, GetPatientPaymentMethodsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientPaymentMethods, GetPatientPaymentMethodsVariables>(GetPatientPaymentMethodsDocument, options);
        }
export type GetPatientPaymentMethodsHookResult = ReturnType<typeof useGetPatientPaymentMethods>;
export type GetPatientPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPatientPaymentMethodsLazyQuery>;
export type GetPatientPaymentMethodsQueryResult = Apollo.QueryResult<GetPatientPaymentMethods, GetPatientPaymentMethodsVariables>;