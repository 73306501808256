import React, { useRef, useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { DownloadIconSecond, ThreeDotsIcon, TrashSecondaryIcon } from 'ui';
import { useClickOutside } from 'hooks';

export interface NotesButtonsProps {
  className?: string;
  title?: string;
  onDeleteClick: () => void;
  downloadLink?: string;
  onAddClick?: () => void;
  onlyAdd?: boolean;
  downloadText?: string;
  deleteText?: string;
  disabled?: boolean;
  onlyDelete?: boolean;
  modalCSS?: CSSProp;
  isTherapist?: boolean;
  isShared?: boolean;
  isHeaderButtons?: boolean;
}

function NotesButtons({
  className,
  title,
  onDeleteClick,
  downloadLink,
  downloadText,
  deleteText,
  onlyDelete = false,
  disabled = false,
  modalCSS,
  onAddClick,
  isTherapist,
  isShared,
  isHeaderButtons,
}: NotesButtonsProps) {
  const [visible, setVisible] = useState(false);

  const modalRef = useRef(null);
  useClickOutside(modalRef, { onClick: () => setVisible(false) });

  const showMenu = () => setVisible(true);

  return (
    <Wrapper
      className={className}
      ref={modalRef}
      $isTherapist={isHeaderButtons}
    >
      {isHeaderButtons && (
        <DeleteButton onClick={onDeleteClick}>
          <Trash />
          {deleteText ? deleteText : 'Delete all'}
        </DeleteButton>
      )}
      {isHeaderButtons && (
        <DownloadButton href={downloadLink} download target="_blank">
          <Download />
          {downloadText ? downloadText : 'Download'}
        </DownloadButton>
      )}

      {isHeaderButtons && (
        <AddButton onClick={onAddClick}>+ Add note</AddButton>
      )}
      {!isHeaderButtons &&
        (title ? (
          <MoreButtonWithTitle onClick={showMenu} disabled={disabled}>
            {title} <MoreIcon />
          </MoreButtonWithTitle>
        ) : (
          <MoreButton onClick={showMenu}>
            <MoreIcon />
          </MoreButton>
        ))}
      {visible && !isHeaderButtons && (
        <DropDownBody $CSS={modalCSS}>
          {onlyDelete === false && (
            <ButtonLink href={downloadLink} download target="_blank">
              <Download />
              {downloadText ? downloadText : 'Download'}
            </ButtonLink>
          )}
          {(!isTherapist || (!isShared && isTherapist)) && (
            <DeleteButton onClick={onDeleteClick}>
              <Trash />
              {deleteText ? deleteText : 'Delete'}
            </DeleteButton>
          )}
        </DropDownBody>
      )}
    </Wrapper>
  );
}

interface WrapperProps {
  $isTherapist?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: ${({ $isTherapist }) => ($isTherapist ? 'grid' : 'flex')};
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${({ $isTherapist }) => ($isTherapist ? '22px' : '0')};
  align-items: center;
  position: relative;
`;

const ButtonLink = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);
  transition: 0.3s ease-out;
  margin-bottom: 18px;

  &:hover {
    opacity: 0.7;
  }
`;

const DownloadButton = styled(ButtonLink)`
  margin: 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.7;
  }
`;

const MoreButtonWithTitle = styled.button`
  font-family: Poppins;
  font-size: ${getSize(13)};
  font-weight: 500;
  line-height: ${getSize(24)};
  color: var(--black3);
  width: 63px;
  height: 26px;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
`;

const MoreButton = styled.button`
  font-family: Poppins;
  font-size: ${getSize(13)};
  font-weight: 500;
  line-height: ${getSize(24)};
  color: var(--black3);
`;

const MoreIcon = styled(ThreeDotsIcon)`
  margin-left: 12px;
  flex-grow: 1;
`;

const DropDownBody = styled.div<{ $CSS?: CSSProp }>`
  position: absolute;
  left: ${getSize(-80)};
  top: 100%;
  width: ${getSize(150)};
  border-radius: ${getSize(8)};
  box-shadow: 0px ${getSize(20)} ${getSize(90)} rgba(40, 31, 61, 0.16);
  background-color: #fff;
  padding: ${getSize(12)} 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  width: 140px;
  padding: 23px 18px 21px 15px;
  ${({ $CSS }) => $CSS};
`;

const AddButton = styled(Button)``;

const DeleteButton = styled(Button)`
  color: var(--red);
  color: #e92323;
`;

const Trash = styled(TrashSecondaryIcon)`
  margin-right: 9px;
  width: ${getSize(16)};
  height: ${getSize(16)};
  fill: #e92323;
`;

const Download = styled(DownloadIconSecond)`
  margin: 0 ${getSize(9)} 0 0;
  width: ${getSize(13)};
  height: ${getSize(13)};
  fill: black;
`;

export default NotesButtons;
