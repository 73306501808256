import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

function ArrowRightIcon({ ...rest }) {
  return (
    <Icon
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 4.16668H11.8083L8.82082 1.17918L10 0L15 5L10 10L8.82082 8.82082L11.8083 5.83332H0V4.16668Z"
        fill={'#B6B6B6'}
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(15)};
  height: ${getSize(10)};
`;

export default ArrowRightIcon;
