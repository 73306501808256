import React from 'react';
import styled, { CSSProp } from 'styled-components';

import { getSize } from 'lib/utils';

import { PopUpMessage } from 'ui/pop-up-message';
import { AlertIcon } from 'ui/icons';

export interface ToltipProps {
  className?: string;
  text?: string;
  textCSS?: CSSProp;
  tooltipWrapperCSS?: CSSProp;
}

function Toltip({ className, text, textCSS, tooltipWrapperCSS }: ToltipProps) {
  if (!text) return null;

  return (
    <Wrapper className={className} $CSS={tooltipWrapperCSS}>
      <AlertIconStylized />

      {text && (
        <PopUpMessage className="tooltip-message" rootCSS={textCSS}>
          {text}
        </PopUpMessage>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  position: relative;

  ${({ $CSS }) => $CSS}
`;

const AlertIconStylized = styled(AlertIcon)`
  width: ${getSize(15)};
  height: ${getSize(15)};
  stroke: var(--gray2);
  cursor: pointer;

  &:hover ~ {
    .tooltip-message {
      display: block;
      animation: showTooltip 0.3s ease-out forwards;
    }
  }
`;

export default Toltip;
