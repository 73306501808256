import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { WorksheetsIcon, RecordingsIcon, MaterialDocument } from 'ui';
import { ControlDropdown } from 'features/common/control-dropdown';
import { FileExtension, FileType, MaterialTag } from '__generated__/types';
import { amplitudeService } from 'lib/amplitude';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useReadMaterial } from 'common/mutation/__generated__/read-material';
import { useApolloClient } from '@apollo/client';

dayjs.extend(relativeTime);

const { Pdf, Jpeg, Png, Mp4, Jpg, Doc, Docx, Mp3, M4A } = FileExtension;
const FILE_EXTENSION_ICONS = {
  [Pdf]: MaterialDocument,
  [Jpeg]: WorksheetsIcon,
  [Png]: WorksheetsIcon,
  [Mp4]: RecordingsIcon,
  [Mp3]: RecordingsIcon,
  [Jpg]: WorksheetsIcon,
  [Doc]: WorksheetsIcon,
  [Docx]: WorksheetsIcon,
  [M4A]: RecordingsIcon,
};
export interface Material {
  id: string;
  source: string;
  name: string;
  sizeInKb: number;
  extension: FileExtension;
  tag: MaterialTag;
  fileType: FileType;
  createdAt: string;
  isRead?: boolean;
  patientId?: string;
}
export interface MaterialItemProps {
  material: Material;
  isSmall?: boolean;
  isTherapist?: boolean;
  className?: string;
  isDropdownVisible: boolean;
  onDropdownTriggerClick: () => void;
  setIsVisible: React.Dispatch<React.SetStateAction<string>>;
  onDeleteClick?: () => void;
  onPreview: () => void;
  patientId?: string;
  isBubbleShow?: boolean;
  fetchMaterials?: () => void;
  fileNameLimit?: number;
}
function MaterialItem({
  className,
  isSmall,
  isTherapist,
  isDropdownVisible,
  onDeleteClick,
  onDropdownTriggerClick,
  onPreview,
  material,
  setIsVisible,
  patientId,
  isBubbleShow,
  fetchMaterials,
  fileNameLimit = 20,
}: MaterialItemProps) {
  const {
    id: fileId,
    source,
    name,
    sizeInKb,
    extension,
    tag,
    fileType,
    createdAt,
  } = material;

  const relativeCreatedTimeFromNow = dayjs(createdAt).fromNow();
  const [setReadMaterial] = useReadMaterial();

  const ExtensionIcon = FILE_EXTENSION_ICONS[extension] || null;

  const { cache } = useApolloClient();

  const onMaterialClick = () => {
    if (fetchMaterials) fetchMaterials();
    setReadMaterial({
      variables: { input: { id: fileId } },
    });
    cache.modify({
      id: cache.identify({
        __typename: 'MaterialModel',
        id: fileId,
      }),
      fields: {
        isRead: () => true,
      },
    });
    onPreview();
    // TODO: to use that event
    // amplitudeService.logEvent('material use', {
    //   action: 'open',
    //   role: isTherapist ? 'therapist' : 'patient',
    //   materialType: tag === MaterialTag.Diary ? 'INFO' : tag,
    //   patientId,
    //   fileType,
    //   sizeInKB: sizeInKb / 1000,
    //   extension,
    // });
  };

  const limitValue = (string: string) =>
    string.split('').splice(0, fileNameLimit).join('');

  return (
    <Wrapper className={className} $isSmall={isSmall} onClick={onMaterialClick}>
      <IconWrapper>
        <ExtensionIcon />
      </IconWrapper>

      <NameWrapper>
        <NameAndExtension>
          <MaterialName className="text-line-clamp-1">
            {limitValue(name)}
          </MaterialName>
          .{extension}
        </NameAndExtension>
      </NameWrapper>

      {isTherapist ? (
        <ControlDropdown
          isVisibleMenu={isDropdownVisible}
          onTriggerClick={onDropdownTriggerClick}
          downloadLink={source}
          setIsVisible={setIsVisible}
          onDeleteClick={() => onDeleteClick?.()}
        />
      ) : (
        <DownloadWrapper onClick={(e) => e.stopPropagation()}>
          <Bubble $isShow={isBubbleShow} />
          <Text>{relativeCreatedTimeFromNow}</Text>
        </DownloadWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.li<{ $isSmall?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${getSize(8)} ${({ $isSmall }) => ($isSmall ? 0 : getSize(32))};
  transition: 0.3s ease-out;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${getSize(20)} 0 0;
  width: ${getSize(64)};
  height: ${getSize(64)};
  border: ${getSize(1)} solid var(--purple3);
  border-radius: ${getSize(8)};
  background: #6c4fe7;
  flex-shrink: 0;
`;

const NameWrapper = styled.div`
  min-width: 0;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
`;

const NameAndExtension = styled.span`
  display: flex;
  flex-shrink: 1;
  min-width: 0;
  font-weight: 500;
  font-size: ${getSize(15)};
  line-height: ${getSize(24)};
  color: var(--black3);
`;

const MaterialName = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DownloadWrapper = styled.div`
  display: flex;
  margin: 0 0 0 auto;
  align-items: center;
`;

const Text = styled.span`
  font-family: Poppins;
  font-size: ${getSize(12)};
  font-weight: 500;
  line-height: ${getSize(24)};
  color: #b0b0b0;
`;

interface BubbleProps {
  $isShow?: boolean;
}

const Bubble = styled.div<BubbleProps>`
  width: ${getSize(10)};
  height: ${getSize(10)};
  border-radius: 50%;
  background: #7151fc;
  margin-right: ${getSize(18)};
  display: ${({ $isShow }) => ($isShow ? 'block' : 'none')};
`;

export default MaterialItem;
