import { PATIENT_ROUTES } from 'lib/routes';
import { LinkItem, NavigationLinkType } from 'components/navigation/navigation';
import { AppsIcon, MailIcon, UserSearchIcon } from 'ui';
import { AmplitudeEvents, amplitudeService } from 'lib/amplitude';

const { DASHBOARD, MESSAGING, FIND_SPECIALIST, RESOURCES, PERSONAL_WELLBEING } =
  PATIENT_ROUTES;

export const PATIENT_LINKS: LinkItem[] = [
  {
    href: DASHBOARD,
    text: 'Home',
    Icon: AppsIcon,
  },
  {
    href: FIND_SPECIALIST,
    text: 'Find a Therapist',
    Icon: UserSearchIcon,
    onClick: () => {
      amplitudeService.logEvent(AmplitudeEvents.FIND_MY);
    },
  },
  {
    href: MESSAGING.pathBuild(),
    text: 'Messaging',
    Icon: MailIcon,
    type: NavigationLinkType.MESSAGING,
    onClick: () => {
      amplitudeService.logEvent(AmplitudeEvents.MTA_MESSAGES);
    },
  },
];

export const B2B_PATIENT_LINKS: LinkItem[] = [
  {
    href: PERSONAL_WELLBEING,
    text: 'Wellbeing',
    Icon: UserSearchIcon,
  },
  {
    href: RESOURCES,
    text: 'Content',
    Icon: UserSearchIcon,
  },
  {
    href: DASHBOARD,
    text: 'Therapy',
    Icon: AppsIcon,
  },
];

export const PUBLIC_PATIENT_LINKS = [
  {
    href: FIND_SPECIALIST,
    text: 'Find a Therapist',
    Icon: UserSearchIcon,
  },
];
