import { API_DATE_FORMAT } from 'lib/constants/date';
import { TimeUtil } from 'lib/utils';

export interface GetTimeOptionsArgs {
  hasPastTime: boolean;
  day?: string;
  duration?: string;
}

const TEN_MINUTE = 10;
const MIN_MINUTES = 0;

export function getTimeOptions({
  hasPastTime,
  day,
  duration,
}: GetTimeOptionsArgs) {
  const nowDay = TimeUtil.now();
  const options = [];

  const maxMinutes = 24 * 60 - (duration ? Number(duration) : 80);

  for (
    let currentMinutes = MIN_MINUTES;
    currentMinutes <= maxMinutes;
    currentMinutes += TEN_MINUTE
  ) {
    const currentDate =
      day && TimeUtil.parse(day, API_DATE_FORMAT).minute(currentMinutes);

    if (currentDate && (hasPastTime || currentDate > nowDay)) {
      const hoursAndMinutes = currentDate.format('HH:mm');

      options.push({
        value: hoursAndMinutes,
        label: hoursAndMinutes,
      });
    }
  }

  return options;
}

export const capitalizeText = (text: string): string => {
  return text ? text[0].toUpperCase() + text.slice(1).toLowerCase() : text;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const enumToOptions = <T extends object>(inputEnum: T) => {
  const resp = Object.keys(inputEnum).map<{
    value: string;
    label: string;
  }>((i) => ({
    value: i,
    label: capitalizeText(i.split('_').join(' ')),
  }));

  return resp;
};
