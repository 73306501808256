import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

const RoundCheckIcon = ({ ...rest }) => {
  return (
    <Icon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.69526 19.3778C15.0498 19.3778 19.3905 15.0399 19.3905 9.68889C19.3905 4.33786 15.0498 0 9.69526 0C4.34071 0 0 4.33786 0 9.68889C0 15.0399 4.34071 19.3778 9.69526 19.3778Z"
        fill="#6B4EE6"
      />
      <path
        d="M7.67847 14.503L4.17492 9.86709L5.86685 8.5887L8.01831 11.4355L14.241 6.16239L15.6123 7.78011L7.67847 14.503Z"
        fill="white"
      />
    </Icon>
  );
};

const Icon = styled.svg`
  width: ${getSize(20)};
  height: ${getSize(20)};
`;

export default RoundCheckIcon;
