import { getSize } from 'lib/utils';
import React, { ReactNode, RefObject } from 'react';
import styled from 'styled-components';

export type Element = 'div' | 'section' | 'li';
export interface ShadowWrapperProps {
  className?: string;
  children: ReactNode;
  asElement?: Element;
  rootRef?: RefObject<HTMLElement>;
  onClick?: (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => void;
}

function ShadowWrapper({
  className,
  children,
  asElement,
  rootRef,
  onClick,
}: ShadowWrapperProps) {
  return (
    <Wrapper
      ref={rootRef}
      as={asElement}
      className={className}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: ${getSize(40)};
  box-shadow: 0 ${getSize(10)} ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
  background: white;
`;

export default ShadowWrapper;
