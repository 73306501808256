import React from 'react';

function TriangleIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#13101F"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path d="M11.293 9.04a1 1 0 011.414 0l3.44 3.44a.5.5 0 01-.354.854H8.207a.5.5 0 01-.353-.854l3.439-3.44z" />
    </svg>
  );
}

export default TriangleIcon;
