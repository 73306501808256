import { getSize, StorageService } from 'lib/utils';
import { IOnboardingStep } from 'lib/utils/storage-service';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';
import { ShadowWrapper } from 'ui/shadow-wrapper';
import { ProgressList } from './components/progress-list';

export enum PageName {
  PERSONAL_INFO = 'personal-info',
  QUALIFICATIONS = 'qualifications-and-credentials',
  APPROACH = 'approach-and-experience',
  PROFILE = 'profile',
  COMMUNITY = 'community',
}

export const PAGES = {
  'personal-info': 'Personal Information',
  'qualifications-and-credentials': 'Qualifications & Credentials',
  'approach-and-experience': 'Approach & Experience',
  profile: 'Profile',
  community: 'Community',
};
export interface OnboardingWrapperProps {
  children: ReactNode;
  title: string;
  className?: string;
  titleCSS?: CSSProp;
  titleTextCSS?: CSSProp;
  innerCSS?: CSSProp;
  currentPageName: PageName;
}
const allPagesCount = Object.keys(PAGES).length;
const currentPages = Object.values(PAGES);

function OnboardingWrapper({
  children,
  title,
  className,
  titleCSS,
  titleTextCSS,
  innerCSS,
  currentPageName,
}: OnboardingWrapperProps) {
  const history = useHistory();

  const savedStep: IOnboardingStep | undefined = useMemo(() => {
    const savedStepJSON = StorageService.getOnboardingStep();

    return savedStepJSON && JSON.parse(savedStepJSON);
  }, []);

  const currentPageCount = useMemo(
    () => Object.keys(PAGES).indexOf(savedStep?.currentStep || currentPageName),
    [currentPageName, savedStep],
  );

  useEffect(() => {
    if (savedStep?.stepUrl) {
      history.push(savedStep.stepUrl);
    }
  }, [savedStep, history]);

  return (
    <Wrapper>
      <ProgressList currentPageCount={currentPageCount} pages={currentPages} />
      <Inner asElement="section" className={className} $CSS={innerCSS}>
        <Header $CSS={titleCSS}>
          <Title $CSS={titleTextCSS}>{title}</Title>
          <PageCount>{`${currentPageCount + 1}/${allPagesCount}`}</PageCount>
        </Header>
        {children}
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSize(20)} ${getSize(123)} ${getSize(50)};
`;

const Inner = styled(ShadowWrapper)<{ $CSS?: CSSProp }>`
  margin: 0 auto;
  padding: ${getSize(34)} ${getSize(32)} ${getSize(34)};
  width: ${getSize(642)};

  ${({ $CSS }) => $CSS}
`;

const Header = styled.header<{ $CSS?: CSSProp }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${getSize(24)};

  ${({ $CSS }) => $CSS}
`;

const PageCount = styled.p`
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
`;

const Title = styled.h1<{ $CSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(18)};
  line-height: ${getSize(32)};
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

export default OnboardingWrapper;
