import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Layout for page
import { ResourcesLayout } from '../layout';
import { TherapyBanner } from '../components/therapy-banner';

// Custom components
import Content from './content';
import { CategoryNavigation } from './components/category-navigation';
import { CategorySubcategoryBreadcrumbs } from '../components/category-subcategory-breadcrumbs';

// Query imports
import { useGetPostsBySubCategory } from '../query/__generated__/get-posts-by-sub-category';
import { useGetCategoriesAndSubcategories } from '../query/__generated__/get-categories-and-subcategories';
import { useGetPostsAndSubcategoriesByCategory } from '../query/__generated__/get-posts-and-subcategories-by-category';
import { PATIENT_RESOURCES_ROUTES } from '../../../../lib/routes';
import { TeamsAppContext } from '../../../../hooks/use-teams-app';

const AllContent = () => {
  /** Route navigation data */
  const { categoryId, subcategoryId } = useParams<{
    categoryId: string;
    subcategoryId: string;
  }>();

  /** State data */
  const [activeCategory, setActiveCategory] = useState<any>(null);
  const history = useHistory();
  /** Fetch categories */
  const { data: categoriesData, loading: categoriesLoading } =
    useGetCategoriesAndSubcategories();
  const categories = categoriesData?.getCategoriesAndSubcategories;
  const isTeamsApp = useContext(TeamsAppContext);

  /** Fetch subcategories that belongs to a category with preview of 3 latest posts */
  const {
    data: subcategoriesWithPostPreviewData,
    loading: loadingSubCategoriesWithPostPreview,
  } = useGetPostsAndSubcategoriesByCategory({
    variables: {
      categoryId: categoryId,
    },
    skip: !categoryId,
  });
  const subcategoriesWithPostPreview =
    subcategoriesWithPostPreviewData?.getPostsAndSubcategoriesByCategory;

  /** Fetch posts of subcategory */
  const { data: postsData, loading: postsLoading } = useGetPostsBySubCategory({
    variables: {
      subCategoryIds: [subcategoryId],
    },
    skip: !subcategoryId,
  });
  const posts = postsData?.getPostsBySubCategory;

  /** Set active category */
  useEffect(() => {
    const newActiveCategory = categories?.find(
      (category) => category.id === categoryId,
    );

    if (!newActiveCategory && categories) {
      history.push(
        PATIENT_RESOURCES_ROUTES.ALL_CONTENT.pathBuild(categories[0].id),
      );
    }

    setActiveCategory(newActiveCategory);
  }, [categoryId, categories]);

  return (
    <ResourcesLayout>
      <TherapyBanner />
      <CategoryNavigation
        categories={categories}
        activeCategoryId={categoryId}
        categoriesLoading={categoriesLoading}
      />

      {/* Category - Subcategory breadcrumbs */}
      <CategorySubcategoryBreadcrumbs
        activeCategory={activeCategory}
        activeCategoryId={categoryId}
        activeSubcategoryId={subcategoryId}
      />

      {/* Display Content */}
      <Content
        posts={posts}
        postsLoading={postsLoading}
        activeCategory={activeCategory}
        activeSubcategoryId={subcategoryId}
        subcategoriesWithPostPreview={subcategoriesWithPostPreview}
        loadingSubCategoriesWithPostPreview={
          loadingSubCategoriesWithPostPreview
        }
      />
    </ResourcesLayout>
  );
};

export default AllContent;
