import React, { useEffect, useMemo, useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import { FieldValidator } from 'final-form';
import { Field } from 'react-final-form';
import { ScrollingRadioButton } from './components/scrolling-radio-button';
import { getSize } from 'lib/utils';

export interface Option {
  label: string;
  value: string;
}
export interface ScrollingRadioGroupProps {
  name: string;
  label?: string;
  buttonCSS?: CSSProp;
  contentCSS?: CSSProp;
  disabled?: boolean;
  options: Option[];
  className?: string;
  validate?: FieldValidator<string>;
  value: string;
}
export interface InnerProps {
  $CSS?: CSSProp;
  $itemCount: number;
  $activeItemIndex?: number;
}

function ScrollingRadioGroup({
  name,
  label,
  buttonCSS,
  disabled,
  options = [],
  contentCSS,
  className,
  validate,
  value,
}: ScrollingRadioGroupProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');
  const [activeItemIndex, setActiveItemIndex] = useState<number>();

  const activeValueIndex = useMemo(
    () => options.findIndex(({ value: optionValue }) => optionValue === value),
    [options, value],
  );

  useEffect(() => {
    setActiveItemIndex(activeValueIndex);
  }, [activeValueIndex]);

  return (
    <div className={className}>
      {label && <Label>{label}</Label>}

      <Inner
        $CSS={contentCSS}
        $itemCount={options.length}
        $activeItemIndex={activeItemIndex}
      >
        {options.map(({ value, label }) => (
          <Field
            key={value}
            name={name}
            type="radio"
            value={value}
            validate={validate}
            render={(props) => (
              <ScrollingRadioButton
                {...props}
                label={label}
                buttonCSS={buttonCSS}
                disabled={disabled}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
              />
            )}
          />
        ))}
      </Inner>

      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </div>
  );
}

const Label = styled.span`
  display: block;
  width: 100%;
  margin: 0 0 ${getSize(10)};
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
`;

const Inner = styled.div<InnerProps>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${({ $itemCount }) => $itemCount}, 1fr);
  width: 100%;
  background: var(--pink2);
  border-radius: ${getSize(10)};

  &::before {
    ${({ $activeItemIndex }) =>
      Number($activeItemIndex) >= 0 ? 'content: "";' : ''}
    position: absolute;
    top: ${getSize(2)};
    left: ${getSize(2)};
    width: calc(100% / ${({ $itemCount }) => $itemCount} - ${getSize(4)});
    height: calc(100% - ${getSize(4)});
    border-radius: ${getSize(10)};
    background: var(--purple3);
    transition: 0.3s ease-out;
    transform: translateX(
      calc(
        ${({ $activeItemIndex }) =>
          `100% * ${$activeItemIndex} + ${getSize(
            Number($activeItemIndex) * 4,
          )}`}
      )
    );
  }

  ${({ $CSS }) => $CSS}
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default ScrollingRadioGroup;
