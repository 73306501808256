import React from 'react';

function InsuranceIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.53859 0.000132023C9.49285 0.00252548 9.44813 0.014266 9.40749 0.0354481C9.40749 0.0354481 5.64714 1.91112 0.957923 2.53635C0.800379 2.55813 0.683411 2.69334 0.684579 2.85237V9.49924C0.684579 13.5712 2.91389 15.9865 5.11189 17.3298C7.30989 18.673 9.50334 18.9964 9.50334 18.9964C9.53449 19.0012 9.56618 19.0012 9.59734 18.9964C9.59734 18.9964 11.7908 18.673 13.9888 17.3298C16.1868 15.9865 18.4161 13.5712 18.4161 9.49924V2.85237C18.4174 2.69334 18.3003 2.55811 18.1428 2.53635C13.4536 1.91112 9.6932 0.0354481 9.6932 0.0354481C9.64549 0.0107955 9.59229 -0.00142372 9.53859 0.000132023H9.53859ZM9.55032 3.80101C12.6943 3.80101 15.2485 6.35522 15.2485 9.49917C15.2485 12.6431 12.6943 15.2005 9.55032 15.2005C6.40637 15.2005 3.85214 12.6431 3.85214 9.49917C3.85214 6.35522 6.40637 3.80101 9.55032 3.80101ZM8.28316 6.65011V8.23513H6.70123C6.52636 8.23449 6.38406 8.37566 6.38336 8.55053V10.4509C6.38399 10.6258 6.52636 10.767 6.70123 10.7663H8.28316V12.3514C8.2838 12.5265 8.42655 12.6678 8.60165 12.6668H10.499C10.6739 12.6674 10.8162 12.5262 10.8169 12.3514V10.7663H12.3994C12.5743 10.767 12.7166 10.6258 12.7173 10.4509V8.55053C12.7166 8.37566 12.5743 8.23446 12.3994 8.23513H10.8169V6.65011C10.8162 6.47524 10.6739 6.33404 10.499 6.33471H8.58526C8.40666 6.35062 8.28303 6.48711 8.28316 6.65011H8.28316Z"
        fill="#6B4EE6"
      />
    </svg>
  );
}

export default InsuranceIcon;
