import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Field, Form as FinalForm } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import {
  composeValidators,
  DecodeTokenService,
  getSize,
  StorageService,
  validationRules,
} from 'lib/utils';
import { resetPassword } from 'lib/auth';
import { notifyError } from 'lib/utils/notification';

import { Input, Button } from 'ui';
import { AUTH_ROUTES } from 'lib/routes';
import { AuthContentLayout } from 'layouts';

const ResetPassword = () => {
  const userEmail = useMemo(() => {
    const accessToken = StorageService.getAccessToken();

    return DecodeTokenService.getUserEmail(accessToken);
  }, []);

  const history = useHistory();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const handleFormSubmit = useCallback(
    ({ email }: ResetPassFormValues) => {
      setIsDataLoading(true);
      resetPassword(email)
        .then(() =>
          userEmail
            ? history.goBack()
            : history.push(AUTH_ROUTES.CHECK_EMAIL.pathBuild(email)),
        )
        .catch(() => notifyError({ text: 'Network error' }))
        .finally(() => setIsDataLoading(false));
    },
    [history, userEmail],
  );

  return (
    <AuthContentLayout title="Reset your password">
      <FinalForm
        onSubmit={handleFormSubmit}
        initialValues={{ email: userEmail }}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <ResetMessage>
              To reset your password, enter the email address you provided when
              you created your account.
            </ResetMessage>

            <Field
              name="email"
              label="Email"
              rootCSS={ROOT_CSS}
              inputCSS={INPUT_CSS}
              labelCSS={LABEL_CSS}
              component={Input}
              validate={composeValidators(
                validationRules.required,
                validationRules.email,
              )}
              placeholder="Your email"
            />

            <ButtonWrapper>
              <Button isLoading={isDataLoading} theme="tertiary">
                RESET PASSWORD
              </Button>
              <StyledButton
                type="button"
                onClick={() =>
                  userEmail
                    ? history.goBack()
                    : history.push(AUTH_ROUTES.SIGN_IN)
                }
              >
                Back
              </StyledButton>
            </ButtonWrapper>
          </Form>
        )}
      />
    </AuthContentLayout>
  );
};

export interface ResetPassFormValues {
  email: string;
}

const ROOT_CSS = {
  marginBottom: getSize(31),
};

const LABEL_CSS = {
  lineHeight: getSize(18),
  fontSize: getSize(11),
};

const INPUT_CSS = {
  '&::placeholder': {
    lineHeight: getSize(24),
    fontSize: getSize(13),
  },
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Message = styled.p`
  margin-bottom: ${getSize(20)};
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--gray2);
`;

const ResetMessage = styled(Message)`
  max-width: ${getSize(381)};
  font-weight: 500;
  font-size: ${getSize(14)};
  line-height: ${getSize(21)};
  margin-bottom: ${getSize(25)};
  color: var(--gray9);
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: ${getSize(5)};
`;

const StyledButton = styled.button`
  padding: 0;
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  font-weight: normal;
  border: 0;
  color: var(--black3);
  cursor: pointer;
  width: fit-content;
  justify-self: center;
`;

export default ResetPassword;
