import React from 'react';

function FoldedCalendarIcon({ ...rest }) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.3047 1.79688H19.2266V0H17.4297V1.79688H5.57031V0H3.77344V1.79688H2.69531C1.20912 1.79688 0 3.00599 0 4.49219V20.3047C0 21.7909 1.20912 23 2.69531 23H15.4759L23 15.1865V4.49219C23 3.00599 21.7909 1.79688 20.3047 1.79688ZM15.9922 19.8733V16.6211C15.9922 16.1257 16.3952 15.7227 16.8906 15.7227H19.9891L15.9922 19.8733ZM21.2031 13.9258H16.8906C15.4044 13.9258 14.1953 15.1349 14.1953 16.6211V21.2031H2.69531C2.19991 21.2031 1.79688 20.8001 1.79688 20.3047V8.44531H21.2031V13.9258ZM21.2031 6.64844H1.79688V4.49219C1.79688 3.99679 2.19991 3.59375 2.69531 3.59375H3.77344V5.39062H5.57031V3.59375H17.4297V5.39062H19.2266V3.59375H20.3047C20.8001 3.59375 21.2031 3.99679 21.2031 4.49219V6.64844Z"
        fill="#EBEBEB"
      />
    </svg>
  );
}

export default FoldedCalendarIcon;
