import React, { FC } from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { GoogleIcon, FacebookIcon, AppleIcon } from 'ui';
import { BaseStyleProps } from 'types';

const FACEBOOK_BUTTON_CSS = {
  'padding-left': `${getSize(15)}`,
};

const AuthWithSocials: FC<AuthWithSocialsProps> = ({
  onGoogleClick,
  onFacebookClick,
  onAppleClick,
  rootCSS,
  separatorText = 'or sign in with',
  className,
}) => {
  return (
    <Wrapper $CSS={rootCSS} className={className}>
      <SeparatorWrapper>
        <Line />
        <Text>{separatorText}</Text>
        <Line />
      </SeparatorWrapper>

      <Socials>
        <SocialButton onClick={onGoogleClick}>
          <GoogleIcon className="social-icon__svg" />
          Google
        </SocialButton>

        <SocialButton onClick={onFacebookClick} $CSS={FACEBOOK_BUTTON_CSS}>
          <FacebookIcon className="social-icon__svg" />
          Facebook
        </SocialButton>

        <SocialButton onClick={onAppleClick}>
          <AppleIcon className="social-icon__svg" />
          Apple
        </SocialButton>
      </Socials>
    </Wrapper>
  );
};

export interface AuthWithSocialsProps extends BaseStyleProps {
  onGoogleClick: () => void;
  onFacebookClick: () => void;
  onAppleClick: () => void;
  rootCSS?: CSSProp;
  separatorText?: string;
}

const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ $CSS }) => $CSS}
`;

const Text = styled.p`
  width: 100%;
  font-size: ${getSize(14)};
  line-height: ${getSize(21)};
  color: var(--gray9);
  text-align: center;
  white-space: nowrap;
`;

const Socials = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${getSize(16)};
`;

const SocialButton = styled.button<{ $CSS?: CSSProp }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${getSize(30)};
  border: ${getSize(1)} solid var(--gray11);
  border-radius: 29px;
  background: var(--gray11);
  position: relative;
  color: var(--black3);
  background-color: white;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  font-weight: 400;

  & > .social-icon__svg {
    width: ${getSize(15)};
    height: ${getSize(15)};
    position: absolute;
    left: ${getSize(8)};
    justify-self: center;
  }

  ${({ $CSS }) => $CSS}
`;

const Line = styled.div`
  width: 100%;
  height: ${getSize(1)};
  background-color: var(--gray10);
`;

const SeparatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${getSize(17)};
`;

export default AuthWithSocials;
