import { InMemoryCacheConfig } from '@apollo/client';
import { relayStylePagination } from '../utils/pagination-helper';
import { relayStylePagination as relayStyleChatMessagesPagination } from '@apollo/client/utilities';
import { FirstLoginType } from '__generated__/types';

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    AvailabilityIntervalModel: {
      keyFields: false,
    },
    SessionModel: {
      keyFields: false,
    },
    MessageEdge: {
      keyFields: false,
    },
    PaymentConnectionModel: {
      keyFields: false,
    },
    TherapistTreatmentAreaModel: {
      keyFields: false,
    },
    TherapistTherapyApproachModel: {
      keyFields: false,
    },
    TherapistPublicModel: {
      keyFields: false,
    },
    UserModel: {
      fields: {
        firstLogin: {
          // prevent override status of initial login for b2b user
          merge(prevValue, newValue) {
            if (prevValue === FirstLoginType.FirstLogin) {
              return prevValue;
            }

            return newValue;
          },
        },
      },
    },
    Query: {
      fields: {
        therapistSearch: relayStylePagination(),
        therapistAvailability: {
          keyArgs: ['availabilityFilter', 'therapistId'],
        },
        therapistSessions: {
          keyArgs: ['sessionFilter'],
        },
        patientGetNotes: relayStylePagination(['filter']),
        therapistGetPatientNotes: relayStylePagination(['filter']),
        patientPastSessions: relayStylePagination(['filter', ['first']]),
        patientUpcomingSessions: relayStylePagination(['filter', ['first']]),
        patientPayments: relayStylePagination(['first']),
        therapistGetPatientMaterials: relayStylePagination(['filter']),
        therapistUpcomingSessionsByPatientId: relayStylePagination([
          'filter',
          ['first'],
          'patientId',
        ]),
        therapistPastSessionsByPatientId: relayStylePagination([
          'filter',
          ['first'],
          'patientId',
        ]),
        chatMessages: relayStyleChatMessagesPagination(['chatId']),
        listChats: relayStylePagination(['limit']),
      },
    },
  },
};
