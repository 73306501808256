import React from 'react';

function FolderIcon({ ...rest }) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="#444752" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.667 2.667A.667.667 0 002 3.334v9.333a.667.667 0 00.667.667h10.666a.666.666 0 00.667-.667V5.334a.667.667 0 00-.667-.667h-6a.667.667 0 01-.554-.297L5.643 2.667H2.667zm-1.414-.748a2 2 0 011.414-.586H6c.223 0 .431.112.555.297L7.69 3.334h5.643a2 2 0 012 2v7.333a2 2 0 01-2 2H2.667a2 2 0 01-2-2V3.333a2 2 0 01.586-1.414z"
      />
    </svg>
  );
}

export default FolderIcon;
