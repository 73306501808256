export const ROOT_ROUTES = {
  HOME: '/',
  AUTH: '/auth',
  PATIENT: '/patient',
  THERAPIST: '/therapist',
  ONBOARDING: '/onboarding',
  NOT_FOUND: '/not-found',
  NOT_MATCH_ROLE: '/not-match-role',
  EMAIL_CHANGED: '/email-change/:status',
  SANDBOX: '/sandbox', // Route used for dev testing
};

export const AUTH_ROUTES = {
  SIGN_IN: '/auth/sign-in',
  SIGN_UP: {
    PATH: '/auth/sign-up/:role',
    pathBuild: (role: string) => `/auth/sign-up/${role}`,
  },
  SIGN_UP_B2B: {
    PATH: '/auth/sign-up/b2b/:role',
    pathBuild: (role: string) => `/auth/sign-up/b2b/${role}`,
  },
  SIGN_UP_B2B_USER: '/auth/sign-up/b2b/user',
  RESET_PASSWORD: '/auth/reset-password',
  CALLBACK: '/auth/callback',
  WELCOME: '/auth/welcome',
  SUBSCRIPTION_SUCCESS: '/auth/subscription-success',
  CHECK_EMAIL: {
    PATH: '/auth/check-email/:emailForPasswordReset',
    pathBuild: (emailForPasswordReset: string) =>
      `/auth/check-email/${emailForPasswordReset}`,
  },
};

export const PATIENT_ROUTES = {
  DASHBOARD: '/patient/dashboard',
  MESSAGING: {
    PATH: '/patient/messaging/:chatId?',
    pathBuild: (chatId?: string | null) => `/patient/messaging/${chatId || ''}`,
  },
  ACCOUNT_SETTINGS: '/patient/account-settings',
  FIND_SPECIALIST: '/patient/find-specialist/',
  GUIDED_MATCHING: '/patient/guided-matching',
  RECOMMENDATION: '/patient/b2b/recommendation',
  UNAUTHORIZED: '/patient/unauthorized',
  RESOURCES: '/patient/resources',
  FIND_YOUR_PATH: '/patient/find-your-path',
  COMPANY_DASHBOARD: '/patient/company-dashboard',
  PERSONAL_WELLBEING: '/patient/wellbeing',
  PMI_SELECTION: '/patient/pmi-select',
  COMPANY_SLACK_WORKSPACE_ONBOARDING:
    '/patient/company/slack-workspace-onboarding',
};

/** TODO: all as content popularity tag is hardcoded */
export const PATIENT_RESOURCES_ROUTES = {
  DISCOVER: {
    PATH: '/patient/resources/discover/:subPath?',
    pathBuild: (subPath?: 'featured' | 'videos' | 'articles' | null) =>
      `/patient/resources/discover/${subPath || 'featured'}`,
  },
  ALL_CONTENT: {
    PATHS: [
      '/patient/resources/all-content/',
      '/patient/resources/all-content/category',
      '/patient/resources/all-content/category/:categoryId?',
      '/patient/resources/all-content/category/:categoryId?/subcategory',
      '/patient/resources/all-content/category/:categoryId?/subcategory/:subcategoryId',
    ],
    pathBuild: (categoryId?: string | null, subcategoryId?: string | null) => {
      if (categoryId && subcategoryId) {
        return `/patient/resources/all-content/category/${categoryId}/subcategory/${subcategoryId}`;
      } else if (categoryId) {
        return `/patient/resources/all-content/category/${categoryId}`;
      }

      return `/patient/resources/all-content`;
    },
  },
  CHOSE_CONTENT: '/patient/resources/chose-content',
  CONTENT_PREVIEW: {
    PATH: '/patient/resources/all-content/preview/:postId?',
    pathBuild: (postId?: string | null) =>
      `/patient/resources/all-content/preview/${postId || ''}`,
  },
};

export const PATIENT_B2B_ROUTES = {
  RECOMMENDATION: '/b2b/patient/recommendation',
};

export const PATIENT_DASHBOARD_ROUTES = {
  SESSIONS: '/patient/dashboard/sessions',
  MATERIALS: {
    PATH: '/patient/dashboard/materials',
    pathBuild: ({
      sessionMetaId,
    }: {
      patientId: string;
      sessionMetaId?: string;
    }) => `/patient/dashboard/materials/${sessionMetaId || ''}`,
  },
  RESCHEDULE_SESSION: {
    PATH: '/patient/dashboard/:sessionId/:therapistId/:startSessionTime/reschedule-session/',
    pathBuild: (
      sessionId: string,
      therapistId: string,
      startSessionTime: string,
    ) =>
      `/patient/dashboard/${sessionId}/${therapistId}/${startSessionTime}/reschedule-session/`,
  },
  NOTES: {
    PATH: '/patient/dashboard/notes/:sessionMetaId?',
    pathBuild: (sessionMetaId?: string) => {
      return `/patient/dashboard/notes/${sessionMetaId || ''}`;
    },
  },
  OUTCOME_MEASURES: {
    PATH: '/patient/dashboard/outcome-measures/:outcomeMeasureId/:codeId/:done?',
    pathBuild: ({
      outcomeMeasureId,
      codeId,
      isDone,
    }: {
      outcomeMeasureId: string;
      codeId: string;
      isDone?: boolean;
    }) =>
      `/patient/dashboard/outcome-measures/${outcomeMeasureId}/${codeId}${
        isDone ? '/done' : ''
      }`,
  },
  GOALS: '/patient/dashboard/goals/',
};

const BOOKING_PAYMENTS_ROUTES = {
  PATH: `/patient/find-specialist/:therapistId/booking/payment`,
  pathBuild: (therapistId: string) =>
    `/patient/find-specialist/${therapistId}/booking/payment`,
  CARD: {
    PATH: `/patient/find-specialist/:therapistId/booking/payment/card`,
    pathBuild: (therapistId: string) =>
      `/patient/find-specialist/${therapistId}/booking/payment/card`,
  },
  VOUCHER: {
    PATH: `/patient/find-specialist/:therapistId/booking/payment/voucher`,
    pathBuild: (therapistId: string) =>
      `/patient/find-specialist/${therapistId}/booking/payment/voucher`,
  },
  INSURANCE: {
    PATH: `/patient/find-specialist/:therapistId/booking/payment/insurance`,
    pathBuild: (therapistId: string) =>
      `/patient/find-specialist/${therapistId}/booking/payment/insurance`,
  },
};

export const PATIENT_FIND_SPECIALIST_ROUTES = {
  SPECIALIST_DETAILS: {
    PATH: '/patient/find-specialist/:therapistId/specialist-details/',
    pathBuild: (therapistId: string) =>
      `/patient/find-specialist/${therapistId}/specialist-details/`,
  },
  BOOKING: {
    PATH: `/patient/find-specialist/:therapistId/booking/`,
    pathBuild: (therapistId: string) =>
      `/patient/find-specialist/${therapistId}/booking/`,
    PAYMENT: BOOKING_PAYMENTS_ROUTES,
    SUMMARY: {
      PATH: `/patient/find-specialist/:therapistId/booking/:sessionId/summary`,
      pathBuild: ({ therapistId, sessionId }: BookingSummaryParams) =>
        `/patient/find-specialist/${therapistId}/booking/${sessionId}/summary`,
    },
    UNLIMITED_CONFIRMATION: {
      PATH: `/patient/find-specialist/:therapistId/booking/unlimited-confirmation`,
      pathBuild: ({ therapistId }: BookingUrlParams) =>
        `/patient/find-specialist/${therapistId}/booking/unlimited-confirmation`,
    },
  },
  ASSESSMENT_OUTCOME: {
    PATH: '/patient/find-specialist/:sessionId/:therapistId/assessment-outcome',
    pathBuild: (sessionId: string, therapistId: string) =>
      `/patient/find-specialist/${sessionId}/${therapistId}/assessment-outcome`,
  },
};

export const PATIENTS_ACCOUNT_SETTINGS = {
  PERSONAL: '/patient/account-settings/personal-info',
  EMERGENCY_CONTACTS: '/patient/account-settings/emergency-contacts',
  PAYMENT: '/patient/account-settings/payment-method',
  TRANSACTIONS: '/patient/account-settings/transaction',
  INSURANCE: '/patient/account-settings/insurance',
};

export const THERAPIST_ROUTES = {
  DASHBOARD: '/therapist/dashboard',
  CALENDAR: '/therapist/calendar',
  MESSAGING: {
    PATH: '/therapist/messaging/:chatId?',
    pathBuild: (chatId?: string | null) =>
      `/therapist/messaging/${chatId || ''}`,
  },
  PATIENTS: '/therapist/patients',
  PAYMENTS: '/therapist/payments',
  ACCOUNT_SETTINGS: '/therapist/account-settings',
  RESCHEDULE_SESSION: {
    PATH: '/therapist/calendar/reschedule-session/:sessionId/:startDateEncoded/',
    pathBuild: (sessionId: string, startDateEncoded: string) =>
      `/therapist/calendar/reschedule-session/${sessionId}/${startDateEncoded}/`,
  },
  RESOURCES: '/therapist/resources',
};

export const THERAPIST_DASHBOARD_ROUTES = {
  SESSION_OUTCOME: {
    PATH: '/therapist/dashboard/session-outcome/:sessionId/:startDateEncoded',
    pathBuild: (sessionId: string, startDateEncoded: string) =>
      `/therapist/dashboard/session-outcome/${sessionId}/${startDateEncoded}`,
  },
  NOTES: {
    PATH: '/therapist/dashboard/:patientId/notes/:sessionMetaId?',
    pathBuild: ({
      patientId,
      sessionMetaId,
    }: {
      patientId: string;
      sessionMetaId?: string;
    }) => {
      return `/therapist/dashboard/${patientId}/notes/${sessionMetaId || ''}`;
    },
  },
  MATERIALS: {
    PATH: '/therapist/dashboard/:patientId/materials/:sessionMetaId?',
    pathBuild: ({
      sessionMetaId,
      patientId,
    }: {
      sessionMetaId?: string;
      patientId: string;
    }) => `/therapist/dashboard/${patientId}/materials/${sessionMetaId || ''}`,
  },
  PATIENT_PROFILE: {
    PATH: '/therapist/dashboard/:patientId/patient-profile',
    buildPath: (patientId: string) =>
      `/therapist/dashboard/${patientId}/patient-profile`,
    NOTES: {
      PATH: '/therapist/dashboard/:patientId/patient-profile/notes',
      pathBuild: (patientId: string) =>
        `/therapist/dashboard/${patientId}/patient-profile/notes`,
    },
    SESSIONS: {
      PATH: '/therapist/dashboard/:patientId/patient-profile/sessions/',
      pathBuild: (patientId: string) =>
        `/therapist/dashboard/${patientId}/patient-profile/sessions/`,
    },
  },
};

export const ONBOARDING_ROUTES = {
  PERSONAL: '/onboarding/personal-info',
  QUALIFICATIONS: '/onboarding/qualifications-and-credentials',
  APPROACH: '/onboarding/approach-and-experience',
  PROFILE: '/onboarding/profile',
  COMMUNITY: '/onboarding/community',
  FINISH_ONBOARDING: '/onboarding/finish-onboarding',
  pathBuild: (page: string) => `/onboarding/${page}`,
};

export const THERAPIST_ACCOUNT_SETTINGS = {
  PERSONAL: '/therapist/account-settings/personal-information',
  QUALIFICATION: '/therapist/account-settings/qualification-and-credentials',
  APPROACH: '/therapist/account-settings/approach-and-experience',
  PROFILE: '/therapist/account-settings/profile',
  ACCOUNT: '/therapist/account-settings/account',
  SYSTEM_INFO: '/therapist/account-settings/system-info',
};

export const THERAPIST_CALENDAR_ROUTES = {
  ADD_SESSION: {
    PATH: '/therapist/calendar/add-session/:dateInISO?',
    pathBuild: (dateInISO?: string) =>
      `/therapist/calendar/add-session/${dateInISO || ''}`,
  },
};

export const LUMO_HEALTH_CONTACT_US_ROUTE =
  'https://www.lumohealth.care/request-contact';

export interface BookingSummaryParams {
  therapistId: string;
  sessionId: string;
}

export interface BookingUrlParams {
  therapistId: string;
}
