import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TreatmentAreasVariables = Types.Exact<{ [key: string]: never; }>;


export type TreatmentAreas = (
  { __typename: 'Query' }
  & { treatmentAreas: Array<(
    { __typename: 'TreatmentAreaModel' }
    & Pick<Types.TreatmentAreaModel, 'id' | 'label' | 'explanation'>
  )> }
);


export const TreatmentAreasDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TreatmentAreas"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"treatmentAreas"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useTreatmentAreas__
 *
 * To run a query within a React component, call `useTreatmentAreas` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentAreas` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentAreas({
 *   variables: {
 *   },
 * });
 */
export function useTreatmentAreas(baseOptions?: Apollo.QueryHookOptions<TreatmentAreas, TreatmentAreasVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TreatmentAreas, TreatmentAreasVariables>(TreatmentAreasDocument, options);
      }
export function useTreatmentAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TreatmentAreas, TreatmentAreasVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TreatmentAreas, TreatmentAreasVariables>(TreatmentAreasDocument, options);
        }
export type TreatmentAreasHookResult = ReturnType<typeof useTreatmentAreas>;
export type TreatmentAreasLazyQueryHookResult = ReturnType<typeof useTreatmentAreasLazyQuery>;
export type TreatmentAreasQueryResult = Apollo.QueryResult<TreatmentAreas, TreatmentAreasVariables>;