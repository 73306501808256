import React from 'react';

function CreateSelfPayAccountIcon({ ...rest }) {
  return (
    <svg
      width="86"
      height="87"
      viewBox="0 0 86 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g filter="url(#filter0_d_0_1)">
        <circle cx="44" cy="41" r="27" fill="url(#paint0_linear_0_1)" />
      </g>
      <path
        d="M44.0012 25.1176C39.4634 25.1176 35.7539 28.8271 35.7539 33.365C35.7539 37.9028 39.4634 41.6123 44.0012 41.6123C48.5391 41.6123 52.2485 37.9028 52.2485 33.365C52.2485 28.8271 48.5391 25.1176 44.0012 25.1176Z"
        fill="white"
      />
      <path
        d="M58.1557 48.2042C57.9396 47.664 57.6515 47.1598 57.3274 46.6916C55.6707 44.2426 53.1137 42.622 50.2326 42.2258C49.8725 42.1898 49.4763 42.2618 49.1882 42.4779C47.6755 43.5944 45.8749 44.1706 44.0021 44.1706C42.1293 44.1706 40.3286 43.5944 38.816 42.4779C38.5278 42.2618 38.1317 42.1538 37.7715 42.2258C34.8904 42.622 32.2974 44.2426 30.6767 46.6916C30.3526 47.1598 30.0645 47.7 29.8484 48.2042C29.7404 48.4203 29.7764 48.6724 29.8844 48.8885C30.1726 49.3927 30.5327 49.897 30.8568 50.3291C31.361 51.0134 31.9012 51.6256 32.5135 52.2018C33.0177 52.706 33.5939 53.1742 34.1702 53.6424C37.0153 55.7673 40.4367 56.8837 43.9661 56.8837C47.4955 56.8837 50.9169 55.7672 53.762 53.6424C54.3382 53.2102 54.9144 52.706 55.4187 52.2018C55.9949 51.6256 56.5711 51.0133 57.0754 50.3291C57.4355 49.8609 57.7597 49.3927 58.0477 48.8885C58.2277 48.6724 58.2637 48.4203 58.1557 48.2042Z"
        fill="white"
      />
      <circle cx="17" cy="17" r="17" fill="#ECEAF1" />
      <path
        d="M26.4877 15.2641C26.4873 15.2636 26.4869 15.2632 26.4864 15.2628L18.7359 7.51257C18.4056 7.18207 17.9664 7 17.4991 7C17.0319 7 16.5927 7.18192 16.2622 7.51243L8.51578 15.2587C8.51317 15.2613 8.51056 15.2641 8.50795 15.2667C7.82955 15.949 7.83071 17.0561 8.51129 17.7366C8.82222 18.0477 9.23289 18.2279 9.67197 18.2467C9.6898 18.2485 9.70778 18.2494 9.7259 18.2494H10.0348V23.953C10.0348 25.0817 10.9531 26 12.0821 26H15.1143C15.4216 26 15.6709 25.7508 15.6709 25.4434V20.9717C15.6709 20.4566 16.0899 20.0377 16.6049 20.0377H18.3934C18.9084 20.0377 19.3274 20.4566 19.3274 20.9717V25.4434C19.3274 25.7508 19.5765 26 19.884 26H22.9162C24.0452 26 24.9635 25.0817 24.9635 23.953V18.2494H25.2499C25.717 18.2494 26.1562 18.0674 26.4869 17.7369C27.1682 17.0552 27.1685 15.9463 26.4877 15.2641V15.2641Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_0_1"
          x="2"
          y="3"
          width="84"
          height="84"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.42353 0 0 0 0 0.309804 0 0 0 0 0.905882 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_0_1"
          x1="44"
          y1="14"
          x2="44"
          y2="68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B4EE6" />
          <stop offset="1" stopColor="#947AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CreateSelfPayAccountIcon;
