import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProfessionalTitlesVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfessionalTitles = (
  { __typename: 'Query' }
  & { professionalTitles: Array<(
    { __typename: 'ProfessionalTitleModel' }
    & Pick<Types.ProfessionalTitleModel, 'id' | 'label' | 'explanation'>
  )> }
);


export const ProfessionalTitlesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ProfessionalTitles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"professionalTitles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useProfessionalTitles__
 *
 * To run a query within a React component, call `useProfessionalTitles` and pass it any options that fit your needs.
 * When your component renders, `useProfessionalTitles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessionalTitles({
 *   variables: {
 *   },
 * });
 */
export function useProfessionalTitles(baseOptions?: Apollo.QueryHookOptions<ProfessionalTitles, ProfessionalTitlesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfessionalTitles, ProfessionalTitlesVariables>(ProfessionalTitlesDocument, options);
      }
export function useProfessionalTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfessionalTitles, ProfessionalTitlesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfessionalTitles, ProfessionalTitlesVariables>(ProfessionalTitlesDocument, options);
        }
export type ProfessionalTitlesHookResult = ReturnType<typeof useProfessionalTitles>;
export type ProfessionalTitlesLazyQueryHookResult = ReturnType<typeof useProfessionalTitlesLazyQuery>;
export type ProfessionalTitlesQueryResult = Apollo.QueryResult<ProfessionalTitles, ProfessionalTitlesVariables>;