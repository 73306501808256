import React from 'react';
import styled from 'styled-components';

import { capitalizeFirstLetter } from 'lib/utils';

interface PathItemProps {
  children: string;
  isActive?: boolean;
  onClick: () => void;
}

/**
 *
 * @description Component will display lables for chaning subpath on url.
 */
const PathItem = (props: PathItemProps) => {
  const { isActive = false, children, onClick } = props;

  return (
    <PathItemContainer isActive={isActive} onClick={onClick}>
      {capitalizeFirstLetter(children)}
    </PathItemContainer>
  );
};

const PathItemContainer = styled.div<{ isActive: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: var(--black);
  cursor: pointer;
  &:not(:first-child) {
    margin-left: 28px;
  }
  border-bottom: ${(props) =>
    props.isActive ? '2px solid var(--purple)' : 'none'};
`;

export default PathItem;
