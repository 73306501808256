import { getSize } from 'lib/utils';

const THEMES: any = {
  primary: ($isChecked: boolean, $isAssessmentOutcome: boolean) => `
    background-color: var(
      ${$isAssessmentOutcome && $isChecked ? '--purple5' : '--purple11'}
    );
    padding: ${getSize(8)} ${getSize(12)};
  `,
  secondary: () => `
    padding: ${getSize(7)} ${getSize(12)};
    border: ${getSize(1)} solid var(--purple3);
    font-weight: 400;
    font-size: ${getSize(11)};
    line-height: ${getSize(24)};
    transition: 0.3s ease-out;

    &:hover {
      border-color: var(--purple2);
    }
  `,
};

export default THEMES;
