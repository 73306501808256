import { useMemo } from 'react';
import { transformToOptions } from 'lib/utils';
import { useTherapyApproaches } from 'common/query/__generated__/get-therapy-approaches';

export function useTherapyApproachesOptions() {
  const therapyApproachesQuery = useTherapyApproaches();

  return useMemo(
    () => transformToOptions(therapyApproachesQuery.data?.therapyApproaches),
    [therapyApproachesQuery],
  );
}
