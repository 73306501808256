import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

interface LogoProps {
  size?: number;
  fill?: string;
}

export function SlackLogo(props: LogoProps) {
  const size = props.size || 36;
  const fill = props.fill || 'none';
  return (
    <SVG
      width={size}
      height={size}
      viewBox="0 0 122.8 122.8"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill="#e01e5a"
          strokeWidth={0}
          d="M25.8,77.6c0,7.1-5.8,12.9-12.9,12.9S0,84.7,0,77.6s5.8-12.9,12.9-12.9h12.9v12.9Z"
        />
        <path
          fill="#e01e5a"
          strokeWidth={0}
          d="M32.3,77.6c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3c0,7.1-5.8,12.9-12.9,12.9s-12.9-5.8-12.9-12.9c0,0,0-32.3,0-32.3Z"
        />
      </g>
      <g>
        <path
          fill="#36c5f0"
          strokeWidth={0}
          d="M45.2,25.8c-7.1,0-12.9-5.8-12.9-12.9S38.1,0,45.2,0s12.9,5.8,12.9,12.9v12.9h-12.9Z"
        />
        <path
          fill="#36c5f0"
          d="M45.2,32.3c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H12.9c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9c0,0,32.3,0,32.3,0Z"
        />
      </g>
      <g>
        <path
          fill="#2eb67d"
          strokeWidth={0}
          d="M97,45.2c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9-5.8,12.9-12.9,12.9h-12.9v-12.9Z"
        />
        <path
          fill="#2eb67d"
          strokeWidth={0}
          d="M90.5,45.2c0,7.1-5.8,12.9-12.9,12.9s-12.9-5.8-12.9-12.9V12.9c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3Z"
        />
      </g>
      <g>
        <path
          fill="#ecb22e"
          strokeWidth={0}
          d="M77.6,97c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9-12.9-5.8-12.9-12.9v-12.9h12.9Z"
        />
        <path
          fill="#ecb22e"
          strokeWidth={0}
          d="M77.6,90.5c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9h32.3c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9h-32.3Z"
        />
      </g>
    </SVG>
  );
}

export function LumoLogo(props: LogoProps) {
  const size = props.size || 36;
  const fill = props.fill || 'none';
  return (
    <SVG
      width={size}
      height={size}
      viewBox="0 0 38 38"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2954 12.3335V25.3095H24.0828"
        stroke="white"
        strokeWidth="3.83418"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M36.4478 25.9916C36.4478 23.4145 35.4454 20.9907 33.6215 19.1695C31.8002 17.3482 29.3765 16.3431 26.7966 16.3431H22.1052V11.6517C22.1052 9.07457 21.1001 6.65082 19.2789 4.82684C17.4549 3.00286 15.0339 2.00049 12.4541 2.00049C9.87697 2.00049 7.45323 3.00559 5.632 4.82684C3.81076 6.64808 2.80566 9.07183 2.80566 11.649V32.3098C2.80566 33.1999 3.15348 34.0352 3.78338 34.6651C4.41328 35.295 5.25132 35.6428 6.13866 35.6428H26.7994C29.3737 35.6428 31.7947 34.6377 33.6187 32.811C35.4454 30.9842 36.4505 28.5632 36.4478 25.9916Z"
        stroke="white"
        strokeWidth="3.83418"
        strokeMiterlimit="10"
      />
    </SVG>
  );
}

const SVG = styled.svg``;

export const Badge = styled.div<{ color: string }>`
  padding: ${getSize(8)};
  border-radius: ${getSize(4)};
  background: ${(props) => props.color};
`;
