import { MutationUpdaterFn } from '@apollo/client';
import { PatientNoteAdd } from 'common/mutation/__generated__/patient-note-add';
import { PatientNoteRemove } from 'common/mutation/__generated__/patient-note-remove';
import { PatientNoteRemoveAll } from 'common/mutation/__generated__/patient-note-remove-all';
import { PatientNote } from 'common/query/__generated__/get-patient-notes';
import { NotesFilter, NoteType } from '../../../__generated__/types';
import { parseStoreNameArgs } from './parse-store-name-args';

export const patientNoteAddToCache: MutationUpdaterFn<PatientNoteAdd> = (
  cache,
  { data },
) => {
  if (data?.patientNoteAdd.note) {
    const note = data.patientNoteAdd.note;
    cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        patientGetNotes(data, { storeFieldName }) {
          const filter = parseStoreNameArgs(storeFieldName, 'patientGetNotes')
            .filter as NotesFilter;

          const newNoteRef = cache.writeFragment({
            data: note,
            fragment: PatientNote,
          });
          if (filter.patientId === note.patientId) {
            if (
              filter.sessionMetaId &&
              filter.sessionMetaId === note.sessionMetaId
            ) {
              if (note.shared) {
                if (
                  filter.type === NoteType.All ||
                  filter.type === NoteType.Shared
                ) {
                  return { ...data, nodes: [newNoteRef, ...data.nodes] };
                }
              } else {
                if (
                  filter.type === NoteType.All ||
                  filter.type === NoteType.OnlyMyNotes
                ) {
                  return { ...data, nodes: [newNoteRef, ...data.nodes] };
                }
              }
            } else if (!filter.sessionMetaId) {
              if (note.shared) {
                if (
                  filter.type === NoteType.All ||
                  filter.type === NoteType.Shared
                ) {
                  return { ...data, nodes: [newNoteRef, ...data.nodes] };
                }
              } else {
                if (
                  filter.type === NoteType.All ||
                  filter.type === NoteType.OnlyMyNotes
                ) {
                  return { ...data, nodes: [newNoteRef, ...data.nodes] };
                }
              }
            }
          }
          return data;
        },
      },
    });
  }
};

export const patientNoteRemoveFromCache: (
  id: string,
) => MutationUpdaterFn<PatientNoteRemove> =
  (id: string) =>
  (cache, { data }) => {
    if (data?.patientNoteRemove) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          patientGetNotes(data, { readField }) {
            return {
              ...data,
              nodes: data.nodes.filter(
                (noteRef: any) => readField('id', noteRef) !== id,
              ),
            };
          },
        },
      });
    }
  };

export const patientNoteRemoveAllFromCache: (params: {
  sessionMetaId?: string;
}) => MutationUpdaterFn<PatientNoteRemoveAll> =
  ({ sessionMetaId }) =>
  (cache, { data }) => {
    if (data?.patientNoteRemoveAll) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          patientGetNotes(data, { readField }) {
            return {
              ...data,
              nodes: data.nodes.filter((noteRef: any) => {
                if (sessionMetaId) {
                  return readField('sessionMetaId', noteRef) !== sessionMetaId;
                } else {
                  return false;
                }
              }),
            };
          },
        },
      });
    }
  };
