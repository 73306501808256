import { getSize } from 'lib/utils';
import React, { FC, useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import { DatesRange } from 'ui/calendar-small/utils/add-day-to-range';
import { ArrowDownIcon } from 'ui/icons';
import { CalendarDrop } from './components';

export interface DropDownCalendarProps {
  title?: string;
  selectedDays?: Date | Date[];
  onDayClick?: (value: Date | Date[]) => void;
  wrapperCSS?: CSSProp;
  buttonCSS?: CSSProp;
  calendarCSS?: CSSProp;
  calendarWrapperCSS?: CSSProp;
  isRange?: boolean;
  selectedRange?: DatesRange;
  onDayClickRange?: (value: DatesRange) => void;
}

const DropDownCalendar: FC<DropDownCalendarProps> = ({
  wrapperCSS,
  buttonCSS,
  calendarCSS,
  calendarWrapperCSS,
  selectedDays,
  onDayClick,
  title,
  isRange,
  selectedRange,
  onDayClickRange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Wrapper $CSS={wrapperCSS}>
      <DropDownButton onClick={() => setIsOpen(!isOpen)} $CSS={buttonCSS}>
        {title && <Title>{title}</Title>}

        <ArrowIndicator isOpen={isOpen}>
          <ArrowIcon />
        </ArrowIndicator>
      </DropDownButton>

      {isOpen && (
        <CalendarDrop
          selectedDays={selectedDays}
          onDayClick={(date) => onDayClick && onDayClick(date)}
          isRange={isRange}
          selectedRange={selectedRange}
          onDayClickRange={onDayClickRange}
          calendarCSS={calendarCSS}
          onWindowClick={() => setIsOpen(false)}
          calendarWrapperCSS={calendarWrapperCSS}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  position: relative;

  ${({ $CSS }) => $CSS}
`;

const DropDownButton = styled.button<{ $CSS?: CSSProp }>`
  position: relative;
  display: flex;
  align-items: center;
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

const Title = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  margin-right: ${getSize(10)};
  white-space: nowrap;
`;

const ArrowIndicator = styled.span<{ isOpen: boolean }>`
  transform: scale(${({ isOpen }) => (isOpen ? -1 : 1)});
  transition: 0.2s ease-out;

  & > svg {
    width: ${getSize(22)};
    height: ${getSize(22)};
  }
`;

const ArrowIcon = styled(ArrowDownIcon)`
  stroke: var(--black3);
`;

export default DropDownCalendar;
