import * as React from 'react';

function ClockCutIcon({ ...rest }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_1751_1833)">
        <path
          d="M12.5269 3.68372C12.2369 3.82399 12.1156 4.17291 12.2558 4.46273C12.6391 5.25441 12.8333 6.10777 12.8333 7C12.8333 10.2165 10.2165 12.8333 7 12.8333C3.78355 12.8333 1.16667 10.2165 1.16667 7C1.16667 3.78355 3.78355 1.16667 7 1.16667C8.33286 1.16667 9.58498 1.60189 10.6213 2.42519C10.8727 2.62585 11.2403 2.58384 11.4409 2.33162C11.6416 2.07955 11.5996 1.7124 11.3472 1.51202C10.1203 0.536906 8.5764 0 7 0C3.1404 0 0 3.1404 0 7C0 10.8596 3.1404 14 7 14C10.8596 14 14 10.8596 14 7C14 5.93046 13.7664 4.9055 13.3059 3.95473C13.1659 3.6642 12.8158 3.54315 12.5269 3.68372Z"
          fill="black"
        />
        <path
          d="M6.99984 2.33337C6.67784 2.33337 6.4165 2.59471 6.4165 2.91671V7.00004C6.4165 7.32204 6.67784 7.58337 6.99984 7.58337H9.9165C10.2385 7.58337 10.4998 7.32204 10.4998 7.00004C10.4998 6.67804 10.2385 6.41671 9.9165 6.41671H7.58317V2.91671C7.58317 2.59471 7.32184 2.33337 6.99984 2.33337Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1751_1833">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ClockCutIcon;
