import React from 'react';

const SunIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3329_3726)">
        <path
          d="M3.26303 5.04109C4.01208 5.02799 4.71957 5.01563 5.35092 4.73427C5.36205 4.72933 5.37333 4.72473 5.38476 4.72042C5.77085 4.57622 5.99532 4.21336 6.25523 3.79317C6.43982 3.49478 6.63063 3.18632 6.90109 2.91928C7.62565 2.1693 8.565 1.70959 9.38875 1.35872C9.40174 1.35319 9.41491 1.34815 9.42824 1.34354C10.2085 1.07528 10.8257 0.624291 10.9641 0.22132C10.9745 0.19115 10.9841 0.155934 10.9896 0.115521C10.5062 0.0397441 10.0109 0 9.50616 0C5.75162 0 2.50615 2.17828 0.963623 5.33982C1.73988 5.0678 2.52715 5.05393 3.26303 5.04109Z"
          fill="#6B4EE6"
        />
        <path
          d="M17.9694 10.8579C17.3691 11.3461 16.802 11.8073 16.339 12.3889C15.4057 13.561 15.2101 15.258 15.7725 16.6396C17.6419 14.9975 18.8614 12.6333 18.994 9.98242C18.663 10.2931 18.314 10.5777 17.9694 10.8579Z"
          fill="#6B4EE6"
        />
        <path
          d="M3.72064 10.1948C5.00507 9.34229 6.32049 8.91152 7.48101 8.53156C8.94972 8.05062 10.2182 7.63529 11.137 6.51748C12.111 5.33254 12.6355 3.43388 12.7731 0.575789C12.5673 0.50042 12.3579 0.432324 12.1457 0.371094C12.1292 0.456 12.1073 0.534227 12.0831 0.604846C11.9332 1.04118 11.613 1.44014 11.1313 1.79068C10.6721 2.12488 10.1759 2.33526 9.83356 2.45453C9.12191 2.75845 8.32083 3.14973 7.74779 3.745C7.74385 3.74909 7.73985 3.75313 7.73576 3.7571C7.56439 3.92513 7.41722 4.16304 7.26143 4.41487C6.95313 4.91321 6.57001 5.53253 5.81643 5.82135C4.97022 6.19389 4.11292 6.20884 3.28364 6.22332C2.17366 6.24269 1.19353 6.26061 0.307432 7.11691C0.110789 7.87814 0.00610352 8.6764 0.00610352 9.49911C0.00610352 10.8171 0.274664 12.0723 0.759795 13.2133C1.60938 11.9423 2.58572 10.948 3.72064 10.1948Z"
          fill="#6B4EE6"
        />
        <path
          d="M8.49134 14.9542C9.01402 13.4669 9.95897 12.0264 11.224 10.7886C11.7332 10.2903 12.2812 9.83485 12.8111 9.39436C13.2903 8.996 13.7858 8.58408 14.2355 8.15302C15.3116 7.1213 15.9391 6.12893 16.154 5.11914C16.3246 4.31747 16.2152 3.46325 16.0553 2.61734C15.423 2.0155 14.7077 1.50038 13.9287 1.08984C13.7408 3.96794 13.1359 5.94872 12.0511 7.26867C10.9136 8.65255 9.42512 9.13994 7.8492 9.65595C6.70693 10.0299 5.52574 10.4167 4.37494 11.1805C3.21386 11.9511 2.23258 13.0194 1.38745 14.4347C2.81742 16.7818 5.23651 18.4601 8.06573 18.8904C7.89128 17.6158 8.03485 16.2531 8.49134 14.9542Z"
          fill="#6B4EE6"
        />
        <path
          d="M14.2794 14.8585C14.3107 13.6701 14.7134 12.5314 15.4134 11.6522C15.9562 10.9704 16.6002 10.4467 17.223 9.94022C17.8661 9.41727 18.4801 8.91752 18.9292 8.28492C18.7403 6.80596 18.2109 5.43358 17.4228 4.24805C17.4228 4.61947 17.3909 4.99387 17.3115 5.36678C17.0456 6.61607 16.3073 7.80724 15.0545 9.0084C14.5746 9.46852 14.0627 9.89405 13.5676 10.3056C13.0559 10.731 12.5267 11.1709 12.0517 11.6356C10.9157 12.7472 10.0706 14.0309 9.6078 15.3478C9.18086 16.5627 9.06946 17.8348 9.28002 18.9975C9.35524 18.9992 9.43061 19.0003 9.50624 19.0003C11.4701 19.0003 13.2946 18.4043 14.8092 17.3835C14.4415 16.6181 14.2561 15.7468 14.2794 14.8585Z"
          fill="#6B4EE6"
        />
      </g>
      <defs>
        <clipPath id="clip0_3329_3726">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SunIcon;
