import { useAvailableClientsTypesOptions } from 'features/find-specialist/booking/booking-home/hook';

export const useDefaultClientType = (therapistId: string) => {
  const { availableClientsTypesOptions, loading } =
    useAvailableClientsTypesOptions(therapistId);

  const adultClientTypeId = availableClientsTypesOptions.find(
    (item) => item.label === 'Adult',
  )?.value;

  const defaultClientType = adultClientTypeId
    ? adultClientTypeId
    : availableClientsTypesOptions[0]?.value;

  return { defaultClientType, loading };
};
