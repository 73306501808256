import React, { FC, useState } from 'react';
import {
  Button,
  DatePickerInput,
  Modal,
  Textarea,
  RadioButton,
  RadioFields,
  SingleSelect,
} from 'ui';
import { Field, Form as FinalForm } from 'react-final-form';
import { getSize, TimeUtil, validationRules } from 'lib/utils';
import styled, { css, CSSProperties } from 'styled-components';
import { validateTherapistInput } from './utils/validateTherapistInput';
import { Option } from 'ui/drop-down-filter/drop-down-filter';
import { API_DATE_FORMAT } from 'lib/constants/date';
import CalendarSvg from 'assets/svg/calendar.svg';
import { bookingSelectStyles } from 'ui/form-elements/single-select/utils/styles';
import { ControlProps } from 'react-select';

const ROOT_CSS = css`
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  width: 134px;
  height: 36px;

  & .DayPickerInput .DayPickerInput-Overlay {
    left: ${getSize(-160)};
    top: 8px;
  }
`;
const RADIO_ROOT_CSS = {
  backgroundColor: 'var(--white)}',
  paddingLeft: 0,
  width: 'auto',
  marginBottom: 0,
  paddingTop: 0,
  paddingBottom: 0,
};
const RADIO_BTN_CSS = {
  width: getSize(22),
  height: getSize(22),
  marginRight: getSize(12),
};
const RADIO_LABEL_CSS = {
  fontSize: getSize(13),
  lineHeight: getSize(24),
};
const SELECT_ARROW_COLOR = '--gray2';

const SHARED_OPTIONS = [
  { label: 'Private', value: 'false' },
  { label: 'Share with my therapist', value: 'true' },
];

const SHARED_OPTION_FOR_NOTES_IN_VIDEO = [
  { label: 'Private', value: 'false' },
  { label: 'Share with therapist', value: 'true' },
];

const customSelectStyles = {
  ...bookingSelectStyles,
  control: (
    base: CSSProperties,
    state: ControlProps<Record<string, unknown>, any>,
  ) => ({
    ...base,
    height: getSize(41),
    boxShadow: 'unset',
    borderRadius: 8,
    border: state.isFocused
      ? `${getSize(1)} solid var(--purple)`
      : `${getSize(1)} solid var(--gray19)`,
  }),
  singleValue: (base: CSSProperties) => ({
    ...base,
    fontSize: 14,
  }),
};

export interface NoteModalFormValues {
  text: string;
  date: string;
  isShared?: string;
  id?: string;
  shareWithTherapistId?: string | null;
}

interface NoteModalProps {
  isVisible: boolean;
  onCloseNoteModal: () => void;
  onAddNoteSubmit: (values: NoteModalFormValues) => void;
  isTherapist?: boolean;
  initialValues?: NoteModalFormValues;
  connectedTherapistsOptions?: Option[];
  isNoteForVideoChart?: boolean;
}

const NoteModal: FC<NoteModalProps> = ({
  isVisible,
  onCloseNoteModal,
  onAddNoteSubmit,
  initialValues,
  isTherapist,
  connectedTherapistsOptions,
  isNoteForVideoChart,
}) => {
  const [selectedDate, setSelectedDate] = useState(TimeUtil.getStartOFNowDay());
  const isSharedWithTherapist =
    initialValues?.isShared === 'true' && isTherapist;

  return (
    <Modal
      title={
        initialValues
          ? isSharedWithTherapist
            ? 'Patient shared note'
            : 'Edit note'
          : 'New note'
      }
      isVisible={isVisible}
      paperCSS={isNoteForVideoChart ? paperVideoCSS : paperCSS}
      headerCSS={headerCSS}
      titleCSS={titleCSS}
      onClose={onCloseNoteModal}
      isNoteForVideoChart={isNoteForVideoChart}
    >
      <FinalForm
        onSubmit={onAddNoteSubmit}
        initialValues={initialValues || { isShared: 'false' }}
        render={({ handleSubmit, values, submitting }) => {
          return (
            <StyledForm onSubmit={handleSubmit}>
              <InputsContainer>
                {!isTherapist && (
                  <RowContainer>
                    <RadioFields
                      name="isShared"
                      options={
                        isNoteForVideoChart
                          ? SHARED_OPTION_FOR_NOTES_IN_VIDEO
                          : SHARED_OPTIONS
                      }
                      component={RadioButton}
                      radioRootCSS={RADIO_ROOT_CSS}
                      radioButtonCSS={RADIO_BTN_CSS}
                      radioLabelCSS={RADIO_LABEL_CSS}
                      validate={validationRules.required}
                      contentCSS={contentCSS}
                    />
                    <InputWrapper>
                      <Field
                        name="date"
                        placeholder="Day Month Year"
                        validate={validationRules.required}
                        defaultValue={TimeUtil.now().format(API_DATE_FORMAT)}
                        inputFormat="DD MMM YYYY"
                        rootCSS={!isTherapist ? ROOT_CSS : ''}
                        inputCSS={DAY_PICKER_INPUT_CSS}
                        arrowStroke="var(--gray2)"
                        component={DatePickerInput}
                        iconCSS={iconCSS}
                        hasLine={false}
                        disabled={isSharedWithTherapist}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                      />
                    </InputWrapper>
                    {values.isShared === 'true' && (
                      <Field
                        name="shareWithTherapistId"
                        options={connectedTherapistsOptions}
                        arrowColorVariable={SELECT_ARROW_COLOR}
                        component={SingleSelect}
                        defaultValue={connectedTherapistsOptions?.[0]?.value}
                        labelTextWhenDisabled=""
                        validate={validateTherapistInput}
                        rootCSS={{
                          width: '100%',
                          marginTop: 15,
                        }}
                        customSelectStyles={customSelectStyles}
                        hasLine={false}
                      />
                    )}
                  </RowContainer>
                )}

                <Container>
                  <Field
                    name="text"
                    placeholder="Write note here"
                    disabled={isSharedWithTherapist}
                    validate={validationRules.required}
                    rootCSS={textareaCss}
                    component={Textarea}
                    hasLine={false}
                  />

                  {!isSharedWithTherapist && (
                    <Btn
                      isDisabled={submitting}
                      isLoading={submitting}
                      theme="primary"
                    >
                      Save
                    </Btn>
                  )}
                </Container>
              </InputsContainer>
            </StyledForm>
          );
        }}
      />
    </Modal>
  );
};

const headerCSS = css`
  padding: 22px 23px 19px 27px;
  border-bottom: 1px solid var(--white3);
  margin: 0;
`;

const paperCSS = css`
  padding: 0;
  width: ${getSize(582)};
  height: ${getSize(609)};
`;

const paperVideoCSS = css`
  padding: 0;
  width: ${getSize(480)};
`;

const titleCSS = css`
  font-family: Poppins;
  font-size: ${getSize(18)};
  font-weight: 500;
  line-height: ${getSize(27)};
  color: black;
`;

const contentCSS = css`
  display: flex;
  justify-content: flex-start;
`;

const textareaCss = css`
  max-height: 380px;
  overflow: auto;
`;

const iconCSS = css`
  display: none;
`;

const DAY_PICKER_INPUT_CSS = css`
  color: 'var(--balck3)';
  padding: 7px 0 5px 43px;
`;

const StyledForm = styled.form`
  height: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  &:after {
    content: url(${CalendarSvg});
    position: absolute;
    top: 10px;
    left: 14px;
  }
`;

const Btn = styled(Button)`
  width: ${getSize(93)};
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  padding: ${getSize(9)};
  height: ${getSize(35)};
  margin-left: auto;
  margin-top: auto;
  display: block;
  border-radius: 34px;
`;

const Container = styled.div`
  padding: 20px 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 7px 13px 10px 25px;
  border-bottom: 1px solid var(--white3);
  align-items: center;
  flex-wrap: wrap;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default NoteModal;
