import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TherapistAvailabilityVariables = Types.Exact<{
  availabilityFilter: Types.AvailabilityFilter;
  therapistId: Types.Scalars['String'];
}>;


export type TherapistAvailability = (
  { __typename: 'Query' }
  & { therapistAvailability: (
    { __typename: 'TherapistAvailability' }
    & { availabilityIntervals: Array<(
      { __typename: 'AvailabilityIntervalModel' }
      & AvailabilityIntervalFragment
    )> }
  ) }
);

export type AvailabilityIntervalFragment = (
  { __typename: 'AvailabilityIntervalModel' }
  & Pick<Types.AvailabilityIntervalModel, 'startOfIntervalUtc' | 'endOfIntervalUtc' | 'sessionType'>
  & { officeLocation?: Types.Maybe<(
    { __typename: 'OfficeLocationModel' }
    & OfficeLocationFragment
  )> }
);

export type OfficeLocationFragment = (
  { __typename: 'OfficeLocationModel' }
  & Pick<Types.OfficeLocationModel, 'id' | 'fullAddress' | 'postcode' | 'lat' | 'lng' | 'hideFullAddress'>
);

export const OfficeLocationFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OfficeLocationFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OfficeLocationModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullAddress"}},{"kind":"Field","name":{"kind":"Name","value":"postcode"}},{"kind":"Field","name":{"kind":"Name","value":"lat"}},{"kind":"Field","name":{"kind":"Name","value":"lng"}},{"kind":"Field","name":{"kind":"Name","value":"hideFullAddress"}}]}}]} as unknown as DocumentNode;
export const AvailabilityIntervalFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AvailabilityIntervalFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AvailabilityIntervalModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startOfIntervalUtc"}},{"kind":"Field","name":{"kind":"Name","value":"endOfIntervalUtc"}},{"kind":"Field","name":{"kind":"Name","value":"sessionType"}},{"kind":"Field","name":{"kind":"Name","value":"officeLocation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OfficeLocationFragment"}}]}}]}},...OfficeLocationFragment.definitions]} as unknown as DocumentNode;
export const TherapistAvailabilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TherapistAvailability"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"availabilityFilter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AvailabilityFilter"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"therapistId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistAvailability"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"availabilityFilter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"availabilityFilter"}}},{"kind":"Argument","name":{"kind":"Name","value":"therapistId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"therapistId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"availabilityIntervals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AvailabilityIntervalFragment"}}]}}]}}]}},...AvailabilityIntervalFragment.definitions]} as unknown as DocumentNode;

/**
 * __useTherapistAvailability__
 *
 * To run a query within a React component, call `useTherapistAvailability` and pass it any options that fit your needs.
 * When your component renders, `useTherapistAvailability` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistAvailability({
 *   variables: {
 *      availabilityFilter: // value for 'availabilityFilter'
 *      therapistId: // value for 'therapistId'
 *   },
 * });
 */
export function useTherapistAvailability(baseOptions: Apollo.QueryHookOptions<TherapistAvailability, TherapistAvailabilityVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistAvailability, TherapistAvailabilityVariables>(TherapistAvailabilityDocument, options);
      }
export function useTherapistAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistAvailability, TherapistAvailabilityVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistAvailability, TherapistAvailabilityVariables>(TherapistAvailabilityDocument, options);
        }
export type TherapistAvailabilityHookResult = ReturnType<typeof useTherapistAvailability>;
export type TherapistAvailabilityLazyQueryHookResult = ReturnType<typeof useTherapistAvailabilityLazyQuery>;
export type TherapistAvailabilityQueryResult = Apollo.QueryResult<TherapistAvailability, TherapistAvailabilityVariables>;