import React from 'react';

function VideoAddNoteIcon({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22915 13.75C3.07822 13.9019 2.98006 14.0982 2.94915 14.31L2.00915 20.86C1.98597 21.0138 1.9992 21.1708 2.04779 21.3185C2.09637 21.4662 2.17896 21.6004 2.28889 21.7103C2.39882 21.8202 2.53303 21.9028 2.68072 21.9514C2.8284 22 2.98542 22.0132 3.13915 21.99L9.68915 21.05C9.901 21.0191 10.0973 20.921 10.2492 20.77L17.9692 13.06L10.9392 6.04004L3.22915 13.75Z"
        fill="white"
      />
      <path
        d="M13.5194 3.46005L12.3594 4.62006L19.3794 11.6401L20.5494 10.4801C25.1823 5.82306 18.1556 -1.17615 13.5194 3.46005Z"
        fill="white"
      />
    </svg>
  );
}

export default VideoAddNoteIcon;
