import React, { ReactNode, useContext } from 'react';
import styled from 'styled-components';
import Plant from 'assets/images/plant.png';
import { getSize } from 'lib/utils';
import { logout } from 'lib/auth';

import { LogoLink, Button, AuthNavigation, ShadowWrapper } from 'ui';
import { TeamsAppContext } from '../../hooks/use-teams-app';

function AuthLayout({
  children,
  hasSignIn,
  hasWrapper = true,
}: AuthLayoutProps) {
  const isTeamsApp = useContext(TeamsAppContext);

  return (
    <Wrapper>
      {!isTeamsApp && (
        <Header>
          <HeaderInner>
            <LogoLink />

            <LinksWrapper $hasSignIn={hasSignIn}>
              {hasSignIn ? (
                <Button onClick={logout} theme="buble-secondary">
                  Log Out
                </Button>
              ) : (
                <AuthNavigation />
              )}
            </LinksWrapper>
          </HeaderInner>
        </Header>
      )}

      <Content>
        {hasWrapper ? (
          <ShadowWrapperStyled>{children}</ShadowWrapperStyled>
        ) : (
          <>{children}</>
        )}

        <PlantBackground />
      </Content>
    </Wrapper>
  );
}

export interface AuthLayoutProps {
  children: ReactNode;
  hasSignIn?: boolean;
  hasWrapper?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--white2);
  position: relative;
  z-index: 1;
`;

const Header = styled.header`
  position: relative;
  background: white;
  box-shadow: 0 ${getSize(20)} ${getSize(90)} var(--blue-opacity);
  border-bottom: ${getSize(1)} solid var(--gray10);
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: ${getSize(14)} ${getSize(12)};
`;

const Content = styled.main`
  height: 100%;
  flex-grow: 1;
  overflow: auto;
`;

const LinksWrapper = styled.div<{ $hasSignIn?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${getSize(12)};
  align-items: center;
`;

const PlantBackground = styled.span`
  background-image: url(${Plant});
  position: absolute;
  background-size: cover;
  width: 225px;
  height: 259px;
  bottom: 0;
  right: 0;
  z-index: -1;
`;

const ShadowWrapperStyled = styled(ShadowWrapper)`
  max-width: 480px;
  margin: 0 auto;
  margin-top: 80px;
`;

export default AuthLayout;
