import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

function GoalsDeleteIcon({ ...rest }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="#ED6C6C"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.334 4c0-.368.298-.667.667-.667h12a.667.667 0 010 1.334H2A.667.667 0 011.334 4z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.666 2a.667.667 0 00-.667.667v.666h4v-.667A.667.667 0 009.333 2H6.666zm4.667 1.333v-.667a2 2 0 00-2-2H6.666a2 2 0 00-2 2v.667H3.333A.667.667 0 002.666 4v9.333a2 2 0 002 2h6.667a2 2 0 002-2V4a.667.667 0 00-.667-.667h-1.333zM3.999 4.666v8.667a.667.667 0 00.667.667h6.667a.667.667 0 00.666-.667V4.667H4z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.667 6.667c.368 0 .666.298.666.666v4a.667.667 0 11-1.333 0v-4c0-.368.298-.667.667-.667zM9.333 6.667c.368 0 .666.298.666.666v4a.667.667 0 01-1.333 0v-4c0-.368.298-.667.667-.667z"
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(16)};
  height: ${getSize(16)};
`;

export default GoalsDeleteIcon;
