import { ApolloCache, Cache } from '@apollo/client';

export type CacheFieldOption = string | Cache.EvictOptions;

export const AVAILABILITY_FIELDS_NAMES = [
  'therapistAvailability',
  'therapistRecurringAvailability',
];

export const SESSIONS_FIELDS_NAMES = [
  'therapistSessions',
  'therapistUpcomingSessions',
  'therapistUpcomingSessionsByPatientId',
  'therapistPastSessionsByPatientId',
  'therapistSessionsNeededAction',
];

export const THERAPIST_PAYMENTS_FIELDS_NAMES = [
  'therapistEarnings',
  'therapistMonthEarnings',
  'therapistPaymentsV2',
  'nearestSessions',
];

export const PATIENT_PAYMENT_METHOD_FIELDS_NAMES = [
  'me',
  'patientPaymentMethods',
  'patientUpcomingSessions',
  'patientPastSessions',
  'patientUpcomingSessions',
];

export function evictQueryCache<T>(
  cache: ApolloCache<T>,
  fieldsOptions: CacheFieldOption[],
) {
  if (fieldsOptions.length < 0) return;

  fieldsOptions.forEach((option) => {
    const currentOption =
      typeof option === 'string'
        ? { fieldName: option, broadcast: true }
        : option;

    cache.evict(currentOption);
  });
  cache.gc();
}

export function getQueryCacheEvictFunc<T>(fieldsOptions: CacheFieldOption[]) {
  return (cache: ApolloCache<T>) => evictQueryCache<T>(cache, fieldsOptions);
}

export function getSessionsCacheEvictFunc<T>() {
  return getQueryCacheEvictFunc<T>(SESSIONS_FIELDS_NAMES);
}

export function getSessionsAndPaymentsCacheEvictFunc<T>() {
  return getQueryCacheEvictFunc<T>(
    SESSIONS_FIELDS_NAMES.concat(THERAPIST_PAYMENTS_FIELDS_NAMES),
  );
}

export function patientEditPaymentMethodCacheEvict<T>() {
  return getQueryCacheEvictFunc<T>(PATIENT_PAYMENT_METHOD_FIELDS_NAMES);
}

export function getAvailabilityCacheEvictFunc<T>() {
  return getQueryCacheEvictFunc<T>(AVAILABILITY_FIELDS_NAMES);
}
