import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TherapyApproachesVariables = Types.Exact<{ [key: string]: never; }>;


export type TherapyApproaches = (
  { __typename: 'Query' }
  & { therapyApproaches: Array<(
    { __typename: 'TherapyApproachModel' }
    & Pick<Types.TherapyApproachModel, 'id' | 'label' | 'explanation'>
  )> }
);


export const TherapyApproachesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TherapyApproaches"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapyApproaches"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useTherapyApproaches__
 *
 * To run a query within a React component, call `useTherapyApproaches` and pass it any options that fit your needs.
 * When your component renders, `useTherapyApproaches` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapyApproaches({
 *   variables: {
 *   },
 * });
 */
export function useTherapyApproaches(baseOptions?: Apollo.QueryHookOptions<TherapyApproaches, TherapyApproachesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapyApproaches, TherapyApproachesVariables>(TherapyApproachesDocument, options);
      }
export function useTherapyApproachesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapyApproaches, TherapyApproachesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapyApproaches, TherapyApproachesVariables>(TherapyApproachesDocument, options);
        }
export type TherapyApproachesHookResult = ReturnType<typeof useTherapyApproaches>;
export type TherapyApproachesLazyQueryHookResult = ReturnType<typeof useTherapyApproachesLazyQuery>;
export type TherapyApproachesQueryResult = Apollo.QueryResult<TherapyApproaches, TherapyApproachesVariables>;