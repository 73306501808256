import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserVariables = Types.Exact<{ [key: string]: never; }>;


export type User = (
  { __typename: 'Query' }
  & { me?: Types.Maybe<(
    { __typename: 'UserModel' }
    & Pick<Types.UserModel, 'id' | 'email' | 'role' | 'companyId' | 'firstName' | 'lastName' | 'firstLogin' | 'timezone'>
    & { patient?: Types.Maybe<(
      { __typename: 'PatientModel' }
      & Pick<Types.PatientModel, 'id'>
    )>, therapist?: Types.Maybe<(
      { __typename: 'TherapistModel' }
      & Pick<Types.TherapistModel, 'id'>
    )>, avatar?: Types.Maybe<(
      { __typename: 'UserPublicFileModel' }
      & Pick<Types.UserPublicFileModel, 'source'>
    )>, subcategories?: Types.Maybe<Array<(
      { __typename: 'ResourcesSubcategoryDetailsModel' }
      & Pick<Types.ResourcesSubcategoryDetailsModel, 'id' | 'name' | 'imageUrl'>
    )>>, b2bRoles: Array<(
      { __typename: 'B2BUserRoleModel' }
      & Pick<Types.B2BUserRoleModel, 'id' | 'role' | 'createdAt' | 'updatedAt'>
    )> }
  )> }
);


export const UserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"User"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"companyId"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"firstLogin"}},{"kind":"Field","name":{"kind":"Name","value":"timezone"}},{"kind":"Field","name":{"kind":"Name","value":"patient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"therapist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"source"}}]}},{"kind":"Field","name":{"kind":"Name","value":"subcategories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}}]}},{"kind":"Field","name":{"kind":"Name","value":"b2bRoles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUser__
 *
 * To run a query within a React component, call `useUser` and pass it any options that fit your needs.
 * When your component renders, `useUser` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUser({
 *   variables: {
 *   },
 * });
 */
export function useUser(baseOptions?: Apollo.QueryHookOptions<User, UserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<User, UserVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<User, UserVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<User, UserVariables>(UserDocument, options);
        }
export type UserHookResult = ReturnType<typeof useUser>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<User, UserVariables>;