import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { AUTH_ROUTES, THERAPIST_ROUTES } from 'lib/routes';
import { DecodeTokenService, getSize, StorageService } from 'lib/utils';
import { Role } from '__generated__/types';

import { Button, RoundCheckIcon } from 'ui';
import { TERMS_AND_CONDITIONAL_FOR_PRACTITIONERS } from 'lib/constants/common';
import { AuthContentLayout } from 'layouts';

const TRAINING_CERTIFICATES = () => (
  <div>
    <p>Your professional training certificates</p>
    <p>
      {
        '(e.g. Clinical Psychology doctorate; MSc in Psychotherapy; Diploma in Psychodynamic Counselling; Level 4/5 Counselling Diploma)'
      }
    </p>
  </div>
);

const PROFESSIONAL_PHOTO = () => (
  <div>
    <p>A professional profile photo</p>
    <p>(a headshot on a plain white background)</p>
  </div>
);
const DOCUMENTS_LIST = [
  TRAINING_CERTIFICATES(),
  'Your current professional indemnity insurance certificate (minimum coverage £1 million).',
  PROFESSIONAL_PHOTO(),
  'A current DBS check (within the last 3 years)',
];

const therapistRole = Role.Therapist.toLowerCase();

function Welcome() {
  const history = useHistory();

  const userRole = useMemo(() => {
    const accessToken = StorageService.getAccessToken();
    return DecodeTokenService.getUserRoleFromToken(accessToken);
  }, []);

  const handleClick = () => {
    history.push(
      userRole
        ? THERAPIST_ROUTES.DASHBOARD
        : AUTH_ROUTES.SIGN_UP.pathBuild(therapistRole),
    );
  };
  return (
    <AuthContentLayout title="Welcome">
      <MainText>
        {`We're delighted that you're interested in joining Lumo Health and becoming part of our community. We understand the value of a supportive network in private practice and aim to create a transparent environment.`}
      </MainText>

      <BoldText>
        To register with us today, you’ll need to be able to upload the
        following documents:
      </BoldText>

      <DocumentsList>
        {DOCUMENTS_LIST.map((document, index) => (
          <DocumentItem key={index}>
            <Icon />
            {document}
          </DocumentItem>
        ))}
      </DocumentsList>

      <AdditionalText>
        {
          'We require all of our psychologists, psychotherapists and counsellors to have been practicing as qualified therapists for '
        }
        <HighlightedText>at least 2 years</HighlightedText>
        {
          ' (min. 300 clinical hours post training). We also ask that our therapists offer '
        }
        <HighlightedText>a minimum of 5 sessions per week</HighlightedText> with
        us.
      </AdditionalText>

      <RegistrationButton theme="tertiary" onClick={handleClick}>
        {userRole ? 'CONTINUE' : `REGISTER`}
      </RegistrationButton>

      <LastText>
        By proceeding to the registration form, you are agreeing to our{' '}
        <MailLink
          title="Privacy Policy "
          href={TERMS_AND_CONDITIONAL_FOR_PRACTITIONERS}
        >
          Terms & Conditions for Therapists and Counsellors
        </MailLink>
      </LastText>
    </AuthContentLayout>
  );
}

const BoldText = styled.p`
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(22)};
  color: var(--black);
  margin-bottom: ${getSize(17)};
  max-width: ${getSize(346)};
`;

const MainText = styled.p`
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: var(--gray13);
  margin: ${getSize(10)} 0 ${getSize(17)};
`;

const AdditionalText = styled(MainText)`
  font-weight: 500;
  margin: 0 0 ${getSize(24)};
`;

const HighlightedText = styled.span`
  font-weight: 600;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: var(--gray13);
`;
const MailLink = styled.a`
  color: var(--gray2);
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
`;

const DocumentsList = styled.ul`
  margin: 0 0 ${getSize(23)};
  list-style: none;
`;

const DocumentItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${getSize(12)} 0 0;
  font-weight: 500;
  font-size: ${getSize(12)};
  line-height: ${getSize(17)};
  color: var(--gray13);
  border-top: ${getSize(1)} solid var(--gray10);

  &:not(:last-child) {
    padding: ${getSize(12)} 0;
  }
`;

const Icon = styled(RoundCheckIcon)`
  margin-right: ${getSize(24)};
  flex-shrink: 0;
`;

const LastText = styled.p`
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  color: var(--gray2);
  font-size: ${getSize(10)};
  line-height: ${getSize(18)};
  max-width: ${getSize(330)};
`;

const RegistrationButton = styled(Button)`
  width: 100%;
  margin-bottom: ${getSize(20)};
`;

export default Welcome;
