import * as Types from '../../../../__generated__/types';

import { ChatFragment } from '../../query/__generated__/chats-list';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReadChatVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ReadChat = (
  { __typename: 'Mutation' }
  & { readChat: (
    { __typename: 'ChatModel' }
    & ChatFragment
  ) }
);


export const ReadChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ReadChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"readChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatFragment"}}]}}]}},...ChatFragment.definitions]} as unknown as DocumentNode;
export type ReadChatMutationFn = Apollo.MutationFunction<ReadChat, ReadChatVariables>;

/**
 * __useReadChat__
 *
 * To run a mutation, you first call `useReadChat` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadChat` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readChat, { data, loading, error }] = useReadChat({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadChat(baseOptions?: Apollo.MutationHookOptions<ReadChat, ReadChatVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadChat, ReadChatVariables>(ReadChatDocument, options);
      }
export type ReadChatHookResult = ReturnType<typeof useReadChat>;
export type ReadChatMutationResult = Apollo.MutationResult<ReadChat>;
export type ReadChatMutationOptions = Apollo.BaseMutationOptions<ReadChat, ReadChatVariables>;