import React from 'react';
import { Switch } from 'react-router-dom';
import { PATIENT_ROUTES } from 'lib/routes';
import { PatientLayout } from 'layouts/patient';
import { TeamsPrivateRoute } from '../../components/teams-private-route';
import TeamsResourcesRoutes from '../patient/teams-resources';
import { useUser } from '../../common/query/__generated__/get-user';
import { updateUserMeInfo } from '../../lib/utils';
import { UserModel } from '../../__generated__/types';
import * as Intercom from 'lib/intercom';

// Routes and features for teams
const { RESOURCES: Patient } = PATIENT_ROUTES;

const TeamsPatientRoutes = () => {
  const { data: user } = useUser({
    onCompleted: (data) => {
      updateUserMeInfo(data.me as UserModel);
      Intercom.update(Intercom.userFromModel(data.me as UserModel));
    },
  });
  return (
    <PatientLayout>
      <Switch>
        <TeamsPrivateRoute path={Patient} component={TeamsResourcesRoutes} />
      </Switch>
    </PatientLayout>
  );
};

export default TeamsPatientRoutes;
