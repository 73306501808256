import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

/** Custom components */
import CategoryItem from './category-item';

/** Constants */
import { PATIENT_RESOURCES_ROUTES } from 'lib/routes';
import { Loader } from 'ui';

interface Category {
  id: string;
  name: string;
  iconUrl: string;
}

interface CategoryNavigationProps {
  categories?: Category[];
  activeCategoryId?: string;
  categoriesLoading: boolean;
}

/**
 * Renders a navigation component that displays a list of categories.
 */
const CategoryNavigation = (props: CategoryNavigationProps) => {
  const history = useHistory();
  const { categories, activeCategoryId, categoriesLoading } = props;

  if (categoriesLoading) {
    return <Loader />;
  } else if (categories?.length === 0) {
    return null;
  }

  const handleCategoryPress = (categoryId: string) => {
    history.push(PATIENT_RESOURCES_ROUTES.ALL_CONTENT.pathBuild(categoryId));
  };

  const renderCategories = () => {
    return categories?.map((category, i) => {
      return (
        <CategoryItem
          key={i}
          name={category.name}
          isActive={category.id === activeCategoryId}
          onClick={() => handleCategoryPress(category.id)}
        />
      );
    });
  };

  return (
    <div className="container g-0">
      <NaviContainer>{renderCategories()}</NaviContainer>
    </div>
  );
};

const NaviContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding-bottom: 5px;

  ::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: 0 0 5px var(--purple3);
    border-radius: 3px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--purple2);
    border-radius: 3px;
  }
`;

export default CategoryNavigation;
