import React from 'react';

function CanceledPaymentIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16.001A8 8 0 0012 20z"
        fill="#ED6C6C"
      />
      <path
        d="M8.464 8.464l7.072 7.072m0-7.072l-7.072 7.072"
        stroke="#ED6C6C"
        strokeWidth={2}
      />
    </svg>
  );
}

export default CanceledPaymentIcon;
