import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { CheckedSelectIcon, AlertIcon } from 'ui/icons';
import { getSize } from './size';
import { SentryService } from 'lib';

interface Notification {
  title?: string;
  text?: string;
}

export const notifySuccess = ({ title, text }: Notification) => {
  SentryService.addBreadcrumb({
    category: 'notification',
    message: `notifySuccess: ${JSON.stringify({ title, text })}`,
    level: 'info',
  });
  toast(
    <Wrapper>
      <CheckIcon />
      <Inner>
        {title && <Title>{title}</Title>}
        <Text>{text}</Text>
      </Inner>
    </Wrapper>,
    {
      containerId: 'toastInvite',
      className: 'inviteToast__container',
      bodyClassName: 'inviteToast__inner',
    },
  );
};

export const notifyError = ({ title, text }: Notification) => {
  SentryService.addBreadcrumb({
    category: 'notification',
    message: `notifyError: ${JSON.stringify({ title, text })}`,
    level: 'info',
  });
  toast(
    <Wrapper>
      <WarningIcon />
      <Inner>
        {title && <Title>{title}</Title>}
        <Text>{text}</Text>
      </Inner>
    </Wrapper>,
    {
      containerId: 'toastError',
      className: 'errorToast__container',
      bodyClassName: 'errorToast__inner',
    },
  );
};

const Wrapper = styled.section`
  display: flex;
  padding: ${getSize(3)} ${getSize(10)} ${getSize(5)};
  z-index: 999999;
`;

const CheckIcon = styled(CheckedSelectIcon)`
  flex-shrink: 0;
  margin: 0 ${getSize(15)} 0 0;
  width: ${getSize(25)};
  height: ${getSize(25)};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h3`
  margin: 0 0 ${getSize(10)};
  font-weight: 600;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--black3);
`;

const Text = styled.p`
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);
`;

const WarningIcon = styled(AlertIcon)`
  flex-shrink: 0;
  margin: 0 ${getSize(15)} 0 0;
  width: ${getSize(25)};
  height: ${getSize(25)};
`;
