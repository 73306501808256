import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TherapistVerificationsVariables = Types.Exact<{ [key: string]: never; }>;


export type TherapistVerifications = (
  { __typename: 'Query' }
  & { me?: Types.Maybe<(
    { __typename: 'UserModel' }
    & VerifiedFragment
  )> }
);

export type VerifiedFragment = (
  { __typename: 'UserModel' }
  & Pick<Types.UserModel, 'id' | 'email' | 'onboarded' | 'verified'>
  & { therapist?: Types.Maybe<(
    { __typename: 'TherapistModel' }
    & Pick<Types.TherapistModel, 'id' | 'requestStatus' | 'stripeAccountSubmitted' | 'stripePayoutsEnabled' | 'stripeAccountId' | 'createdAt'>
  )> }
);

export const VerifiedFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VerifiedFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"therapist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"requestStatus"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"stripeAccountSubmitted"}},{"kind":"Field","name":{"kind":"Name","value":"stripePayoutsEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"stripeAccountId"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}},{"kind":"Field","name":{"kind":"Name","value":"onboarded"}},{"kind":"Field","name":{"kind":"Name","value":"verified"}}]}}]} as unknown as DocumentNode;
export const TherapistVerificationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TherapistVerifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VerifiedFragment"}}]}}]}},...VerifiedFragment.definitions]} as unknown as DocumentNode;

/**
 * __useTherapistVerifications__
 *
 * To run a query within a React component, call `useTherapistVerifications` and pass it any options that fit your needs.
 * When your component renders, `useTherapistVerifications` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistVerifications({
 *   variables: {
 *   },
 * });
 */
export function useTherapistVerifications(baseOptions?: Apollo.QueryHookOptions<TherapistVerifications, TherapistVerificationsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistVerifications, TherapistVerificationsVariables>(TherapistVerificationsDocument, options);
      }
export function useTherapistVerificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistVerifications, TherapistVerificationsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistVerifications, TherapistVerificationsVariables>(TherapistVerificationsDocument, options);
        }
export type TherapistVerificationsHookResult = ReturnType<typeof useTherapistVerifications>;
export type TherapistVerificationsLazyQueryHookResult = ReturnType<typeof useTherapistVerificationsLazyQuery>;
export type TherapistVerificationsQueryResult = Apollo.QueryResult<TherapistVerifications, TherapistVerificationsVariables>;