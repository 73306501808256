import React from 'react';

function DownloadIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#444752"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path d="M14.857 10.286a.572.572 0 00-.572.571v3.429a.571.571 0 01-.571.571H2.285a.571.571 0 01-.571-.571v-3.429a.571.571 0 10-1.143 0v3.429c0 .946.768 1.714 1.714 1.714h11.429c.947 0 1.714-.768 1.714-1.714v-3.429a.571.571 0 00-.571-.571z" />
      <path d="M11.248 9.882a.572.572 0 00-.794 0l-1.883 1.881V.571a.571.571 0 10-1.143 0v11.192L5.547 9.882a.571.571 0 00-.808.808l2.857 2.857a.571.571 0 00.808 0h.001l2.857-2.857a.571.571 0 00-.014-.808z" />
    </svg>
  );
}

export default DownloadIcon;
