import { getSize } from 'lib/utils';

const THEMES: any = {
  primary: () => `
  .DayPicker-wrapper {
    padding: ${getSize(10)} 0;
  }

  .DayPicker-Month {
    font-weight: 400;
    font-size: ${getSize(14)};
    line-height: ${getSize(14)};
    color: var(--black3);
    margin: 1em 0 0;
  }

  .DayPicker-Weekday {
    width: ${getSize(42)};
    height: ${getSize(42)};
    padding: ${getSize(12)};
    font-size: ${getSize(14)};
    line-height: ${getSize(18)};
    color: var(--gray2);
  }

  .DayPicker-Day {
    position: relative;
    z-index: 1;
    width: ${getSize(42)};
    height: ${getSize(42)};
    font-size: ${getSize(14)};
    line-height: ${getSize(18)};

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      width: ${getSize(32)};
      height: ${getSize(32)};
      border-radius: ${getSize(8)};
      transform: translate(-50%, -50%);
      transition: background 0.2s ease-out;
    }

    &:hover {
      &:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected) {
        background: 0 !important;

        &::before {
          background: var(--purple3);
        }
      }
    }

    &--today {
      &:not(.DayPicker-Day--outside) {
        background: 0;
        color: var(--purple);

        &::before {
          background: var(--purple5);
        }
      }
    }

    &--selected {
      &:not(.DayPicker-Day--outside) {
        background: 0;
        color: white;

        &::before {
          background: var(--purple);
        }

        &:hover {
          background: 0 !important;

          &::before {
            background: var(--purple);
          }
        }
      }
    }

    &--disabled {
      color: var(--black3);
    }
  }
  `,
  secondary: (isLoading: boolean) => `
  .DayPicker-wrapper {
    padding: 0;
  }

  .DayPicker-Month {
    font-weight: 400;
    font-size: ${getSize(14)};
    line-height: ${getSize(14)};
    color: var(--black3);
    margin: 1em 0 0;
  }

  .DayPicker-Weekday {
    width: ${getSize(42)};
    height: ${getSize(42)};
    padding: ${getSize(12)};
    font-size: ${getSize(12)};
    line-height: ${getSize(20)};
    color: var(--black3);
  }

  .DayPicker-Day {
    position: relative;
    z-index: 1;
    width: ${getSize(42)};
    height: ${getSize(42)};
    font-size: ${getSize(12)};
    line-height: ${getSize(20)};

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      width: ${getSize(30)};
      height: ${getSize(30)};
      border-radius: ${getSize(8)};
      transform: translate(-50%, -50%);
      transition: background 0.2s ease-out;
    }

    &:hover {
      &:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected) {
        background: 0 !important;

        &::before {
          border: 1px solid var(--gray22);
        }
      }
    }

    &--today {
      &:not(.DayPicker-Day--outside) {
        background: 0;
        font-weight: 700;
        color: var(--black3);
      }
    }

    &--selected {
      &:not(.DayPicker-Day--outside) {
        background: 0;
        font-weight: 700;
        color: var(--black3);

        &::before {
          background: 0;
          color: var(--black3);
          border-radius: ${getSize(8)};
          border: 1px solid var(--black6);
        }

        &:hover {
          background: 0 !important;
        }
      }
    }

    &--disabled {
      color: var(--gray7);
    }
  }
  `,
};

export default THEMES;
