import React from 'react';

function ShieldIcon({ ...rest }) {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...rest}>
      <g clipPath="url(#prefix__clip0)" fill="#B4B0BB">
        <path d="M12.984 2.86a.864.864 0 00-.52-.668L7.328.066a.854.854 0 00-.658 0L1.537 2.192a.864.864 0 00-.521.668c-.108.725-.289 2.644.36 4.793a10.07 10.07 0 001.678 3.268c.905 1.17 2.092 2.169 3.529 2.971a.856.856 0 00.834 0c1.437-.802 2.624-1.802 3.529-2.97a10.07 10.07 0 001.679-3.27c.648-2.148.467-4.067.36-4.792zm-.752 4.675c-.77 2.552-2.457 4.571-5.014 6a.45.45 0 01-.436 0c-2.557-1.429-4.244-3.448-5.015-6a10.973 10.973 0 01-.346-4.615.452.452 0 01.272-.35L6.828.445a.448.448 0 01.344 0l5.135 2.127c.145.06.249.194.272.35.104.697.278 2.544-.347 4.614z" />
        <path d="M11.402 3.155l-2.007-.819a.205.205 0 10-.154.38l2.007.818a.316.316 0 01.193.244c.083.55.222 2.003-.277 3.632-.615 2.01-1.964 3.6-4.008 4.724a.327.327 0 01-.312 0C4.799 11.01 3.45 9.42 2.836 7.41a8.516 8.516 0 01-.277-3.632.316.316 0 01.193-.244l4.124-1.683a.33.33 0 01.247 0l1.239.506a.205.205 0 00.155-.38l-1.239-.505a.733.733 0 00-.557 0L2.597 3.155a.728.728 0 00-.443.562 8.941 8.941 0 00.29 3.813c.291.951.747 1.826 1.356 2.601.73.93 1.687 1.725 2.846 2.362a.734.734 0 00.708 0c1.158-.637 2.116-1.432 2.846-2.362a7.97 7.97 0 001.356-2.6 8.94 8.94 0 00.29-3.814.728.728 0 00-.444-.562z" />
        <path d="M6.592 9.484h.816a.53.53 0 00.53-.527V8.05a.12.12 0 01.121-.118h.913a.53.53 0 00.53-.528v-.81a.53.53 0 00-.53-.527h-.913a.12.12 0 01-.12-.118v-.906a.53.53 0 00-.53-.527h-.817a.53.53 0 00-.53.527v.906a.12.12 0 01-.12.118h-.913a.53.53 0 00-.53.527v.81a.53.53 0 00.46.524.205.205 0 10.053-.407.119.119 0 01-.104-.117v-.81a.12.12 0 01.12-.118h.913a.53.53 0 00.53-.527v-.906a.12.12 0 01.12-.118h.817a.12.12 0 01.12.118v.906c0 .29.239.527.531.527h.913a.12.12 0 01.12.118v.81a.12.12 0 01-.12.118h-.913a.53.53 0 00-.53.528v.906a.12.12 0 01-.12.117h-.817a.12.12 0 01-.12-.117V8.05a.53.53 0 00-.53-.528.205.205 0 100 .41.12.12 0 01.12.118v.906c0 .29.238.527.53.527z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShieldIcon;
