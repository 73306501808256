import React from 'react';

function InPersonIconWhite({ ...rest }) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 2.28571C0 1.02335 1.06438 0 2.37736 0H11.6226C12.9356 0 14 1.02335 14 2.28571V13.7143C14 14.9767 12.9356 16 11.6226 16H2.37736C1.06438 16 0 14.9767 0 13.7143V2.28571Z"
        fill="white"
      />
      <path
        d="M7 9C8.65685 9 10 7.65685 10 6C10 4.34315 8.65685 3 7 3C5.34315 3 4 4.34315 4 6C4 7.65685 5.34315 9 7 9Z"
        fill="#8065F3"
      />
      <path
        d="M3 11.5C3 10.6716 3.67157 10 4.5 10H9.5C10.3284 10 11 10.6716 11 11.5V11.5C11 12.3284 10.3284 13 9.5 13H4.5C3.67157 13 3 12.3284 3 11.5V11.5Z"
        fill="#7D61F1"
      />
    </svg>
  );
}

export default InPersonIconWhite;
