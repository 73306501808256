import React from 'react';

function MessageArrowIcon({ ...rest }) {
  return (
    <svg
      width="22"
      height="6"
      viewBox="0 0 22 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.00003H10.8972H11.1028H22C22 6.00003 19.2312 5.62853 17.9626 5.07705C16.6482 4.50561 15.4804 3.35554 14.4028 2.2943C13.1849 1.09491 12.0823 0.00899353 11.0133 8.84437e-05C11.0089 2.97732e-05 11.0045 2.48911e-07 11 0C9.92689 -5.98988e-05 8.82014 1.08991 7.59722 2.29428C6.51963 3.35553 5.35185 4.50561 4.03738 5.07705C2.76885 5.62853 0 6.00003 0 6.00003Z"
        fill="#000000"
      />
    </svg>
  );
}

export default MessageArrowIcon;
