import { makeVar } from '@apollo/client';
import { Role } from '__generated__/types';

export interface UserMeInfo {
  firstName: string | null;
  userId: string | null;
  isB2B: boolean;
  isTherapist: boolean;
  therapistId: string | null;
  isFirstLogin: boolean;
  b2bRoles?: Array<any>;
  role: Role | null;
}

export const userMeInfoVar = makeVar<UserMeInfo>({
  firstName: '',
  userId: null,
  isB2B: false,
  isTherapist: false,
  therapistId: null,
  isFirstLogin: true,
  b2bRoles: [],
  role: null,
});
