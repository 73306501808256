import { getSize } from 'lib/utils';
import React, { FC, useEffect } from 'react';
import styled, { CSSProp } from 'styled-components';
import { CalendarSmall } from 'ui/calendar-small';
import { DatesRange } from 'ui/calendar-small/utils/add-day-to-range';
import { DayInsideRange } from './components/indexs';

interface CalendarDropProps {
  isRange?: boolean;
  selectedRange?: DatesRange;
  onDayClickRange?: (value: DatesRange) => void;
  calendarWrapperCSS?: CSSProp;
  selectedDays?: Date | Date[];
  onDayClick?: (value: Date | Date[]) => void;
  calendarCSS?: CSSProp;
  onWindowClick: () => void;
}

const CalendarDrop: FC<CalendarDropProps> = ({
  isRange,
  selectedRange,
  onDayClick,
  onDayClickRange,
  selectedDays,
  calendarWrapperCSS,
  calendarCSS,
  onWindowClick,
}) => {
  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    return () => window.removeEventListener('click', onWindowClick);
  }, [onWindowClick]);

  return (
    <CalendarWrapper
      onClick={(e) => e.stopPropagation()}
      $CSS={calendarWrapperCSS}
    >
      <CalendarSmall
        selectedDays={selectedDays}
        onDayClick={(date) => onDayClick && onDayClick(date)}
        isRange={isRange}
        selectedRange={selectedRange}
        onDayClickRange={onDayClickRange}
        rootCSS={calendarCSS}
        renderDay={(day: Date) => (
          <DayInsideRange day={day} datesRange={selectedRange} />
        )}
      />
    </CalendarWrapper>
  );
};

const CalendarWrapper = styled.div<{ $CSS?: CSSProp }>`
  position: absolute;
  right: 0;
  top: 100%;
  padding: ${getSize(10)};
  box-shadow: 0 ${getSize(10)} ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
  background-color: var(--white);
  z-index: 100000;

  ${({ $CSS }) => $CSS}
`;

export default CalendarDrop;
