import React, { useCallback } from 'react';
import styled, { CSSProp } from 'styled-components';
import { FieldRenderProps } from 'react-final-form';
import { FourDots, MasterCardIcon, TrashIcon, VisaClassicIcon } from 'ui/icons';
import { getSize } from 'lib/utils';
import { Loader } from 'ui/loader';

export interface CreditCardRadioButtonProps extends FieldRenderProps<string> {
  label: string;
  radioRootCSS?: CSSProp;
  radioButtonCSS?: CSSProp;
  removeItem?: (id: string) => void;
  id: string;
  brand?: string | null;
  isLoading?: boolean;
  iconProps?: { [x: string]: any };
}

function CreditCardRadioButton({
  label,
  input,
  brand,
  radioRootCSS,
  id,
  removeItem,
  isLoading,
  radioButtonCSS,
  iconProps,
}: CreditCardRadioButtonProps): JSX.Element {
  const isActive = input.checked;

  const renderCardIcon = useCallback(() => {
    if (brand) {
      switch (brand) {
        case 'mastercard':
          return <StyledMasterCardIcon $CSS={iconProps} />;
        case 'visa':
          return <StyledVisaClassicIcon $CSS={iconProps} />;
        default:
          break;
      }
    }
  }, [brand, iconProps]);

  return (
    <Wrapper $CSS={radioRootCSS} $isActive={isActive}>
      <Input
        name={input.name}
        type="radio"
        onChange={input.onChange}
        checked={isActive}
        value={input.value}
      />

      <Indicator $CSS={radioButtonCSS} $isActive={isActive} />

      <CardIconWrapper>{renderCardIcon()}</CardIconWrapper>

      <Dots $isActive={isActive} />

      <Text $isActive={isActive}>{label}</Text>

      {removeItem && (
        <RemoveButton
          type="button"
          onClick={(e) => {
            e.preventDefault();
            removeItem(id);
          }}
        >
          {isLoading ? <Loader /> : <RemoveText>Delete</RemoveText>}
        </RemoveButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp; $isActive?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${getSize(8)};
  cursor: pointer;
  background-color: var(--purple11);
  padding: ${getSize(15)} ${getSize(23)};

  &:not(:last-child) {
    margin-bottom: ${getSize(10)};
  }

  ${({ $CSS }) => $CSS};
`;

const Input = styled.input`
  display: none;
`;

const Text = styled.p<{ $isActive?: boolean }>`
  color: var(${({ $isActive }) => ($isActive ? ' --black3' : '--gray7')});
`;

const Indicator = styled.span<{ $CSS?: CSSProp; $isActive?: boolean }>`
  min-width: ${getSize(18)};
  min-height: ${getSize(18)};
  position: relative;
  border-radius: 100%;
  margin-right: ${getSize(23)};

  border: ${({ $isActive }) =>
    $isActive
      ? `${getSize(6)} solid var(--purple)`
      : `${getSize(1)} solid var(--purple5)`};

  ${({ $CSS }) => $CSS};
`;

const CardIconWrapper = styled.span`
  margin-right: ${getSize(15)};
`;

const Dots = styled(FourDots)<{ $isActive?: boolean }>`
  margin-right: ${getSize(12)};

  ${({ $isActive }) => !$isActive && 'fill: var(--gray7);'}
`;

const RemoveButton = styled.button`
  min-height: ${getSize(22)};
  margin-left: auto;
`;

const StyledMasterCardIcon = styled(MasterCardIcon)<{ $CSS?: CSSProp }>`
  ${({ $CSS }) => $CSS}
`;
const StyledVisaClassicIcon = styled(VisaClassicIcon)<{ $CSS?: CSSProp }>`
  ${({ $CSS }) => $CSS}
`;

const RemoveText = styled.p`
  font-family: Poppins;
  font-size: ${getSize(13)};
  font-weight: 500;
  line-height: ${getSize(24)};
  text-align: right;
  color: var(--gray41);
`;

export default CreditCardRadioButton;
