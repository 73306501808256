import React from 'react';

function WorksheetsIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.90625 12.2891C3.12614 12.2891 0.0507812 15.3644 0.0507812 19.1445C0.0507812 22.9246 3.12614 26 6.90625 26C10.6864 26 13.7617 22.9246 13.7617 19.1445C13.7617 15.3644 10.6864 12.2891 6.90625 12.2891ZM9.93312 18.312L6.37843 21.8667C6.22974 22.0154 6.03479 22.0898 5.83984 22.0898C5.64489 22.0898 5.44995 22.0154 5.30126 21.8667L3.77782 20.3433C3.48034 20.0458 3.48034 19.5635 3.77782 19.2661C4.07525 18.9686 4.55757 18.9686 4.85504 19.2661L5.83984 20.2509L8.85595 17.2348C9.15337 16.9374 9.63569 16.9374 9.93317 17.2348C10.2306 17.5323 10.2306 18.0146 9.93312 18.312Z"
        fill="white"
      />
      <path
        d="M20.1094 6.60156C19.6887 6.60156 19.3477 6.26052 19.3477 5.83984V0H8.42969C7.16965 0 6.14453 1.02512 6.14453 2.28516V10.8006C6.39549 10.7779 6.64945 10.7656 6.90625 10.7656C9.50259 10.7656 11.8268 11.9527 13.3649 13.8125H21.6328C22.0535 13.8125 22.3945 14.1535 22.3945 14.5742C22.3945 14.9949 22.0535 15.3359 21.6328 15.3359H14.3685C14.8446 16.265 15.1516 17.2943 15.2502 18.3828H21.6328C22.0535 18.3828 22.3945 18.7239 22.3945 19.1445C22.3945 19.5652 22.0535 19.9062 21.6328 19.9062H15.2502C15.0224 22.4219 13.6783 24.6201 11.7182 26H23.6641C24.9241 26 25.9492 24.9749 25.9492 23.7148V6.60156H20.1094ZM21.6328 10.7656H10.4609C10.0403 10.7656 9.69922 10.4246 9.69922 10.0039C9.69922 9.58323 10.0403 9.24219 10.4609 9.24219H21.6328C22.0535 9.24219 22.3945 9.58323 22.3945 10.0039C22.3945 10.4246 22.0535 10.7656 21.6328 10.7656Z"
        fill="white"
      />
      <path
        d="M20.8711 0.445312V5.07743H25.5029L20.8711 0.445312Z"
        fill="white"
      />
    </svg>
  );
}

export default WorksheetsIcon;
