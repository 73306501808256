import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { FieldArray } from 'react-final-form-arrays';
import { useForm, useFormState } from 'react-final-form';
import { FieldValidator } from 'final-form';

import { Button, CloseButton } from 'ui';
import { PlusIcon } from 'ui/icons';
import { FieldsSection } from '../fields-section';
import { getSize } from 'lib/utils';

export interface FieldsArrayWrapperProps {
  className?: string;
  fieldsArrayName: string;
  validate?: FieldValidator<any[]>;
  renderItem: (fieldName: string, fieldIndex: number) => JSX.Element;
  itemCSS?: CSSProp;
  addButtonCSS?: CSSProp;
  havePlusIcon?: boolean;
  addBtnTitle?: string;
  maxFieldsCount?: number;
  minFieldsCount?: number;
  canBeEmpty?: boolean;
  titleWhenEmpty?: string;
  closeButtonCSS?: CSSProp;
}

function FieldsArrayWrapper({
  className,
  fieldsArrayName,
  validate,
  renderItem,
  itemCSS,
  addButtonCSS,
  havePlusIcon,
  addBtnTitle,
  maxFieldsCount,
  minFieldsCount = 1,
  canBeEmpty,
  titleWhenEmpty,
  closeButtonCSS,
}: FieldsArrayWrapperProps) {
  const { mutators } = useForm();
  const { values } = useFormState();
  const fieldsCount = values[fieldsArrayName]?.length || 0;

  return (
    <div className={className}>
      <FieldArray name={fieldsArrayName} validate={validate}>
        {({ fields }) =>
          fields.length ? (
            fields.map((fieldName, fieldIndex) => (
              <FieldsArrayItem key={fieldName} $CSS={itemCSS}>
                {(fieldIndex >= minFieldsCount || canBeEmpty) && (
                  <CloseButtonStylized
                    $CSS={closeButtonCSS}
                    onClick={() => fields.remove(fieldIndex)}
                  />
                )}
                {renderItem(fieldName, fieldIndex)}
              </FieldsArrayItem>
            ))
          ) : (
            <FieldsSection title={titleWhenEmpty} />
          )
        }
      </FieldArray>

      {maxFieldsCount && maxFieldsCount > fieldsCount && (
        <AddFieldButton
          $CSS={addButtonCSS}
          theme="secondary"
          onClick={() => mutators.push(fieldsArrayName, undefined)}
          type="button"
        >
          {havePlusIcon && <Icon />}
          {addBtnTitle || 'Add'}
        </AddFieldButton>
      )}
    </div>
  );
}

const FieldsArrayItem = styled.div<{ $CSS?: CSSProp }>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ $CSS }) => $CSS}
`;

const CloseButtonStylized = styled(CloseButton)<{ $CSS?: CSSProp }>`
  align-self: flex-end;

  ${({ $CSS }) => $CSS}
`;

const AddFieldButton = styled(Button)<{ $CSS?: CSSProp }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${getSize(6)};
  min-height: ${getSize(32)};
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

const Icon = styled(PlusIcon)`
  margin-right: ${getSize(7)};
`;

export default FieldsArrayWrapper;
