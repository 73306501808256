import React from 'react';

function ThreeDotsTriangleIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      {...rest}
    >
      <path
        d="M9.00013 0C6.59288 0 4.64149 1.94791 4.64149 4.35084C4.64149 6.7535 6.59288 8.70128 9.00013 8.70128C11.4073 8.70128 13.3586 6.7535 13.3586 4.35084C13.3586 1.94791 11.4073 0 9.00013 0ZM9.00013 6.13338C8.01369 6.13338 7.21416 5.33532 7.21416 4.35084C7.21416 3.36622 8.01369 2.56817 9.00013 2.56817C9.98644 2.56817 10.786 3.36622 10.786 4.35084C10.786 5.33532 9.98644 6.13338 9.00013 6.13338ZM8.71728 12.6494C8.71728 10.2466 6.76589 8.29885 4.35864 8.29885C1.95152 8.29885 0 10.2465 0 12.6494C0 15.0524 1.95152 17.0001 4.35864 17.0001C6.76589 17 8.71728 15.0522 8.71728 12.6494ZM2.57266 12.6494C2.57266 11.6651 3.37233 10.8669 4.35864 10.8669C5.34495 10.8669 6.14448 11.6651 6.14448 12.6494C6.14448 13.634 5.34495 14.4321 4.35864 14.4321C3.37233 14.4321 2.57266 13.634 2.57266 12.6494ZM18 12.6494C18 10.2466 16.0486 8.29885 13.6414 8.29885C11.2342 8.29885 9.28272 10.2466 9.28272 12.6494C9.28272 15.0522 11.2342 17.0001 13.6414 17.0001C16.0486 17 18 15.0522 18 12.6494ZM13.6414 14.4321C12.655 14.4321 11.8555 13.634 11.8555 12.6494C11.8555 11.6651 12.655 10.8669 13.6414 10.8669C14.6277 10.8669 15.4273 11.6651 15.4273 12.6494C15.4273 13.634 14.6278 14.4321 13.6414 14.4321Z"
        fill="#674FDE"
      />
    </svg>
  );
}

export default ThreeDotsTriangleIcon;
