import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { useHistory } from 'react-router-dom';

// Logo
import logoFull from 'assets/images/logo-full.png';

export interface LogoLinkProps {
  className?: string;
}

function LogoLink({ className }: LogoLinkProps) {
  const history = useHistory();

  function Wrapper({ ...rest }) {
    return <ExternalLink {...rest} />;
  }

  return (
    <Wrapper className={className} onClick={() => history.push('/')}>
      <Logo src={logoFull} alt="Logo" />
    </Wrapper>
  );
}

const ExternalLink = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  transition: 0.3s ease-out;
  cursor: pointer;
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
`;

const Logo = styled.img`
  width: auto;
  height: ${getSize(40)};
`;

export default LogoLink;
