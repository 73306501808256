import { GoogleMap } from '@react-google-maps/api';
import { OfficeLocationsInformation } from 'features/find-specialist/specialist-details/query/__generated__/get-therapist-details-query';
import { getSize } from 'lib/utils';
import React, { FC, useMemo } from 'react';
import styled, { CSSProp } from 'styled-components';
import { MapPinIcon } from 'ui/icons';
import { Markers, ToggleAddresses } from 'ui/locations-map/components';
import { Modal } from 'ui/modal';

const CONTAINER_STYLE = {
  width: getSize(816),
  height: getSize(543),
};

const PAPER_CSS: CSSProp = {
  position: 'relative',
  maxWidth: getSize(888),
};
const HEADER_CSS = {
  marginBottom: getSize(12),
};
const TITLE_CSS = {
  fontSize: getSize(18),
  lineHeight: getSize(32),
};
const TOGGLE_ADDRESS_ROOT_CSS = {
  bottom: getSize(48),
  right: getSize(120),
};

const defaultMapOptions = {
  fullscreenControl: false,
  draggable: false,
  draggableCursor: 'arrow',
};

interface LocationMapModalProps {
  isVisible: boolean;
  onClose: () => void;
  addressTitle?: string | string[];
  currentAddressIndex: number;
  setCurrentAddressIndex?: (value: number) => void;
  addressesCount: number;
  isSession?: boolean;
  locations: OfficeLocationsInformation[];
  isDetailedMap?: boolean;
}

const LocationMapModal: FC<LocationMapModalProps> = ({
  isVisible,
  onClose,
  addressTitle,
  currentAddressIndex,
  setCurrentAddressIndex,
  addressesCount,
  isSession,
  locations,
  isDetailedMap = false,
}) => {
  const centerCoordinates = useMemo(
    () =>
      locations[currentAddressIndex]
        ? {
            lat: locations[currentAddressIndex].lat,
            lng: locations[currentAddressIndex].lng,
          }
        : undefined,
    [locations, currentAddressIndex],
  );

  const isAddressTitleArray = addressTitle instanceof Array;

  const renderTitle = () => {
    if (isAddressTitleArray) {
      const addresses = addressTitle as string[];
      return (
        <Wrapper>
          {addresses.map((title, idx) => {
            return (
              <Wrapper key={title}>
                <Label>{`Address${idx === 0 ? '' : idx + 1}`}</Label>
                <Title
                  onClick={() =>
                    setCurrentAddressIndex && setCurrentAddressIndex(idx)
                  }
                >
                  <StyledMapPinIcon />
                  {title}
                </Title>
              </Wrapper>
            );
          })}
        </Wrapper>
      );
    }
  };

  return (
    <Modal
      title={isAddressTitleArray ? ' ' : (addressTitle as string)}
      isVisible={isVisible}
      onClose={onClose}
      paperCSS={PAPER_CSS}
      headerCSS={HEADER_CSS}
      titleCSS={TITLE_CSS}
      renderTitle={isAddressTitleArray ? renderTitle() : undefined}
    >
      <GoogleMap
        mapContainerStyle={CONTAINER_STYLE}
        center={centerCoordinates}
        options={defaultMapOptions}
        zoom={14}
      >
        <Markers
          currentAddressIndex={currentAddressIndex}
          locations={locations}
          isSession={isSession}
          isDetailedMap={isDetailedMap}
        />
      </GoogleMap>
      {!!(
        addressesCount > 1 &&
        currentAddressIndex &&
        setCurrentAddressIndex &&
        !isDetailedMap
      ) && (
        <ToggleAddresses
          currentAddressIndex={currentAddressIndex}
          setCurrentAddressIndex={setCurrentAddressIndex}
          addressesCount={addressesCount}
          rootCSS={TOGGLE_ADDRESS_ROOT_CSS}
        />
      )}
    </Modal>
  );
};

export default LocationMapModal;

const Title = styled.h2`
  font-weight: 600;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--black3);
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--black3);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledMapPinIcon = styled(MapPinIcon)`
  margin-right: ${getSize(9)};
`;
