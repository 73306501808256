import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isValidUserRole, StorageService } from 'lib/utils';
import {
  AUTH_ROUTES,
  ONBOARDING_ROUTES,
  PATIENT_RESOURCES_ROUTES,
  PATIENT_ROUTES,
  ROOT_ROUTES,
  THERAPIST_ROUTES,
} from 'lib/routes';
import { DecodeTokenService, getDefaultRouteByRole } from 'lib/utils';
import {
  Role,
  TherapistRequestStatus,
  UserModel,
} from '../__generated__/types';
import { useGetUserVerifiedLazyQuery } from 'common/query/__generated__/get-user-verified';
import { REGISTRATION_TYPE } from 'features/auth/check-email/check-email';
import { logout } from 'lib/auth';
import { notifyError } from 'lib/utils/notification';
import { PageName, PAGES } from 'ui/onboarding-wrapper/onboarding-wrapper';
import storageService from 'lib/utils/storage-service';
import { AmplitudeEvents, amplitudeService } from 'lib/amplitude';
import * as Intercom from 'lib/intercom';

const PATIENT_ROLE = Role.Patient.toLowerCase();

function getTherapistIdFromPatientBookUrlParams() {
  const bookUrlParams = StorageService.getPatientBookUrlParam();
  const decodeBase64 = atob(bookUrlParams || '');
  const parsedPatientBookUrlParams: {
    bookingPathWithParams: string;
  } | null = decodeBase64 ? JSON.parse(decodeBase64) : null;

  return parsedPatientBookUrlParams?.bookingPathWithParams;
}

function useRedirect() {
  const history = useHistory();
  const location = useLocation();
  const [fetchUserVerified, userVerifiedQuery] = useGetUserVerifiedLazyQuery();
  const onboardedStep = userVerifiedQuery.data?.me?.therapist?.onboardedStep;
  const isOnboarded = userVerifiedQuery.data?.me?.onboarded;
  const requestStatus = userVerifiedQuery.data?.me?.therapist?.requestStatus;
  useEffect(() => {
    if (
      typeof onboardedStep === 'number' &&
      requestStatus !== TherapistRequestStatus.Accepted
    ) {
      const page = Object.keys(PAGES)[onboardedStep - 1];

      if (isOnboarded) {
        history.push(ONBOARDING_ROUTES.FINISH_ONBOARDING);
      } else {
        StorageService.setOnboardingStep({
          currentStep: page as PageName,
          stepUrl: ONBOARDING_ROUTES.pathBuild(page),
        });
      }
    }
  }, [onboardedStep, isOnboarded, history, requestStatus]);

  useEffect(() => {
    const currentRoute = location.pathname;
    const accessToken = StorageService.getAccessToken();
    const isRedirectToGuidedMatchingJson =
      StorageService.getIsRedirectToGuidedMatching();
    const isRedirectToGuidedMatching =
      isRedirectToGuidedMatchingJson &&
      JSON.parse(isRedirectToGuidedMatchingJson);

    function checkAndNavigateToCorrectRoleRoute() {
      const [, roleInURL] = currentRoute.split('/');
      const roleFromToken =
        DecodeTokenService.getUserRoleFromToken(accessToken);
      const isPatientRole = roleFromToken === PATIENT_ROLE;
      const isOnboardingRoute = currentRoute.startsWith(ROOT_ROUTES.ONBOARDING);
      const isAuthWelcomeRoute = currentRoute.startsWith(AUTH_ROUTES.WELCOME);
      const isResetPasswordRoute = currentRoute.startsWith(
        AUTH_ROUTES.RESET_PASSWORD,
      );

      if (isPatientRole) {
        const bookUrlParams = getTherapistIdFromPatientBookUrlParams();
        if (isOnboardingRoute || isAuthWelcomeRoute) {
          return history.push(ROOT_ROUTES.NOT_MATCH_ROLE);
        } else if (isRedirectToGuidedMatching) {
          StorageService.removeIsRedirectToGuidedMatching();
          return history.push(PATIENT_ROUTES.GUIDED_MATCHING);
        } else if (bookUrlParams) {
          StorageService.removePatientBookUrlParams();
          return history.push(bookUrlParams);
        }
      }

      if (
        roleFromToken !== roleInURL &&
        !isOnboardingRoute &&
        !isAuthWelcomeRoute &&
        !isResetPasswordRoute
      ) {
        if (isValidUserRole(roleInURL)) {
          return history.push(ROOT_ROUTES.NOT_MATCH_ROLE);
        }

        const redirectRoute = getDefaultRouteByRole(roleFromToken);
        const contentRedirectRoute =
          StorageService.getContentAfterAuthRedirect();

        //  is therapist auth
        if (redirectRoute === THERAPIST_ROUTES.DASHBOARD) {
          if (storageService.getAfterAuthRedirect()) {
            amplitudeService.logEvent(
              AmplitudeEvents.THERAPIST_LOGIN_COMPLETED,
            );

            storageService.removeAfterAuthRedirect();
          }
        }
        if (contentRedirectRoute) {
          StorageService.removeContentAfterAuthRedirect();
          return history.push(contentRedirectRoute);
        } else if (redirectRoute) {
          return history.push(redirectRoute);
        }
      }
    }

    if (accessToken) {
      if (userVerifiedQuery.called) {
        if (userVerifiedQuery.data?.me) {
          Intercom.boot(
            // This will be missing a few key attributes, but we'll fill those in from other queries.
            Intercom.userFromModel(
              userVerifiedQuery.data?.me as unknown as UserModel,
            ),
          );
        }

        if (userVerifiedQuery.data?.me?.verified) {
          checkAndNavigateToCorrectRoleRoute();
        } else if (
          userVerifiedQuery.data &&
          !currentRoute.startsWith(ROOT_ROUTES.AUTH)
        ) {
          return history.replace(
            AUTH_ROUTES.CHECK_EMAIL.pathBuild(REGISTRATION_TYPE),
          );
        } else if (userVerifiedQuery.error?.message === 'User removed') {
          notifyError({
            title: 'Access denied',
            text: 'You are removed from the platform',
          });
          setTimeout(() => logout(), 3000);
          return;
        }
      } else {
        fetchUserVerified();
      }
    } else if (!currentRoute.startsWith(ROOT_ROUTES.AUTH)) {
      if (
        currentRoute.startsWith(
          PATIENT_RESOURCES_ROUTES.CONTENT_PREVIEW.PATH.substring(
            0,
            PATIENT_RESOURCES_ROUTES.CONTENT_PREVIEW.PATH.lastIndexOf('/'),
          ),
        )
      ) {
        StorageService.setContentAfterAuthRedirect(currentRoute);
      }
      return history.replace(AUTH_ROUTES.SIGN_IN);
    }
  }, [history, location, fetchUserVerified, userVerifiedQuery, onboardedStep]);
}

export default useRedirect;
