import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import PathItem from './path-item';

interface SubPathsProps {
  paths: Array<any>;
  activeSubPath?: string;
}

/**
 *
 * @description Component will display lables for chaning subpath on url.
 */
const SubPaths = (props: SubPathsProps) => {
  const history = useHistory();
  const { paths, activeSubPath } = props;

  const handleOnClick = (path: string) => {
    history.replace(path);
  };

  const renderPaths = () => {
    return paths.map((path, index) => {
      return (
        <PathItem
          key={index}
          isActive={path === activeSubPath}
          onClick={() => handleOnClick(path)}
        >
          {path}
        </PathItem>
      );
    });
  };

  return <Container>{renderPaths()}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;

export default SubPaths;
