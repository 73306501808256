import React, { ReactNode, useEffect } from 'react';
import styled, { CSSProp } from 'styled-components';
import { FieldRenderProps } from 'react-final-form';
import { getSize } from 'lib/utils';
import THEMES from './utils/themes';

export type RadioButtonTheme = 'primary' | 'secondary';
export interface RadioButtonProps extends FieldRenderProps<string> {
  label: ReactNode;
  theme?: RadioButtonTheme;
  radioRootCSS?: CSSProp;
  radioButtonCSS?: CSSProp;
  radioLabelCSS?: CSSProp;
  errorMessage: string;
  setErrorMessage: (error: string) => void;
  isAssessmentOutcome?: boolean;
  hasIndicator?: boolean;
}

function RadioButton({
  label,
  input,
  meta,
  theme = 'primary',
  radioRootCSS,
  radioButtonCSS,
  radioLabelCSS,
  errorMessage,
  setErrorMessage,
  isAssessmentOutcome,
  hasIndicator = true,
}: RadioButtonProps): JSX.Element {
  useEffect(() => {
    const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
    const hasError =
      ((error || submitError) && touched && !dirtySinceLastSubmit) ||
      data?.error;

    if (!errorMessage && hasError) {
      const newErrorMessage = error || submitError || data?.error;
      setErrorMessage(newErrorMessage);
    } else if (errorMessage && !hasError) {
      setErrorMessage('');
    }
  }, [errorMessage, meta, setErrorMessage]);

  const isChecked = input.checked;

  return (
    <Wrapper
      $CSS={radioRootCSS}
      $theme={theme}
      $isAssessmentOutcome={isAssessmentOutcome}
      $isChecked={isChecked}
      onClick={() => input.onChange(input.value)}
    >
      <label>
        <Input
          name={input.name}
          type="radio"
          onChange={input.onChange}
          checked={isChecked}
          value={input.value}
        />
      </label>

      {hasIndicator && (
        <Indicator $isChecked={isChecked} $CSS={radioButtonCSS} />
      )}
      <Text $CSS={radioLabelCSS}>{label}</Text>
    </Wrapper>
  );
}

const Wrapper = styled.div<{
  $CSS?: CSSProp;
  $isAssessmentOutcome?: boolean;
  $isChecked?: boolean;
  $theme: RadioButtonTheme;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${getSize(7)};
  cursor: pointer;
  margin-bottom: ${getSize(8)};

  ${({ $theme, $isChecked, $isAssessmentOutcome }) =>
    THEMES[$theme]?.($isChecked, $isAssessmentOutcome) || ''}
  ${({ $CSS }) => $CSS};
`;

const Input = styled.input`
  display: none;
`;

const Text = styled.div<{
  $CSS?: CSSProp;
}>`
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

const Indicator = styled.span<{ $isChecked?: boolean; $CSS?: CSSProp }>`
  flex-shrink: 0;
  width: ${getSize(18)};
  height: ${getSize(18)};
  position: relative;

  border-radius: 50%;
  margin-right: ${getSize(8)};
  border: ${getSize(1)} solid
    var(${({ $isChecked }) => ($isChecked ? '--purple' : '--purple5')});

  ${({ $isChecked }) =>
    $isChecked &&
    `background: radial-gradient(var(--purple) ${getSize(
      5,
    )}, transparent ${getSize(5)});`}

  ${({ $CSS }) => $CSS}
`;

export default RadioButton;
