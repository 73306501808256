import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { notifyError } from './notification';
import { config } from 'lib/utils';

class ApiService {
  request: AxiosInstance;

  constructor() {
    this.request = axios.create({ baseURL: `${config.API_URL}/` });
    this.request.interceptors.response.use(
      (r) => r,
      async (error) => {
        const errorResponse = error.response;
        if (!errorResponse) return;

        function showError() {
          notifyError({
            title: errorResponse.data.message,
            text: errorResponse.data.description,
          });
          throw error;
        }

        if (errorResponse.status === 402) {
          showError();
        } else if (errorResponse.status === 404) {
          showError();
        } else {
          throw error;
        }
      },
    );
  }

  postRequest = (uri: string, data: Record<string, any>) =>
    this.request.post(uri, data);

  patchRequest = (uri: string, data: Record<string, any>) =>
    this.request.patch(uri, data);

  putRequest = (
    uri: string,
    data: Record<string, any>,
    config?: AxiosRequestConfig,
  ) => this.request.put(uri, data, config);

  getRequest = (uri: string, config?: AxiosRequestConfig) =>
    this.request.get(uri, config);

  deleteRequest = (uri: string) => this.request.delete(uri);
}

export default new ApiService();
