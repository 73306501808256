import React from 'react';

function PdfIcon({ ...rest }) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.122 1.003a.438.438 0 00-.31-.128H6.563A3.066 3.066 0 003.5 3.938v20.125a3.066 3.066 0 003.063 3.062h14.875a3.066 3.066 0 003.062-3.063v-17.5a.437.437 0 00-.128-.309l-5.25-5.25zm.128 1.366l3.756 3.756h-1.569a2.19 2.19 0 01-2.187-2.188V2.37zM6.562 1.75h11.813v2.188A3.066 3.066 0 0021.438 7h2.187v12.25H4.375V3.937A2.19 2.19 0 016.563 1.75zm14.875 24.5H6.563a2.19 2.19 0 01-2.187-2.188v-3.937h19.25v3.938a2.19 2.19 0 01-2.188 2.187z"
        fill="#7A60E9"
      />
      <path
        d="M18.375 21h-1.75a.438.438 0 00-.438.438v3.5a.438.438 0 00.875 0v-1.313h.438a.438.438 0 000-.875h-.438v-.875h1.313a.438.438 0 000-.875zM10.5 21h-.875a.438.438 0 00-.438.438v3.5a.438.438 0 00.876 0v-1.313h.437a1.313 1.313 0 000-2.625zm0 1.75h-.438v-.875h.438a.438.438 0 010 .875zM13.125 21a.438.438 0 00-.438.438v3.5a.438.438 0 00.438.437 2.188 2.188 0 000-4.375zm.438 3.425V21.95a1.312 1.312 0 010 2.475zM13.563 9.625h7a.438.438 0 000-.875h-7a.438.438 0 000 .875zM13.563 11.375h1.75a.438.438 0 000-.875h-1.75a.438.438 0 000 .875zM13.563 13.125h7a.438.438 0 000-.875h-7a.438.438 0 000 .875zM7.438 14.875H17.5a.438.438 0 000-.875H7.437a.438.438 0 000 .875zM20.563 10.5h-3.5a.438.438 0 000 .875h3.5a.438.438 0 000-.875zM20.563 14H19.25a.438.438 0 000 .875h1.313a.438.438 0 000-.875zM7.438 16.625h2.625a.438.438 0 000-.875H7.437a.438.438 0 000 .875zM16.188 15.75h-4.375a.438.438 0 000 .875h4.374a.438.438 0 000-.875zM20.563 15.75h-2.625a.438.438 0 000 .875h2.625a.438.438 0 000-.875zM7.438 13.125h4.375a.438.438 0 00.437-.438v-5.25A.438.438 0 0011.812 7H7.439A.437.437 0 007 7.438v5.25a.438.438 0 00.438.437zm.437-5.25h3.5v4.375h-3.5V7.875zM13.563 7.875h3.5a.438.438 0 000-.875h-3.5a.438.438 0 000 .875z"
        fill="#7A60E9"
      />
    </svg>
  );
}

export default PdfIcon;
