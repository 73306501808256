import React from 'react';

function CallIcon({ ...rest }) {
  return (
    <svg
      width="34"
      height="14"
      viewBox="0 0 34 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.22091 12.5215C8.26385 11.4785 9.45101 10.6176 10.7421 9.96788C11.3798 9.65455 11.8156 8.92121 11.8077 8.33293L12.2772 4.97161C15.6843 3.93603 18.4204 3.93559 21.7946 4.97068L22.2567 8.23955C22.2562 8.96828 22.6573 9.63095 23.3131 9.97334C24.6107 10.6268 25.7978 11.4867 26.8412 12.5301C27.5949 13.2839 28.8213 13.2839 29.575 12.5302L32.7034 9.40183C33.4571 8.64812 33.4571 7.4217 32.7034 6.668C24.064 -1.97142 10.0069 -1.97137 1.36749 6.66804C0.61379 7.42175 0.613787 8.64817 1.36749 9.40187L4.48713 12.5215C5.24083 13.2751 6.46725 13.2751 7.22091 12.5215Z"
        fill="white"
      />
    </svg>
  );
}

export default CallIcon;
