import { THERAPIST_NOTICE_PERIOD_FOR_DAYJS } from 'lib/constants/therapist';
import { TimeUtil } from 'lib/utils';
import { TimePeriodType } from '__generated__/types';

export const getNoticePeriodDate = ({
  noticePeriod,
  noticePeriodType,
}: {
  noticePeriod?: number | null;
  noticePeriodType?: TimePeriodType | null;
}) =>
  noticePeriod && noticePeriodType
    ? TimeUtil.now().add(
        noticePeriod,
        THERAPIST_NOTICE_PERIOD_FOR_DAYJS[noticePeriodType],
      )
    : null;
