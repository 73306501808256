import React from 'react';
import styled from 'styled-components';
import { ChatAttachmentFragment } from 'components/chat/query/__generated__/chat-messages';
import { getSize, transformLongStringToShortWithDots } from 'lib/utils';
import { AttachedDocIcon } from 'ui';

export interface AttachmentItemProps {
  attachment: ChatAttachmentFragment;
  onClick: () => void;
}

function AttachmentItem({
  onClick,
  attachment: { format, name },
}: AttachmentItemProps) {
  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Button onClick={onClick}>
        <DocIcon />
        {/* <ExtensionName>{format.toUpperCase()}</ExtensionName> */}
        <FileName>
          <Name>
            {`${transformLongStringToShortWithDots(
              name,
              30,
            )}.${format.toLowerCase()}`}
          </Name>
        </FileName>
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.li`
  &:not(:last-child) {
    margin-bottom: ${getSize(10)};
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
`;

const ExtensionName = styled.p`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${getSize(10)} 0 0;
  width: ${getSize(28)};
  height: ${getSize(28)};
  font-weight: 600;
  font-size: ${getSize(8)};
  line-height: ${getSize(12)};
  color: var(--black3);
  background: white;
  border-radius: ${getSize(8)};
`;

const FileName = styled.p`
  display: flex;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
`;

const Name = styled.span`
  text-align: left;
  white-space: nowrap;
`;

const DocIcon = styled(AttachedDocIcon)`
  margin-right: 5px;
`;

export default AttachmentItem;
