import * as Types from '../../../../__generated__/types';

import { ChatFragment } from '../../query/__generated__/chats-list';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TherapistStartNewChatVariables = Types.Exact<{
  chatUserId: Types.Scalars['String'];
}>;


export type TherapistStartNewChat = (
  { __typename: 'Mutation' }
  & { therapistStartChat: (
    { __typename: 'ChatModel' }
    & ChatFragment
  ) }
);


export const TherapistStartNewChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"TherapistStartNewChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"chatUserId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistStartChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"chatUserId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"chatUserId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatFragment"}}]}}]}},...ChatFragment.definitions]} as unknown as DocumentNode;
export type TherapistStartNewChatMutationFn = Apollo.MutationFunction<TherapistStartNewChat, TherapistStartNewChatVariables>;

/**
 * __useTherapistStartNewChat__
 *
 * To run a mutation, you first call `useTherapistStartNewChat` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTherapistStartNewChat` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [therapistStartNewChat, { data, loading, error }] = useTherapistStartNewChat({
 *   variables: {
 *      chatUserId: // value for 'chatUserId'
 *   },
 * });
 */
export function useTherapistStartNewChat(baseOptions?: Apollo.MutationHookOptions<TherapistStartNewChat, TherapistStartNewChatVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TherapistStartNewChat, TherapistStartNewChatVariables>(TherapistStartNewChatDocument, options);
      }
export type TherapistStartNewChatHookResult = ReturnType<typeof useTherapistStartNewChat>;
export type TherapistStartNewChatMutationResult = Apollo.MutationResult<TherapistStartNewChat>;
export type TherapistStartNewChatMutationOptions = Apollo.BaseMutationOptions<TherapistStartNewChat, TherapistStartNewChatVariables>;