import { getSize } from 'lib/utils';
import React from 'react';
import styled, { css } from 'styled-components';

interface DayProps {
  day: Date;
  timeOfAvailabilityInMonth: { label: string; value: string }[][];
}

function Day({ day, timeOfAvailabilityInMonth }: DayProps) {
  const dayNumber = day.getDate();
  return (
    <DateDisplay
      $isActive={Boolean(timeOfAvailabilityInMonth[dayNumber - 1]?.length)}
    >
      {dayNumber}
    </DateDisplay>
  );
}

const DateDisplay = styled.span<{ $isActive: boolean }>`
  color: var(${({ $isActive }) => ($isActive ? '--black3' : '--gray2')});
  position: relative;
  display: flex;
  justify-content: center;

  ${({ $isActive }) =>
    $isActive &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: ${getSize(-6)};
        width: ${getSize(5)};
        height: ${getSize(5)};

        background: var(--green2);
        border-radius: 50%;
        transition: 0.1s ease-out;
      }
    `}
`;

// const DateDisplay = styled.p<{ $isActive: boolean }>`
//   color: var(${({ $isActive }) => ($isActive ? '--black3' : '--gray2')});
// `;

export default Day;
