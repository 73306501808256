import React from 'react';

function MapPinIcon({ ...rest }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.875 7.08325C14.875 12.0416 8.5 16.2916 8.5 16.2916C8.5 16.2916 2.125 12.0416 2.125 7.08325C2.125 3.56244 4.97918 0.708252 8.5 0.708252C12.0208 0.708252 14.875 3.56244 14.875 7.08325Z"
        fill="#6B4EE6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 9.20825C9.6736 9.20825 10.625 8.25686 10.625 7.08325C10.625 5.90965 9.6736 4.95825 8.5 4.95825C7.32639 4.95825 6.375 5.90965 6.375 7.08325C6.375 8.25686 7.32639 9.20825 8.5 9.20825Z"
        fill="white"
      />
    </svg>
  );
}

export default MapPinIcon;
