import { notifyError } from 'lib/utils/notification';

interface handleMutationErrorArgs {
  name: string;
  message: string;
}

export function showFetchErrorToaster({
  name,
  message,
}: handleMutationErrorArgs) {
  notifyError({ title: name, text: message });
}
