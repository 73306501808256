import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function EditIcon({ ...rest }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      fill="#6B4EE6"
      viewBox="0 0 20 20"
      {...rest}
    >
      <g clipPath="url(#edit-icon-svg)">
        <path d="M16.428 10a.714.714 0 00-.714.714v7.143c0 .395-.32.714-.714.714H2.143a.714.714 0 01-.714-.714V3.572c0-.395.32-.715.714-.715h8.571a.714.714 0 100-1.428H2.143C.959 1.429 0 2.389 0 3.572v14.285C0 19.041.96 20 2.143 20H15c1.183 0 2.143-.96 2.143-2.143v-7.143a.714.714 0 00-.715-.714z" />
        <path d="M19.2.801a2.734 2.734 0 00-3.868 0L5.923 10.21a.723.723 0 00-.172.28l-1.428 4.285a.714.714 0 00.903.904l4.285-1.429a.714.714 0 00.28-.173l9.408-9.408a2.734 2.734 0 000-3.867zm-1.011 2.858l-9.286 9.285-2.774.926.924-2.77 9.289-9.286a1.306 1.306 0 012.23.921 1.3 1.3 0 01-.383.924z" />
      </g>
      <defs>
        <clipPath id="edit-icon-svg">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(20)};
  height: ${getSize(20)};
`;

export default EditIcon;
