import { getSize } from 'lib/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { MaterialIndicatorsModel, MaterialTag } from '__generated__/types';

export interface TabsProps {
  tabs: Record<string, string>;
  className?: string;
  activeTab: string;
  isSmall?: boolean;
  onTabClick: (key: string) => void;
  materialIndicators?: MaterialIndicatorsModel;
}

function TabsMaterial({
  tabs,
  className,
  activeTab,
  onTabClick,
  isSmall,
  materialIndicators,
}: TabsProps) {
  const tabsKeys = useMemo(() => Object.keys(tabs), [tabs]);

  return (
    <List className={className}>
      {tabsKeys.map((key) => {
        const isActive = key === activeTab;
        let indicator = false;

        if (
          (key === MaterialTag.Worksheet &&
            materialIndicators?.worksheetsCount) ||
          (key === MaterialTag.Recording &&
            materialIndicators?.recordingCount) ||
          (key === MaterialTag.Diary && materialIndicators?.diaryCount)
        ) {
          indicator = true;
        }

        return (
          <Item key={key} $itemsCount={tabsKeys.length} $isSmall={isSmall}>
            <Button
              type="button"
              $isSmall={isSmall}
              $isActive={isActive}
              onClick={() => onTabClick(key)}
            >
              {tabs[key]}
              {!!indicator && <Indicator />}
            </Button>
          </Item>
        );
      })}
    </List>
  );
}

const Indicator = styled.div`
  margin-left: 4px;
  width: 4px;
  height: 4px;
  background: var(--red);
  border-radius: 2px;
`;

const List = styled.ul`
  display: flex;
  flex-shrink: 0;
  border-bottom: ${getSize(1)} solid var(--purple3);
  padding: 0 0 ${getSize(2)};
`;

const Item = styled.li<{ $itemsCount: number; $isSmall?: boolean }>`
  width: ${({ $isSmall, $itemsCount }) =>
    $isSmall ? `calc(100% / ${$itemsCount})` : getSize(145)};
`;

const Button = styled.button<{ $isActive: boolean; $isSmall?: boolean }>`
  position: relative;
  padding: 0 0 ${getSize(6)};
  width: 100%;
  font-weight: ${({ $isActive }) => ($isActive ? 600 : 400)};
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(${({ $isActive }) => ($isActive ? '--purple' : '--gray2')});
  transition: 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover:not(:disabled) {
    color: var(--purple);
  }

  ${({ $isActive }) =>
    $isActive &&
    `
  &::before {
    content: '';
    position: absolute;
    bottom: ${getSize(-5)};
    left: 0;
    width: 100%;
    height: ${getSize(3)};
    background: var(--purple);
  }
  `}
`;

export default TabsMaterial;
