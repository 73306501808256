import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { getSize, TimeUtil } from 'lib/utils';
import { API_DATE_FORMAT } from 'lib/constants/date';
import { NoteModalFormValues } from '../note-modal/note-modal';
import { NotesButtons } from '..';

export type AcceptedType = 'li' | 'div';
export interface Note {
  id: string;
  date: string;
  shared: boolean;
  text: string;
  shareWithTherapistId?: string | null;
}
export interface NoteItemProps {
  note: Note;
  className?: string;
  asElement?: AcceptedType;
  hasShortText?: boolean;
  isSmall?: boolean;
  onDeleteClick: () => void;
  onEditClick?: (value: NoteModalFormValues) => void;
  downloadLink: string;
  isTherapist?: boolean;
}

function NoteItem({
  onDeleteClick,
  hasShortText,
  asElement,
  className,
  isSmall,
  note: { date, shared, text, id, shareWithTherapistId },
  onEditClick,
  downloadLink,
  isTherapist,
}: NoteItemProps) {
  const handleEditClick = useCallback(() => {
    if (isTherapist) {
      onEditClick?.({
        date,
        isShared: String(shared),
        text,
        id,
        shareWithTherapistId,
      });
    } else {
      onEditClick?.({
        date,
        isShared: String(shared),
        id,
        text,
        shareWithTherapistId,
      });
    }
  }, [date, id, isTherapist, onEditClick, shareWithTherapistId, shared, text]);

  const onDeleteNote = () => {
    if (isTherapist && !shared) {
      return onDeleteClick;
    } else {
      return onDeleteClick;
    }
  };

  return (
    <Wrapper
      className={className}
      as={asElement}
      $isSmall={isSmall}
      onClick={handleEditClick}
      $isTherapist={isTherapist}
    >
      <Text className={`text-line-clamp-${hasShortText ? 4 : 9}`}>{text}</Text>
      <Header>
        <DateTime className="note-date-time">
          {TimeUtil.parse(date, API_DATE_FORMAT).format('DD MMM YYYY')}
        </DateTime>
        {shared && <SharedText className="note-shared-text">Shared</SharedText>}
        <NotesButtonsWrapper onClick={(e) => e.stopPropagation()}>
          <NotesButtons
            onDeleteClick={onDeleteNote()}
            downloadLink={downloadLink}
            onAddClick={() => {}}
            isShared={shared}
            isTherapist={isTherapist}
          />
        </NotesButtonsWrapper>
      </Header>
    </Wrapper>
  );
}

const Wrapper = styled.li<{ $isSmall?: boolean; $isTherapist?: boolean }>`
  min-height: ${getSize(318)};
  padding: ${getSize(30)} ${getSize(28)} ${getSize(28)};
  cursor: pointer;
  box-shadow: ${({ $isTherapist }) =>
    $isTherapist ? 'none' : '0px 10px 20px rgba(0, 0, 0, 0.03)'};
  border-radius: ${getSize(7)};
  display: flex;
  flex-direction: ${({ $isTherapist }) =>
    $isTherapist ? 'column-reverse' : 'column'};
  justify-content: ${({ $isTherapist }) =>
    $isTherapist ? 'flex-end' : 'space-between'};
  width: 100%;
  background: ${({ $isTherapist }) => ($isTherapist ? '#faf8fd' : 'white')};

  &:first-child {
    div {
      time {
        ${({ $isTherapist }) =>
          $isTherapist &&
          css`
            font-weight: 600;
            font-size: ${getSize(18)};
            line-height: 32px;
            color: #444752;
          `}
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const DateTime = styled.time`
  margin: 0 ${getSize(19)} 0 0;
  line-height: ${getSize(17)};
  color: var(--black3);
  font-family: Poppins;
  font-size: ${getSize(12)};
  font-weight: 500;
`;

const SharedText = styled.p`
  padding: 0 ${getSize(7)};
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--purple);
  background: #f3f0ff;
  border-radius: ${getSize(38)};
`;

const Text = styled.pre`
  font-weight: 400;
  font-size: ${getSize(13)};
  line-height: ${getSize(23)};
  color: var(--black3);
  word-break: break-word;
`;

const NotesButtonsWrapper = styled.div`
  margin-left: auto;
`;

export default NoteItem;
