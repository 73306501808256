import React, { FC } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

/** Custom components */
import { Button } from 'ui/button';

/** Utils & routes */
import { getSize } from 'lib/utils';
import { PATIENT_ROUTES } from 'lib/routes';

/** Images */
import Photo from 'assets/images/photo-group.png';

const GuidedMatchingBanner: FC = () => {
  const history = useHistory();

  return (
    <Container>
      <Row>
        <StyledImg src={Photo} />
        Get a personalised therapist recommendation
      </Row>
      <StyledButton
        theme="outline"
        onClick={() => history.push(PATIENT_ROUTES.GUIDED_MATCHING)}
      >
        Start Now
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  background: var(--purple);
  border-radius: 7px;
  color: var(--white);
  display: flex;
  margin-top: ${getSize(15)};
  padding: 40px 42px;
  align-items: center;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledImg = styled.img`
  height: 50px;
  object-fit: contain;
  width: fit-content;
  margin-right: 19px;
`;

const StyledButton = styled(Button)`
  color: var(--white);
`;

export default GuidedMatchingBanner;
