import React from 'react';

function DownloadIconSecond({ ...rest }) {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11 10.3125C11 10.6922 10.6922 11 10.3125 11H0.6875C0.307807 11 0 10.6922 0 10.3125C0 9.93281 0.307807 9.625 0.6875 9.625H10.3125C10.6922 9.625 11 9.93281 11 10.3125ZM5.01387 8.13459C5.14813 8.26884 5.32404 8.33596 5.5 8.33596C5.67591 8.33596 5.85191 8.26882 5.98613 8.13459L8.42198 5.69875C8.69047 5.43026 8.69047 4.99497 8.42198 4.72648C8.15349 4.45799 7.7182 4.45799 7.44971 4.72648L6.1875 5.98868V0.6875C6.1875 0.307807 5.87969 0 5.5 0C5.12031 0 4.8125 0.307807 4.8125 0.6875V5.98868L3.55029 4.72648C3.2818 4.45799 2.84651 4.45799 2.57802 4.72648C2.30953 4.99497 2.30953 5.43026 2.57802 5.69875L5.01387 8.13459Z"
        fill="black"
      />
    </svg>
  );
}

export default DownloadIconSecond;
