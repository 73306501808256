import React from 'react';

function MaterialVideoIcon({ ...rest }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.9331 4.50996C30.9331 3.19708 29.8562 2.13281 28.5277 2.13281H3.47178C2.14331 2.13281 1.06641 3.19708 1.06641 4.50996V27.489C1.06641 28.8019 2.14331 29.8661 3.47178 29.8661H28.5277C29.8562 29.8661 30.9331 28.8019 30.9331 27.489V4.50996ZM10.1868 5.10424H14.4964V8.47186H10.1868V5.10424ZM7.18005 26.8947H4.07312V23.5271H7.18005V26.8947ZM7.18005 8.47186H4.07312V5.10424H7.18005V8.47186ZM14.4964 26.8947H10.1868V23.5271H14.4964V26.8947ZM13.3939 18.7439V13.255C13.3939 12.4378 13.982 12.1355 14.6738 12.5831L18.7065 15.1853C19.3982 15.6329 19.4038 16.3654 18.7121 16.8131L14.671 19.4157C13.9792 19.8634 13.3939 19.561 13.3939 18.7439ZM21.8127 26.8947H17.5031V23.5271H21.8127V26.8947ZM21.8127 8.47186H17.5031V5.10424H21.8127V8.47186ZM27.9264 26.8947H24.8194V23.5271H27.9264V26.8947ZM27.9264 8.47186H24.8194V5.10424H27.9264V8.47186Z"
        fill="white"
      />
    </svg>
  );
}

export default MaterialVideoIcon;
