import React, { useCallback, useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { ALL_FILE_TYPES, BUCKET_TYPES, UploadFile } from 'interfaces/files';
import { notifyError } from 'lib/utils/notification';
import { getFileInfo, getSize, uploadFileToS3 } from 'lib/utils';

import { FileItem, UploadButton } from './components';
import { FileExtension, FileType } from '__generated__/types';

export enum UploadInputTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
export interface UploadInputProps extends FieldRenderProps<UploadFile[]> {
  label?: string;
  rootCSS?: CSSProp;
  uploadButtonCSS?: CSSProp;
  labelCSS?: CSSProp;
  placeholder?: string;
  disabled?: boolean;
  maxNumber?: number;
  fileTypes?: FileType[];
  fileExtensions?: FileExtension[];
  isQualification?: boolean;
  theme?: UploadInputTheme;
  setIsUploading?: (isLoading: boolean) => void;
}

function UploadInput({
  input,
  meta,
  label,
  rootCSS,
  placeholder = 'No file uploaded',
  disabled,
  maxNumber = 5,
  fileTypes,
  fileExtensions,
  isQualification,
  theme = UploadInputTheme.PRIMARY,
  setIsUploading,
  uploadButtonCSS,
  labelCSS,
}: UploadInputProps) {
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);

  const valueFiles = useMemo<(UploadFile | undefined)[]>(
    () => input.value || [],
    [input.value],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      let currentErrorMessage = '';

      const getAllowedExtension = () => {
        if (fileExtensions && fileExtensions.length !== 0) {
          return fileExtensions;
        } else if (fileTypes) {
          return fileTypes.map((type) => ALL_FILE_TYPES[type]).flat();
        } else {
          return Object.values(ALL_FILE_TYPES).flat();
        }
      };

      const incomingFile = acceptedFiles[0];
      const allowedExtensions = getAllowedExtension();

      const { fileName, fileExtension, fileType } = getFileInfo(
        incomingFile,
        fileTypes,
      );

      async function setFileToForm(source: string) {
        const newFile = {
          source,
          name: fileName,
          sizeInKb: incomingFile.size,
          fileType: fileType?.toUpperCase(),
          extension: fileExtension.toUpperCase(),
        };

        input.onChange([...valueFiles, newFile]);
      }

      if (
        allowedExtensions.includes(fileExtension.toUpperCase() as FileExtension)
      ) {
        const bucketType = isQualification
          ? BUCKET_TYPES.QUALIFICATIONS
          : BUCKET_TYPES.REPRESENTATION;
        const apiUrl = `aws/signed-url?fileType=${fileType?.toLowerCase()}&fileName=${fileName}&extension=${fileExtension}&bucketType=${bucketType}`;

        setIsLoadingFile(true);
        setIsUploading?.(true);

        uploadFileToS3({ apiUrl, file: incomingFile })
          .then(setFileToForm)
          .catch((error) => notifyError({ text: error?.message }))
          .finally(() => {
            setIsLoadingFile(false);
            setIsUploading?.(false);
          });
      } else {
        currentErrorMessage = `The file must be one of these formats: ${allowedExtensions.join(
          ', ',
        )}`;
      }

      setErrorMessage(currentErrorMessage);
    },
    [
      input,
      valueFiles,
      fileTypes,
      isQualification,
      setIsUploading,
      fileExtensions,
    ],
  );

  const removeFileByIndex = useCallback(
    (deletedIndex: number) => {
      const newFiles = valueFiles.filter((_, index) => index !== deletedIndex);
      input.onChange(newFiles);
    },
    [input, valueFiles],
  );

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    multiple: false,
    onDrop,
  });

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) ||
    data?.error ||
    errorMessage;
  const isSecondaryThemeActive = theme === UploadInputTheme.SECONDARY;

  const currentFiles = useMemo(() => {
    const valueLength = valueFiles.length;

    if (
      !isSecondaryThemeActive &&
      ((valueLength === 0 && disabled) ||
        (valueLength < maxNumber && !disabled))
    ) {
      return valueFiles.concat(undefined);
    }

    return valueFiles;
  }, [valueFiles, maxNumber, disabled, isSecondaryThemeActive]);

  return (
    <Wrapper
      {...getRootProps()}
      onFocus={() => setIsInputFocused(true)}
      onBlur={() => setIsInputFocused(false)}
      $CSS={rootCSS}
      aria-label="Upload-files"
    >
      {label && <Text $CSS={labelCSS}>{label}</Text>}

      <FilesWrapper $isSecondaryThemeActive={isSecondaryThemeActive}>
        {isSecondaryThemeActive && (
          <>
            <UploadButtonStylized
              getInputProps={getInputProps}
              isLoading={isLoadingFile}
            />
            {currentFiles.length === 0 && (
              <NoFilesText>No files uploaded</NoFilesText>
            )}
          </>
        )}

        {currentFiles.map((currentFile, index) => (
          <FileItem
            key={index}
            onDeleteClick={() => removeFileByIndex(index)}
            disabled={disabled}
            placeholder={placeholder}
            file={currentFile}
            getInputProps={getInputProps}
            isLoading={isLoadingFile}
            isSecondaryThemeActive={isSecondaryThemeActive}
            uploadButtonCSS={uploadButtonCSS}
          />
        ))}
      </FilesWrapper>

      <Line $hasError={hasError} $isInputFocused={isInputFocused} />

      {hasError && (
        <ErrorText>
          {errorMessage || error || submitError || data?.error}
        </ErrorText>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  display: block;

  ${({ $CSS }) => $CSS}
`;

const Text = styled.span<{ $CSS?: CSSProp }>`
  display: block;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray2);

  ${({ $CSS }) => $CSS}
`;

const FilesWrapper = styled.div<{ $isSecondaryThemeActive: boolean }>`
  ${({ $isSecondaryThemeActive }) =>
    $isSecondaryThemeActive &&
    `
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    `}
`;

const UploadButtonStylized = styled(UploadButton)`
  margin: ${getSize(9)} ${getSize(12)} ${getSize(9)} 0;
  width: ${getSize(87)};
`;

const NoFilesText = styled.p`
  display: flex;
  align-items: center;
  height: ${getSize(44)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
`;

const Line = styled.span<{ $hasError: boolean; $isInputFocused: boolean }>`
  display: block;
  width: 100%;
  height: ${getSize(1)};
  border-radius: ${getSize(8)};
  background: var(
    ${({ $isInputFocused, $hasError }) => {
      if ($hasError) {
        return '--red';
      } else if ($isInputFocused) {
        return '--purple';
      } else {
        return '--purple3';
      }
    }}
  );
  transition: 0.3s ease-out;
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default UploadInput;
