import React, { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import _ from 'lodash';

/** Constants & types */
import { B2BRole, Role } from '__generated__/types';

/** Stored user data */
import { userMeInfoVar } from 'lib/apollo-client/reactive-variables';

interface RenderGuardProps {
  children: any;
  allowedRoles?: Array<Role>;
  allowedB2bRoles?: Array<B2BRole>;
}

/**
 * @function
 * @name RenderGuard
 * @description This component will render children or null based on limitiations of role(patient, therapist)
 * or in limitation of patient b2b role-
 * @param props
 */
const RenderGuard: React.FC<RenderGuardProps> = (props) => {
  /** Props */
  const { children, allowedRoles = [], allowedB2bRoles = [] } = props;

  /** User data */
  const { role, b2bRoles } = useReactiveVar(userMeInfoVar);
  const b2bRolesArray = useMemo(() => {
    return b2bRoles?.map((roleObject) => roleObject.role);
  }, [b2bRoles]);

  /** Check for role */
  if (allowedRoles?.length > 0 && role && allowedRoles.indexOf(role) === -1) {
    return null;
  }

  /** Check for b2bRoles */
  if (
    allowedB2bRoles?.length > 0 &&
    _.intersection(allowedB2bRoles, b2bRolesArray).length === 0
  ) {
    return null;
  }

  return children;
};

export default RenderGuard;
