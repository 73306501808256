import React, { FC } from 'react';
import styled from 'styled-components';
import { NavBar } from '../dashboard-home/components';

const DashboardLayout: FC = ({ children }) => {
  return (
    <Wrapper>
      <NavBarWrapper>
        <NavBar />
      </NavBarWrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default DashboardLayout;

const Wrapper = styled.section`
  min-height: 100%;
  display: flex;
  justify-content: start;
  max-width: 1205px;
  margin: 0 auto;
`;

const NavBarWrapper = styled.nav`
  padding: 22px 30px 0 0;
  border-right: 1px solid #eeeeee;
`;

const Content = styled.div`
  padding: 40px 0 40px 60px;
  flex-grow: 1;
`;
