import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {
  StarDiscoverIcon,
  ThreeDotsTriangleIcon,
  ChatIcon,
  SavedIcon,
} from 'ui';
import { getSize } from 'lib/utils';

// ROUTES
import { PATIENT_RESOURCES_ROUTES } from 'lib/routes';
export const { ALL_CONTENT, DISCOVER } = PATIENT_RESOURCES_ROUTES;

export const isNavLinkActive = (match: any, location: any, subPath: string) => {
  return location.pathname.includes(subPath);
};

const NavBar = () => {
  /**
   * @method
   * @name isNavLinkActive
   * @description
   */

  return (
    <LinkList>
      <LinkWrapper>
        <StyledLink
          to={DISCOVER.pathBuild()}
          isActive={(match, location) =>
            isNavLinkActive(match, location, 'discover')
          }
        >
          <InnerLink>
            <StarDiscoverIcon />
            <LinkText>Discover</LinkText>
          </InnerLink>
        </StyledLink>
      </LinkWrapper>

      <LinkWrapper>
        <StyledLink
          to={ALL_CONTENT.pathBuild()}
          isActive={(match, location) =>
            isNavLinkActive(match, location, 'all-content')
          }
        >
          <InnerLink>
            <ThreeDotsTriangleIcon />
            <LinkText>All content</LinkText>
          </InnerLink>
        </StyledLink>
      </LinkWrapper>

      {/* <LinkWrapper>
        <StyledLink exact={true} to={ALL_CONTENT}>
          <InnerLink>
            <ChatIcon />
            <LinkText>Webinars/Events</LinkText>
          </InnerLink>
        </StyledLink>
      </LinkWrapper>

      <LinkWrapper>
        <StyledLink exact={true} to={ALL_CONTENT}>
          <InnerLink>
            <SavedIcon />
            <LinkText>Saved</LinkText>
          </InnerLink>
        </StyledLink>
      </LinkWrapper> */}
    </LinkList>
  );
};

export default NavBar;

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled.li`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(NavLink)`
  width: ${getSize(225)};
  height: ${getSize(49)};
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: #46494b;
  transition: 0.3s ease-out;

  padding: 15px 0 15px 15px;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    border: 1px solid #ededed;
    border-radius: 8px;
    background: white;
  }
`;

const InnerLink = styled.div`
  display: flex;
  align-items: center;
`;

const LinkText = styled.span`
  margin-left: 12px;
  font-weight: 500;
`;
