import React from 'react';

function InsurancePendingIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="#F7C852"
      {...rest}
    >
      <path d="M3.555 13H5.57a7.002 7.002 0 0013.858 0h2.016a9.001 9.001 0 01-17.89 0h.001zm0-2a9.001 9.001 0 0117.89 0H19.43a7.002 7.002 0 00-13.858 0H3.555z" />
    </svg>
  );
}

export default InsurancePendingIcon;
