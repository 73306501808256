import React from 'react';

function CheckMarkWithCircleIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#79D0BB"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fillRule="evenodd"
        d="M15.663 3.78A9 9 0 1021 12.004v-.92a1 1 0 112 0v.92A11.002 11.002 0 018.188 22.319a11 11 0 118.289-20.366 1 1 0 11-.814 1.827z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M22.707 3.292a.999.999 0 010 1.413l-10 10.002a1 1 0 01-1.414 0l-3-2.997a.999.999 0 111.414-1.413L12 12.587l9.293-9.294a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default CheckMarkWithCircleIcon;
