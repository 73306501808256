import { getSize } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';
import { UploadIcon } from 'ui';
import { FileExtension } from '__generated__/types';

export interface DownloadButtonProps {
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  href?: string;
  fileName?: string;
  extension?: FileExtension;
}

function DownloadButton({
  onClick,
  className,
  disabled,
  href,
  fileName,
  extension,
}: DownloadButtonProps) {
  const wrapperProps =
    href && fileName && extension
      ? {
          as: 'a' as any,
          href: href,
          download: `${fileName}.${extension}`,
          target: '_blank',
        }
      : undefined;

  return (
    <Wrapper
      {...(wrapperProps ? wrapperProps : {})}
      className={className}
      disabled={disabled}
      type="button"
      onClick={() => onClick?.()}
    >
      <UploadIconStylized />
      <span className="visually-hidden">Download</span>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${getSize(28)};
  height: ${getSize(28)};
  background: var(--purple11);
  border-radius: ${getSize(6)};
  transition: 0.3s ease-out;

  &:hover:not(:disabled) {
    background: var(--purple);

    svg {
      stroke: white;
    }
  }
`;

const UploadIconStylized = styled(UploadIcon)`
  transition: 0.3s ease-out;
`;

export default DownloadButton;
