import { ReadChatMessagesArgs } from './use-read-chat-messages';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useHistory, generatePath, useParams } from 'react-router-dom';
import { PATIENT_ROUTES, THERAPIST_ROUTES } from 'lib/routes';
import { getChatById } from '../utils';
import { useChatsList } from '../query/__generated__/chats-list';
import { ChatUserType } from '__generated__/types';

interface UseChatListLogicArgs {
  isTherapist?: boolean;
  readChatMessages: (args: ReadChatMessagesArgs) => void;
}

export const MAX_VISIBLE_CHATS = 20;

export function useChatListLogic({
  isTherapist,
  readChatMessages,
}: UseChatListLogicArgs) {
  const history = useHistory();
  const [selectedChatId, setSelectedChatId] = useState('');

  const [currentUnreadCount, setCurrentUnreadCount] = useState(0);
  const { chatId: chatIdFromUrl } = useParams<{ chatId?: string }>();

  const chatsListQuery = useChatsList({
    variables: { limit: MAX_VISIBLE_CHATS },
    notifyOnNetworkStatusChange: true,
  });

  const chatsList = useMemo(
    () => chatsListQuery.data?.listChats.nodes || [],
    [chatsListQuery.data?.listChats.nodes],
  );

  const selectedChat = useMemo(
    () => getChatById(chatsList, selectedChatId),
    [chatsList, selectedChatId],
  );

  const participantsCountOfSelectedChat = useMemo(() => {
    if (!selectedChat) return 0;

    return selectedChat.participants.length > 2
      ? selectedChat.participants.filter(
          (participant) => participant.type !== ChatUserType.Admin,
        ).length || 0
      : 0;
  }, [selectedChat]);

  const setSelectedChat = useCallback(
    (chatId: string) => {
      setSelectedChatId(chatId);
      history.replace(
        generatePath(
          isTherapist
            ? THERAPIST_ROUTES.MESSAGING.PATH
            : PATIENT_ROUTES.MESSAGING.PATH,
          { chatId },
        ),
      );
    },
    [history, isTherapist],
  );

  useEffect(() => {
    if (!selectedChatId && chatsList.length > 0) {
      let newSelectedChat = chatsList[0];

      if (chatIdFromUrl) {
        newSelectedChat =
          chatsList.find((chat) => chat.id === chatIdFromUrl) ||
          newSelectedChat;
      }

      const { unreadCount, id: newChatId } = newSelectedChat;
      setSelectedChat(newChatId);

      if (unreadCount) {
        readChatMessages({ chatId: newChatId });
        setCurrentUnreadCount(unreadCount);
      }
    }
  }, [
    chatsList,
    selectedChatId,
    setSelectedChat,
    chatIdFromUrl,
    readChatMessages,
  ]);

  return {
    selectedChatId,
    chatsList,
    selectedChat,
    chatsListQuery,
    currentUnreadCount,
    setCurrentUnreadCount,
    setSelectedChat,
    participantsCountOfSelectedChat,
  };
}
