import { userMeInfoVar } from '../../../../../lib/apollo-client/reactive-variables';
import { useReactiveVar } from '@apollo/client';
import { ManipulateType } from 'dayjs';
import { AmplitudeEvents, amplitudeService } from 'lib/amplitude';
import { StorageService, TimeUtil } from 'lib/utils';
import { PreBookingInfo } from 'lib/utils/storage-service';
import { SessionType } from '__generated__/types';
import { BookinHomeFormValues } from '../types';

/** Custom hooks */
import { usePrices } from 'hooks';

export const useSubmitBookingHome = ({
  duration,
  timeUTC,
  sessionType,
  officeLocationId,
  therapistId,
  onNavigateNextStep,
  onNotAuthorizedSubmit,
}: UseSubmitBookingHomeParams) => {
  /** Init */
  const { sessionPriceId } = usePrices();
  const { userId } = useReactiveVar(userMeInfoVar);
  const isAuthorized = Boolean(userId);

  const onSubmit = (values: BookinHomeFormValues) => {
    amplitudeService.logEvent(AmplitudeEvents.BOOKING_GATE_2);

    const timeAddOptions: { value: number; unit: ManipulateType } = {
      value: Number(duration),
      unit: 'minute',
    };

    const preBookingValues: PreBookingInfo = {
      sessionPriceId: sessionPriceId,
      startDateUtc: timeUTC,
      timezone: values.timezone,
      endDateUtc: TimeUtil.add(timeUTC, timeAddOptions).toISOString(),
      sessionType,
      therapistId,
      clientType: values.clientType,
      officeLocationId,
      voucher: '',
    };

    StorageService.setPreBookedSessionInfo(preBookingValues);

    if (isAuthorized) {
      onNavigateNextStep();
    } else {
      onNotAuthorizedSubmit?.(values.email);
    }
  };

  return { onSubmit };
};

export interface UseSubmitBookingHomeParams {
  officeLocationId?: string | null;
  duration: string;
  sessionType: SessionType;
  timeUTC: string;
  therapistId: string;
  onNavigateNextStep: () => void;
  onNotAuthorizedSubmit?: (email: string) => void;
}
