import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function TrashIcon({ ...rest }) {
  return (
    <Icon viewBox="0 0 24 24" stroke="#ED6C6C" fill="none" {...rest}>
      <path
        d="M3 6h18M8 6V4a2 2 0 012-2h4a2 2 0 012 2v2m3 0v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6h14z"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

export default TrashIcon;
