import { signIn, socialAuth, SOCIAL_CONNECTION } from 'lib/auth';
import { AUTH_ROUTES } from 'lib/routes';
import {
  composeValidators,
  getSize,
  StorageService,
  validationRules,
} from 'lib/utils';
import { notifyError } from 'lib/utils/notification';
import React, { FC, useContext, useState } from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import styled from 'styled-components';
import {
  AuthWithSocials,
  Button,
  Checkbox,
  Input,
  Link,
  PasswordInput,
} from 'ui';
import { TeamsAppContext } from '../../hooks/use-teams-app';

const ACCESS_DENIED_ERROR = 'access_denied';

const SignInForm: FC<SignInFormProps> = ({ initialValues }) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [hasAccessDeniedError, setHasAccessDeniedError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const isTeamsApp = useContext(TeamsAppContext);

  const handleFormSubmit = ({
    email,
    password,
    isRemember,
  }: SignInFormValues) => {
    if (isRemember || isTeamsApp) {
      StorageService.setIsRememberUser();
    }

    setIsDataLoading(true);

    return signIn({ email, password: password.trim() }).catch((error) => {
      const isAccessDeniedError = error?.error === ACCESS_DENIED_ERROR;

      setHasAccessDeniedError(isAccessDeniedError);
      setIsDataLoading(false);

      if (isAccessDeniedError) {
        return {
          email: 'Check email',
          password: 'Check password',
        };
      } else {
        notifyError({ text: error?.description });
      }
    });
  };

  return (
    <>
      <FinalForm
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormSpy
              onChange={(e) => {
                setRememberMe(e.values.isRemember);
              }}
            />

            <TitleDescription>Please log in to your account</TitleDescription>

            <Field
              name="email"
              label="Email"
              component={InputStyled}
              hasErrorText={!hasAccessDeniedError}
              validate={composeValidators(
                validationRules.required,
                validationRules.email,
              )}
              placeholder="Enter email"
            />

            <Field
              name="password"
              label="Password"
              component={PasswordInputStyled}
              hasErrorText={!hasAccessDeniedError}
              validate={validationRules.required}
              placeholder="Enter password"
            />

            {!isTeamsApp && (
              <RememberWrapper>
                <Field
                  name="isRemember"
                  type="checkbox"
                  label="Remember Me"
                  labelCSS={LABEL_CHECKBOX_CSS}
                  component={Checkbox}
                  checkboxCSS={CHECKBOX_CSS}
                />

                <ForgotLink to={AUTH_ROUTES.RESET_PASSWORD}>
                  Forgot Password?
                </ForgotLink>
              </RememberWrapper>
            )}

            <Button isLoading={isDataLoading} theme="tertiary">
              LOG IN
            </Button>
          </Form>
        )}
      />

      {!isTeamsApp && (
        <AuthWithSocialsStyled
          separatorText="or log in with"
          onGoogleClick={() =>
            socialAuth(SOCIAL_CONNECTION.GOOGLE, { isRemember: rememberMe })
          }
          onFacebookClick={() =>
            socialAuth(SOCIAL_CONNECTION.FACEBOOK, { isRemember: rememberMe })
          }
          onAppleClick={() =>
            socialAuth(SOCIAL_CONNECTION.APPLE, { isRemember: rememberMe })
          }
        />
      )}

      {hasAccessDeniedError && (
        <AccessDeniedError>
          {`We couldn't find an account matching the email and password you entered. Please check your email and password and try again.`}
        </AccessDeniedError>
      )}
    </>
  );
};

interface SignInFormProps {
  initialValues?: Partial<SignInFormValues>;
}

export interface SignInFormValues {
  email: string;
  password: string;
  isRemember: boolean;
}

const LABEL_CHECKBOX_CSS = {
  fontSize: `${getSize(11)}`,
  lineHeight: `${getSize(18)}`,
  color: 'var(--black3)',
};

const CHECKBOX_CSS = {
  width: getSize(18),
  height: getSize(18),
  marginBottom: getSize(1),
  '&::before': {
    content: '',
    position: 'absolute',
    top: getSize(3),
    left: getSize(4),
    width: getSize(8),
    height: getSize(5),
    border: '2px solid white',
    'border-top': '0',
    'border-right': '0',
    transform: 'rotate(-45deg)',
    transition: '0.3s ease-out',
  },
};

const TitleDescription = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
  margin-bottom: ${getSize(24)};
`;

const RememberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${getSize(19)};
`;

const ForgotLink = styled(Link)`
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray2);
  transition: 0.3s ease-out;

  &:hover {
    color: var(--purple);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 0 ${getSize(20)};
`;

const AuthWithSocialsStyled = styled(AuthWithSocials)`
  margin-bottom: ${getSize(22)};
`;

const InputStyled = styled(Input)`
  margin-bottom: 16px;
`;

const PasswordInputStyled = styled(PasswordInput)`
  margin-bottom: 16px;
`;

const AccessDeniedError = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  padding: ${getSize(3)};
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: white;
  background: var(--red);
  transform: translateY(-100%);
  animation-name: access-denied-show;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;

  @keyframes access-denied-show {
    to {
      transform: translateY(0);
    }
  }
`;

export default SignInForm;
