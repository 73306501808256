import React, { useCallback } from 'react';
import { NavbarElementProps } from 'react-day-picker';
import styled, { css, CSSProp } from 'styled-components';

import { ArrowDownIcon } from 'ui/icons';
import { getSize, TimeUtil } from 'lib/utils';
import { ISO_DATE_FORMAT } from 'lib/constants/date';

interface Navbar extends NavbarElementProps {
  onMonthChange?: (value: Date) => void;
  selectedDay?: Date | Date[];
  hasBookingAvailability?: boolean;
}

function Navbar({
  onPreviousClick,
  onNextClick,
  localeUtils,
  month,
  selectedDay,
  onMonthChange,
  hasBookingAvailability = false,
}: Navbar) {
  const date = localeUtils.formatMonthTitle(month);

  const onNextMoth = useCallback(() => {
    if (selectedDay && !Array.isArray(selectedDay) && onMonthChange) {
      const nextMonth = TimeUtil.parse(
        selectedDay.toISOString(),
        ISO_DATE_FORMAT,
      )
        .add(1, 'month')
        .toDate();

      onMonthChange(nextMonth);
    } else {
      onNextClick();
    }
  }, [onMonthChange, onNextClick, selectedDay]);

  const onPrevMonth = useCallback(() => {
    if (selectedDay && !Array.isArray(selectedDay) && onMonthChange) {
      const prevMonth = TimeUtil.parse(
        selectedDay.toISOString(),
        ISO_DATE_FORMAT,
      )
        .subtract(1, 'month')
        .toDate();

      onMonthChange(prevMonth);
    } else {
      onPreviousClick();
    }
  }, [onMonthChange, onPreviousClick, selectedDay]);

  const renderMonthChanger = (hasBookingAvailability = false) => (
    <>
      <Button type="button" onClick={onPrevMonth}>
        <LeftIcon />
      </Button>
      <DateDisplay $hasBookingAvailability={hasBookingAvailability}>
        {date}
      </DateDisplay>
      <Button type="button" onClick={onNextMoth}>
        <RightIcon />
      </Button>
    </>
  );

  return (
    <Root
      onClick={(e) => e.stopPropagation()}
      $hasBookingAvailability={hasBookingAvailability}
    >
      {hasBookingAvailability ? (
        <>
          <Header>
            Select Date
            <Availability>
              <Marker />
              Available
            </Availability>
          </Header>

          <MonthChangerWrapper>
            {renderMonthChanger(hasBookingAvailability)}
          </MonthChangerWrapper>
        </>
      ) : (
        renderMonthChanger()
      )}
    </Root>
  );
}

const Root = styled.div<{ $hasBookingAvailability?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 0.3em;

  ${({ $hasBookingAvailability }) =>
    $hasBookingAvailability &&
    css`
      overflow: hidden;

      flex-direction: column;
      padding: 0;
    `}
`;

const MonthChangerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.3em;
  justify-content: space-between;
  margin-top: ${getSize(11)};
  padding: 0 calc(${getSize(18)} + 0.3em) 0;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray33);
  align-items: center;
  font-weight: 600;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--black3);
  padding: ${getSize(14)} ${getSize(21)} ${getSize(11)};
`;

const Availability = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(17)};
  color: var(--gray7);
`;

const Marker = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--green2);
  margin-right: ${getSize(6)};
`;

const Button = styled.button`
  &:hover {
    svg {
      stroke: var(--purple);
    }
  }
`;

const Icon = styled(ArrowDownIcon)`
  stroke: var(--gray2);
  transition: 0.3s ease-out;
`;

const LeftIcon = styled(Icon)`
  transform: rotate(90deg);
`;

const RightIcon = styled(Icon)`
  transform: rotate(-90deg);
`;

const DateDisplay = styled.p<{ $hasBookingAvailability?: boolean }>`
  flex-grow: 1;
  text-align: center;
  font-weight: ${({ $hasBookingAvailability }) =>
    $hasBookingAvailability ? 500 : 400};
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
`;

export default Navbar;
