import { DecodeTokenService, StorageService } from 'lib/utils';
import auth0 from 'auth0-js';
import { amplitudeService, AmplitudeEvents } from 'lib/amplitude';
import * as Intercom from 'lib/intercom';
import { config } from 'lib/utils/config';
import { client } from 'lib/apollo-client/client';
import { SignUpSpots } from 'types';

export interface SignInOptions {
  email: string;
  password: string;
}
export interface SignUpOptions {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  companyKey: string;
  role?: string;
  signUpSpot?: SignUpSpots;
}
interface RefreshAccessTokenArgs {
  isRememberUser?: boolean;
  isSkipTokenCheck?: boolean;
}
export enum SOCIAL_CONNECTION {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

const RESPONSE_TYPE = 'token';

const SOCIAL_CONNECTIONS = {
  [SOCIAL_CONNECTION.GOOGLE]: config.AUTH0_GOOGLE_NAME,
  [SOCIAL_CONNECTION.FACEBOOK]: config.AUTH0_FACEBOOK_NAME,
  [SOCIAL_CONNECTION.APPLE]: config.AUTH0_APPLE_NAME,
};

export const auth0WebAuth = new auth0.WebAuth({
  audience: config.AUTH0_AUDIENCE,
  domain: config.AUTH0_DOMAIN,
  responseType: RESPONSE_TYPE,
  clientID: config.AUTH0_CLIENT_ID,
  redirectUri: config.AUTH0_REDIRECT_URI,
  scope: config.AUTH0_SCOPE,
});

export function signIn({ email, password }: SignInOptions) {
  amplitudeService.logEvent(AmplitudeEvents.LOGIN, {
    with: 'email',
  });

  return new Promise((resolve, reject) => {
    auth0WebAuth.login(
      {
        email,
        password,
        realm: config.AUTH0_DB_NAME,
        onRedirecting: (done) => {
          StorageService.setAfterAuthRedirect(true);
          done();
        },
      },
      (error, result) => (error ? reject(error) : resolve(result)),
    );
  });
}

export function signUp({
  email,
  password,
  firstName,
  lastName,
  companyKey,
  signUpSpot = SignUpSpots.STANDART,
}: SignUpOptions) {
  amplitudeService.logEvent(AmplitudeEvents.CREATE_ACCOUNT, {
    with: 'email',
    spot: signUpSpot,
  });

  return new Promise((resolve, reject) => {
    auth0WebAuth.signup(
      {
        email,
        password,
        connection: config.AUTH0_DB_NAME,
        userMetadata: {
          firstName,
          lastName,
          companyKey,
          timezone: Intl
            ? Intl.DateTimeFormat().resolvedOptions().timeZone
            : undefined,
        },
      },
      (error, result) => (error ? reject(error) : resolve(result)),
    );
  });
}

export function socialAuth(
  socialName: SOCIAL_CONNECTION,
  {
    isRemember = false,
    isSignUp,
    signUpSpot = SignUpSpots.STANDART,
  }: SocialAuthOptions = {},
) {
  if (isRemember) {
    StorageService.setIsRememberUser();
  }

  const connection = SOCIAL_CONNECTIONS[socialName];

  if (isSignUp) {
    amplitudeService.logEvent(AmplitudeEvents.CREATE_ACCOUNT, {
      with: socialName,
      spot: signUpSpot,
    });
  } else {
    amplitudeService.logEvent(AmplitudeEvents.LOGIN, {
      with: socialName,
    });
  }

  auth0WebAuth.authorize({ connection });
  localStorage.setItem('social', socialName);
}

export function resetPassword(email: string) {
  return new Promise((resolve, reject) => {
    auth0WebAuth.changePassword(
      { email, connection: config.AUTH0_DB_NAME },
      (error, result) => (error ? reject(error) : resolve(result)),
    );
  });
}

export function refreshAccessToken({
  isRememberUser,
  isSkipTokenCheck,
}: RefreshAccessTokenArgs) {
  return new Promise((resolve, reject) => {
    auth0WebAuth.checkSession(
      { clientID: config.AUTH0_CLIENT_ID },
      (error, result) => {
        if (error) return reject(error);
        const currentAccessToken = result?.accessToken;

        function isNewTokenDataSimilarToOld() {
          const prevAccessToken = StorageService.getAccessToken();
          const prevAuth0UserId =
            DecodeTokenService.getAuth0Id(prevAccessToken);
          const prevRole =
            DecodeTokenService.getUserRoleFromToken(prevAccessToken);

          if (!prevRole) {
            return true;
          }

          const currentAuth0UserId =
            DecodeTokenService.getAuth0Id(currentAccessToken);
          const currentRole =
            DecodeTokenService.getUserRoleFromToken(currentAccessToken);

          return (
            currentAuth0UserId === prevAuth0UserId && currentRole === prevRole
          );
        }
        if (isSkipTokenCheck || isNewTokenDataSimilarToOld()) {
          StorageService.setAccessToken(currentAccessToken, isRememberUser);
          resolve(currentAccessToken);
        } else {
          reject('Role not matching!');
        }
      },
    );
  });
}

export async function logout() {
  Intercom.shutdown();
  StorageService.removeGuidedMatchData();
  StorageService.removeIsAOCompleted();
  auth0WebAuth.logout({
    clientID: config.AUTH0_CLIENT_ID,
    returnTo: config.AUTH0_LOGOUT_URI,
  });
  StorageService.removeAccessToken();
  StorageService.removeIsRememberUser();
  StorageService.setIsLogout();
  amplitudeService.setUserId(null);
  amplitudeService.regenerateDeviceId();
  await client.clearStore();
}

export async function logoutAndSignUp() {
  auth0WebAuth.logout({
    clientID: config.AUTH0_CLIENT_ID,
    returnTo: config.AUTH0_LOGOUT_URI,
  });
  StorageService.removeAccessToken();
  StorageService.removeIsRememberUser();
  StorageService.setIsLogout();
  amplitudeService.setUserId(null);
  amplitudeService.regenerateDeviceId();
  await client.clearStore();

  window.location.href = '/auth/sign-up/user';
}

interface SocialAuthOptions {
  isRemember?: boolean;
  isSignUp?: boolean;
  signUpSpot?: SignUpSpots;
}
