import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import { amplitudeService } from 'lib/amplitude';
import { auth0WebAuth, logout, refreshAccessToken } from 'lib/auth';
import { getSize, StorageService } from 'lib/utils';
import { notifyError } from 'lib/utils/notification';
import { AUTH_ROUTES, ROOT_ROUTES } from 'lib/routes';
import {
  DecodeTokenService,
  SignUpDataService,
  validationRules,
  isValidUserRole,
} from 'lib/utils';
import { useSetUserRole } from 'common/mutation/__generated__/set-user-role';
import { Role } from '__generated__/types';

import {
  ArrowDownIcon,
  Button,
  ErrorMessage,
  Loader,
  RadioButton,
  RadioFields,
  ShadowWrapper,
} from 'ui';
import { AuthContentLayout } from 'layouts';

export interface ChosenRoleForm {
  role: string;
}

const ROLE_OPTIONS = [
  { value: 'patient', label: 'User' },
  { value: 'therapist', label: 'Therapist' },
];
const EXIST_ROLE_ERROR_MESSAGE = 'User already has role';

const isRememberUser = Boolean(StorageService.getIsRememberUser());

function Callback() {
  const history = useHistory();
  const [isChooseRoleVisible, setIsChooseRoleVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isUserHasEmail, setIsUserHasEmail] = useState(true);
  const [setUserRole] = useSetUserRole();

  const navigateToHome = useCallback(
    () => history.replace(ROOT_ROUTES.HOME),
    [history],
  );

  useEffect(() => {
    auth0WebAuth.parseHash((errors, result) => {
      const accessToken = result?.accessToken;
      const error = errors?.errorDescription;

      if (error) {
        notifyError({ text: error });
        setTimeout(() => history.replace(ROOT_ROUTES.AUTH), 5000);
      } else if (accessToken) {
        const emailFromToken = DecodeTokenService.getUserEmail(accessToken);

        if (!emailFromToken) {
          return setIsUserHasEmail(false);
        }

        StorageService.setAccessToken(accessToken, isRememberUser);

        const roleFromToken =
          DecodeTokenService.getUserRoleFromToken(accessToken);

        const roleFromSignUp = JSON.parse(
          String(SignUpDataService.getSignUpData()),
        )?.role;

        const social = localStorage.getItem('social');

        if (isValidUserRole(roleFromToken)) {
          navigateToHome();
          amplitudeService.logEvent(`${roleFromToken}_login completed`, {
            with: social ? social : 'email + password',
          });
        } else if (isValidUserRole(roleFromSignUp)) {
          amplitudeService.logEvent(
            `${roleFromSignUp}_registration completed`,
            { with: social ? social : 'email + password' },
          );

          setUserRole({
            variables: { role: roleFromSignUp.toUpperCase() as Role },
          })
            .then(() => {
              refreshAccessToken({ isRememberUser }).then(navigateToHome);
            })
            .finally(SignUpDataService.removeSignUpData);
        } else {
          setIsChooseRoleVisible(true);
        }

        if (social) localStorage.removeItem('social');
      }
    });
  }, [navigateToHome, setUserRole, history]);

  const handleFormSubmit = useCallback(
    ({ role }: ChosenRoleForm) => {
      const selectedRole = role.toUpperCase() as Role;

      function navigateAfterRoleChosen() {
        selectedRole === Role.Therapist
          ? history.replace(AUTH_ROUTES.WELCOME)
          : navigateToHome();
      }

      setIsDataLoading(true);
      setUserRole({ variables: { role: selectedRole } })
        .then(() => {
          refreshAccessToken({
            isRememberUser,
            isSkipTokenCheck: true,
          })
            .then(navigateAfterRoleChosen)
            .catch((err) => {
              notifyError({ text: err });
              setTimeout(logout, 5000);
            });
        })
        .catch(({ name, message }) => {
          if (message === EXIST_ROLE_ERROR_MESSAGE) {
            navigateAfterRoleChosen();
          } else {
            notifyError({ title: name, text: message });
            setIsDataLoading(false);
          }
        });
    },
    [setUserRole, navigateToHome, history],
  );

  if (!isUserHasEmail) {
    return (
      <ErrorWrapper>
        <BackLink href={ROOT_ROUTES.AUTH}>
          <Icon />
          Back
        </BackLink>
        <ErrorInner>
          <ErrorBlock>
            <p>
              Your Facebook account appears not to contain your email address.
            </p>
            <p>
              Please add your email address to your Facebook account and try
              again, or Sign Up with with email and password.
            </p>
          </ErrorBlock>
        </ErrorInner>
      </ErrorWrapper>
    );
  } else if (!isChooseRoleVisible) {
    return <Loader hasFillWholeBlock size={50} pathColor="white" />;
  }

  return (
    <AuthContentLayout title="Select a role to login">
      <Text>You seem not to be registered with us!</Text>
      <FinalForm
        onSubmit={handleFormSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <RoleFields
              name="role"
              options={ROLE_OPTIONS}
              component={RadioButton}
              validate={validationRules.required}
              radioRootCSS={ROLE_FIELD_BUTTON_CSS}
              contentCSS={ROLE_FIELD_CONTENT_CSS}
            />
            <SubmitButton isLoading={isDataLoading} theme="primary">
              Continue
            </SubmitButton>
          </form>
        )}
      />
    </AuthContentLayout>
  );
}

const ROLE_FIELD_CONTENT_CSS = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '0 15px',
};

const ROLE_FIELD_BUTTON_CSS = { margin: 0 };

const ErrorWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const BackLink = styled.a`
  position: absolute;
  top: ${getSize(13)};
  left: ${getSize(112)};
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--gray2);
  transition: color 0.2s ease-in;

  & > svg {
    transition: stroke 0.2s ease-in;
  }

  &:hover {
    color: var(--purple);

    & > svg {
      stroke: var(--purple);
    }
  }
`;

const Icon = styled(ArrowDownIcon)`
  transform: rotate(90deg);
  margin: 0 ${getSize(8)} 0 0;
  stroke: var(--black3);
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

const ErrorInner = styled(ShadowWrapper)`
  margin: auto;
`;

const ErrorBlock = styled(ErrorMessage)`
  font-weight: 600;
  font-size: ${getSize(20)};
  line-height: ${getSize(32)};
`;

const Text = styled.p`
  margin: 0 0 ${getSize(32)};
  font-weight: normal;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--gray2);
`;

const RoleFields = styled(RadioFields)`
  margin: 0 0 ${getSize(40)};
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

export default Callback;
