import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPostsAndSubcategoriesByCategoryVariables = Types.Exact<{
  categoryId: Types.Scalars['String'];
}>;


export type GetPostsAndSubcategoriesByCategory = (
  { __typename: 'Query' }
  & { getPostsAndSubcategoriesByCategory: Array<(
    { __typename: 'ResourcesSubcategoryDetailsModel' }
    & Pick<Types.ResourcesSubcategoryDetailsModel, 'id' | 'name' | 'imageUrl'>
    & { posts?: Types.Maybe<Array<(
      { __typename: 'PostDetailsModel' }
      & Pick<Types.PostDetailsModel, 'id' | 'title' | 'author' | 'type' | 'imageUrl'>
    )>> }
  )> }
);


export const GetPostsAndSubcategoriesByCategoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPostsAndSubcategoriesByCategory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"categoryId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPostsAndSubcategoriesByCategory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"categoryId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"categoryId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"posts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetPostsAndSubcategoriesByCategory__
 *
 * To run a query within a React component, call `useGetPostsAndSubcategoriesByCategory` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsAndSubcategoriesByCategory` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsAndSubcategoriesByCategory({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetPostsAndSubcategoriesByCategory(baseOptions: Apollo.QueryHookOptions<GetPostsAndSubcategoriesByCategory, GetPostsAndSubcategoriesByCategoryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsAndSubcategoriesByCategory, GetPostsAndSubcategoriesByCategoryVariables>(GetPostsAndSubcategoriesByCategoryDocument, options);
      }
export function useGetPostsAndSubcategoriesByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsAndSubcategoriesByCategory, GetPostsAndSubcategoriesByCategoryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsAndSubcategoriesByCategory, GetPostsAndSubcategoriesByCategoryVariables>(GetPostsAndSubcategoriesByCategoryDocument, options);
        }
export type GetPostsAndSubcategoriesByCategoryHookResult = ReturnType<typeof useGetPostsAndSubcategoriesByCategory>;
export type GetPostsAndSubcategoriesByCategoryLazyQueryHookResult = ReturnType<typeof useGetPostsAndSubcategoriesByCategoryLazyQuery>;
export type GetPostsAndSubcategoriesByCategoryQueryResult = Apollo.QueryResult<GetPostsAndSubcategoriesByCategory, GetPostsAndSubcategoriesByCategoryVariables>;