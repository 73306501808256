import { useMemo } from 'react';
import { TimeUtil } from 'lib/utils';
import { ISO_DATE_FORMAT } from 'lib/constants/date';
import useDynamicTimeNow from './use-dynamic-time-now';

function useIsDisabledSessionControlButtons({
  startDateUtc,
  endDateUtc,
}: {
  startDateUtc: string;
  endDateUtc: string;
}) {
  const { timeNow } = useDynamicTimeNow(30);

  const sessionStartTime = useMemo(
    () => TimeUtil.parse(startDateUtc, ISO_DATE_FORMAT),
    [startDateUtc],
  );
  const sessionEndTime = useMemo(
    () => TimeUtil.parse(endDateUtc, ISO_DATE_FORMAT),
    [endDateUtc],
  );

  const isDisableRescheduleButton = useMemo(
    () => timeNow.add(48, 'hour') > sessionStartTime,
    [sessionStartTime, timeNow],
  );
  const isDisabledStartButton = useMemo(
    () =>
      sessionStartTime.subtract(10, 'minute') >= timeNow ||
      sessionEndTime <= timeNow,
    [sessionEndTime, sessionStartTime, timeNow],
  );

  const isDisableCancelButton = useMemo(
    () => sessionStartTime >= timeNow,
    [sessionStartTime, timeNow],
  );

  return {
    isDisableRescheduleButton,
    isDisabledStartButton,
    isDisableCancelButton,
  };
}

export default useIsDisabledSessionControlButtons;
