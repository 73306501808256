import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Field, Form as FinalForm } from 'react-final-form';
import { useParams } from 'react-router-dom';

import {
  composeValidators,
  getSize,
  SignUpDataService,
  StorageService,
  validationRules,
} from 'lib/utils';
import { signUp, socialAuth, signIn, SOCIAL_CONNECTION } from 'lib/auth';
import { notifyError } from 'lib/utils/notification';

import { AuthWithSocials, Button, PasswordInput, Input } from 'ui';
import { Role } from '__generated__/types';
import { LABEL_CSS, EMAIL_INPUT_CSS } from '../sign-in/sign-in';
import {
  TERMS_AND_CONDITIONAL_FOR_PRACTITIONERS,
  TERMS_AND_CONDITIONAL_FOR_CLIENT,
} from 'lib/constants/common';
import { AuthContentLayout } from 'layouts';
import { SignUpSpots } from 'types';

const ALREADY_EXIST_EMAIL_ERROR_CODE = 'invalid_signup';
const PRIVACY = 'https://www.iubenda.com/privacy-policy/29132364';
export const PATIENT_ROLE_TITLE = 'user';

const SignUp: FC<SignUpProps> = ({ initialValues, children }) => {
  const { role } = useParams<{ role?: string }>();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const currentRole =
    role === PATIENT_ROLE_TITLE ? Role.Patient.toLowerCase() : role;
  const isTherapist = currentRole === Role.Therapist.toLowerCase();

  const setRoleToSignUpDataService = useCallback(() => {
    SignUpDataService.setSignUpData(JSON.stringify({ role: currentRole }));
  }, [currentRole]);

  const handelFormSubmit = useCallback(
    ({ firstName, lastName, email, password, companyKey }: SignUpValues) => {
      StorageService.setIsFirstTimeAuthorization('true');

      const valuesWithTrim = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim().toLowerCase(),
        password: password.trim(),
        companyKey: companyKey?.toString(),
      };

      setIsDataLoading(true);
      return signUp({
        ...valuesWithTrim,
        role: currentRole,
        signUpSpot: initialValues?.signUpSpot,
      })
        .then(() => {
          setRoleToSignUpDataService();
          signIn(valuesWithTrim).catch((error) =>
            notifyError({ text: error?.description }),
          );
        })
        .catch((error) => {
          const isEmailAlreadyExist =
            error?.code === ALREADY_EXIST_EMAIL_ERROR_CODE;
          setIsDataLoading(false);

          if (isEmailAlreadyExist) {
            notifyError({ text: 'This email is already being used' });
            return { email: 'Already exist email' };
          } else {
            notifyError({
              text:
                typeof error?.description === 'string'
                  ? error?.description
                  : error?.code,
            });
          }
        });
    },
    [currentRole, setRoleToSignUpDataService],
  );

  function handleGoogleClick() {
    socialAuth(SOCIAL_CONNECTION.GOOGLE, {
      isSignUp: true,
      signUpSpot: initialValues?.signUpSpot,
    });
    setRoleToSignUpDataService();
  }

  function handleFacebookClick() {
    socialAuth(SOCIAL_CONNECTION.FACEBOOK, {
      isSignUp: true,
      signUpSpot: initialValues?.signUpSpot,
    });
    setRoleToSignUpDataService();
  }

  function handleAppleClick() {
    socialAuth(SOCIAL_CONNECTION.APPLE, {
      isSignUp: true,
      signUpSpot: initialValues?.signUpSpot,
    });
    setRoleToSignUpDataService();
  }

  return (
    <AuthContentLayout title="Create your account">
      <FinalForm
        initialValues={initialValues}
        onSubmit={handelFormSubmit}
        render={({ handleSubmit }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Field
                name="firstName"
                label="First name"
                labelCSS={LABEL_CSS}
                inputCSS={EMAIL_INPUT_CSS}
                rootCSS={ROOT_CSS}
                component={Input}
                validate={validationRules.required}
                placeholder="Your first name"
              />
              <Field
                name="lastName"
                label="Last name"
                labelCSS={LABEL_CSS}
                inputCSS={EMAIL_INPUT_CSS}
                rootCSS={ROOT_CSS}
                component={Input}
                validate={validationRules.required}
                placeholder="Your last name"
              />
              <Field
                name="email"
                label="Email"
                labelCSS={LABEL_CSS}
                inputCSS={EMAIL_INPUT_CSS}
                rootCSS={ROOT_CSS}
                component={Input}
                validate={composeValidators(
                  validationRules.required,
                  validationRules.email,
                )}
                placeholder="Your email"
              />
              <Field
                name="password"
                label="Password"
                labelCSS={LABEL_CSS}
                rootCSS={PASSWORD_ROOT_CSS}
                component={PasswordInput}
                validate={composeValidators(
                  validationRules.required,
                  validationRules.passStrength,
                )}
                placeholder="Enter password"
                checkStrength={true}
              />

              <Button isLoading={isDataLoading} theme="tertiary">
                CREATE ACCOUNT
              </Button>

              <PrivacyPolicy>
                By clicking “Sign Up” I agree to the{' '}
                <PrivacyPolicyLink
                  target="_blank"
                  href={
                    isTherapist
                      ? TERMS_AND_CONDITIONAL_FOR_PRACTITIONERS
                      : TERMS_AND_CONDITIONAL_FOR_CLIENT
                  }
                >
                  Terms Of Service{' '}
                </PrivacyPolicyLink>
                and{' '}
                <PrivacyPolicyLink target="_blank" href={PRIVACY}>
                  Privacy Policy
                </PrivacyPolicyLink>
              </PrivacyPolicy>
            </Form>

            <AuthWithSocials
              onGoogleClick={handleGoogleClick}
              onFacebookClick={handleFacebookClick}
              onAppleClick={handleAppleClick}
            />
          </>
        )}
      />

      {children}
    </AuthContentLayout>
  );
};

interface SignUpProps {
  initialValues?: Partial<SignUpValues>;
}

export interface SignUpValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyKey: string;
  signUpSpot?: SignUpSpots;
}

export const TITLE_CSS = {
  marginBottom: getSize(13),
  color: 'var(--black4)',
  fontSize: `${getSize(32)}`,
  lineHeight: `${getSize(37)}`,
};

export const INNER_CSS = {
  width: `${getSize(480)}`,
  padding: `${getSize(47)} ${getSize(50)} ${getSize(48)}`,
};

export const ROOT_CSS = { marginBottom: getSize(9) };

export const PASSWORD_ROOT_CSS = { margin: `0 0 ${getSize(20)}` };

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 0 ${getSize(20)};
`;

const Text = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--gray2);
`;

const PrivacyPolicy = styled(Text)`
  text-align: center;
  font-weight: 500;
  font-size: ${getSize(10)};
  line-height: ${getSize(18)};
  margin-top: ${getSize(15)};
`;

const PrivacyPolicyLink = styled.a`
  text-decoration: underline;
`;

export default SignUp;
