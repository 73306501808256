import React from 'react';

function CheckedWorkTypeICon({ ...rest }) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      stroke="#fff"
      {...rest}
    >
      <circle cx={12} cy={12} r={12} fill="#6B4EE6" />
      <path
        d="M23 3.25L9.75 16.5 6 12.75"
        strokeWidth={2}
        strokeLinecap="square"
      />
    </svg>
  );
}

export default CheckedWorkTypeICon;
