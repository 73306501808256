import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateB2BRegistrationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  companyKey: Types.Scalars['String'];
}>;


export type ValidateB2BRegistration = (
  { __typename: 'Query' }
  & Pick<Types.Query, 'validateB2BRegistration'>
);


export const ValidateB2BRegistrationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ValidateB2BRegistration"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyKey"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"validateB2BRegistration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"companyKey"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyKey"}}}]}]}}]} as unknown as DocumentNode;

/**
 * __useValidateB2BRegistration__
 *
 * To run a query within a React component, call `useValidateB2BRegistration` and pass it any options that fit your needs.
 * When your component renders, `useValidateB2BRegistration` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateB2BRegistration({
 *   variables: {
 *      email: // value for 'email'
 *      companyKey: // value for 'companyKey'
 *   },
 * });
 */
export function useValidateB2BRegistration(baseOptions: Apollo.QueryHookOptions<ValidateB2BRegistration, ValidateB2BRegistrationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateB2BRegistration, ValidateB2BRegistrationVariables>(ValidateB2BRegistrationDocument, options);
      }
export function useValidateB2BRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateB2BRegistration, ValidateB2BRegistrationVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateB2BRegistration, ValidateB2BRegistrationVariables>(ValidateB2BRegistrationDocument, options);
        }
export type ValidateB2BRegistrationHookResult = ReturnType<typeof useValidateB2BRegistration>;
export type ValidateB2BRegistrationLazyQueryHookResult = ReturnType<typeof useValidateB2BRegistrationLazyQuery>;
export type ValidateB2BRegistrationQueryResult = Apollo.QueryResult<ValidateB2BRegistration, ValidateB2BRegistrationVariables>;