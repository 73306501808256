import React, { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

import { getSize } from 'lib/utils';

import { BackButton, ShadowWrapper } from 'ui';

export interface DashboardContainerProps {
  title: string;
  children: ReactNode;
  wrapperCSS?: CSSProp;
  titleCSS?: CSSProp;
  renderTitle?: JSX.Element;
  isTherapist?: boolean;
}

function DashboardContainer({
  children,
  title,
  wrapperCSS,
  titleCSS,
  renderTitle,
  isTherapist,
}: DashboardContainerProps) {
  return (
    <Wrapper $isTherapist={isTherapist}>
      <Back />
      <ContentWrapper
        $CSS={wrapperCSS}
        $isTherapist={isTherapist}
        asElement="section"
      >
        {renderTitle ? renderTitle : <Title $CSS={titleCSS}>{title}</Title>}
        <Content>{children}</Content>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $isTherapist?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${getSize(13)} ${getSize(112)} ${getSize(50)}
    ${({ $isTherapist }) => ($isTherapist ? getSize(50) : getSize(112))};
  min-height: 100%;
`;

const Back = styled(BackButton)`
  margin: 0 0 ${getSize(13)};
`;

const ContentWrapper = styled(ShadowWrapper)<{
  $CSS?: CSSProp;
  $isTherapist?: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${({ $isTherapist }) =>
    $isTherapist &&
    `box-shadow: none;
  padding: 0;`};

  ${({ $CSS }) => $CSS}
`;

const Title = styled.h1<{ $CSS?: CSSProp }>`
  margin: 0 0 ${getSize(24)};
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 ${getSize(10)};
`;

export default DashboardContainer;
