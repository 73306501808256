import * as React from 'react';

function HomeIcon({ ...rest }) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" fill=" #E5E4E8" {...rest}>
      <path d="M18.333 18.333a.917.917 0 01-.916.917H4.583a.917.917 0 01-.916-.917v-8.25H.917l9.466-8.606a.916.916 0 011.234 0l9.466 8.606h-2.75v8.25z" />
    </svg>
  );
}

export default HomeIcon;
