import React, { useEffect } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { FieldRenderProps } from 'react-final-form';
import { getSize } from 'lib/utils';

export interface ScrollingRadioButtonProps extends FieldRenderProps<string> {
  label: string;
  buttonCSS?: CSSProp;
  disabled?: boolean;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
}

function ScrollingRadioButton({
  label,
  input,
  meta,
  buttonCSS,
  disabled,
  onClick,
  errorMessage,
  setErrorMessage,
}: ScrollingRadioButtonProps): JSX.Element {
  useEffect(() => {
    const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
    const hasError =
      ((error || submitError) && touched && !dirtySinceLastSubmit) ||
      data?.error;

    if (!errorMessage && hasError) {
      const newErrorMessage = error || submitError || data?.error;
      setErrorMessage(newErrorMessage);
    } else if (errorMessage && !hasError) {
      setErrorMessage('');
    }
  }, [errorMessage, meta, setErrorMessage]);

  const isActive = input.checked;

  return (
    <Button
      $CSS={buttonCSS}
      $isActive={isActive}
      onClick={onClick}
      $isDisabled={disabled}
    >
      <Input
        name={input.name}
        type="radio"
        onChange={input.onChange}
        checked={isActive}
        value={input.value}
        disabled={disabled}
      />
      {label}
    </Button>
  );
}

const Button = styled.label<{
  $isActive?: boolean;
  $isDisabled?: boolean;
  $CSS?: CSSProp;
}>`
  position: relative;
  flex-grow: 1;
  padding: ${getSize(6)} ${getSize(20)};
  text-align: center;
  font-weight: 400;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  cursor: pointer;
  border-radius: ${getSize(10)};
  transition: 0.2s ease-in-out;
  color: var(${({ $isActive }) => ($isActive ? '--black3' : '--gray7')});

  &:hover {
    color: var(--purple);
    ${({ $isDisabled }) =>
      $isDisabled &&
      css<{ $isActive?: boolean }>`
        color: var(${({ $isActive }) => ($isActive ? '--black3' : '--gray7')});
        cursor: not-allowed;
      `}
  }

  ${({ $CSS }) => $CSS}
`;

const Input = styled.input`
  display: none;
`;

export default ScrollingRadioButton;
