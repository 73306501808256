import { useMemo } from 'react';
import { useAcademicInitials } from 'common/query/__generated__/get-academic-initials';
import { sortOptionsByAlphaBet, transformToOptions } from 'lib/utils';

export function useAcademicInitialsOptions() {
  const academicInitialsQuery = useAcademicInitials();

  return useMemo(
    () =>
      sortOptionsByAlphaBet(
        transformToOptions(academicInitialsQuery.data?.academicInitials),
      ),
    [academicInitialsQuery.data],
  );
}
