import React, { useCallback, useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import { useParams } from 'react-router-dom';

import { getSize, validationRules } from 'lib/utils';
import { getTimeBySeconds, setTimePerSecond } from './utils';
import { useResendVerificationEmail } from './mutation/__generated__/resend-verification-email';
import { notifyError } from 'lib/utils/notification';
import { resetPassword } from 'lib/auth';
import { CheckEmailIcon, OutlookIcon, GmailIcon, YahooIcon } from 'ui/icons';
import { AuthContentLayout } from 'layouts';

export const REGISTRATION_TYPE = 'registration';
const RESEND_TIMEOUT_IN_SECONDS = 2 * 60;

function CheckEmail() {
  const [timeToResend, setTimeToResend] = useState(0);
  const { emailForPasswordReset } = useParams<{
    emailForPasswordReset: string;
  }>();

  const isRegistrationEmail = emailForPasswordReset === REGISTRATION_TYPE;

  const [resendEmail] = useResendVerificationEmail({
    onError: ({ name, message }) => notifyError({ title: name, text: message }),
  });

  const handleResendClick = useCallback(() => {
    if (isRegistrationEmail) {
      resendEmail();
    } else if (!validationRules.email(emailForPasswordReset)) {
      resetPassword(emailForPasswordReset).catch(() =>
        notifyError({ text: 'Network error' }),
      );
    }

    setTimePerSecond(setTimeToResend, RESEND_TIMEOUT_IN_SECONDS);
  }, [resendEmail, isRegistrationEmail, emailForPasswordReset]);

  return (
    <AuthContentLayout title="Check your email">
      <CheckEmailWrapper>
        <CheckMessage>
          {isRegistrationEmail
            ? 'An email has been sent to your inbox. Please follow the link to confirm your account.'
            : 'We have sent you a link to reset your password.'}
        </CheckMessage>

        <IconWrapper>
          <Icon />
        </IconWrapper>

        <SeparatorWrapper>
          <Line />
          <MailText>Go to your email</MailText>
          <Line />
        </SeparatorWrapper>

        <LinksWrapper>
          {MAILS_LINKS.map(({ href, Icon, text, css }) => (
            <Link key={href} href={href} target="_blank" $CSS={css}>
              <Icon className="social-icon__svg" />
              {text}
            </Link>
          ))}
        </LinksWrapper>

        <NotReceive>
          <NotReceiveText>{`Didn't receive an email?`}</NotReceiveText>
          {timeToResend ? (
            <ResendTimer>{getTimeBySeconds(timeToResend)}</ResendTimer>
          ) : (
            <ResendButton onClick={handleResendClick}>Resend</ResendButton>
          )}
        </NotReceive>
      </CheckEmailWrapper>
    </AuthContentLayout>
  );
}

const YAHOO_CSS = {
  'padding-left': `${getSize(15)}`,
};

const MAILS_LINKS = [
  { Icon: GmailIcon, href: 'https://gmail.com', text: 'Gmail' },
  { Icon: OutlookIcon, href: 'https://outlook.com', text: 'Outlook' },
  {
    Icon: YahooIcon,
    href: 'https://mail.yahoo.com',
    text: 'Yahoo mail',
    css: YAHOO_CSS,
  },
];

const CheckEmailWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CheckMessage = styled.p`
  margin-bottom: ${getSize(25)};
  max-width: ${getSize(346)};
  font-size: ${getSize(14)};
  font-weight: 500;
  line-height: ${getSize(21)};
  color: var(--gray9);
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${getSize(37)} ${getSize(151)} ${getSize(47)};
  margin-bottom: ${getSize(24)};
  background-color: var(--purple11);
  border-radius: ${getSize(8)};
`;

const Icon = styled(CheckEmailIcon)`
  width: ${getSize(78)};
  height: ${getSize(78)};
`;

const MailText = styled.p`
  width: 100%;
  font-size: ${getSize(14)};
  line-height: ${getSize(21)};
  color: var(--gray9);
  text-align: center;
  white-space: nowrap;
`;

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${getSize(16)};
`;

const Link = styled.a<{ $CSS?: CSSProp }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${getSize(30)};
  border: ${getSize(1)} solid var(--gray11);
  border-radius: 29px;
  background: var(--gray11);
  position: relative;
  color: var(--black3);
  background-color: white;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  font-weight: 400;

  & > .social-icon__svg {
    width: ${getSize(15)};
    height: ${getSize(15)};
    position: absolute;
    left: ${getSize(8)};
    justify-self: center;
  }

  ${({ $CSS }) => $CSS}
`;

const NotReceive = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  bottom: ${getSize(-111)};
  min-width: ${getSize(227)};
  transform: translateX(-50%);
  margin: 0 0 ${getSize(20)};
  padding: ${getSize(11)} ${getSize(24)};
  background: var(--purple-opacity3);
  border-radius: ${getSize(8)};
`;

const NotReceiveText = styled.p`
  margin-right: ${getSize(7)};
  color: var(--black2);
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  white-space: nowrap;
`;

const ResendTimer = styled.p`
  margin: 0 auto;
  font-weight: 400;
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--purple);
`;

const ResendButton = styled(({ ...rest }) => (
  <ResendTimer as="button" {...rest} />
))`
  &:hover {
    text-decoration: underline;
  }
`;

const Line = styled.div`
  width: 100%;
  height: ${getSize(1)};
  background-color: var(--gray10);
`;

const SeparatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${getSize(17)};
`;

export default CheckEmail;
