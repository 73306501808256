import { APP_ICON } from 'lib/constants/common';
import { config, getSize } from 'lib/utils';
import React, { FC } from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import styled from 'styled-components';
import { Button } from 'ui';
import { AppItem } from './components';

const SelectAppModal: FC<SelectAppModalProps> = ({ onClose }) => {
  const getAppUrl = () => {
    if (isIOS) {
      // only safari can directry open app
      if (!isSafari) {
        return config.IOS_APP_STORE_URL;
      }
    }

    return config.ANDROID_PLAY_STORE_URL;
  };

  return (
    <Overlay>
      <Root>
        <Title>See Lumo Health in...</Title>

        <AppsList>
          <AppItem
            title="Lumo Health App"
            buttonTitle="Open"
            appUrl={getAppUrl()}
            iconUri={APP_ICON}
          />

          <ContinueButton theme="secondary" onClick={onClose}>
            Continue in Web
          </ContinueButton>
        </AppsList>
      </Root>
    </Overlay>
  );
};

interface SelectAppModalProps {
  onClose: () => void;
}

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: var(--gray-opacity3);
`;

const Root = styled.div`
  padding: 24px 26px;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: var(--white);

  border-top-left-radius: 26px;
  border-top-right-radius: 26px;

  z-index: 10;
`;

const Title = styled.h2`
  text-align: center;

  font-size: ${getSize(24)};
  line-height: ${getSize(32)};
  font-weight: 600;
`;

const AppsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-top: 12px;
  padding-top: 16px;

  border-style: solid;
  border-top-width: 1px;
  border-color: var(--gray);
`;

const ContinueButton = styled(Button)`
  width: 100%;

  padding: 12px 18px;
  margin-top: 20px;

  border-radius: 30px;

  font-size: ${getSize(20)};
  font-weight: 500;
`;

export default SelectAppModal;
