import {
  PATIENT_RESOURCES_ROUTES,
  PATIENT_ROUTES,
  THERAPIST_ROUTES,
} from 'lib/routes';
import { Role } from '__generated__/types';

/** Storage */
import StorageService from './storage-service';

export function getDefaultRouteByRole(userRole: string) {
  const isTherapistRole = userRole === Role.Therapist.toLowerCase();
  const isPatientRole = userRole === Role.Patient.toLowerCase();

  if (isTherapistRole) {
    return THERAPIST_ROUTES.DASHBOARD;
  } else if (isPatientRole) {
    const choosenPath = StorageService.getChoosenPath();
    const route =
      choosenPath === 'selfHelp'
        ? PATIENT_RESOURCES_ROUTES.DISCOVER.pathBuild()
        : PATIENT_ROUTES.DASHBOARD;
    return route;
  }
}
