import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TherapistApproachVariables = Types.Exact<{ [key: string]: never; }>;


export type TherapistApproach = (
  { __typename: 'Query' }
  & { me?: Types.Maybe<(
    { __typename: 'UserModel' }
    & Pick<Types.UserModel, 'id'>
    & { therapist?: Types.Maybe<(
      { __typename: 'TherapistModel' }
      & { treatmentAreas: Array<(
        { __typename: 'TherapistTreatmentAreaModel' }
        & TreatmentAreaFragment
      )>, therapyApproaches: Array<(
        { __typename: 'TherapistTherapyApproachModel' }
        & TherapyApproachesFragment
      )>, insuranceCompanies: Array<(
        { __typename: 'InsuranceCompanyModel' }
        & Pick<Types.InsuranceCompanyModel, 'id'>
      )>, therapistClientTypes: Array<(
        { __typename: 'TherapistClientTypeModel' }
        & Pick<Types.TherapistClientTypeModel, 'id'>
      )> }
    )> }
  )> }
);

export type TherapyApproachesFragment = (
  { __typename: 'TherapistTherapyApproachModel' }
  & Pick<Types.TherapistTherapyApproachModel, 'id' | 'label' | 'explanation' | 'order'>
);

export type TreatmentAreaFragment = (
  { __typename: 'TherapistTreatmentAreaModel' }
  & Pick<Types.TherapistTreatmentAreaModel, 'id' | 'label' | 'explanation' | 'order'>
);

export const TherapyApproachesFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TherapyApproachesFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistTherapyApproachModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}},{"kind":"Field","name":{"kind":"Name","value":"order"}}]}}]} as unknown as DocumentNode;
export const TreatmentAreaFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TreatmentAreaFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TherapistTreatmentAreaModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}},{"kind":"Field","name":{"kind":"Name","value":"order"}}]}}]} as unknown as DocumentNode;
export const TherapistApproachDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TherapistApproach"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"therapist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"treatmentAreas"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TreatmentAreaFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"therapyApproaches"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TherapyApproachesFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"insuranceCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"therapistClientTypes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}},...TreatmentAreaFragment.definitions,...TherapyApproachesFragment.definitions]} as unknown as DocumentNode;

/**
 * __useTherapistApproach__
 *
 * To run a query within a React component, call `useTherapistApproach` and pass it any options that fit your needs.
 * When your component renders, `useTherapistApproach` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistApproach({
 *   variables: {
 *   },
 * });
 */
export function useTherapistApproach(baseOptions?: Apollo.QueryHookOptions<TherapistApproach, TherapistApproachVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistApproach, TherapistApproachVariables>(TherapistApproachDocument, options);
      }
export function useTherapistApproachLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistApproach, TherapistApproachVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistApproach, TherapistApproachVariables>(TherapistApproachDocument, options);
        }
export type TherapistApproachHookResult = ReturnType<typeof useTherapistApproach>;
export type TherapistApproachLazyQueryHookResult = ReturnType<typeof useTherapistApproachLazyQuery>;
export type TherapistApproachQueryResult = Apollo.QueryResult<TherapistApproach, TherapistApproachVariables>;