import { HOURS_AND_MINUTES } from 'lib/constants/date';
import { getStringFromEnum } from './string';
import { TimeUtil } from './time-util';
export interface OptionFromBackend {
  id: string;
  label: string;
  explanation?: string | null;
}
export interface Option {
  value: string;
  label: string;
  explanation?: string | null;
}

export function transformStringsToOptions(strings?: string[] | null) {
  return strings?.map((string) => ({ value: string, label: string })) || [];
}

export function transformAvailableTimesToOptions(times?: string[] | null) {
  return (
    times?.map((time) => ({
      value: time,
      label: TimeUtil.getFormatted(time, HOURS_AND_MINUTES),
    })) || []
  );
}

export function transformEnumToOptions(enums: {
  [key: string]: string | number;
}) {
  return (
    Object.values(enums).map((value) => {
      return {
        value: String(value),
        label:
          typeof value === 'string' ? getStringFromEnum(value) : String(value),
      };
    }) || []
  );
}

export function transformToOptions(data?: OptionFromBackend[]): Option[] {
  if (!data) return [];
  return data.map((item) => ({
    value: item.id,
    label: item.label,
    explanation: item.explanation,
  }));
}

export function sortOptionsByAlphaBet(data?: Option[]): Option[] {
  if (!data) return [];
  return data.sort((curr, next) => curr.label.localeCompare(next.label));
}
