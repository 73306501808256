import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientSkipSessionFeedbackVariables = Types.Exact<{
  sessionMetaId: Types.Scalars['String'];
}>;


export type PatientSkipSessionFeedback = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'patientReviewSkip'>
);


export const PatientSkipSessionFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PatientSkipSessionFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sessionMetaId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientReviewSkip"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sessionMetaId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sessionMetaId"}}}]}]}}]} as unknown as DocumentNode;
export type PatientSkipSessionFeedbackMutationFn = Apollo.MutationFunction<PatientSkipSessionFeedback, PatientSkipSessionFeedbackVariables>;

/**
 * __usePatientSkipSessionFeedback__
 *
 * To run a mutation, you first call `usePatientSkipSessionFeedback` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientSkipSessionFeedback` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientSkipSessionFeedback, { data, loading, error }] = usePatientSkipSessionFeedback({
 *   variables: {
 *      sessionMetaId: // value for 'sessionMetaId'
 *   },
 * });
 */
export function usePatientSkipSessionFeedback(baseOptions?: Apollo.MutationHookOptions<PatientSkipSessionFeedback, PatientSkipSessionFeedbackVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientSkipSessionFeedback, PatientSkipSessionFeedbackVariables>(PatientSkipSessionFeedbackDocument, options);
      }
export type PatientSkipSessionFeedbackHookResult = ReturnType<typeof usePatientSkipSessionFeedback>;
export type PatientSkipSessionFeedbackMutationResult = Apollo.MutationResult<PatientSkipSessionFeedback>;
export type PatientSkipSessionFeedbackMutationOptions = Apollo.BaseMutationOptions<PatientSkipSessionFeedback, PatientSkipSessionFeedbackVariables>;