import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CoreTrainingsVariables = Types.Exact<{ [key: string]: never; }>;


export type CoreTrainings = (
  { __typename: 'Query' }
  & { coreTrainings: Array<(
    { __typename: 'CoreTrainingModel' }
    & Pick<Types.CoreTrainingModel, 'id' | 'label' | 'explanation'>
  )> }
);


export const CoreTrainingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CoreTrainings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"coreTrainings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useCoreTrainings__
 *
 * To run a query within a React component, call `useCoreTrainings` and pass it any options that fit your needs.
 * When your component renders, `useCoreTrainings` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoreTrainings({
 *   variables: {
 *   },
 * });
 */
export function useCoreTrainings(baseOptions?: Apollo.QueryHookOptions<CoreTrainings, CoreTrainingsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoreTrainings, CoreTrainingsVariables>(CoreTrainingsDocument, options);
      }
export function useCoreTrainingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoreTrainings, CoreTrainingsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoreTrainings, CoreTrainingsVariables>(CoreTrainingsDocument, options);
        }
export type CoreTrainingsHookResult = ReturnType<typeof useCoreTrainings>;
export type CoreTrainingsLazyQueryHookResult = ReturnType<typeof useCoreTrainingsLazyQuery>;
export type CoreTrainingsQueryResult = Apollo.QueryResult<CoreTrainings, CoreTrainingsVariables>;