import * as Types from '../../../__generated__/types';

import { MaterialModelFragment } from '../../query/__generated__/get-patient-session-materials';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MaterialAddedOrRemovedVariables = Types.Exact<{ [key: string]: never; }>;


export type MaterialAddedOrRemoved = (
  { __typename: 'Subscription' }
  & { materialAddedOrRemoved: (
    { __typename: 'MaterialChangeModel' }
    & Pick<Types.MaterialChangeModel, 'type'>
    & { material: (
      { __typename: 'MaterialModel' }
      & Pick<Types.MaterialModel, 'patientId' | 'sessionMetaId' | 'createdAt'>
      & MaterialModelFragment
    ) }
  ) }
);


export const MaterialAddedOrRemovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"MaterialAddedOrRemoved"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"materialAddedOrRemoved"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"material"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MaterialModelFragment"}},{"kind":"Field","name":{"kind":"Name","value":"patientId"}},{"kind":"Field","name":{"kind":"Name","value":"sessionMetaId"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}},...MaterialModelFragment.definitions]} as unknown as DocumentNode;

/**
 * __useMaterialAddedOrRemoved__
 *
 * To run a query within a React component, call `useMaterialAddedOrRemoved` and pass it any options that fit your needs.
 * When your component renders, `useMaterialAddedOrRemoved` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialAddedOrRemoved({
 *   variables: {
 *   },
 * });
 */
export function useMaterialAddedOrRemoved(baseOptions?: Apollo.SubscriptionHookOptions<MaterialAddedOrRemoved, MaterialAddedOrRemovedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MaterialAddedOrRemoved, MaterialAddedOrRemovedVariables>(MaterialAddedOrRemovedDocument, options);
      }
export type MaterialAddedOrRemovedHookResult = ReturnType<typeof useMaterialAddedOrRemoved>;
export type MaterialAddedOrRemovedSubscriptionResult = Apollo.SubscriptionResult<MaterialAddedOrRemoved>;