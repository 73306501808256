import React from 'react';
import styled from 'styled-components';
import { TriangleIcon } from 'ui/icons';
import { getSize } from 'lib/utils';

export interface TriggerButtonProps {
  title: string;
}

function TriggerButton({ title }: TriggerButtonProps) {
  return (
    <Button>
      {title}
      <IconWrap>
        <Icon />
      </IconWrap>
    </Button>
  );
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  font-size: ${getSize(14)};
  line-height: ${getSize(22)};
  background-color: var(--light-gray);
  padding: 6px 17px 6px 22px;
  border-top: 1px solid var(--gray10);
  color: var(--black3);
`;

const Icon = styled(TriangleIcon)`
  width: ${getSize(24)};
  height: ${getSize(24)};
  fill: var(--black3);
  transition: 0.3s ease-out;
`;

const IconWrap = styled.span`
  transform: rotate(180deg);
`;

export default TriggerButton;
