import { useEffect, useState } from 'react';

export function useStopwatch() {
  const [stopwatch, setStopwatch] = useState('');

  useEffect(() => {
    let minute = 0;
    let second = 0;

    const interval = setInterval(() => {
      if (second >= 59) {
        second = 0;
        minute++;
      } else {
        second++;
      }

      const stopwatchCounter = `${minute < 10 ? `0${minute}` : minute}:${
        second < 10 ? `0${second}` : second
      }`;

      setStopwatch(stopwatchCounter);
    }, 1000);

    return () => {
      clearInterval(interval);
      minute = 0;
      second = 0;
    };
  }, []);

  return { stopwatch };
}
