import { StorageService } from '../lib/utils';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AUTH_ROUTES,
  PATIENT_RESOURCES_ROUTES,
  PATIENT_ROUTES,
} from '../lib/routes';

const useTeamsRedirect = () => {
  const accessToken = StorageService.getAccessToken();
  const history = useHistory();
  const url = useHistory();

  useEffect(() => {
    if (accessToken) {
      const path = url.location.pathname ?? '';
      const isResourcePath = path.includes(PATIENT_ROUTES.RESOURCES);

      if (path && !isResourcePath) {
        history.push(PATIENT_RESOURCES_ROUTES.DISCOVER.pathBuild('featured'));
      }
    } else {
      history.push(AUTH_ROUTES.SIGN_IN);
    }
  }, [accessToken]);
};

export default useTeamsRedirect;
