import React, { useCallback, useMemo } from 'react';
import styled, { CSSProp } from 'styled-components';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Weekday, Navbar } from 'ui/calendar-components';
import { getSize, TimeUtil } from 'lib/utils';
import { addDayToRange, DatesRange } from './utils/add-day-to-range';
import THEMES from './utils/themes';

export type CalendarTheme = 'primary' | 'secondary';

export interface CalendarSmallProps {
  className?: string;
  multiple?: boolean;
  isRange?: boolean;
  selectedDays?: Date | Date[];
  selectedRange?: DatesRange;
  onDayClick: (date: Date | Date[]) => void;
  onDayClickRange?: (value: DatesRange) => void;
  disabledPastDay?: boolean;
  disabledFutureDay?: boolean;
  initialMonth?: Date;
  currentMonth?: Date;
  rootCSS?: CSSProp;
  renderDay?: (date: Date) => JSX.Element;
  onMonthChange?: (value: Date) => void;
  firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  theme?: CalendarTheme;
}

function CalendarSmall({
  multiple,
  isRange,
  selectedDays,
  selectedRange,
  onDayClick,
  className,
  disabledPastDay,
  disabledFutureDay,
  initialMonth,
  currentMonth,
  rootCSS,
  renderDay,
  onMonthChange,
  onDayClickRange,
  firstDayOfWeek = 1,
  theme = 'primary',
}: CalendarSmallProps) {
  const handleClick = useCallback(
    (day: Date, { selected }) => {
      if (disabledPastDay) {
        const isPastDate = TimeUtil.getStartOFNowDay().isAfter(day);
        if (isPastDate) return;
      } else if (disabledFutureDay) {
        const isPastDate = TimeUtil.getStartOFNowDay().isBefore(day);
        if (isPastDate) return;
      }

      const selectedDaysArray = Array.isArray(selectedDays) ? selectedDays : [];
      day.setHours(0);
      day.setMinutes(0);
      day.setSeconds(0);
      day.setMilliseconds(0);

      let newSelectedDays: Date | Date[] = day;

      if (multiple) {
        newSelectedDays = [...selectedDaysArray];

        if (selected) {
          const selectedIndex = selectedDaysArray.findIndex((selectedDay) =>
            DateUtils.isSameDay(selectedDay, day),
          );
          newSelectedDays.splice(selectedIndex, 1);
        } else {
          newSelectedDays.push(day);
        }
      } else if (isRange && selectedRange && onDayClickRange) {
        onDayClickRange(addDayToRange({ day, range: selectedRange }));
      }

      onDayClick(newSelectedDays);
    },
    [
      onDayClick,
      selectedDays,
      multiple,
      disabledFutureDay,
      disabledPastDay,
      selectedRange,
      isRange,
      onDayClickRange,
    ],
  );

  const disabledDays = useMemo(() => {
    if (disabledPastDay) {
      return { before: new Date() };
    } else if (disabledFutureDay) {
      return { after: new Date() };
    }
  }, [disabledPastDay, disabledFutureDay]);

  return (
    <Calendar
      firstDayOfWeek={firstDayOfWeek}
      className={className}
      selectedDays={selectedDays}
      initialMonth={initialMonth}
      month={currentMonth}
      onDayClick={handleClick}
      weekdayElement={Weekday}
      captionElement={<></>}
      navbarElement={(props) => (
        <Navbar
          {...props}
          onMonthChange={onMonthChange}
          selectedDay={selectedDays}
        />
      )}
      disabledDays={disabledDays}
      renderDay={renderDay}
      $CSS={rootCSS}
      $theme={theme}
    />
  );
}

const Calendar = styled(DayPicker)<{ $CSS?: CSSProp; $theme: CalendarTheme }>`
  ${({ $theme }) => THEMES[$theme]?.() || ''}
`;

export default CalendarSmall;
