import * as Types from '../../../__generated__/types';

import { PatientNote } from '../../query/__generated__/get-patient-notes';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientNoteEditVariables = Types.Exact<{
  input: Types.PatientEditNoteInput;
}>;


export type PatientNoteEdit = (
  { __typename: 'Mutation' }
  & { patientNoteEdit: (
    { __typename: 'NotePayload' }
    & { note?: Types.Maybe<(
      { __typename: 'NoteModel' }
      & PatientNote
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const PatientNoteEditDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PatientNoteEdit"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PatientEditNoteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientNoteEdit"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"note"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PatientNote"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}},...PatientNote.definitions]} as unknown as DocumentNode;
export type PatientNoteEditMutationFn = Apollo.MutationFunction<PatientNoteEdit, PatientNoteEditVariables>;

/**
 * __usePatientNoteEdit__
 *
 * To run a mutation, you first call `usePatientNoteEdit` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientNoteEdit` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientNoteEdit, { data, loading, error }] = usePatientNoteEdit({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientNoteEdit(baseOptions?: Apollo.MutationHookOptions<PatientNoteEdit, PatientNoteEditVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientNoteEdit, PatientNoteEditVariables>(PatientNoteEditDocument, options);
      }
export type PatientNoteEditHookResult = ReturnType<typeof usePatientNoteEdit>;
export type PatientNoteEditMutationResult = Apollo.MutationResult<PatientNoteEdit>;
export type PatientNoteEditMutationOptions = Apollo.BaseMutationOptions<PatientNoteEdit, PatientNoteEditVariables>;