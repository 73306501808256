import TagManager from 'react-gtm-module';

const enabled = process.env.NODE_ENV === 'production';

export const gtmService = {
  init() {
    if (enabled) {
      const tagManagerArgs = {
        gtmId: 'GTM-58WQ6ZG',
      };

      TagManager.initialize(tagManagerArgs);
    }
  },
};
