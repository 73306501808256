import { ApolloQueryResult } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

const useInfinityScroll = <T>(
  rootRef: React.RefObject<HTMLDivElement>,
  fetchMore: () => Promise<ApolloQueryResult<T>>,
  hasNextPage: boolean,
) => {
  const [isFetching, setIsFetching] = useState(false);
  const [scrollIsBottom, setScrollIsBottom] = useState(false);

  const isScrolling = useCallback(() => {
    const targetElement = rootRef?.current;

    if (targetElement) {
      const height = targetElement.scrollHeight - window.innerHeight;

      const top = Math.abs(
        Math.floor(targetElement?.getBoundingClientRect().top),
      );

      if (height <= top) {
        setScrollIsBottom(true);
      }
    }
  }, [rootRef]);

  useEffect(() => {
    window.addEventListener('scroll', isScrolling, true);

    return () => window.removeEventListener('scroll', isScrolling);
  }, [rootRef, isScrolling]);

  useEffect(() => {
    if (!isFetching && hasNextPage && scrollIsBottom) {
      setIsFetching(true);
      setScrollIsBottom(false);

      fetchMore().finally(() => {
        setIsFetching(false);
      });
    }
  }, [scrollIsBottom, isFetching, hasNextPage, fetchMore]);

  return { isFetching };
};

export default useInfinityScroll;
