import { getSize } from 'lib/utils';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { BaseStyleProps } from 'types';

const ProfessionTitle: FC<ProfessionTitleProps> = ({ titles, className }) => {
  const concatedTitles = useMemo(
    () =>
      titles.reduce((accum, item, index) => {
        if (!item) {
          return accum;
        }

        if (index === titles.length - 1) {
          return accum + item;
        }

        return accum + item + ' • ';
      }, ''),
    [titles],
  );

  return <Root className={className}>{concatedTitles}</Root>;
};

interface ProfessionTitleProps extends BaseStyleProps {
  titles: (string | undefined)[];
}

const Root = styled.p`
  color: var(--purple);

  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  font-weight: 500;
`;

export default ProfessionTitle;
