import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ROOT_ROUTES } from 'lib/routes';
import { Loader } from '../ui';
import { EmailChanged } from 'features/email-changed';
import ScrollToTop from './scroll-to-top';
import { useShareSessionStorage } from 'hooks/use-share-session-storage';

// Test playground
import { Sandbox } from 'features/sandbox';

const NotFound = lazy(() => import('./not-found'));
const Patient = lazy(() => import('./patient'));
const Therapist = lazy(() => import('./therapist'));
const Auth = lazy(() => import('./auth'));
const Home = lazy(() => import('./home'));
const Onboarding = lazy(() => import('./onboarding'));
const NotMatchRole = lazy(() => import('./not-match-role'));

const {
  AUTH,
  PATIENT,
  THERAPIST,
  NOT_FOUND,
  HOME,
  ONBOARDING,
  NOT_MATCH_ROLE,
  EMAIL_CHANGED,
  SANDBOX,
} = ROOT_ROUTES;

const Routes = () => {
  useShareSessionStorage();

  return (
    <Router>
      <Suspense fallback={<Loader hasFillWholeBlock size={50} />}>
        <ScrollToTop />

        <Switch>
          <Route path={SANDBOX} component={Sandbox} />
          <Route path={EMAIL_CHANGED} component={EmailChanged} />
          <Route path={NOT_MATCH_ROLE} component={NotMatchRole} />
          <Route path={AUTH} component={Auth} />
          <Route path={ONBOARDING} component={Onboarding} />
          <Route path={PATIENT} component={Patient} />
          <Route path={THERAPIST} component={Therapist} />
          <Route path={NOT_FOUND} component={NotFound} />
          <Route path={HOME} component={Home} />
          <Route path="*" component={Home} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
