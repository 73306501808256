import React from 'react';

function UserPrimaryIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="#E5E4E8"
      viewBox="0 0 24 25"
      {...rest}
    >
      <g clipPath="url(#user-icon-svg)">
        <path d="M23.984 23.396l-.6-2.4a3.485 3.485 0 00-2.285-2.478l-4.4-1.466c-1.073-.447-1.564-2.175-1.67-2.832a4.158 4.158 0 001.47-2.703.815.815 0 01.121-.514.5.5 0 00.343-.3 7.474 7.474 0 00.536-2.185.484.484 0 00-.015-.121A1.286 1.286 0 0017 7.669V5.018c0-1.611-.492-2.272-1.01-2.655C15.89 1.587 15.06.018 12 .018a5.214 5.214 0 00-5 5v2.65a1.286 1.286 0 00-.485.729.48.48 0 00-.015.12c.068.752.248 1.49.536 2.187.044.14.157.25.3.287.056.028.161.173.161.527a4.157 4.157 0 001.478 2.708c-.105.655-.593 2.382-1.636 2.818L2.9 18.518a3.485 3.485 0 00-2.284 2.477l-.6 2.4a.5.5 0 00.484.623h23a.5.5 0 00.485-.622z" />
      </g>
      <defs>
        <clipPath id="user-icon-svg">
          <path fill="#fff" d="M0 0h24v24H0z" transform="translate(0 .018)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UserPrimaryIcon;
