import React from 'react';

// Import custom components
import { ContentCard } from '../components/content-card';
import { Loader } from 'ui';

// Interface definition
interface ContentProps {
  posts?: any;
  isLoading?: boolean;
}

const Content = (props: ContentProps) => {
  const { posts, isLoading } = props;

  if (isLoading) {
    return <Loader size={50} />;
  } else if (!posts) {
    return null;
  }

  /** TODO: This sorting should be on backend not frontend */
  const sortedPosts = [...posts];
  sortedPosts.sort((a: any, b: any) => {
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="container g-0">
      <div className="row">
        {sortedPosts.map((post: any, i: number) => {
          const className = i <= 1 ? 'col-md-6 mt-4' : 'col-md-4 mt-4';
          const cardSize = i <= 1 ? 'large' : 'small';

          return (
            <div key={post.id} className={className}>
              <ContentCard
                id={post.id}
                size={cardSize}
                type={post.type}
                title={post.title}
                author={post.author}
                imageUrl={post.imageUrl}
                subtitle={post.subtitle}
                createdAt={post.createdAt}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Content;
