import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { Loader } from 'ui/loader';
import THEMES from './utils/themes';
import { getSize } from 'lib/utils';
import { ArrowRightIcon } from 'ui/icons';

export type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'buble-primary'
  | 'buble-secondary'
  | 'outline'
  | 'purpleGradient';
export interface ButtonProps {
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children: ReactNode;
  theme?: ButtonTheme;
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  withIcon?: boolean;
  type?: ButtonHTMLAttributes<Element>['type'];
}

function Button({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  theme,
  className,
  isDisabled,
  isLoading,
  withIcon = false,
  type,
}: ButtonProps) {
  const isWhiteLoader = theme === 'primary' || theme === 'tertiary';

  return (
    <Wrapper
      className={className}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      theme={theme}
      isLoading={isLoading}
      type={type}
      disabled={isDisabled || isLoading}
    >
      {isLoading ? (
        <Loader pathColor={isLoading && isWhiteLoader ? 'white' : undefined} />
      ) : withIcon ? (
        <ChildrenWithIconWrapper>
          {children} <Icon />
        </ChildrenWithIconWrapper>
      ) : (
        children
      )}
    </Wrapper>
  );
}

const Wrapper = styled.button<{ theme: ButtonTheme; isLoading?: boolean }>`
  padding: ${getSize(8)};
  min-height: ${getSize(42)};
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  border-radius: ${getSize(8)};
  transition: 0.3s ease-out;
  color: var(--black3);

  ${({ theme, isLoading }) => THEMES[theme]?.(isLoading) || ''}
`;

const ChildrenWithIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled(ArrowRightIcon)`
  margin-left: ${getSize(5)};

  ${Wrapper}:hover:not([disabled]) & {
    & path {
      transition: 0.3s ease-out;

      fill: var(--purple18);
    }
  }
`;

export default Button;
