import { getSize } from 'lib/utils';
import React, { ReactNode, useEffect, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import { ArrowRightIcon, ArrowRightShortIcon, Button } from 'ui';

interface WizardFormProps<T> {
  children: ReactNode;
  currentScreen: number;
  setCurrentScreenIndex: (value: number) => void;
  setScreensCount: (value: number) => void;
  onSubmit: (values: T) => void;
  onFinishAssessmentOutcome: () => void;
  titles: string[];
  isQuestionnaire?: boolean;
  isScrollableField?: boolean;
  rootCSS?: CSSProp;
  titleCSS?: CSSProp;
  assessmentOutput?: boolean;
}

function WizardForm<T>({
  children,
  currentScreen,
  setCurrentScreenIndex,
  setScreensCount,
  onSubmit,
  onFinishAssessmentOutcome,
  titles,
  isQuestionnaire,
  isScrollableField = true,
  rootCSS,
  titleCSS,
  assessmentOutput,
}: WizardFormProps<T>) {
  const [childrenArray] = useState(React.Children.toArray(children));

  const isLastChild = currentScreen === childrenArray.length - 1;

  const isFirstChild = currentScreen === 0;
  const isPenultimateChild = currentScreen === childrenArray.length - 2;

  const currentTitle = titles[currentScreen] || [];

  useEffect(
    () => setScreensCount(childrenArray.length - 1),
    [childrenArray, setScreensCount],
  );

  const currentChildren: any = childrenArray[currentScreen];
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        const currentValue = values[currentChildren?.props.name as keyof T];

        return (
          <Form $CSS={rootCSS}>
            {isQuestionnaire && !isLastChild && (
              <QuestionnaireTitle>
                Over the last two weeks, how often have you been bothered by
              </QuestionnaireTitle>
            )}
            {currentTitle.length !== 0 && (
              <Title $CSS={titleCSS}>
                {!isLastChild && !assessmentOutput && `${currentScreen + 1}.`}{' '}
                {currentTitle}
              </Title>
            )}
            <Wrapper>
              {isScrollableField ? (
                <ScrollableWrapper>
                  {childrenArray[currentScreen]}
                </ScrollableWrapper>
              ) : (
                <>{childrenArray[currentScreen]}</>
              )}
            </Wrapper>

            {assessmentOutput ? (
              <>
                {isLastChild ? (
                  <SubmitAOButton
                    type="button"
                    onClick={onFinishAssessmentOutcome}
                    theme="purpleGradient"
                  >
                    Go to Dashboard <ArrowRightIcon />
                  </SubmitAOButton>
                ) : (
                  <SubmitAOButton
                    type="button"
                    onClick={() =>
                      isPenultimateChild
                        ? handleSubmit()
                        : setCurrentScreenIndex(currentScreen + 1)
                    }
                    isDisabled={
                      isFirstChild
                        ? false
                        : Array.isArray(currentValue)
                        ? currentValue.length === 0
                        : !currentValue
                    }
                    theme="purpleGradient"
                  >
                    {isFirstChild ? 'Continue' : 'Next'} <ArrowRightShortIcon />
                  </SubmitAOButton>
                )}
              </>
            ) : (
              <>
                {isLastChild ? (
                  <SubmitButton
                    type="button"
                    onClick={onFinishAssessmentOutcome}
                    theme="primary"
                  >
                    Done
                  </SubmitButton>
                ) : (
                  <SubmitButton
                    type="button"
                    onClick={() =>
                      isPenultimateChild
                        ? handleSubmit()
                        : setCurrentScreenIndex(currentScreen + 1)
                    }
                    isDisabled={
                      Array.isArray(currentValue)
                        ? currentValue.length === 0
                        : !currentValue
                    }
                    theme="primary"
                  >
                    Next
                  </SubmitButton>
                )}
              </>
            )}
          </Form>
        );
      }}
    />
  );
}

const Form = styled.form<{ $CSS?: CSSProp }>`
  flex-grow: 1;
  height: 100%;
  max-height: ${getSize(586)};
  width: ${getSize(458)};
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${({ $CSS }) => $CSS}
`;

const Title = styled.h2<{ $CSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};
  text-align: center;
  margin-bottom: ${getSize(20)};
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

const Wrapper = styled.div`
  position: relative;
  flex-shrink: 1;
  height: 100%;
  overflow-y: auto;
  margin-bottom: ${getSize(10)};
`;

const ScrollableWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: auto;
`;

const SubmitAOButton = styled(Button)`
  width: ${getSize(206)};
  margin-top: auto;
`;

const QuestionnaireTitle = styled.p`
  position: absolute;
  top: ${getSize(-35)};
  left: 50%;
  transform: translateX(-50%);
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
  white-space: nowrap;
`;

export default WizardForm;
