import { PageName } from 'ui/onboarding-wrapper/onboarding-wrapper';
import {
  AssessmentOutcomeInput,
  SessionRequestAddInput,
  SessionType,
} from '__generated__/types';

export interface IOnboardingStep {
  currentStep: PageName;
  stepUrl: string;
}
export type PreBookingInfo = Omit<SessionRequestAddInput, 'paymentType'>;

class StorageService {
  /** Storage keys */
  public accessTokenName = '@mental-health-access-token';
  public shareAccessToken = '@mental-health-share-access-token-between-pages';
  public isFirstTimeAuthorization = '@mental-health-first-authorization';
  private isRememberUserName = '@mental-health-is-remember-user';
  private patientBookUrlParams = '@mental-health-patient-book-url-params';
  private patientBookInitialValues =
    '@mental-health-patient-bool-session-initial-values';
  private preBookedSessionInfo = '@mental-health-prebooked-session-info';
  private bookedSessionInfo = '@mental-health-booked-session-info';
  private onboardingStep = '@mental-health-onboarding-step';
  private isRedirectToGuidedMatching =
    '@mental-health-is-redirect-to-guided-matching';
  private guidedMatchData = '@mental-health-guided-match-data';
  private openedPagesCount = '@mental-health-opened-pages-count';
  private isLogout = '@mental-health-is-logout';
  private readonly contentAfterAuthRedirect =
    '@@mental-health-content-after-auth-redirect';
  // to get auth info about user for amplitude events
  private afterAuthRedirect = '@@mental-health-after-auth-redirect';
  private isAOCompleted = '@mental-health-is-ao-completed';
  private isTeamsApp = '@is-teams-app';

  // This is path that b2b user can picke when logging in app, it can be either therapy or selfHelp
  private choosenPath = '@mental-health-choosen-path';

  setChoosenPath = (choosenPath: 'therapy' | 'selfHelp') => {
    localStorage.setItem(this.choosenPath, choosenPath);
  };

  getChoosenPath = () => {
    return localStorage.getItem(this.choosenPath);
  };

  setAccessToken = (token: string, isRememberMe?: boolean) => {
    if (isRememberMe) {
      localStorage.setItem(this.accessTokenName, token);
    } else {
      sessionStorage.setItem(this.accessTokenName, token);
    }
    return token;
  };

  getAccessToken = () => {
    return (
      sessionStorage.getItem(this.accessTokenName) ||
      localStorage.getItem(this.accessTokenName)
    );
  };

  setShareAccessToken = () => {
    localStorage.setItem(this.shareAccessToken, new Date().toISOString());
  };

  removeAccessToken = () => {
    sessionStorage.removeItem(this.accessTokenName);
    localStorage.removeItem(this.accessTokenName);
  };

  removeAccessTokenFormLocalStorage = () => {
    localStorage.removeItem(this.accessTokenName);
  };

  setIsRememberUser = () => {
    localStorage.setItem(this.isRememberUserName, 'true');
  };

  getIsRememberUser = () => {
    return localStorage.getItem(this.isRememberUserName);
  };

  removeIsRememberUser = () => {
    localStorage.removeItem(this.isRememberUserName);
  };

  setPatientBookUrlParams = (base64: string) => {
    localStorage.setItem(this.patientBookUrlParams, base64);
  };

  getPatientBookUrlParam = () => {
    return localStorage.getItem(this.patientBookUrlParams);
  };

  removePatientBookUrlParams = () => {
    localStorage.removeItem(this.patientBookUrlParams);
  };

  setPatientBookInitialValues = (values: {
    sessionType?: SessionType;
    timeInterval?: string;
  }) => {
    localStorage.setItem(this.patientBookInitialValues, JSON.stringify(values));
  };

  getPatientBookInitialValues = () => {
    return localStorage.getItem(this.patientBookInitialValues);
  };

  setBookedSessionInfo = (values: SessionRequestAddInput) => {
    localStorage.setItem(this.bookedSessionInfo, JSON.stringify(values));
  };

  getBookedSessionInfo = () => {
    return localStorage.getItem(this.bookedSessionInfo);
  };

  setPreBookedSessionInfo = (values: PreBookingInfo) => {
    localStorage.setItem(this.preBookedSessionInfo, JSON.stringify(values));
  };

  getPreBookedSessionInfo = () => {
    return localStorage.getItem(this.preBookedSessionInfo);
  };

  setOnboardingStep = (step: IOnboardingStep) => {
    localStorage.setItem(this.onboardingStep, JSON.stringify(step));
  };

  getOnboardingStep = () => {
    return localStorage.getItem(this.onboardingStep);
  };

  removeOnboardingStep = () => {
    localStorage.removeItem(this.onboardingStep);
  };

  setIsRedirectToGuidedMatching = () => {
    sessionStorage.setItem(this.isRedirectToGuidedMatching, 'true');
  };

  getIsRedirectToGuidedMatching = () => {
    return sessionStorage.getItem(this.isRedirectToGuidedMatching);
  };

  removeIsRedirectToGuidedMatching = () => {
    sessionStorage.removeItem(this.isRedirectToGuidedMatching);
  };

  setGuidedMatchData = (data: AssessmentOutcomeInput) => {
    localStorage.setItem(this.guidedMatchData, JSON.stringify(data));
  };

  getGuidedMatchData = () => {
    return localStorage.getItem(this.guidedMatchData);
  };

  setIsFirstTimeAuthorization = (isFirstTimeAuthorization: string) =>
    localStorage.setItem(
      this.isFirstTimeAuthorization,
      isFirstTimeAuthorization,
    );

  getIsFirstTimeAuthorization = () =>
    localStorage.getItem(this.isFirstTimeAuthorization);

  removeGuidedMatchData = () => {
    localStorage.removeItem(this.guidedMatchData);
  };

  setOpenedPagesCount = (count: number) => {
    localStorage.setItem(this.openedPagesCount, String(count));
  };

  getOpenedPagesCount = () => {
    return localStorage.getItem(this.openedPagesCount);
  };

  removeOpenedPageCount = () => {
    localStorage.removeItem(this.openedPagesCount);
  };

  setIsLogout = () => {
    localStorage.setItem(this.isLogout, 'true');
  };

  getIsLogout = () => {
    return localStorage.getItem(this.isLogout);
  };

  removeIsLogout = () => {
    localStorage.removeItem(this.isLogout);
  };

  setContentAfterAuthRedirect = (path: string) => {
    sessionStorage.setItem(this.contentAfterAuthRedirect, path);
  };

  getContentAfterAuthRedirect = () => {
    return sessionStorage.getItem(this.contentAfterAuthRedirect);
  };

  removeContentAfterAuthRedirect = () => {
    localStorage.removeItem(this.contentAfterAuthRedirect);
  };

  setAfterAuthRedirect = (value: boolean) => {
    localStorage.setItem(this.afterAuthRedirect, JSON.stringify(value));
  };

  getAfterAuthRedirect = () => {
    return localStorage.getItem(this.afterAuthRedirect);
  };

  removeAfterAuthRedirect = () => {
    localStorage.removeItem(this.afterAuthRedirect);
  };

  setIsAOCompleted = (value: boolean) => {
    localStorage.setItem(this.isAOCompleted, JSON.stringify(value));
  };

  getIsAOCompleted = () => {
    return localStorage.getItem(this.isAOCompleted);
  };

  removeIsAOCompleted = () => {
    localStorage.removeItem(this.isAOCompleted);
  };

  setIsTeamsApp = (value: boolean) => {
    sessionStorage.setItem(this.isTeamsApp, JSON.stringify(value));
  };

  getIsTeamsApp = () => {
    return sessionStorage.getItem(this.isTeamsApp);
  };
}

export default new StorageService();
