import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Peer from 'simple-peer';
import { io, Socket } from 'socket.io-client';
import { VideoWindow } from 'ui';
import { config, StorageService } from 'lib/utils';
import { FeedbackContext } from 'hooks/use-feedback';
import { SentryService } from 'lib';
import { useGetTherapistSessionMetaLazyQuery } from 'common/query/__generated__/get-therapist-session-meta';
import { notifyError } from 'lib/utils/notification';
import axios from 'axios';

interface DataForStartCall {
  sessionId?: string;
  startDateEncoded?: string;
  startDateUtc?: string;
  patientName?: string;
  patientId?: string;
}
export interface TargetStream {
  stream: MediaStream;
  socketId: string;
}

const log = (...arg: any[]) => console.log('MYLOG:', ...arg);

const constraints: MediaStreamConstraints = {
  audio: true,
  video: {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    facingMode: {
      ideal: 'user',
    },
  },
};

function getMedia(mediaConstraints: MediaStreamConstraints) {
  return navigator.mediaDevices.getUserMedia(mediaConstraints);
}

function connectToSignaling(hostAndPort: string, sessionMetaId: string) {
  const scheme = 'https';

  const socket = io(`${scheme}://${hostAndPort}/signaling`, {
    transports: ['websocket'],
    query: {
      sessionMetaId,
      token: StorageService.getAccessToken(),
    },
    reconnection: false,
    autoConnect: false,
  });

  socket.on('connect_error', (err: any) => {
    SentryService.addBreadcrumb({
      data: {
        clientOS: navigator.userAgent,
      },
    });
    SentryService.captureException(err);
  });

  socket.on('error', (error) => {
    SentryService.addBreadcrumb({
      category: 'videoCall',
      data: {
        clientOS: navigator.userAgent,
      },
      level: 'info',
      message: `Error ${error.message}`,
    });
    SentryService.captureException(error);
  });

  socket.on('connection', (socket: any) => {
    SentryService.captureMessage('Socket handshake query');
    SentryService.addBreadcrumb({
      category: 'videoCall',
      message: `Socket handshake query: ${socket.handshake.query}`, // prints { x: "42", EIO: "4", transport: "polling" }
      level: 'info',
    });
  });

  try {
    socket.connect();
    socket.on('connection', (socket: any) => {
      SentryService.captureMessage('Connected');
      SentryService.addBreadcrumb({
        category: 'videoCall',
        message: `Connected`, // prints { x: "42", EIO: "4", transport: "polling" }
        level: 'info',
      });
    });
  } catch (e) {
    console.log('e', e);
    SentryService.captureException(e);
  }

  return socket;
}

export const VideoCallContext = createContext({
  showVideoCallWindow: (() => {}) as Dispatch<
    SetStateAction<DataForStartCall | undefined>
  >,
});

export function useVideoCallWindow() {
  const { showVideoCallWindow } = useContext(VideoCallContext);
  return { showVideoCallWindow };
}

export function useVideoCall(isPatient?: boolean) {
  const socket = useRef<Socket | undefined>(undefined);
  const { onSetIsSessionInProgress } = useContext(FeedbackContext);

  const [isCallStarted, setIsCallStarted] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [isConnectionError, setIsConnectionError] = useState<string | null>(
    null,
  );
  const [hasLocalVideoRequest, setHasLocalVideoRequest] = useState(false);
  const [counter, setCounter] = useState(1); // used for update isMuted<device>
  const [isVideoWindowVisible, setIsVideoWindowVisible] = useState(false);

  const [dataForStartCall, setDataForStartCall] = useState<DataForStartCall>();
  const [peers, setPeers] = useState<Record<string, Peer.Instance>>({});
  const [localStream, setLocalStream] = useState<MediaStream>();
  const [targetStreams, setTargetStreams] = useState<TargetStream[]>([]);
  const [isBothUserConnected, setIsBothUserConnected] = useState(false);
  const [isLostConnection, setIsLostConnection] = useState(false);

  const [
    getTherapistSessionMeta,
    {
      data: sessionMetaData,
      error: sessionMetaError,
      loading: isSessionMetaLoading,
    },
  ] = useGetTherapistSessionMetaLazyQuery();

  useEffect(() => {
    if (dataForStartCall) setIsVideoWindowVisible(true);
  }, [dataForStartCall]);
  useEffect(() => {
    const { sessionId, startDateEncoded } = dataForStartCall || {};

    if (sessionId && startDateEncoded) {
      getTherapistSessionMeta({
        variables: { input: { id: sessionId, hash: startDateEncoded } },
      });
    }
  }, [getTherapistSessionMeta, dataForStartCall]);

  const sessionMeta = sessionMetaData?.sessionMeta.sessionMeta;
  const sessionMetaId = sessionMeta?.id;
  const therapistInfo =
    sessionMetaData?.sessionMeta?.sessionMeta?.therapistPublic;
  const sessionTimeStart = sessionMeta?.startDateUtc;

  useEffect(() => {
    onSetIsSessionInProgress(true);
    return () => onSetIsSessionInProgress(false);
  });

  useEffect(() => {
    if (isVideoWindowVisible && !localStream && sessionMetaId) {
      setHasLocalVideoRequest(true);
      getMedia(constraints)
        .then(setLocalStream)
        .catch((e: any) => {
          log(e);
          if (e.name === 'NotAllowedError') {
            SentryService.captureException(e);
          }
        })
        .finally(() => setHasLocalVideoRequest(false));
    }

    return () => {
      localStream?.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [sessionMetaId, isVideoWindowVisible, localStream]);
  const removePeer = (socketId: string) => {
    log('DESTROY', socketId, peers[socketId]);

    if (peers[socketId]) {
      peers[socketId].removeAllListeners();
      peers[socketId].destroy();
      setIsCallStarted(false);
      delete peers[socketId];
    }
    setPeers({ ...peers });
    setTargetStreams(targetStreams.filter((v) => v.socketId !== socketId));
  };

  const destroySocket = useCallback(() => {
    if (socket.current) {
      if (sessionMetaId) {
        socket.current?.emit('end', sessionMetaId);
      }
      socket.current?.offAny();
      socket.current?.disconnect();
      socket.current?.close();
      socket.current = undefined;
    }
  }, [sessionMetaId, !!socket.current]);

  useEffect(() => {
    if (localStream && sessionMetaId && !socket.current && !connecting) {
      log('---CONNECT---');

      setConnecting(true);
      socket.current = connectToSignaling(
        config.WS_HOST_AND_PORT,
        sessionMetaId,
      );

      socket.current?.on('error', (data: any) => {
        console.log('data', data);
        SentryService.captureException(data);
      });

      const handleErrors = (error: any) => {
        const errorData = {
          WebSocketError: error,
          clientOS: navigator.userAgent,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          socketURL: socket?.current?.io.uri,
          socketEngine: socket.current?.io.engine,
        };

        setIsLostConnection(true);

        SentryService.addBreadcrumb({
          data: errorData,
        });

        SentryService.captureException(error);
      };

      socket.current.on('connect_error', (err: any) => handleErrors(err));
      socket.current.on('connect_failed', (err: any) => handleErrors(err));

      let configuration: RTCConfiguration | undefined;
      const addPeer = (socketId: string, amInitiator: boolean) => {
        peers[socketId] = new Peer({
          initiator: amInitiator,
          stream: localStream,
          config: configuration,
        });

        peers[socketId]._debug = console.log;
        const onError = (e: any) => {
          log('#$#', e);
          SentryService.captureException(e);
        };
        peers[socketId].on('error', onError);

        const onSignal = (sessionMetaData: any) => {
          console.log('onSignal');
          socket.current?.emit('signal', {
            signal: sessionMetaData,
            socketId,
          });
          SentryService.addBreadcrumb({
            category: 'videoCall',
            message: `On signal`, // prints { x: "42", EIO: "4", transport: "polling" }
            level: 'info',
          });
        };
        peers[socketId].on('signal', onSignal);

        const onStream = (stream: MediaStream) => {
          setTargetStreams(targetStreams.concat({ stream, socketId }));
        };
        peers[socketId].on('stream', onStream);
        setPeers({ ...peers });
        setIsCallStarted(true);
      };

      socket.current?.on('connect_failed', (data: any) => {
        console.log('connect_failed');
      });

      socket.current?.on('count', (data: any) => {
        setIsBothUserConnected(data?.count && data.count > 1);
      });

      socket.current.on('authorized', () => {
        setConnecting(false);
        setIsConnectionError(null);

        socket.current?.on('initReceive', (socketId: string) => {
          log('INIT RECEIVE ' + socketId);
          addPeer(socketId, false);

          socket.current?.emit('initSend', socketId);
        });

        socket.current?.on('initSend', (socketId: string) => {
          log('INIT SEND ' + socketId);
          addPeer(socketId, true);
        });

        socket.current?.on('removePeer', (socketId: string) => {
          log('removing peer ' + socketId);
          removePeer(socketId);
        });
        socket.current?.on('disconnect', () => {
          socket.current?.offAny();
          log('GOT DISCONNECTED', isVideoWindowVisible);
          axios.head(config.API_URL).catch((e) => {
            if (e.message === 'Network Error')
              notifyError({
                title: 'Unable to connect',
                text: 'Make sure you are connected to the internet',
              });
          });
          if (isVideoWindowVisible) {
            handleCloseClick();
          }
        });
        socket.current?.on('connection-error', (e: string) => {
          log('connection-error');
          destroySocket();
          setIsConnectionError(e);
        });
        socket.current?.on('signal', (data: any) => {
          if (peers[data.socket_id] && !peers[data.socket_id].destroyed) {
            log('ON_SIGNAL', peers[data.socket_id].destroyed);
            peers[data.socket_id].signal(data.signal);
          }
        });

        socket.current?.on('credentials', ({ username, credential }: any) => {
          configuration = {
            iceServers: [
              { urls: 'stun:stun.l.google.com:19302' },
              {
                username,
                credential,
                urls: 'turn:turn.mytherapyassistant.com:3478',
              },
            ],
          };
        });
      });
    }
  }, [
    localStream,
    sessionMetaId,
    peers,
    targetStreams,
    destroySocket,
    !socket.current,
    connecting,
    isVideoWindowVisible,
    handleCloseClick,
    removePeer,
  ]);
  function handleStart() {
    if (sessionMetaId && Object.keys(peers).length === 0) {
      socket.current?.emit('start', sessionMetaId);
    }
    setIsCallStarted(true);
  }

  function handleEnd() {
    if (sessionMetaId) {
      socket.current?.emit('end', sessionMetaId);
      setIsCallStarted(false);
    }
  }

  function handleCloseClick() {
    for (const socketId in peers) {
      removePeer(socketId);
    }
    setIsVideoWindowVisible(false);
    setDataForStartCall(undefined);
    setLocalStream(undefined);

    setIsCallStarted(false);
    destroySocket();
    setConnecting(false);
    setIsBothUserConnected(false);
    setIsLostConnection(false);
  }
  function toggleCamera() {
    if (localStream) {
      localStream.getVideoTracks()[0].enabled =
        !localStream.getVideoTracks()[0].enabled;
    }
    setCounter(counter + 1);
  }

  function toggleMicrophone() {
    if (localStream) {
      localStream.getAudioTracks()[0].enabled =
        !localStream.getAudioTracks()[0].enabled;
    }
    setCounter(counter + 1);
  }

  function getInterlocutorName() {
    if (sessionMeta?.patientConnected && sessionMeta.therapistPublic) {
      const { patientConnected, therapistPublic } = sessionMeta;

      return isPatient
        ? therapistPublic.fullName
        : `${patientConnected.firstName} ${patientConnected.lastName}`;
    }

    return '';
  }

  const videoWindowProps = {
    isCallStarted,
    localStream,
    targetStreams,
    handleEnd,
    handleStart,
    isPatient,
    toggleCamera,
    toggleMicrophone,
    hasLocalVideoRequest,
    sessionMetaError,
    isVisible: isVideoWindowVisible,
    isSessionMetaLoading,
    onCloseClick: handleCloseClick,
    interlocutorName: getInterlocutorName(),
    isMicMuted: !localStream?.getAudioTracks()[0].enabled,
    isCameraMuted: !localStream?.getVideoTracks()[0].enabled,
    isConnectionError,
    therapistInfo,
    sessionTimeStart,
    isBothUserConnected,
    dataForStartCall,
    isLostConnection,
  };

  return {
    VideoWindow,
    videoWindowProps,
    showVideoCallWindow: setDataForStartCall,
  };
}
