import { getSize } from 'lib/utils';

const THEMES: any = {
  primary: (isLoading: boolean) => `
    font-weight: 600;
    color: white;
    background: var(--purple);

    &:hover {
      background: var(--purple4);
    }

    ${
      !isLoading &&
      `
      &:disabled {
        color: var(--purple3);
        background: var(--purple17);
      }
    `
    }
  `,
  secondary: (isLoading: boolean) => `
    border: ${getSize(1)} solid var(--purple3);
    background: white;

    &:hover {
      border-color: var(--gray3);
    }
    
    &:active {
      border-color: var(--purple5);
      background: var(--purple5);
    }

    &:focus {
      border-color: var(--purple5);
    }

    &:disabled {
      border-color: var(--gray4);;
      color: var(--gray7);
    }

    ${isLoading ? 'border-color: var(--gray4);' : ''}
  `,
  tertiary: (isLoading: boolean) => `
    border-radius: ${getSize(25)};
    font-weight: 700;
    font-size: ${getSize(13)};
    line-height: ${getSize(19)};
    letter-spacing: 0.72px;
    color: white;
    background: var(--purple21);

    &:hover {
      background: var(--purple16);
    }

    ${
      !isLoading &&
      `
      &:disabled {
        background: var(--purple9);
      }
    `
    }
  `,
  'buble-primary': () => `
    padding: ${getSize(7)} ${getSize(28)};
    min-height: auto;
    font-weight: 600;
    font-size: ${getSize(12)};
    line-height: ${getSize(18)};
    color: var(--purple);
    border: ${getSize(1)} solid var(--purple);
    border-radius: ${getSize(28)};
    transition: 0.2s ease-out;
    text-transform: uppercase;

    &:hover {
      box-shadow: 0 0 ${getSize(15)} var(--purple-opacity2);
    }
  `,
  'buble-secondary': () => `
    padding: ${getSize(7)} ${getSize(28)};
    min-height: auto;
    font-weight: 400;
    font-size: ${getSize(12)};
    line-height: ${getSize(18)};
    color: var(--black3);
    border: ${getSize(1)} solid var(--purple3);
    border-radius: ${getSize(28)};
    transition: 0.2s ease-out;

    &:hover {
      border-color: var(--purple);
      color: var(--purple);
      box-shadow: 0 0 ${getSize(15)} var(--purple-opacity2);
    }
  `,
  outline: () => `
    padding: ${getSize(11)} ${getSize(28)};
    font-weight: 500;
    font-size: ${getSize(13)};
    line-height: ${getSize(19)};
    color: var(--gray9);
    border: ${getSize(1)} solid var(--gray12);
    border-radius: ${getSize(91)};

    &:hover {
      transition: 0.3s ease-out;

      border-color: var(--purple18);
      color: var(--purple18);
    }
  `,
  purpleGradient: () => `
    position: relative;
    padding: ${getSize(12)} 0;
    font-weight: 600;
    font-size: ${getSize(14)};
    line-height: ${getSize(24)};
    color: var(--white);
    border-radius: ${getSize(91)};
    background: linear-gradient(89.04deg, #6B4EE6 13.35%, #846AF4 97.4%);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-out;

    &:hover:not(:disabled) {
      background: linear-gradient(89.04deg, #7F61FD 13.35%, #9B84FF 97.4%);
    }

    & path {
      fill: white;
    }
    & > svg {
      position: absolute;
      width: ${getSize(11)};
      height: ${getSize(11)};
      right: 20px;
      fill: var(--white);
    }

    &:disabled {
      background: var(--gray32);
    }
  `,
};

export default THEMES;
