import React from 'react';

function LocationIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="#6B4EE6"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 12l19-9-9 19-2-8-8-2z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default LocationIcon;
