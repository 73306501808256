import React from 'react';

function PeopleIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50094 9.86622C12.2152 9.86622 14.434 7.64741 14.434 4.93311C14.434 2.21881 12.2152 0 9.50094 0C6.78664 0 4.56787 2.21881 4.56787 4.93311C4.56787 7.64741 6.78668 9.86622 9.50094 9.86622Z"
        fill="#6C4FE7"
      />
      <path
        d="M17.9675 13.808C17.8383 13.4849 17.6659 13.1833 17.4721 12.9033C16.4811 11.4384 14.9517 10.469 13.2283 10.232C13.0129 10.2105 12.776 10.2536 12.6036 10.3828C11.6988 11.0506 10.6218 11.3953 9.50156 11.3953C8.38135 11.3953 7.30427 11.0506 6.39951 10.3828C6.22715 10.2536 5.99019 10.189 5.77479 10.232C4.05143 10.469 2.50043 11.4384 1.53105 12.9033C1.33717 13.1833 1.16482 13.5065 1.0356 13.808C0.970988 13.9373 0.992511 14.0881 1.05712 14.2173C1.22948 14.5189 1.44487 14.8205 1.63875 15.079C1.94032 15.4883 2.26346 15.8545 2.62969 16.1992C2.93127 16.5008 3.27593 16.7808 3.62064 17.0609C5.32243 18.3319 7.36893 18.9996 9.48003 18.9996C11.5911 18.9996 13.6376 18.3318 15.3394 17.0609C15.6841 16.8024 16.0288 16.5008 16.3304 16.1992C16.675 15.8545 17.0197 15.4883 17.3213 15.079C17.5367 14.799 17.7306 14.5189 17.9029 14.2173C18.0106 14.0881 18.0321 13.9373 17.9675 13.808Z"
        fill="#6C4FE7"
      />
    </svg>
  );
}

export default PeopleIcon;
