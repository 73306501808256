import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MaterialIndicatorsChangedVariables = Types.Exact<{ [key: string]: never; }>;


export type MaterialIndicatorsChanged = (
  { __typename: 'Subscription' }
  & { materialIndicatorsChanged: (
    { __typename: 'MaterialIndicatorsModel' }
    & Pick<Types.MaterialIndicatorsModel, 'worksheetsCount' | 'diaryCount' | 'recordingCount' | 'all'>
  ) }
);


export const MaterialIndicatorsChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"MaterialIndicatorsChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"materialIndicatorsChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"worksheetsCount"}},{"kind":"Field","name":{"kind":"Name","value":"diaryCount"}},{"kind":"Field","name":{"kind":"Name","value":"recordingCount"}},{"kind":"Field","name":{"kind":"Name","value":"all"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useMaterialIndicatorsChanged__
 *
 * To run a query within a React component, call `useMaterialIndicatorsChanged` and pass it any options that fit your needs.
 * When your component renders, `useMaterialIndicatorsChanged` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialIndicatorsChanged({
 *   variables: {
 *   },
 * });
 */
export function useMaterialIndicatorsChanged(baseOptions?: Apollo.SubscriptionHookOptions<MaterialIndicatorsChanged, MaterialIndicatorsChangedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MaterialIndicatorsChanged, MaterialIndicatorsChangedVariables>(MaterialIndicatorsChangedDocument, options);
      }
export type MaterialIndicatorsChangedHookResult = ReturnType<typeof useMaterialIndicatorsChanged>;
export type MaterialIndicatorsChangedSubscriptionResult = Apollo.SubscriptionResult<MaterialIndicatorsChanged>;