import React from 'react';

function EmptyNotesIcon({ ...rest }) {
  return (
    <svg
      width="172"
      height="127"
      viewBox="0 0 172 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_3826_4249)">
        <path
          d="M63.7523 78.2495L28.1469 85.761C27.1179 85.9768 26.0454 85.7754 25.1647 85.2008C24.284 84.6262 23.6669 83.7254 23.4489 82.6961L17.3391 53.6847C17.1234 52.6548 17.3247 51.5814 17.8988 50.6999C18.4729 49.8185 19.3728 49.2009 20.4013 48.9827L56.0067 41.4712C57.0357 41.2554 58.1082 41.4568 58.9889 42.0314C59.8696 42.606 60.4867 43.5068 60.7047 44.5361L66.8145 73.5475C67.0302 74.5774 66.8289 75.6508 66.2548 76.5322C65.6807 77.4137 64.7808 78.0313 63.7523 78.2495ZM20.4975 49.4396C19.5901 49.6321 18.796 50.177 18.2894 50.9548C17.7829 51.7326 17.6053 52.6797 17.7956 53.5884L23.9054 82.5998C24.0978 83.508 24.6422 84.3028 25.4193 84.8098C26.1964 85.3168 27.1427 85.4945 28.0506 85.3041L63.6561 77.7926C64.5635 77.6001 65.3576 77.0552 65.8642 76.2774C66.3707 75.4996 66.5483 74.5525 66.358 73.6438L60.2482 44.6324C60.0559 43.7242 59.5114 42.9294 58.7343 42.4224C57.9572 41.9154 57.0109 41.7377 56.103 41.9281L20.4975 49.4396Z"
          fill="#3F3D56"
        />
        <path
          d="M53.7945 51.038L35.8249 54.829C35.6676 54.8655 35.5024 54.8409 35.3626 54.7602C35.2227 54.6794 35.1188 54.5485 35.0716 54.394C35.0501 54.3134 35.0452 54.2293 35.0572 54.1467C35.0692 54.0642 35.0979 53.9849 35.1415 53.9138C35.1851 53.8428 35.2428 53.7813 35.3109 53.7333C35.3791 53.6852 35.4563 53.6515 35.5378 53.6343L53.8298 49.7754C54.544 50.1782 54.3183 50.9275 53.7943 51.0381L53.7945 51.038Z"
          fill="#CCCCCC"
        />
        <path
          d="M54.459 54.1923L36.4894 57.9833C36.3322 58.0198 36.1669 57.9952 36.0271 57.9145C35.8873 57.8337 35.7833 57.7029 35.7362 57.5483C35.7146 57.4677 35.7097 57.3836 35.7217 57.301C35.7338 57.2185 35.7624 57.1392 35.8061 57.0682C35.8497 56.9971 35.9073 56.9356 35.9755 56.8876C36.0436 56.8395 36.1208 56.8058 36.2024 56.7886L54.4943 52.9297C55.2085 53.3325 54.9828 54.0818 54.4588 54.1924L54.459 54.1923Z"
          fill="#CCCCCC"
        />
        <path
          d="M32.983 60.5998L27.4988 61.7567C27.3197 61.7943 27.133 61.7593 26.9797 61.6592C26.8264 61.5592 26.719 61.4024 26.6811 61.2232L25.317 54.7459C25.2794 54.5667 25.3144 54.3798 25.4144 54.2264C25.5143 54.073 25.6709 53.9655 25.85 53.9275L31.3341 52.7705C31.5132 52.733 31.6999 52.7681 31.8532 52.8681C32.0065 52.9681 32.1139 53.1249 32.1518 53.304L33.516 59.7813C33.5535 59.9606 33.5185 60.1474 33.4186 60.3009C33.3187 60.4543 33.162 60.5618 32.983 60.5998V60.5998Z"
          fill="#CCCCCC"
        />
        <path
          d="M55.7669 60.6631L28.342 66.4487C28.1847 66.4853 28.0195 66.4607 27.8797 66.3799C27.7398 66.2992 27.6358 66.1683 27.5887 66.0138C27.5672 65.9332 27.5623 65.8491 27.5743 65.7665C27.5863 65.684 27.615 65.6047 27.6586 65.5336C27.7022 65.4625 27.7599 65.4011 27.828 65.353C27.8961 65.305 27.9733 65.2713 28.0549 65.2541L55.8024 59.4004C56.5166 59.8032 56.2909 60.5525 55.7669 60.6631Z"
          fill="#CCCCCC"
        />
        <path
          d="M56.4317 63.8193L29.0068 69.605C28.8495 69.6415 28.6843 69.6169 28.5445 69.5362C28.4046 69.4555 28.3006 69.3246 28.2535 69.1701C28.232 69.0895 28.2271 69.0053 28.2391 68.9228C28.2511 68.8402 28.2798 68.761 28.3234 68.6899C28.367 68.6188 28.4247 68.5574 28.4928 68.5093C28.5609 68.4612 28.6381 68.4276 28.7197 68.4104L56.4672 62.5566C57.1814 62.9595 56.9556 63.7088 56.4317 63.8193Z"
          fill="#CCCCCC"
        />
        <path
          d="M57.0957 66.9717L29.6708 72.7573C29.5136 72.7939 29.3483 72.7693 29.2085 72.6885C29.0687 72.6078 28.9647 72.4769 28.9176 72.3224C28.896 72.2418 28.8911 72.1577 28.9031 72.0751C28.9152 71.9925 28.9438 71.9133 28.9875 71.8422C29.0311 71.7711 29.0887 71.7097 29.1569 71.6616C29.225 71.6136 29.3022 71.5799 29.3838 71.5627L57.1312 65.709C57.8455 66.1118 57.6197 66.8611 57.0957 66.9717H57.0957Z"
          fill="#CCCCCC"
        />
        <path
          d="M57.7603 70.1279L30.3354 75.9136C30.1781 75.9501 30.0129 75.9255 29.8731 75.8448C29.7332 75.764 29.6293 75.6332 29.5821 75.4786C29.5606 75.3981 29.5557 75.3139 29.5677 75.2314C29.5797 75.1488 29.6084 75.0696 29.652 74.9985C29.6956 74.9274 29.7533 74.8659 29.8214 74.8179C29.8896 74.7698 29.9668 74.7362 30.0483 74.7189L57.7958 68.8652C58.51 69.2681 58.2843 70.0174 57.7603 70.1279Z"
          fill="#CCCCCC"
        />
        <path
          d="M58.4244 73.2803L30.9995 79.0659C30.8422 79.1024 30.677 79.0778 30.5371 78.9971C30.3973 78.9164 30.2933 78.7855 30.2462 78.631C30.2247 78.5504 30.2197 78.4662 30.2317 78.3837C30.2438 78.3011 30.2725 78.2219 30.3161 78.1508C30.3597 78.0797 30.4173 78.0183 30.4855 77.9702C30.5536 77.9221 30.6308 77.8885 30.7124 77.8713L58.4599 72.0176C59.1741 72.4204 58.9483 73.1697 58.4244 73.2803Z"
          fill="#CCCCCC"
        />
        <path
          d="M62.799 58.8374C62.7367 58.9505 62.6834 59.0684 62.6396 59.1899L51.4122 61.9784L49.4591 59.9561L46.0244 62.5074L49.1345 66.4238C49.3857 66.7402 49.7327 66.9665 50.1234 67.0687C50.5141 67.1709 50.9274 67.1435 51.3012 66.9906L63.4839 62.0082C63.8844 62.3032 64.365 62.4692 64.862 62.4841C65.359 62.499 65.8488 62.3621 66.2662 62.0917C66.6835 61.8212 67.0087 61.43 67.1985 60.97C67.3883 60.51 67.4337 60.0031 67.3286 59.5167C67.2236 59.0303 66.973 58.5875 66.6103 58.247C66.2476 57.9066 65.79 57.6848 65.2983 57.611C64.8065 57.5373 64.304 57.6151 63.8576 57.8342C63.4111 58.0533 63.0419 58.4032 62.799 58.8374L62.799 58.8374Z"
          fill="#FFB8B8"
        />
        <path
          d="M24.4761 83.6531C24.8261 83.545 25.1482 83.3613 25.4196 83.115C25.691 82.8688 25.9052 82.566 26.047 82.228C26.1889 81.8899 26.2549 81.5248 26.2406 81.1585C26.2262 80.7921 26.1317 80.4334 25.9638 80.1075L33.6902 58.6138L28.3245 57.6621L22.4169 79.0889C21.8887 79.4075 21.4955 79.909 21.3119 80.4983C21.1284 81.0876 21.1672 81.7238 21.421 82.2864C21.6748 82.849 22.126 83.2989 22.6891 83.5508C23.2521 83.8027 23.888 83.8391 24.4761 83.6531Z"
          fill="#FFB8B8"
        />
        <path
          d="M32.709 62.4286L27.4111 60.3936C27.2604 60.3357 27.1239 60.246 27.011 60.1305C26.898 60.0151 26.8112 59.8766 26.7566 59.7245C26.7019 59.5725 26.6806 59.4104 26.6941 59.2494C26.7077 59.0884 26.7558 58.9322 26.8351 58.7914L29.6143 53.8595C29.9149 53.0896 30.5081 52.4703 31.2641 52.1375C32.0201 51.8046 32.8771 51.7853 33.6472 52.0837C34.4174 52.3821 35.038 52.974 35.3729 53.7296C35.7078 54.4852 35.7298 55.3428 35.434 56.1146L34.2081 61.6236C34.173 61.7813 34.1043 61.9296 34.0067 62.0583C33.9092 62.1871 33.785 62.2933 33.6427 62.3697C33.5005 62.4461 33.3434 62.4909 33.1823 62.5011C33.0211 62.5112 32.8597 62.4865 32.709 62.4286V62.4286Z"
          fill="#6C63FF"
        />
        <path
          d="M44.6667 124.006H47.5262L48.8868 112.967L44.6663 112.967L44.6667 124.006Z"
          fill="#FFB8B8"
        />
        <path
          d="M43.9373 123.072L49.5689 123.072H49.5692C50.0405 123.072 50.5072 123.165 50.9426 123.346C51.378 123.526 51.7737 123.791 52.1069 124.124C52.4402 124.458 52.7046 124.854 52.8849 125.29C53.0653 125.725 53.1581 126.193 53.1581 126.664V126.781L43.9374 126.781L43.9373 123.072Z"
          fill="#2F2E41"
        />
        <path
          d="M28.5722 124.006H31.4317L32.7923 112.967L28.5718 112.967L28.5722 124.006Z"
          fill="#FFB8B8"
        />
        <path
          d="M27.8425 123.072L33.4742 123.072H33.4744C33.9457 123.072 34.4124 123.165 34.8479 123.346C35.2833 123.526 35.679 123.791 36.0122 124.124C36.3455 124.458 36.6099 124.854 36.7902 125.29C36.9706 125.725 37.0634 126.193 37.0634 126.664V126.781L27.8427 126.781L27.8425 123.072Z"
          fill="#2F2E41"
        />
        <path
          d="M31.5699 119.562H27.951C27.809 119.562 27.6684 119.533 27.5378 119.477C27.4073 119.421 27.2894 119.339 27.1914 119.236C27.0934 119.134 27.0172 119.012 26.9676 118.879C26.9179 118.745 26.8958 118.604 26.9025 118.462L28.6215 81.498L28.7298 81.495L46.2051 81.0391L49.6247 118.024C49.6379 118.162 49.6233 118.302 49.582 118.435C49.5406 118.568 49.4733 118.692 49.3838 118.798C49.2944 118.905 49.1847 118.993 49.061 119.057C48.9373 119.121 48.8022 119.159 48.6635 119.17L44.5987 119.496C44.3434 119.516 44.0894 119.442 43.8846 119.288C43.6798 119.134 43.5383 118.911 43.4868 118.659L38.2875 92.3311C38.2733 92.2511 38.2309 92.1789 38.168 92.1275C38.1052 92.0761 38.026 92.049 37.9448 92.0511H37.9434C37.862 92.0493 37.7826 92.0769 37.7198 92.1288C37.657 92.1807 37.615 92.2535 37.6014 92.3338L32.5998 118.714C32.5524 118.953 32.4239 119.168 32.2362 119.322C32.0484 119.477 31.813 119.562 31.5699 119.562Z"
          fill="#2F2E41"
        />
        <path
          d="M38.199 48.5323C41.363 48.5323 43.928 45.9651 43.928 42.7984C43.928 39.6316 41.363 37.0645 38.199 37.0645C35.0349 37.0645 32.47 39.6316 32.47 42.7984C32.47 45.9651 35.0349 48.5323 38.199 48.5323Z"
          fill="#FFB8B8"
        />
        <path
          d="M36.2779 85.8298C33.5474 85.8298 30.7427 85.1114 28.4177 82.9875C28.294 82.8753 28.1985 82.7354 28.1389 82.5792C28.0793 82.4231 28.0574 82.2551 28.0749 82.0888C28.3238 79.8509 29.531 68.296 28.8511 60.5774C28.7185 59.1356 28.9224 57.6828 29.4468 56.3334C29.9713 54.9841 30.8019 53.7752 31.8731 52.8022C32.9255 51.8314 34.189 51.1187 35.5638 50.7202C36.9386 50.3218 38.387 50.2486 39.7949 50.5064H39.795C39.8663 50.5192 39.9376 50.5326 40.0091 50.5463C44.5957 51.4347 47.7825 55.6832 47.4219 60.4287C46.8957 67.3531 46.424 76.7191 47.1647 82.0159C47.1954 82.2326 47.1576 82.4534 47.0566 82.6475C46.9555 82.8417 46.7964 82.9993 46.6014 83.0983C44.9162 83.9506 40.694 85.8298 36.2779 85.8298Z"
          fill="#6C63FF"
        />
        <path
          d="M43.7536 62.842C43.6192 62.7525 43.5056 62.635 43.4207 62.4976C43.3358 62.3602 43.2815 62.206 43.2615 62.0456L42.5636 56.4451C42.342 55.6486 42.445 54.7967 42.8501 54.0761C43.2551 53.3556 43.9291 52.8251 44.7242 52.6012C45.5194 52.3772 46.3709 52.4779 47.0919 52.8813C47.813 53.2847 48.3448 53.9577 48.5709 54.753L50.8699 59.9266C50.9355 60.0743 50.9685 60.2343 50.9667 60.3959C50.965 60.5575 50.9284 60.7168 50.8596 60.863C50.7907 61.0092 50.6912 61.1388 50.5678 61.243C50.4444 61.3472 50.3 61.4236 50.1445 61.4669L44.6776 62.9895C44.5221 63.0329 44.359 63.0422 44.1995 63.0167C44.04 62.9913 43.8879 62.9317 43.7536 62.842Z"
          fill="#6C63FF"
        />
        <path
          d="M36.1992 48.451C35.6569 48.4611 35.118 48.3635 34.6137 48.1639C34.3918 48.0783 34.1614 48.0079 33.9399 47.9223C31.9837 47.1659 30.695 45.0807 30.6489 42.9822C30.6027 40.8838 31.6885 38.8471 33.3008 37.5048C34.9131 36.1626 37.0014 35.4734 39.0925 35.3141C41.3449 35.1424 43.8813 35.7141 45.1399 37.5916C45.4749 38.0913 45.7092 38.6977 45.5107 39.301C45.4513 39.4831 45.3451 39.6464 45.2028 39.7745C44.6407 40.275 44.0803 39.8987 43.5073 39.8654C42.7197 39.8197 42.0123 40.4578 41.758 41.2052C41.5038 41.9527 41.6157 42.7756 41.821 43.538C41.9774 43.9968 42.076 44.4733 42.1145 44.9565C42.1311 45.1988 42.0855 45.4413 41.9822 45.661C41.8789 45.8807 41.7212 46.0704 41.5241 46.2121C41.032 46.5099 40.3857 46.3375 39.8932 46.0403C39.4008 45.743 38.9753 45.3297 38.4434 45.1109C37.9115 44.8922 37.1968 44.9407 36.8872 45.4258C36.7888 45.6022 36.7222 45.7947 36.6905 45.9943C36.4129 47.2599 36.4767 47.1854 36.1992 48.451V48.451Z"
          fill="#2F2E41"
        />
        <path
          d="M115.552 40.6216H79.1643C78.113 40.6204 77.1051 40.2018 76.3617 39.4578C75.6183 38.7138 75.2002 37.705 75.199 36.6528V7.00391C75.2002 5.95169 75.6183 4.94291 76.3617 4.19888C77.1051 3.45486 78.113 3.03634 79.1643 3.03516H115.552C116.603 3.03634 117.611 3.45486 118.355 4.19888C119.098 4.94291 119.516 5.95169 119.517 7.00391V36.6528C119.516 37.705 119.098 38.7138 118.355 39.4578C117.611 40.2018 116.603 40.6204 115.552 40.6216ZM79.1643 3.50207C78.2367 3.50312 77.3474 3.8724 76.6914 4.52889C76.0355 5.18539 75.6665 6.07548 75.6655 7.00391V36.6528C75.6665 37.5812 76.0355 38.4713 76.6914 39.1278C77.3474 39.7843 78.2367 40.1536 79.1643 40.1546H115.552C116.48 40.1536 117.369 39.7843 118.025 39.1278C118.681 38.4713 119.05 37.5812 119.051 36.6528V7.00391C119.05 6.07548 118.681 5.18539 118.025 4.52889C117.369 3.8724 116.48 3.50312 115.552 3.50207H79.1643Z"
          fill="#3F3D56"
        />
        <path
          d="M111.416 11.9391H93.0519C92.8905 11.9424 92.7339 11.8842 92.6137 11.7764C92.4935 11.6685 92.4187 11.519 92.4045 11.3581C92.4 11.2748 92.4125 11.1914 92.4413 11.1131C92.47 11.0348 92.5144 10.9632 92.5718 10.9026C92.6291 10.8421 92.6982 10.7939 92.7747 10.7609C92.8513 10.7279 92.9338 10.7109 93.0172 10.7109H111.711C112.327 11.2526 111.952 11.9391 111.416 11.9391L111.416 11.9391Z"
          fill="#CCCCCC"
        />
        <path
          d="M111.416 15.1618H93.0519C92.8905 15.1651 92.7339 15.1069 92.6137 14.999C92.4935 14.8912 92.4187 14.7417 92.4045 14.5807C92.4 14.4974 92.4125 14.4141 92.4413 14.3358C92.47 14.2575 92.5144 14.1859 92.5718 14.1253C92.6291 14.0647 92.6982 14.0165 92.7747 13.9836C92.8513 13.9506 92.9338 13.9336 93.0172 13.9336H111.711C112.327 14.4752 111.952 15.1618 111.416 15.1618L111.416 15.1618Z"
          fill="#CCCCCC"
        />
        <path
          d="M89.082 16.9973H83.4773C83.2943 16.9971 83.1189 16.9243 82.9895 16.7948C82.8601 16.6653 82.7873 16.4897 82.7871 16.3065V9.68689C82.7873 9.50374 82.8601 9.32814 82.9895 9.19863C83.1189 9.06912 83.2943 8.99628 83.4773 8.99609H89.082C89.2649 8.99634 89.4404 9.0692 89.5697 9.1987C89.6991 9.32819 89.7719 9.50375 89.7722 9.68689V16.3065C89.772 16.4897 89.6992 16.6653 89.5698 16.7948C89.4404 16.9243 89.265 16.9971 89.082 16.9973Z"
          fill="#3F3D56"
        />
        <path
          d="M111.363 21.7634H83.3353C83.1739 21.7666 83.0173 21.7085 82.8971 21.6006C82.7769 21.4927 82.7022 21.3432 82.6879 21.1823C82.6834 21.099 82.6959 21.0156 82.7247 20.9373C82.7535 20.859 82.7979 20.7874 82.8552 20.7269C82.9126 20.6663 82.9816 20.6181 83.0582 20.5851C83.1348 20.5522 83.2173 20.5352 83.3006 20.5352H111.658C112.274 21.0768 111.898 21.7634 111.363 21.7634V21.7634Z"
          fill="#CCCCCC"
        />
        <path
          d="M111.363 24.988H83.3353C83.1739 24.9912 83.0173 24.9331 82.8971 24.8252C82.7769 24.7173 82.7022 24.5678 82.6879 24.4069C82.6834 24.3236 82.6959 24.2402 82.7247 24.1619C82.7535 24.0836 82.7979 24.012 82.8552 23.9515C82.9126 23.8909 82.9816 23.8427 83.0582 23.8097C83.1348 23.7768 83.2173 23.7598 83.3006 23.7598H111.658C112.274 24.3014 111.898 24.988 111.363 24.988V24.988Z"
          fill="#CCCCCC"
        />
        <path
          d="M111.363 28.2106H83.3353C83.1739 28.2139 83.0173 28.1557 82.8971 28.0479C82.7769 27.94 82.7022 27.7905 82.6879 27.6295C82.6834 27.5462 82.6959 27.4629 82.7247 27.3846C82.7535 27.3063 82.7979 27.2347 82.8552 27.1741C82.9126 27.1136 82.9816 27.0653 83.0582 27.0324C83.1348 26.9994 83.2173 26.9824 83.3006 26.9824H111.658C112.274 27.524 111.898 28.2106 111.363 28.2106V28.2106Z"
          fill="#CCCCCC"
        />
        <path
          d="M111.363 31.4372H83.3353C83.1739 31.4405 83.0173 31.3823 82.8971 31.2744C82.7769 31.1666 82.7022 31.017 82.6879 30.8561C82.6834 30.7728 82.6959 30.6895 82.7247 30.6112C82.7535 30.5329 82.7979 30.4612 82.8552 30.4007C82.9126 30.3401 82.9816 30.2919 83.0582 30.2589C83.1348 30.226 83.2173 30.209 83.3006 30.209H111.658C112.274 30.7506 111.898 31.4372 111.363 31.4372V31.4372Z"
          fill="#CCCCCC"
        />
        <path
          d="M111.363 34.6599H83.3353C83.1739 34.6631 83.0173 34.605 82.8971 34.4971C82.7769 34.3892 82.7022 34.2397 82.6879 34.0788C82.6834 33.9955 82.6959 33.9121 82.7247 33.8338C82.7535 33.7555 82.7979 33.6839 82.8552 33.6233C82.9126 33.5628 82.9816 33.5146 83.0582 33.4816C83.1348 33.4486 83.2173 33.4316 83.3006 33.4316H111.658C112.274 33.9733 111.898 34.6599 111.363 34.6599Z"
          fill="#CCCCCC"
        />
        <path
          d="M107.621 0C107.931 0.000354048 108.227 0.123449 108.446 0.342279C108.664 0.56111 108.787 0.857806 108.788 1.16728V4.90257C108.787 5.21205 108.664 5.50874 108.446 5.72757C108.227 5.9464 107.931 6.0695 107.621 6.06985H87.095C86.7858 6.0695 86.4893 5.9464 86.2707 5.72757C86.052 5.50874 85.9291 5.21205 85.9287 4.90257V1.16728C85.9291 0.857806 86.052 0.56111 86.2707 0.342279C86.4893 0.123449 86.7858 0.000354048 87.095 0"
          fill="#6C63FF"
        />
        <path
          d="M168.035 77.2739H131.647C130.595 77.2727 129.588 76.8542 128.844 76.1102C128.101 75.3661 127.683 74.3574 127.681 73.3051V43.6562C127.683 42.604 128.101 41.5953 128.844 40.8512C129.588 40.1072 130.595 39.6887 131.647 39.6875H168.035C169.086 39.6887 170.094 40.1072 170.837 40.8512C171.581 41.5953 171.999 42.604 172 43.6562V73.3051C171.999 74.3574 171.581 75.3661 170.837 76.1102C170.094 76.8542 169.086 77.2727 168.035 77.2739ZM131.647 40.1544C130.719 40.1555 129.83 40.5247 129.174 41.1812C128.518 41.8377 128.149 42.7278 128.148 43.6562V73.3051C128.149 74.2336 128.518 75.1237 129.174 75.7802C129.83 76.4366 130.719 76.8059 131.647 76.807H168.035C168.962 76.8059 169.852 76.4366 170.507 75.7802C171.163 75.1237 171.532 74.2336 171.533 73.3051V43.6562C171.532 42.7278 171.163 41.8377 170.507 41.1812C169.852 40.5247 168.962 40.1555 168.035 40.1544H131.647Z"
          fill="#3F3D56"
        />
        <path
          d="M163.899 48.5915H145.534C145.373 48.5948 145.216 48.5366 145.096 48.4287C144.976 48.3209 144.901 48.1713 144.887 48.0104C144.882 47.9271 144.895 47.8438 144.924 47.7655C144.952 47.6872 144.997 47.6155 145.054 47.555C145.112 47.4944 145.181 47.4462 145.257 47.4132C145.334 47.3803 145.416 47.3633 145.5 47.3633H164.193C164.809 47.9049 164.434 48.5915 163.899 48.5915H163.899Z"
          fill="#3F3D56"
        />
        <path
          d="M163.899 51.8141H145.534C145.373 51.8174 145.216 51.7592 145.096 51.6514C144.976 51.5435 144.901 51.394 144.887 51.2331C144.882 51.1498 144.895 51.0664 144.924 50.9881C144.952 50.9098 144.997 50.8382 145.054 50.7776C145.112 50.7171 145.181 50.6689 145.257 50.6359C145.334 50.6029 145.416 50.5859 145.5 50.5859H164.193C164.809 51.1276 164.434 51.8141 163.899 51.8141H163.899Z"
          fill="#3F3D56"
        />
        <path
          d="M141.564 53.6516H135.96C135.777 53.6514 135.601 53.5786 135.472 53.4491C135.342 53.3196 135.27 53.144 135.27 52.9608V46.3412C135.27 46.158 135.342 45.9824 135.472 45.8529C135.601 45.7234 135.777 45.6506 135.96 45.6504H141.564C141.747 45.6506 141.923 45.7235 142.052 45.853C142.182 45.9825 142.254 46.1581 142.255 46.3412V52.9608C142.254 53.144 142.182 53.3196 142.052 53.4491C141.923 53.5786 141.747 53.6514 141.564 53.6516Z"
          fill="#CCCCCC"
        />
        <path
          d="M163.724 71.6275H158.119C157.936 71.6273 157.76 71.5545 157.631 71.425C157.502 71.2955 157.429 71.1199 157.429 70.9367V67.1185C157.429 66.9354 157.502 66.7598 157.631 66.6303C157.76 66.5008 157.936 66.4279 158.119 66.4277H163.724C163.907 66.428 164.082 66.5008 164.211 66.6303C164.341 66.7598 164.414 66.9354 164.414 67.1185V70.9367C164.414 71.1199 164.341 71.2955 164.211 71.425C164.082 71.5545 163.907 71.6273 163.724 71.6275Z"
          fill="#3F3D56"
        />
        <path
          d="M163.845 58.4157H135.818C135.656 58.419 135.5 58.3608 135.38 58.2529C135.259 58.1451 135.185 57.9956 135.17 57.8346C135.166 57.7513 135.178 57.668 135.207 57.5897C135.236 57.5114 135.28 57.4398 135.338 57.3792C135.395 57.3186 135.464 57.2704 135.541 57.2375C135.617 57.2045 135.7 57.1875 135.783 57.1875H164.14C164.756 57.7291 164.381 58.4157 163.845 58.4157V58.4157Z"
          fill="#CCCCCC"
        />
        <path
          d="M163.845 61.6403H135.818C135.656 61.6436 135.5 61.5854 135.38 61.4775C135.259 61.3697 135.185 61.2202 135.17 61.0592C135.166 60.9759 135.178 60.8926 135.207 60.8143C135.236 60.736 135.28 60.6644 135.338 60.6038C135.395 60.5433 135.464 60.495 135.541 60.4621C135.617 60.4291 135.7 60.4121 135.783 60.4121H164.14C164.756 60.9537 164.381 61.6403 163.845 61.6403V61.6403Z"
          fill="#CCCCCC"
        />
        <path
          d="M163.845 64.8649H135.818C135.656 64.8682 135.5 64.81 135.38 64.7022C135.259 64.5943 135.185 64.4448 135.17 64.2838C135.166 64.2005 135.178 64.1172 135.207 64.0389C135.236 63.9606 135.28 63.889 135.338 63.8284C135.395 63.7679 135.464 63.7196 135.541 63.6867C135.617 63.6537 135.7 63.6367 135.783 63.6367H164.14C164.756 64.1783 164.381 64.8649 163.845 64.8649V64.8649Z"
          fill="#CCCCCC"
        />
        <path
          d="M149.15 68.0895H135.818C135.656 68.0928 135.5 68.0346 135.38 67.9268C135.259 67.8189 135.185 67.6694 135.17 67.5084C135.166 67.4251 135.178 67.3418 135.207 67.2635C135.236 67.1852 135.28 67.1136 135.338 67.053C135.395 66.9925 135.464 66.9443 135.541 66.9113C135.617 66.8783 135.7 66.8613 135.783 66.8613H149.445C150.061 67.4029 149.686 68.0895 149.15 68.0895V68.0895Z"
          fill="#CCCCCC"
        />
        <path
          d="M149.15 71.3122H135.818C135.656 71.3155 135.5 71.2573 135.38 71.1494C135.259 71.0416 135.185 70.8921 135.17 70.7311C135.166 70.6478 135.178 70.5645 135.207 70.4862C135.236 70.4079 135.28 70.3362 135.338 70.2757C135.395 70.2151 135.464 70.1669 135.541 70.1339C135.617 70.101 135.7 70.084 135.783 70.084H149.445C150.061 70.6256 149.686 71.3122 149.15 71.3122Z"
          fill="#CCCCCC"
        />
        <path
          d="M160.104 42.7222H139.577C139.268 42.7218 138.972 42.5987 138.753 42.3799C138.534 42.1611 138.411 41.8644 138.411 41.5549V37.8196C138.411 37.5102 138.534 37.2135 138.753 36.9946C138.972 36.7758 139.268 36.6527 139.577 36.6523H160.104C160.413 36.6527 160.71 36.7758 160.928 36.9946C161.147 37.2135 161.27 37.5102 161.27 37.8196V41.5549C161.27 41.8644 161.147 42.1611 160.928 42.3799C160.71 42.5987 160.413 42.7218 160.104 42.7222Z"
          fill="#6C63FF"
        />
        <path
          d="M89.1035 127H0.233255C0.171392 127 0.112063 126.976 0.0683187 126.932C0.0245749 126.888 0 126.829 0 126.767C0 126.705 0.0245749 126.645 0.0683187 126.602C0.112063 126.558 0.171392 126.533 0.233255 126.533H89.1035C89.1654 126.533 89.2247 126.558 89.2684 126.602C89.3122 126.645 89.3368 126.705 89.3368 126.767C89.3368 126.829 89.3122 126.888 89.2684 126.932C89.2247 126.976 89.1654 127 89.1035 127Z"
          fill="#3F3D56"
        />
        <path
          d="M22.5707 6.95501C23.4038 6.95501 24.0791 6.27908 24.0791 5.44528C24.0791 4.61148 23.4038 3.93555 22.5707 3.93555C21.7376 3.93555 21.0623 4.61148 21.0623 5.44528C21.0623 6.27908 21.7376 6.95501 22.5707 6.95501Z"
          fill="#F2F2F2"
        />
        <path
          d="M22.5707 12.9726C23.4038 12.9726 24.0791 12.2967 24.0791 11.4629C24.0791 10.6291 23.4038 9.95312 22.5707 9.95312C21.7376 9.95312 21.0623 10.6291 21.0623 11.4629C21.0623 12.2967 21.7376 12.9726 22.5707 12.9726Z"
          fill="#F2F2F2"
        />
        <path
          d="M22.5707 18.9882C23.4038 18.9882 24.0791 18.3123 24.0791 17.4785C24.0791 16.6447 23.4038 15.9688 22.5707 15.9688C21.7376 15.9688 21.0623 16.6447 21.0623 17.4785C21.0623 18.3123 21.7376 18.9882 22.5707 18.9882Z"
          fill="#F2F2F2"
        />
        <path
          d="M22.5707 25.0058C23.4038 25.0058 24.0791 24.3299 24.0791 23.4961C24.0791 22.6623 23.4038 21.9863 22.5707 21.9863C21.7376 21.9863 21.0623 22.6623 21.0623 23.4961C21.0623 24.3299 21.7376 25.0058 22.5707 25.0058Z"
          fill="#F2F2F2"
        />
        <path
          d="M28.8685 6.95501C29.7016 6.95501 30.377 6.27908 30.377 5.44528C30.377 4.61148 29.7016 3.93555 28.8685 3.93555C28.0355 3.93555 27.3601 4.61148 27.3601 5.44528C27.3601 6.27908 28.0355 6.95501 28.8685 6.95501Z"
          fill="#F2F2F2"
        />
        <path
          d="M28.8685 12.9726C29.7016 12.9726 30.377 12.2967 30.377 11.4629C30.377 10.6291 29.7016 9.95312 28.8685 9.95312C28.0355 9.95312 27.3601 10.6291 27.3601 11.4629C27.3601 12.2967 28.0355 12.9726 28.8685 12.9726Z"
          fill="#F2F2F2"
        />
        <path
          d="M28.8685 18.9882C29.7016 18.9882 30.377 18.3123 30.377 17.4785C30.377 16.6447 29.7016 15.9688 28.8685 15.9688C28.0355 15.9688 27.3601 16.6447 27.3601 17.4785C27.3601 18.3123 28.0355 18.9882 28.8685 18.9882Z"
          fill="#F2F2F2"
        />
        <path
          d="M28.8685 25.0058C29.7016 25.0058 30.377 24.3299 30.377 23.4961C30.377 22.6623 29.7016 21.9863 28.8685 21.9863C28.0355 21.9863 27.3601 22.6623 27.3601 23.4961C27.3601 24.3299 28.0355 25.0058 28.8685 25.0058Z"
          fill="#F2F2F2"
        />
        <path
          d="M35.1664 6.95501C35.9995 6.95501 36.6748 6.27908 36.6748 5.44528C36.6748 4.61148 35.9995 3.93555 35.1664 3.93555C34.3333 3.93555 33.658 4.61148 33.658 5.44528C33.658 6.27908 34.3333 6.95501 35.1664 6.95501Z"
          fill="#F2F2F2"
        />
        <path
          d="M35.1664 12.9726C35.9995 12.9726 36.6748 12.2967 36.6748 11.4629C36.6748 10.6291 35.9995 9.95312 35.1664 9.95312C34.3333 9.95312 33.658 10.6291 33.658 11.4629C33.658 12.2967 34.3333 12.9726 35.1664 12.9726Z"
          fill="#F2F2F2"
        />
        <path
          d="M35.1664 18.9882C35.9995 18.9882 36.6748 18.3123 36.6748 17.4785C36.6748 16.6447 35.9995 15.9688 35.1664 15.9688C34.3333 15.9688 33.658 16.6447 33.658 17.4785C33.658 18.3123 34.3333 18.9882 35.1664 18.9882Z"
          fill="#F2F2F2"
        />
        <path
          d="M35.1664 25.0058C35.9995 25.0058 36.6748 24.3299 36.6748 23.4961C36.6748 22.6623 35.9995 21.9863 35.1664 21.9863C34.3333 21.9863 33.658 22.6623 33.658 23.4961C33.658 24.3299 34.3333 25.0058 35.1664 25.0058Z"
          fill="#F2F2F2"
        />
        <path
          d="M41.4642 6.95501C42.2973 6.95501 42.9727 6.27908 42.9727 5.44528C42.9727 4.61148 42.2973 3.93555 41.4642 3.93555C40.6312 3.93555 39.9558 4.61148 39.9558 5.44528C39.9558 6.27908 40.6312 6.95501 41.4642 6.95501Z"
          fill="#F2F2F2"
        />
        <path
          d="M41.4642 12.9726C42.2973 12.9726 42.9727 12.2967 42.9727 11.4629C42.9727 10.6291 42.2973 9.95312 41.4642 9.95312C40.6312 9.95312 39.9558 10.6291 39.9558 11.4629C39.9558 12.2967 40.6312 12.9726 41.4642 12.9726Z"
          fill="#F2F2F2"
        />
        <path
          d="M41.4642 18.9882C42.2973 18.9882 42.9727 18.3123 42.9727 17.4785C42.9727 16.6447 42.2973 15.9688 41.4642 15.9688C40.6312 15.9688 39.9558 16.6447 39.9558 17.4785C39.9558 18.3123 40.6312 18.9882 41.4642 18.9882Z"
          fill="#F2F2F2"
        />
        <path
          d="M41.4642 25.0058C42.2973 25.0058 42.9727 24.3299 42.9727 23.4961C42.9727 22.6623 42.2973 21.9863 41.4642 21.9863C40.6312 21.9863 39.9558 22.6623 39.9558 23.4961C39.9558 24.3299 40.6312 25.0058 41.4642 25.0058Z"
          fill="#F2F2F2"
        />
        <path
          d="M47.7623 6.95501C48.5954 6.95501 49.2708 6.27908 49.2708 5.44528C49.2708 4.61148 48.5954 3.93555 47.7623 3.93555C46.9293 3.93555 46.2539 4.61148 46.2539 5.44528C46.2539 6.27908 46.9293 6.95501 47.7623 6.95501Z"
          fill="#F2F2F2"
        />
        <path
          d="M47.7623 12.9726C48.5954 12.9726 49.2708 12.2967 49.2708 11.4629C49.2708 10.6291 48.5954 9.95312 47.7623 9.95312C46.9293 9.95312 46.2539 10.6291 46.2539 11.4629C46.2539 12.2967 46.9293 12.9726 47.7623 12.9726Z"
          fill="#F2F2F2"
        />
        <path
          d="M47.7623 18.9882C48.5954 18.9882 49.2708 18.3123 49.2708 17.4785C49.2708 16.6447 48.5954 15.9688 47.7623 15.9688C46.9293 15.9688 46.2539 16.6447 46.2539 17.4785C46.2539 18.3123 46.9293 18.9882 47.7623 18.9882Z"
          fill="#F2F2F2"
        />
        <path
          d="M47.7623 25.0058C48.5954 25.0058 49.2708 24.3299 49.2708 23.4961C49.2708 22.6623 48.5954 21.9863 47.7623 21.9863C46.9293 21.9863 46.2539 22.6623 46.2539 23.4961C46.2539 24.3299 46.9293 25.0058 47.7623 25.0058Z"
          fill="#F2F2F2"
        />
        <path
          d="M54.0602 6.95501C54.8933 6.95501 55.5686 6.27908 55.5686 5.44528C55.5686 4.61148 54.8933 3.93555 54.0602 3.93555C53.2271 3.93555 52.5518 4.61148 52.5518 5.44528C52.5518 6.27908 53.2271 6.95501 54.0602 6.95501Z"
          fill="#F2F2F2"
        />
        <path
          d="M54.0602 12.9726C54.8933 12.9726 55.5686 12.2967 55.5686 11.4629C55.5686 10.6291 54.8933 9.95312 54.0602 9.95312C53.2271 9.95312 52.5518 10.6291 52.5518 11.4629C52.5518 12.2967 53.2271 12.9726 54.0602 12.9726Z"
          fill="#F2F2F2"
        />
        <path
          d="M54.0602 18.9882C54.8933 18.9882 55.5686 18.3123 55.5686 17.4785C55.5686 16.6447 54.8933 15.9688 54.0602 15.9688C53.2271 15.9688 52.5518 16.6447 52.5518 17.4785C52.5518 18.3123 53.2271 18.9882 54.0602 18.9882Z"
          fill="#F2F2F2"
        />
        <path
          d="M54.0602 25.0058C54.8933 25.0058 55.5686 24.3299 55.5686 23.4961C55.5686 22.6623 54.8933 21.9863 54.0602 21.9863C53.2271 21.9863 52.5518 22.6623 52.5518 23.4961C52.5518 24.3299 53.2271 25.0058 54.0602 25.0058Z"
          fill="#F2F2F2"
        />
        <path
          d="M140.365 90.5312C141.198 90.5312 141.873 89.8553 141.873 89.0215C141.873 88.1876 141.198 87.5117 140.365 87.5117C139.532 87.5117 138.856 88.1876 138.856 89.0215C138.856 89.8553 139.532 90.5312 140.365 90.5312Z"
          fill="#F2F2F2"
        />
        <path
          d="M140.365 96.5488C141.198 96.5488 141.873 95.8728 141.873 95.039C141.873 94.2052 141.198 93.5293 140.365 93.5293C139.532 93.5293 138.856 94.2052 138.856 95.039C138.856 95.8728 139.532 96.5488 140.365 96.5488Z"
          fill="#F2F2F2"
        />
        <path
          d="M140.365 102.566C141.198 102.566 141.873 101.89 141.873 101.057C141.873 100.223 141.198 99.5469 140.365 99.5469C139.532 99.5469 138.856 100.223 138.856 101.057C138.856 101.89 139.532 102.566 140.365 102.566Z"
          fill="#F2F2F2"
        />
        <path
          d="M146.662 90.5312C147.496 90.5312 148.171 89.8553 148.171 89.0215C148.171 88.1876 147.496 87.5117 146.662 87.5117C145.829 87.5117 145.154 88.1876 145.154 89.0215C145.154 89.8553 145.829 90.5312 146.662 90.5312Z"
          fill="#F2F2F2"
        />
        <path
          d="M146.662 96.5488C147.496 96.5488 148.171 95.8728 148.171 95.039C148.171 94.2052 147.496 93.5293 146.662 93.5293C145.829 93.5293 145.154 94.2052 145.154 95.039C145.154 95.8728 145.829 96.5488 146.662 96.5488Z"
          fill="#F2F2F2"
        />
        <path
          d="M146.662 102.566C147.496 102.566 148.171 101.89 148.171 101.057C148.171 100.223 147.496 99.5469 146.662 99.5469C145.829 99.5469 145.154 100.223 145.154 101.057C145.154 101.89 145.829 102.566 146.662 102.566Z"
          fill="#F2F2F2"
        />
        <path
          d="M152.96 90.5312C153.793 90.5312 154.469 89.8553 154.469 89.0215C154.469 88.1876 153.793 87.5117 152.96 87.5117C152.127 87.5117 151.452 88.1876 151.452 89.0215C151.452 89.8553 152.127 90.5312 152.96 90.5312Z"
          fill="#F2F2F2"
        />
        <path
          d="M152.96 96.5488C153.793 96.5488 154.469 95.8728 154.469 95.039C154.469 94.2052 153.793 93.5293 152.96 93.5293C152.127 93.5293 151.452 94.2052 151.452 95.039C151.452 95.8728 152.127 96.5488 152.96 96.5488Z"
          fill="#F2F2F2"
        />
        <path
          d="M152.96 102.566C153.793 102.566 154.469 101.89 154.469 101.057C154.469 100.223 153.793 99.5469 152.96 99.5469C152.127 99.5469 151.452 100.223 151.452 101.057C151.452 101.89 152.127 102.566 152.96 102.566Z"
          fill="#F2F2F2"
        />
        <path
          d="M159.258 90.5312C160.091 90.5312 160.767 89.8553 160.767 89.0215C160.767 88.1876 160.091 87.5117 159.258 87.5117C158.425 87.5117 157.75 88.1876 157.75 89.0215C157.75 89.8553 158.425 90.5312 159.258 90.5312Z"
          fill="#F2F2F2"
        />
        <path
          d="M159.258 96.5488C160.091 96.5488 160.767 95.8728 160.767 95.039C160.767 94.2052 160.091 93.5293 159.258 93.5293C158.425 93.5293 157.75 94.2052 157.75 95.039C157.75 95.8728 158.425 96.5488 159.258 96.5488Z"
          fill="#F2F2F2"
        />
        <path
          d="M159.258 102.566C160.091 102.566 160.767 101.89 160.767 101.057C160.767 100.223 160.091 99.5469 159.258 99.5469C158.425 99.5469 157.75 100.223 157.75 101.057C157.75 101.89 158.425 102.566 159.258 102.566Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_3826_4249">
          <rect width="172" height="127" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmptyNotesIcon;
