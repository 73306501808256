import React from 'react';

function ContactIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6611 13.5289C12.9292 12.7971 11.7384 12.7971 11.0066 13.5289L10.3183 14.2173C10.3159 14.2196 10.0532 14.4495 9.52613 14.4495C8.91007 14.4495 7.86114 14.1349 6.36252 12.6363C4.91822 11.192 4.58049 10.1675 4.5515 9.56238C4.52441 8.99609 4.76088 8.70556 4.78841 8.67372L5.46989 7.99225C5.82443 7.63774 6.01966 7.16638 6.01966 6.66499C6.01966 6.16365 5.82439 5.69228 5.46989 5.33778L4.17518 4.04303C3.44338 3.3112 2.25258 3.31116 1.52074 4.04303L0.832365 4.73141C0.818895 4.74488 0.806129 4.75902 0.794106 4.77379C0.759854 4.81591 -0.0442324 5.82599 0.0011153 7.63255C0.026795 8.65539 0.317176 9.73605 0.864168 10.8445C1.52991 12.1936 2.58307 13.5932 3.99438 15.0045C5.40569 16.4158 6.80527 17.4689 8.15438 18.1347C9.26284 18.6817 10.3435 18.9721 11.3663 18.9977C11.4158 18.999 11.4647 18.9996 11.513 18.9996C13.2275 18.9996 14.1841 18.2381 14.2251 18.2047C14.2398 18.1927 14.254 18.18 14.2675 18.1665L14.9558 17.4781C15.3104 17.1236 15.5056 16.6523 15.5056 16.1509C15.5056 15.6495 15.3103 15.1782 14.9558 14.8237L13.6611 13.5289Z"
        fill="#6C4FE7"
      />
      <path
        d="M14.1203 0C11.43 0 9.24121 2.18875 9.24121 4.8791C9.24121 6.82163 10.3937 8.56774 12.1474 9.34258V10.6179C12.1474 10.8173 12.254 11.0014 12.4269 11.1007C12.5127 11.15 12.6084 11.1746 12.704 11.1746C12.801 11.1746 12.898 11.1492 12.9847 11.0986L15.7718 9.4712C17.7049 8.77626 18.9995 6.93804 18.9995 4.8791C18.9995 2.18879 16.8107 0 14.1203 0ZM14.677 5.43574V6.08438C14.677 6.39179 14.4277 6.64102 14.1203 6.64102C13.8129 6.64102 13.5637 6.39179 13.5637 6.08438V5.43574H12.915C12.6076 5.43574 12.3584 5.18652 12.3584 4.8791C12.3584 4.57169 12.6076 4.32246 12.915 4.32246H13.5637V3.67383C13.5637 3.36641 13.8129 3.11719 14.1203 3.11719C14.4277 3.11719 14.677 3.36641 14.677 3.67383V4.32246H15.3256C15.633 4.32246 15.8823 4.57169 15.8823 4.8791C15.8823 5.18652 15.633 5.43574 15.3256 5.43574H14.677Z"
        fill="#6C4FE7"
      />
    </svg>
  );
}

export default ContactIcon;
