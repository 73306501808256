import { getSize } from 'lib/utils';
import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { AlertIcon } from 'ui';

export interface AlertMessageProps {
  className?: string;
  text: string;
  rootCSS?: CSSProp;
  textCSS?: CSSProp;
  Icon?: (props: any) => JSX.Element;
  stroke?: string;
  isError?: boolean;
}

function AlertMessage({
  className,
  text,
  rootCSS,
  textCSS,
  stroke,
  Icon,
  isError,
}: AlertMessageProps) {
  return (
    <Wrapper className={className} $CSS={rootCSS} $isError={isError}>
      <IconWrapper>
        {Icon ? (
          <Icon />
        ) : (
          <AlertIconStylized
            stroke={isError ? 'var(--red)' : stroke || 'var(--purple4)'}
          />
        )}
      </IconWrapper>
      <Text $CSS={textCSS} $isError={isError}>
        {text}
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $CSS?: CSSProp; $isError?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${getSize(11)} ${getSize(10)};
  border: 1px solid var(--purple5);
  border-radius: ${getSize(8)};

  ${({ $isError }) =>
    $isError
      ? `
      background: var(--white);
      border: 1px solid var(--red);
      `
      : ''}

  ${({ $CSS }) => $CSS}
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${getSize(42)};
  height: ${getSize(42)};
  margin: 0 ${getSize(12)} 0 0;
  background-color: var(--white);
  border-radius: ${getSize(8)};
  flex-shrink: 0;
`;

const AlertIconStylized = styled(AlertIcon)`
  width: ${getSize(22)};
  height: ${getSize(22)};
`;

const Text = styled.p<{ $CSS?: CSSProp; $isError?: boolean }>`
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(${({ $isError }) => ($isError ? '--red' : '--purple16')});

  ${({ $CSS }) => $CSS}
`;

export default AlertMessage;
