class SignUpDataService {
  private signUpDataName = '@mental-health-sign-up-data';

  setSignUpData = (role?: string | null) => {
    if (role) {
      localStorage.setItem(this.signUpDataName, role);
      return role;
    }
  };

  getSignUpData = () => {
    return localStorage.getItem(this.signUpDataName);
  };

  removeSignUpData = () => {
    localStorage.removeItem(this.signUpDataName);
  };
}

export default new SignUpDataService();
