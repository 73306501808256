import { API_DATE_FORMAT, ISO_DATE_FORMAT } from 'lib/constants/date';
import { TimeUtil } from './time-util';

export function getDateStringFromExpDate(expiryDate: string) {
  return TimeUtil.parse(
    new Date(
      Number(`20${expiryDate.slice(3)}`),
      Number(expiryDate.slice(0, 2)) - 1,
    ).toISOString(),
    ISO_DATE_FORMAT,
  ).format(API_DATE_FORMAT);
}
export function getYears(from: number) {
  const to = TimeUtil.now().year();
  const years = [];

  for (let i = from; i <= to; i += 1) {
    years.push(i.toString());
  }

  return years;
}

export default getYears;
