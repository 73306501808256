import React from 'react';

function VideoCameraIcon({ ...rest }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_20_28)">
        <path
          d="M22.1861 6.46311C22.028 6.48947 21.8698 6.56854 21.738 6.64761L17.6262 9.0198V15.1348L21.7644 17.507C22.5287 17.955 23.4776 17.6915 23.9257 16.9271C24.0575 16.6899 24.1366 16.4263 24.1366 16.1364V7.99185C24.1366 7.01662 23.214 6.22589 22.1861 6.46311Z"
          fill="white"
        />
        <rect
          x="19.0918"
          y="2.70703"
          width="2"
          height="26"
          transform="rotate(45 19.0918 2.70703)"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0095 5.3752C14.6169 5.14525 14.1595 5.01427 13.6723 5.01427L2.68113 5.01427C1.23146 5.01427 0.0453664 6.20037 0.045367 7.65004L0.0453667 16.5326C0.0453667 17.5577 0.638443 18.451 1.49841 18.8863L15.0095 5.3752ZM2.63106 19.1679C2.64771 19.1682 2.66441 19.1683 2.68113 19.1683L5.45905 19.1683L16.308 8.31934L16.308 7.65004C16.308 7.03793 16.1041 6.48021 15.7619 6.03704L2.63106 19.1679ZM13.6723 19.1683L6.87326 19.1683L16.308 9.73356L16.308 16.5326C16.308 17.9822 15.122 19.1683 13.6723 19.1683Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_20_28">
          <rect
            width="24.0909"
            height="24.0909"
            fill="white"
            transform="translate(0.0454102 0.0454102)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default VideoCameraIcon;
