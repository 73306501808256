import React, { FC, useEffect } from 'react';
import styled, { CSSProp } from 'styled-components';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { getSize } from 'lib/utils';
import { ShadowWrapper } from 'ui';
import { logout } from 'lib/auth';

/** Constants */
import { Role, B2BRole } from '__generated__/types';
import { PATIENT_ROUTES } from 'lib/routes';

/** Custom components */
import RenderGuard from 'components/render-guard/render-guard';

const BUTTON_STYLE: CSSProp = {
  textAlign: 'left',
  fontWeight: 400,
  fontSize: getSize(14),
  lineHeight: getSize(24),
  color: 'var(--black3)',
  transition: '0.3s ease-out',
  '&:hover': {
    color: 'var(--purple)',
  },
};

interface DropdownMenuProps {
  onCloseDropdown: () => void;
  settingsRoute: string;
  userRole?: string;
}

const DropdownMenu: FC<DropdownMenuProps> = ({
  onCloseDropdown,
  settingsRoute,
  userRole,
}) => {
  /** Init */
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    window.addEventListener('click', onCloseDropdown);
    return () => window.removeEventListener('click', onCloseDropdown);
  }, [onCloseDropdown]);

  /**
   * @description
   */
  const renderWellbeingLink = () => {
    const route = currentPath.includes(PATIENT_ROUTES.COMPANY_DASHBOARD)
      ? PATIENT_ROUTES.DASHBOARD
      : PATIENT_ROUTES.COMPANY_DASHBOARD;
    const linkLabel = currentPath.includes(PATIENT_ROUTES.COMPANY_DASHBOARD)
      ? 'My Health Space'
      : 'Company Wellbeing';

    return (
      <>
        <Link onClick={onCloseDropdown} to={route}>
          {linkLabel}
        </Link>
        <Line />
      </>
    );
  };

  return (
    <Dropdown onClick={(e) => e.stopPropagation()}>
      <RenderGuard
        allowedRoles={[Role.Patient]}
        allowedB2bRoles={[B2BRole.Manager]}
      >
        {renderWellbeingLink()}
      </RenderGuard>
      <Link onClick={onCloseDropdown} to={settingsRoute}>
        My account
      </Link>
      <Line />
      <LogoutButton onClick={logout}>Log Out</LogoutButton>
    </Dropdown>
  );
};

const Dropdown = styled(ShadowWrapper)`
  position: absolute;
  top: calc(100% + ${getSize(10)});
  right: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSize(24)};
  padding: ${getSize(24)};
  min-width: ${getSize(230)};
  opacity: 0;
  transform: translateY(${getSize(-20)});
  animation: showDropdown 0.3s ease-out forwards;
  z-index: 1000;

  @keyframes showDropdown {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Link = styled(RouterLink)`
  ${() => BUTTON_STYLE}
`;

const Line = styled.div`
  height: 1px;
  background: var(--gray4);
`;

const LogoutButton = styled.button`
  ${() => BUTTON_STYLE}
`;

export default DropdownMenu;
