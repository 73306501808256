import { MutationUpdaterFn } from '@apollo/client';
import {
  TherapistNote,
  TherapistNoteAdd,
} from 'common/mutation/__generated__/therapist-note-add';
import { TherapistNoteRemove } from 'common/mutation/__generated__/therapist-note-remove';
import { TherapistNoteRemoveAll } from 'common/mutation/__generated__/therapist-note-remove-all';
import { NotesFilter, NoteType } from '../../../__generated__/types';
import { parseStoreNameArgs } from './parse-store-name-args';

export const therapistNoteAddToCache: MutationUpdaterFn<TherapistNoteAdd> = (
  cache,
  { data },
) => {
  if (data?.therapistNoteAdd.note) {
    const note = data?.therapistNoteAdd.note;
    cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        therapistGetPatientNotes(data, { storeFieldName }) {
          const filter = parseStoreNameArgs(
            storeFieldName,
            'therapistGetPatientNotes',
          ).filter as NotesFilter;
          const newNoteRef = cache.writeFragment({
            data: note,
            fragment: TherapistNote,
          });
          if (filter.patientId === note.createdForPatientId) {
            if (
              filter.sessionMetaId &&
              filter.sessionMetaId === note.sessionMetaId
            ) {
              if (
                filter.type === NoteType.All ||
                filter.type === NoteType.OnlyMyNotes
              ) {
                return { ...data, nodes: [newNoteRef, ...data.nodes] };
              }
            } else if (!filter.sessionMetaId) {
              if (
                filter.type === NoteType.All ||
                filter.type === NoteType.OnlyMyNotes
              ) {
                return { ...data, nodes: [newNoteRef, ...data.nodes] };
              }
            }
          }
          return data;
        },
      },
    });
  }
};

export const therapistNoteRemoveFromCache: (
  id: string,
) => MutationUpdaterFn<TherapistNoteRemove> =
  (id: string) =>
  (cache, { data }) => {
    if (data?.therapistNoteRemove) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          therapistGetPatientNotes(data, { readField }) {
            return {
              ...data,
              nodes: data.nodes.filter(
                (noteRef: any) => readField('id', noteRef) !== id,
              ),
            };
          },
        },
      });
    }
  };

export const therapistNoteRemoveAllFromCache: (params: {
  patientId: string;
  sessionMetaId?: string;
}) => MutationUpdaterFn<TherapistNoteRemoveAll> =
  ({ patientId, sessionMetaId }) =>
  (cache, { data }) => {
    if (data?.therapistNoteRemoveAll) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          therapistGetPatientNotes(data, { readField }) {
            return {
              ...data,
              nodes: data.nodes.filter((noteRef: any) => {
                if (sessionMetaId) {
                  return (
                    readField('createdForPatientId', noteRef) !== patientId &&
                    readField('sessionMetaId', noteRef) !== sessionMetaId
                  );
                } else {
                  return (
                    readField('createdForPatientId', noteRef) !== patientId
                  );
                }
              }),
            };
          },
        },
      });
    }
  };
