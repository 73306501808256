import React, { useMemo } from 'react';
import styled from 'styled-components';

import { getSize } from 'lib/utils';

import { SectionWithTitle } from 'features/common';
import { GoalsEmptyBlock } from './components';
import GoalItem, { Goal } from './components/goal-item/goal-item';
import { Button, Link } from 'ui';
import { PATIENT_DASHBOARD_ROUTES } from 'lib/routes';
import SessionStatistic from 'features/patient/dashboard/common/sessions-statistic/sessions-statistic';

export interface GoalsAndJourneyProps {
  goals: Goal[];
  className?: string;
  onAddClick: () => void;
  onGoalClick: (id: string) => void;
  attendedSessionsCount?: number;
  firstSessionDateUtc?: string;
}

const TITLE_CSS = { margin: `0 0 ${getSize(38)}` };
const MAX_COUNT = 3;

function GoalsAndJourney({
  goals,
  className,
  onAddClick,
  onGoalClick,
  attendedSessionsCount,
  firstSessionDateUtc,
}: GoalsAndJourneyProps) {
  const currentGoals = useMemo(() => goals.slice(0, MAX_COUNT), [goals]);
  const isGoalsEmpty = currentGoals.length === 0;
  const isActiveGoalsMaxCount = currentGoals.length === MAX_COUNT;

  return (
    <Wrapper
      className={className}
      title="My goals & journey"
      titleCSS={TITLE_CSS}
    >
      <SessionStatistic
        isSmallScreen
        isGoalsEmpty={isGoalsEmpty}
        attendedSessionsCount={attendedSessionsCount}
        firstSessionDateUtc={firstSessionDateUtc}
      />

      {isGoalsEmpty ? (
        <GoalsEmptyBlock onAddClick={onAddClick} />
      ) : (
        <>
          <GoalList>
            {currentGoals.map((goal) => {
              const goalId = goal.id;

              return (
                <GoalItem
                  key={goalId}
                  goal={goal}
                  onClick={() => onGoalClick(goalId)}
                />
              );
            })}
          </GoalList>

          <>
            {!isActiveGoalsMaxCount && (
              <AddButton theme="secondary" onClick={onAddClick}>
                Add
              </AddButton>
            )}
            <ShowAll to={PATIENT_DASHBOARD_ROUTES.GOALS}>Show all</ShowAll>
          </>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(SectionWithTitle)`
  max-height: ${getSize(455)};
  padding-bottom: ${getSize(17)};
`;

const GoalList = styled.ul`
  display: grid;
  grid-template: 1fr;
  grid-gap: ${getSize(20)};
  margin: 0 0 ${getSize(16)};
`;

const AddButton = styled(Button)`
  font-weight: 600;
  margin: auto 0 ${getSize(16)};
`;

const ShowAll = styled(Link)`
  width: 100%;
  text-align: center;
`;

export default GoalsAndJourney;
