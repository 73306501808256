import React from 'react';

function StartFeedbackIcon({ ...rest }) {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M39.4765 13.7985C39.3391 13.3755 38.9888 13.0564 38.5549 12.9588L28.0676 10.6004L22.584 1.35531C22.3571 0.972766 21.9453 0.738281 21.5005 0.738281C21.0557 0.738281 20.644 0.97285 20.417 1.35531L14.9334 10.6005L4.44623 12.9588C4.01229 13.0564 3.66199 13.3756 3.52459 13.7986C3.38711 14.2216 3.48293 14.6857 3.77663 15.0197L10.8747 23.0919L9.87683 33.7945C9.8356 34.2374 10.0309 34.6692 10.3907 34.9307C10.7504 35.192 11.2214 35.2445 11.63 35.0683L21.5005 30.812L31.371 35.0683C31.5311 35.1374 31.7008 35.1713 31.8696 35.1713C32.1316 35.1713 32.3915 35.0897 32.6102 34.9307C32.9701 34.6692 33.1654 34.2375 33.1241 33.7945L32.1262 23.0919L39.2244 15.0197C39.5182 14.6857 39.614 14.2216 39.4765 13.7985V13.7985ZM32.1789 17.309L28.2121 21.82L28.7698 27.8012C28.811 28.2441 28.6158 28.6759 28.2559 28.9374C28.0372 29.0963 27.7773 29.178 27.5153 29.178C27.3464 29.178 27.1768 29.1441 27.0167 29.075L21.5005 26.6963L15.9844 29.0749C15.5759 29.2511 15.1048 29.1987 14.7452 28.9373C14.3853 28.6758 14.19 28.2441 14.2313 27.8012L14.7889 21.82L10.8222 17.3089C10.5285 16.9749 10.4326 16.5108 10.5701 16.0878C10.7076 15.6647 11.0579 15.3456 11.4918 15.248L17.3525 13.93L20.417 8.76332C20.644 8.38077 21.0557 8.14621 21.5005 8.14621C21.9453 8.14621 22.3571 8.38077 22.584 8.76332L25.6485 13.93L31.5093 15.248C31.9432 15.3456 32.2935 15.6647 32.4309 16.0878C32.5684 16.5109 32.4726 16.9751 32.1789 17.309V17.309Z"
        fill="#7B5CFF"
      />
      <path
        d="M23.7581 15.6821L21.5 11.875L19.2419 15.6821C19.0651 15.9803 18.7731 16.1924 18.4349 16.2685L14.1162 17.2397L17.0392 20.5637C17.2681 20.824 17.3797 21.1673 17.3475 21.5125L16.9366 25.9199L21.0013 24.1671C21.1605 24.0985 21.3302 24.0642 21.5 24.0642C21.6699 24.0642 21.8397 24.0985 21.9988 24.1671L26.0635 25.9199L25.6526 21.5125C25.6205 21.1673 25.732 20.824 25.9609 20.5637L28.8839 17.2397L24.5653 16.2685C24.2269 16.1924 23.9351 15.9803 23.7581 15.6821V15.6821Z"
        fill="#7B5CFF"
      />
      <path
        d="M21.5 42.2617C20.8043 42.2617 20.2402 41.6976 20.2402 41.0019V37.1328C20.2402 36.4371 20.8043 35.873 21.5 35.873C22.1957 35.873 22.7598 36.4371 22.7598 37.1328V41.0019C22.7598 41.6976 22.1957 42.2617 21.5 42.2617Z"
        fill="#6B4EE6"
      />
      <path
        d="M1.26006 27.5571C0.7386 27.5571 0.25107 27.2309 0.070168 26.7106C-0.158269 26.0535 0.189426 25.3356 0.84652 25.1072L4.44861 23.8553C5.1057 23.6269 5.82377 23.9744 6.05212 24.6317C6.28056 25.2889 5.93287 26.0068 5.27577 26.2351L1.67368 27.487C1.53687 27.5345 1.39729 27.5571 1.26006 27.5571Z"
        fill="#6B4EE6"
      />
      <path
        d="M41.7402 27.5572C41.603 27.5572 41.4635 27.5346 41.3265 27.487L37.7244 26.2352C37.0674 26.0068 36.7197 25.2889 36.9481 24.6317C37.1766 23.9745 37.8943 23.6269 38.5516 23.8554L42.1537 25.1072C42.8108 25.3356 43.1585 26.0535 42.93 26.7107C42.7492 27.231 42.2616 27.5572 41.7402 27.5572Z"
        fill="#6B4EE6"
      />
      <path
        d="M31.7812 6.7456C31.5601 6.7456 31.3363 6.6874 31.1331 6.56512C30.5369 6.20651 30.3443 5.43251 30.7029 4.8363L32.6268 1.63793C32.9855 1.04181 33.7594 0.849145 34.3556 1.20776C34.9518 1.56637 35.1444 2.34037 34.7858 2.93658L32.8619 6.13495C32.6255 6.528 32.2086 6.7456 31.7812 6.7456Z"
        fill="#6B4EE6"
      />
      <path
        d="M11.2183 6.74559C10.7908 6.74559 10.374 6.52799 10.1376 6.13503L8.21369 2.93665C7.855 2.34045 8.04757 1.56645 8.64386 1.20783C9.24007 0.849052 10.0142 1.04188 10.3727 1.638L12.2966 4.83638C12.6552 5.43258 12.4627 6.20658 11.8664 6.56519C11.6633 6.68739 11.4393 6.74559 11.2183 6.74559Z"
        fill="#6B4EE6"
      />
    </svg>
  );
}

export default StartFeedbackIcon;
