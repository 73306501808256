import React from 'react';

function SmallHouseIcon({ ...rest }) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.6508 5.65437C12.6505 5.65408 12.6502 5.65378 12.6499 5.65348L7.34639 0.350708C7.12033 0.124573 6.81978 0 6.50008 0C6.18038 0 5.87983 0.124474 5.65367 0.350609L0.352939 5.6507C0.351154 5.65249 0.349368 5.65437 0.347583 5.65616C-0.116637 6.12301 -0.115843 6.88046 0.349864 7.34612C0.562632 7.55897 0.843643 7.68225 1.1441 7.69514C1.1563 7.69633 1.1686 7.69693 1.181 7.69693H1.39237V11.5994C1.39237 12.3717 2.02076 13 2.79327 13H4.86817C5.07846 13 5.24907 12.8295 5.24907 12.6191V9.55957C5.24907 9.20718 5.53573 8.92054 5.88816 8.92054H7.112C7.46443 8.92054 7.75109 9.20718 7.75109 9.55957V12.6191C7.75109 12.8295 7.9216 13 8.13199 13H10.2069C10.9794 13 11.6078 12.3717 11.6078 11.5994V7.69693H11.8038C12.1234 7.69693 12.4239 7.57246 12.6502 7.34632C13.1164 6.87987 13.1166 6.12112 12.6508 5.65437V5.65437Z"
        fill="#6B4EE6"
      />
    </svg>
  );
}

export default SmallHouseIcon;
