import React from 'react';

function MessageHeaderIcon({ ...rest }) {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.8601 0H2.13993C0.959941 0 0 0.959971 0 2.13993V9.37506C0 10.555 0.959941 11.515 2.13993 11.515H2.97967L2.9961 13.4041C2.99757 13.5783 3.09756 13.7365 3.25418 13.8126C3.31781 13.8435 3.38637 13.8587 3.45457 13.8587C3.55415 13.8587 3.653 13.8263 3.7348 13.7632L6.64761 11.515H12.8601C14.04 11.515 15 10.5551 15 9.37512V2.13993C15 0.959971 14.04 0 12.8601 0Z"
        fill="#5945B1"
      />
    </svg>
  );
}

export default MessageHeaderIcon;
