import { getSize } from 'lib/utils';
import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { CloseButton } from 'ui/close-button';

interface Props {
  children?: React.ReactNode;
  isVisible: boolean;
  title?: string;
  description?: string;
  onClose?: () => void;
  titleCSS?: CSSProp;
  headerCSS?: CSSProp;
  paperCSS?: CSSProp;
  className?: string;
  renderTitle?: JSX.Element;
  closeButtonCSS?: CSSProp;
  descriptionCSS?: CSSProp;
  hasCloseIcon?: boolean;
  isNoteForVideoChart?: boolean;
}

const Modal = (props: Props) => {
  const {
    title,
    description,
    isVisible,
    children,
    onClose,
    titleCSS,
    className,
    headerCSS,
    paperCSS,
    renderTitle,
    closeButtonCSS,
    descriptionCSS,
    isNoteForVideoChart,
    hasCloseIcon = true,
  } = props;

  if (!isVisible) {
    return null;
  }
  return (
    <Root onClick={onClose} $isNoteForVideoChart={isNoteForVideoChart}>
      <Paper
        onClick={(e) => e.stopPropagation()}
        className={className}
        $CSS={paperCSS}
        $isNoteForVideoChart={isNoteForVideoChart}
      >
        <Header $CSS={headerCSS}>
          {renderTitle
            ? renderTitle
            : title && <Title $CSS={titleCSS}>{title}</Title>}

          {hasCloseIcon && onClose && (
            <Close $CSS={closeButtonCSS} onClick={onClose} />
          )}
        </Header>
        {description && (
          <Description $CSS={descriptionCSS}>{description}</Description>
        )}
        {children}
      </Paper>
    </Root>
  );
};

interface RootProps {
  $isNoteForVideoChart?: boolean;
}

const Root = styled.div<RootProps>`
  position: ${({ $isNoteForVideoChart }) =>
    $isNoteForVideoChart ? 'static' : 'fixed'};
  overflow: auto;
  z-index: 101;
  top: 0;
  left: 0;
  height: 100%;
  flex-shrink: 0;
  width: ${({ $isNoteForVideoChart }) =>
    $isNoteForVideoChart ? '500px' : '100%'};
  background-color: ${({ $isNoteForVideoChart }) =>
    $isNoteForVideoChart ? 'none' : 'var(--black-opacity2)'};
  display: flex;
  padding: ${({ $isNoteForVideoChart }) =>
    $isNoteForVideoChart
      ? `${getSize(30)} ${getSize(30)} 107px ${getSize(30)}`
      : getSize(10)};
`;

interface PaperProps {
  $isNoteForVideoChart?: boolean;
  $CSS?: CSSProp;
}

const Paper = styled.div<PaperProps>`
  position: relative;
  width: 100%;
  max-width: ${getSize(530)};
  display: flex;
  flex-direction: column;
  margin: ${({ $isNoteForVideoChart }) =>
    $isNoteForVideoChart ? '0' : 'auto'};
  padding: ${getSize(50)} ${getSize(36)} ${getSize(38)};
  border-radius: ${getSize(8)};
  background-color: var(--white);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.19);

  ${({ $CSS }) => $CSS}
`;

const Title = styled.h2<{ $CSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

const Header = styled.header<{ $CSS?: CSSProp }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: 600;
  font-size: ${getSize(18)};
  line-height: ${getSize(26)};
  margin-bottom: ${getSize(16)};
  ${({ $CSS }) => $CSS}
`;

const Close = styled(CloseButton)<{ $CSS?: CSSProp }>`
  ${({ $CSS }) => $CSS}
`;

const Description = styled.div<{ $CSS?: CSSProp }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  margin-bottom: ${getSize(37)};
  color: var(--black3);

  ${({ $CSS }) => $CSS}
`;

export default Modal;
