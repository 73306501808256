import React from 'react';
import styled from 'styled-components';
import { CircleLoaderIcon } from 'ui/icons';

export interface LoaderProps {
  className?: string;
  hasFillWholeBlock?: boolean;
  size?: number;
  pathColor?: string;
}

function Loader({
  className,
  hasFillWholeBlock,
  size = 24,
  pathColor,
}: LoaderProps) {
  return (
    <Wrapper className={className} $hasFillWholeBlock={hasFillWholeBlock}>
      <Icon width={size} height={size} pathColor={pathColor} />
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $hasFillWholeBlock?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $hasFillWholeBlock }) =>
    $hasFillWholeBlock ? 'width: 100%; height: 100%;' : ''}
`;

const Icon = styled(CircleLoaderIcon)`
  animation: frontOvalAnimate infinite 2s linear;

  @keyframes frontOvalAnimate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default Loader;
