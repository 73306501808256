export const getPricesMapByDuration = ({
  thirtyMin,
  fiftyMin,
  eightyMin,
}: {
  thirtyMin: number;
  fiftyMin: number;
  eightyMin: number;
}) => {
  return {
    '30': thirtyMin,
    '50': fiftyMin,
    '80': eightyMin,
  } as { [key: string]: number };
};
