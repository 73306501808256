import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
class SentryService {
  private static instance: SentryService;
  enabled: boolean;

  private constructor() {
    this.enabled = !!process.env.NODE_ENV;

    if (SentryService.instance) {
      return SentryService.instance;
    }

    if (!this.enabled) {
      return;
    }

    const production = process.env.NODE_ENV === 'production';

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      tracesSampleRate: production ? 1.0 : 0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: production ? 1.0 : 0,
      integrations: [
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
    });

    SentryService.instance = this;
  }

  public captureException(
    exception: any,
    captureContext?: CaptureContext,
  ): string {
    if (!this.enabled) {
      return '';
    }

    return Sentry.captureException(exception, captureContext);
  }

  public addBreadcrumb(breadcrumb: Sentry.Breadcrumb): void {
    if (!this.enabled) {
      return;
    }

    return Sentry.addBreadcrumb(breadcrumb);
  }

  public captureMessage(message: string): string {
    if (!this.enabled) {
      return '';
    }

    return Sentry.captureMessage(message);
  }

  static init() {
    return new SentryService();
  }
}

export default SentryService.init();
