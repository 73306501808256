import { CSSProperties } from 'react';
import { Styles } from 'react-select';
import { getSize } from 'lib/utils';

export const customStyles: Partial<Styles<any, any>> = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  container: (base, { isFocused, isDisabled, selectProps: { isError } }) => ({
    ...base,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: getSize(-1),
      width: '100%',
      height: getSize(1),
      borderRadius: getSize(8),
      background: `var(${(() => {
        if (isError) {
          return '--red';
        } else if (isDisabled) {
          return '---gray7';
        } else if (isFocused) {
          return '--purple4';
        } else {
          return '--purple3';
        }
      })()}
      )`,
      transition: '0.3s ease-out',
    },
  }),
  control: (base) => {
    return {
      ...base,
      minHeight: getSize(44),
      boxShadow: 'none',
      border: 0,
      background: 'transparent',

      '&:hover': {
        cursor: 'pointer',
      },
    };
  },
  placeholder: (base) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: getSize(12),
    lineHeight: getSize(20),
    color: 'var(--gray7)',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: `${getSize(4)} 0`,
    overflow: 'visible',
    alignItems: 'center',
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  singleValue: (base, { isFocused, hasValue, isDisabled }) => ({
    ...base,
    fontWeight: 'normal',
    fontSize: getSize(12),
    lineHeight: getSize(24),
    color: `var(${(() => {
      if (isDisabled) {
        return '--gray7';
      } else if (isFocused || hasValue) {
        return '--black3';
      } else {
        return '--gray2';
      }
    })()})`,
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (base, { isDisabled, selectProps: { isOpen } }) => ({
    ...base,
    display: isDisabled ? 'none' : 'flex',
    padding: 0,
    height: getSize(44),
    transform: `scale(${isOpen ? -1 : 1})`,
    transition: '0.2s ease-out',
  }),
  menu: (base) => ({
    ...base,
    top: `calc(100% + ${getSize(8)})`,
    margin: 0,
    zIndex: 10,
    padding: 0,
    borderRadius: getSize(8),
    boxShadow: `0 ${getSize(20)} ${getSize(19)} var(--gray-opacity)`,
    overflow: 'hidden',
  }),
  menuList: (base) => ({
    ...base,
    padding: `${getSize(12)} 0`,
    maxHeight: getSize(168),
  }),
  multiValue: (base) => ({
    ...base,
    alignItems: 'center',
    padding: `${getSize(5)} ${getSize(8)}`,
    border: `${getSize(1)} solid var(--gray4)`,
    borderRadius: getSize(8),
    background: 'white',

    '&:hover': {
      cursor: 'default',
    },

    'div:first-of-type': {
      margin: `0 ${getSize(4)} 0 0`,
      padding: 0,
      fontWeight: 'normal',
      fontSize: getSize(12),
      lineHeight: getSize(18),
      color: 'var(--black3)',
      whiteSpace: 'break-spaces',
    },
    'div:nth-of-type(2)': {
      padding: 0,
      cursor: 'pointer',
      background: 'var(--white) !important',

      '&:hover': {
        svg: {
          stroke: 'var(--purple)',
        },
      },
    },
  }),
  input: (base) => ({
    ...base,
    padding: 0,
  }),
  option: (base, { isSelected }) => ({
    ...base,
    padding: `${getSize(12)} ${getSize(24)}`,
    minHeight: getSize(48),
    fontWeight: 'normal',
    lineHeight: getSize(24),
    fontSize: getSize(14),
    color: 'var(--black3)',
    background: `var(${isSelected ? '--purple12' : '--white'})`,
    transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 0.15s',
    cursor: 'pointer',

    '&:hover': {
      background: `var(--purple11)`,
    },
  }),
};

export const onboardingMultiSelectStyles: Partial<Styles<any, any>> = {
  ...customStyles,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  singleValue: (base, { isFocused, hasValue, isDisabled }) => ({
    ...base,
    fontWeight: 500,
    fontSize: getSize(12),
    lineHeight: getSize(24),
    color: `var(${(() => {
      if (isDisabled) {
        return '--gray7';
      } else if (isFocused || hasValue) {
        return '--black3';
      } else {
        return '--gray2';
      }
    })()})`,
  }),
  multiValue: (base) => ({
    ...base,
    alignItems: 'center',
    padding: `${getSize(5)} ${getSize(8)}`,
    border: `${getSize(1)} solid var(--gray4)`,
    borderRadius: getSize(8),
    background: 'white',

    '&:hover': {
      cursor: 'default',
    },

    'div:first-of-type': {
      margin: `0 ${getSize(4)} 0 0`,
      padding: 0,
      fontWeight: '500',
      fontSize: getSize(12),
      lineHeight: getSize(24),
      color: 'var(--black4)',
      whiteSpace: 'break-spaces',
    },
    'div:nth-of-type(2)': {
      padding: 0,
      cursor: 'pointer',
      background: 'var(--white) !important',
      svg: {
        stroke: 'var(--purple)',
      },
      '&:hover': {
        svg: {
          stroke: 'var(--black3)',
        },
      },
    },
  }),
};

export const filterTherapistMultiSelect: Partial<Styles<any, any>> = {
  ...customStyles,
  container: (base) => ({
    ...base,
  }),
  control: (base, state) => {
    return {
      ...base,
      minHeight: getSize(43),
      boxShadow: 'none',
      border: state.isFocused
        ? `${getSize(1)} solid var(--purple)`
        : `${getSize(1)} solid var(--gray19)`,
      background: 'transparent',
      transition: '0.2s ease-out',
      borderRadius: getSize(8),

      '&:hover': {
        cursor: 'pointer',
        border: state.isFocused
          ? `${getSize(1)} solid var(--purple)`
          : `${getSize(1)} solid var(--gray22)`,
      },
    };
  },
  valueContainer: (base) => ({
    ...base,
    padding: `${getSize(6)} 0 ${getSize(6)} ${getSize(9)}`,
    overflow: 'visible',
    alignItems: 'center',
  }),
  indicatorsContainer: (base, { isDisabled, selectProps: { isOpen } }) => ({
    ...base,
    display: isDisabled ? 'none' : 'flex',
    margin: `0 ${getSize(14)} 0 0`,
    height: getSize(44),
    transform: `scale(${isOpen ? -1 : 1})`,
    transition: '0.2s ease-out',
  }),
  menuList: (base) => ({
    ...base,
    padding: `${getSize(12)} 0`,
    maxHeight: getSize(181),
  }),
};
