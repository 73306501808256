import React from 'react';

function VisaClassicIcon({ ...rest }) {
  return (
    <svg width={51} height={35} viewBox="0 0 51 35" fill="none" {...rest}>
      <rect
        x={0.5}
        y={0.5}
        width={50}
        height={34}
        rx={3.5}
        fill="#fff"
        stroke="#F3F3F3"
      />
      <path
        d="M22.644 24.004h-3.395l2.123-12.25h3.395l-2.123 12.25zM34.953 12.053a8.897 8.897 0 00-3.046-.522c-3.353 0-5.714 1.669-5.729 4.053-.028 1.76 1.69 2.737 2.976 3.324 1.314.6 1.76.99 1.76 1.525-.013.82-1.061 1.2-2.039 1.2-1.355 0-2.082-.196-3.186-.652l-.447-.196-.475 2.75c.797.338 2.264.639 3.787.652 3.563 0 5.882-1.643 5.91-4.184.013-1.395-.894-2.463-2.85-3.336-1.188-.56-1.915-.939-1.915-1.512.014-.522.615-1.056 1.955-1.056a6.124 6.124 0 012.53.47l.307.13.462-2.646z"
        fill="#444752"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.03 11.753h2.627l2.739 12.25h-3.144s-.308-1.407-.405-1.837h-4.36l-.712 1.838h-3.563l5.043-11.234c.35-.795.965-1.017 1.775-1.017zm-.21 4.483s-1.075 2.724-1.355 3.428h2.822c-.14-.612-.782-3.545-.782-3.545l-.237-1.055c-.1.271-.245.645-.342.897l-.105.275zM4.536 11.753H10c.74.026 1.342.248 1.537 1.03l1.187 5.628v.002l.364 1.694 3.325-8.354h3.59l-5.336 12.238h-3.59L8.048 13.346a14.426 14.426 0 00-3.569-1.345l.056-.248z"
        fill="#444752"
      />
    </svg>
  );
}

export default VisaClassicIcon;
