import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientAddSessionFeedbackVariables = Types.Exact<{
  input: Types.ReviewAddInput;
}>;


export type PatientAddSessionFeedback = (
  { __typename: 'Mutation' }
  & { patientReviewAdd: (
    { __typename: 'ReviewCommonPayload' }
    & Pick<Types.ReviewCommonPayload, 'success'>
    & { userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const PatientAddSessionFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PatientAddSessionFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReviewAddInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientReviewAdd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type PatientAddSessionFeedbackMutationFn = Apollo.MutationFunction<PatientAddSessionFeedback, PatientAddSessionFeedbackVariables>;

/**
 * __usePatientAddSessionFeedback__
 *
 * To run a mutation, you first call `usePatientAddSessionFeedback` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientAddSessionFeedback` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientAddSessionFeedback, { data, loading, error }] = usePatientAddSessionFeedback({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientAddSessionFeedback(baseOptions?: Apollo.MutationHookOptions<PatientAddSessionFeedback, PatientAddSessionFeedbackVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientAddSessionFeedback, PatientAddSessionFeedbackVariables>(PatientAddSessionFeedbackDocument, options);
      }
export type PatientAddSessionFeedbackHookResult = ReturnType<typeof usePatientAddSessionFeedback>;
export type PatientAddSessionFeedbackMutationResult = Apollo.MutationResult<PatientAddSessionFeedback>;
export type PatientAddSessionFeedbackMutationOptions = Apollo.BaseMutationOptions<PatientAddSessionFeedback, PatientAddSessionFeedbackVariables>;