import React from 'react';

function CalendarIcon({ ...rest }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      stroke="#6B4EE6"
      fill="none"
      {...rest}
    >
      <path
        clipRule="evenodd"
        d="M3 6a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V6z"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16 2v4M8 2v4" strokeWidth={2} strokeLinecap="square" />
      <path
        d="M3 10h18"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CalendarIcon;
