import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import { css } from 'styled-components';
import { SingleSelect } from 'ui/form-elements';
import { SingleSelectProps } from 'ui/form-elements/single-select/single-select';
import { bookingSelectStyles } from 'ui/form-elements/single-select/utils/styles';

const SELECT_LABEL_CSS = css`
  font-weight: 600;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--black3);
  margin-bottom: ${getSize(7)};
`;

const SingleSelectBorder: FC<SingleSelectProps> = ({ ...props }) => {
  return (
    <SingleSelect
      customSelectStyles={bookingSelectStyles}
      labelCSS={SELECT_LABEL_CSS}
      {...props}
    />
  );
};

export default SingleSelectBorder;
