import * as Types from '../../../../__generated__/types';

import { ChatUserFragment } from './users-for-start-new-chat';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatsListVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['String']>;
}>;


export type ChatsList = (
  { __typename: 'Query' }
  & { listChats: (
    { __typename: 'ChatConnectionModel' }
    & { nodes: Array<(
      { __typename: 'ChatModel' }
      & ChatFragment
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type ChatFragment = (
  { __typename: 'ChatModel' }
  & Pick<Types.ChatModel, 'id' | 'title' | 'imageUrl' | 'unreadCount' | 'type'>
  & { lastMessage?: Types.Maybe<(
    { __typename: 'MessageModel' }
    & Pick<Types.MessageModel, 'text' | 'createdAt'>
    & { attachments?: Types.Maybe<Array<(
      { __typename: 'MessageAttachmentModel' }
      & Pick<Types.MessageAttachmentModel, 'name'>
    )>>, sender: (
      { __typename: 'ChatUserModel' }
      & Pick<Types.ChatUserModel, 'id' | 'name'>
    ) }
  )>, participants: Array<(
    { __typename: 'ChatUserModel' }
    & ChatUserFragment
  )> }
);

export const ChatFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ChatFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ChatModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"unreadCount"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"lastMessage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"text"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"attachments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sender"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"participants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatUserFragment"}}]}}]}},...ChatUserFragment.definitions]} as unknown as DocumentNode;
export const ChatsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ChatsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listChats"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...ChatFragment.definitions]} as unknown as DocumentNode;

/**
 * __useChatsList__
 *
 * To run a query within a React component, call `useChatsList` and pass it any options that fit your needs.
 * When your component renders, `useChatsList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsList({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChatsList(baseOptions?: Apollo.QueryHookOptions<ChatsList, ChatsListVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatsList, ChatsListVariables>(ChatsListDocument, options);
      }
export function useChatsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatsList, ChatsListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatsList, ChatsListVariables>(ChatsListDocument, options);
        }
export type ChatsListHookResult = ReturnType<typeof useChatsList>;
export type ChatsListLazyQueryHookResult = ReturnType<typeof useChatsListLazyQuery>;
export type ChatsListQueryResult = Apollo.QueryResult<ChatsList, ChatsListVariables>;