import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

/** Constants */
import { PATIENT_ROUTES } from 'lib/routes';

/** Custom components and images */
import { Button } from 'ui';
import { ArrowRightShortIcon } from 'ui';
import Photo from 'assets/images/photo-group.png';

/** Context */

/** Queries */
import { useReactiveVar } from '@apollo/client';
import { userMeInfoVar } from 'lib/apollo-client/reactive-variables';
import { TeamsAppContext } from '../../../../../hooks/use-teams-app';

const TherapyBanner = () => {
  /** Init */
  const history = useHistory();
  const isTeamsApp = useContext(TeamsAppContext);

  /** Query */
  const { firstName } = useReactiveVar(userMeInfoVar);

  const goToTherapy = () => {
    history.push(PATIENT_ROUTES.DASHBOARD);
  };

  return (
    <Container>
      <Name>Hi {firstName}</Name>
      {!isTeamsApp && (
        <TherapistsContainer>
          <StyledImg src={Photo} />
          <StyledButton theme="tertiary" onClick={goToTherapy}>
            Explore Therapy &nbsp;
            <ArrowRightShortIcon color="white" />
          </StyledButton>
        </TherapistsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 160%;
`;

const TherapistsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--white);
  border-radius: 80px;
  padding: 13px 16px;
`;

const StyledImg = styled.img`
  width: 110px;
  object-fit: contain;
  object-position: center;
  image-rendering: crisp-edges;
  margin-right: 14px;
`;

const StyledButton = styled(Button)`
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default TherapyBanner;
