import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'ui';

interface ConfirmModalProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirmClick: () => void;
  isLoading?: boolean;
  title: string;
  text?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}
const ConfirmModal: FC<ConfirmModalProps> = ({
  isVisible,
  onClose,
  onConfirmClick,
  isLoading,
  title,
  text,
  confirmButtonText,
  cancelButtonText,
}) => {
  return (
    <Modal title={title} isVisible={isVisible} onClose={onClose}>
      {text && <Text>{text}</Text>}

      <ButtonConfirm
        isLoading={isLoading}
        theme="secondary"
        onClick={onConfirmClick}
      >
        {confirmButtonText || 'Confirm'}
      </ButtonConfirm>

      <ButtonCloseModal
        isDisabled={isLoading}
        theme="primary"
        onClick={onClose}
      >
        {cancelButtonText || 'Do not cancel'}
      </ButtonCloseModal>
    </Modal>
  );
};

const Text = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  margin-bottom: ${getSize(40)};
`;

const ButtonConfirm = styled(Button)`
  border: 1px solid #f0edfd;
  border-radius: 36px;
  margin-bottom: ${getSize(7)};
`;

const ButtonCloseModal = styled(Button)`
  border-radius: 36px;
`;

export default ConfirmModal;
