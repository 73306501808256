import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Field, Form as FinalForm } from 'react-final-form';
import { FormApi } from 'final-form';

import { getSize } from 'lib/utils';

import { SendPlaneIcon } from 'ui';
import { AttachmentInput, AttachmentItem, EmojiInput } from './components';

export interface MessageAttachment {
  type: string;
  source: string;
  name: string;
  sizeInKB: number;
  format: string;
}
export interface MessageSendFormValues {
  text: string;
  attachments: MessageAttachment[];
}
interface OnAttachmentCloseClickArgs {
  attachments: MessageAttachment[];
  index: number;
  change: (
    name: 'attachments',
    value?: MessageSendFormValues['attachments'],
  ) => void;
}
export interface MessageSendFormProps {
  isExpanded: boolean;
  selectedChatId?: string;
  onSubmit: (values: MessageSendFormValues) => Promise<any> | undefined;
}

const DEFAULT_INITIAL_VALUES = { attachments: [] };

function MessageSendForm({
  onSubmit,
  isExpanded,
  selectedChatId,
}: MessageSendFormProps) {
  const [uploadPercents, setUploadPercents] = useState<Record<string, number>>(
    {},
  );

  const onAttachmentCloseClick = useCallback(
    ({ attachments, index, change }: OnAttachmentCloseClickArgs) => {
      const cloneAttachments = [...attachments];
      const [removedAttachment] = cloneAttachments.splice(index, 1);
      const removedAttachmentSource = removedAttachment.source;

      change('attachments', cloneAttachments);

      if (uploadPercents[removedAttachmentSource]) {
        setUploadPercents((prevValue) => {
          const clonePrevValue = { ...prevValue };
          delete clonePrevValue[removedAttachmentSource];
          return clonePrevValue;
        });
      }
    },
    [uploadPercents],
  );

  function handleSubmit(
    values: MessageSendFormValues,
    form: FormApi<MessageSendFormValues>,
  ) {
    onSubmit(values)?.then(() => {
      setTimeout(form.reset);
      setUploadPercents({});
    });
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={DEFAULT_INITIAL_VALUES}
      render={({ handleSubmit, values, form: { change } }) => {
        return (
          <Wrapper onSubmit={handleSubmit}>
            <Inner>
              <InputWrapper>
                <Field name="text" component={EmojiInput} />

                <Field
                  name="attachments"
                  selectedChatId={selectedChatId}
                  component={AttachmentInput}
                  setUploadPercents={setUploadPercents}
                />

                <Button disabled={Object.keys(uploadPercents).length > 0}>
                  <SendPlaneIcon />
                </Button>
              </InputWrapper>

              {values.attachments.length > 0 && (
                <AttachmentsList>
                  {values.attachments.map((attachment, index) => {
                    const fileSource = attachment.source;

                    return (
                      <AttachmentItem
                        key={fileSource}
                        attachment={attachment}
                        isExpanded={isExpanded}
                        uploadPercent={uploadPercents[fileSource]}
                        onCloseClick={() =>
                          onAttachmentCloseClick({
                            attachments: values.attachments,
                            index,
                            change,
                          })
                        }
                      />
                    );
                  })}
                </AttachmentsList>
              )}
            </Inner>
          </Wrapper>
        );
      }}
    />
  );
}

const Wrapper = styled.form`
  position: relative;
  z-index: 1;
  margin: auto 0 0;
  padding: 0 ${getSize(34)} ${getSize(23)};
`;

const Inner = styled.div`
  padding: ${getSize(13)} ${getSize(24)};
  background: var(--gray24);
  border-radius: ${getSize(6)};
  border: 1px solid #eeeeee;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const Button = styled.button`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${getSize(28)};
  height: ${getSize(28)};
  background: linear-gradient(89.04deg, #6b4ee6 13.35%, #846af4 97.4%);
  border-radius: 50%;
  transition: 0.3s ease-out;

  &:hover {
    background: var(--purple9);
  }
`;

const AttachmentsList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${getSize(4)} ${getSize(12)};
  margin: ${getSize(13)} 0 0;
`;

export default MessageSendForm;
