import { useMemo } from 'react';
import { Option, sortOptionsByAlphaBet, transformToOptions } from 'lib/utils';
import { useInsuranceCompanies } from 'common/query/__generated__/get-insurance-companies';

export const INSURANCE_NOT_REQUIRED_ADDRESS = [
  'Bupa',
  'Aviva',
  'AXA PPP healthcare',
  'Cigna Healthcare',
  'VitalityHealth',
];

export const getNotRequireAddressIds = (insuranceCompaniesOptions: Option[]) =>
  insuranceCompaniesOptions
    .filter(({ label }) => INSURANCE_NOT_REQUIRED_ADDRESS.includes(label))
    .map(({ value }) => value);

export function useInsuranceCompaniesOptions() {
  const insuranceCompaniesQuery = useInsuranceCompanies();

  return useMemo(
    () =>
      sortOptionsByAlphaBet(
        transformToOptions(insuranceCompaniesQuery.data?.insuranceCompanies),
      ),
    [insuranceCompaniesQuery],
  );
}
