import * as Types from '../../../../__generated__/types';

import { MessageRemoveModalFragment } from '../../mutation/__generated__/delete-messages';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessagesRemovedVariables = Types.Exact<{ [key: string]: never; }>;


export type MessagesRemoved = (
  { __typename: 'Subscription' }
  & { messagesRemoved: (
    { __typename: 'MessagesRemoveModel' }
    & MessageRemoveModalFragment
  ) }
);


export const MessagesRemovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"MessagesRemoved"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messagesRemoved"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MessageRemoveModalFragment"}}]}}]}},...MessageRemoveModalFragment.definitions]} as unknown as DocumentNode;

/**
 * __useMessagesRemoved__
 *
 * To run a query within a React component, call `useMessagesRemoved` and pass it any options that fit your needs.
 * When your component renders, `useMessagesRemoved` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesRemoved({
 *   variables: {
 *   },
 * });
 */
export function useMessagesRemoved(baseOptions?: Apollo.SubscriptionHookOptions<MessagesRemoved, MessagesRemovedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessagesRemoved, MessagesRemovedVariables>(MessagesRemovedDocument, options);
      }
export type MessagesRemovedHookResult = ReturnType<typeof useMessagesRemoved>;
export type MessagesRemovedSubscriptionResult = Apollo.SubscriptionResult<MessagesRemoved>;