import { useCallback } from 'react';

import { notifyError } from 'lib/utils/notification';
import {
  CancelTherapistSession,
  useCancelTherapistSession,
} from 'common/mutation/__generated__/cancel-therapist-session';
import { PatientUpcomingSessionsDocument } from 'features/patient/dashboard/query/__generated__/get-patient-upcoming-sessions';
import { VISIBLE_ITEMS_COUNT } from 'lib/constants/patient';
import {
  PatientCancelAllSessions,
  usePatientCancelAllSessions,
} from 'common/mutation/__generated__/patient-cancel-all-sessions';
import { MutationHookOptions } from '@apollo/client';
import {
  Exact,
  PatientSessionCancelAllInput,
  SessionCancelInput,
} from '__generated__/types';

function getMutationOptions<T, I>(): MutationHookOptions<
  T,
  Exact<{
    input: I;
  }>
> {
  return {
    onError: ({ name, message }) => notifyError({ title: name, text: message }),
    refetchQueries: [
      {
        query: PatientUpcomingSessionsDocument,
        variables: {
          filter: {
            first: VISIBLE_ITEMS_COUNT,
          },
        },
      },
    ],
  };
}

function usePatientCancelSession() {
  const [cancelSession] = useCancelTherapistSession(
    getMutationOptions<CancelTherapistSession, SessionCancelInput>(),
  );
  const [cancelAllSessions] = usePatientCancelAllSessions(
    getMutationOptions<
      PatientCancelAllSessions,
      PatientSessionCancelAllInput
    >(),
  );

  const handleCancelSessionClick = useCallback(
    (id: string, startDateUtc: string, cancelReason: string) => {
      return cancelSession({
        variables: { input: { id, startDateUtc, reason: cancelReason } },
      });
    },
    [cancelSession],
  );

  const handelCancelAllSessions = ({
    startDateUtc,
    cancelReason,
    therapistId,
  }: {
    startDateUtc: string;
    cancelReason: string;
    therapistId: string;
  }) => {
    return cancelAllSessions({
      variables: {
        input: {
          startDateUtc,
          reason: cancelReason,
          therapistId,
        },
      },
    });
  };

  return {
    cancelSession: handleCancelSessionClick,
    cancelAllSession: handelCancelAllSessions,
  };
}

export default usePatientCancelSession;
