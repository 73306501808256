import {
  RequestVisitType,
  SessionType,
  TimePeriodType,
} from './../../__generated__/types';
import { InPersonIcon, VideoChatIcon, LiveChatIcon } from 'ui';
import { ManipulateType, OpUnitType } from 'dayjs';

export const SESSION_TYPES_ICONS = {
  [SessionType.InPerson]: InPersonIcon,
  [SessionType.VideoCall]: VideoChatIcon,
  [SessionType.LiveChat]: LiveChatIcon,
};

export const SESSION_TYPE_TITLES = {
  [SessionType.InPerson]: 'In Person',
  [SessionType.VideoCall]: 'Video Call',
  [SessionType.LiveChat]: 'Live Chat',
};

export const VISIT_TYPE_TITLES = {
  [RequestVisitType.FirstSession]: 'First session',
  [RequestVisitType.Ongoing]: 'Ongoing',
  [RequestVisitType.Reschedule]: 'Reschedule',
};

export const THERAPIST_NOTICE_PERIOD_FOR_DAYJS = {
  [TimePeriodType.Days]: 'day' as ManipulateType,
  [TimePeriodType.Weeks]: 'week' as ManipulateType,
  [TimePeriodType.Hours]: 'hour' as ManipulateType,
};

export const DEFAULT_AVATAR = '/images/default-avatar.png';
