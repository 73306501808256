import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserVerifiedVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserVerified = (
  { __typename: 'Query' }
  & { me?: Types.Maybe<(
    { __typename: 'UserModel' }
    & Pick<Types.UserModel, 'id' | 'firstLogin' | 'verified' | 'onboarded'>
    & { therapist?: Types.Maybe<(
      { __typename: 'TherapistModel' }
      & Pick<Types.TherapistModel, 'onboardedStep' | 'requestStatus'>
    )> }
  )> }
);


export const GetUserVerifiedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserVerified"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstLogin"}},{"kind":"Field","name":{"kind":"Name","value":"verified"}},{"kind":"Field","name":{"kind":"Name","value":"therapist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"onboardedStep"}},{"kind":"Field","name":{"kind":"Name","value":"requestStatus"}}]}},{"kind":"Field","name":{"kind":"Name","value":"onboarded"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetUserVerified__
 *
 * To run a query within a React component, call `useGetUserVerified` and pass it any options that fit your needs.
 * When your component renders, `useGetUserVerified` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserVerified({
 *   variables: {
 *   },
 * });
 */
export function useGetUserVerified(baseOptions?: Apollo.QueryHookOptions<GetUserVerified, GetUserVerifiedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserVerified, GetUserVerifiedVariables>(GetUserVerifiedDocument, options);
      }
export function useGetUserVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserVerified, GetUserVerifiedVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserVerified, GetUserVerifiedVariables>(GetUserVerifiedDocument, options);
        }
export type GetUserVerifiedHookResult = ReturnType<typeof useGetUserVerified>;
export type GetUserVerifiedLazyQueryHookResult = ReturnType<typeof useGetUserVerifiedLazyQuery>;
export type GetUserVerifiedQueryResult = Apollo.QueryResult<GetUserVerified, GetUserVerifiedVariables>;