import {
  ISO_DATE_FORMAT,
  TIME_WITH_TWENTY_FOUR_HOURS,
} from 'lib/constants/date';
import { TimeUtil } from 'lib/utils/time-util';

const timeNowUtc = TimeUtil.now();

export const getAvailableTimesOptions = (
  startOfAvailabilityUTC: string,
  endOfAvailabilityUTC: string,
  duration: number,
  selectedTimezone: string,
) => {
  const availableOptions: { value: string; label: string }[] = [];

  let i =
    startOfAvailabilityUTC >= timeNowUtc.toISOString()
      ? startOfAvailabilityUTC
      : timeNowUtc.add(1, 'h').minute(0).second(0).millisecond(0).toISOString();

  const endOfAvailability = TimeUtil.parse(
    endOfAvailabilityUTC,
    ISO_DATE_FORMAT,
  )
    .subtract(duration, 'minute')
    .toISOString();

  while (i <= endOfAvailability) {
    availableOptions.push({
      value: i,
      label: TimeUtil.parseWithZone(
        i,
        selectedTimezone,
        ISO_DATE_FORMAT,
      ).format(TIME_WITH_TWENTY_FOUR_HOURS),
    });

    i = TimeUtil.parse(i, ISO_DATE_FORMAT).add(30, 'minute').toISOString();
  }
  return availableOptions;
};
