import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import ReactInputMask from 'react-input-mask';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';

export interface InputMaskProps extends FieldRenderProps<string> {
  label?: string;
  rootCSS?: CSSProp;
  inputCSS?: CSSProp;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  mask: string | (string | RegExp)[];
  formatChars?: { [key: string]: string };
  maskChar?: string;
}

function InputMask({
  input,
  meta,
  label,
  rootCSS,
  inputCSS,
  placeholder,
  type,
  disabled,
  mask,
  formatChars,
  maskChar,
}: InputMaskProps) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  return (
    <Wrapper as={label ? 'label' : 'div'} CSS={rootCSS}>
      {label && <Text>{label}</Text>}

      <InputStylized
        mask={mask}
        $CSS={inputCSS}
        placeholder={placeholder}
        {...input}
        type={type}
        formatChars={formatChars}
        disabled={disabled}
        formNoValidate={false}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        maskChar={maskChar}
      />
      <Line $hasError={hasError} $isInputFocused={isInputFocused} />

      {hasError && <ErrorText>{error || submitError || data?.error}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ CSS?: CSSProp }>`
  display: block;

  ${({ CSS }) => CSS}
`;

const Text = styled.span`
  display: block;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray2);
`;

const InputStylized = styled(ReactInputMask)<{ $CSS?: CSSProp }>`
  width: 100%;
  padding: ${getSize(10)} 0;
  font-weight: 400;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--black2);
  border: 0;

  &::placeholder {
    color: var(--gray2);
  }

  ${({ $CSS }) => $CSS}
`;

const Line = styled.div<{ $isInputFocused: boolean; $hasError: boolean }>`
  width: 100%;
  height: ${getSize(1)};
  border-radius: ${getSize(8)};
  background: var(
    ${({ $isInputFocused, $hasError }) => {
      if ($hasError) {
        return '--red';
      } else if ($isInputFocused) {
        return '--purple';
      } else {
        return '--purple3';
      }
    }}
  );
  transition: 0.3s ease-out;
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default InputMask;
