import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

/** Constants */
import { PATIENT_RESOURCES_ROUTES } from 'lib/routes';

import { ArrowDownIcon } from 'ui';

interface categorySubcategoryBreadcrumbsProps {
  activeCategoryId?: string;
  activeSubcategoryId?: string;
  activeCategory?: any;
}

const CategorySubcategoryBreadcrumbs = (
  props: categorySubcategoryBreadcrumbsProps,
) => {
  /** Init */
  const history = useHistory();

  /** Props */
  const { activeCategory, activeCategoryId, activeSubcategoryId } = props;

  const activeCategoryName = activeCategory?.name;
  const activeSubcategoryName =
    activeCategory &&
    activeCategory.subCategories.find(
      (subCategory: { id: string | undefined }) => {
        return subCategory.id === activeSubcategoryId;
      },
    )?.name;

  const handleBackButton = () => {
    history.push(
      PATIENT_RESOURCES_ROUTES.ALL_CONTENT.pathBuild(activeCategoryId),
    );
  };

  return (
    <Container>
      {activeSubcategoryName && (
        <BackButton onClick={handleBackButton}>
          <ArrowDownIcon stroke={'white'} />
        </BackButton>
      )}

      {activeCategoryName && (
        <>
          <div>All</div>
          <div>&nbsp; / &nbsp;</div>
          <div>{activeCategoryName}</div>
        </>
      )}

      {activeSubcategoryName && (
        <>
          <div>&nbsp; / &nbsp;</div>
          <div>{activeSubcategoryName}</div>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--black12);
  > * {
    &:last-child {
      color: var(--black);
    }
  }
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: var(--white);
  background: var(--purple);
  height: 26px;
  width: 26px;
  border-radius: 100%;
  transform: rotate(90deg);
  cursor: pointer;
`;

export default CategorySubcategoryBreadcrumbs;
