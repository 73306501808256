import React from 'react';

function TransactionsIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6875 0.515625H2.3125C1.74063 0.515625 1.19218 0.742801 0.787801 1.14718C0.383426 1.55155 0.15625 2.1 0.15625 2.67188V16.3281C0.15625 16.9 0.383426 17.4484 0.787801 17.8528C1.19218 18.2572 1.74063 18.4844 2.3125 18.4844H16.6875C17.2594 18.4844 17.8078 18.2572 18.2122 17.8528C18.6166 17.4484 18.8438 16.9 18.8438 16.3281V2.67188C18.8438 2.1 18.6166 1.55155 18.2122 1.14718C17.8078 0.742801 17.2594 0.515625 16.6875 0.515625ZM3.80391 7.97984C3.74976 7.84827 3.73588 7.70359 3.76403 7.56412C3.79218 7.42465 3.86109 7.29668 3.96203 7.19641L6.11828 5.04016C6.25578 4.92241 6.43265 4.86088 6.61354 4.86786C6.79443 4.87485 6.96602 4.94984 7.09403 5.07785C7.22204 5.20585 7.29702 5.37744 7.30401 5.55834C7.311 5.73923 7.24947 5.9161 7.13172 6.05359L6.20453 6.98438H14.1719C14.3625 6.98438 14.5453 7.0601 14.6801 7.19489C14.8149 7.32968 14.8906 7.5125 14.8906 7.70312C14.8906 7.89375 14.8149 8.07657 14.6801 8.21136C14.5453 8.34615 14.3625 8.42188 14.1719 8.42188H4.46875C4.32665 8.42217 4.18764 8.38034 4.06931 8.30166C3.95097 8.22298 3.85862 8.11099 3.80391 7.97984ZM15.3973 12.163L13.2411 14.3192C13.1767 14.3945 13.0974 14.4556 13.0082 14.4987C12.919 14.5419 12.8219 14.5661 12.7229 14.5699C12.6239 14.5737 12.5252 14.5571 12.433 14.5209C12.3408 14.4848 12.257 14.43 12.1869 14.3599C12.1169 14.2899 12.0621 14.2061 12.0259 14.1139C11.9898 14.0217 11.9731 13.9229 11.977 13.824C11.9808 13.725 12.005 13.6278 12.0482 13.5387C12.0913 13.4495 12.1524 13.3702 12.2277 13.3058L13.1548 12.375H5.1875C4.99688 12.375 4.81406 12.2993 4.67927 12.1645C4.54448 12.0297 4.46875 11.8469 4.46875 11.6562C4.46875 11.4656 4.54448 11.2828 4.67927 11.148C4.81406 11.0132 4.99688 10.9375 5.1875 10.9375H14.8906C15.0327 10.9372 15.1717 10.979 15.2901 11.0577C15.4084 11.1364 15.5008 11.2484 15.5555 11.3795C15.6096 11.5111 15.6235 11.6558 15.5953 11.7953C15.5672 11.9347 15.4983 12.0627 15.3973 12.163Z"
        fill="#6B4EE6"
      />
    </svg>
  );
}

export default TransactionsIcon;
