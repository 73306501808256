export function setTimePerSecond(
  setTime: (time: number) => void,
  maxSeconds: number,
) {
  let count = 0;
  const interval = setInterval(() => {
    count++;
    setTime(maxSeconds - count + 1);

    if (count > maxSeconds) {
      interval && clearInterval(interval);
    }
  }, 1000);
}
