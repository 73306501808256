import React, { useContext } from 'react';
import { ApolloProvider } from '@apollo/client';
import Routes from './routes';
import './styles/index.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'react-phone-number-input/style.css';
import 'react-day-picker/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import { client } from './lib/apollo-client/client';
import { ToastContainer } from 'react-toastify';
import { config } from 'lib/utils';
import 'react-circular-progressbar/dist/styles.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'emoji-mart/css/emoji-mart.css';
import { SelectAppModal } from 'components/select-app-modal';
import { useSelectContinueApp } from 'hooks';
import { TeamsAppContext } from './hooks/use-teams-app';
import TeamsRoutes from './routes/teams';

function App() {
  const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);
  const { isShowSelectAppModal, onCloseModal } = useSelectContinueApp();
  const isTeamsApp = useContext(TeamsAppContext);

  const routes = () => {
    return isTeamsApp ? <TeamsRoutes /> : <Routes />;
  };

  return (
    <ApolloProvider client={client}>
      <Elements stripe={stripePromise}>
        {routes()}
        <ToastContainer />
        {!isTeamsApp && isShowSelectAppModal && (
          <SelectAppModal onClose={onCloseModal} />
        )}
      </Elements>
    </ApolloProvider>
  );
}

export default App;
