import React from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import styled, { CSSProp } from 'styled-components';

import { getSize } from 'lib/utils';

import { Loader } from 'ui/loader';
import { AttachIcon } from 'ui/icons';

export interface UploadButtonProps {
  isLoading: boolean;
  className?: string;
  rootCSS?: CSSProp;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
}

function UploadButton({
  isLoading,
  className,
  getInputProps,
  rootCSS,
}: UploadButtonProps) {
  return (
    <Wrapper className={className} $CSS={rootCSS}>
      {isLoading ? (
        <Loader size={18} hasFillWholeBlock />
      ) : (
        <>
          <input {...getInputProps()} />
          <AttachIconStylized />
          Upload
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: ${getSize(4)} ${getSize(12)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  background: var(--purple11);
  border-radius: ${getSize(8)};
  color: var(--black3);
  cursor: pointer;

  ${({ $CSS }) => $CSS}
`;

const AttachIconStylized = styled(AttachIcon)`
  margin: 0 ${getSize(4)} 0 0;
`;

export default UploadButton;
