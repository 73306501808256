export interface BaseStyleProps {
  className?: string;
}

export enum BookingSessionStatus {
  Pending = 'Pending',
  Success = 'Success',
  Fail = 'Fail',
}

export enum DurationValues {
  First = '30',
  Second = '50',
  Third = '80',
}

export enum SignUpSpots {
  STANDART = 'standart',
  BOOKING_GATE_1 = 'booking_gate_1',
}
