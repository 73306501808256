import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGetSessionsNeededFeedbackLazyQuery } from 'common/query/__generated__/get-sessions-need-feedback';
import { sortedByType } from 'lib/utils/sort-helper';
import { SORT_TYPE } from 'interfaces/common';
import { TimeUtil } from 'lib/utils';
import { ISO_DATE_FORMAT } from 'lib/constants/date';
import {
  PatientAddSessionFeedback,
  usePatientAddSessionFeedback,
} from 'common/mutation/__generated__/patient-add-session-feedback';
import {
  PatientSkipSessionFeedback,
  usePatientSkipSessionFeedback,
} from 'common/mutation/__generated__/patient-skip-session-feedback';
import { getQueryCacheEvictFunc } from 'lib/apollo-client/utils/evict-cache-helper';
import { useGetSessionsNeedAppFeedbackLazyQuery } from 'common/query/__generated__/get-sessions-need-app-feedback';
import { FetchResult } from '@apollo/client';

const FOUR_HOURS_IN_MILLISECOND = 14400000;

interface UseFeedBackArgs {
  onOpenFeedbackModal: () => void;
  onCloseFeedbackModal: () => void;
}

export interface FeedbackContextValues {
  onSetIsSessionInProgress: (value: boolean) => void;
}

export const FeedbackContext = createContext<FeedbackContextValues>({
  onSetIsSessionInProgress: () => {},
});

export const useFeedback = ({
  onOpenFeedbackModal,
  onCloseFeedbackModal,
}: UseFeedBackArgs) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sessionMetaId, setSessionMetaId] = useState<string | null>(null);
  const [therapistId, setTherapistId] = useState<string | null>(null);
  const [isSessionInProgress, setIsSessionInProgress] = useState(false);
  const [
    sessionWithFeedbackAboutAppMetaId,
    setSessionWithFeedbackAboutAppMetaId,
  ] = useState<string | null>(null);

  const [getSessionNeededFeedback, { data: sessionsData }] =
    useGetSessionsNeededFeedbackLazyQuery();
  const [getAppFeedbackSession, { data: appFeedbackSessionsData }] =
    useGetSessionsNeedAppFeedbackLazyQuery();

  const [addSessionFeedback] = usePatientAddSessionFeedback({
    update: getQueryCacheEvictFunc<PatientAddSessionFeedback>([
      'patientSessionsNeededFeedback',
    ]),
  });
  const [skipSessionFeedback] = usePatientSkipSessionFeedback({
    update: getQueryCacheEvictFunc<PatientSkipSessionFeedback>([
      'patientSessionsNeededFeedback',
    ]),
  });

  const sessions = sessionsData?.patientSessionsNeededFeedback;
  const patientAppNeededFeedback =
    appFeedbackSessionsData?.patientAppNeededFeedback;
  const appFeedbackSessionsIds = useMemo(
    () =>
      patientAppNeededFeedback &&
      [...patientAppNeededFeedback]
        .sort((a, b) => sortedByType(a.endDateUtc, b.endDateUtc, SORT_TYPE.ASC))
        .map(({ id }) => id),
    [patientAppNeededFeedback],
  );

  useEffect(() => {
    getSessionNeededFeedback();
    getAppFeedbackSession();
    const interval = setInterval(() => {
      getSessionNeededFeedback();
      getAppFeedbackSession();
    }, FOUR_HOURS_IN_MILLISECOND);
    return () => clearInterval(interval);
  }, [getSessionNeededFeedback, getAppFeedbackSession]);

  useEffect(() => {
    if (sessionWithFeedbackAboutAppMetaId) return onOpenFeedbackModal();

    let interval: number;

    if (sessions) {
      const sortedSessions = [...sessions].sort((a, b) =>
        sortedByType(a.endDateUtc, b.endDateUtc, SORT_TYPE.ASC),
      );
      const nearestSessionEndDate =
        sortedSessions[0] &&
        TimeUtil.parse(sortedSessions[0].endDateUtc, ISO_DATE_FORMAT).toDate();
      if (
        !nearestSessionEndDate ||
        nearestSessionEndDate > TimeUtil.now().toDate()
      ) {
        onCloseFeedbackModal();
      }

      const checkSessionsProgressStatus = () => {
        if (
          nearestSessionEndDate &&
          nearestSessionEndDate <= TimeUtil.now().toDate() &&
          !isSessionInProgress
        ) {
          setSessionMetaId(sortedSessions[0].id);
          setTherapistId(sortedSessions[0].therapistId);
          onOpenFeedbackModal();
        }
      };

      checkSessionsProgressStatus();

      interval = setInterval(() => {
        checkSessionsProgressStatus();
      }, 60000);
    }

    return () => clearInterval(interval);
  }, [
    sessions,
    onOpenFeedbackModal,
    isSessionInProgress,
    onCloseFeedbackModal,
    sessionWithFeedbackAboutAppMetaId,
  ]);

  return {
    isLoading,
    isAppFeedback: Boolean(sessionWithFeedbackAboutAppMetaId),
    onSetIsSessionInProgress: (value: boolean) => setIsSessionInProgress(value),
    getSessionNeededFeedback,
    sessionsData,
  };
};
