import { ADMIN_ID } from '../chat';
import { ChatFragment } from './../query/__generated__/chats-list';

export function isAdminChat(chat?: ChatFragment) {
  if (!chat) return;
  const { participants } = chat;

  const isAdminChat = participants.some(({ id }) => id === ADMIN_ID);
  const isPrivateChat = participants.length === 2;

  return isAdminChat && isPrivateChat;
}
