import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { NavBar } from './components/nav-bar';
import { TeamsAppContext } from '../../../../hooks/use-teams-app';
import { TeamsNavBar } from './components/teams-nav-bar';
import { logout } from '../../../../lib/auth';
import LogoutIcon from '../../../../ui/icons/logout-icon/logout-icon';

const ResourcesLayout: FC = ({ children }) => {
  const isTeamsApp = useContext(TeamsAppContext);

  if (isTeamsApp) {
    return (
      <ResourcesLayoutTeamsWrapper>
        <TeamsWrapper>
          <TeamsNavBarWrapper>
            <TeamsNavBar />
          </TeamsNavBarWrapper>
          <TeamsContent>{children}</TeamsContent>
        </TeamsWrapper>
        <LogoutButton>
          <LogoutButtonText onClick={() => logout()}>
            <LogoutButtonTextWrapper>Log out</LogoutButtonTextWrapper>
            <LogoutIcon height={15} width={15} />
          </LogoutButtonText>
        </LogoutButton>
      </ResourcesLayoutTeamsWrapper>
    );
  }
  return (
    <Wrapper>
      <NavBarWrapper>
        <NavBar />
      </NavBarWrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default ResourcesLayout;

const Wrapper = styled.section`
  min-height: 100%;
  display: flex;
  justify-content: start;
  max-width: 1205px;
  margin: 0 auto;
`;

const NavBarWrapper = styled.nav`
  padding: 22px 30px 0 0;
  border-right: 1px solid #eeeeee;
`;

const Content = styled.div`
  padding: 40px 0 40px 60px;
  flex-grow: 1;
  max-width: 1025px;
`;

const TeamsWrapper = styled.section`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 875px;
  margin: 20px auto 0 auto;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const TeamsNavBarWrapper = styled.nav``;

const TeamsContent = styled.div`
  padding: 10px;
`;

const LogoutButton = styled.div`
  padding-top: 30px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const LogoutButtonText = styled.span`
  display: flex;
  align-items: center;
  line-height: 20px;
  color: var(--purple);
`;

const ResourcesLayoutTeamsWrapper = styled.div`
  display: flex;
`;

const LogoutButtonTextWrapper = styled.span`
  margin-right: 5px;
`;
