import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { ArrowIcon, CircleProgressBar } from 'ui';
import { PatientGoalFragment } from 'common/query/__generated__/get-patient-goals';

export interface Goal extends PatientGoalFragment {
  percent: number;
}
export interface GoalItemProps {
  goal: Goal;
  onClick: () => void;
}

function GoalItem({ onClick, goal: { title, percent } }: GoalItemProps) {
  return (
    <Wrapper onClick={onClick}>
      <CircleProgressBar percent={percent} />
      <Title className="text-line-clamp-2">{title}</Title>
      <span className="visually-hidden">Open goal editor</span>
      <Icon />
    </Wrapper>
  );
}

const Wrapper = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover > svg {
    transform: scale(1.3) rotate(-90deg);
  }
`;

const Title = styled.p`
  margin: 0 ${getSize(18)};
  max-width: ${getSize(180)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(21)};
  color: var(--black3);
`;

const Icon = styled(ArrowIcon)`
  margin: 0 0 0 auto;
  transition: 0.3s ease-out;
  transform: rotate(-90deg);
  width: ${getSize(22)};
  height: ${getSize(22)};
`;

export default GoalItem;
