import React from 'react';

function PlusIcon({ ...rest }) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      stroke="#13101F"
      fill="none"
      strokeWidth={1.5}
      {...rest}
    >
      <path
        d="M9 3.75v10.5M3.75 9h10.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PlusIcon;
