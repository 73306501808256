import { useMemo } from 'react';
import { transformToOptions } from 'lib/utils';
import { useTreatmentAreas } from 'common/query/__generated__/get-treatment-areas';

export function useTreatmentAreasOptions() {
  const treatmentAreasQuery = useTreatmentAreas();

  return useMemo(
    () => transformToOptions(treatmentAreasQuery.data?.treatmentAreas),
    [treatmentAreasQuery],
  );
}
