import React from 'react';
import styled from 'styled-components';

// Images
import checkboxChecked from 'assets/images/checkbox-checked.png';

interface SubcategoryProps {
  name: string;
  isSelected: boolean;
  onChange: () => void;
}

const Subcategory = (props: SubcategoryProps) => {
  const { name, isSelected, onChange } = props;

  return (
    <Container>
      <Checkbox
        onChange={onChange}
        checked={isSelected}
        checkedImg={checkboxChecked}
      />
      <Name>{name}</Name>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
`;

const Name = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: white;
  margin-left: 20px;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })<{
  checkedImg: any;
}>`
  appearance: none;
  position: relative;
  height: 24px;
  width: 24px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-radius: 5px;
  margin: 0;

  &:checked {
    background-color: var(--white);
    background: white url(${(props) => props.checkedImg}) no-repeat 50% 50%;
  }
`;

export default Subcategory;
