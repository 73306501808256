import { getSize } from 'lib/utils';
import React, { FC, ReactNode, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css, CSSProp } from 'styled-components';
import { ArrowDownIcon, ArrowRightShortIcon } from 'ui';

interface ContainerProps {
  children: ReactNode;
  currentScreen: number;
  screensCount: number;
  isQuestionnaire?: boolean;
  setCurrentScreenIndex: (value: number) => void;
  rootCSS?: CSSProp;
  assessmentOutput?: boolean;
  progressCSS?: CSSProp;
  pageCountCSS?: CSSProp;
}

const Container: FC<ContainerProps> = ({
  children,
  currentScreen,
  screensCount,
  setCurrentScreenIndex,
  isQuestionnaire,
  rootCSS,
  assessmentOutput,
  progressCSS,
  pageCountCSS,
}) => {
  const isFirstScreen = currentScreen === 0;
  const isLastScreen = currentScreen + 1 > screensCount;

  const history = useHistory();

  const progressLinesList = useMemo(() => {
    return [...Array(screensCount)].map((_, index) => (
      <ProgressLine $isActive={currentScreen >= index} key={index} />
    ));
  }, [currentScreen, screensCount]);
  return (
    <Wrapper $CSS={rootCSS}>
      <ProgressLinesWrapper
        $CSS={progressCSS}
        $isLastOrFirstScreen={isFirstScreen || isLastScreen}
      >
        {progressLinesList}
        {!isLastScreen && (
          <PageCount $CSS={pageCountCSS}>
            {currentScreen + 1}/{screensCount}
          </PageCount>
        )}
      </ProgressLinesWrapper>

      {isQuestionnaire && isFirstScreen && (
        <BackButton onClick={() => history.goBack()}>
          <Icon />
          Back
        </BackButton>
      )}

      {!(isFirstScreen || isLastScreen) && (
        <BackButton
          onClick={() => setCurrentScreenIndex(currentScreen - 1)}
          $isAssessmentOutput={assessmentOutput}
        >
          {assessmentOutput ? (
            <>
              <LeftIcon />
              Back
            </>
          ) : (
            <>
              <Icon />
              Previous Question
            </>
          )}
        </BackButton>
      )}

      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  position: relative;
  width: 100%;
  height: 100%;

  padding: ${getSize(28)} ${getSize(121)} ${getSize(20)};

  ${({ $CSS }) => $CSS}
`;

const LeftIcon = styled(ArrowRightShortIcon)`
  transform: rotate(180deg);
  margin: 0 ${getSize(8)} 0 0;
  width: ${getSize(12)};
  height: ${getSize(12)};

  path {
    fill: var(--black);
  }
`;

const ProgressLinesWrapper = styled.div<{
  $CSS?: CSSProp;
  $isLastOrFirstScreen: boolean;
}>`
  position: relative;
  display: flex;
  padding: 0 ${getSize(16)};
  margin-bottom: ${getSize(154)};

  ${({ $CSS }) => $CSS}
`;

const ProgressLine = styled.div<{ $isActive?: boolean }>`
  width: ${getSize(166)};
  height: ${getSize(6)};
  background-color: var(
    ${({ $isActive }) => ($isActive ? '--purple' : '--purple3')}
  );

  &:not(:last-of-type) {
    margin-right: ${getSize(3)};
  }

  &:first-child {
    border-radius: ${getSize(4)} 0 0 ${getSize(4)};
  }

  &:last-of-type {
    border-radius: 0 ${getSize(4)} ${getSize(4)} 0;
  }
`;

const PageCount = styled.p<{ $CSS?: CSSProp }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${getSize(-58)};
  font-weight: 600;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};

  ${({ $CSS }) => $CSS}
`;

const BackButton = styled.button<{ $isAssessmentOutput?: boolean }>`
  position: absolute;
  top: ${getSize(68)};
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--gray2);
  transition: color 0.2s ease-in;

  & > svg {
    transition: stroke 0.2s ease-in;
  }

  &:hover {
    color: var(--purple);
  }
  margin-bottom: ${getSize(102)};

  ${({ $isAssessmentOutput }) =>
    $isAssessmentOutput &&
    css`
      color: var(--black3);
      font-size: ${getSize(14)};
      line-height: ${getSize(24)};
      transition: color 0s;

      &:hover {
        color: var(--purple);

        svg {
          path {
            fill: var(--purple);
          }
        }
      }
    `}
`;

const Icon = styled(ArrowDownIcon)`
  transform: rotate(90deg);
  margin: 0 ${getSize(8)} 0 0;
  stroke: var(--black3);
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

export default Container;
