import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientPaymentMethodSetDefaultVariables = Types.Exact<{
  paymentMethodId: Types.Scalars['String'];
}>;


export type PatientPaymentMethodSetDefault = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'patientPaymentMethodSetDefault'>
);


export const PatientPaymentMethodSetDefaultDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PatientPaymentMethodSetDefault"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"paymentMethodId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientPaymentMethodSetDefault"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"paymentMethodId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"paymentMethodId"}}}]}]}}]} as unknown as DocumentNode;
export type PatientPaymentMethodSetDefaultMutationFn = Apollo.MutationFunction<PatientPaymentMethodSetDefault, PatientPaymentMethodSetDefaultVariables>;

/**
 * __usePatientPaymentMethodSetDefault__
 *
 * To run a mutation, you first call `usePatientPaymentMethodSetDefault` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientPaymentMethodSetDefault` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientPaymentMethodSetDefault, { data, loading, error }] = usePatientPaymentMethodSetDefault({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function usePatientPaymentMethodSetDefault(baseOptions?: Apollo.MutationHookOptions<PatientPaymentMethodSetDefault, PatientPaymentMethodSetDefaultVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientPaymentMethodSetDefault, PatientPaymentMethodSetDefaultVariables>(PatientPaymentMethodSetDefaultDocument, options);
      }
export type PatientPaymentMethodSetDefaultHookResult = ReturnType<typeof usePatientPaymentMethodSetDefault>;
export type PatientPaymentMethodSetDefaultMutationResult = Apollo.MutationResult<PatientPaymentMethodSetDefault>;
export type PatientPaymentMethodSetDefaultMutationOptions = Apollo.BaseMutationOptions<PatientPaymentMethodSetDefault, PatientPaymentMethodSetDefaultVariables>;