import React from 'react';
import styled from 'styled-components';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';

/** Custom components */
import { SingleSelect } from 'ui';

/** Styles */
import { wellbeingSelectStyles } from 'ui/form-elements/single-select/utils/styles';

const timeOptions = [
  { label: '30 days', value: '30' },
  { label: '90 days', value: '90' },
  { label: '12 months', value: '365' },
];

interface FilterProps {
  filterValues?: any;
  wellbeingTrackerQuestions?: Array<any>;
  handleFilterChange: (values: any) => void;
  wellbeingTrackerQuestionsIsLoading: boolean;
}

const WellbeingFilter = (props: FilterProps) => {
  /** Props */
  const {
    filterValues,
    handleFilterChange,
    wellbeingTrackerQuestions,
    wellbeingTrackerQuestionsIsLoading,
  } = props;

  const questions = wellbeingTrackerQuestions?.map((question) => ({
    label: question.label,
    value: question.id,
  }));
  questions?.unshift({
    label: 'All',
    value: 'all',
  });

  /**
   * @method
   * @description method will trigger each time any part of form is changed.
   */
  const handleFormChange = ({ values, modified, touched, visited }: any) => {
    handleFilterChange(values);
  };

  /**
   * @method
   * @description
   */
  const renderFilterForm = () => {
    if (wellbeingTrackerQuestionsIsLoading) {
      return null;
    }

    return (
      <FinalForm
        initialValues={filterValues}
        onSubmit={() => {}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy
              onChange={handleFormChange}
              subscription={{
                values: true,
                modified: true,
                touched: true,
                visited: true,
              }}
            />

            <div style={{ display: 'flex' }}>
              <Flex1>
                <Field
                  name="questionId"
                  options={questions}
                  component={SingleSelect}
                  customSelectStyles={wellbeingSelectStyles}
                />
              </Flex1>
              <Spacer />
              <Flex1>
                <Field
                  name="duration"
                  options={timeOptions}
                  component={SingleSelect}
                  customSelectStyles={wellbeingSelectStyles}
                />
              </Flex1>
            </div>
          </form>
        )}
      />
    );
  };

  return (
    <Container>
      <Flex1_5>Wellbeing Tracker</Flex1_5>

      <Flex1>{renderFilterForm()}</Flex1>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--content-padding);
  font-weight: 500;
  color: var(--black13);
  font-size: 1.2rem;
`;

const Spacer = styled.div`
  width: 1rem;
`;

const Flex1_5 = styled.div`
  flex: 1.5;
`;

const Flex1 = styled.div`
  flex: 1;
`;

export default WellbeingFilter;
