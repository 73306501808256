import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLatestPostsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLatestPosts = (
  { __typename: 'Query' }
  & { getLatestPosts: Array<(
    { __typename: 'PostDetailsModel' }
    & Pick<Types.PostDetailsModel, 'id' | 'createdAt' | 'title' | 'subtitle' | 'author' | 'imageUrl' | 'videoUrl' | 'postText' | 'subCategoryId' | 'type'>
    & { subCategory?: Types.Maybe<(
      { __typename: 'ResourcesSubcategoryDetailsModel' }
      & Pick<Types.ResourcesSubcategoryDetailsModel, 'id' | 'name' | 'imageUrl'>
    )> }
  )> }
);


export const GetLatestPostsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLatestPosts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLatestPosts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"subtitle"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"videoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"postText"}},{"kind":"Field","name":{"kind":"Name","value":"subCategoryId"}},{"kind":"Field","name":{"kind":"Name","value":"subCategory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}}]}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetLatestPosts__
 *
 * To run a query within a React component, call `useGetLatestPosts` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPosts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPosts({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestPosts(baseOptions?: Apollo.QueryHookOptions<GetLatestPosts, GetLatestPostsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestPosts, GetLatestPostsVariables>(GetLatestPostsDocument, options);
      }
export function useGetLatestPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestPosts, GetLatestPostsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestPosts, GetLatestPostsVariables>(GetLatestPostsDocument, options);
        }
export type GetLatestPostsHookResult = ReturnType<typeof useGetLatestPosts>;
export type GetLatestPostsLazyQueryHookResult = ReturnType<typeof useGetLatestPostsLazyQuery>;
export type GetLatestPostsQueryResult = Apollo.QueryResult<GetLatestPosts, GetLatestPostsVariables>;