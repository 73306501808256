import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientGetNotesVariables = Types.Exact<{
  filter: Types.NotesFilter;
  after?: Types.Maybe<Types.Scalars['String']>;
}>;


export type PatientGetNotes = (
  { __typename: 'Query' }
  & { patientGetNotes: (
    { __typename: 'NoteConnectionModel' }
    & { nodes: Array<(
      { __typename: 'NoteModel' }
      & PatientNote
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type PatientNote = (
  { __typename: 'NoteModel' }
  & Pick<Types.NoteModel, 'id' | 'text' | 'date' | 'shared' | 'createdAt' | 'patientId' | 'sessionMetaId' | 'shareWithTherapistId'>
);

export const PatientNote = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PatientNote"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"NoteModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"shared"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"patientId"}},{"kind":"Field","name":{"kind":"Name","value":"sessionMetaId"}},{"kind":"Field","name":{"kind":"Name","value":"shareWithTherapistId"}}]}}]} as unknown as DocumentNode;
export const PatientGetNotesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PatientGetNotes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NotesFilter"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"after"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientGetNotes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}},{"kind":"Argument","name":{"kind":"Name","value":"after"},"value":{"kind":"Variable","name":{"kind":"Name","value":"after"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PatientNote"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...PatientNote.definitions]} as unknown as DocumentNode;

/**
 * __usePatientGetNotes__
 *
 * To run a query within a React component, call `usePatientGetNotes` and pass it any options that fit your needs.
 * When your component renders, `usePatientGetNotes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientGetNotes({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePatientGetNotes(baseOptions: Apollo.QueryHookOptions<PatientGetNotes, PatientGetNotesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientGetNotes, PatientGetNotesVariables>(PatientGetNotesDocument, options);
      }
export function usePatientGetNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientGetNotes, PatientGetNotesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientGetNotes, PatientGetNotesVariables>(PatientGetNotesDocument, options);
        }
export type PatientGetNotesHookResult = ReturnType<typeof usePatientGetNotes>;
export type PatientGetNotesLazyQueryHookResult = ReturnType<typeof usePatientGetNotesLazyQuery>;
export type PatientGetNotesQueryResult = Apollo.QueryResult<PatientGetNotes, PatientGetNotesVariables>;