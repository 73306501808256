import React from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';

export interface CheckboxIndicatorProps {
  isChecked?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  className?: string;
  rootCSS?: CSSProp;
}
interface WrapperProps {
  $isChecked?: boolean;
  $isDisabled?: boolean;
  $CSS?: CSSProp;
}

function CheckboxIndicator({
  isChecked,
  isDisabled,
  onClick,
  className,
  rootCSS,
}: CheckboxIndicatorProps) {
  return (
    <Wrapper
      className={className}
      $CSS={rootCSS}
      $isChecked={isChecked}
      onClick={onClick}
      $isDisabled={isDisabled}
    />
  );
}

const Wrapper = styled.span<WrapperProps>`
  position: relative;
  flex-shrink: 0;
  width: ${getSize(22)};
  height: ${getSize(22)};
  border: 1px solid
    var(${({ $isChecked }) => ($isChecked ? '--purple' : '--purple3')});
  border-radius: ${getSize(4)};
  background: var(${({ $isChecked }) => ($isChecked ? '--purple' : '--white')});
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css<WrapperProps>`
      cursor: pointer;

      &:hover {
        ${({ $isChecked }) => !$isChecked && 'border-color: var(--purple2);'}

        &::before {
          transform: rotate(-45deg) scale(1.2);
        }
      }
    `}

  &::before {
    content: '';
    position: absolute;
    top: ${getSize(4)};
    left: ${getSize(4)};
    width: ${getSize(12)};
    height: ${getSize(7)};
    border: 2px solid white;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg);
    transition: 0.3s ease-out;
  }

  ${({ $CSS }) => $CSS}
`;

export default CheckboxIndicator;
