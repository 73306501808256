import React, { ReactNode } from 'react';
import { CSSProp } from 'styled-components';
import { Container, WizardForm } from './components';

interface QuestionnaireProps<T> {
  children: ReactNode;
  currentScreen: number;
  screensCount: number;
  setCurrentScreenIndex: (value: number) => void;
  setScreensCount: (value: number) => void;
  onSubmit: (values: T) => void;
  onFinishAssessmentOutcome: () => void;
  titles: string[];
  isQuestionnaire?: boolean;
  containerRootCSS?: CSSProp;
  wizardFormCSS?: CSSProp;
  titleCSS?: CSSProp;
  isScrollableField?: boolean;
  assessmentOutput?: boolean;
  progressCSS?: CSSProp;
  pageCountCSS?: CSSProp;
}

function Questionnaire<T>({
  children,
  currentScreen,
  screensCount,
  setCurrentScreenIndex,
  onSubmit,
  titles,
  setScreensCount,
  onFinishAssessmentOutcome,
  isQuestionnaire,
  containerRootCSS,
  isScrollableField,
  wizardFormCSS,
  assessmentOutput,
  titleCSS,
  progressCSS,
  pageCountCSS,
}: QuestionnaireProps<T>) {
  return (
    <Container
      setCurrentScreenIndex={setCurrentScreenIndex}
      isQuestionnaire={isQuestionnaire}
      screensCount={screensCount}
      currentScreen={currentScreen}
      rootCSS={containerRootCSS}
      progressCSS={progressCSS}
      assessmentOutput={assessmentOutput}
      pageCountCSS={pageCountCSS}
    >
      <WizardForm<T>
        setScreensCount={setScreensCount}
        onFinishAssessmentOutcome={onFinishAssessmentOutcome}
        titles={titles}
        onSubmit={onSubmit}
        setCurrentScreenIndex={setCurrentScreenIndex}
        isQuestionnaire={isQuestionnaire}
        currentScreen={currentScreen}
        isScrollableField={isScrollableField}
        rootCSS={wizardFormCSS}
        assessmentOutput={assessmentOutput}
        titleCSS={titleCSS}
      >
        {children}
      </WizardForm>
    </Container>
  );
}

export default Questionnaire;
