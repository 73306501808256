import React, { FormEvent, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';

import { getSize } from 'lib/utils';

import { CheckboxIndicator } from '../checkbox-indicator';

export type EditableCheckboxType = {
  completed: boolean;
  title: string;
  active: boolean;
};
export type EditableCheckboxProps = FieldRenderProps<EditableCheckboxType>;

function EditableCheckbox({
  input: {
    onChange,
    value: { completed, title },
  },
  active,
}: EditableCheckboxProps) {
  const handleIndicatorClick = useCallback(() => {
    if (active) {
      onChange({ title, completed: !completed });
    }
  }, [onChange, title, completed]);

  const handleInputChange = useCallback(
    ({ currentTarget: { value } }: FormEvent<HTMLTextAreaElement>) => {
      onChange({ completed, title: value });
    },
    [onChange, completed],
  );

  return (
    <Wrapper>
      <IndicatorStylized isChecked={completed} onClick={handleIndicatorClick} />

      <TextAreaWrapper>
        <TextArea
          value={title}
          disabled={true}
          onChange={handleInputChange}
          $isCompleted={completed}
        />
      </TextAreaWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const IndicatorStylized = styled(CheckboxIndicator)`
  margin: 2px ${getSize(15)} 0 0;
  border-radius: 4px;
  ${({ isChecked }) =>
    isChecked
      ? `border-color: #4B4B4B; background: #4B4B4B`
      : `border-color: #BEBEBE;`};
  &:hover {
    border-color: #4b4b4b;
  }
`;

const TextAreaWrapper = styled.div`
  flex-grow: 1;
`;

const TextArea = styled(TextareaAutosize)<{ $isCompleted: boolean }>`
  display: block;
  padding: 0 0 ${getSize(8)};
  width: 100%;
  font-weight: 400;
  font-size: ${getSize(13)};
  line-height: ${getSize(27)};
  color: ${({ $isCompleted }) => ($isCompleted ? '#AAAAAA' : '#676767')});
  resize: none;
  caret-color: var(--purple);

  &:focus {
    border-bottom: ${getSize(1)} solid var(--purple3);
  }
`;

export default EditableCheckbox;
