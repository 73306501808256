import * as React from 'react';

function ClockLargeIcon({ ...props }) {
  return (
    <svg
      width={90}
      height={90}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45 14.674c1.077 0 1.957-.88 1.957-1.957v-.978c0-1.076-.88-1.956-1.956-1.956-1.077 0-1.957.88-1.957 1.956v.978c0 1.076.88 1.957 1.957 1.957zM45 75.326c-1.076 0-1.956.88-1.956 1.957v.978c0 1.076.88 1.956 1.957 1.956 1.076 0 1.956-.88 1.956-1.956v-.978c0-1.076-.88-1.957-1.956-1.957zM12.717 43.044h-.978c-1.076 0-1.957.88-1.957 1.956s.88 1.956 1.957 1.956h.978c1.076 0 1.957-.88 1.957-1.956s-.88-1.956-1.957-1.956zM78.261 43.044h-.978c-1.076 0-1.957.88-1.957 1.956s.88 1.956 1.957 1.956h.978c1.076 0 1.957-.88 1.957-1.956s-.881-1.956-1.957-1.956zM22.892 20.152c-.783-.782-1.957-.782-2.74 0-.782.783-.782 1.957 0 2.74l.685.684c.392.391.88.587 1.37.587.489 0 .978-.196 1.37-.587.782-.782.782-1.956 0-2.739l-.686-.685zM20.837 66.424l-.685.685c-.782.782-.782 1.956 0 2.739.392.391.88.587 1.37.587.49 0 .978-.196 1.37-.587l.684-.685c.783-.782.783-1.956 0-2.74-.782-.782-1.956-.782-2.739 0zM67.109 20.152l-.685.685c-.783.783-.783 1.957 0 2.74.391.39.88.586 1.37.586.489 0 .978-.196 1.369-.587l.685-.685c.782-.782.782-1.956 0-2.739-.783-.782-1.957-.782-2.74 0zM46.957 44.217V26.413c0-1.076-.88-1.957-1.956-1.957-1.077 0-1.957.881-1.957 1.957V45c0 .49.196.978.587 1.37l21.424 21.423c.391.392.88.587 1.37.587.489 0 .978-.195 1.369-.587.782-.782.782-1.956 0-2.739L46.957 44.217z"
        fill="#E1DCFA"
      />
      <path
        d="M45 0C20.152 0 0 20.152 0 45s20.152 45 45 45 45-20.152 45-45S69.848 0 45 0zm0 86.087C22.304 86.087 3.913 67.696 3.913 45 3.913 22.304 22.304 3.913 45 3.913c22.696 0 41.087 18.391 41.087 41.087 0 22.696-18.391 41.087-41.087 41.087z"
        fill="#E1DCFA"
      />
    </svg>
  );
}

export default ClockLargeIcon;
