import { OnSubscriptionDataOptions } from '@apollo/client';
import { MaterialModelFragment } from 'common/query/__generated__/get-patient-session-materials';
import {
  MaterialAddedOrRemoved,
  useMaterialAddedOrRemoved,
} from 'common/subscription/__generated__/material-added-or-removed';
import {
  MaterialIndicatorsChanged,
  useMaterialIndicatorsChanged,
} from 'common/subscription/__generated__/material-indicators-changed';
import { MaterialChangedType, MaterialsFilter } from '__generated__/types';

const parseStoreNameArgs = (storeFieldName: string, fieldName: string) => {
  const argsJson = storeFieldName.split(fieldName + '(')[1];
  return JSON.parse(argsJson.slice(0, -1));
};

export const useMaterialSubscription = () => {
  function updateMaterialIndicatorsChanged({
    client: { cache },
    subscriptionData: { data },
  }: OnSubscriptionDataOptions<MaterialIndicatorsChanged>) {
    const newData = data;
    if (newData) {
      cache.modify({
        fields: {
          getMaterialIndicators: () => newData,
        },
      });
    }
  }
  function updateMaterialAddedOrRemoved({
    client: { cache },
    subscriptionData: { data },
  }: OnSubscriptionDataOptions<MaterialAddedOrRemoved>) {
    if (data) {
      const { material, type } = data.materialAddedOrRemoved;

      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          patientGetOwnMaterials(
            existingMaterialRefs,
            { storeFieldName, readField },
          ) {
            const newMaterialRef = cache.writeFragment({
              data: material,
              fragment: MaterialModelFragment,
            });

            const filter = parseStoreNameArgs(
              storeFieldName,
              'patientGetOwnMaterials',
            ).filter as MaterialsFilter;

            if (type === MaterialChangedType.Added) {
              if (
                existingMaterialRefs?.nodes?.filter(
                  (data: any) => data.__ref === newMaterialRef?.__ref,
                ).length
              ) {
                return {
                  ...existingMaterialRefs,
                  nodes: [...existingMaterialRefs.nodes],
                };
              }
              if (filter.tag === material.tag) {
                return {
                  ...existingMaterialRefs,
                  nodes: [newMaterialRef, ...existingMaterialRefs.nodes],
                };
              }
            }
            if (type === MaterialChangedType.Deleted) {
              return {
                ...existingMaterialRefs,
                nodes: existingMaterialRefs.nodes.filter((materialRef: any) => {
                  return readField('id', materialRef) !== material.id;
                }),
              };
            }

            return existingMaterialRefs;
          },
        },
      });
    }
  }

  useMaterialIndicatorsChanged({
    onSubscriptionData: updateMaterialIndicatorsChanged,
  });

  useMaterialAddedOrRemoved({
    onSubscriptionData: updateMaterialAddedOrRemoved,
  });
};
