import React, { useCallback, FC, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldRenderProps } from 'react-final-form';
import styled, { css } from 'styled-components';

import { BUCKET_TYPES, UploadFile } from 'interfaces/files';
import { getFileInfo, getSize, uploadFileToS3 } from 'lib/utils';

import { PhotoCameraIcon, VideoCameraIcon } from 'ui';
import { GoalsDeleteIcon } from 'ui/icons';
import { Loader } from 'ui/loader';
import { notifyError } from 'lib/utils/notification';
import { FileExtension, FileType } from '__generated__/types';
import { EditAvatarModal } from 'ui/edit-avatar-modal';
import avatar from 'assets/images/default-avatar.png';

type AllowedType = FileType.Image | FileType.Video;

const IMAGE_TYPES = [FileExtension.Jpg, FileExtension.Jpeg, FileExtension.Png];

const DEFAULT_DESCRIPTION =
  ' A professional profile picture with plain, white background';

export interface DropzoneProps extends FieldRenderProps<UploadFile> {
  label?: string;
  fileType: AllowedType;
  isPatient?: boolean;
  onDeleteVideo?: () => void;
  hasButton?: boolean;
  descriptionPhotoText?: string;
}

const DropzoneInput: FC<DropzoneProps> = ({
  input,
  meta,
  label,
  fileType = FileType.Image,
  isPatient,
  onDeleteVideo,
  hasButton = false,
  descriptionPhotoText = DEFAULT_DESCRIPTION,
}) => {
  const { value } = input;
  const {
    error,
    submitError,
    touched,
    dirtySinceLastSubmit,
    data,
    submitting,
  } = meta;

  const [updatingAvatar, setUpdatingAvatar] = useState<File | undefined>();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [previewFileSrc, setPreviewFileSrc] = useState('');

  useEffect(() => {
    if (submitting) {
      setErrorMessage('');
    }
  }, [submitting]);

  useEffect(() => {
    const previewSrc = value.source;
    setPreviewFileSrc(previewSrc);
  }, [previewFileSrc, value]);

  const isPhotoType = fileType === FileType.Image;
  const isVideoType = fileType === FileType.Video;

  const hasFile = Boolean(value);
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) ||
    data?.error ||
    errorMessage;

  const handleUploadFileToS3 = useCallback(
    (currentFile: File, onSuccess?: () => void) => {
      const { fileName, fileExtension } = getFileInfo(currentFile);

      async function setFileToForm(source: string) {
        const newFile = {
          source,
          name: fileName,
          sizeInKb: currentFile.size,
          fileType: fileType?.toUpperCase(),
          extension: fileExtension.toUpperCase(),
        };

        input.onChange(newFile);
      }
      if (currentFile) {
        const apiUrl = `aws/signed-url?fileType=${fileType.toLowerCase()}&fileName=${fileName}&extension=${fileExtension}&bucketType=${
          BUCKET_TYPES.REPRESENTATION
        }`;

        setIsLoadingFile(true);
        uploadFileToS3({ apiUrl, file: currentFile as File })
          .then((file) => {
            setPreviewFileSrc(file);
            setFileToForm(file);
            onSuccess?.();
          })
          .catch((error) => notifyError({ text: error?.message }))
          .finally(() => {
            setIsLoadingFile(false);
          });
      }
    },
    [],
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const incomingFile: File = acceptedFiles[0];
      const { fileExtension } = getFileInfo(incomingFile);
      let currentFile: File | undefined;
      if (isPhotoType) {
        if (IMAGE_TYPES.includes(fileExtension.toUpperCase() as any)) {
          setUpdatingAvatar(incomingFile);
          setErrorMessage('');
        } else {
          setErrorMessage('File should be format: .jpg, .jpeg, .png');
        }
      } else if (isVideoType) {
        if (fileExtension === FileExtension.Mp4.toLowerCase()) {
          currentFile = incomingFile;
          setErrorMessage('');
        } else {
          setErrorMessage('The file must be in the format: .mp4');
        }
      }

      currentFile && handleUploadFileToS3(currentFile);
    },
    [isPhotoType, isVideoType, handleUploadFileToS3],
  );

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop });

  const renderFilePreview = useCallback(() => {
    if (previewFileSrc) {
      if (isPhotoType) {
        return <img src={previewFileSrc} alt="Uploaded file" />;
      } else if (isVideoType) {
        return <video src={previewFileSrc} />;
      }
    } else {
      if (isPhotoType) {
        return hasButton ? (
          <img src={avatar} alt="Avatar" />
        ) : (
          <PhotoCameraIcon />
        );
      } else if (isVideoType) {
        return <VideoCameraIcon />;
      }
    }

    return null;
  }, [hasButton, isPhotoType, isVideoType, previewFileSrc]);

  const handleDeleteVideo = () => {
    onDeleteVideo && onDeleteVideo();
    setPreviewFileSrc('');
  };

  const renderFileName = useCallback((file: UploadFile) => {
    const fileName = file.name;
    const fileExtenstion = file.extension;
    const fileNameWithExt = `${fileName}.${fileExtenstion}`;

    return fileNameWithExt.length > 12
      ? `${fileName.slice(0, 9)}....${fileExtenstion}`
      : fileNameWithExt;
  }, []);

  const containerProps = hasFile ? {} : getRootProps();
  const inputProps = isLoadingFile ? {} : getInputProps();

  return (
    <div>
      <Wrapper
        {...containerProps}
        $hasCursorPointer={hasButton || hasFile || isLoadingFile}
        $hasButton={hasButton}
      >
        <Input
          {...inputProps}
          onChange={({ target: { files } }) => onDrop(files)}
        />

        <FilePreview $hasFile={hasFile} $hasButton={hasButton}>
          {renderFilePreview()}
          {hasFile && (
            <>
              {isVideoType && (
                <DeleteLabel onClick={handleDeleteVideo}>
                  <DeleteIcon />
                </DeleteLabel>
              )}
              {!isPatient && (
                <EditLabel {...getRootProps()} $hasButton={hasButton}>
                  Edit
                </EditLabel>
              )}
            </>
          )}
          {isLoadingFile && <LoaderStylized />}
        </FilePreview>

        <InfoWrapper $hasButton={hasButton}>
          {isPhotoType && isPatient && (
            <>
              <Title>Upload your photo</Title>
              <MessageForPatient>
                <p>Why not personalize your profile with a photo or image?</p>
                <p>This will only be visible to your therapist.</p>
              </MessageForPatient>
            </>
          )}

          {/* <Label $isPhotoType={isPhotoType} $hasButton={hasButton}>
            {value.name ? renderFileName(value) : label}
            {isLoadingFile && <LoaderStylized />}
          </Label> */}

          {!isPatient && (
            <Description $hasButton={hasButton} $hasFile={hasFile}>
              {/* {!hasFile && */}
              {isPhotoType
                ? descriptionPhotoText
                : 'A professional video (<3 mins) to introduce yourself & how you work'}
            </Description>
          )}

          {hasButton && !hasFile && (
            <StyledButton type="button">+ Add photo</StyledButton>
          )}

          {hasButton && hasFile && (
            <StyledButton {...getRootProps()} type="button">
              Edit photo
            </StyledButton>
          )}

          <EditAvatarModal
            selectedImage={updatingAvatar}
            onClose={() => setUpdatingAvatar(undefined)}
            onUpload={handleUploadFileToS3}
            isLoadingFile={isLoadingFile}
          />
        </InfoWrapper>
      </Wrapper>

      {hasError && (
        <ErrorText>
          {errorMessage || error || submitError || data?.error}
        </ErrorText>
      )}
    </div>
  );
};

const Wrapper = styled.div<{
  $hasCursorPointer: boolean;
  $hasButton?: boolean;
}>`
  position: relative;
  padding: ${getSize(38)} ${getSize(24)} ${getSize(43)} ${getSize(33)};
  background-color: var(--purple11);
  border-radius: ${getSize(8)};
  display: flex;
  cursor: ${({ $hasCursorPointer }) =>
    $hasCursorPointer ? `default` : `pointer`};

  ${({ $hasButton }) =>
    $hasButton &&
    css`
      padding: ${getSize(33)} ${getSize(46)} ${getSize(37)} ${getSize(33)};
      background-color: var(--light-gray);
      align-items: center;
    `};
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label<{ $isPhotoType?: boolean; $hasButton?: boolean }>`
  display: flex;
  align-items: center;
  ${({ $isPhotoType, $hasButton }) =>
    $isPhotoType && !$hasButton && `margin: 0 0 ${getSize(8)};`};
  font-weight: ${({ $hasButton }) => ($hasButton ? 600 : 400)};
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
  cursor: inherit;
`;

const LoaderStylized = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Button = styled.button`
  color: var(--white);
  font-size: ${getSize(13)};
  line-height: ${getSize(20)};
  font-weight: 700;
  letter-spacing: 0.72px;
  border-radius: ${getSize(52)};
  background-color: var(--purple21);
  width: fit-content;
  padding: ${getSize(7)} ${getSize(25)};
`;

const Description = styled.p<{ $hasButton?: boolean; $hasFile?: boolean }>`
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--gray2);

  ${({ $hasButton }) =>
    $hasButton &&
    css<{ $hasFile?: boolean }>`
      font-size: ${getSize(11)};
      line-height: ${getSize(18)};
      color: var(--gray7);
      margin-bottom: ${({ $hasFile }) => ($hasFile ? 0 : getSize(0))};
      margin-top: ${({ $hasFile }) => ($hasFile ? 0 : getSize(1))};
    `};
`;

const FilePreview = styled.div<{ $hasFile?: boolean; $hasButton?: boolean }>`
  position: relative;
  width: ${getSize(96)};
  height: ${getSize(96)};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: ${getSize(50)};
  border-radius: 50%;
  ${({ $hasFile, $hasButton }) =>
    !$hasFile && !$hasButton && `border: ${getSize(1)} dashed var(--gray2)`};
  overflow: hidden;

  ${({ $hasButton }) =>
    $hasButton &&
    css`
      width: ${getSize(96)};
      height: ${getSize(96)};
      margin-right: ${getSize(55)};
    `};

  & > img,
  & > video {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &:hover label {
    opacity: 1;
  }
`;

const InfoWrapper = styled.div<{ $hasButton?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ $hasButton }) =>
    $hasButton &&
    css`
      width: 100%;
    `}
`;

const EditLabel = styled.label<{ $hasButton?: boolean }>`
  /* width: ${({ $hasButton }) => ($hasButton ? getSize(96) : getSize(74))}; */
  width: 100%;
  height: ${getSize(21)};
  position: absolute;
  bottom: 0;
  font-size: ${getSize(14)};
  background-color: var(--black-opacity);
  color: var(--white);
  text-align: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
`;

const DeleteLabel = styled(EditLabel)`
  bottom: auto;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteIcon = styled(GoalsDeleteIcon)`
  width: ${getSize(12)};
  height: ${getSize(12)};
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

const MessageForPatient = styled.div`
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(17)};
  margin-bottom: ${getSize(5)};
`;

const Title = styled.h2`
  font-family: Poppins;
  font-size: ${getSize(13)};
  font-weight: 500;
  line-height: ${getSize(24)};
  margin-bottom: 2px;
  color: black;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
  font-weight: 600;
`;

export default DropzoneInput;
