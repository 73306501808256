import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { getSize } from 'lib/utils';

import { Tabs } from 'features/common';
import { SessionItem, SessionScrollList } from './components';
import { EmptySession, Link, LocationMapModal } from 'ui';
import {
  SessionFragment,
  SessionRequestPreviewFragment,
} from 'features/patient/dashboard/query/__generated__/get-patient-upcoming-sessions';
import { Role } from '__generated__/types';
import { OfficeLocationsInformation } from 'features/find-specialist/specialist-details/query/__generated__/get-therapist-details-query';

export interface SessionsSectionProps {
  role: Role;
  upcomingSessions: (SessionFragment | SessionRequestPreviewFragment)[];
  pastSessions: SessionFragment[];
  canceledSessions: SessionFragment[];
  isSmall?: boolean;
  className?: string;
  onCancelClick?: (session: SessionFragment) => void;
  onStartClick?: (session: SessionFragment) => void;
  onCancelRequest?: (requestId: string) => void;
  fetchMoreUpcoming?: () => Promise<any>;
  fetchMorePast?: () => Promise<any>;
  fetchMoreCanceled?: () => Promise<any>;
  hasMoreUpcoming?: boolean;
  hasMorePast?: boolean;
  hasMoreCanceled?: boolean;
  showAllLink?: string;
  defaultActiveTab?: string | null;
  onTabClick?: (newTab: string) => void;
  onOpenRescheduleModal?: () => void;
  onCloseRescheduleModal?: () => void;
  rescheduleModalVisible?: boolean;
}

export enum SESSIONS_TABS {
  UPCOMING = 'upcoming',
  PAST = 'past',
  CANCELED = 'canceled',
}

const { UPCOMING, PAST, CANCELED } = SESSIONS_TABS;
const TABS = {
  [UPCOMING]: 'Upcoming',
  [PAST]: 'Session History',
  [CANCELED]: 'Canceled History',
};

function SessionsSection({
  role,
  upcomingSessions,
  pastSessions,
  canceledSessions,
  isSmall,
  className,
  onCancelClick,
  onStartClick,
  fetchMoreUpcoming,
  fetchMorePast,
  fetchMoreCanceled,
  hasMoreUpcoming,
  hasMorePast,
  hasMoreCanceled,
  showAllLink,
  defaultActiveTab,
  onTabClick,
  onCancelRequest,
  onOpenRescheduleModal,
  onCloseRescheduleModal,
  rescheduleModalVisible,
}: SessionsSectionProps) {
  const [activeTab, setActiveTab] = useState<string>(
    defaultActiveTab &&
      Object.values(SESSIONS_TABS).includes(defaultActiveTab as SESSIONS_TABS)
      ? defaultActiveTab
      : UPCOMING,
  );
  const [officeLocation, setOfficeLocation] = useState<
    OfficeLocationsInformation | undefined | null
  >();

  const isUpcomingTabActive = activeTab === UPCOMING;
  const isPastTabActive = activeTab === PAST;
  const isCanceledTabActive = activeTab === CANCELED;

  const isTherapist = role === Role.Therapist;

  const isShowAllLinkVisible =
    (isUpcomingTabActive && upcomingSessions.length > 0) ||
    (isPastTabActive && pastSessions.length > 0) ||
    (isCanceledTabActive && canceledSessions.length > 0);

  const currentSessions = useMemo(() => {
    if (isUpcomingTabActive && upcomingSessions?.length !== 0) {
      return upcomingSessions;
    } else if (isPastTabActive && pastSessions?.length !== 0) {
      return pastSessions;
    } else if (isCanceledTabActive) {
      return canceledSessions;
    } else {
      return [];
    }
  }, [
    upcomingSessions,
    pastSessions,
    canceledSessions,
    isUpcomingTabActive,
    isPastTabActive,
    isCanceledTabActive,
  ]);

  const areTherePastSession = pastSessions?.length
    ? !!pastSessions.length
    : false;

  const linkId = (): string => {
    if (isCanceledTabActive) {
      return 'canceledList';
    } else if (isPastTabActive) {
      return 'pastList';
    } else {
      return 'upcomingList';
    }
  };

  function handleTabClick(newTab: string) {
    setActiveTab(newTab);
    onTabClick?.(newTab);
  }

  useEffect(() => {
    if (!areTherePastSession) {
      handleTabClick(TABS[UPCOMING].toLowerCase());
    }
  }, [areTherePastSession]);

  return (
    <Wrapper className={className}>
      {areTherePastSession && (
        <TabsStylized
          tabs={TABS}
          activeTab={activeTab}
          isSmall={isSmall}
          $isSmall={isSmall}
          onTabClick={handleTabClick}
        />
      )}
      {currentSessions && currentSessions.length === 0 ? (
        <EmptyWrapper>
          <EmptySession />
          <EmptyText>
            You don’t have
            <br /> any upcoming sessions
          </EmptyText>
        </EmptyWrapper>
      ) : isSmall ? (
        <SessionList $isSmall={isSmall} id={linkId()} key={linkId()}>
          {currentSessions?.map((session) => {
            return (
              <SessionItem
                key={session.startDateUtc}
                session={session}
                isPast={isPastTabActive}
                isSmall={isSmall}
                isTherapist={isTherapist}
                onCancelClick={() =>
                  onCancelClick?.(session as SessionFragment)
                }
                onStartClick={() => onStartClick?.(session as SessionFragment)}
                onCancelRequest={() => {
                  onCancelRequest?.(session.id);
                }}
              />
            );
          })}
        </SessionList>
      ) : (
        <SessionScrollList
          upcomingSessions={upcomingSessions}
          pastSessions={pastSessions}
          canceledSessions={canceledSessions}
          onCancelClick={onCancelClick}
          onStartClick={onStartClick}
          onCancelRequest={onCancelRequest}
          hasMorePast={hasMorePast}
          hasMoreUpcoming={hasMoreUpcoming}
          hasMoreCanceled={hasMoreCanceled}
          fetchMorePast={fetchMorePast}
          fetchMoreCanceled={fetchMoreCanceled}
          fetchMoreUpcoming={fetchMoreUpcoming}
          isUpcomingTabActive={isUpcomingTabActive}
          isPastTabActive={isPastTabActive}
          isCanceledTabActive={isCanceledTabActive}
          isTherapist={isTherapist}
          onOpenRescheduleModal={onOpenRescheduleModal}
          onCloseRescheduleModal={onCloseRescheduleModal}
          rescheduleModalVisible={rescheduleModalVisible}
        />
      )}

      {showAllLink && isShowAllLinkVisible && (
        <ShowAllLink to={`${showAllLink}/?activeTab=${activeTab}`}>
          Show all
        </ShowAllLink>
      )}

      <LocationMapModal
        isSession
        isVisible={Boolean(officeLocation)}
        onClose={() => setOfficeLocation(undefined)}
        locations={officeLocation ? [officeLocation] : []}
        currentAddressIndex={0}
        addressesCount={1}
        addressTitle={officeLocation?.fullAddress}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const TabsStylized = styled(Tabs)<{ $isSmall?: boolean }>`
  margin: 0 0 ${({ $isSmall }) => getSize($isSmall ? 16 : 23)};
`;

const EmptyText = styled.p`
  margin: 0 0 ${getSize(6)};
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--black6);
  font-weight: 500;
  text-align: center;
`;

const SessionList = styled.ul<{ $isSmall?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSize(8)};
  max-height: ${getSize(580)};
  overflow: visible;

  ${({ $isSmall }) => (!$isSmall ? `overflow: auto;` : ``)}
`;

const ShowAllLink = styled(Link)`
  margin: auto auto 0;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: ${getSize(122)};
`;

export default SessionsSection;
