import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelTherapistSessionVariables = Types.Exact<{
  input: Types.SessionCancelInput;
}>;


export type CancelTherapistSession = (
  { __typename: 'Mutation' }
  & { sessionCancel: (
    { __typename: 'SessionCommonPayload' }
    & Pick<Types.SessionCommonPayload, 'success'>
    & { userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const CancelTherapistSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelTherapistSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SessionCancelInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sessionCancel"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type CancelTherapistSessionMutationFn = Apollo.MutationFunction<CancelTherapistSession, CancelTherapistSessionVariables>;

/**
 * __useCancelTherapistSession__
 *
 * To run a mutation, you first call `useCancelTherapistSession` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTherapistSession` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTherapistSession, { data, loading, error }] = useCancelTherapistSession({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelTherapistSession(baseOptions?: Apollo.MutationHookOptions<CancelTherapistSession, CancelTherapistSessionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTherapistSession, CancelTherapistSessionVariables>(CancelTherapistSessionDocument, options);
      }
export type CancelTherapistSessionHookResult = ReturnType<typeof useCancelTherapistSession>;
export type CancelTherapistSessionMutationResult = Apollo.MutationResult<CancelTherapistSession>;
export type CancelTherapistSessionMutationOptions = Apollo.BaseMutationOptions<CancelTherapistSession, CancelTherapistSessionVariables>;