import { useEffect } from 'react';
import { config, slashAgnostic } from 'lib/utils';
import { useUser } from 'common/query/__generated__/get-user';
import { useApolloClient } from '@apollo/client';
import {
  CacheFieldOption,
  evictQueryCache,
} from 'lib/apollo-client/utils/evict-cache-helper';

enum SESSION_EVENT_TYPE {
  SESSION_TYPE = 'SESSION_TYPE',
  SESSION_REQUEST_TYPE = 'SESSION_REQUEST_TYPE',
  SESSION_ACTION_TYPE = 'SESSION_ACTION_TYPE',
}
interface SessionEventData {
  id: string;
  type: SESSION_EVENT_TYPE;
}

const PATIENT_SESSION_FIELDS_NAMES = ['patientUpcomingSessions'];
const THERAPIST_ACTION_FIELDS_NAMES = ['therapistSessionsNeededAction'];
const THERAPIST_SESSION_FIELDS_NAMES: CacheFieldOption[] = [
  'therapistSessions',
  'therapistUpcomingSessions',
  'therapistUpcomingSessionsByPatientId',
];

function getEvictOptions({ type }: SessionEventData, therapistId?: string) {
  const { SESSION_TYPE, SESSION_REQUEST_TYPE, SESSION_ACTION_TYPE } =
    SESSION_EVENT_TYPE;

  if (therapistId) {
    if (type === SESSION_TYPE || type === SESSION_REQUEST_TYPE) {
      return THERAPIST_SESSION_FIELDS_NAMES.concat({
        fieldName: 'sessionRequests',
        id: `TherapistModel:${therapistId}`,
      });
    } else if (type === SESSION_ACTION_TYPE) {
      return THERAPIST_ACTION_FIELDS_NAMES;
    }
  }

  return PATIENT_SESSION_FIELDS_NAMES;
}

export function useRealTimeSessionsQueriesUpdate(isTherapist?: boolean) {
  const { data: userData } = useUser();
  const { cache: apolloCache } = useApolloClient();

  const userId = isTherapist
    ? userData?.me?.therapist?.id
    : userData?.me?.patient?.id;

  useEffect(() => {
    let eventsSubscription: EventSource | null = null;

    if (userId) {
      eventsSubscription = new EventSource(
        slashAgnostic(config.API_URL, `realtime-events?id=${userId}`),
      );

      eventsSubscription.onmessage = ({ data }) => {
        const eventData = JSON.parse(data);
        const evictOptions = getEvictOptions(
          eventData,
          isTherapist ? userId : undefined,
        );

        evictQueryCache(apolloCache, evictOptions);
      };
      eventsSubscription.onerror = () => {
        console.error('Session event connection lost');
      };
    }

    return () => eventsSubscription?.close();
  }, [userId, isTherapist, apolloCache]);
}
