import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function YahooIcon({ ...rest }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 22"
      {...rest}
    >
      <path
        fill="#720E9E"
        d="M11 0c6.075 0 11 4.925 11 11 0 6.076-4.925 11-11 11-6.076 0-11-4.925-11-11S4.925 0 11 0z"
      />
      <path
        fill="#fff"
        d="M16.037 8.683l.011-.002.365-.406h-.018l.038-.061H11.33l.196.698h1.393l-2.344 2.17c-.479-.699-1.6-2.303-2.382-3.506h1.454v-.495l.02-.142-.02-.003v-.058h-5.52v.698h1.726c.67.554 3.576 4.082 3.682 4.419.043.315.103 2.178-.022 2.318-.15.217-1.723.1-2 .12l-.01.684c.506.015 2.024 0 2.536 0 1.01 0 2.777-.026 3.026-.007l.03-.647-2.03-.03c-.043-.294-.09-2.147-.047-2.349.191-.586 3.308-3.013 3.629-3.105l.302-.068h.942l.147-.228zm-.762 4.84l.883.068 1.029-3.774c-.172-.006-1.733-.146-1.936-.184l.024 3.89zm-.153.606l.005.922.447.037.482.034.142-.902-.509-.024-.567-.067z"
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(22)};
  height: ${getSize(22)};
`;

export default YahooIcon;
