import { getSize } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';
import { UploadButton } from 'ui';

export interface TherapistTitleProps {
  onUploadClick?: () => void;
}

function TherapistTitle({ onUploadClick }: TherapistTitleProps) {
  return (
    <Wrapper>
      <Title>Materials</Title>
      <UploadButton onClick={() => onUploadClick?.()} />
    </Wrapper>
  );
}

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${getSize(32)} ${getSize(24)};
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};
  color: var(--black3);
`;

export default TherapistTitle;
