import { useMemo } from 'react';
import { useProfessionalTitles } from 'common/query/__generated__/get-professional-titles';
import { sortOptionsByAlphaBet, transformToOptions } from 'lib/utils';

export function useProfessionalTitlesOptions() {
  const professionalTitlesQuery = useProfessionalTitles();
  return useMemo(
    () =>
      sortOptionsByAlphaBet(
        transformToOptions(professionalTitlesQuery.data?.professionalTitles),
      ),
    [professionalTitlesQuery.data],
  );
}
