import React from 'react';

function AttachedDocIcon({ ...rest }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.21094 30H23.7891C25.243 30 26.4258 28.8172 26.4258 27.3633V8.78906H20.2734C18.8195 8.78906 17.6367 7.60629 17.6367 6.15234V0H6.21094C4.75699 0 3.57422 1.18277 3.57422 2.63672V27.3633C3.57422 28.8172 4.75699 30 6.21094 30ZM9.72656 12.3633H20.2734C20.7592 12.3633 21.1523 12.7564 21.1523 13.2422C21.1523 13.728 20.7592 14.1211 20.2734 14.1211H9.72656C9.24076 14.1211 8.84766 13.728 8.84766 13.2422C8.84766 12.7564 9.24076 12.3633 9.72656 12.3633ZM9.72656 15.8789H20.2734C20.7592 15.8789 21.1523 16.272 21.1523 16.7578C21.1523 17.2436 20.7592 17.6367 20.2734 17.6367H9.72656C9.24076 17.6367 8.84766 17.2436 8.84766 16.7578C8.84766 16.272 9.24076 15.8789 9.72656 15.8789ZM9.72656 19.3945H20.2734C20.7592 19.3945 21.1523 19.7876 21.1523 20.2734C21.1523 20.7592 20.7592 21.1523 20.2734 21.1523H9.72656C9.24076 21.1523 8.84766 20.7592 8.84766 20.2734C8.84766 19.7876 9.24076 19.3945 9.72656 19.3945ZM9.72656 22.9102H16.7578C17.2436 22.9102 17.6367 23.3033 17.6367 23.7891C17.6367 24.2749 17.2436 24.668 16.7578 24.668H9.72656C9.24076 24.668 8.84766 24.2749 8.84766 23.7891C8.84766 23.3033 9.24076 22.9102 9.72656 22.9102Z"
        fill="#A79ADC"
      />
      <path
        d="M20.2734 7.0319H25.9108L19.3945 0.515625V6.15299C19.3945 6.63791 19.7885 7.0319 20.2734 7.0319Z"
        fill="#A79ADC"
      />
    </svg>
  );
}

export default AttachedDocIcon;
