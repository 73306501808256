import lodashSet from 'lodash.set';
import { UserError } from '__generated__/types';

export function getFormSubmitError(errors: UserError[]) {
  return errors.reduce((acc: Record<string, string[]>, currentError) => {
    if (currentError.field === 'base') {
      return lodashSet(acc, 'FORM_ERROR', currentError.messages);
    } else {
      return lodashSet(acc, currentError.field, currentError.messages);
    }
  }, {});
}

export function getDataWithoutTypename(data: any) {
  if (!data) return;
  const cloneData = { ...data };
  delete cloneData.__typename;

  return cloneData;
}
