import React from 'react';

function LocationMiniIcon({ ...rest }) {
  return (
    <svg width={13} height={13} viewBox="0 0 13 13" fill="#B4B0BB" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.333 6L13 0 7 12.667 5.667 7.333.333 6z"
      />
    </svg>
  );
}

export default LocationMiniIcon;
