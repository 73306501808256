import { FirstLoginType, UserModel } from '__generated__/types';
import { userMeInfoVar } from '../apollo-client/reactive-variables';

export const updateUserMeInfo = (me?: UserModel | null) => {
  if (!me) {
    return;
  }

  return userMeInfoVar({
    userId: me.id,
    role: me.role,
    firstName: me.firstName,
    b2bRoles: me.b2bRoles,
    isB2B: Boolean(me.companyId),
    isTherapist: Boolean(me.therapist?.id),
    therapistId: me.therapist?.id || null,
    isFirstLogin: me.firstLogin === FirstLoginType.FirstLogin,
  });
};
