import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'ui';

const AppItem: FC<AppItemProps> = ({ title, iconUri, appUrl, buttonTitle }) => {
  return (
    <Root>
      <InfoWrapper>
        <AvatarImg src={iconUri} alt="App icon" />
        <AppTitle>{title}</AppTitle>
      </InfoWrapper>

      <a href={appUrl}>
        <StyledButton theme="primary">{buttonTitle}</StyledButton>
      </a>
    </Root>
  );
};

interface AppItemProps {
  title: string;
  buttonTitle: string;
  iconUri: string;
  appUrl: string;
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
`;

const AvatarImg = styled.img`
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 50%;
`;

const AppTitle = styled.p`
  display: block;

  margin-left: 14px;

  font-size: ${getSize(21)};
  font-weight: 500;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  padding: 10px 14px;

  border-radius: 20px;

  font-size: ${getSize(21)};
  font-weight: 400;
`;

export default AppItem;
