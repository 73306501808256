import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { Modal, UserAvatar, CalendarWithDaysIcon } from 'ui';
import { PATIENT_FIND_SPECIALIST_ROUTES } from 'lib/routes';
import { usePriceOptions } from 'hooks';

interface SessionDetailsModalProps {
  therapistName: string;
  therapistId: string;
  when: string;
  sessionType: string;
  duration: number;
  isVisible: boolean;
  avatarUrl?: string;
  onClose: () => void;
  isPast?: boolean;
  onCancelRequest?: () => void;
  isRequest?: boolean;
  onOpenRescheduleModal?: () => void;
  isSessionWithin48h?: boolean;
  isDisableCancelButton?: boolean;
  profession: string;
}

const SessionDetailsModal: FC<SessionDetailsModalProps> = ({
  isVisible,
  therapistName,
  therapistId,
  when,
  duration,
  avatarUrl,
  sessionType,
  onClose,
  isPast,
  onCancelRequest,
  isRequest,
  onOpenRescheduleModal,
  isSessionWithin48h,
  isDisableCancelButton,
  profession,
}) => {
  const history = useHistory();
  const handleOpenProfile = (e: React.MouseEvent) => {
    e.stopPropagation();
    const selectionText = window.getSelection()?.toString().length;

    if (selectionText) {
      return;
    }

    history.push({
      pathname:
        PATIENT_FIND_SPECIALIST_ROUTES.SPECIALIST_DETAILS.pathBuild(
          therapistId,
        ),
    });
  };

  const priceOptions = usePriceOptions();

  const durationWithPrice = priceOptions.find(
    (option) => option.value === duration.toString(),
  )?.label;

  return (
    <Modal
      isVisible={isVisible}
      paperCSS={paperCSS}
      headerCSS={headerCSS}
      titleCSS={titleCSS}
      title="Session Details"
      onClose={onClose}
    >
      <Container>
        <UserAvatar avatarUrl={avatarUrl} rootCSS={rootCSS} />

        <TherapistName>{therapistName}</TherapistName>

        {Boolean(profession) && (
          <SectionWrapper>
            <Profession>{profession}</Profession>
            <ViewProfileButton onClick={handleOpenProfile}>
              <StyledText>View Profile</StyledText>
            </ViewProfileButton>
          </SectionWrapper>
        )}

        <SectionWrapper>
          <BorderWrapper>
            <Text>When:</Text>
            <Flex>
              <StyledCalendar />
              <SubText>{when}</SubText>
            </Flex>
          </BorderWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <BorderLeft>
            <Text>Duration:</Text>
            <Flex>
              <SubText>{durationWithPrice}</SubText>
            </Flex>
          </BorderLeft>
          <BorderRight>
            <Text>How:</Text>
            <Flex>
              <SubText>{sessionType}</SubText>
            </Flex>
          </BorderRight>
        </SectionWrapper>

        {isPast ? (
          <StyledButton onClick={handleOpenProfile}>Book session</StyledButton>
        ) : (
          <>
            {!isDisableCancelButton && (
              <StyledButton onClick={onCancelRequest}>
                Cancel session
              </StyledButton>
            )}

            {!isRequest && !isDisableCancelButton && (
              <StyledButton
                disabled={isSessionWithin48h}
                onClick={onOpenRescheduleModal}
              >
                Reschedule
              </StyledButton>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
};

export default SessionDetailsModal;

const headerCSS = css`
  padding: 22px 23px 19px 27px;
  border-bottom: 1px solid #f1f1f1;
`;

const paperCSS = css`
  padding: 0;
  width: ${getSize(582)};
`;

const titleCSS = css`
  font-family: Poppins;
  font-size: ${getSize(18)};
  font-weight: 500;
  line-height: ${getSize(27)};
`;

const Container = styled.div`
  padding: ${getSize(10)} ${getSize(44)} ${getSize(55)} ${getSize(43)};
`;

const rootCSS = css`
  width: ${getSize(100)};
  height: ${getSize(100)};
  margin-bottom: 5px;
`;

const TherapistName = styled.p`
  font-family: Poppins;
  font-size: ${getSize(20)};
  font-weight: 500;
  line-height: ${getSize(44)};
`;

const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
`;

const Profession = styled.p`
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: var(--purple);
  font-weight: 500;
`;

const pseudo = css`
  content: '';
  display: block;
  background: var(--purple);
  border-radius: 1px;
  width: 2px;
  height: 10px;
  position: absolute;
  right: -10px;
`;

const StyledText = styled(Profession)`
  position: relative;
  &:before {
    ${pseudo};
    right: 0;
    top: 50%;
    transform: translate(10px, -20%) rotate(45deg);
  }
  &:after {
    ${pseudo};
    right: -10px;
    top: 50%;
    transform: translate(0, -80%) rotate(-45deg);
  }
`;

const BorderWrapper = styled.div`
  width: 100%;
  border: 1px solid var(--gray37);
  border-radius: 5px;
  padding: 13px 0 13px 20px;
`;

const BorderLeft = styled.div`
  width: 100%;
  border: 1px solid var(--gray37);
  border-right: 0.5px;
  border-radius: 5px 0 0 5px;
  padding: 13px 0 13px 20px;
`;

const BorderRight = styled.div`
  width: 100%;
  border: 1px solid var(--gray37);
  border-radius: 0 5px 5px 0;
  padding: 13px 0 13px 20px;
`;

const Text = styled.p`
  font-family: Poppins;
  font-size: ${getSize(13)};
  font-weight: 400;
  line-height: ${getSize(26)};
  color: var(--black3);
`;
const SubText = styled.p`
  font-family: Poppins;
  font-size: ${getSize(14)};
  font-weight: 600;
  line-height: ${getSize(28)};
`;
const StyledCalendar = styled(CalendarWithDaysIcon)`
  margin-right: 10px;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const ViewProfileButton = styled.button`
  margin-right: 15px;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 14px 12px 13px 18px;
  height: 45px;
  color: #1b1b1b;
  font-family: Poppins;
  font-size: ${getSize(12)};
  font-weight: 600;
  line-height: ${getSize(12)};
  border: 1px solid var(--gray37);

  margin-bottom: 13px;
  border-radius: 8px;
  text-align: left;
  position: relative;
  &:before {
    ${pseudo};
    top: 13px;
    bottom: 14px;
    right: 15px;
    transform: rotate(-45deg);
  }
  &:after {
    ${pseudo};
    bottom: 14px;
    right: 15px;
    transform: rotate(45deg);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;
