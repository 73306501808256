import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetUserRoleVariables = Types.Exact<{
  role: Types.Role;
}>;


export type SetUserRole = (
  { __typename: 'Mutation' }
  & { setUserRole?: Types.Maybe<(
    { __typename: 'UserRoleModel' }
    & Pick<Types.UserRoleModel, 'role'>
  )> }
);


export const SetUserRoleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetUserRole"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"role"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Role"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setUserRole"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"role"},"value":{"kind":"Variable","name":{"kind":"Name","value":"role"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode;
export type SetUserRoleMutationFn = Apollo.MutationFunction<SetUserRole, SetUserRoleVariables>;

/**
 * __useSetUserRole__
 *
 * To run a mutation, you first call `useSetUserRole` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserRole` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserRole, { data, loading, error }] = useSetUserRole({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useSetUserRole(baseOptions?: Apollo.MutationHookOptions<SetUserRole, SetUserRoleVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserRole, SetUserRoleVariables>(SetUserRoleDocument, options);
      }
export type SetUserRoleHookResult = ReturnType<typeof useSetUserRole>;
export type SetUserRoleMutationResult = Apollo.MutationResult<SetUserRole>;
export type SetUserRoleMutationOptions = Apollo.BaseMutationOptions<SetUserRole, SetUserRoleVariables>;