import React from 'react';
import styled from 'styled-components';
import { getSize } from '../../../../../../lib/utils';

interface CategoryItemProps {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

const CategoryItem = (props: CategoryItemProps) => {
  const { name, isActive, onClick } = props;

  return (
    <Container isActive={isActive} onClick={onClick}>
      <Title> {name} </Title>
    </Container>
  );
};

const Container = styled.div<{ isActive?: boolean }>`
  display: flex;
  width: ${getSize(400)};
  cursor: pointer;
  padding: 6px 15px;
  border-radius: 30px;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  text-overflow: ellipsis;
  background: ${(props) =>
    props.isActive ? 'var(--purple)' : 'var(--purple3)'};
  color: ${(props) => (props.isActive ? 'var(--white)' : 'var(--purple)')};
  &:not(:first-child) {
    margin-left: 5px;
  }
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

export default CategoryItem;
