import React from 'react';

function CalendarSecondaryIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="#E5E4E8"
      viewBox="0 0 24 25"
      {...rest}
    >
      <g clipPath="url(#calendar-secondary-icon-svg)">
        <path d="M20.906 2.06H18.75V.933a.75.75 0 00-1.5 0V2.06H6.75V.934a.75.75 0 00-1.5 0V2.06H3.094A3.097 3.097 0 000 5.153v15.938a3.097 3.097 0 003.094 3.093h17.812A3.097 3.097 0 0024 21.091V5.153a3.097 3.097 0 00-3.094-3.094zM22.5 21.09c0 .88-.713 1.595-1.594 1.595H3.094c-.88 0-1.594-.714-1.594-1.594V8.669c0-.13.105-.235.234-.235h20.532c.129 0 .234.105.234.235V21.09z" />
      </g>
      <defs>
        <clipPath id="calendar-secondary-icon-svg">
          <path fill="#fff" d="M0 0h24v24H0z" transform="translate(0 .184)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CalendarSecondaryIcon;
