import React from 'react';

function GoogleIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <g clipPath="url(#google-icon-svg)">
        <path
          fill="#fff"
          d="M12 23.999c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"
        />
        <path
          fill="#167EE6"
          d="M19.396 10.609h-6.527a.522.522 0 00-.521.521v2.085c0 .288.233.522.521.522h3.676a4.907 4.907 0 01-2.112 2.475L16 18.925c2.514-1.454 4-4.005 4-6.86 0-.407-.03-.698-.09-1.025a.524.524 0 00-.514-.431z"
        />
        <path
          fill="#12B347"
          d="M12 16.87a4.869 4.869 0 01-4.212-2.437l-2.713 1.563A7.99 7.99 0 0016 18.928v-.003l-1.567-2.713A4.834 4.834 0 0112 16.87z"
        />
        <path
          fill="#0F993E"
          d="M16 18.928v-.003l-1.567-2.713a4.833 4.833 0 01-2.433.657V20c1.451 0 2.82-.39 4-1.07z"
        />
        <path
          fill="#FFD500"
          d="M7.13 12c0-.886.242-1.716.658-2.433L5.075 8.004A7.917 7.917 0 004 12c0 1.455.39 2.82 1.075 3.996l2.713-1.563A4.834 4.834 0 017.13 12z"
        />
        <path
          fill="#FF4B26"
          d="M12 7.13c1.173 0 2.25.417 3.091 1.11a.52.52 0 00.7-.031l1.477-1.477a.526.526 0 00-.03-.77A7.976 7.976 0 0012 4a7.99 7.99 0 00-6.925 4.004l2.713 1.563A4.869 4.869 0 0112 7.13z"
        />
        <path
          fill="#D93F21"
          d="M15.092 8.24a.52.52 0 00.7-.031l1.476-1.477a.526.526 0 00-.03-.77A7.976 7.976 0 0012 4v3.13c1.173 0 2.25.417 3.092 1.11z"
        />
      </g>
      <defs>
        <clipPath id="google-icon-svg">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GoogleIcon;
