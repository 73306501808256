import React, { ReactElement, useState } from 'react';
import { ReportResourceModal } from '../../../../ui/report-resource-modal';
import styled from 'styled-components';
import { useResourcesReportAbuse } from '../../../../common/mutation/__generated__/resources-report-abuse';
import { SentryService } from '../../../../lib';
import { notifyError, notifySuccess } from '../../../../lib/utils/notification';
import { ReportAbuseInput } from '../../../../__generated__/types';

interface ReportAbusePopupProps {
  postId: string;
}

const ReportAbusePopup: (props: ReportAbusePopupProps) => ReactElement = ({
  postId,
}) => {
  const [openReportModal, setOpenReportModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resourceAbuse] = useResourcesReportAbuse({
    onError: (error) => {
      SentryService.captureException({
        title: error.name,
        text: error.message,
      });

      notifyError({ text: error?.message });
    },
  });
  const handleFormSubmit = (props: ReportAbuseInput) => {
    setIsLoading(true);
    resourceAbuse({
      variables: {
        input: {
          reason: props.reason,
          postId: postId,
        },
      },
    }).then(() => {
      setOpenReportModal(false);
      setIsLoading(false);
      notifySuccess({
        title: 'Report Submitted',
        text: 'Thank you for submitting your report',
      });
    });
  };

  return (
    <>
      <ReportResourcesContainer>
        Inappropriate Content?{' '}
        <ReportButton
          onClick={() => {
            setOpenReportModal(true);
          }}
        >
          Report
        </ReportButton>
      </ReportResourcesContainer>

      <ReportResourceModal
        isVisible={openReportModal}
        onClose={() => {
          setOpenReportModal(false);
        }}
        handleFormSubmit={handleFormSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

const ReportResourcesContainer = styled.div`
  margin-top: 10px;
  border-top: 3px solid #d9d9d9;
  padding: 15px 0 0 10px;
`;

const ReportButton = styled.span`
  color: var(--purple);
  margin-bottom: 10px;
`;

export default ReportAbusePopup;
