import { FieldPolicy, Reference } from '@apollo/client';

type TPageInfo = {
  hasNextPage: boolean;
  endCursor: string | null;
};

type TExistingRelay<TNode> = Readonly<{
  nodes: TNode[];
  pageInfo: TPageInfo;
}>;

type TIncomingRelay<TNode> = {
  nodes: TNode[];
  pageInfo: TPageInfo;
};

type RelayFieldPolicy<TNode> = FieldPolicy<
  TExistingRelay<TNode>,
  TIncomingRelay<TNode>,
  TIncomingRelay<TNode>
>;
type KeyArgs = FieldPolicy<any>['keyArgs'];

export function relayStylePagination<TNode = Reference>(
  keyArgs: KeyArgs = ['filter', 'orderBy'],
): RelayFieldPolicy<TNode> {
  return {
    keyArgs,
    merge(existing = makeEmptyData(), incoming, { variables }) {
      if (variables?.filter?.first) {
        // refetch query for upcoming sessions
        if (!variables?.filter?.after) {
          return incoming;
        }
      }
      const merged: {
        nodes: TNode[];
        pageInfo: TPageInfo;
      } = {
        nodes: [...existing.nodes, ...incoming.nodes],
        pageInfo: { ...existing.pageInfo, ...incoming.pageInfo },
      };
      return merged;
    },

    // Return all items stored so far, to avoid ambiguities
    // about the order of the items.
    read(existing, { args }) {
      return existing;
    },
  };
}

function makeEmptyData(): TExistingRelay<any> {
  return {
    nodes: [],
    pageInfo: {
      hasNextPage: false,
      endCursor: null,
    },
  };
}
