import { StorageService } from 'lib/utils';
import { useEffect } from 'react';

const listener = (event: StorageEvent) => {
  const isLogout = Boolean(StorageService.getIsLogout());

  if (isLogout) {
    StorageService.removeAccessToken();
    StorageService.removeIsLogout();
  } else {
    if (event.key == StorageService.shareAccessToken) {
      const accessToken = StorageService.getAccessToken();
      accessToken && StorageService.setAccessToken(accessToken, true);
      StorageService.removeAccessTokenFormLocalStorage();
    } else if (
      event.key == StorageService.accessTokenName &&
      !sessionStorage.length &&
      event.newValue
    ) {
      StorageService.setAccessToken(event.newValue);
    }
  }
};

export const useShareSessionStorage = () => {
  const isRememberMe = Boolean(StorageService.getIsRememberUser());

  if (!sessionStorage.length && !isRememberMe) {
    StorageService.setShareAccessToken();
  }

  useEffect(() => {
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, []);
};
