import React, { VideoHTMLAttributes, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

type VideoProps = VideoHTMLAttributes<HTMLVideoElement> & {
  srcObject: MediaStream;
  isSmallScreen?: boolean;
  objectFit?: 'cover' | 'contain';
};

function Video({ srcObject, isSmallScreen, objectFit, ...props }: VideoProps) {
  const refVideo = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!refVideo.current) return;
    refVideo.current.srcObject = srcObject;
  }, [srcObject]);

  return (
    <Root
      $isSmallScreen={isSmallScreen}
      $objectFit={objectFit}
      controls={false}
      ref={refVideo}
      {...props}
    />
  );
}

const Root = styled.video<{
  $isSmallScreen?: boolean;
  $objectFit?: 'cover' | 'contain';
}>`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: ${({ $objectFit }) => ($objectFit ? $objectFit : 'cover')};
  transform: scaleX(-1);
  ${({ $isSmallScreen }) => $isSmallScreen && `border-radius: ${getSize(8)};`}
`;

export default Video;
