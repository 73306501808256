import { Circle, InfoBox, Marker } from '@react-google-maps/api';
import { OfficeLocationsInformation } from 'features/find-specialist/specialist-details/query/__generated__/get-therapist-details-query';
import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { getHiddenAddress } from 'ui/locations-map/utils/get-hidden-address';

const INFO_BOX_STYLE = { overflow: 'visible' };

interface MarkersProps {
  locations: OfficeLocationsInformation[];
  currentAddressIndex: number;
  isSession?: boolean;
  isDetailedMap?: boolean;
}

const Markers: FC<MarkersProps> = ({
  locations,
  currentAddressIndex,
  isSession,
  isDetailedMap = false,
}) => {
  return (
    <React.Fragment>
      {locations.map(
        ({ fullAddress, lat, lng, hideFullAddress, postcode }, index) => {
          const shortPostcode = postcode.split(' ')[0];
          const district = getHiddenAddress(fullAddress);
          const isActiveAddress = index === currentAddressIndex;

          return (
            <React.Fragment key={fullAddress}>
              {hideFullAddress && !isSession ? (
                <Circle
                  center={{ lat, lng }}
                  radius={800}
                  options={{
                    fillOpacity: 0.1,
                    strokeOpacity: 0,
                    fillColor: '#6B4EE6',
                  }}
                />
              ) : (
                <Marker position={{ lat, lng }} icon="/images/pin-icon.svg" />
              )}
              {!isDetailedMap && (
                <InfoBox
                  position={{ lat, lng }}
                  options={{
                    closeBoxURL: '',
                    boxStyle: INFO_BOX_STYLE,
                    disableAutoPan: true,
                    zIndex: isActiveAddress ? 1000 : 1,
                  }}
                >
                  <InfoWrapper>
                    {hideFullAddress && !isSession
                      ? `${district}, ${shortPostcode}`
                      : fullAddress}
                  </InfoWrapper>
                </InfoBox>
              )}
            </React.Fragment>
          );
        },
      )}
    </React.Fragment>
  );
};

const InfoWrapper = styled.p`
  position: absolute;
  top: ${getSize(0)};
  right: ${getSize(28)};
  padding: ${getSize(18)} ${getSize(26)} ${getSize(16)} ${getSize(18)};
  min-width: ${getSize(184)};
  background: white;
  border-radius: ${getSize(8)};
  font-family: Poppins;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);
  transform: translateY(-50%);
  box-shadow: 0px ${getSize(20)} ${getSize(90)} var(--gray-opacity);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: ${getSize(-9)};
    transform: translateY(-50%) rotate(45deg);
    width: ${getSize(18)};
    height: ${getSize(18)};
    background: white;
    border-radius: ${getSize(4)};
  }
`;

export default Markers;
