import React from 'react';

function LogoutIcon({ ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.25 11H2.25C1.55964 11 1 10.4404 1 9.75V2.25C1 1.55964 1.55964 1 2.25 1H7.25M4.75 6H11M11 6L9.125 4.125M11 6L9.125 7.875"
        stroke="#6B4EE6"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LogoutIcon;
