import { useTherapistClientTypesByIdLazyQuery } from 'common/query/__generated__/get-therapis-client-types-by-id';
import { useTherapistClientTypesOptions } from 'hooks';
import { useEffect, useMemo } from 'react';

export const useAvailableClientsTypesOptions = (therapistId: string) => {
  const clientTypesOptions = useTherapistClientTypesOptions();

  const [getTherapistClientTypes, { data, loading }] =
    useTherapistClientTypesByIdLazyQuery();

  useEffect(() => {
    void getTherapistClientTypes({
      variables: {
        id: therapistId,
      },
    });
  }, [therapistId, getTherapistClientTypes]);

  const therapistClientTypesList =
    data?.therapistPublicById.therapistClientTypes;

  const availableClientsTypesOptions = useMemo(() => {
    return therapistClientTypesList
      ? clientTypesOptions.filter((typeOption) =>
          therapistClientTypesList.includes(typeOption.label),
        )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistClientTypesList, clientTypesOptions]);

  return { availableClientsTypesOptions, loading };
};
