import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSessionsNeededFeedbackVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSessionsNeededFeedback = (
  { __typename: 'Query' }
  & { patientSessionsNeededFeedback: Array<(
    { __typename: 'SessionMetaModel' }
    & SessionsNeededFragment
  )> }
);

export type SessionsNeededFragment = (
  { __typename: 'SessionMetaModel' }
  & Pick<Types.SessionMetaModel, 'id' | 'reviewSkipped' | 'endDateUtc' | 'therapistId'>
);

export const SessionsNeededFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SessionsNeededFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SessionMetaModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reviewSkipped"}},{"kind":"Field","name":{"kind":"Name","value":"endDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"therapistId"}}]}}]} as unknown as DocumentNode;
export const GetSessionsNeededFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSessionsNeededFeedback"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientSessionsNeededFeedback"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SessionsNeededFragment"}}]}}]}},...SessionsNeededFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetSessionsNeededFeedback__
 *
 * To run a query within a React component, call `useGetSessionsNeededFeedback` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsNeededFeedback` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsNeededFeedback({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionsNeededFeedback(baseOptions?: Apollo.QueryHookOptions<GetSessionsNeededFeedback, GetSessionsNeededFeedbackVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionsNeededFeedback, GetSessionsNeededFeedbackVariables>(GetSessionsNeededFeedbackDocument, options);
      }
export function useGetSessionsNeededFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionsNeededFeedback, GetSessionsNeededFeedbackVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionsNeededFeedback, GetSessionsNeededFeedbackVariables>(GetSessionsNeededFeedbackDocument, options);
        }
export type GetSessionsNeededFeedbackHookResult = ReturnType<typeof useGetSessionsNeededFeedback>;
export type GetSessionsNeededFeedbackLazyQueryHookResult = ReturnType<typeof useGetSessionsNeededFeedbackLazyQuery>;
export type GetSessionsNeededFeedbackQueryResult = Apollo.QueryResult<GetSessionsNeededFeedback, GetSessionsNeededFeedbackVariables>;