import React from 'react';

function CheckEmailIcon({ ...rest }) {
  return (
    <svg width={136} height={136} viewBox="0 0 136 136" fill="none" {...rest}>
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M28.717 78.577l39.25 23.986 39.249-23.986 26.167-15.99v58.875c.001 8.027-6.507 14.537-14.535 14.538H17.087c-8.03-.001-14.536-6.511-14.536-14.538V62.587l26.166 15.99z"
          fill="#E1DCFA"
        />
        <path
          d="M133.382 57.824v4.763l-26.166 15.99V38.281l1.662.772 21.374 13.192a6.537 6.537 0 013.13 5.58z"
          fill="#8877DF"
        />
        <path
          d="M107.217 38.281v40.296l-39.25 23.986-39.25-23.986V10.375C28.718 4.645 33.362 0 39.09 0h39.566l.528.052v17.61c-.003 5.73 4.642 10.375 10.37 10.375h14.782l2.881 3.212v7.032zM90.84 51.645C90.84 39 80.59 28.75 67.944 28.75 55.301 28.75 45.05 39 45.05 51.645S55.3 74.541 67.944 74.541c12.645 0 22.896-10.25 22.896-22.895z"
          fill="#fff"
        />
        <path
          d="M79.239.059l25.097 27.978H89.554c-5.729 0-10.373-4.646-10.37-10.375V.052l.055.007z"
          fill="#EFE8E8"
        />
        <path
          d="M90.841 51.646c0 12.645-10.25 22.895-22.896 22.895-12.643 0-22.895-10.25-22.895-22.895S55.302 28.75 67.945 28.75c12.645 0 22.896 10.25 22.896 22.896z"
          fill="#E1DCFA"
        />
        <path
          d="M28.717 38.281v40.296L2.551 62.587v-4.763a6.544 6.544 0 013.13-5.58l23.036-13.963z"
          fill="#8877DF"
        />
        <path
          d="M64.566 58.757a2.287 2.287 0 01-1.743-.803l-5.089-5.96a2.289 2.289 0 113.482-2.973l3.638 4.26 10.117-8.231a2.287 2.287 0 013.717 1.408 2.29 2.29 0 01-.827 2.144l-11.85 9.642a2.28 2.28 0 01-1.445.513z"
          fill="#6B4EE6"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h136v136H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CheckEmailIcon;
