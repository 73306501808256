import { useEffect, useState } from 'react';
import { TimeUtil } from 'lib/utils/time-util';

function useDynamicTimeNow(updateTimeoutInSeconds = 60) {
  const [timeNow, setTimeNow] = useState(TimeUtil.now());

  useEffect(() => {
    const interval = setInterval(
      () => setTimeNow(TimeUtil.now()),
      updateTimeoutInSeconds * 1000,
    );

    return () => clearInterval(interval);
  }, [updateTimeoutInSeconds]);

  return { timeNow };
}

export default useDynamicTimeNow;
