import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

function VideoCallIcon({ ...rest }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 138 138"
      {...rest}
    >
      <g clipPath="url(#video-call-icon-svg)">
        <path
          fill="#E1DCFA"
          d="M89.815 54.273c-1.997-1.383-4.517-1.819-7.217-.995L68.47 57.984c-1.145-3.048-4.059-5.156-7.557-5.156h-40.7c-4.532 0-8.085 3.553-8.085 8.086v32.344c0 4.532 3.553 8.086 8.086 8.086h40.7c3.497 0 6.41-2.117 7.556-5.157l13.866 4.604c2.52 1.04 5.552.556 7.652-1.019 2.076-1.61 3.269-3.98 3.269-6.514V60.914c0-2.535-1.193-4.904-3.443-6.641zm36.056-38.101h-4.312V4.042a4.044 4.044 0 00-6.902-2.858L99.67 16.172H77.086c-6.688 0-12.129 5.44-12.129 12.129v8.355A4.04 4.04 0 0069 40.7h24.258a4.05 4.05 0 014.043 4.043V69a4.04 4.04 0 004.043 4.043h24.527c6.688 0 12.129-5.44 12.129-12.13V28.302c0-6.688-5.441-12.13-12.129-12.13z"
        />
        <path
          fill="#BCAEFF"
          d="M93.258 32.613h-81.13A12.113 12.113 0 000 44.743v64.956a12.113 12.113 0 0012.129 12.129h4.043v12.129c0 2.261 1.897 4.043 4.043 4.043 1.051 0 2.102-.404 2.83-1.213l15.04-14.959h55.173c6.711 0 12.129-5.418 12.129-12.129V44.742a12.113 12.113 0 00-12.13-12.129zm-4.043 60.645c0 1.293-.647 2.507-1.699 3.315-.913.685-2.436.966-3.638.485L64.957 90.75v2.507a4.004 4.004 0 01-4.043 4.043h-40.7a4.004 4.004 0 01-4.042-4.043V60.914a4.003 4.003 0 014.043-4.043h40.7a4.003 4.003 0 014.042 4.043v2.507l18.921-6.308c1.294-.404 2.587-.242 3.639.486 1.051.808 1.698 2.021 1.698 3.315v32.344z"
        />
      </g>
      <defs>
        <clipPath id="video-call-icon-svg">
          <path fill="#fff" d="M0 0h138v138H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(138)};
  height: ${getSize(138)};
`;

export default VideoCallIcon;
