import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyPlanToActiveVariables = Types.Exact<{
  sessionId: Types.Scalars['String'];
}>;


export type UpdateCompanyPlanToActive = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'updateCompanyPlanToActive'>
);


export const UpdateCompanyPlanToActiveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCompanyPlanToActive"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sessionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCompanyPlanToActive"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sessionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sessionId"}}}]}]}}]} as unknown as DocumentNode;
export type UpdateCompanyPlanToActiveMutationFn = Apollo.MutationFunction<UpdateCompanyPlanToActive, UpdateCompanyPlanToActiveVariables>;

/**
 * __useUpdateCompanyPlanToActive__
 *
 * To run a mutation, you first call `useUpdateCompanyPlanToActive` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyPlanToActive` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyPlanToActive, { data, loading, error }] = useUpdateCompanyPlanToActive({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useUpdateCompanyPlanToActive(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyPlanToActive, UpdateCompanyPlanToActiveVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyPlanToActive, UpdateCompanyPlanToActiveVariables>(UpdateCompanyPlanToActiveDocument, options);
      }
export type UpdateCompanyPlanToActiveHookResult = ReturnType<typeof useUpdateCompanyPlanToActive>;
export type UpdateCompanyPlanToActiveMutationResult = Apollo.MutationResult<UpdateCompanyPlanToActive>;
export type UpdateCompanyPlanToActiveMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyPlanToActive, UpdateCompanyPlanToActiveVariables>;