import React from 'react';

function ArrowIcon({ ...rest }) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="#444752" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 1c.276 0 .5.308.5.688v9.624c0 .38-.224.688-.5.688s-.5-.308-.5-.688V1.688c0-.38.224-.688.5-.688z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.201 7.195a.703.703 0 01.973 0L8.5 11.39l4.326-4.195a.703.703 0 01.973 0 .652.652 0 010 .943l-4.813 4.667a.703.703 0 01-.972 0L3.2 8.138a.652.652 0 010-.943z"
      />
    </svg>
  );
}

export default ArrowIcon;
