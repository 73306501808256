import React, { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { Tooltip } from 'ui/tooltip';

export interface FieldsSectionProps {
  title?: string;
  className?: string;
  children?: ReactNode;
  contentCSS?: CSSProp;
  tooltipText?: string;
  tooltipTextCSS?: CSSProp;
  headerCSS?: CSSProp;
}

function FieldsSection({
  title,
  children,
  className,
  contentCSS,
  tooltipText,
  tooltipTextCSS,
  headerCSS,
}: FieldsSectionProps) {
  return (
    <fieldset className={className}>
      {title && (
        <>
          <legend className="visually-hidden">{title}</legend>

          <Header $CSS={headerCSS}>
            <Title>{title}</Title>
            <TooltipStylized text={tooltipText} textCSS={tooltipTextCSS} />
          </Header>
        </>
      )}

      <Content $CSS={contentCSS}>{children}</Content>
    </fieldset>
  );
}

const Header = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  align-items: center;
  margin: 0 0 ${getSize(12)};
  ${({ $CSS }) => $CSS}
`;

const Title = styled.p`
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--black3);
`;

const TooltipStylized = styled(Tooltip)`
  margin: 0 0 0 ${getSize(8)};
`;

const Content = styled.div<{ $CSS?: CSSProp }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${getSize(16)} ${getSize(20)};

  ${({ $CSS }) => $CSS}
`;

export default FieldsSection;
