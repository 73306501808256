import React from 'react';

function PatientIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="#E5E4E8"
      viewBox="0 0 24 25"
      {...rest}
    >
      <g clipPath="url(#patient-icon-svg)">
        <path d="M12 10.683a4.078 4.078 0 100-8.156 4.078 4.078 0 000 8.156zM20.25 10.683a2.578 2.578 0 100-5.156 2.578 2.578 0 000 5.156zM3.75 10.683a2.578 2.578 0 100-5.156 2.578 2.578 0 000 5.156zM6.29 12.998c-1.015-.831-1.934-.72-3.107-.72C1.428 12.277 0 13.695 0 15.44v5.119c0 .757.618 1.373 1.379 1.373 3.282 0 2.887.06 2.887-.141 0-3.628-.43-6.288 2.024-8.794z" />
        <path d="M13.115 12.296c-2.05-.171-3.83.002-5.367 1.27-2.572 2.06-2.077 4.833-2.077 8.226 0 .897.73 1.641 1.642 1.641 9.894 0 10.288.32 10.875-.98.192-.44.14-.3.14-4.503 0-3.339-2.892-5.654-5.213-5.654zM20.816 12.277c-1.18 0-2.093-.109-3.107.721 2.436 2.487 2.024 4.966 2.024 8.794 0 .202-.328.141 2.838.141.788 0 1.428-.638 1.428-1.422v-5.07c0-1.745-1.428-3.164-3.183-3.164z" />
      </g>
      <defs>
        <clipPath id="patient-icon-svg">
          <path fill="#fff" d="M0 0h24v24H0z" transform="translate(0 .997)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PatientIcon;
