import React from 'react';

function AddGoalIcon({ ...rest }) {
  return (
    <svg
      width="57"
      height="58"
      viewBox="0 0 57 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.8202 16.8126C22.7432 16.8126 23.49 16.0657 23.49 15.1427V10.1412C24.6429 10.0743 25.2871 10.3825 26.0846 10.7804C27.363 11.4196 28.9531 12.2171 32.1999 11.493C32.9647 11.3235 33.5094 10.645 33.5094 9.86232V3.07317C33.5094 2.56764 33.2795 2.08816 32.8849 1.77189C32.4919 1.45395 31.9766 1.33483 31.4792 1.44248C29.3901 1.91049 28.6287 1.52564 27.5768 1.00219C26.2999 0.361293 24.7099 -0.437798 21.4599 0.287929C20.6951 0.457477 20.1504 1.13589 20.1504 1.91862V15.1427C20.1503 16.0657 20.8972 16.8126 21.8202 16.8126ZM39.9881 41.0646L35.1793 38.1262L31.6448 31.0575H11.9956L0.176588 54.5841C-0.0826873 55.1011 -0.0549674 55.7158 0.249952 56.2083C0.55487 56.7008 1.09146 57.0009 1.67035 57.0009H41.8586C42.4375 57.0009 46.3139 56.7008 46.6188 56.2083L46.6921 54.5841L39.9881 41.0646Z"
        fill="url(#paint0_linear_3768_4832)"
      />
      <path
        d="M23.49 15.1427C23.49 16.0657 22.7432 16.8126 21.8202 16.8126C20.8972 16.8126 20.1503 16.0657 20.1504 15.1427V1.91862C20.1504 1.13589 20.6951 0.457477 21.4599 0.287929C24.7085 -0.437469 26.2988 0.360568 27.5754 1.00132L27.5768 1.00219L27.5894 1.00832C28.635 1.52863 29.3983 1.90866 31.4792 1.44248C31.9766 1.33483 32.4919 1.45395 32.8849 1.77189C33.2795 2.08816 33.5094 2.56764 33.5094 3.07317V9.86232C33.5094 10.645 32.9647 11.3235 32.1999 11.493C28.9564 12.2163 27.3666 11.4213 26.0888 10.7824L26.0846 10.7804C25.2871 10.3825 24.6429 10.0743 23.49 10.1412V15.1427Z"
        fill="url(#paint1_linear_3768_4832)"
      />
      <path
        d="M20.3269 14.3957L11.9961 31.0574L20.64 39.7014C20.9662 40.0276 21.3935 40.1907 21.8206 40.1907C22.2478 40.1907 22.6752 40.0276 23.0012 39.7014L31.6452 31.0574L23.3144 14.3957C22.747 13.2639 20.8944 13.2639 20.3269 14.3957V14.3957ZM56.8232 54.584L43.3529 27.7547C42.7853 26.6229 40.9329 26.6229 40.3654 27.7547L35.1796 38.1261L43.3529 54.584C43.6121 55.101 43.5844 55.7157 43.2795 56.2082C42.9746 56.7007 42.438 57.0007 41.8591 57.0007H55.3294C55.9083 57.0007 56.4449 56.7007 56.7498 56.2082C57.0547 55.7157 57.0825 55.1008 56.8232 54.584V54.584Z"
        fill="url(#paint2_linear_3768_4832)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3768_4832"
          x1="23.3461"
          y1="57.0009"
          x2="23.3461"
          y2="-6.79504e-05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD456" />
          <stop offset="1" stopColor="#E2A311" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3768_4832"
          x1="23.3461"
          y1="57.0009"
          x2="23.3461"
          y2="-6.79504e-05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD456" />
          <stop offset="1" stopColor="#E2A311" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3768_4832"
          x1="34.498"
          y1="57.0007"
          x2="34.498"
          y2="13.5468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7D169" />
          <stop offset="0.9973" stopColor="#F0FFF4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AddGoalIcon;
