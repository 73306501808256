import React from 'react';

function Mp4Icon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      {...rest}
    >
      <path
        d="M19.122 1.003a.438.438 0 00-.31-.128H6.563A3.066 3.066 0 003.5 3.938v20.125a3.066 3.066 0 003.063 3.062h14.875a3.066 3.066 0 003.062-3.063v-17.5a.437.437 0 00-.128-.309l-5.25-5.25zm.128 1.366l3.756 3.756h-1.569a2.19 2.19 0 01-2.187-2.188V2.37zM6.562 1.75h11.813v2.188A3.066 3.066 0 0021.438 7h2.187v12.25H4.375V3.937A2.19 2.19 0 016.563 1.75zm14.875 24.5H6.563a2.19 2.19 0 01-2.187-2.188v-3.937h19.25v3.938a2.19 2.19 0 01-2.188 2.187z"
        fill="#7A60E9"
      />
      <path
        d="M11.813 21a.437.437 0 00-.31.128L10.5 22.13l-1.003-1.003a.438.438 0 00-.747.309v3.5a.438.438 0 00.875 0v-2.444l.566.566a.439.439 0 00.618 0l.566-.566v2.444a.438.438 0 00.875 0v-3.5a.437.437 0 00-.438-.438zM14.438 21h-.876a.438.438 0 00-.437.438v3.5a.438.438 0 00.875 0v-1.313h.438a1.313 1.313 0 000-2.625zm0 1.75H14v-.875h.438a.438.438 0 010 .875zM18.813 21a.438.438 0 00-.438.438v1.312H17.5v-1.313a.438.438 0 00-.875 0v1.75a.438.438 0 00.438.438h1.312v1.313a.438.438 0 00.875 0v-3.5a.438.438 0 00-.438-.438zM14 16.625a5.688 5.688 0 10-5.688-5.688A5.694 5.694 0 0014 16.625zm0-10.5a4.813 4.813 0 110 9.625 4.813 4.813 0 010-9.625z"
        fill="#7A60E9"
      />
      <path
        d="M12.457 13.06a.437.437 0 00.426.02l3.5-1.75a.438.438 0 000-.783l-3.5-1.75a.437.437 0 00-.633.391v3.5a.438.438 0 00.207.372zm.668-3.164l2.084 1.042-2.084 1.042V9.896z"
        fill="#7A60E9"
      />
    </svg>
  );
}

export default Mp4Icon;
