import React from 'react';

function MaterialDocument({ ...rest }) {
  return (
    <svg
      width="42"
      height="38"
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.2467 35.2513H30.9017C32.6102 35.2513 34 33.8615 34 32.153V10.3275H26.7707C25.0623 10.3275 23.6725 8.93771 23.6725 7.22927V0H10.2467C8.53825 0 7.14844 1.38981 7.14844 3.09826V32.153C7.14844 33.8615 8.53825 35.2513 10.2467 35.2513ZM14.3777 14.5274H26.7707C27.3416 14.5274 27.8035 14.9893 27.8035 15.5601C27.8035 16.131 27.3416 16.5929 26.7707 16.5929H14.3777C13.8069 16.5929 13.345 16.131 13.345 15.5601C13.345 14.9893 13.8069 14.5274 14.3777 14.5274ZM14.3777 18.6584H26.7707C27.3416 18.6584 27.8035 19.1203 27.8035 19.6911C27.8035 20.262 27.3416 20.7239 26.7707 20.7239H14.3777C13.8069 20.7239 13.345 20.262 13.345 19.6911C13.345 19.1203 13.8069 18.6584 14.3777 18.6584ZM14.3777 22.7894H26.7707C27.3416 22.7894 27.8035 23.2513 27.8035 23.8222C27.8035 24.393 27.3416 24.8549 26.7707 24.8549H14.3777C13.8069 24.8549 13.345 24.393 13.345 23.8222C13.345 23.2513 13.8069 22.7894 14.3777 22.7894ZM14.3777 26.9204H22.6397C23.2106 26.9204 23.6725 27.3823 23.6725 27.9532C23.6725 28.524 23.2106 28.9859 22.6397 28.9859H14.3777C13.8069 28.9859 13.345 28.524 13.345 27.9532C13.345 27.3823 13.8069 26.9204 14.3777 26.9204Z"
        fill="white"
      />
      <path
        d="M26.771 8.26237H33.3952L25.7383 0.605469V7.22961C25.7383 7.79942 26.2012 8.26237 26.771 8.26237Z"
        fill="white"
      />
    </svg>
  );
}

export default MaterialDocument;
