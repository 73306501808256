import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';

const BookingStepLayout: FC<BookingStepLayoutProps> = ({ title, children }) => {
  return (
    <>
      <Title>{title}</Title>

      {children}
    </>
  );
};

export interface BookingStepLayoutProps {
  title: string;
}

const Title = styled.h1`
  margin-bottom: ${getSize(26)};

  color: var(--black7);

  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(28)};
`;

export default BookingStepLayout;
