import React from 'react';

function ReceiptIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      {...rest}
    >
      <path
        d="M17.145 1.67L14.925.371l-2.22 1.297-2.22-1.297-2.219 1.297L5.411 0v17.3H2v2.325C2 20.935 3.05 22 4.341 22H17.66C18.949 22 20 20.934 20 19.625V0l-2.855 1.67zM4.34 20.71c-.59 0-1.07-.487-1.07-1.085V18.59h12.047v1.035c0 .39.093.76.259 1.085H4.34zm13.318 0c-.59 0-1.07-.487-1.07-1.085V17.3H6.682V2.232l1.584.926 2.22-1.297 2.22 1.297 2.219-1.298 2.22 1.298 1.584-.926v17.393c0 .598-.48 1.085-1.07 1.085z"
        fill="#444752"
      />
      <path d="M18 6H8v1h10V6zM18 8H8v1h10V8zM13 11H8v1h5v-1z" fill="#444752" />
    </svg>
  );
}

export default ReceiptIcon;
