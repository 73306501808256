import React from 'react';

function BarChartIcon({ ...rest }) {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8C4 6.89543 3.10457 6 2 6C0.89543 6 0 6.89543 0 8H4ZM4 16.5V8H0V16.5H4Z"
        fill="#674FDE"
      />
      <path
        d="M10 4C10 2.89543 9.10457 2 8 2C6.89543 2 6 2.89543 6 4H10ZM10 16.5V4H6V16.5H10Z"
        fill="#674FDE"
      />
      <path
        d="M16 11C16 9.89543 15.1046 9 14 9C12.8954 9 12 9.89543 12 11H16ZM16 16.5V11H12V16.5H16Z"
        fill="#674FDE"
      />
    </svg>
  );
}

export default BarChartIcon;
