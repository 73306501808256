import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import styled from 'styled-components';
import { Button } from 'ui';

interface WizardFormProps {
  children: ReactNode;
  currentScreenIndex: number;
  setCurrentScreenIndex: (value: number) => void;
  setIsVisibleModal: (value: boolean) => void;
}

const WizardForm: FC<WizardFormProps> = ({
  children,
  currentScreenIndex,
  setCurrentScreenIndex,
  setIsVisibleModal,
}) => {
  const [childrenArray] = useState(React.Children.toArray(children));
  const isLastChild = currentScreenIndex === childrenArray.length - 1;
  const handleOnSubmit = useCallback((values) => {
    console.log(values);
  }, []);

  const currentChildren: any = childrenArray[currentScreenIndex];

  return (
    <FinalForm
      onSubmit={handleOnSubmit}
      render={({ handleSubmit, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Wrapper>{childrenArray[currentScreenIndex]}</Wrapper>
            {isLastChild ? (
              <Button
                theme="primary"
                onClick={() => {
                  setCurrentScreenIndex(0);
                  setIsVisibleModal(false);
                }}
              >
                Ok
              </Button>
            ) : (
              <Button
                type="button"
                isDisabled={!values[currentChildren?.props.name]}
                onClick={() => setCurrentScreenIndex(currentScreenIndex + 1)}
                theme="primary"
              >
                Done
              </Button>
            )}
          </Form>
        );
      }}
    />
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export default WizardForm;
