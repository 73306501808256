import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyPlanAndSessionInfoVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanyPlanAndSessionInfo = (
  { __typename: 'Query' }
  & { planAndSessionsInfo: (
    { __typename: 'PlanAndSessionsInfoModel' }
    & Pick<Types.PlanAndSessionsInfoModel, 'sessionsLeft'>
    & { plan?: Types.Maybe<(
      { __typename: 'CompanyPlanModel' }
      & Pick<Types.CompanyPlanModel, 'planType' | 'insuranceCompanyId'>
      & { sessionRestrictions: (
        { __typename: 'B2BSessionRestrictions' }
        & Pick<Types.B2BSessionRestrictions, 'sessionTypes' | 'sessionDurations' | 'sessionProfessionalTitles'>
      ) }
    )> }
  ) }
);


export const GetCompanyPlanAndSessionInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyPlanAndSessionInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"planAndSessionsInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sessionsLeft"}},{"kind":"Field","name":{"kind":"Name","value":"plan"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"planType"}},{"kind":"Field","name":{"kind":"Name","value":"insuranceCompanyId"}},{"kind":"Field","name":{"kind":"Name","value":"sessionRestrictions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sessionTypes"}},{"kind":"Field","name":{"kind":"Name","value":"sessionDurations"}},{"kind":"Field","name":{"kind":"Name","value":"sessionProfessionalTitles"}}]}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetCompanyPlanAndSessionInfo__
 *
 * To run a query within a React component, call `useGetCompanyPlanAndSessionInfo` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPlanAndSessionInfo` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPlanAndSessionInfo({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyPlanAndSessionInfo(baseOptions?: Apollo.QueryHookOptions<GetCompanyPlanAndSessionInfo, GetCompanyPlanAndSessionInfoVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyPlanAndSessionInfo, GetCompanyPlanAndSessionInfoVariables>(GetCompanyPlanAndSessionInfoDocument, options);
      }
export function useGetCompanyPlanAndSessionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyPlanAndSessionInfo, GetCompanyPlanAndSessionInfoVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyPlanAndSessionInfo, GetCompanyPlanAndSessionInfoVariables>(GetCompanyPlanAndSessionInfoDocument, options);
        }
export type GetCompanyPlanAndSessionInfoHookResult = ReturnType<typeof useGetCompanyPlanAndSessionInfo>;
export type GetCompanyPlanAndSessionInfoLazyQueryHookResult = ReturnType<typeof useGetCompanyPlanAndSessionInfoLazyQuery>;
export type GetCompanyPlanAndSessionInfoQueryResult = Apollo.QueryResult<GetCompanyPlanAndSessionInfo, GetCompanyPlanAndSessionInfoVariables>;