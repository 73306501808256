import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

/** Constants */
import { PATIENT_RESOURCES_ROUTES } from 'lib/routes';
import { ContentCard } from '../components/content-card';

// Custom components
import { Loader } from 'ui';

interface ContentProps {
  posts?: any;
  activeCategory?: any;
  postsLoading?: boolean;
  subcategoriesWithPostPreview?: any;
  loadingSubCategoriesWithPostPreview: boolean;
  activeSubcategoryId?: string;
}

const Content = (props: ContentProps) => {
  const history = useHistory();

  /** Props */
  const {
    posts,
    postsLoading,
    activeCategory,
    activeSubcategoryId,
    subcategoriesWithPostPreview,
    loadingSubCategoriesWithPostPreview,
  } = props;

  /** Guard */
  if (loadingSubCategoriesWithPostPreview || postsLoading) {
    return <Loader size={45} />;
  } else if (
    !subcategoriesWithPostPreview ||
    subcategoriesWithPostPreview.length === 0
  ) {
    return null;
  }

  const activeSubcategory =
    activeCategory &&
    activeCategory.subCategories.find(
      (subCategory: { id: string | undefined }) => {
        return subCategory.id === activeSubcategoryId;
      },
    );

  const handleSubCategoryClick = (subCategoryId: string) => {
    history.push(
      PATIENT_RESOURCES_ROUTES.ALL_CONTENT.pathBuild(
        activeCategory.id,
        subCategoryId,
      ),
    );
  };

  /** If we have active subcategory, then show posts for it */
  if (activeSubcategory && posts) {
    return (
      <div className="container g-0">
        <div className="row">
          {posts.map((post: any, i: number) => {
            const className = i <= 1 ? 'col-md-6 mt-4' : 'col-md-4 mt-4';
            const cardSize = i <= 1 ? 'large' : 'small';

            return (
              <div key={post.id} className={className}>
                <ContentCard
                  id={post.id}
                  size={cardSize}
                  type={post.type}
                  title={post.title}
                  author={post.author}
                  imageUrl={post.imageUrl}
                  subtitle={post.subtitle}
                  createdAt={post.createdAt}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  // This part will render out subcategories with posts preview
  return (
    <div className="container g-0">
      {/* Subcategories */}
      {subcategoriesWithPostPreview.map((subCategory: any) => {
        return (
          <React.Fragment key={subCategory.id}>
            <SubCategoryHeader>
              <span>{subCategory.name}</span>
              <SeeAllLink
                onClick={() => handleSubCategoryClick(subCategory.id)}
              >
                See All
              </SeeAllLink>
            </SubCategoryHeader>

            {/* Preiew Posts */}
            <div className="row">
              {subCategory.posts.slice(0, 2).map((post: any, i: number) => {
                const className = i <= 1 ? 'col-md-6 mt-4' : 'col-md-4 mt-4';
                const cardSize = i <= 1 ? 'large' : 'small';

                return (
                  <div className={className} key={post.id}>
                    <ContentCard {...post} size={cardSize} />
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const SubCategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  margin-top: 20px;
`;

const SeeAllLink = styled.span`
  cursor: pointer;
  font-size: 14px;
  color: var(--purple);
`;

export default Content;
