import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendVerificationEmailVariables = Types.Exact<{ [key: string]: never; }>;


export type ResendVerificationEmail = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'resendVerificationEmail'>
);


export const ResendVerificationEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResendVerificationEmail"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resendVerificationEmail"}}]}}]} as unknown as DocumentNode;
export type ResendVerificationEmailMutationFn = Apollo.MutationFunction<ResendVerificationEmail, ResendVerificationEmailVariables>;

/**
 * __useResendVerificationEmail__
 *
 * To run a mutation, you first call `useResendVerificationEmail` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmail` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmail, { data, loading, error }] = useResendVerificationEmail({
 *   variables: {
 *   },
 * });
 */
export function useResendVerificationEmail(baseOptions?: Apollo.MutationHookOptions<ResendVerificationEmail, ResendVerificationEmailVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerificationEmail, ResendVerificationEmailVariables>(ResendVerificationEmailDocument, options);
      }
export type ResendVerificationEmailHookResult = ReturnType<typeof useResendVerificationEmail>;
export type ResendVerificationEmailMutationResult = Apollo.MutationResult<ResendVerificationEmail>;
export type ResendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerificationEmail, ResendVerificationEmailVariables>;