import React from 'react';

function AddGoalSmallIcon({ ...rest }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.3124 11.7983C15.9601 11.7983 16.4843 11.2742 16.4843 10.6265V7.11663C17.2933 7.06968 17.7454 7.286 18.305 7.56522C19.2022 8.0138 20.318 8.57339 22.5964 8.06528C23.1331 7.9463 23.5154 7.47022 23.5154 6.92094V2.15661C23.5154 1.80186 23.3541 1.46538 23.0771 1.24343C22.8014 1.02032 22.4397 0.936724 22.0907 1.01227C20.6247 1.3407 20.0903 1.07063 19.3521 0.703292C18.4561 0.253539 17.3403 -0.307227 15.0596 0.202056C14.5229 0.321037 14.1406 0.797118 14.1406 1.3464V10.6265C14.1406 11.2742 14.6647 11.7983 15.3124 11.7983ZM28.0618 28.8173L24.6872 26.7552L22.2069 21.7947H8.41799L0.123922 38.3047C-0.0580263 38.6675 -0.0385737 39.0989 0.175405 39.4445C0.389383 39.7901 0.765936 40.0007 1.17217 40.0007H29.3745C29.7808 40.0007 32.501 39.7901 32.715 39.4445L32.7665 38.3047L28.0618 28.8173Z"
        fill="url(#paint0_linear_4807_7666)"
      />
      <path
        d="M16.4843 10.6265C16.4843 11.2742 15.9601 11.7983 15.3124 11.7983C14.6647 11.7983 14.1406 11.2742 14.1406 10.6265V1.3464C14.1406 0.797118 14.5229 0.321037 15.0596 0.202056C17.3393 -0.306996 18.4553 0.253031 19.3512 0.70268L19.3521 0.703292L19.361 0.707594C20.0947 1.07273 20.6304 1.33941 22.0907 1.01227C22.4397 0.936724 22.8014 1.02032 23.0771 1.24343C23.3541 1.46538 23.5154 1.80186 23.5154 2.15661V6.92094C23.5154 7.47022 23.1331 7.9463 22.5964 8.06528C20.3203 8.57287 19.2046 8.01493 18.308 7.56658L18.305 7.56522C17.7454 7.286 17.2933 7.06968 16.4843 7.11663V10.6265Z"
        fill="url(#paint1_linear_4807_7666)"
      />
      <path
        d="M14.2651 10.1025L8.41895 21.795L14.4849 27.8609C14.7138 28.0898 15.0136 28.2043 15.3134 28.2043C15.6131 28.2043 15.913 28.0898 16.1419 27.8609L22.2078 21.795L16.3616 10.1025C15.9634 9.30829 14.6634 9.30829 14.2651 10.1025V10.1025ZM39.8766 38.3048L30.4237 19.4772C30.0255 18.683 28.7255 18.683 28.3272 19.4772L24.6881 26.7555L30.4237 38.3048C30.6057 38.6676 30.5862 39.099 30.3723 39.4447C30.1583 39.7903 29.7817 40.0008 29.3755 40.0008H38.8284C39.2346 40.0008 39.6111 39.7903 39.8251 39.4447C40.0391 39.099 40.0586 38.6676 39.8766 38.3048V38.3048Z"
        fill="url(#paint2_linear_4807_7666)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4807_7666"
          x1="16.3832"
          y1="40.0007"
          x2="16.3832"
          y2="-4.76846e-05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD456" />
          <stop offset="1" stopColor="#E2A311" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4807_7666"
          x1="16.3832"
          y1="40.0007"
          x2="16.3832"
          y2="-4.76846e-05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD456" />
          <stop offset="1" stopColor="#E2A311" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4807_7666"
          x1="24.2098"
          y1="40.0008"
          x2="24.2098"
          y2="9.5068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7D169" />
          <stop offset="0.9973" stopColor="#F0FFF4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AddGoalSmallIcon;
