import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function SingleArrowIcon({ ...rest }) {
  return (
    <Icon viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.25 1.5L1.75 6L6.25 10.5"
        stroke="#AAAAAA"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(8)};
  height: ${getSize(12)};
`;

export default SingleArrowIcon;
