import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProfessionalRegistrationsVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfessionalRegistrations = (
  { __typename: 'Query' }
  & { professionalRegistrations: Array<(
    { __typename: 'ProfessionalRegistrationModel' }
    & Pick<Types.ProfessionalRegistrationModel, 'id' | 'label' | 'explanation'>
  )> }
);


export const ProfessionalRegistrationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ProfessionalRegistrations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"professionalRegistrations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useProfessionalRegistrations__
 *
 * To run a query within a React component, call `useProfessionalRegistrations` and pass it any options that fit your needs.
 * When your component renders, `useProfessionalRegistrations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessionalRegistrations({
 *   variables: {
 *   },
 * });
 */
export function useProfessionalRegistrations(baseOptions?: Apollo.QueryHookOptions<ProfessionalRegistrations, ProfessionalRegistrationsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfessionalRegistrations, ProfessionalRegistrationsVariables>(ProfessionalRegistrationsDocument, options);
      }
export function useProfessionalRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfessionalRegistrations, ProfessionalRegistrationsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfessionalRegistrations, ProfessionalRegistrationsVariables>(ProfessionalRegistrationsDocument, options);
        }
export type ProfessionalRegistrationsHookResult = ReturnType<typeof useProfessionalRegistrations>;
export type ProfessionalRegistrationsLazyQueryHookResult = ReturnType<typeof useProfessionalRegistrationsLazyQuery>;
export type ProfessionalRegistrationsQueryResult = Apollo.QueryResult<ProfessionalRegistrations, ProfessionalRegistrationsVariables>;