import { useLocation } from 'react-router-dom';
import { PATIENT_ROUTES, THERAPIST_ROUTES } from 'lib/routes';

export function useIsMessagingRoute(isTherapist?: boolean) {
  const { pathname } = useLocation();

  const messagingRoute = (
    isTherapist
      ? THERAPIST_ROUTES.MESSAGING.PATH
      : PATIENT_ROUTES.MESSAGING.PATH
  )
    .split('/')
    .slice(0, -1)
    .join('/');

  const isMessagingRoute = pathname.startsWith(messagingRoute);

  return { isMessagingRoute };
}
