import { ROOT_ROUTES } from 'lib/routes';
import { getSize } from 'lib/utils';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, LogoLink, ShadowWrapper } from 'ui';

const EmailChanged = () => {
  const { status } = useParams<{ status: string }>();
  const history = useHistory();

  const isSuccess = status === 'success';
  const isFail = status === 'fail';

  useEffect(() => {
    if (!isSuccess && !isFail) {
      history.push(ROOT_ROUTES.AUTH);
    }
  }, [history, isFail, isSuccess]);

  return (
    <Wrapper>
      <Header>
        <HeaderInner>
          <LogoLink />
        </HeaderInner>
      </Header>
      <Content>
        <MessageWrapper>
          <Text>
            {isSuccess
              ? 'You email successfully changed'
              : `Some error occured. Email didn't changed`}
          </Text>
          <Button
            theme="primary"
            onClick={() => history.push(ROOT_ROUTES.HOME)}
          >
            Done
          </Button>
        </MessageWrapper>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(
    180deg,
    var(--gray) ${getSize(-80)},
    var(--purple)
  );
`;

const Header = styled.header`
  position: relative;
  background: white;
  box-shadow: 0 ${getSize(20)} ${getSize(90)} var(--blue-opacity);
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: ${getSize(14)} ${getSize(110)};
  max-width: 1440px;
`;

const Content = styled.main`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageWrapper = styled(ShadowWrapper)`
  display: flex;
  flex-direction: column;
  padding: ${getSize(35)} ${getSize(38)} ${getSize(41)};
`;

const Text = styled.p`
  font-weight: 600;
  font-size: ${getSize(18)};
  line-height: ${getSize(32)};
  color: var(--black3);
  padding: 0 ${getSize(13)};
  margin-bottom: ${getSize(23)};
`;

export default EmailChanged;
