import React from 'react';

function FullyBookedIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      {...rest}
    >
      <path
        d="M42.857 5.143h-5.143V1.714a1.714 1.714 0 10-3.428 0v3.429H13.714V1.714a1.714 1.714 0 10-3.428 0v3.429H5.143A5.143 5.143 0 000 10.286v32.571A5.143 5.143 0 005.143 48h37.714A5.143 5.143 0 0048 42.857V10.286a5.143 5.143 0 00-5.143-5.143zm1.714 37.714c0 .947-.767 1.715-1.714 1.715H5.143a1.714 1.714 0 01-1.714-1.715V20.571H44.57v22.286zm0-25.714H3.43v-6.857c0-.947.767-1.715 1.714-1.715h5.143V12a1.714 1.714 0 103.428 0V8.57h20.572V12a1.714 1.714 0 103.428 0V8.57h5.143c.947 0 1.715.768 1.715 1.715v6.857z"
        fill="#E1DCFA"
      />
      <path
        d="M13.714 24h-3.428a1.714 1.714 0 000 3.429h3.428a1.714 1.714 0 100-3.43zM25.714 24h-3.428a1.714 1.714 0 000 3.429h3.428a1.714 1.714 0 100-3.43zM37.714 24h-3.428a1.714 1.714 0 000 3.429h3.428a1.714 1.714 0 100-3.43zM13.714 30.857h-3.428a1.714 1.714 0 100 3.429h3.428a1.714 1.714 0 000-3.429zM25.714 30.857h-3.428a1.714 1.714 0 100 3.429h3.428a1.714 1.714 0 000-3.429zM37.714 30.857h-3.428a1.714 1.714 0 100 3.429h3.428a1.714 1.714 0 000-3.429zM13.714 37.714h-3.428a1.714 1.714 0 100 3.429h3.428a1.714 1.714 0 100-3.429zM25.714 37.714h-3.428a1.714 1.714 0 100 3.429h3.428a1.714 1.714 0 100-3.429zM37.714 37.714h-3.428a1.714 1.714 0 100 3.429h3.428a1.714 1.714 0 100-3.429z"
        fill="#E1DCFA"
      />
    </svg>
  );
}

export default FullyBookedIcon;
