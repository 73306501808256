import React, { useMemo } from 'react';
import styled from 'styled-components';

import { getSize } from 'lib/utils';
import { useDatetime } from './utils';
import {
  ChatAttachmentFragment,
  ChatMessageEdgeFragment,
} from 'components/chat/query/__generated__/chat-messages';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import { AttachmentItem } from './components';
import { getInnerHtmlWithWrapedEmoji } from 'lib/utils';
import CheckedWorkTypeICon from 'ui/icons/checked-work-type-icon/checked-work-type-icon';

export interface MessageProps {
  isUserMessage: boolean;
  messageIndex: number;
  allMessages: ChatMessageEdgeFragment[];
  isSideBarExpanded: boolean;
  isTherapist?: boolean;
  unreadCount?: number;
  hasTextForSearchMessages: boolean;
  onMessageClick: () => void;
  isGroupSelectedChat: boolean;
  onAttachmentClick: (attachment: ChatAttachmentFragment) => void;
  onSelectMessageForDeleting?: () => void;
  isSelectedSessionForDeleting?: boolean;
}
export interface InnerProps {
  $isUserMessage: boolean;
  $hasAttachment: boolean;
  $hasTextForSearchMessages: boolean;
}
interface WrapperProps {
  $isUserMessage: boolean;
  $hasBorderDate: boolean;
  $hasLeftPadding: boolean;
  $isSideBarExpanded: boolean;
  $isTherapist?: boolean;
}

function Message({
  allMessages,
  messageIndex,
  isUserMessage,
  isSideBarExpanded,
  isTherapist,
  unreadCount,
  hasTextForSearchMessages,
  onMessageClick,
  isGroupSelectedChat,
  onAttachmentClick,
  onSelectMessageForDeleting,
  isSelectedSessionForDeleting,
}: MessageProps) {
  const {
    attachments,
    text,
    createdAt,
    id: currentMessageId,
    sender: { avatarUrl, id: senderId, name: senderName },
  } = allMessages[messageIndex].node;

  const previousMessage =
    messageIndex === allMessages.length - 1
      ? null
      : allMessages[messageIndex + 1];
  const nextMessage = messageIndex === 0 ? null : allMessages[messageIndex - 1];

  const isUnreadBorderMessage = messageIndex === Number(unreadCount) - 1;
  const isMessageSenderWroteNextMessage =
    nextMessage?.node.sender.id === senderId;
  const isNotUserMessageInGroupChat = isGroupSelectedChat && !isUserMessage;
  const isInterlocutorInfoVisible =
    isNotUserMessageInGroupChat && !isMessageSenderWroteNextMessage;

  const { borderDate, messageTime } = useDatetime(createdAt, previousMessage);
  const hasAttachments = Number(attachments?.length) > 0;

  const currentText = useMemo(() => getInnerHtmlWithWrapedEmoji(text), [text]);

  return (
    <Container
      $isUserMessage={isUserMessage}
      data-id={currentMessageId}
      $zIndex={allMessages.length - messageIndex}
    >
      {!!borderDate && <BorderDate>{borderDate}</BorderDate>}

      {isUnreadBorderMessage && (
        <UnreadBorderText id="unread-message-mark">
          Unread messages
        </UnreadBorderText>
      )}

      <Wrapper
        $isUserMessage={isUserMessage}
        $hasBorderDate={Boolean(borderDate)}
        $isSideBarExpanded={isSideBarExpanded}
        $isTherapist={isTherapist}
        $hasLeftPadding={
          isNotUserMessageInGroupChat && isMessageSenderWroteNextMessage
        }
      >
        {isSelectedSessionForDeleting && <CheckedIcon />}
        {isInterlocutorInfoVisible && (
          <Avatar src={avatarUrl || DEFAULT_AVATAR} />
        )}
        <Inner
          $isUserMessage={isUserMessage}
          $hasAttachment={hasAttachments}
          onClick={
            hasTextForSearchMessages
              ? onMessageClick
              : onSelectMessageForDeleting
          }
          $hasTextForSearchMessages={hasTextForSearchMessages}
        >
          {hasAttachments && (
            <AttachmentList>
              {attachments?.map((attachment) => (
                <AttachmentItem
                  key={attachment.source}
                  onClick={() => onAttachmentClick(attachment)}
                  attachment={attachment}
                />
              ))}
            </AttachmentList>
          )}

          <Text $isUserMessage={isUserMessage} $hasAttachment={hasAttachments}>
            <span dangerouslySetInnerHTML={currentText} />
            {!isInterlocutorInfoVisible && (
              <Time
                $isUserMessage={isUserMessage}
                $hasAttachment={hasAttachments}
              >
                {messageTime}
              </Time>
            )}
          </Text>

          {isInterlocutorInfoVisible && (
            <InfoWrapper>
              {!isUserMessage && <SenderName>{senderName}</SenderName>}
              <Time
                $isUserMessage={isUserMessage}
                $hasAttachment={hasAttachments}
              >
                {messageTime}
              </Time>
            </InfoWrapper>
          )}
        </Inner>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div<{ $isUserMessage: boolean; $zIndex: number }>`
  z-index: ${({ $zIndex }) => $zIndex};
  display: flex;
  flex-direction: column;

  ${({ $isUserMessage }) =>
    !$isUserMessage &&
    `
    &:first-of-type {
      margin-bottom: ${getSize(35)};
    }
  `}
`;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  max-width: ${({ $isSideBarExpanded, $isTherapist }) =>
    $isSideBarExpanded && !$isTherapist ? '48%' : getSize(367)};
  align-self: ${({ $isUserMessage }) =>
    $isUserMessage ? 'flex-end' : 'flex-start'};
  align-items: center;
  margin-top: ${({ $hasBorderDate, $isUserMessage }) => {
    if ($hasBorderDate) {
      return 0;
    } else {
      return getSize($isUserMessage ? 20 : 8);
    }
  }};

  ${({ $hasLeftPadding }) => $hasLeftPadding && `padding: 0 0 0 ${getSize(57)}`}

  ${({ $isUserMessage }) => ($isUserMessage ? 'cursor: pointer' : '')}
`;

const Avatar = styled.img`
  flex-shrink: 0;
  margin: auto ${getSize(15)} ${getSize(8)} 0;
  width: ${getSize(42)};
  height: ${getSize(42)};
  border-radius: 50%;
  object-fit: cover;
`;

const BorderDate = styled.p`
  margin: ${getSize(20)} 0;
  text-align: center;
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
`;

const UnreadBorderText = styled(BorderDate)`
  display: flex;
  align-items: center;
  white-space: nowrap;

  &::before {
    content: '';
    margin: 0 ${getSize(16)} 0 0;
    width: 100%;
    height: 1px;
    background: var(--purple5);
  }

  &::after {
    content: '';
    margin: 0 0 0 ${getSize(16)};
    width: 100%;
    height: 1px;
    background: var(--purple5);
  }
`;

const Inner = styled.div<InnerProps>`
  display: flex;
  flex-direction: column;
  padding-top: ${({ $hasAttachment }) => getSize($hasAttachment ? 20 : 10)};
  padding-right: ${({ $isUserMessage }) => getSize($isUserMessage ? 25 : 20)};
  padding-bottom: ${getSize(10)};
  padding-left: ${({ $isUserMessage, $hasAttachment }) => {
    let paddingSize = 20;

    if ($hasAttachment) {
      paddingSize = 14;
    } else if ($isUserMessage) {
      paddingSize = 24;
    }

    return getSize(paddingSize);
  }};

  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: ${({ $hasAttachment }) =>
    $hasAttachment ? '#8568FF' : 'var(--black3)'};
  border-radius: ${({ $isUserMessage }) =>
    $isUserMessage
      ? `${getSize(25)} 0 ${getSize(12)} ${getSize(25)}`
      : `${getSize(12)} ${getSize(25)} ${getSize(25)} 0`};

  background: 
    ${({ $isUserMessage, $hasAttachment }) =>
      $isUserMessage
        ? $hasAttachment
          ? '#F6F3FF;'
          : 'linear-gradient(255.44deg, #8568FF -2.58%, #7457F0 113.17%);'
        : 'var(--gray24)'}
  
  transition: max-width 0.3s ease-out, background 0.3s ease-out;

  border: 1px solid;
  border-color: ${({ $isUserMessage, $hasAttachment }) =>
    $isUserMessage ? ($hasAttachment ? '#F6F3FF' : '#8C74F1') : '#E8E8E8;'};

  ${({ $hasTextForSearchMessages }) =>
    $hasTextForSearchMessages &&
    `
    cursor: pointer;

    &:hover {
      background: var(--purple5);
    }
  `};

  & button {
    color: ${({ $hasAttachment }) =>
      $hasAttachment ? '#8568FF' : 'var(--black3)'};
  }
`;

const AttachmentList = styled.ul`
  margin: 0 0 ${getSize(5)};
`;

const Text = styled.pre<{ $isUserMessage: boolean; $hasAttachment: boolean }>`
  ${({ $isUserMessage }) => $isUserMessage && 'align-self: flex-end;'}
  color: ${({ $isUserMessage, $hasAttachment }) =>
    $isUserMessage && !$hasAttachment ? `white` : `#020202`};
  word-break: break-word;
  .emoji {
    vertical-align: bottom;
    font-size: ${getSize(22)};
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SenderName = styled.p`
  margin: 0 ${getSize(6)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: inherit;
  color: var(--gray7);
`;

interface TimeProps {
  $isUserMessage: boolean;
  $hasAttachment: boolean;
}

const Time = styled.time<TimeProps>`
  flex-shrink: 0;
  float: right;
  margin: 0 0 0 ${getSize(7)};
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: inherit;
  color: ${({ $isUserMessage, $hasAttachment }) =>
    $isUserMessage && !$hasAttachment ? ' white' : ' var(--gray7)'};

  text-transform: uppercase;
`;

const CheckedIcon = styled(CheckedWorkTypeICon)`
  width: ${getSize(20)};
  height: ${getSize(20)};
  margin-right: ${getSize(14)};
  flex-shrink: 0;
`;

export default Message;
