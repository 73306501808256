import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSearchParams } from 'hooks';

function getInitialQueryParams<T>(
  pageQueryParams: URLSearchParams,
  queryParamsNames: Record<string, string>,
) {
  const initialQueryParams = Object.values(queryParamsNames).reduce<string[][]>(
    (acc, paramsName) => {
      const paramsFromUrl = pageQueryParams.get(paramsName);

      if (paramsFromUrl) {
        acc.push([paramsName, paramsFromUrl]);
      }

      return acc;
    },
    [],
  );

  return initialQueryParams.length
    ? Object.fromEntries(initialQueryParams)
    : {};
}

function useCalendarQueryParams<T extends string>(
  queryParamsNames: Record<T, string>,
) {
  type QueryParams = Record<keyof typeof queryParamsNames, string>;

  const pageQueryParams = useSearchParams();
  const history = useHistory();

  const [queryParams, setQueryParams] = useState<QueryParams>(() =>
    getInitialQueryParams<typeof queryParamsNames>(
      pageQueryParams,
      queryParamsNames,
    ),
  );

  useEffect(() => {
    const newSearchParams = new URLSearchParams(queryParams).toString();

    if (history.location.search.replace('?', '') !== newSearchParams) {
      history.push({ search: newSearchParams });
    }
  }, [queryParams, history]);

  const handleSetQueryParams = useCallback(
    (newQueryParams: Partial<QueryParams>) => {
      const oldParams = { ...queryParams };

      function deleteEmptyOrAddNewParams(newQueryParamsKey: string) {
        const newQueryParamsValue =
          newQueryParams[newQueryParamsKey as keyof QueryParams];

        if (!newQueryParamsValue) {
          delete oldParams[newQueryParamsKey as keyof QueryParams];
        } else if (newQueryParamsValue) {
          if (newQueryParamsValue) {
            oldParams[newQueryParamsKey as keyof QueryParams] =
              newQueryParamsValue as string;
          }
        }
      }

      Object.keys(newQueryParams).forEach(deleteEmptyOrAddNewParams);
      setQueryParams(oldParams);
    },
    [queryParams],
  );

  return { queryParams, setQueryParams: handleSetQueryParams };
}

export default useCalendarQueryParams;
