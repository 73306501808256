import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPatientMeDataVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPatientMeData = (
  { __typename: 'Query' }
  & { me?: Types.Maybe<(
    { __typename: 'UserModel' }
    & Pick<Types.UserModel, 'id' | 'firstLogin'>
    & { patient?: Types.Maybe<(
      { __typename: 'PatientModel' }
      & Pick<Types.PatientModel, 'id' | 'defaultPaymentMethod' | 'firstSessionDateUts' | 'attendedSessionsCount' | 'pmiStatus'>
      & { connectedTherapists: Array<(
        { __typename: 'TherapistPublicModel' }
        & Pick<Types.TherapistPublicModel, 'id' | 'fullName'>
      )> }
    )> }
  )> }
);


export const GetPatientMeDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPatientMeData"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstLogin"}},{"kind":"Field","name":{"kind":"Name","value":"patient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"connectedTherapists"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"defaultPaymentMethod"}},{"kind":"Field","name":{"kind":"Name","value":"firstSessionDateUts"}},{"kind":"Field","name":{"kind":"Name","value":"attendedSessionsCount"}},{"kind":"Field","name":{"kind":"Name","value":"pmiStatus"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetPatientMeData__
 *
 * To run a query within a React component, call `useGetPatientMeData` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientMeData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientMeData({
 *   variables: {
 *   },
 * });
 */
export function useGetPatientMeData(baseOptions?: Apollo.QueryHookOptions<GetPatientMeData, GetPatientMeDataVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientMeData, GetPatientMeDataVariables>(GetPatientMeDataDocument, options);
      }
export function useGetPatientMeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientMeData, GetPatientMeDataVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientMeData, GetPatientMeDataVariables>(GetPatientMeDataDocument, options);
        }
export type GetPatientMeDataHookResult = ReturnType<typeof useGetPatientMeData>;
export type GetPatientMeDataLazyQueryHookResult = ReturnType<typeof useGetPatientMeDataLazyQuery>;
export type GetPatientMeDataQueryResult = Apollo.QueryResult<GetPatientMeData, GetPatientMeDataVariables>;