import React, { FC } from 'react';
import styled from 'styled-components';
import { BaseStyleProps } from '../../types/common';

const SeparateLine: FC<SeparateLine> = ({ className }) => {
  return <Root className={className} />;
};

type SeparateLine = BaseStyleProps;

const Root = styled.div`
  width: auto;
  height: 1px;
  background-color: var(--gray38);
  align-self: center;
`;

export default SeparateLine;
