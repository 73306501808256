import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LanguagesVariables = Types.Exact<{ [key: string]: never; }>;


export type Languages = (
  { __typename: 'Query' }
  & { languages: Array<(
    { __typename: 'LanguageModel' }
    & Pick<Types.LanguageModel, 'id' | 'name'>
  )> }
);


export const LanguagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Languages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"languages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useLanguages__
 *
 * To run a query within a React component, call `useLanguages` and pass it any options that fit your needs.
 * When your component renders, `useLanguages` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguages({
 *   variables: {
 *   },
 * });
 */
export function useLanguages(baseOptions?: Apollo.QueryHookOptions<Languages, LanguagesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Languages, LanguagesVariables>(LanguagesDocument, options);
      }
export function useLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Languages, LanguagesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Languages, LanguagesVariables>(LanguagesDocument, options);
        }
export type LanguagesHookResult = ReturnType<typeof useLanguages>;
export type LanguagesLazyQueryHookResult = ReturnType<typeof useLanguagesLazyQuery>;
export type LanguagesQueryResult = Apollo.QueryResult<Languages, LanguagesVariables>;