import React from 'react';

const NoteIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00844677 13.6172C-0.0171587 13.7625 0.01598 13.9122 0.100664 14.0331C0.18531 14.1541 0.314599 14.2364 0.459994 14.262L2.93062 14.6976L1.37429 5.87109L0.00844677 13.6172Z"
        fill="#6C4FE7"
      />
      <path
        d="M2.97598 0.0089639C2.83026 -0.0174951 2.67989 0.0151612 2.55828 0.0997705C2.43671 0.184417 2.35385 0.31404 2.32813 0.459954L1.73779 3.80796L12.9698 1.82743L2.97598 0.0089639Z"
        fill="#6C4FE7"
      />
      <path
        d="M18.9916 16.0266L16.6717 2.87018C16.6183 2.56744 16.3297 2.36542 16.0269 2.41867L2.87049 4.73849C2.56775 4.79185 2.36558 5.08057 2.41894 5.3833L4.73876 18.5397C4.7644 18.6851 4.84675 18.8144 4.96769 18.8991C5.06191 18.965 5.17349 18.9997 5.28697 18.9997C5.31915 18.9997 5.35151 18.997 5.38361 18.9913L18.54 16.6715C18.6854 16.6459 18.8147 16.5635 18.8993 16.4426C18.984 16.3216 19.0172 16.172 18.9916 16.0266ZM5.38494 9.38217C5.33158 9.07944 5.53375 8.79072 5.83649 8.73736L14.6075 7.19079C14.9105 7.13743 15.1989 7.33956 15.2523 7.6423C15.3057 7.94504 15.1035 8.23375 14.8008 8.28711L6.02979 9.83372C5.9971 9.83947 5.96455 9.84226 5.93249 9.84226C5.66742 9.84226 5.43255 9.65226 5.38494 9.38217ZM12.6504 13.1881L6.80308 14.2192C6.77038 14.2249 6.73784 14.2277 6.70578 14.2277C6.4407 14.2277 6.20584 14.0377 6.15823 13.7676C6.10486 13.4649 6.30704 13.1762 6.60977 13.1228L12.4571 12.0918C12.76 12.0384 13.0485 12.2406 13.1019 12.5433C13.1553 12.846 12.9531 13.1347 12.6504 13.1881ZM15.1874 10.4799L6.4164 12.0264C6.3837 12.0322 6.35116 12.035 6.3191 12.035C6.05402 12.035 5.81916 11.845 5.77155 11.5749C5.71819 11.2722 5.92036 10.9834 6.22309 10.9301L14.9941 9.38351C15.297 9.33015 15.5855 9.53228 15.6389 9.83502C15.6923 10.1378 15.4901 10.4265 15.1874 10.4799Z"
        fill="#6C4FE7"
      />
    </svg>
  );
};

export default NoteIcon;
