import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

function ArrowLongIcon({ ...rest }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="12"
      fill="#7A60E9"
      viewBox="0 0 25 12"
      {...rest}
    >
      <path d="M24.53 6.53a.75.75 0 000-1.06L19.757.697a.75.75 0 00-1.06 1.06L22.939 6l-4.242 4.243a.75.75 0 001.06 1.06L24.53 6.53zM0 6.75h24v-1.5H0v1.5z" />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(25)};
  height: ${getSize(12)};
`;

export default ArrowLongIcon;
