import React from 'react';
import styled from 'styled-components';
import Subcategory from './subcategory';

interface PathCardProps {
  name: string;
  imageUrl: string;
  iconUrl: string;
  subcategories?: Array<any>;
  selectedSubCategories: Array<any>;
  toggleSelectedSubCategory: (subCategoryId: string) => void;
}

const CategoryCard = (props: PathCardProps) => {
  const {
    name,
    imageUrl,
    iconUrl,
    subcategories,
    selectedSubCategories,
    toggleSelectedSubCategory,
  } = props;

  const renderSubcategories = () => {
    if (!subcategories || subcategories.length === 0) {
      return null;
    }

    return subcategories.map((subcategory, i) => {
      return (
        <Subcategory
          key={i}
          name={subcategory.name}
          onChange={() => toggleSelectedSubCategory(subcategory.id)}
          isSelected={selectedSubCategories.includes(subcategory.id)}
        />
      );
    });
  };

  return (
    <CardContainer>
      <CardBackground backgroundImg={imageUrl} />
      <CardContent>
        <Title>{name}</Title>
        {renderSubcategories()}
      </CardContent>
      {/* Category Icon */}
      <CardIcon alt={name} src={iconUrl} />
    </CardContainer>
  );
};

const CardContainer = styled.div`
  width: 427px;
  height: 277px;
  color: white;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const CardBackground = styled('div')<{
  backgroundImg: any;
}>`
  display: flex;
  flex: 1;
  background-image: url(${(props) => props.backgroundImg});
  background-size: cover;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: transparent;
  padding: 30px 20px;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: white;
  margin-bottom: 8px;
`;

const CardIcon = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 15px;
  right: 15px;
`;

export default CategoryCard;
