import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ONBOARDING_ROUTES, ROOT_ROUTES } from 'lib/routes';
import { TherapistRequestStatus } from '__generated__/types';
import { useTherapistVerifications } from 'common/query/__generated__/get-therapist-verifications';
import { StorageService } from 'lib/utils';

const { Accepted, Created } = TherapistRequestStatus;

function useTherapistRedirect() {
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();

  const {
    data: therapistApprovedData,
    loading: isLoadingTherapistApprovedStatus,
  } = useTherapistVerifications({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    function redirect() {
      const isTherapistOnboarded = therapistApprovedData?.me?.onboarded;
      const therapistApprovedStatus =
        therapistApprovedData?.me?.therapist?.requestStatus;
      const currentRoute = location.pathname;
      const savedStepJSON = StorageService.getOnboardingStep();

      if (
        (isTherapistOnboarded && therapistApprovedStatus === Accepted) ||
        currentRoute.startsWith(ROOT_ROUTES.ONBOARDING)
      ) {
        setIsLoading(false);
      } else if (
        therapistApprovedStatus === Created &&
        isTherapistOnboarded &&
        !savedStepJSON
      ) {
        history.push(ONBOARDING_ROUTES.FINISH_ONBOARDING);
      } else {
        history.push(ROOT_ROUTES.ONBOARDING);
      }
    }

    if (!isLoadingTherapistApprovedStatus) redirect();
  }, [
    history,
    location.pathname,
    isLoadingTherapistApprovedStatus,
    therapistApprovedData,
  ]);

  return { isLoading };
}

export default useTherapistRedirect;
