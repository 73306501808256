import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function SearchIcon({ ...rest }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#E1DCFA"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35"
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

export default SearchIcon;
