import { Role } from '__generated__/types';

export function isValidUserRole(role?: string | null) {
  if (!role) return false;

  const validRoles = Object.values(Role).map((role) => role.toLowerCase());
  return validRoles.includes(role.toLowerCase());
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function getInnerHtmlWithWrapedEmoji(text?: string | null) {
  const emojiRanges = [
    '\u00a9',
    '\u00ae',
    '[\u2000-\u3300]',
    '\ud83c[\ud000-\udfff]',
    '\ud83d[\ud000-\udfff]',
    '\ud83e[\ud000-\udfff]',
  ];

  return {
    __html:
      text?.replaceAll(
        new RegExp(emojiRanges.join('|'), 'gi'),
        (oldValue) => `<span class="emoji">${oldValue}</span>`,
      ) || '',
  };
}
