import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// Custom Components
import { Button } from 'ui';
import { CategoryCard } from './components/category-card';

// Query imports
import { useUser } from 'common/query/__generated__/get-user';
import { useGetCategoriesAndSubcategories } from '../query/__generated__/get-categories-and-subcategories';

// Mutation imports
import { useSaveUserSubCategories } from '../mutation/__generated__/save-user-sub-categories';

// Route constants
import { PATIENT_RESOURCES_ROUTES } from 'lib/routes';
const { DISCOVER } = PATIENT_RESOURCES_ROUTES;

/**
 * @function
 * @name ChoseContent
 * @description Function wil return screen content with categories and subcategories. User can then choose
 * what category/subcategories are of interest to him.
 */
const ChoseContent = () => {
  /** State Data */
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>(
    [],
  );

  /** History for navigation */
  const history = useHistory();

  /** Data about a user */
  const { data: user } = useUser();

  /** Data fetching */
  const { data } = useGetCategoriesAndSubcategories();
  const categoriesAndSubcategories = data?.getCategoriesAndSubcategories;

  /** Mutation init */
  const [saveUserSubCategories, { loading }] = useSaveUserSubCategories();

  useEffect(() => {
    if (user?.me?.subcategories) {
      const userSubcategories = user?.me?.subcategories.map(
        (subcategory) => subcategory.id,
      );
      setSelectedSubCategories(userSubcategories);
    }
  }, [user?.me?.subcategories]);

  /**
   * @method
   * @description Method will take subcategory id and either
   * add it or remove it from selectedSubCategories
   */
  const toggleSelectedSubCategory = (subCategoryId: string) => {
    const newSelectedSubCategories = [...selectedSubCategories];
    const indexOfSubCategory = selectedSubCategories.indexOf(subCategoryId);

    if (indexOfSubCategory === -1) {
      newSelectedSubCategories.push(subCategoryId);
    } else {
      newSelectedSubCategories.splice(indexOfSubCategory, 1);
    }

    setSelectedSubCategories(newSelectedSubCategories);
  };

  const handleSelect = async () => {
    try {
      await saveUserSubCategories({
        variables: { subCategoryIds: selectedSubCategories },
      });

      history.replace(DISCOVER.pathBuild());
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @desc Method will render out category cards with subcategories in it.
   */
  const renderCategoryCards = () => {
    if (!categoriesAndSubcategories) {
      return null;
    }

    return categoriesAndSubcategories.map((category, i) => {
      return (
        <div key={i} className="col-6 mt-3">
          <CategoryCard
            name={category.name}
            iconUrl={category.iconUrl}
            imageUrl={category.imageUrl}
            subcategories={category.subCategories}
            selectedSubCategories={selectedSubCategories}
            toggleSelectedSubCategory={toggleSelectedSubCategory}
          />
        </div>
      );
    });
  };

  return (
    <Container>
      <Content>
        <Title>Find the on-demand support you need</Title>
        <SubTitle>Select the 5 areas you most want support with today</SubTitle>

        <div className="container">
          <div className="row">{renderCategoryCards()}</div>
        </div>
      </Content>
      <StyledButton theme="tertiary" onClick={handleSelect}>
        Select
      </StyledButton>
    </Container>
  );
};

/** Styled Components */
const Title = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 120%;
  padding-bottom: 12px;
`;

const StyledButton = styled(Button)`
  margin: 7px 0;
  width: 25%;
`;

const SubTitle = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  margin-bottom: 25px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
`;

const Content = styled.div`
  width: 63%;
`;

export default ChoseContent;
