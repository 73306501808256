import React from 'react';

function TrashIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#ED6C6C"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        fillRule="evenodd"
        d="M1.333 4c0-.368.299-.667.667-.667h12a.667.667 0 110 1.334H2A.667.667 0 011.333 4z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M6.667 2A.667.667 0 006 2.667v.666h4v-.667A.667.667 0 009.333 2H6.667zm4.666 1.333v-.667a2 2 0 00-2-2H6.667a2 2 0 00-2 2v.667H3.333A.667.667 0 002.667 4v9.333a2 2 0 002 2h6.666a2 2 0 002-2V4a.667.667 0 00-.666-.667h-1.334zM4 4.666v8.667a.667.667 0 00.667.667h6.666a.667.667 0 00.667-.667V4.667H4z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M6.667 6.667c.368 0 .666.298.666.666v4a.667.667 0 11-1.333 0v-4c0-.368.298-.667.667-.667zM9.333 6.667c.369 0 .667.298.667.666v4a.667.667 0 01-1.333 0v-4c0-.368.298-.667.666-.667z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default TrashIcon;
