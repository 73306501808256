import React from 'react';

function ManyMaterialsIcon({ ...rest }) {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.62708 5.25C6.62622 5.25 6.62525 5.25 6.62439 5.25C6.32284 5.25 6.03922 5.36755 5.82505 5.58118C5.60853 5.79717 5.48926 6.08476 5.48926 6.39093V23.4989C5.48926 24.1263 6.00165 24.6379 6.63158 24.6396C9.28724 24.6459 13.7365 25.1994 16.8059 28.4114V10.5074C16.8059 10.2948 16.7516 10.095 16.6491 9.92965C14.1299 5.87264 9.28875 5.25623 6.62708 5.25Z"
        fill="white"
      />
      <path
        d="M29.8927 23.4991V6.39093C29.8927 6.08476 29.7735 5.79717 29.5569 5.58118C29.3428 5.36755 29.0589 5.25 28.7577 5.25C28.7567 5.25 28.7558 5.25 28.7549 5.25C26.0933 5.25633 21.2522 5.87274 18.7329 9.92977C18.6304 10.0951 18.5762 10.2949 18.5762 10.5075V28.4115C21.6456 25.1994 26.0948 24.6459 28.7505 24.6396C29.3803 24.638 29.8927 24.1263 29.8927 23.4991Z"
        fill="white"
      />
      <path
        d="M32.4929 9.19531H31.6656V23.4989C31.6656 25.1007 30.3606 26.4068 28.7563 26.4108C26.5038 26.4162 22.7896 26.8566 20.1592 29.3462C24.7085 28.2323 29.5043 28.9564 32.2375 29.5793C32.5788 29.657 32.9314 29.5767 33.2048 29.3588C33.4773 29.1414 33.6336 28.8164 33.6336 28.4677V10.336C33.6337 9.70706 33.1219 9.19531 32.4929 9.19531Z"
        fill="white"
      />
      <path
        d="M3.71796 23.4989V9.19531H2.89071C2.26185 9.19531 1.75 9.70706 1.75 10.336V28.4673C1.75 28.8162 1.9063 29.1411 2.17876 29.3585C2.45197 29.5763 2.8043 29.6569 3.1461 29.579C5.87927 28.956 10.6752 28.232 15.2243 29.3459C12.594 26.8564 8.87984 26.4161 6.62729 26.4107C5.02314 26.4068 3.71796 25.1007 3.71796 23.4989Z"
        fill="white"
      />
    </svg>
  );
}

export default ManyMaterialsIcon;
