import { useTherapistShortDetails } from 'common/query/therapist/__generated__/get-therapist-short-details';
import ProfessionTitle from 'components/profession-title';
import { usePriceOptions } from 'hooks';
import { DAY_AND_MONTH } from 'lib/constants/date';
import { SESSION_TYPES_ICONS } from 'lib/constants/therapist';
import { getSize, getTherapistPreviewName, TimeUtil } from 'lib/utils';
import { capitalizeText } from 'lib/utils/options-helper';
import React, { FC } from 'react';
import styled, { CSSProp } from 'styled-components';
import { BaseStyleProps } from 'types';
import { Loader, CalendarWithDaysIcon, Avatar } from 'ui';
import { SessionType } from '__generated__/types';
import { ICONS_CSS } from './constants';

const formatLabelToUpperCase = (label: string) => {
  return label
    .split('_')
    .map((word) => {
      return capitalizeText(word);
    })
    .join(' ');
};

const SessionSummary: FC<SessionSummaryProps> = ({
  className,
  sessionType,
  therapistId,
  duration,
  timeUTC,
}) => {
  const { data, loading: loadingTherpaist } = useTherapistShortDetails({
    variables: {
      id: therapistId,
    },
  });

  const therapist = data?.therapistPublicById;

  const priceOptions = usePriceOptions();

  if (loadingTherpaist && !therapist) {
    return (
      <Root>
        <Loader size={50} hasFillWholeBlock />
      </Root>
    );
  }

  const durationWithPrice = priceOptions.find(
    (option) => option.value === duration,
  )?.label;

  const Icon = SESSION_TYPES_ICONS[sessionType];

  return (
    <Root className={className}>
      <PsychologistAvatar size={100} src={therapist?.avatarUrl} />

      <FullName>{getTherapistPreviewName({ ...therapist })}</FullName>

      <ProfessionTitle
        titles={[therapist?.professionalTitle, therapist?.coreTraining.label]}
      />

      <TimeSummary>
        <Wrapper>
          <DescriptionText>When:</DescriptionText>

          <TextContainer>
            <CalendarWithDaysIcon />

            <Text>
              {TimeUtil.getFormatted(timeUTC, DAY_AND_MONTH + ' - ' + 'hh:mmA')}
            </Text>
          </TextContainer>
        </Wrapper>
      </TimeSummary>

      <SummaryInfo>
        <DurationWrapper>
          <DescriptionText>Duration:</DescriptionText>
          <Text>{durationWithPrice}</Text>
        </DurationWrapper>

        <Wrapper>
          <DescriptionText>How:</DescriptionText>
          <TextContainer $CSS={ICONS_CSS[sessionType]}>
            <Icon /> <Text>{formatLabelToUpperCase(sessionType)}</Text>
          </TextContainer>
        </Wrapper>
      </SummaryInfo>
    </Root>
  );
};

interface SessionSummaryProps extends BaseStyleProps {
  therapistId: string;
  duration: string;
  sessionType: SessionType;
  timeUTC: string;
}

const Root = styled.div`
  height: fit-content;
`;

const PsychologistAvatar = styled(Avatar)`
  margin-bottom: ${getSize(22)};
`;

const FullName = styled.p`
  font-weight: 500;
  font-size: ${getSize(20)};
  line-height: ${getSize(24)};
  color: var(--black7);
`;

const TimeSummary = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--gray37);
  border-radius: ${getSize(5)};
  margin-top: 22px;
`;

const Wrapper = styled.div`
  padding: ${getSize(13)} ${getSize(20)};
`;

const DurationWrapper = styled(Wrapper)`
  border-right: 1px solid var(--gray37);
  grid-gap: 1px;
`;

const SummaryInfo = styled(TimeSummary)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${getSize(13)};
`;

const DescriptionText = styled.p`
  font-size: ${getSize(13)};
  line-height: ${getSize(26)};
  color: var(--black3);
`;

const TextContainer = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  align-items: center;
  gap: ${getSize(7)};

  svg {
    width: ${getSize(18)};
    height: ${getSize(18)};
  }

  ${({ $CSS }) => $CSS}
`;

const Text = styled.p`
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  font-weight: 600;
  color: var(--black3);
`;

export default SessionSummary;
