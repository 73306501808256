import React from 'react';

function UserSearchIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#E5E4E8"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path d="M11.003 14.062c.547-.069.997.386.997.938v6a1 1 0 01-1 1H5c-.552 0-1.006-.45-.938-.997a8 8 0 016.94-6.94zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm9.446 7.032l.797.797a1 1 0 11-1.414 1.414l-.797-.797a4 4 0 111.414-1.414zM18 20a2 2 0 100-4 2 2 0 000 4z" />
    </svg>
  );
}

export default UserSearchIcon;
