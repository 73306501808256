import React from 'react';

function FacebookIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="#3B5998"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
      />
      <path
        fill="#fff"
        d="M15.017 12.47h-2.141v7.844H9.632V12.47H8.089V9.713h1.543V7.929c0-1.276.606-3.274 3.273-3.274l2.403.01v2.676h-1.744c-.286 0-.688.143-.688.752v1.622h2.425l-.284 2.755z"
      />
    </svg>
  );
}

export default FacebookIcon;
