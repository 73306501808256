import React from 'react';

function AttachIcon({ ...rest }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0882 8.62856L12.0216 7.59313L7.222 2.93407C6.33745 2.08181 4.90961 2.08438 4.02837 2.93986C3.14714 3.79534 3.14449 5.18145 4.02241 6.04015L8.82174 10.7006C9.01804 10.8913 9.33644 10.8913 9.53285 10.7006C9.72915 10.5099 9.72915 10.2009 9.53285 10.0103L4.73352 5.34983C4.24267 4.87332 4.24267 4.1009 4.73352 3.6244C5.22437 3.14789 6.02004 3.14789 6.5109 3.6244L11.3117 8.28346L12.3783 9.31878C13.2619 10.1769 13.2616 11.568 12.3777 12.4257C11.4937 13.2834 10.0609 13.2832 9.17724 12.4251L8.28861 11.5621L3.31142 6.73036L2.95581 6.38526C1.71289 5.14094 1.72868 3.15861 2.99114 1.93294C4.25371 0.707379 6.29572 0.692053 7.5775 1.89864L12.9102 7.07558C13.0373 7.19883 13.2224 7.24695 13.3959 7.20183C13.5694 7.15671 13.705 7.02521 13.7515 6.85673C13.7979 6.68824 13.7483 6.50862 13.6213 6.38526L8.28861 1.20843C6.61854 -0.405432 3.91797 -0.402431 2.25177 1.21518C0.585453 2.83268 0.582362 5.45432 2.24481 7.07558L7.5775 12.2524L8.46746 13.1154C9.7509 14.3121 11.7849 14.2922 13.043 13.0704C14.3013 11.8487 14.3213 9.87417 13.0882 8.62856V8.62856Z"
        fill="#795DEE"
      />
    </svg>
  );
}

export default AttachIcon;
