import React from 'react';

function ReturnIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="#444752"
      {...rest}
    >
      <path d="M8.531 11.531c0 .26-.21.469-.469.469H2.345a1.877 1.877 0 01-1.875-1.875V4.922a.469.469 0 11.937 0v5.203c0 .517.42.938.938.938h5.719c.258 0 .468.21.468.468zM12 1.406v1.407c0 .258-.21.468-.469.468v3.422a.469.469 0 11-.937 0V3.281H.469A.469.469 0 010 2.812V1.407C0 .631.63 0 1.406 0h9.188C11.369 0 12 .63 12 1.406zM.937 2.344h10.126v-.938a.47.47 0 00-.47-.468H1.407a.47.47 0 00-.468.468v.938zm6.094 2.812a.469.469 0 100-.937h-2.11a.469.469 0 100 .937h2.11zm2.895 2.696H7.453l.588-.581a.469.469 0 10-.66-.667l-.87.861c-.223.22-.346.515-.347.828 0 .313.122.607.344.829l.872.867a.467.467 0 00.663-.002.469.469 0 00-.002-.663l-.537-.535h2.422a1.138 1.138 0 01.013 2.274.469.469 0 100 .937 2.04 2.04 0 001.462-.61 2.077 2.077 0 00-1.475-3.539z" />
    </svg>
  );
}

export default ReturnIcon;
