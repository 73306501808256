import { getSize } from 'lib/utils';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { ArrowIcon } from 'ui/icons';

interface ScrollToItemButtonProps {
  children: ReactNode;
  onClick: () => void;
}

const ScrollToItemButton: FC<ScrollToItemButtonProps> = ({
  children,
  onClick,
}) => {
  return (
    <Wrapper type="button" onClick={onClick}>
      <Icon />
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  position: absolute;
  bottom: ${getSize(16)};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  height: ${getSize(31)};
  border-radius: ${getSize(8)};
  white-space: nowrap;
  background-color: var(--purple3);
  padding: ${getSize(6)} ${getSize(9)};
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
  transition: opacity 0.3s ease-out;
  z-index: 100;

  &:hover {
    opacity: 0.8;
  }
`;

const Icon = styled(ArrowIcon)`
  margin-right: ${getSize(3)};
  width: ${getSize(16)};
  height: ${getSize(16)};
`;

export default ScrollToItemButton;
