import * as React from 'react';

function SvgComponent({ ...props }) {
  return (
    <svg
      width={props.width || 14}
      height={props.height || 14}
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path stroke="#fff" strokeWidth={1.75} d="M.875.875h12.25v12.25H.875z" />
      <path fill="#fff" d="M6.125 7H10.5v3.5H6.125z" />
    </svg>
  );
}

export default SvgComponent;
