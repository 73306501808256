import React from 'react';

function MicrophoneIcon({ ...rest }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_20_19)">
        <path
          d="M20.3462 12.4892C20.3462 12.0074 19.9777 11.6389 19.4959 11.6389C19.0141 11.6389 18.6456 12.0074 18.6456 12.4892C18.6456 15.8052 15.9531 18.4977 12.6371 18.4977C9.32103 18.4977 6.62851 15.8052 6.62851 12.4892C6.62851 12.0074 6.26006 11.6389 5.77825 11.6389C5.29643 11.6389 4.92798 12.0074 4.92798 12.4892C4.92798 16.4288 7.87557 19.7448 11.7868 20.1699V22.4373H8.6975C8.21568 22.4373 7.84723 22.8058 7.84723 23.2876C7.84723 23.7694 8.21568 24.1379 8.6975 24.1379H16.5766C17.0585 24.1379 17.4269 23.7694 17.4269 23.2876C17.4269 22.8058 17.0585 22.4373 16.5766 22.4373H13.4873V20.1699C17.3986 19.7448 20.3462 16.4288 20.3462 12.4892Z"
          fill="white"
        />
        <path
          d="M12.6372 0.0454102C10.0297 0.0454102 7.90405 2.17108 7.90405 4.77857V12.4593C7.90405 15.0951 10.0297 17.1925 12.6372 17.2208C15.2447 17.2208 17.3704 15.0951 17.3704 12.4877V4.77857C17.3704 2.17108 15.2447 0.0454102 12.6372 0.0454102Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_20_19">
          <rect
            width="24.0909"
            height="24.0909"
            fill="white"
            transform="translate(0.591553 0.0454102)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MicrophoneIcon;
