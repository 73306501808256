import { refreshAccessToken, logout } from 'lib/auth';
import { SentryService } from 'lib';
import { DecodeTokenService, StorageService } from 'lib/utils';
import { notifyError } from 'lib/utils/notification';

let refreshingPromise: Promise<any> | null = null;

function getResult(json: any, response: Response) {
  return {
    ...response,
    ok: true,
    json: () => new Promise((resolve) => resolve(json)),
    text: () =>
      new Promise<string>((resolve) => resolve(JSON.stringify(json) as string)),
  };
}

export const customFetch = async (uri: string, options?: any) => {
  const accessToken = StorageService.getAccessToken();
  options.headers.authorization = `Bearer ${accessToken}`;
  const initialRequest = fetch(uri, options);

  return initialRequest.then(async (response) => {
    const json = await response.json();
    const errorCode = json?.errors?.[0]?.extensions?.status;
    if (errorCode === 401) {
      const accessToken = StorageService.getAccessToken();
      if (!accessToken) {
        return getResult(json, response);
      }

      if (!refreshingPromise) {
        const isRememberUser = Boolean(StorageService.getIsRememberUser());
        refreshingPromise = refreshAccessToken({ isRememberUser });
      }

      return refreshingPromise
        .then((newAccessToken) => {
          refreshingPromise = null;
          options.headers.authorization = `Bearer ${newAccessToken}`;
          return fetch(uri, options);
        })
        .catch(async (err) => {
          refreshingPromise = null;
          await notifyError({ text: err });
          await logout();
          return getResult(json, response);
        });
    } else if (json?.errors?.length) {
      SentryService.addBreadcrumb({
        data: {
          userEmail: DecodeTokenService.getUserEmail(accessToken),
          status: response.status,
          statusText: response.statusText,
          type: response.type,
          errors: JSON.stringify(json.errors),
          variables: JSON.parse(options?.body).variables,
        },
      });

      SentryService.captureException(new Error(json.errors[0].message));
    }

    return getResult(json, response);
  });
};
