import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { NoteIcon, SunIcon, ChatIcon, OpenBookIcon, TargetIcon } from 'ui';
import { PATIENT_ROUTES, PATIENT_DASHBOARD_ROUTES } from 'lib/routes';
import { getSize } from 'lib/utils';
import { useGetPatientMeData } from 'common/query/__generated__/get-patient-me-data';
import { useGetMaterialIndicators } from 'common/query/__generated__/get-material-indicators';

const { DASHBOARD } = PATIENT_ROUTES;

const NavBar = () => {
  const { data: patientMeResponseData } = useGetPatientMeData();
  const patientMe = patientMeResponseData?.me?.patient;
  const patientId = patientMe?.id;

  const { data: materialIndicators, refetch: materialIndicatorsRefetch } =
    useGetMaterialIndicators({
      skip: !patientId,
      variables: {
        filter: { patientId: patientId as string },
      },
    });
  const numberOfMaterials = materialIndicators?.getMaterialIndicators?.all;

  return (
    <LinkList>
      <LinkWrapper>
        <StyledLink exact={true} to={DASHBOARD}>
          <InnerLink>
            <SunIcon />
            <LinkText>Therapy Home</LinkText>
          </InnerLink>
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper>
        <StyledLink exact={true} to={PATIENT_DASHBOARD_ROUTES.SESSIONS}>
          <InnerLink>
            <ChatIcon />
            <LinkText>Sessions</LinkText>
          </InnerLink>
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper>
        <StyledLink
          exact={true}
          to={PATIENT_DASHBOARD_ROUTES.NOTES.pathBuild()}
        >
          <InnerLink>
            <NoteIcon />
            <LinkText>Notes</LinkText>
          </InnerLink>
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper>
        <StyledLink
          exact={true}
          to={PATIENT_DASHBOARD_ROUTES.MATERIALS.pathBuild({
            patientId: patientId || '',
          })}
        >
          <InnerLink>
            <OpenBookIcon />
            <LinkText>Materials</LinkText>
          </InnerLink>
          {!!numberOfMaterials && (
            <MyMaterialIndicator>{numberOfMaterials}</MyMaterialIndicator>
          )}
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper>
        <StyledLink exact={true} to={PATIENT_DASHBOARD_ROUTES.GOALS}>
          <InnerLink>
            <TargetIcon />
            <LinkText>Goals</LinkText>
          </InnerLink>
        </StyledLink>
      </LinkWrapper>
    </LinkList>
  );
};

export default NavBar;

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled.li`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(NavLink)`
  width: ${getSize(225)};
  height: ${getSize(49)};
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: #46494b;
  transition: 0.3s ease-out;

  padding: 15px 0 15px 15px;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    border: 1px solid #ededed;
    border-radius: 8px;
    background: white;
  }
`;

const InnerLink = styled.div`
  display: flex;
  align-items: center;
`;

const LinkText = styled.span`
  margin-left: 12px;
  font-weight: 500;
`;

const MyMaterialIndicator = styled.p`
  background: #6b4ee6;
  border-radius: 15px;
  padding: 0 ${getSize(12)};
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: white;
  margin-left: auto;
  margin-right: 6px;
`;
