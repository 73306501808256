import React from 'react';

function EmptyGoalsIcon({ ...rest }) {
  return (
    <svg
      width="176"
      height="149"
      viewBox="0 0 176 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_4496_4729)">
        <path
          d="M165.934 106.631C166.184 106.193 166.426 105.753 166.658 105.305C171.309 96.3908 172.525 85.8174 170.669 74.9392C170.601 74.5295 170.527 74.1198 170.445 73.7101C168.998 66.4129 166.425 59.3877 162.819 52.8884C162.692 52.6529 162.562 52.4172 162.432 52.1817C149.314 28.7583 122.569 8.45649 86.1276 5.75744C45.4037 1.02257 48.8675 33.2089 41.3767 56.1406C41.3028 56.3711 41.2264 56.599 41.1499 56.8269C37.8875 66.4426 34.2228 62.1862 21.025 76.9725C17.1113 83.1201 -11.6105 135.455 57.2784 149.358C89.8339 145.306 75.1629 125.02 96.4984 128.298C134.075 133.809 156.356 123.397 165.934 106.631Z"
          fill="#F2F2F2"
        />
        <ellipse
          cx="117.396"
          cy="35.7109"
          rx="30.6179"
          ry="30.7851"
          stroke="#E4DEFF"
          strokeWidth="5"
          strokeLinejoin="bevel"
        />
        <path
          d="M86.7778 35.7109C86.7778 52.7131 100.486 66.496 117.396 66.496C134.306 66.496 148.014 52.7131 148.014 35.7109C148.014 18.7088 134.306 4.92578 117.396 4.92578"
          stroke="#7A60E9"
          strokeWidth="5"
          strokeLinejoin="bevel"
        />
        <path
          d="M162.819 52.8884C160.205 56.0104 157.259 58.8363 154.034 61.3159C141.471 70.9956 125.559 76.2452 109.862 77.3464C101.794 77.9162 93.6873 77.3629 85.7708 75.7023C77.7168 74.0071 70.0348 71.0212 62.595 67.5335C55.3591 64.1404 48.3374 60.3146 41.1499 56.8269C41.2264 56.599 41.3028 56.371 41.3768 56.1406C49.2142 59.9305 56.891 64.1071 64.8201 67.7178C72.2905 71.1211 80.0233 73.897 88.1105 75.3822C96.1795 76.854 104.414 77.1781 112.573 76.3451C128.228 74.7625 144.152 68.9598 156.187 58.5784C158.447 56.6295 160.536 54.4894 162.432 52.1816C162.562 52.4172 162.692 52.6528 162.819 52.8884Z"
          fill="white"
        />
        <path
          d="M170.669 74.9382C167.172 81.1685 162.235 86.6639 156.582 91.0198C144.019 100.7 128.107 105.949 112.41 107.05C104.342 107.62 96.2354 107.067 88.3189 105.406C80.2649 103.711 72.5829 100.725 65.1432 97.2373C57.5684 93.6855 50.2254 89.6574 42.6836 86.0391C35.1497 82.4233 28.2792 80.2124 20.1665 78.1945C20.7604 78.0895 20.4638 77.102 21.0244 76.9714C21.5546 77.1097 22.083 77.2531 22.6098 77.4016C38.4731 81.8573 52.4734 90.6382 67.3682 97.4217C74.8386 100.825 82.5714 103.601 90.6586 105.086C98.7276 106.558 106.963 106.882 115.121 106.049C130.776 104.466 146.7 98.6637 158.736 88.2823C163.499 84.1912 167.469 79.2504 170.444 73.709C170.526 74.1187 170.6 74.5284 170.669 74.9382Z"
          fill="white"
        />
        <path
          d="M52.4325 103.708C68.0613 108.097 81.8807 116.686 96.5308 123.423C96.3804 123.636 96.23 123.848 96.0771 124.061C95.705 123.889 95.3355 123.718 94.9659 123.544C87.3911 119.992 80.0481 115.964 72.5063 112.345C64.8117 108.653 56.9004 105.516 48.5992 103.501C37.1245 100.724 25.2712 99.8765 13.5209 100.994C13.4927 100.737 13.4674 100.479 13.4443 100.223C26.5432 98.9804 39.7577 100.162 52.4325 103.708Z"
          fill="white"
        />
        <path
          d="M50.0289 48.0035C53.9861 48.0035 57.1941 44.7804 57.1941 40.8045C57.1941 36.8286 53.9861 33.6055 50.0289 33.6055C46.0717 33.6055 42.8638 36.8286 42.8638 40.8045C42.8638 44.7804 46.0717 48.0035 50.0289 48.0035Z"
          fill="#FFB8B8"
        />
        <path
          d="M76.8387 91.6035L77.0059 95.1932L63.3054 97.5498L63.0586 92.2522L76.8387 91.6035Z"
          fill="#A0616A"
        />
        <path
          d="M80.2594 90.5254L80.6398 98.6857L80.4941 98.6925C79.3685 98.767 78.2472 98.4945 77.28 97.9115C76.4054 97.3515 75.9017 96.5758 75.8621 95.7275V95.7273L75.6299 90.7433L80.2594 90.5254Z"
          fill="#2F2E41"
        />
        <path
          d="M76.4557 97.5091L80.0323 97.5088L81.7336 83.6484L76.4551 83.6487L76.4557 97.5091Z"
          fill="#A0616A"
        />
        <path
          d="M75.5435 96.3362L82.5869 96.3359H82.5872C83.1766 96.3359 83.7603 96.4526 84.3049 96.6792C84.8495 96.9058 85.3444 97.238 85.7612 97.6568C86.178 98.0755 86.5086 98.5727 86.7342 99.1199C86.9598 99.667 87.0759 100.253 87.0759 100.846V100.992L75.5437 100.993L75.5435 96.3362Z"
          fill="#2F2E41"
        />
        <path
          d="M42.6897 94.1512C43.0816 93.9126 43.4166 93.5907 43.6712 93.208C43.9259 92.8253 44.0939 92.3912 44.1634 91.9362C44.233 91.4812 44.2024 91.0164 44.0738 90.5746C43.9453 90.1327 43.7218 89.7246 43.4193 89.3789L46.274 60.8438L39.4752 61.3213L38.807 89.2202C38.2627 89.7694 37.9377 90.5 37.8938 91.2738C37.8498 92.0475 38.0899 92.8106 38.5685 93.4184C39.0471 94.0262 39.731 94.4365 40.4905 94.5715C41.2501 94.7066 42.0326 94.557 42.6897 94.1512V94.1512Z"
          fill="#FFB8B8"
        />
        <path
          d="M46.2407 65.7907L39.1957 64.9281C38.9952 64.9036 38.8024 64.8359 38.6303 64.7297C38.4582 64.6236 38.3109 64.4814 38.1985 64.3129C38.086 64.1443 38.0111 63.9535 37.9787 63.7532C37.9463 63.5529 37.9572 63.348 38.0107 63.1524L39.8867 56.2978C40.0175 55.2681 40.5493 54.3324 41.3657 53.6961C42.182 53.0598 43.2161 52.7747 44.2413 52.9033C45.2666 53.0319 46.1992 53.5637 46.8348 54.3821C47.4703 55.2005 47.7569 56.2388 47.6317 57.2692L47.8154 64.3529C47.8206 64.5557 47.7822 64.7573 47.7029 64.9438C47.6236 65.1304 47.5052 65.2976 47.3557 65.4341C47.2063 65.5705 47.0293 65.673 46.8369 65.7346C46.6445 65.7961 46.4411 65.8153 46.2407 65.7907V65.7907Z"
          fill="#6C63FF"
        />
        <path
          d="M45.2556 98.1962C44.4019 98.1375 43.5788 97.8534 42.8693 97.3727C42.1599 96.8919 41.5894 96.2315 41.2154 95.4583C40.8414 94.685 40.6772 93.8262 40.7394 92.9687C40.8016 92.1112 41.0881 91.2855 41.5697 90.5748L46.1715 85.775L62.9459 70.68L71.9138 63.2032C72.4371 62.7669 73.0621 62.4713 73.7301 62.3441C74.3982 62.2168 75.0875 62.2621 75.7334 62.4756C76.3793 62.6892 76.9606 63.064 77.4229 63.565C77.8852 64.066 78.2133 64.6767 78.3765 65.3399L83.0617 84.3827C83.1403 84.6995 83.0989 85.0343 82.9456 85.3222C82.7922 85.6101 82.5379 85.8304 82.2319 85.9404L77.1241 87.7878C76.9531 87.8497 76.7712 87.8752 76.5898 87.8627C76.4084 87.8502 76.2316 87.8001 76.0706 87.7153C75.9095 87.6306 75.7677 87.5132 75.6542 87.3706C75.5407 87.2279 75.4579 87.0632 75.411 86.8867L73.4681 79.5662L62.6572 89.95L66.0302 91.1974C66.3395 91.3132 66.5944 91.5414 66.7444 91.8369C66.8944 92.1323 66.9286 92.4736 66.8402 92.7932L65.7502 96.6731C65.675 96.9437 65.5154 97.183 65.2949 97.3558C65.0744 97.5286 64.8045 97.6258 64.5249 97.6332L45.2556 98.1962Z"
          fill="#2F2E41"
        />
        <path
          d="M44.797 87.3714L44.3533 71.0883L41.5544 63.9464C40.9829 62.4894 40.7353 60.9244 40.8289 59.3611C40.9224 57.7979 41.3549 56.274 42.0961 54.8963C42.8372 53.5185 43.8691 52.3202 45.1196 51.3851C46.37 50.45 47.8087 49.8008 49.335 49.4829C49.5654 49.4348 49.7951 49.388 50.0242 49.3424C51.4884 49.0504 52.9971 49.0695 54.4534 49.3986C55.9098 49.7277 57.2814 50.3594 58.4801 51.2531C59.6788 52.1469 60.678 53.2828 61.4135 54.5879C62.1489 55.893 62.6043 57.3383 62.7504 58.8308L64.0656 71.0892L64.0603 71.1126C61.963 80.4854 55.8941 85.6437 44.9607 87.3459L44.797 87.3714Z"
          fill="#6C63FF"
        />
        <path
          d="M51.417 76.0608C51.5766 76.0361 51.7378 76.0241 51.8992 76.0249L59.9974 63.9793L58.4637 60.8019L62.934 57.8496L66.1931 63.2095C66.4564 63.6425 66.5728 64.1496 66.5248 64.6548C66.4768 65.1599 66.267 65.6357 65.9269 66.0109L54.8435 78.2373C55.0176 78.8371 55.0058 79.4759 54.8097 80.0689C54.6135 80.6618 54.2424 81.1806 53.7456 81.5565C53.2488 81.9323 52.6499 82.1472 52.0286 82.1727C51.4072 82.1982 50.7929 82.033 50.2672 81.6992C49.7416 81.3653 49.3296 80.8786 49.086 80.3037C48.8424 79.7288 48.7788 79.093 48.9036 78.481C49.0284 77.8689 49.3358 77.3095 49.7848 76.8772C50.2337 76.4449 50.8031 76.1601 51.417 76.0608V76.0608Z"
          fill="#FFB8B8"
        />
        <path
          d="M59.2883 63.7546C59.1066 63.7036 58.938 63.6139 58.7939 63.4915C58.6499 63.3691 58.5338 63.2169 58.4537 63.0453L55.6529 57.0532C55.1189 56.2455 54.9254 55.2581 55.1149 54.3073C55.3043 53.3565 55.8613 52.52 56.6636 51.9811C57.4659 51.4422 58.4481 51.2449 59.395 51.4325C60.3418 51.62 61.176 52.1771 61.7148 52.9816L66.1398 57.9153C66.2661 58.0562 66.3607 58.2227 66.4171 58.4035C66.4736 58.5844 66.4906 58.7753 66.467 58.9634C66.4433 59.1514 66.3797 59.3322 66.2802 59.4933C66.1808 59.6544 66.0481 59.792 65.8909 59.8969L60.3682 63.583C60.211 63.6879 60.0332 63.7576 59.8469 63.7872C59.6605 63.8168 59.47 63.8057 59.2883 63.7546V63.7546Z"
          fill="#6C63FF"
        />
        <path
          d="M104.844 113.192H81.1931C80.8522 113.192 80.5252 113.056 80.2842 112.814C80.0431 112.572 79.9077 112.243 79.9077 111.901C79.9077 111.558 80.0431 111.23 80.2842 110.988C80.5252 110.745 80.8522 110.609 81.1931 110.609H104.844C105.184 110.609 105.511 110.745 105.752 110.988C105.993 111.23 106.129 111.558 106.129 111.901C106.129 112.243 105.993 112.572 105.752 112.814C105.511 113.056 105.184 113.192 104.844 113.192V113.192Z"
          fill="#CCCCCC"
        />
        <path
          d="M74.1235 114.484C73.5897 114.484 73.0678 114.325 72.6239 114.027C72.18 113.729 71.8341 113.306 71.6298 112.81C71.4255 112.315 71.372 111.77 71.4762 111.243C71.5803 110.717 71.8374 110.234 72.2149 109.855C72.5924 109.476 73.0733 109.217 73.5969 109.113C74.1205 109.008 74.6633 109.062 75.1565 109.267C75.6497 109.472 76.0713 109.82 76.3678 110.266C76.6644 110.712 76.8227 111.236 76.8227 111.773C76.8219 112.492 76.5373 113.181 76.0313 113.689C75.5252 114.198 74.8392 114.484 74.1235 114.484Z"
          fill="#3F3D56"
        />
        <path
          d="M104.844 120.942H81.1931C80.8522 120.942 80.5252 120.806 80.2842 120.564C80.0431 120.322 79.9077 119.993 79.9077 119.651C79.9077 119.308 80.0431 118.98 80.2842 118.738C80.5252 118.495 80.8522 118.359 81.1931 118.359H104.844C105.184 118.359 105.511 118.495 105.752 118.738C105.993 118.98 106.129 119.308 106.129 119.651C106.129 119.993 105.993 120.322 105.752 120.564C105.511 120.806 105.184 120.942 104.844 120.942V120.942Z"
          fill="#CCCCCC"
        />
        <path
          d="M74.1235 122.235C73.5897 122.235 73.0678 122.075 72.6239 121.777C72.18 121.479 71.8341 121.056 71.6298 120.56C71.4255 120.065 71.372 119.52 71.4762 118.993C71.5803 118.467 71.8374 117.984 72.2149 117.605C72.5924 117.226 73.0734 116.967 73.597 116.863C74.1206 116.758 74.6633 116.812 75.1565 117.017C75.6497 117.222 76.0713 117.57 76.3679 118.016C76.6645 118.462 76.8228 118.986 76.8228 119.523C76.822 120.242 76.5373 120.931 76.0313 121.439C75.5253 121.948 74.8392 122.234 74.1235 122.235Z"
          fill="#3F3D56"
        />
        <path
          d="M104.844 128.69H81.1931C80.8522 128.69 80.5252 128.554 80.2842 128.312C80.0431 128.07 79.9077 127.741 79.9077 127.399C79.9077 127.056 80.0431 126.728 80.2842 126.486C80.5252 126.243 80.8522 126.107 81.1931 126.107H104.844C105.184 126.107 105.511 126.243 105.752 126.486C105.993 126.728 106.129 127.056 106.129 127.399C106.129 127.741 105.993 128.07 105.752 128.312C105.511 128.554 105.184 128.69 104.844 128.69V128.69Z"
          fill="#CCCCCC"
        />
        <path
          d="M74.1235 129.981C73.5897 129.981 73.0678 129.822 72.6239 129.524C72.18 129.226 71.8341 128.802 71.6298 128.306C71.4255 127.811 71.372 127.266 71.4762 126.74C71.5803 126.213 71.8374 125.73 72.2149 125.351C72.5924 124.972 73.0733 124.713 73.5969 124.609C74.1205 124.504 74.6633 124.558 75.1565 124.763C75.6497 124.968 76.0713 125.316 76.3678 125.762C76.6644 126.208 76.8227 126.732 76.8227 127.269C76.8219 127.988 76.5373 128.677 76.0313 129.185C75.5252 129.694 74.8392 129.98 74.1235 129.981Z"
          fill="#3F3D56"
        />
        <path
          d="M111.638 119.617H121.407C121.475 119.617 121.541 119.589 121.589 119.541C121.637 119.492 121.664 119.427 121.664 119.358V58.6821C121.664 58.6136 121.637 58.5479 121.589 58.4995C121.541 58.451 121.475 58.4238 121.407 58.4238C121.339 58.4238 121.273 58.451 121.225 58.4995C121.177 58.5479 121.15 58.6136 121.15 58.6821V119.1H111.638C111.604 119.1 111.571 119.106 111.54 119.119C111.508 119.132 111.48 119.151 111.456 119.175C111.432 119.199 111.413 119.228 111.4 119.259C111.387 119.291 111.38 119.324 111.38 119.358C111.38 119.392 111.387 119.426 111.4 119.457C111.413 119.489 111.432 119.517 111.456 119.541C111.48 119.565 111.508 119.584 111.54 119.597C111.571 119.61 111.604 119.617 111.638 119.617V119.617Z"
          fill="#3F3D56"
        />
        <path
          d="M153.497 19.0242C152.844 19.0771 152.19 18.9341 151.618 18.6135C151.045 18.2928 150.58 17.8089 150.282 17.2228C149.983 16.6367 149.864 15.9748 149.94 15.3208C150.016 14.6668 150.283 14.0501 150.708 13.5486C151.133 13.0472 151.696 12.6835 152.326 12.5036C152.956 12.3237 153.626 12.3357 154.249 12.538C154.873 12.7403 155.423 13.1239 155.83 13.6402C156.236 14.1565 156.481 14.7824 156.534 15.4386C156.605 16.3187 156.324 17.1909 155.755 17.8633C155.185 18.5357 154.373 18.9533 153.497 19.0242V19.0242ZM153.456 18.5137C154.009 18.469 154.536 18.2606 154.971 17.9149C155.406 17.5691 155.729 17.1016 155.899 16.5715C156.07 16.0413 156.08 15.4723 155.928 14.9364C155.777 14.4004 155.471 13.9217 155.048 13.5606C154.626 13.1996 154.107 12.9725 153.556 12.908C153.005 12.8436 152.448 12.9446 151.954 13.1985C151.46 13.4523 151.053 13.8475 150.783 14.3341C150.513 14.8207 150.392 15.3768 150.437 15.9321C150.466 16.3009 150.568 16.6601 150.736 16.9894C150.903 17.3187 151.134 17.6116 151.414 17.8513C151.695 18.091 152.02 18.2729 152.37 18.3865C152.72 18.5002 153.089 18.5434 153.456 18.5137V18.5137Z"
          fill="#CCCCCC"
        />
        <path
          d="M164.907 10.482C166.033 10.482 166.946 9.5648 166.946 8.43338C166.946 7.30196 166.033 6.38477 164.907 6.38477C163.781 6.38477 162.868 7.30196 162.868 8.43338C162.868 9.5648 163.781 10.482 164.907 10.482Z"
          fill="#CCCCCC"
        />
        <path
          d="M174.928 3.07411C175.52 3.07411 176 2.59232 176 1.99799C176 1.40367 175.52 0.921875 174.928 0.921875C174.337 0.921875 173.857 1.40367 173.857 1.99799C173.857 2.59232 174.337 3.07411 174.928 3.07411Z"
          fill="#CCCCCC"
        />
        <path
          d="M137.928 84.549C137.567 85.0983 137.052 85.5277 136.448 85.7828C135.843 86.0379 135.178 86.1072 134.534 85.9821C133.891 85.857 133.299 85.543 132.833 85.0798C132.367 84.6167 132.049 84.0251 131.918 83.38C131.786 82.7349 131.849 82.0653 132.096 81.4557C132.344 80.8461 132.766 80.324 133.309 79.9554C133.852 79.5868 134.492 79.3882 135.147 79.3848C135.802 79.3814 136.444 79.5734 136.991 79.9363V79.9363C137.723 80.4237 138.233 81.1831 138.409 82.0478C138.585 82.9126 138.412 83.8121 137.928 84.549V84.549ZM132.825 81.1609C132.519 81.6257 132.358 82.1712 132.36 82.7283C132.363 83.2854 132.53 83.8291 132.841 84.2908C133.151 84.7524 133.591 85.1112 134.104 85.3217C134.618 85.5323 135.182 85.5851 135.725 85.4736C136.268 85.3621 136.767 85.0913 137.157 84.6953C137.547 84.2993 137.811 83.7961 137.916 83.2491C138.022 82.7021 137.963 82.136 137.749 81.6224C137.534 81.1088 137.172 80.6708 136.71 80.3636V80.3636C136.089 79.9526 135.331 79.8057 134.603 79.9552C133.875 80.1047 133.235 80.5383 132.825 81.1609V81.1609Z"
          fill="#CCCCCC"
        />
        <path
          d="M141.898 96.7925C143.024 96.7925 143.937 95.8753 143.937 94.7439C143.937 93.6125 143.024 92.6953 141.898 92.6953C140.772 92.6953 139.859 93.6125 139.859 94.7439C139.859 95.8753 140.772 96.7925 141.898 96.7925Z"
          fill="#CCCCCC"
        />
        <path
          d="M147.586 106.316C148.177 106.316 148.657 105.834 148.657 105.24C148.657 104.646 148.177 104.164 147.586 104.164C146.994 104.164 146.515 104.646 146.515 105.24C146.515 105.834 146.994 106.316 147.586 106.316Z"
          fill="#CCCCCC"
        />
        <path
          d="M55.6557 28.6386C55.3641 28.049 55.2531 27.3857 55.3366 26.7327C55.4202 26.0796 55.6946 25.4661 56.1252 24.9698C56.5557 24.4735 57.1232 24.1165 57.7556 23.9442C58.3881 23.7718 59.0572 23.7918 59.6784 24.0016C60.2996 24.2113 60.8449 24.6014 61.2454 25.1225C61.646 25.6437 61.8837 26.2724 61.9285 26.9293C61.9734 27.5861 61.8233 28.2416 61.4974 28.8128C61.1715 29.384 60.6843 29.8453 60.0974 30.1383V30.1383C59.3103 30.5302 58.4006 30.5924 57.5679 30.3113C56.7352 30.0301 56.0476 29.4285 55.6557 28.6386V28.6386ZM61.1336 25.9036C60.8869 25.4047 60.4984 24.9905 60.0173 24.7134C59.5363 24.4363 58.9842 24.3087 58.431 24.3469C57.8778 24.385 57.3483 24.5871 56.9094 24.9276C56.4705 25.2681 56.142 25.7317 55.9654 26.2598C55.7887 26.7879 55.7719 27.3568 55.9171 27.8945C56.0622 28.4321 56.3628 28.9145 56.7808 29.2806C57.1988 29.6466 57.7155 29.8799 58.2654 29.951C58.8154 30.022 59.374 29.9276 59.8706 29.6797C60.5359 29.3466 61.0425 28.7619 61.2792 28.054C61.516 27.3461 61.4637 26.5727 61.1336 25.9036Z"
          fill="#CCCCCC"
        />
        <path
          d="M44.8876 29.021C46.0137 29.021 46.9266 28.1039 46.9266 26.9724C46.9266 25.841 46.0137 24.9238 44.8876 24.9238C43.7615 24.9238 42.8486 25.841 42.8486 26.9724C42.8486 28.1039 43.7615 29.021 44.8876 29.021Z"
          fill="#CCCCCC"
        />
        <path
          d="M32.9973 27.7226C33.5889 27.7226 34.0684 27.2408 34.0684 26.6464C34.0684 26.0521 33.5889 25.5703 32.9973 25.5703C32.4058 25.5703 31.9263 26.0521 31.9263 26.6464C31.9263 27.2408 32.4058 27.7226 32.9973 27.7226Z"
          fill="#CCCCCC"
        />
        <path
          d="M57.6005 41.437L57.3018 40.6722C56.4779 38.5622 54.5076 36.9403 52.399 36.6363C51.347 36.498 50.2776 36.6627 49.315 37.111C48.3525 37.5594 47.5364 38.2732 46.9617 39.1692C46.474 40.0193 46.0889 40.9248 45.8147 41.8666C45.7207 42.1469 45.626 42.3499 45.444 42.4112C45.2391 42.4853 45.0352 42.3585 44.6974 42.148C44.622 42.1011 44.535 42.0767 44.4463 42.0777C44.3577 42.0787 44.2712 42.1049 44.1969 42.1534C44.1225 42.2019 44.0635 42.2707 44.0266 42.3516C43.9896 42.4326 43.9764 42.5224 43.9884 42.6106L44.3427 45.1518L44.0462 44.8867C42.1171 43.1615 41.7208 39.9152 43.1143 37.8556L39.9429 38.5102L40.0858 38.2363C41.4029 35.682 43.6731 33.7557 46.4 32.8785L43.3622 32.2063L43.6693 32.0162C46.3897 30.3324 49.9786 30.3798 52.8119 32.1343C54.235 33.0502 55.4136 34.3018 56.2452 35.7799C57.0768 37.2581 57.5362 38.9181 57.5834 40.6154L57.6005 41.437Z"
          fill="#2F2E41"
        />
        <path
          d="M126.516 36.6656H119.244V44.1176H115.428V36.6656H108.156V33.2096H115.428V25.7576H119.244V33.2096H126.516V36.6656Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_4496_4729">
          <rect width="176" height="149" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmptyGoalsIcon;
