import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcademicInitialsVariables = Types.Exact<{ [key: string]: never; }>;


export type AcademicInitials = (
  { __typename: 'Query' }
  & { academicInitials: Array<(
    { __typename: 'AcademicInitialModel' }
    & Pick<Types.AcademicInitialModel, 'id' | 'label' | 'explanation'>
  )> }
);


export const AcademicInitialsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AcademicInitials"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"academicInitials"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useAcademicInitials__
 *
 * To run a query within a React component, call `useAcademicInitials` and pass it any options that fit your needs.
 * When your component renders, `useAcademicInitials` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicInitials({
 *   variables: {
 *   },
 * });
 */
export function useAcademicInitials(baseOptions?: Apollo.QueryHookOptions<AcademicInitials, AcademicInitialsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AcademicInitials, AcademicInitialsVariables>(AcademicInitialsDocument, options);
      }
export function useAcademicInitialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AcademicInitials, AcademicInitialsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AcademicInitials, AcademicInitialsVariables>(AcademicInitialsDocument, options);
        }
export type AcademicInitialsHookResult = ReturnType<typeof useAcademicInitials>;
export type AcademicInitialsLazyQueryHookResult = ReturnType<typeof useAcademicInitialsLazyQuery>;
export type AcademicInitialsQueryResult = Apollo.QueryResult<AcademicInitials, AcademicInitialsVariables>;