import React from 'react';

function EmojiIcon({ ...rest }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8033 2.19668C11.3867 0.780147 9.50332 0 7.5 0C5.49668 0 3.61327 0.780147 2.19668 2.19668C0.780147 3.61327 0 5.49668 0 7.5C0 9.50332 0.780147 11.3868 2.19668 12.8033C3.61327 14.2199 5.49668 15 7.5 15C9.50332 15 11.3867 14.2199 12.8033 12.8033C14.2199 11.3868 15 9.50332 15 7.5C15 5.49668 14.2199 3.61327 12.8033 2.19668ZM7.5 13.8281C4.01065 13.8281 1.17188 10.9893 1.17188 7.5C1.17188 4.01065 4.01065 1.17188 7.5 1.17188C10.9893 1.17188 13.8281 4.01065 13.8281 7.5C13.8281 10.9893 10.9893 13.8281 7.5 13.8281Z"
        fill="#795DEE"
      />
      <path
        d="M10.8105 8.37241C10.8084 8.37868 10.5967 9.00599 10.0775 9.61439C9.41672 10.3887 8.57323 10.7813 7.57046 10.7813C6.56045 10.7813 5.68974 10.384 4.98257 9.60057C4.42827 8.98644 4.18359 8.35428 4.18189 8.34985L3.08398 8.75972C3.09629 8.79259 3.39257 9.57394 4.08046 10.3497C5.01055 11.3987 6.21738 11.9532 7.57046 11.9532C8.93019 11.9532 10.1165 11.3943 11.0011 10.337C11.6549 9.55551 11.9141 8.76815 11.9249 8.73502L10.8105 8.37241Z"
        fill="#795DEE"
      />
      <path
        d="M4.92188 6.21436C5.43964 6.21436 5.85938 5.79462 5.85938 5.27686C5.85938 4.75909 5.43964 4.33936 4.92188 4.33936C4.40411 4.33936 3.98438 4.75909 3.98438 5.27686C3.98438 5.79462 4.40411 6.21436 4.92188 6.21436Z"
        fill="#795DEE"
      />
      <path
        d="M10.0781 6.21436C10.5959 6.21436 11.0156 5.79462 11.0156 5.27686C11.0156 4.75909 10.5959 4.33936 10.0781 4.33936C9.56036 4.33936 9.14062 4.75909 9.14062 5.27686C9.14062 5.79462 9.56036 6.21436 10.0781 6.21436Z"
        fill="#795DEE"
      />
    </svg>
  );
}

export default EmojiIcon;
