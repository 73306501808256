import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function ArrowDownIcon({ ...rest }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#13101F"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path strokeLinecap="square" strokeWidth="2" d="M6 9l6 6 6-6" />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

export default ArrowDownIcon;
