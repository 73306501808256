import React, { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';
import Collapsible from 'react-collapsible';
import { TriggerButton } from './components';
import { getSize } from 'lib/utils';

export interface AccordionProps {
  title: string;
  children: ReactNode;
  className?: string;
  contentCSS?: CSSProp;
  openAfterRender?: boolean;
}

function Accordion({
  title,
  children,
  className,
  contentCSS,
  openAfterRender,
}: AccordionProps) {
  return (
    <Wrapper className={className}>
      <Collapsible
        trigger={<TriggerButton title={title} />}
        transitionTime={300}
        transitionCloseTime={300}
        open={openAfterRender}
        overflowWhenOpen="visible"
      >
        <Content CSS={contentCSS}>{children}</Content>
      </Collapsible>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .Collapsible {
    width: 100%;
  }
  .Collapsible__trigger {
    &.is-open {
      svg {
        transform: scale(-1);
      }
    }
  }
`;

const Content = styled.div<{ CSS?: CSSProp }>`
  ${({ CSS }) => CSS}
`;

export default Accordion;
