import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import { getAddressComponentByName, getSize } from 'lib/utils';
import { Location } from 'interfaces/location';

import { LocationIcon } from 'ui';
import { Suggestions } from './components';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { useWindowClick } from 'hooks';

export interface AddressInputProps extends FieldRenderProps<Location> {
  label?: string;
  rootCSS?: CSSProp;
  inputCSS?: CSSProp;
  labelCSS?: CSSProp;
  inputWrapperCSS?: CSSProp;
  placeholder?: string;
  disabled?: boolean;
  hasExact?: boolean;
  hasUnderLine?: boolean;
  Icon?: (props: any) => JSX.Element;
}

const EXACT_ADDRESS_TYPE = 'street_number';
const ERROR_MESSAGE = 'Provide the exact address';

function AddressInput({
  input,
  meta,
  label,
  rootCSS,
  inputCSS,
  labelCSS,
  placeholder = 'Search Places...',
  disabled,
  hasExact,
  inputWrapperCSS,
  Icon,
  hasUnderLine = true,
}: AddressInputProps) {
  const { setValue, suggestions } = usePlacesAutocomplete();

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [initialValues, setInitialValue] = useState('');
  const [addressError, setAddressError] = useState('');

  useEffect(() => {
    setInitialValue(input.value.fullAddress || '');
  }, [input, setInitialValue]);

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;

  const hasError =
    ((error?.error || error || submitError) &&
      touched &&
      !dirtySinceLastSubmit) ||
    data?.error ||
    addressError;

  const handleSelect = useCallback(
    (address: string) => {
      getGeocode({ address })
        .then(async (results) => {
          let currentError = '';
          const currentResult = results[0];

          const hasExactAddress = currentResult.address_components.some(
            (component) => component.types.includes(EXACT_ADDRESS_TYPE),
          );
          const coordinates = await getLatLng(currentResult);
          const fullAddress = currentResult.formatted_address;
          const addressComponents = currentResult.address_components;
          if (
            (hasExact && hasExactAddress) ||
            (!hasExact && coordinates.lat && coordinates.lng)
          ) {
            input.onChange({
              fullAddress,
              coordinates,
              addressComponents,
              country: getAddressComponentByName(addressComponents, 'country'),
              city: getAddressComponentByName(addressComponents, 'city'),
              postcode: getAddressComponentByName(
                addressComponents,
                'postcode',
              ),
            });
            setValue(currentResult.formatted_address);
          } else {
            currentError = ERROR_MESSAGE;
          }

          setAddressError(currentError);
        })
        .catch((error) => console.error('Error', error))
        .finally(() => setIsInputFocused(false));
    },
    [hasExact, input, setValue],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value;

      setInitialValue(text);
      setValue(text);

      if (!text || text.trim().length === 0) {
        input.onChange({});
      }

      setIsInputFocused(true);
    },
    [input, setValue],
  );

  useWindowClick(() => setIsInputFocused(false));

  return (
    <Wrapper as={label ? 'label' : 'div'} $CSS={rootCSS}>
      {label && <Text $CSS={labelCSS}>{label}</Text>}
      <div onClick={(e) => e.stopPropagation()}>
        <InputWrapper $CSS={inputWrapperCSS} $isInputFocused={isInputFocused}>
          <InputStylized
            value={initialValues}
            onChange={handleChange}
            placeholder={placeholder}
            $CSS={inputCSS}
            disabled={disabled}
            onFocus={() => setIsInputFocused(true)}
          />
          {Icon ? <Icon /> : <LocationIcon className="location-icon__svg" />}
        </InputWrapper>
        <Suggestions
          loading={suggestions.loading}
          suggestions={suggestions.data}
          isInputFocused={isInputFocused}
          onSelect={handleSelect}
        />
      </div>

      {hasUnderLine && (
        <Line $hasError={hasError} $isInputFocused={isInputFocused} />
      )}

      {hasError && (
        <ErrorText>
          {addressError || error?.error || error || submitError || data?.error}
        </ErrorText>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp }>`
  position: relative;
  display: block;

  ${({ $CSS }) => $CSS}
`;

const Text = styled.span<{ $CSS?: CSSProp }>`
  display: block;
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);

  ${({ $CSS }) => $CSS}
`;

const InputWrapper = styled.div<{ $CSS?: CSSProp; $isInputFocused?: boolean }>`
  display: flex;
  align-items: center;

  & > .location-icon__svg {
    width: ${getSize(22)};
    height: ${getSize(22)};
  }

  ${({ $CSS }) => $CSS}
`;

const InputStylized = styled.input<{ $CSS?: CSSProp }>`
  width: 100%;
  margin: 0 ${getSize(10)} 0 0;
  padding: ${getSize(10)} 0;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--black3);
  border: 0;

  &::placeholder {
    color: var(--gray7);
  }

  ${({ $CSS }) => $CSS}
`;

const Line = styled.div<{ $isInputFocused: boolean; $hasError: boolean }>`
  width: 100%;
  height: 1px;
  border-radius: ${getSize(8)};
  background: var(
    ${({ $isInputFocused, $hasError }) => {
      if ($hasError) {
        return '--red';
      } else if ($isInputFocused) {
        return '--purple';
      } else {
        return '--purple3';
      }
    }}
  );
  transition: 0.3s ease-out;
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default AddressInput;
