import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPostsBySubCategoryVariables = Types.Exact<{
  subCategoryIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type GetPostsBySubCategory = (
  { __typename: 'Query' }
  & { getPostsBySubCategory: Array<(
    { __typename: 'PostDetailsModel' }
    & Pick<Types.PostDetailsModel, 'id' | 'title' | 'subtitle' | 'imageUrl' | 'author' | 'subCategoryId' | 'type'>
  )> }
);


export const GetPostsBySubCategoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPostsBySubCategory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"subCategoryIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPostsBySubCategory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"subCategoryIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"subCategoryIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"subtitle"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"subCategoryId"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetPostsBySubCategory__
 *
 * To run a query within a React component, call `useGetPostsBySubCategory` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsBySubCategory` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsBySubCategory({
 *   variables: {
 *      subCategoryIds: // value for 'subCategoryIds'
 *   },
 * });
 */
export function useGetPostsBySubCategory(baseOptions: Apollo.QueryHookOptions<GetPostsBySubCategory, GetPostsBySubCategoryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsBySubCategory, GetPostsBySubCategoryVariables>(GetPostsBySubCategoryDocument, options);
      }
export function useGetPostsBySubCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsBySubCategory, GetPostsBySubCategoryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsBySubCategory, GetPostsBySubCategoryVariables>(GetPostsBySubCategoryDocument, options);
        }
export type GetPostsBySubCategoryHookResult = ReturnType<typeof useGetPostsBySubCategory>;
export type GetPostsBySubCategoryLazyQueryHookResult = ReturnType<typeof useGetPostsBySubCategoryLazyQuery>;
export type GetPostsBySubCategoryQueryResult = Apollo.QueryResult<GetPostsBySubCategory, GetPostsBySubCategoryVariables>;