import React from 'react';

const ChatIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3646 4.26905C11.292 2.07887 9.04819 0.630859 6.53138 0.630859C2.93011 0.630859 0.000285974 3.54406 0.000285974 7.12485C0.000285974 8.09798 0.209207 9.0307 0.621557 9.90034L0.0110846 12.9531C-0.0666207 13.3417 0.276188 13.6851 0.664862 13.6083L3.75522 12.9973C4.17132 13.1946 4.60178 13.3448 5.04407 13.4482C4.05265 8.75243 7.61194 4.34468 12.3646 4.26905Z"
        fill="#6C4FE7"
      />
      <path
        d="M18.3787 14.6501C18.4853 14.4252 18.5781 14.196 18.6574 13.9631H18.637C20.0267 9.88422 17.1042 5.5973 12.7899 5.38856C12.7899 5.38834 12.7898 5.38811 12.7898 5.38789C9.07757 5.21682 5.99341 8.18907 5.99341 11.8746C5.99341 15.4524 8.90127 18.3633 12.4779 18.3685C13.448 18.3672 14.3779 18.1583 15.245 17.747C18.6295 18.4162 18.3656 18.3686 18.4433 18.3686C18.7951 18.3686 19.0578 18.0463 18.9891 17.7028L18.3787 14.6501Z"
        fill="#6C4FE7"
      />
    </svg>
  );
};

export default ChatIcon;
