import React from 'react';

function CreditCardIcon() {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 4.04883V3.04688C19 1.89916 18.0696 0.96875 16.9219 0.96875H2.07812C0.930406 0.96875 0 1.89916 0 3.04688V4.04883C0 4.15129 0.0830879 4.23438 0.185547 4.23438H18.8145C18.9169 4.23438 19 4.15129 19 4.04883Z"
        fill="#6B4EE6"
      />
      <path
        d="M0 5.60742V11.9531C0 13.1008 0.930406 14.0312 2.07812 14.0312H16.9219C18.0696 14.0312 19 13.1008 19 11.9531V5.60742C19 5.50496 18.9169 5.42188 18.8145 5.42188H0.185547C0.0830879 5.42188 0 5.50496 0 5.60742ZM4.75 10.4688C4.75 10.7966 4.48415 11.0625 4.15625 11.0625H3.5625C3.2346 11.0625 2.96875 10.7966 2.96875 10.4688V9.875C2.96875 9.5471 3.2346 9.28125 3.5625 9.28125H4.15625C4.48415 9.28125 4.75 9.5471 4.75 9.875V10.4688Z"
        fill="#6B4EE6"
      />
    </svg>
  );
}

export default CreditCardIcon;
