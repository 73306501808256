import React from 'react';

function PngIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      {...rest}
    >
      <path
        d="M24.372 6.253l-5.25-5.25a.438.438 0 00-.31-.128H6.563A3.066 3.066 0 003.5 3.938v20.125a3.066 3.066 0 003.063 3.062h14.875a3.066 3.066 0 003.062-3.063v-17.5a.437.437 0 00-.128-.309zM19.25 2.37l3.756 3.756h-1.569a2.19 2.19 0 01-2.187-2.188V2.37zM6.562 1.75h11.813v2.188A3.066 3.066 0 0021.438 7h2.187v12.25H4.375V3.937A2.19 2.19 0 016.563 1.75zm14.875 24.5H6.563a2.19 2.19 0 01-2.187-2.188v-3.937h19.25v3.938a2.19 2.19 0 01-2.188 2.187z"
        fill="#7A60E9"
      />
      <path
        d="M10.063 21h-.876a.438.438 0 00-.437.438v3.5a.438.438 0 00.875 0v-1.313h.438a1.313 1.313 0 000-2.625zm0 1.75h-.438v-.875h.438a.438.438 0 010 .875zM18.813 22.75h-.875a.438.438 0 000 .875h.385c-.123.504-.45.875-.823.875-.474 0-.875-.601-.875-1.313 0-.71.4-1.312.875-1.312.087 0 .173.02.251.058a.437.437 0 10.373-.792A1.455 1.455 0 0017.5 21c-.965 0-1.75.981-1.75 2.188 0 1.206.785 2.187 1.75 2.187s1.75-.981 1.75-2.188a.438.438 0 00-.438-.437zM14.438 21a.438.438 0 00-.438.438v1.646l-.921-1.842a.437.437 0 00-.829.195v3.5a.438.438 0 00.875 0v-1.646l.921 1.842a.437.437 0 00.829-.195v-3.5a.438.438 0 00-.438-.438zM9.625 16.625h1.75a.438.438 0 00.438-.438v-1.312h.874v1.313a.438.438 0 00.438.437h1.75a.438.438 0 00.438-.438v-1.312h.874v1.313a.438.438 0 00.438.437h1.75a.438.438 0 00.438-.438v-1.75a.438.438 0 00-.438-.437h-1.313v-.875h1.313a.438.438 0 00.438-.438v-1.75a.438.438 0 00-.438-.437h-1.313v-.875h1.313a.438.438 0 00.438-.438v-1.75A.438.438 0 0018.375 7h-1.75a.438.438 0 00-.438.438V8.75h-.875V7.437A.438.438 0 0014.876 7h-1.75a.438.438 0 00-.438.438V8.75h-.874V7.437A.438.438 0 0011.374 7h-1.75a.437.437 0 00-.438.438v1.75a.437.437 0 00.438.437h1.313v.875H9.624a.438.438 0 00-.438.438v1.75a.438.438 0 00.438.437h1.313V14H9.624a.438.438 0 00-.438.438v1.75a.438.438 0 00.438.437zm3.063-5.25v.875h-.876v-.875h.876zm3.5.875h-.875v-.875h.874v.875zm-1.75 0h-.876v-.875h.876v.875zm-.876.875h.876V14h-.876v-.875zm.876-2.625h-.876v-.875h.876v.875zm-2.626 2.625h.876V14h-.876v-.875zm2.626 2.625h-.876v-.875h.876v.875zm.874-2.625h.876V14h-.875v-.875zm2.626 2.625h-.875v-.875h.875v.875zm0-3.5h-.875v-.875h.875v.875zm-.875-4.375h.875v.875h-.875v-.875zm-.875 2.625h-.875v-.875h.874v.875zm-2.625-2.625h.874v.875h-.874v-.875zm-.876 2.625h-.874v-.875h.874v.875zm-2.624-2.625h.874v.875h-.874v-.875zm0 3.5h.874v.875h-.874v-.875zm0 3.5h.874v.875h-.874v-.875z"
        fill="#7A60E9"
      />
    </svg>
  );
}

export default PngIcon;
