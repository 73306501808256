import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import { amplitudeService } from 'lib/amplitude';
import { Filters } from 'amplitude-analytics/enums';
import THEMES from './utils/themes';
import { AmplitudeEvents } from '../../../lib/amplitude';

export type BubbleCheckboxTheme = 'primary' | 'secondary';
export interface BubbleCheckboxProps extends FieldRenderProps<string> {
  label?: string;
  theme?: BubbleCheckboxTheme;
  rootCSS?: CSSProp;
  labelCSS?: CSSProp;
  disabled?: boolean;
  errorMessage: string;
  setErrorMessage: (error: string) => void;
  filterType: Filters;
}

function BubbleCheckbox({
  input,
  meta,
  label,
  rootCSS,
  labelCSS,
  disabled,
  theme = 'primary',
  errorMessage,
  setErrorMessage,
  filterType,
}: BubbleCheckboxProps) {
  useEffect(() => {
    const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
    const hasError =
      ((error || submitError) && touched && !dirtySinceLastSubmit) ||
      data?.error;

    if (!errorMessage && hasError) {
      const newErrorMessage = error || submitError || data?.error;
      setErrorMessage(newErrorMessage);
    } else if (errorMessage && !hasError) {
      setErrorMessage('');
    }
  }, [errorMessage, meta, setErrorMessage]);

  useEffect(() => {
    if (input.checked && filterType === Filters.this_is_for) {
      amplitudeService.logEvent(AmplitudeEvents.FILTER_ADDED, {
        type: filterType,
        value: label,
      });
    }
  }, [input.checked]);

  return (
    <Wrapper $CSS={rootCSS} $isDisabled={disabled}>
      <Input {...input} disabled={disabled} />
      <Text $isChecked={input.checked} $theme={theme} $CSS={labelCSS}>
        {label}
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.label<{
  $CSS?: CSSProp;
  $isDisabled?: boolean;
}>`
  display: block;
  ${({ $isDisabled }) => ($isDisabled ? '' : 'cursor: pointer;')}

  ${({ $CSS }) => $CSS}
`;

const Input = styled.input`
  display: none;
`;

const Text = styled.span<{
  $isChecked?: boolean;
  $theme: BubbleCheckboxTheme;
  $CSS?: CSSProp;
}>`
  display: block;

  ${({ $theme, $isChecked }) => THEMES[$theme]?.($isChecked) || ''}

  ${({ $CSS }) => $CSS}
`;

export default BubbleCheckbox;
