export enum Filters {
  help_with = 'help_with',
  therapist_type = 'therapist_type',
  therapist_approach = 'therapist_approach',
  this_is_for = 'this_is_for',
  how = 'how',
}

export enum Location {
  therapists_screen = 'therapists screen',
  start_assessment = 'start my assessment modal',
  resume_modal = 'resume modal',
  all_therapists_page = 'all therapists page modal',
  guided_matching_modal = 'guided matching modal',
  sign_up_therapist = 'sign up as therapist',
  sign_up_user = 'sign up as user',
  none = 'none',
}
