import React, { InputHTMLAttributes, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import TextareaAutosize from 'react-autosize-textarea';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';

type InputProps = {
  label?: string;
  rootCSS?: CSSProp;
  areaCSS?: CSSProp;
  labelCSS?: CSSProp;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  hasLine?: boolean;
} & FieldRenderProps<string> &
  InputHTMLAttributes<HTMLTextAreaElement>;

function Textarea({
  input,
  meta,
  label,
  rootCSS,
  areaCSS,
  labelCSS,
  placeholder,
  disabled,
  maxLength,
  hasLine = true,
  ...props
}: InputProps) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  return (
    <Wrapper as={label ? 'label' : 'div'} $CSS={rootCSS}>
      {label && <Text $CSS={labelCSS}>{label}</Text>}

      <TextareaStylized
        {...props}
        $CSS={areaCSS}
        placeholder={placeholder}
        {...input}
        disabled={disabled}
        maxLength={maxLength}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
      />
      {hasLine && (
        <Line $hasError={hasError} $isInputFocused={isInputFocused} />
      )}

      {hasError && <ErrorText>{error || submitError || data?.error}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp }>`
  display: block;

  ${({ $CSS }) => $CSS}
`;

const Text = styled.span<{ $CSS?: CSSProp }>`
  display: block;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray2);

  ${({ $CSS }) => $CSS}
`;

const TextareaStylized = styled(TextareaAutosize)<{ $CSS?: CSSProp }>`
  display: block;
  width: 100%;
  height: auto;
  padding: ${getSize(10)} 0;
  font-weight: 400;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--black3);
  border: 0;
  resize: none;

  &::placeholder {
    color: var(--gray2);
  }

  ${({ $CSS }) => $CSS}
`;

const Line = styled.div<{ $isInputFocused: boolean; $hasError: boolean }>`
  width: 100%;
  height: ${getSize(1)};
  border-radius: ${getSize(8)};
  background: var(
    ${({ $isInputFocused, $hasError }) => {
      if ($hasError) {
        return '--red';
      } else if ($isInputFocused) {
        return '--purple';
      } else {
        return '--purple3';
      }
    }}
  );
  transition: 0.3s ease-out;
`;

const ErrorText = styled.span`
  margin: ${getSize(3)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default Textarea;
