import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNearestSessionsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetNearestSessions = (
  { __typename: 'Query' }
  & { nearestSessions?: Types.Maybe<Array<(
    { __typename: 'SessionModel' }
    & Pick<Types.SessionModel, 'id' | 'sessionType' | 'startDateUtc' | 'endDateUtc'>
    & { patientConnected: (
      { __typename: 'PatientConnectedModel' }
      & Pick<Types.PatientConnectedModel, 'chatId'>
    ), sessionOutcome?: Types.Maybe<(
      { __typename: 'SessionOutcomeModel' }
      & Pick<Types.SessionOutcomeModel, 'id'>
    )> }
  )>> }
);


export const GetNearestSessionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetNearestSessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nearestSessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sessionType"}},{"kind":"Field","name":{"kind":"Name","value":"startDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"endDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"patientConnected"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chatId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sessionOutcome"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetNearestSessions__
 *
 * To run a query within a React component, call `useGetNearestSessions` and pass it any options that fit your needs.
 * When your component renders, `useGetNearestSessions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNearestSessions({
 *   variables: {
 *   },
 * });
 */
export function useGetNearestSessions(baseOptions?: Apollo.QueryHookOptions<GetNearestSessions, GetNearestSessionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNearestSessions, GetNearestSessionsVariables>(GetNearestSessionsDocument, options);
      }
export function useGetNearestSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNearestSessions, GetNearestSessionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNearestSessions, GetNearestSessionsVariables>(GetNearestSessionsDocument, options);
        }
export type GetNearestSessionsHookResult = ReturnType<typeof useGetNearestSessions>;
export type GetNearestSessionsLazyQueryHookResult = ReturnType<typeof useGetNearestSessionsLazyQuery>;
export type GetNearestSessionsQueryResult = Apollo.QueryResult<GetNearestSessions, GetNearestSessionsVariables>;