import React from 'react';

/** Icons */
import {
  CheckEmailIcon,
  CheckSuccessIcon,
  CheckSuccessSmallIcon,
  HideEyeIcon,
  ArrowDownIcon,
  AlertIcon,
  CrossIcon,
  FacebookIcon,
  GoogleIcon,
  RoundCrossIcon,
  AttachIcon,
  PhotoCameraIcon,
  VideoCameraIcon,
  VideoCameraIconMuted,
  AppsIcon,
  MailIcon,
  UserSearchIcon,
  CalendarIcon,
  VideoChatIcon,
  InPersonIcon,
  LiveChatIcon,
  LocationMiniIcon,
  TriangleIcon,
  CheckedSelectIcon,
  LocationIcon,
  CheckedWorkTypeIcon,
  CalendarSecondaryIcon,
  PatientIcon,
  PaymentsIcon,
  UserPrimaryIcon,
  CloseIcon,
  PlusIcon,
  CircleLoaderIcon,
  MasterCardIcon,
  VisaClassicIcon,
  FourDots,
  TrashIcon,
  ShieldIcon,
  MtaLogoIcon,
  CheckMarkWithCircleIcon,
  PdfIcon,
  UploadIcon,
  HomeIcon,
  ArrowIcon,
  FolderIcon,
  TrashSecondaryIcon,
  DownloadIcon,
  ThreeDotsIcon,
  MapFullscreenIcon,
  YahooIcon,
  OutlookIcon,
  GmailIcon,
  MessageIcon,
  SearchIcon,
  DoubleArrowIcon,
  EditIcon,
  SendPlaneIcon,
  UserSecondaryIcon,
  DocIcon,
  DocxIcon,
  JpegIcon,
  Mp4Icon,
  PngIcon,
  ReturnIcon,
  DownloadIconMini,
  ClockIcon,
  ReceiptIcon,
  CircleCheckIcon,
  MicrophoneIcon,
  MicrophoneIconMuted,
  CallIcon,
  VideoCallIcon,
  ArrowLongIcon,
  RefundIcon,
  JpgIcon,
  GoalsDeleteIcon,
  StarIcon,
  CanceledPaymentIcon,
  Mp3Icon,
  InsurancePendingStatusIcon,
  InsuranceConfirmedIcon,
  MapIcon,
  FullyBookedIcon,
  AppleIcon,
  EmojiIcon,
  ClockLargeIcon,
  ResourceIcon,
  ArrowRightIcon,
  RoundCheckIcon,
  ArrowRightShortIcon,
  FoldedCalendarIcon,
  CalendarWideIcon,
  ClockCutIcon,
  MapPinIcon,
  CalendarWithDaysIcon,
  RetryIcon,
  NoteIcon,
  SavedIcon,
  ChatIcon,
  SunIcon,
  TargetIcon,
} from 'ui';

const Sandbox = () => {
  return (
    <div>
      SANDBOX
      <div>
        <CheckEmailIcon />
      </div>
      <div>
        <CheckSuccessIcon />
      </div>
      <div>
        <CheckSuccessSmallIcon />
      </div>
      <div>
        <HideEyeIcon />
      </div>
      <div>
        <ArrowDownIcon />
      </div>
      <div>
        <AlertIcon />
      </div>
      <div>
        <CrossIcon />
      </div>
      <div>
        <FacebookIcon />
      </div>
      <div>
        <GoogleIcon />
      </div>
      <div>
        <RoundCrossIcon />
      </div>
      <div>
        <AttachIcon />
      </div>
      <div>
        <PhotoCameraIcon />
      </div>
      <div>
        <VideoCameraIcon />
      </div>
      <div>
        <VideoCameraIconMuted />
      </div>
      <div>
        <AppsIcon />
      </div>
      <div>
        <MailIcon />
      </div>
      <div>
        <UserSearchIcon />
      </div>
      <div>
        <CalendarIcon />
      </div>
      <div>
        <VideoChatIcon />
      </div>
      <div>
        <InPersonIcon />
      </div>
      <div>
        <LiveChatIcon />
      </div>
      <div>
        <LocationMiniIcon />
      </div>
      <div>
        <TriangleIcon />
      </div>
      <div>
        <CheckedSelectIcon />
      </div>
      <div>
        <LocationIcon />
      </div>
      <div>
        <CheckedWorkTypeIcon />
      </div>
      <div>
        <CalendarSecondaryIcon />
      </div>
      <div>
        <PatientIcon />
      </div>
      <div>
        <PaymentsIcon />
      </div>
      <div>
        <UserPrimaryIcon />
      </div>
      <div>
        <CloseIcon />
      </div>
      <div>
        <PlusIcon />
      </div>
      <div>
        <CircleLoaderIcon />
      </div>
      <div>
        <MasterCardIcon />
      </div>
      <div>
        <VisaClassicIcon />
      </div>
      <div>
        <FourDots />
      </div>
      <div>
        <TrashIcon />
      </div>
      <div>
        <ShieldIcon />
      </div>
      <div>
        <MtaLogoIcon />
      </div>
      <div>
        <CheckMarkWithCircleIcon />
      </div>
      <div>
        <PdfIcon />
      </div>
      <div>
        <UploadIcon />
      </div>
      <div>
        <HomeIcon />
      </div>
      <div>
        <ArrowIcon />
      </div>
      <div>
        <FolderIcon />
      </div>
      <div>
        <TrashSecondaryIcon />
      </div>
      <div>
        <DownloadIcon />
      </div>
      <div>
        <ThreeDotsIcon />
      </div>
      <div>
        <MapFullscreenIcon />
      </div>
      <div>
        <YahooIcon />
      </div>
      <div>
        <OutlookIcon />
      </div>
      <div>
        <GmailIcon />
      </div>
      <div>
        <MessageIcon />
      </div>
      <div>
        <SearchIcon />
      </div>
      <div>
        <DoubleArrowIcon />
      </div>
      <div>
        <EditIcon />
      </div>
      <div>
        <SendPlaneIcon />
      </div>
      <div>
        <UserSecondaryIcon />
      </div>
      <div>
        <DocIcon />
      </div>
      <div>
        <DocxIcon />
      </div>
      <div>
        <JpegIcon />
      </div>
      <div>
        <Mp4Icon />
      </div>
      <div>
        <PngIcon />
      </div>
      <div>
        <ReturnIcon />
      </div>
      <div>
        <DownloadIconMini />
      </div>
      <div>
        <ClockIcon />
      </div>
      <div>
        <ReceiptIcon />
      </div>
      <div>
        <CircleCheckIcon />
      </div>
      <div>
        <MicrophoneIcon />
      </div>
      <div>
        <MicrophoneIconMuted />
      </div>
      <div>
        <CallIcon />
      </div>
      <div>
        <VideoCallIcon />
      </div>
      <div>
        <ArrowLongIcon />
      </div>
      <div>
        <RefundIcon />
      </div>
      <div>
        <JpgIcon />
      </div>
      <div>
        <GoalsDeleteIcon />
      </div>
      <div>
        <StarIcon />
      </div>
      <div>
        <CanceledPaymentIcon />
      </div>
      <div>
        <Mp3Icon />
      </div>
      <div>
        <InsurancePendingStatusIcon />
      </div>
      <div>
        <InsuranceConfirmedIcon />
      </div>
      <div>
        <MapIcon />
      </div>
      <div>
        <FullyBookedIcon />
      </div>
      <div>
        <AppleIcon />
      </div>
      <div>
        <EmojiIcon />
      </div>
      <div>
        <ClockLargeIcon />
      </div>
      <div>
        <ResourceIcon />
      </div>
      <div>
        <ArrowRightIcon />
      </div>
      <div>
        <RoundCheckIcon />
      </div>
      <div>
        <ArrowRightShortIcon />
      </div>
      <div>
        <FoldedCalendarIcon />
      </div>
      <div>
        <CalendarWideIcon />
      </div>
      <div>
        <ClockCutIcon />
      </div>
      <div>
        <MapPinIcon />
      </div>
      <div>
        <CalendarWithDaysIcon />
      </div>
      <div>
        <RetryIcon />
      </div>
      <div>
        <NoteIcon />
      </div>
      <div>
        <SavedIcon />
      </div>
    </div>
  );
};

export default Sandbox;
