import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';
import { useChatAdded } from '../subscription/__generated__/chat-added';
import { useChatChanged } from '../subscription/__generated__/chat-changed';
import { useMessageAdded } from '../subscription/__generated__/message-added';
import { getScrollPosition } from 'components/chat/utils';
import {
  updateChatMessagesCache,
  updateChatListCacheAfterAdded,
  updateChatListCacheAfterChanged,
  scrollDialog,
} from '../utils';
import { ReadChatMessagesArgs } from './use-read-chat-messages';
import { useMessagesRemoved } from '../subscription/__generated__/messages-removed';
import { updateChatMessagesCacheAfterDeleting } from '../utils/update-chat-messages-cache';
import { useApolloClient } from '@apollo/client';

interface UseChatSubscriptionsArgs {
  messageListRef: RefObject<HTMLDivElement>;
  selectedChatId: string;
  setCurrentUnreadCount: Dispatch<SetStateAction<number>>;
  readChatMessages: (args: ReadChatMessagesArgs) => void;
  userId?: string;
  onChatClick: (chatId: string) => void;
}

export function useChatSubscriptions({
  messageListRef,
  selectedChatId,
  setCurrentUnreadCount,
  readChatMessages,
  userId,
  onChatClick,
}: UseChatSubscriptionsArgs) {
  useMessageAdded({
    onSubscriptionData: ({ client, subscriptionData }) => {
      updateChatMessagesCache({ client, subscriptionData });
      const dialogWrapper = messageListRef.current;

      if (!dialogWrapper || !subscriptionData.data) return;
      const { chatId, sender } = subscriptionData.data.messageAdded;
      const { isNearTop } = getScrollPosition(dialogWrapper);

      if (chatId === selectedChatId) {
        if (isNearTop) {
          readChatMessages({
            chatId,
            onSuccess: () => setCurrentUnreadCount(0),
          });
        }
        if (sender.id === userId) {
          setTimeout(() => scrollDialog({ messageListRef }));
        }
      }
    },
  });

  useChatAdded({
    onSubscriptionData: ({ client, subscriptionData }) => {
      updateChatListCacheAfterAdded({ client, subscriptionData });
      const newChatId = subscriptionData.data?.chatAdded.id;
      if (newChatId) onChatClick(newChatId);
    },
  });

  useChatChanged({
    onSubscriptionData: (args) => {
      const incomingChat = args.subscriptionData.data?.chatChanged;

      updateChatListCacheAfterChanged({ userId, selectedChatId, ...args });

      if (incomingChat) {
        const { id: chatId, unreadCount } = incomingChat;

        if (selectedChatId === chatId) {
          setCurrentUnreadCount(unreadCount);
        }
      }
    },
  });

  useMessagesRemoved({
    onSubscriptionData: updateChatMessagesCacheAfterDeleting,
  });
  const client = useApolloClient();
  useEffect(() => {
    return () => {
      client.cache.evict({
        fieldName: 'chatMessages',
      });
      client.cache.evict({
        fieldName: 'listChats',
      });
      client.cache.gc();
    };
  }, []);
}
