import React, { useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import { FieldValidator } from 'final-form';
import { Field } from 'react-final-form';
import { getSize } from 'lib/utils';
import { StarRadioButton } from '../star-radio-button';

export interface Option {
  value: string;
}

export interface StarRadioGroupProps {
  className?: string;
  options: Option[];
  name: string;
  contentCSS?: CSSProp;
  disabled?: boolean;
  validate?: FieldValidator<string>;
  selectedRating?: string;
}

function StarRadioGroup({
  className,
  options,
  name,
  disabled,
  validate,
  contentCSS,
  selectedRating,
}: StarRadioGroupProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Wrapper className={className}>
      <Inner $CSS={contentCSS}>
        {options.map(({ value }) => (
          <Field
            key={value}
            id={value}
            name={name}
            type="radio"
            value={value}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            validate={validate}
            disabled={disabled}
            selectedRating={selectedRating}
            component={StarRadioButton}
          />
        ))}
      </Inner>

      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${getSize(20)};
`;

const Inner = styled.div<{ $CSS?: CSSProp }>`
  display: flex;

  ${({ $CSS }) => $CSS}
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default StarRadioGroup;
