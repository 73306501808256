import { useStopwatch } from 'hooks/use-stopwatch';
import { getSize } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';

const VideoStopwatch = () => {
  const { stopwatch } = useStopwatch();

  return <Wrapper>{stopwatch}</Wrapper>;
};

const Wrapper = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray7);
`;

export default VideoStopwatch;
