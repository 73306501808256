import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPostDetailsVariables = Types.Exact<{
  postId: Types.Scalars['String'];
}>;


export type GetPostDetails = (
  { __typename: 'Query' }
  & { getPostDetails: (
    { __typename: 'PostDetailsModel' }
    & Pick<Types.PostDetailsModel, 'id' | 'createdAt' | 'title' | 'subtitle' | 'author' | 'imageUrl' | 'videoUrl' | 'postText' | 'subCategoryId' | 'type'>
  ) }
);


export const GetPostDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPostDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"postId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPostDetails"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"postId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"postId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"subtitle"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"videoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"postText"}},{"kind":"Field","name":{"kind":"Name","value":"subCategoryId"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetPostDetails__
 *
 * To run a query within a React component, call `useGetPostDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetPostDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostDetails({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetPostDetails(baseOptions: Apollo.QueryHookOptions<GetPostDetails, GetPostDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostDetails, GetPostDetailsVariables>(GetPostDetailsDocument, options);
      }
export function useGetPostDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostDetails, GetPostDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostDetails, GetPostDetailsVariables>(GetPostDetailsDocument, options);
        }
export type GetPostDetailsHookResult = ReturnType<typeof useGetPostDetails>;
export type GetPostDetailsLazyQueryHookResult = ReturnType<typeof useGetPostDetailsLazyQuery>;
export type GetPostDetailsQueryResult = Apollo.QueryResult<GetPostDetails, GetPostDetailsVariables>;