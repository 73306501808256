import React, { FC } from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { Link, ArrowRightShortIcon } from 'ui';

type DashboardSectionHeaderProps = {
  showAllLink: string;
  setIsVisibleModal: (value: boolean) => void;
  title: string;
  isAddButtonHide?: boolean;
  buttonText?: string;
};

export const DashboardSectionHeader: FC<DashboardSectionHeaderProps> = ({
  showAllLink,
  setIsVisibleModal,
  title,
  isAddButtonHide,
  buttonText,
}) => (
  <>
    <Header>
      <TitleContainer>
        {!isAddButtonHide && (
          <AddButton onClick={() => setIsVisibleModal(true)}>+ </AddButton>
        )}
        <Title>{title}</Title>
      </TitleContainer>

      <ShowAllLink to={showAllLink}>
        {buttonText ? buttonText : 'See all'}
        <StyledArrow />
      </ShowAllLink>
    </Header>
    <UnderLine />
  </>
);

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${getSize(12)} 0;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: ${getSize(16)};

  display: flex;
  align-items: center;

  color: #000000;
`;

const ShowAllLink = styled(Link)`
  grid-area: show-all;
  justify-self: center;
  align-self: center;
  line-height: ${getSize(11)};
  color: var(--gray44);
  display: flex;
`;

const StyledArrow = styled(ArrowRightShortIcon)`
  margin-left: 5px;
  & path {
    fill: var(--purple);
  }
`;

const UnderLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray45);
  margin: 0 0 ${getSize(20)} 0;
`;

const AddButton = styled.button`
  background-color: var(--purple);
  color: white;
  display: inline;
  border-radius: 33px;
  line-height: 0;
  margin-right: ${getSize(14)};
  width: ${getSize(41)};
  height: ${getSize(30)};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
