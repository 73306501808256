import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isYesterday from 'dayjs/plugin/isYesterday';
import localeData from 'dayjs/plugin/localeData';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isYesterday);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);

export class TimeUtil {
  static validate(param: string, format: string) {
    const currentDate = dayjs(param, format);
    const isValid = currentDate.format(format) === param;

    if (!isValid) {
      throw new Error(
        `Invalid date string ${param} for format ${format}. Date to compare: ${currentDate.toISOString()}`,
      );
    }
  }

  static now() {
    return dayjs();
  }

  static dayjs() {
    return dayjs;
  }

  static startOfYear(format?: string) {
    return dayjs().startOf('year').format(format);
  }

  static endOfYear(format?: string) {
    return dayjs().endOf('year').format(format);
  }

  static parseFormat(value: string | Date, mask: string) {
    return dayjs(value, mask);
  }

  static parseDate(param: string | Date) {
    return dayjs(param);
  }

  static getStartOFNowDay() {
    return dayjs().startOf('date');
  }

  static getStartOfDay(param: string, format: string) {
    this.validate(param, format);
    return dayjs(param).startOf('day');
  }

  static parse(param: string, format: string, forceApplyFormat = false) {
    this.validate(param, format);
    if (forceApplyFormat) {
      return dayjs(param, format);
    }

    return dayjs(param);
  }

  static parseWithZone(param: string, tz: string, format: string) {
    this.validate(param, format);
    return dayjs(param).tz(tz);
  }

  static add(
    date: string | Date,
    { value, unit }: { value: number; unit: dayjs.ManipulateType },
  ) {
    return dayjs(date).add(value, unit);
  }

  static getClientTimezone() {
    return dayjs.tz.guess();
  }

  static getYear(date: string | Date) {
    return dayjs(date).year();
  }

  static getMonth(date: string | Date) {
    return dayjs(date).month();
  }

  static getDate(date: string | Date) {
    return dayjs(date).date();
  }

  static getDay(date: string | Date) {
    return dayjs(date).day();
  }

  static getHour(date: string | Date) {
    return dayjs(date).hour();
  }

  static getFormatted(date: string | Date, format?: string) {
    return dayjs(date).format(format);
  }

  static getNumberOfDaysInMonth(params: string) {
    return dayjs(params).daysInMonth();
  }

  static getDiffOfYears(firstYear: Date | string, secondYear: Date | string) {
    return dayjs(firstYear).diff(secondYear, 'year');
  }

  static getDiffOfMinutes(
    firstDate: Date | string | Dayjs,
    secondDate: Date | string,
  ) {
    return dayjs(secondDate).diff(firstDate, 'minute');
  }

  static getDiffOfHours(
    firstDate: Date | string | Dayjs,
    secondDate: Date | string,
  ) {
    return dayjs(secondDate).diff(firstDate, 'hour');
  }

  static getDiffOfDays(firstDate: Date | string, secondDate: Date | string) {
    return dayjs(secondDate).diff(firstDate, 'day');
  }

  static getDiffOfMonths(
    firstDate: Date | string | Dayjs,
    secondDate: Date | string,
  ) {
    return dayjs(secondDate).diff(firstDate, 'month');
  }

  static getDiffOfMilliseconds(
    firstDate: Date | string,
    secondDate: Date | string,
  ) {
    return dayjs(secondDate).diff(firstDate, 'millisecond');
  }

  static monthsShort() {
    return dayjs().localeData().monthsShort();
  }

  static duration(duration: Duration) {
    return dayjs.duration(duration);
  }

  static getDiffBetweenTwoDates(
    startDate: string | Date,
    endDate: string | Date,
    unit: dayjs.OpUnitType,
  ) {
    return TimeUtil.parseDate(endDate).diff(startDate, unit);
  }
}

export { dayjs, Dayjs };
