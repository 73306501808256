import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

export interface NotesEmptyBlockProps {
  className?: string;
  isSmall?: boolean;
  onAddClick: () => void;
}

function NotesEmptyBlock({
  className,
  isSmall,
  onAddClick,
}: NotesEmptyBlockProps) {
  return (
    <Wrapper className={className}>
      <ImageWrapper>
        <EmptyImage src="/images/notes.svg" />
      </ImageWrapper>

      <EmptyTextWrapper $isSmall={isSmall}>
        <EmptyText>Your latest notes will be shown here.</EmptyText>
        <Button type="button" onClick={onAddClick}>
          Add note
        </Button>
      </EmptyTextWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 ${getSize(24)};
  min-height: ${getSize(225)};
  background: var(--gray-opacity2);
  border-radius: ${getSize(8)};
`;

const EmptyTextWrapper = styled.div<{ $isSmall?: boolean }>`
  ${({ $isSmall }) =>
    !$isSmall &&
    `
  margin: 0 auto;
  text-align: center;
  `}
`;

const EmptyImage = styled.img`
  width: ${getSize(116)};
  height: ${getSize(116)};
`;

const EmptyText = styled.p`
  margin: 0 0 ${getSize(8)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray2);
`;

const Button = styled.button`
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--purple);
`;

export default NotesEmptyBlock;
