import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPostsByTypeVariables = Types.Exact<{
  type: Types.ResourcesPostTypeEnum;
}>;


export type GetPostsByType = (
  { __typename: 'Query' }
  & { getPostsByType: Array<(
    { __typename: 'PostDetailsModel' }
    & Pick<Types.PostDetailsModel, 'id' | 'title' | 'subtitle' | 'postText' | 'subCategoryId' | 'type' | 'author' | 'videoUrl' | 'imageUrl' | 'createdAt'>
  )> }
);


export const GetPostsByTypeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPostsByType"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResourcesPostTypeEnum"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPostsByType"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"subtitle"}},{"kind":"Field","name":{"kind":"Name","value":"postText"}},{"kind":"Field","name":{"kind":"Name","value":"subCategoryId"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"videoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetPostsByType__
 *
 * To run a query within a React component, call `useGetPostsByType` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsByType` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsByType({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetPostsByType(baseOptions: Apollo.QueryHookOptions<GetPostsByType, GetPostsByTypeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsByType, GetPostsByTypeVariables>(GetPostsByTypeDocument, options);
      }
export function useGetPostsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsByType, GetPostsByTypeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsByType, GetPostsByTypeVariables>(GetPostsByTypeDocument, options);
        }
export type GetPostsByTypeHookResult = ReturnType<typeof useGetPostsByType>;
export type GetPostsByTypeLazyQueryHookResult = ReturnType<typeof useGetPostsByTypeLazyQuery>;
export type GetPostsByTypeQueryResult = Apollo.QueryResult<GetPostsByType, GetPostsByTypeVariables>;