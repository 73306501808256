import { useEffect } from 'react';

export const useClickOutside = (
  ref: React.RefObject<any>,
  { onClick }: UseClickOutsideOptions,
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClick]);
};

interface UseClickOutsideOptions {
  onClick: () => void;
}
