import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TherapistClientTypesByIdVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type TherapistClientTypesById = (
  { __typename: 'Query' }
  & { therapistPublicById: (
    { __typename: 'TherapistPublicModel' }
    & Pick<Types.TherapistPublicModel, 'id' | 'therapistClientTypes'>
  ) }
);


export const TherapistClientTypesByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TherapistClientTypesById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistPublicById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"therapistClientTypes"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useTherapistClientTypesById__
 *
 * To run a query within a React component, call `useTherapistClientTypesById` and pass it any options that fit your needs.
 * When your component renders, `useTherapistClientTypesById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistClientTypesById({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTherapistClientTypesById(baseOptions: Apollo.QueryHookOptions<TherapistClientTypesById, TherapistClientTypesByIdVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistClientTypesById, TherapistClientTypesByIdVariables>(TherapistClientTypesByIdDocument, options);
      }
export function useTherapistClientTypesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistClientTypesById, TherapistClientTypesByIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistClientTypesById, TherapistClientTypesByIdVariables>(TherapistClientTypesByIdDocument, options);
        }
export type TherapistClientTypesByIdHookResult = ReturnType<typeof useTherapistClientTypesById>;
export type TherapistClientTypesByIdLazyQueryHookResult = ReturnType<typeof useTherapistClientTypesByIdLazyQuery>;
export type TherapistClientTypesByIdQueryResult = Apollo.QueryResult<TherapistClientTypesById, TherapistClientTypesByIdVariables>;