import React from 'react';

function MailIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#E5E4E8"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path d="M21.89 2.86H2.11c-.325 0-.63.08-.904.21l10.747 10.748 2.409-2.315 8.433-8.432a2.085 2.085 0 00-.905-.212zM23.789 4.065L15.853 12l7.935 7.935c.132-.275.212-.58.212-.904V4.97c0-.325-.08-.629-.211-.904zM.211 4.065A2.085 2.085 0 000 4.97V19.03c0 .325.08.629.211.904L8.146 12 .211 4.065z" />
      <path d="M14.86 12.994l-2.41 2.316a.7.7 0 01-.994 0l-2.315-2.316-7.935 7.935c.275.132.579.212.904.212h19.78c.326 0 .63-.08.905-.212l-7.935-7.935z" />
    </svg>
  );
}

export default MailIcon;
