import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { getSize } from 'lib/utils';
import { AUTH_ROUTES, LUMO_HEALTH_CONTACT_US_ROUTE } from 'lib/routes';

import { PATIENT_ROLE_TITLE } from '../sign-up/sign-up';
import { AuthContentLayout } from 'layouts';
import { SignInForm } from 'components';
import { TeamsAppContext } from '../../../hooks/use-teams-app';
import { ArrowRightIcon } from '../../../ui';

function SignIn() {
  const isTeamsApp = useContext(TeamsAppContext);
  const purpleFilter =
    'brightness(0) saturate(100%) invert(35%) sepia(38%) saturate(2947%) hue-rotate(232deg) brightness(103%) contrast(101%)';
  const signUpBar = () => {
    return isTeamsApp ? (
      <>
        <SignAsUserLink to={AUTH_ROUTES.SIGN_UP_B2B_USER} target="_blank">
          Sign up on Lumo Health
        </SignAsUserLink>
        <Text>
          {`Your organization is not registered yet? `}
          <OutsideLink href={LUMO_HEALTH_CONTACT_US_ROUTE} target="_blank">
            Contact us
          </OutsideLink>
        </Text>
      </>
    ) : (
      <SignAsUserLink to={AUTH_ROUTES.SIGN_UP.pathBuild(PATIENT_ROLE_TITLE)}>
        Create Account{' '}
        <ArrowRightIcon style={{ display: 'inherit', filter: purpleFilter }} />
      </SignAsUserLink>
    );
  };

  return (
    <AuthContentLayout title="Welcome back">
      <SignInForm />

      <Line />

      <Text>
        {`Don't have an account yet? `}
        {signUpBar()}
      </Text>
      {!isTeamsApp && (
        <Text>
          {`Business user? `}
          <SignAsUserLink to={AUTH_ROUTES.SIGN_UP_B2B_USER}>
            Create account{' '}
            <ArrowRightIcon
              style={{ display: 'inherit', filter: purpleFilter }}
            />
          </SignAsUserLink>
        </Text>
      )}
    </AuthContentLayout>
  );
}

export interface SignInFormValues {
  email: string;
  password: string;
  isRemember: boolean;
}

export const TITLE_CSS = {
  color: 'var(--black4)',
  fontSize: `${getSize(32)}`,
  lineHeight: `${getSize(37)}`,
};

export const LABEL_CSS = {
  fontSize: `${getSize(11)}`,
  lineHeight: `${getSize(18)}`,
  color: 'var(--gray7)',
};

export const INNER_CSS = {
  width: `${getSize(480)}`,
  padding: `${getSize(47)} ${getSize(50)} ${getSize(48)}`,
};

export const EMAIL_INPUT_CSS = css`
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  font-weight: 500;
  color: var(--black4);

  &::placeholder {
    font-size: ${getSize(13)};
    line-height: ${getSize(24)};
    color: var(--gray2);
  }
`;

const Text = styled.p`
  font-weight: 500;
  font-size: ${getSize(14)};
  line-height: ${getSize(21)};
  color: var(--9);
  text-align: center;
  margin-bottom: 10px;
`;

const SignAsUserLink = styled(Link)`
  font-weight: 500;
  font-size: ${getSize(14)};
  line-height: ${getSize(21)};
  color: var(--purple21);
  transition: 0.3s ease-out;

  &:hover {
    color: var(--purple16);
  }
`;

const OutsideLink = styled.a`
  font-weight: 500;
  font-size: ${getSize(14)};
  line-height: ${getSize(21)};
  color: var(--purple21);
  transition: 0.3s ease-out;

  &:hover {
    color: var(--purple16);
  }
`;

const Line = styled.div`
  width: 100%;
  height: ${getSize(1)};
  background-color: var(--gray10);
  margin-bottom: ${getSize(22)};
`;

export default SignIn;
