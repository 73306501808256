import { getSize } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';

function CheckSuccessSmallIcon({ ...rest }) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.2779 0.650391L4.65422 6.79522L1.85302 3.80587L0 5.54225L4.52548 10.3717L13 2.5171L11.2779 0.650391Z"
        fill="#349C83"
      />
    </svg>
  );
}

export default CheckSuccessSmallIcon;
