import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { BaseStyleProps } from '../../types/common';

const AuthContentLayout: FC<AuthContentLayout> = ({ title, children }) => {
  return (
    <Root>
      <Title>{title}</Title>
      {children}
    </Root>
  );
};

interface AuthContentLayout extends BaseStyleProps {
  title?: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: ${getSize(32)};
  line-height: ${getSize(37)};
  color: var(--black2);

  margin-bottom: 13px;
`;

export default AuthContentLayout;
