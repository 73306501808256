import React from 'react';

function QuestionnaireIcon({ ...rest }) {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.3059 25.1535C13.3059 18.5529 18.7043 13.2131 25.3268 13.3084C31.7417 13.4006 36.958 18.6615 36.9986 25.0769C37.0213 28.6697 35.4441 31.8942 32.9381 34.0813C32.6736 34.3122 32.7117 34.7334 33.0138 34.9122L35.246 36.2329C35.6069 36.4464 35.4554 36.9996 35.0361 36.9995L24.9281 36.997C18.489 36.8774 13.3059 31.6211 13.3059 25.1535Z"
        fill="#EFECFF"
      />
      <path
        d="M35.2457 36.2328L33.0135 34.9121C32.7114 34.7334 32.6734 34.3121 32.9378 34.0813C35.4438 31.8942 37.021 28.6696 36.9984 25.0769C36.9578 18.6615 31.7415 13.4006 25.3266 13.3083C24.89 13.302 24.4589 13.32 24.0341 13.3598C30.0105 13.9234 34.7265 18.9782 34.765 25.0769C34.7877 28.6697 33.2105 31.8942 30.7044 34.0813C30.4399 34.3121 30.478 34.7334 30.7801 34.9121L33.0124 36.2328C33.3712 36.4452 33.2232 36.9931 32.809 36.999L35.0358 36.9995C35.4551 36.9995 35.6066 36.4463 35.2457 36.2328Z"
        fill="#D6CFFB"
      />
      <path
        d="M25.606 13.3159C25.5134 13.3123 25.4204 13.3097 25.3273 13.3084C18.7047 13.2131 13.3064 18.5529 13.3064 25.1535C13.3064 25.3049 13.3101 25.4552 13.3158 25.6051C19.7841 24.9223 24.9234 19.7843 25.606 13.3159Z"
        fill="#D6CFFB"
      />
      <path
        d="M23.6928 11.8464C23.6928 5.24579 18.2944 -0.0939889 11.6719 0.00125461C5.25706 0.0936076 0.0408538 5.35448 0.000241609 11.7699C-0.0224492 15.3627 1.55471 18.5872 4.06074 20.7743C4.32522 21.0051 4.28714 21.4264 3.985 21.6051L1.75278 22.9258C1.3919 23.1394 1.54343 23.6926 1.96271 23.6925L12.0707 23.69C18.5098 23.5703 23.6928 18.314 23.6928 11.8464Z"
        fill="#EFECFF"
      />
      <path
        d="M11.6722 0.00125461C11.3554 0.00580722 11.0417 0.0234395 10.7314 0.0527063C16.7496 0.616146 21.4597 5.68089 21.4597 11.8464C21.4597 18.314 16.2766 23.5703 9.83762 23.69L1.9564 23.692C1.95864 23.692 1.96066 23.6925 1.96297 23.6925L12.071 23.6901C18.51 23.5704 23.6931 18.3141 23.6931 11.8465C23.693 5.24579 18.2947 -0.0939889 11.6722 0.00125461Z"
        fill="#D6CFFB"
      />
      <path
        d="M11.6449 19.1472C12.1946 19.1472 12.6402 18.7016 12.6402 18.1519C12.6402 17.6022 12.1946 17.1565 11.6449 17.1565C11.0951 17.1565 10.6495 17.6022 10.6495 18.1519C10.6495 18.7016 11.0951 19.1472 11.6449 19.1472Z"
        fill="#6C4FE7"
      />
      <path
        d="M11.6464 15.3706C11.153 15.3706 10.7529 14.9705 10.7529 14.477V11.2951C10.7529 10.8017 11.153 10.4016 11.6464 10.4016C12.2413 10.4016 12.7948 10.1636 13.2051 9.73146C13.6151 9.29965 13.8238 8.73329 13.7929 8.13663C13.7348 7.019 12.8117 6.12583 11.6913 6.10328C10.6003 6.08119 9.6679 6.87578 9.51785 7.95131C9.50403 8.05019 9.49704 8.15137 9.49704 8.25221C9.49704 8.74566 9.09697 9.14573 8.60352 9.14573C8.11008 9.14573 7.71001 8.74566 7.71001 8.25221C7.71001 8.06898 7.72279 7.88459 7.748 7.70424C8.01935 5.75948 9.68796 4.31585 11.6468 4.31585C11.6736 4.31585 11.7004 4.31614 11.7272 4.31666C13.7799 4.358 15.471 5.99527 15.5774 8.04406C15.6341 9.13497 15.2518 10.1712 14.501 10.962C13.9693 11.522 13.2827 11.9104 12.5399 12.0848V14.4771C12.5399 14.9705 12.1399 15.3706 11.6464 15.3706Z"
        fill="#6C4FE7"
      />
      <path
        d="M25.2161 26.4689C25.7988 26.4689 26.2712 25.9965 26.2712 25.4137C26.2712 24.831 25.7988 24.3586 25.2161 24.3586C24.6333 24.3586 24.1609 24.831 24.1609 25.4137C24.1609 25.9965 24.6333 26.4689 25.2161 26.4689Z"
        fill="#6B4EE6"
      />
      <path
        d="M29.9516 26.4689C30.5344 26.4689 31.0068 25.9965 31.0068 25.4137C31.0068 24.831 30.5344 24.3586 29.9516 24.3586C29.3689 24.3586 28.8965 24.831 28.8965 25.4137C28.8965 25.9965 29.3689 26.4689 29.9516 26.4689Z"
        fill="#6B4EE6"
      />
      <path
        d="M20.4798 26.4689C21.0625 26.4689 21.5349 25.9965 21.5349 25.4137C21.5349 24.831 21.0625 24.3586 20.4798 24.3586C19.897 24.3586 19.4246 24.831 19.4246 25.4137C19.4246 25.9965 19.897 26.4689 20.4798 26.4689Z"
        fill="#6B4EE6"
      />
    </svg>
  );
}

export default QuestionnaireIcon;
