import React, { ReactNode, useContext, useState } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { B2B_PATIENT_LINKS, PATIENT_LINKS } from './utils/links';
import {
  AuthNavigation,
  Loader,
  LogoLink,
  MessageHeaderIcon,
  SmallHouseIcon,
} from 'ui';
import CloseButton from './components/close-button';
import { Navigation } from 'components/navigation';
import { UserProfileDropdown } from 'components/user-profile-dropdown';
import { DecodeTokenService, getSize, StorageService } from 'lib/utils';
import { CompanyPlanType, Role } from '__generated__/types';
import { FeedbackContext, useFeedback } from 'hooks/use-feedback';
import { useVideoCall, VideoCallContext } from 'hooks/use-video-call';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Plant from 'assets/images/plant.png';
import { useUser } from 'common/query/__generated__/get-user';
import { useGetCompanyPlanAndSessionInfo } from 'common/query/patient/__generated__/get-company-plan-and-session-info';
import { TeamsAppContext } from '../../hooks/use-teams-app';

/** Constants */

import { PATIENT_ROUTES } from 'lib/routes';
const {
  UNAUTHORIZED,
  GUIDED_MATCHING,
  COMPANY_DASHBOARD,
  PERSONAL_WELLBEING,
  COMPANY_SLACK_WORKSPACE_ONBOARDING,
} = PATIENT_ROUTES;

const UNAUTHORIZED_CSS = css`
  max-width: none;
`;

export interface PatientLayoutProps {
  children: ReactNode;
  contentCSS?: CSSProp;
}

function PatientLayout({ children, contentCSS }: PatientLayoutProps) {
  const accessToken = StorageService.getAccessToken();
  const userEmail = DecodeTokenService.getUserEmail(accessToken);
  const currentRole = DecodeTokenService.getUserRoleFromToken(accessToken);
  const isPatient = currentRole === Role.Patient.toLowerCase();
  const { VideoWindow, videoWindowProps, showVideoCallWindow } =
    useVideoCall(true);

  //TODO: could be removed when the design of other pages will be finished, $isUnauthorized style props might be removed as well
  const { pathname: location } = useLocation();
  const isUnauthorized = location === UNAUTHORIZED;
  const isGuidedMatchingRoute = location === GUIDED_MATCHING;
  const isSpecialRoute = isUnauthorized || isGuidedMatchingRoute;
  const isTeamsApp = useContext(TeamsAppContext);

  const [isVisible, setIsVisible] = useState(false);

  const { isLoading, onSetIsSessionInProgress, isAppFeedback } = useFeedback({
    onOpenFeedbackModal: () => setIsVisible(true),
    onCloseFeedbackModal: () => setIsVisible(false),
  });

  const { data: user, loading: userDataLoading } = useUser();

  const isB2bPatient = !!user?.me?.companyId;
  const history = useHistory();

  const isOnPersonalWellbeing =
    history.location.pathname.includes(PERSONAL_WELLBEING);

  const isOnSlackOnboarding = history.location.pathname.includes(
    COMPANY_SLACK_WORKSPACE_ONBOARDING,
  );

  const isOnCompanyDashbaord =
    history.location.pathname.includes(COMPANY_DASHBOARD);

  const isDashboard = history.location.pathname.includes(
    PATIENT_ROUTES.DASHBOARD,
  );

  const isResources = history.location.pathname.includes(
    PATIENT_ROUTES.RESOURCES,
  );

  const isMessaging = history.location.pathname.includes('/patient/messaging/');
  const isAccountSetting =
    history.location.pathname.includes('/account-settings');

  const isBookSession = history.location.pathname.includes(
    '/patient/find-specialist/',
  );

  const isConfirmBookSession = history.location.pathname.includes(
    '/patient/b2b/find-specialist/',
  );

  const isGuidedMatch = history.location.pathname.includes(
    PATIENT_ROUTES.GUIDED_MATCHING,
  );

  const isRecommendation = history.location.pathname.includes(
    PATIENT_ROUTES.RECOMMENDATION,
  );

  /** If user is on screen to choose path */
  const isOnChoosePath = history.location.pathname.includes(
    PATIENT_ROUTES.FIND_YOUR_PATH,
  );

  /** If user is on content preview route */
  const isContentPreview = history.location.pathname.includes(
    'all-content/preview',
  );

  const linkToMessages = `${PATIENT_ROUTES.MESSAGING.PATH}/${user?.me?.id}`;
  const isShowUserProfileDropdown =
    (isB2bPatient &&
      (isDashboard ||
        isGuidedMatch ||
        isBookSession ||
        isConfirmBookSession ||
        isResources ||
        isOnCompanyDashbaord ||
        isOnPersonalWellbeing ||
        isOnSlackOnboarding) &&
      userEmail) ||
    (!isB2bPatient && userEmail);

  const { data: companyPlanInfo } = useGetCompanyPlanAndSessionInfo({
    fetchPolicy: 'network-only',
  });
  const planType = companyPlanInfo?.planAndSessionsInfo.plan?.planType;

  const numberOfSession =
    companyPlanInfo?.planAndSessionsInfo?.sessionsLeft || 0;

  const isSessionEnded =
    numberOfSession === 0 &&
    (planType === CompanyPlanType.InsuranceLimited ||
      planType === CompanyPlanType.LimitedPerEmployee ||
      planType === CompanyPlanType.SubscriptionPerEmployee ||
      planType === CompanyPlanType.LimitedPerCompany ||
      planType === CompanyPlanType.SubscriptionPerCompany);

  const returnArrayPlanInfoText = () => {
    const caption = companyPlanInfo?.planAndSessionsInfo?.sessionsLeft
      ? companyPlanInfo?.planAndSessionsInfo?.sessionsLeft > 1
        ? 'Sessions left'
        : 'Session left'
      : '';

    if (
      planType === CompanyPlanType.Unlimited ||
      planType === CompanyPlanType.Insurance
    ) {
      return ['Unlimited', 'plan'];
    } else if (
      planType === CompanyPlanType.SubscriptionPerCompany ||
      planType === CompanyPlanType.LimitedPerCompany
    ) {
      return ['', 'Sessions avaliable'];
    } else {
      return [numberOfSession, caption];
    }
  };

  /**
   * @method
   * @desc Method will generate right tooltip text
   */
  const getTooltipText = () => {
    if (!planType) {
      return "You don't have a company plan yet";
    }

    /** If user dont have any more sessions we also check plans */
    if (
      isSessionEnded &&
      (planType === CompanyPlanType.SubscriptionPerEmployee ||
        planType === CompanyPlanType.LimitedPerEmployee)
    ) {
      return "You've used all sessions covered by business for the current month";
    } else if (
      isSessionEnded &&
      (planType === CompanyPlanType.SubscriptionPerCompany ||
        planType === CompanyPlanType.LimitedPerCompany)
    ) {
      return 'Your organisation has run out of sessions until the following month';
    } else if (
      isSessionEnded &&
      planType === CompanyPlanType.InsuranceLimited
    ) {
      return "You've used all sessions covered by your policy for the current period";
    }

    /** If user has sessions left we also check for plan type */
    if (
      planType === CompanyPlanType.Insurance ||
      planType === CompanyPlanType.InsuranceLimited
    ) {
      return 'You are covered by PMI policy';
    } else if (planType === CompanyPlanType.Resources) {
      return 'Sessions not included in the plan';
    }

    return 'You are covered by business';
  };

  const handleClose = () => {
    history.goBack();
  };

  /**
   * @method
   * @description Method will render out links for b2b and regular patients
   */
  const renderPatientLinks = () => {
    /** Guard */
    if (!isPatient || isOnCompanyDashbaord) {
      return null;
    }

    if (!isB2bPatient) {
      return <Nav isHeader links={PATIENT_LINKS} />;
    } else if (isB2bPatient && !isGuidedMatch && !isOnChoosePath) {
      return (
        <B2bNavContainer>
          <Nav isHeader links={B2B_PATIENT_LINKS} />
        </B2bNavContainer>
      );
    }
  };

  /** If data is still loading */
  if (userDataLoading) {
    return (
      <Wrapper>
        {!isTeamsApp && (
          <ContentPreviewHeader>
            <HeaderInner $isUnauthorized={isUnauthorized}>
              <Logo $isPatient={isPatient} />
              <Loader />
            </HeaderInner>
          </ContentPreviewHeader>
        )}
        <Content>
          <ContentInner $CSS={isSpecialRoute ? UNAUTHORIZED_CSS : contentCSS}>
            {children}
          </ContentInner>
        </Content>
      </Wrapper>
    );
  }

  /** If patient is on content preview page serv this layout */
  if (isContentPreview) {
    return (
      <Wrapper>
        <ContentPreviewHeader>
          <HeaderInner $isUnauthorized={isUnauthorized}>
            <Logo $isPatient={isPatient} />
            <CloseButton onClick={handleClose} />
          </HeaderInner>
        </ContentPreviewHeader>
        <Content>
          <ContentInner $CSS={isSpecialRoute ? UNAUTHORIZED_CSS : contentCSS}>
            {children}
          </ContentInner>
        </Content>
      </Wrapper>
    );
  }

  return (
    <VideoCallContext.Provider value={{ showVideoCallWindow }}>
      <Wrapper>
        {!isTeamsApp && (
          <Header $isUnauthorized={isUnauthorized}>
            <HeaderInner $isUnauthorized={isUnauthorized}>
              <Logo $isPatient={isPatient} />

              {renderPatientLinks()}

              {isB2bPatient &&
                (isMessaging || isRecommendation || isAccountSetting) && (
                  <BackToHomeLink to={PATIENT_ROUTES.DASHBOARD}>
                    Back to Home
                  </BackToHomeLink>
                )}

              {(isDashboard ||
                isResources ||
                isOnPersonalWellbeing ||
                isOnSlackOnboarding) &&
                isB2bPatient && (
                  <B2bNavContainer>
                    <MessagesLinkForB2b to={linkToMessages}>
                      <MessageHeaderIcon />
                      <p>Messages</p>
                    </MessagesLinkForB2b>

                    <BusinessPlanNotification $isSessionEnded={isSessionEnded}>
                      Business Plan
                      <InfoIcon $isSessionEnded={isSessionEnded} />
                      <Tooltip>
                        <SmallHouseIcon />
                        <TooltipHeader>{getTooltipText()}</TooltipHeader>

                        {!isSessionEnded && <TooltipSeparator />}
                        {!isSessionEnded && (
                          <TooltipInfo>
                            <span>{returnArrayPlanInfoText()[0]}</span>{' '}
                            <span>{returnArrayPlanInfoText()[1]}</span>
                          </TooltipInfo>
                        )}
                      </Tooltip>
                    </BusinessPlanNotification>
                  </B2bNavContainer>
                )}

              {isShowUserProfileDropdown && <UserProfileDropdown />}
              {!userEmail && <AuthNavigation />}
            </HeaderInner>
          </Header>
        )}
        <Content>
          <FeedbackContext.Provider
            value={{
              onSetIsSessionInProgress,
            }}
          >
            <ContentInner $CSS={isSpecialRoute ? UNAUTHORIZED_CSS : contentCSS}>
              {children}
            </ContentInner>
          </FeedbackContext.Provider>
        </Content>
        <VideoWindow {...videoWindowProps} />
        {isGuidedMatchingRoute && <PlantBackground />}
      </Wrapper>
    </VideoCallContext.Provider>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(180deg, #fcfcfa 0%, #fbf9ff 100%);
`;

const Header = styled.header<{ $isUnauthorized?: boolean }>`
  position: relative;
  background: white;
  box-shadow: 0 ${getSize(20)} ${getSize(90)} var(--blue-opacity);
  border-bottom: ${getSize(1)} solid var(--gray37);
  ${({ $isUnauthorized }) =>
    $isUnauthorized &&
    css`
      border-bottom: ${getSize(1)} solid var(--gray10);
    `}
`;

const ContentPreviewHeader = styled.header`
  background: var(--white);
`;

const HeaderInner = styled.div<{ $isUnauthorized?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${getSize(11)} ${getSize(14)} ${getSize(11)} ${getSize(20)};

  ${({ $isUnauthorized }) =>
    $isUnauthorized &&
    css`
      padding: ${getSize(14)} ${getSize(12)};
      max-width: none;
    `}
`;

const Content = styled.main`
  height: 100%;
  overflow-y: auto;

  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
`;

const ContentInner = styled.div<{ $CSS?: CSSProp }>`
  flex-grow: 1;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  position: relative;

  ${({ $CSS }) => $CSS}
`;

const Logo = styled(LogoLink)<{ $isPatient?: boolean }>``;

const Nav = styled(Navigation)``;

const PlantBackground = styled.span`
  background-image: url(${Plant});
  position: absolute;
  background-size: cover;
  height: 31px;
  width: 225px;
  height: 259px;
  bottom: 0;
  right: 0;
  z-index: 0;
`;

const Tooltip = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 15px;
  max-width: 200px;
  font-size: 12px;
  line-height: 16px;
  transition: all 0.3s;
  transform: scale(0);
  opacity: 0;
`;

const TooltipSeparator = styled.div`
  width: calc(100% + 30px);
  position: relative;
  height: 1px;
  left: -15px;
  margin-top: 7px;
  background: #eeeeee;
`;

const TooltipInfo = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #414141;
  margin-top: 11px;
  width: 120px;
  & span:first-child {
    color: #6b4ee6;
  }
`;

const TooltipHeader = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin-top: 1px;

  display: flex;
  align-items: center;

  color: #414141;
`;

interface InfoIconProps {
  $isSessionEnded: boolean;
}

const InfoIcon = styled.div<InfoIconProps>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${({ $isSessionEnded }) =>
    $isSessionEnded ? '#FF4343' : '#cbcbcb'};
  position: relative;
  margin-left: 11.5px;
  transition: all 0.3s;

  &:before {
    content: '';
    transition: all 0.3s;
    position: absolute;
    width: 1px;
    height: 5px;
    border-radius: 50%;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ $isSessionEnded }) =>
      $isSessionEnded ? '#FF4343' : '#cbcbcb'};
  }

  &:after {
    content: '';
    transition: all 0.3s;
    position: absolute;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ $isSessionEnded }) =>
      $isSessionEnded ? '#FF4343' : '#cbcbcb'};
  }
`;
interface BusinessPlanNotificationProps {
  $isSessionEnded: boolean;
}
const BusinessPlanNotification = styled.div<BusinessPlanNotificationProps>`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 13px;
  border: 1px solid #eaeaea;
  border-color: ${({ $isSessionEnded }) =>
    $isSessionEnded ? '#FFE7E7' : '#eaeaea'};
  border-radius: 17px;
  padding: 7px 15px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  background-color: ${({ $isSessionEnded }) =>
    $isSessionEnded ? '#FFE7E7' : 'none'};

  user-select: none;
  color: ${({ $isSessionEnded }) => ($isSessionEnded ? '#FF4343' : '#000000;')};
  z-index: 99;

  &:hover ${Tooltip} {
    top: 50px;
    opacity: 1;
    transform: scale(1);
  }

  &:hover ${InfoIcon} {
    border-color: ${({ $isSessionEnded }) =>
      $isSessionEnded ? '#FF4343' : '#6b4ee6;'};
  }

  &:hover ${InfoIcon}::before {
    background-color: ${({ $isSessionEnded }) =>
      $isSessionEnded ? '#FF4343' : '#6b4ee6;'};
  }

  &:hover ${InfoIcon}::after {
    background-color: ${({ $isSessionEnded }) =>
      $isSessionEnded ? '#FF4343' : '#6b4ee6;'};
  }
`;
const BackToHomeLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 13px;
  border: 1px solid #eaeaea;
  border-radius: 17px;
  padding: 7px 15px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  transition: all 0.3s;

  margin-left: auto;

  &:hover {
    opacity: 0.7;
  }
`;

const MessagesLinkForB2b = styled(NavLink)`
  display: flex;
  margin-right: 10px;
  font-size: 13px;
  transition: all 0.3s;
  width: 121px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border: 1px solid #eaeaea;
  border-radius: 17px;

  display: flex;
  align-items: center;

  color: #626262;

  & p {
    margin-left: 7px;
  }

  &:hover {
    opacity: 0.7;
  }

  & svg {
    transform: translateY(2px);
  }
`;

const B2bNavContainer = styled.nav`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export default PatientLayout;
