import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { Button, Link } from 'ui';
import { PATIENT_DASHBOARD_ROUTES } from 'lib/routes';

export interface GoalsEmptyBlockProps {
  onAddClick: () => void;
}

function GoalsEmptyBlock({ onAddClick }: GoalsEmptyBlockProps) {
  return (
    <Wrapper>
      <ImageWrapper>
        <EmptyImage src="/images/check-list.svg" />
      </ImageWrapper>

      <EmptyText>
        You can set yourself therapy goals here & track your progress.
      </EmptyText>
      <AddButton type="button" onClick={onAddClick} theme="primary">
        Add
      </AddButton>
      <ShowAll to={PATIENT_DASHBOARD_ROUTES.GOALS}>Show all</ShowAll>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 ${getSize(6)};
  min-height: ${getSize(87)};
  background: var(--purple11);
  border-radius: ${getSize(8)};
`;

const EmptyImage = styled.img`
  width: ${getSize(60)};
  height: ${getSize(60)};
`;

const EmptyText = styled.p`
  margin: 0 0 ${getSize(6)};
  max-width: ${getSize(300)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(22)};
  color: var(--black3);
`;

const AddButton = styled(Button)`
  width: 100%;
  margin-bottom: ${getSize(16)};
`;

const ShowAll = styled(Link)`
  width: 100%;
  text-align: center;
`;

export default GoalsEmptyBlock;
