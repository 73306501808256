import React from 'react';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { getSize } from 'lib/utils';

export interface CircleProgressBarProps {
  percent: number;
  className?: string;
  pathColor?: string;
  textColor?: string;
}

function CircleProgressBar({
  percent,
  className,
  pathColor,
  textColor,
}: CircleProgressBarProps) {
  const styles = buildStyles({
    strokeLinecap: 'butt',
    textColor: textColor || 'var(--black3)',
    trailColor: 'var(--purple3)',
    pathColor: pathColor || 'var(--purple)',
  });

  return (
    <Wrapper className={className}>
      <CircularProgressbar
        styles={styles}
        value={percent}
        text={`${percent}%`}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: ${getSize(46)};
  height: ${getSize(46)};
`;

export default CircleProgressBar;
