import {
  SessionFragment,
  SessionRequestPreviewFragment,
} from 'features/patient/dashboard/query/__generated__/get-patient-upcoming-sessions';
import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { Loader } from 'ui';
import { SessionItem } from '../session-item';

interface SessionScrollListProps {
  upcomingSessions: (SessionFragment | SessionRequestPreviewFragment)[];
  pastSessions: SessionFragment[];
  canceledSessions: SessionFragment[];
  hasMoreUpcoming?: boolean;
  hasMorePast?: boolean;
  hasMoreCanceled?: boolean;
  isSmall?: boolean;
  isPastTabActive: boolean;
  isUpcomingTabActive: boolean;
  isCanceledTabActive: boolean;
  onCancelClick?: (session: SessionFragment) => void;
  onStartClick?: (session: SessionFragment) => void;
  fetchMoreUpcoming?: () => Promise<any>;
  fetchMorePast?: () => Promise<any>;
  fetchMoreCanceled?: () => Promise<any>;
  onCancelRequest?: (requestId: string) => void;
  isTherapist?: boolean;
  onOpenRescheduleModal?: () => void;
  onCloseRescheduleModal?: () => void;
  rescheduleModalVisible?: boolean;
}

const SessionScrollList: FC<SessionScrollListProps> = ({
  upcomingSessions,
  pastSessions,
  canceledSessions,
  fetchMoreUpcoming,
  fetchMorePast,
  fetchMoreCanceled,
  hasMoreUpcoming,
  hasMorePast,
  hasMoreCanceled,
  isSmall,
  isPastTabActive,
  isUpcomingTabActive,
  isCanceledTabActive,
  onCancelClick,
  onStartClick,
  isTherapist,
  onCancelRequest,
  onOpenRescheduleModal,
  onCloseRescheduleModal,
  rescheduleModalVisible,
}) => {
  if (fetchMoreUpcoming && isUpcomingTabActive) {
    return (
      <SessionList id="upcomingList" key="upcomingList">
        <InfiniteScroll
          dataLength={upcomingSessions.length} //This is important field to render the next data
          next={fetchMoreUpcoming}
          hasMore={Boolean(hasMoreUpcoming)}
          scrollableTarget="upcomingList"
          loader={<Loader />}
          style={{ overflow: 'visible' }}
        >
          {upcomingSessions?.map((session) => {
            if (session.__typename === 'SessionModel') {
              return (
                <SessionItem
                  key={session.startDateUtc}
                  session={session}
                  isSmall={isSmall}
                  onCancelClick={() => onCancelClick?.(session)}
                  onStartClick={() => onStartClick?.(session)}
                  isTherapist={isTherapist}
                  onOpenRescheduleModal={onOpenRescheduleModal}
                  onCloseRescheduleModal={onCloseRescheduleModal}
                  rescheduleModalVisible={rescheduleModalVisible}
                />
              );
            } else {
              return (
                <SessionItem
                  key={session.startDateUtc}
                  session={session}
                  isSmall={isSmall}
                  isTherapist={isTherapist}
                  onCancelRequest={() => onCancelRequest?.(session.id)}
                  onOpenRescheduleModal={onOpenRescheduleModal}
                  onCloseRescheduleModal={onCloseRescheduleModal}
                  rescheduleModalVisible={rescheduleModalVisible}
                />
              );
            }
          })}
        </InfiniteScroll>
      </SessionList>
    );
  } else if (fetchMorePast && isPastTabActive) {
    return (
      <SessionList id="pastList" key="pastList">
        <InfiniteScroll
          dataLength={pastSessions.length} //This is important field to render the next data
          next={fetchMorePast}
          hasMore={Boolean(hasMorePast)}
          scrollableTarget="pastList"
          loader={<Loader />}
          style={{ overflow: 'visible' }}
        >
          {pastSessions?.map((session) => {
            const { startDateUtc } = session;

            return (
              <SessionItem
                key={startDateUtc}
                session={session}
                isPast
                isSmall={isSmall}
                isTherapist={isTherapist}
                onCloseRescheduleModal={onCloseRescheduleModal}
                rescheduleModalVisible={rescheduleModalVisible}
              />
            );
          })}
        </InfiniteScroll>
      </SessionList>
    );
  } else if (fetchMoreCanceled && isCanceledTabActive) {
    return (
      <SessionList id="canceledList" key="canceledList">
        <InfiniteScroll
          dataLength={canceledSessions.length} //This is important field to render the next data
          next={fetchMoreCanceled}
          hasMore={Boolean(hasMoreCanceled)}
          scrollableTarget="canceledList"
          loader={<Loader />}
          style={{ overflow: 'visible' }}
        >
          {canceledSessions?.map((session) => {
            const { startDateUtc } = session;

            return (
              <SessionItem
                key={startDateUtc}
                session={session}
                isPast
                isSmall={isSmall}
                isTherapist={isTherapist}
                onCloseRescheduleModal={onCloseRescheduleModal}
                rescheduleModalVisible={rescheduleModalVisible}
              />
            );
          })}
        </InfiniteScroll>
      </SessionList>
    );
  } else {
    return null;
  }
};

const SessionList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSize(8)};
  max-height: ${getSize(580)};
  overflow: auto;
`;

export default SessionScrollList;
