import React from 'react';
import styled from 'styled-components';

import { CloseIcon } from 'ui';

interface CloseButtonProps {
  onClick?: () => void;
}

const CloseButton = (props: CloseButtonProps) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <CloseIcon stroke={'#6B6D74'} />
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  padding: 10.5px;
  border-radius: 100%;
  background-color: var(--purple3);
`;

export default CloseButton;
