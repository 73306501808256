const EMPTY_TITLE = 'No title';

export const getTherapistPreviewName = ({
  title,
  fullName,
}: GetTherapistPreviewNameParams) => {
  if (!fullName) {
    return '';
  }

  if (title && title !== EMPTY_TITLE) {
    return `${title} ${fullName}`;
  }

  return fullName;
};

interface GetTherapistPreviewNameParams {
  title?: string;
  fullName?: string;
}
