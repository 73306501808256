import { useMemo } from 'react';
import { sortOptionsByAlphaBet, transformToOptions } from 'lib/utils';
import { useProfessionalRegistrations } from 'common/query/__generated__/get-professional-registrations';

export function useProfessionalRegistrationsOptions() {
  const professtionRegistrationsQuery = useProfessionalRegistrations();

  return useMemo(
    () =>
      sortOptionsByAlphaBet(
        transformToOptions(
          professtionRegistrationsQuery.data?.professionalRegistrations,
        ),
      ),
    [professtionRegistrationsQuery.data],
  );
}
