import { getSize } from 'lib/utils';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

const Avatar: FC<AvatarProps> = ({ size, ...props }) => {
  return <Root {...props} $size={size} />;
};

interface AvatarProps extends ImgHTMLAttributes<HTMLImageElement> {
  size: number;
}

const Root = styled.img<{ $size: number }>`
  border-radius: 50%;
  width: ${({ $size }) => getSize($size)};
  height: ${({ $size }) => getSize($size)};
  object-fit: cover;
`;

export default Avatar;
