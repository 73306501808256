import { useMemo } from 'react';
import { useCoreTrainings } from 'common/query/__generated__/get-core-trainings';
import { sortOptionsByAlphaBet, transformToOptions } from 'lib/utils';

export function useCoreTrainingsOptions() {
  const coreTrainingsQuery = useCoreTrainings();

  return useMemo(
    () =>
      sortOptionsByAlphaBet(
        transformToOptions(coreTrainingsQuery.data?.coreTrainings),
      ),
    [coreTrainingsQuery.data],
  );
}
