import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TherapistClientTypesVariables = Types.Exact<{ [key: string]: never; }>;


export type TherapistClientTypes = (
  { __typename: 'Query' }
  & { therapistClientTypes: Array<(
    { __typename: 'TherapistClientTypeModel' }
    & Pick<Types.TherapistClientTypeModel, 'id' | 'label' | 'explanation'>
  )> }
);


export const TherapistClientTypesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TherapistClientTypes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistClientTypes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useTherapistClientTypes__
 *
 * To run a query within a React component, call `useTherapistClientTypes` and pass it any options that fit your needs.
 * When your component renders, `useTherapistClientTypes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistClientTypes({
 *   variables: {
 *   },
 * });
 */
export function useTherapistClientTypes(baseOptions?: Apollo.QueryHookOptions<TherapistClientTypes, TherapistClientTypesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistClientTypes, TherapistClientTypesVariables>(TherapistClientTypesDocument, options);
      }
export function useTherapistClientTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistClientTypes, TherapistClientTypesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistClientTypes, TherapistClientTypesVariables>(TherapistClientTypesDocument, options);
        }
export type TherapistClientTypesHookResult = ReturnType<typeof useTherapistClientTypes>;
export type TherapistClientTypesLazyQueryHookResult = ReturnType<typeof useTherapistClientTypesLazyQuery>;
export type TherapistClientTypesQueryResult = Apollo.QueryResult<TherapistClientTypes, TherapistClientTypesVariables>;