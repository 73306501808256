import { Option } from 'ui/form-elements/single-select/single-select';

export function getOfficeLocationId(
  officeLocationIdOptions: Option[],
  officeLocationId?: string,
) {
  let currentOfficeLocationId: string | undefined;
  const isLocationAvailableInOptions = officeLocationIdOptions.some(
    (locationIdOption) => officeLocationId === locationIdOption.value,
  );

  if (isLocationAvailableInOptions) {
    currentOfficeLocationId = officeLocationId;
  } else if (!officeLocationId) {
    currentOfficeLocationId = officeLocationIdOptions[0]?.value;
  }

  return currentOfficeLocationId;
}
