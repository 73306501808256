import { THERAPIST_ACCOUNT_SETTINGS } from 'lib/routes';

const { PERSONAL, QUALIFICATION, APPROACH, PROFILE, ACCOUNT, SYSTEM_INFO } =
  THERAPIST_ACCOUNT_SETTINGS;

export const THERAPIST_SETTINGS = [
  {
    href: PERSONAL,
    text: 'Personal Information',
  },
  {
    href: QUALIFICATION,
    text: 'Qualifications & Credentials',
  },
  {
    href: APPROACH,
    text: 'Approach & Experience',
  },
  {
    href: PROFILE,
    text: 'Profile',
  },
  {
    href: ACCOUNT,
    text: 'Account',
  },
  {
    href: SYSTEM_INFO,
    text: 'System Info',
  },
];
