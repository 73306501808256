import { ChatFragment } from 'components/chat/query/__generated__/chats-list';
import { ChatUserType } from '__generated__/types';
import { useMemo, useState } from 'react';
import { ChatUserFragment } from 'components/chat/query/__generated__/users-for-start-new-chat';
import { useUsersForStartNewChatLazyQuery } from '../query/__generated__/users-for-start-new-chat';
import { useTherapistStartNewChat } from '../mutation/__generated__/therapist-start-new-chat';
import { updateChatListCacheAfterStartNewChat } from '../utils';

interface UseStartNewChatArgs {
  userId?: string;
  selectedChat?: ChatFragment;
  onChatClick: (chadId: string) => void;
}

export function useStartNewChat({
  userId,
  selectedChat,
  onChatClick,
}: UseStartNewChatArgs) {
  const [idOfUserWithNewChat, setIdOfUserWithNewChat] = useState('');
  const [isWriteMessageModalVisible, setIsWriteMessageModalVisible] =
    useState(false);
  const [searchInterlocutorString, setSearchInterlocutorString] =
    useState<string>();
  const [groupChatParticipantsToShow, grouChatParticipantsToShow] =
    useState<ChatUserFragment[]>();

  const [getUsersForStartNewChat, usersForStartNewChatQuery] =
    useUsersForStartNewChatLazyQuery();
  const [startNewTherapistChat, { loading: isStartNewTherapistChatLoading }] =
    useTherapistStartNewChat({
      update: updateChatListCacheAfterStartNewChat,
    });

  const interlocutorForStartNewChat = useMemo(() => {
    const isGroupParticipants = Boolean(groupChatParticipantsToShow);
    const currentInterlocutors =
      groupChatParticipantsToShow ||
      usersForStartNewChatQuery.data?.therapistListUsersCanStartChat ||
      [];

    return currentInterlocutors.filter((interlocutor) => {
      if (interlocutor.type === ChatUserType.Admin) return;
      if (!isGroupParticipants && interlocutor.id === userId) return;

      if (searchInterlocutorString) {
        const isSearchStringFit = new RegExp(
          `${searchInterlocutorString}`,
          'gi',
        ).test(interlocutor.name);

        return isSearchStringFit;
      }

      return true;
    });
  }, [
    searchInterlocutorString,
    usersForStartNewChatQuery.data,
    userId,
    groupChatParticipantsToShow,
  ]);

  function showWriteMessageModal() {
    setIsWriteMessageModalVisible(true);
  }
  function handleWriteMessageClick() {
    if (!usersForStartNewChatQuery.called) getUsersForStartNewChat();
    showWriteMessageModal();
  }
  function closeWriteMessageModal() {
    setIsWriteMessageModalVisible(false);
  }

  function handleParticipantsClick() {
    grouChatParticipantsToShow(selectedChat?.participants);
    showWriteMessageModal();
  }

  function startNewChat(chatUserId: string) {
    if (!chatUserId) return;

    startNewTherapistChat({ variables: { chatUserId } }).then(({ data }) => {
      closeWriteMessageModal();
      setIdOfUserWithNewChat('');

      if (data) {
        const newChatId = data.therapistStartChat.id;

        if (newChatId !== selectedChat?.id) {
          onChatClick(newChatId);
        }
      }
    });
  }

  function handleWriteMessageModalClose() {
    closeWriteMessageModal();
    if (groupChatParticipantsToShow) grouChatParticipantsToShow(undefined);
  }

  return {
    usersForStartNewChatQuery,
    isWriteMessageModalVisible,
    idOfUserWithNewChat,
    setSearchInterlocutorString,
    isStartNewTherapistChatLoading,
    interlocutorForStartNewChat,
    onWriteMessageClick: handleWriteMessageClick,
    onParticipantsClick: handleParticipantsClick,
    startNewChat: startNewChat,
    onWriteMessageModalClose: handleWriteMessageModalClose,
    groupChatParticipantsToShow,
  };
}
