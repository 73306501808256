import React from 'react';

function EmptySession() {
  return (
    <svg
      width="251"
      height="153"
      viewBox="0 0 251 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3826_4374)">
        <path
          d="M182.948 75.5772L168.345 82.5451L181.269 72.8019C179.169 69.4964 176.809 66.3657 174.213 63.44L110.883 79.3102L168.088 57.3094C165.474 54.9841 162.698 52.8507 159.781 50.9258L63.2818 66.597C53.036 79.5145 47.4508 95.5604 47.4429 112.1H192.7C192.694 99.2728 189.33 86.6741 182.948 75.5772Z"
          fill="#F2F2F2"
        />
        <path
          d="M151.883 46.4023C137.273 39.2381 120.704 37.2157 104.82 40.658C88.937 44.1003 74.6555 52.8088 64.2561 65.3932C87.0697 60.3534 126.79 51.6293 151.883 46.4023Z"
          fill="#F2F2F2"
        />
        <path
          d="M138.717 47.8171C136.231 55.2378 132.419 61.9991 124.651 61.9991C116.882 61.9991 111.022 55.6372 110.584 47.8171C110.025 37.8246 116.703 33.5937 124.651 33.6351C134.04 33.684 141.667 39.0105 138.717 47.8171Z"
          fill="#2F2E41"
        />
        <path
          d="M136.053 45.9297H113.045V79.7359H136.053V45.9297Z"
          fill="#2F2E41"
        />
        <path
          d="M121.117 65.3412C121.117 65.3412 121.853 75.9761 120.136 77.7074C118.419 79.4387 135.345 80.9226 135.1 78.944C134.854 76.9654 131.665 64.1046 132.647 61.3841C133.628 58.6635 121.117 65.3412 121.117 65.3412Z"
          fill="#FFB8B8"
        />
        <path
          opacity="0.1"
          d="M121.117 65.3412C121.117 65.3412 121.853 75.9761 120.136 77.7074C118.419 79.4387 135.345 80.9226 135.1 78.944C134.854 76.9654 131.665 64.1046 132.647 61.3841C133.628 58.6635 121.117 65.3412 121.117 65.3412Z"
          fill="black"
        />
        <path
          d="M104.929 92.5462L96.1149 122.596L95.7617 123.806H80.9298C86.8314 105.388 101.421 78.2276 101.421 78.2276L103.699 77.707L104.929 92.5462Z"
          fill="#6C63FF"
        />
        <path
          opacity="0.1"
          d="M104.929 92.5462L96.1149 122.596L95.7617 123.806H80.9298C86.8314 105.388 101.421 78.2276 101.421 78.2276L103.699 77.707L104.929 92.5462Z"
          fill="black"
        />
        <path
          d="M124.061 69.0501C130.158 69.0501 135.1 64.0672 135.1 57.9205C135.1 51.7739 130.158 46.791 124.061 46.791C117.964 46.791 113.022 51.7739 113.022 57.9205C113.022 64.0672 117.964 69.0501 124.061 69.0501Z"
          fill="#FFB8B8"
        />
        <path
          d="M135.836 75.7288C135.836 75.7288 131.911 79.4386 127.495 78.6966C123.08 77.9547 121.226 72.541 121.226 72.541L116.947 75.7288C116.947 75.7288 115.721 84.6324 121.363 85.6217C127.005 86.611 135.1 86.8583 136.817 85.6217C138.534 84.3851 135.836 75.7288 135.836 75.7288Z"
          fill="#3F3D56"
        />
        <path
          d="M149.328 88.8333L148.624 91.1396L138.686 123.807H95.9149C96.0059 123.408 96.0727 123.004 96.115 122.596C96.6684 117.946 96.3423 106.215 96.3423 105.408C96.3423 104.417 100.51 79.9343 101.495 78.2008C101.648 77.9911 101.849 77.8219 102.081 77.7076C103.225 77.0451 105.754 76.3029 108.482 75.6479C108.484 75.6454 108.484 75.6454 108.486 75.6454C110.011 75.2718 111.602 74.9281 113.044 74.6367H113.047C114.462 74.3502 115.732 74.1161 116.661 73.9617C117.162 73.8649 117.667 73.7942 118.175 73.75C118.402 73.75 118.568 74.0215 118.679 74.4374C119.052 75.7923 118.909 78.6939 118.909 78.6939C119.24 79.0777 119.609 79.4265 120.011 79.735C120.954 80.4823 121.988 81.1042 123.089 81.5855C124.032 82.0026 125.009 82.3384 126.009 82.5893H126.011C127.062 82.8577 128.131 83.0451 129.21 83.1497C130.09 83.2518 130.979 83.2342 131.853 83.0974H131.858C134.46 82.664 135.413 81.0351 135.858 79.735C135.937 79.4984 135.999 79.2717 136.053 79.065C136.053 79.0625 136.055 79.0576 136.055 79.0551C136.218 78.4199 136.288 77.9741 136.461 77.9816H136.463C136.91 78.0239 142.55 82.8981 146.213 86.0986C147.953 87.6154 149.245 88.7611 149.324 88.8308L149.328 88.8333Z"
          fill="#6C63FF"
        />
        <path
          opacity="0.1"
          d="M148.624 91.1408L138.686 123.808H134.657C135.019 123.273 135.177 122.623 135.099 121.98C134.971 121.068 137.77 111.781 140.465 103.138C142.915 95.2852 145.28 87.9727 145.28 87.9727L147.609 89.0835C147.609 89.0835 148.027 89.878 148.624 91.1408Z"
          fill="black"
        />
        <path
          d="M155.707 120.739C155.223 120.861 155.275 122.31 155.247 123.807H136.211C136.443 123.518 136.615 123.186 136.72 122.829C136.824 122.473 136.858 122.099 136.819 121.73C136.693 120.856 138.57 111.842 140.465 103.137C141.954 96.2531 143.456 89.5607 143.978 87.232C144.106 86.6691 144.175 86.3652 144.175 86.3652L144.4 86.4723L144.405 86.4748L149.323 88.831L149.328 88.8335C149.328 88.8335 156.932 103.429 154.482 106.891C152.026 110.353 152.026 110.353 153.007 112.086C153.987 113.815 156.685 120.492 155.707 120.739Z"
          fill="#6C63FF"
        />
        <path
          d="M124.651 36.6202C127.717 36.6202 130.203 34.1138 130.203 31.022C130.203 27.9302 127.717 25.4238 124.651 25.4238C121.584 25.4238 119.098 27.9302 119.098 31.022C119.098 34.1138 121.584 36.6202 124.651 36.6202Z"
          fill="#2F2E41"
        />
        <path
          d="M117.987 28.0357C117.988 26.6479 118.499 25.3097 119.422 24.2805C120.346 23.2513 121.615 22.6046 122.985 22.4658C122.802 22.4475 122.617 22.4375 122.429 22.4375C120.957 22.4375 119.545 23.0273 118.503 24.0772C117.462 25.127 116.877 26.5509 116.877 28.0357C116.877 29.5204 117.462 30.9443 118.503 31.9942C119.545 33.044 120.957 33.6338 122.429 33.6338C122.617 33.6338 122.802 33.6239 122.985 33.6056C121.615 33.4667 120.346 32.82 119.422 31.7908C118.499 30.7617 117.988 29.4234 117.987 28.0357Z"
          fill="#2F2E41"
        />
        <path
          d="M120.566 43.8987C120.566 43.8987 120.45 43.248 115.916 44.9624C111.381 46.6768 108.663 59.3284 110.93 66.7574C111.844 69.7513 111.101 71.9099 109.888 73.4203C105.335 79.0879 107.127 87.6006 113.758 90.5151C113.946 90.5974 114.137 90.6787 114.331 90.7591L117.165 92.4735C117.165 92.4735 113.553 87.6807 115.361 81.5499C116.931 76.3262 117.125 70.7803 115.923 65.4582C115.716 64.5603 115.48 63.6349 115.212 62.6904C112.945 54.6899 114.048 55.6138 121.983 47.6133C121.983 47.6133 126.518 51.0421 130.485 52.7565C134.453 54.4709 137.854 56.1853 135.02 61.9C132.186 67.6146 125.384 72.7578 129.919 80.1869C134.453 87.616 135.02 84.7587 134.453 87.616L133.886 90.4733L137.287 87.0445L135.02 91.6163C135.02 91.6163 142.388 91.6163 144.656 85.9016L145.789 87.0445C145.789 87.0445 150.324 79.044 141.822 71.6149C141.822 71.6149 138.988 69.9005 142.955 64.1859C146.923 58.4712 143.876 46.1846 138.774 43.8988C133.673 41.6129 120.566 43.8987 120.566 43.8987Z"
          fill="#2F2E41"
        />
        <path
          d="M124.061 79.8867L114.617 96.3792H116.825V98.6051L127.558 79.8867H124.061Z"
          fill="#3F3D56"
        />
        <path
          d="M176.83 17.4472L176.486 17.8047L177.459 18.7568L177.803 18.3993L176.83 17.4472Z"
          fill="#2F2E41"
        />
        <path
          d="M202.828 124.057H34.3517V27.4199H202.828V124.057ZM34.8458 123.559H202.334V27.918H34.8458V123.559Z"
          fill="#2F2E41"
        />
        <path d="M194.8 111.604H42.3802V112.6H194.8V111.604Z" fill="#2F2E41" />
        <g filter="url(#filter0_d_3826_4374)">
          <path
            d="M226.533 74H158.467C156.753 74.0021 155.109 74.6321 153.897 75.7518C152.684 76.8715 152.002 78.3895 152 79.9729V123.03C152.002 124.613 152.685 126.131 153.897 127.25C155.109 128.369 156.753 128.999 158.467 129H226.533C228.247 128.999 229.891 128.369 231.103 127.25C232.315 126.131 232.998 124.613 233 123.03V79.9729C232.998 78.3895 232.316 76.8715 231.103 75.7518C229.891 74.6321 228.247 74.0021 226.533 74Z"
            fill="white"
          />
        </g>
        <path
          d="M198.769 93.2699C201.389 93.2699 203.512 91.1283 203.512 88.4865C203.512 85.8447 201.389 83.7031 198.769 83.7031C196.149 83.7031 194.025 85.8447 194.025 88.4865C194.025 91.1283 196.149 93.2699 198.769 93.2699Z"
          fill="#2F2E41"
        />
        <path
          d="M189.024 109.525C195.661 109.525 201.041 104.1 201.041 97.407C201.041 90.7145 195.661 85.2891 189.024 85.2891C182.387 85.2891 177.007 90.7145 177.007 97.407C177.007 104.1 182.387 109.525 189.024 109.525Z"
          fill="#2F2E41"
        />
        <path
          d="M186.936 110.261C192.329 110.261 196.701 105.852 196.701 100.414C196.701 94.9751 192.329 90.5664 186.936 90.5664C181.543 90.5664 177.171 94.9751 177.171 100.414C177.171 105.852 181.543 110.261 186.936 110.261Z"
          fill="#9E616A"
        />
        <path
          d="M183.255 88.1517C185.152 87.4998 187.187 87.3739 189.149 87.787C191.111 88.2002 192.927 89.1372 194.406 90.5001C195.886 91.863 196.975 93.6018 197.561 95.5346C198.146 97.4674 198.205 99.5233 197.733 101.487C192.605 101.627 187.294 101.422 182.529 98.3826L181.972 95.0892L180.584 97.9484C178.863 98.2444 177.16 98.4394 175.845 96.8779C176.215 94.8967 177.094 93.048 178.394 91.5167C179.694 89.9854 181.37 88.8251 183.255 88.1517Z"
          fill="#2F2E41"
        />
        <path
          d="M205.878 76.7789C209.407 76.0173 213.271 77.6139 215.424 80.5337C217.578 83.4536 218.011 87.5161 216.721 90.9143C215.404 94.381 212.562 96.9826 209.904 99.5511C207.246 102.12 204.571 104.987 203.773 108.611C203.198 111.221 203.674 113.955 205.095 116.212C206.516 118.469 208.767 120.066 211.354 120.652C208.025 121.289 204.558 121.922 201.289 121.024C198.02 120.126 195.03 117.207 195.218 113.796C195.353 111.338 197.017 109.285 198.464 107.303C200.822 104.072 202.785 100.567 204.31 96.8625C205.037 95.0944 205.673 93.2067 205.469 91.3036C205.285 89.8859 204.654 88.5654 203.669 87.5369C202.684 86.5084 201.397 85.8263 199.998 85.5911L199.126 84.3559C199.417 80.7289 202.35 77.5405 205.878 76.7789Z"
          fill="#2F2E41"
        />
        <path
          d="M209.34 129.05C208.708 122.551 205.134 122.806 205.134 122.806L195.698 120.947L191.565 114.027L181.189 113.619L177.495 120.15L169.905 121.107L169.899 121.215C169.146 121.273 166.433 121.872 165.889 127.456C165.838 127.969 165.769 128.598 165.68 129.321H209.369C209.359 129.226 209.35 129.136 209.34 129.05Z"
          fill="#2F2E41"
        />
        <path
          d="M138 117C143.523 117 148 112.523 148 107C148 101.477 143.523 97 138 97C132.477 97 128 101.477 128 107C128 112.523 132.477 117 138 117Z"
          fill="white"
        />
        <path
          d="M108.5 117C114.299 117 119 112.523 119 107C119 101.477 114.299 97 108.5 97C102.701 97 98 101.477 98 107C98 112.523 102.701 117 108.5 117Z"
          fill="white"
        />
        <path
          d="M111.015 112C110.579 112 109.967 111.842 109.05 111.33C107.91 110.721 106.869 109.943 105.963 109.022C105.025 108.129 104.242 107.086 103.645 105.937C102.823 104.441 102.963 103.657 103.12 103.322C103.301 102.955 103.585 102.65 103.938 102.444C104.141 102.311 104.355 102.198 104.578 102.105C104.6 102.095 104.621 102.086 104.639 102.078C104.713 102.036 104.795 102.01 104.88 102.002C104.964 101.995 105.049 102.005 105.13 102.033C105.308 102.119 105.466 102.24 105.595 102.39C106.06 102.911 106.452 103.493 106.76 104.119C106.887 104.335 106.965 104.577 106.989 104.827C106.97 105.074 106.87 105.308 106.704 105.493C106.675 105.532 106.646 105.571 106.618 105.608C106.448 105.831 106.41 105.896 106.435 106.011C106.485 106.242 106.39 107.105 107 107.714C107.61 108.323 108.746 108.497 108.979 108.547C109.099 108.572 109.165 108.533 109.395 108.358C109.428 108.332 109.462 108.306 109.498 108.28C109.682 108.106 109.92 107.999 110.173 107.978H110.175C110.428 108.004 110.672 108.089 110.886 108.227C111.505 108.541 112.084 108.929 112.609 109.383C112.759 109.512 112.881 109.67 112.967 109.848C112.995 109.928 113.005 110.014 112.998 110.098C112.99 110.183 112.964 110.265 112.923 110.339C112.914 110.358 112.905 110.378 112.896 110.401C112.802 110.623 112.687 110.837 112.554 111.039C112.347 111.391 112.041 111.675 111.675 111.855C111.469 111.953 111.243 112.002 111.015 112Z"
          fill="#6C63FF"
        />
        <path
          d="M140.667 106C140.578 106 140.493 106.037 140.431 106.103C140.368 106.17 140.333 106.259 140.333 106.353V107.059C140.333 108.423 139.289 109.57 138 109.57C136.711 109.57 135.667 108.423 135.667 107.059V106.353C135.667 106.259 135.632 106.17 135.569 106.103C135.507 106.037 135.422 106 135.333 106C135.245 106 135.16 106.037 135.098 106.103C135.035 106.17 135 106.259 135 106.353V107.059C135.001 107.84 135.273 108.593 135.765 109.175C136.257 109.757 136.934 110.127 137.667 110.214V111.647C137.667 111.741 137.702 111.83 137.764 111.897C137.827 111.963 137.912 112 138 112C138.088 112 138.173 111.963 138.236 111.897C138.298 111.83 138.333 111.741 138.333 111.647V110.214C139.066 110.127 139.743 109.757 140.235 109.175C140.727 108.593 140.999 107.84 141 107.059V106.353C141 106.259 140.965 106.17 140.902 106.103C140.84 106.037 140.755 106 140.667 106Z"
          fill="#6C63FF"
        />
        <path
          d="M138 108.999C137.509 109.017 137.024 108.804 136.586 108.379C136.429 108.259 136.291 108.059 136.188 107.801C136.085 107.544 136.02 107.237 136 106.915V104.059C135.996 102.926 136.884 102.005 137.984 102C137.99 102 137.995 102 138 102C139.093 101.988 139.988 102.89 140 104.015C140 104.029 140 104.044 140 104.059V106.915C140.005 108.058 139.111 108.99 138 108.999Z"
          fill="#6C63FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3826_4374"
          x="132"
          y="64"
          width="121"
          height="95"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3826_4374"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3826_4374"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3826_4374">
          <rect width="251" height="153" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmptySession;
