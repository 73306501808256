import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientSetupIntentVariables = Types.Exact<{ [key: string]: never; }>;


export type PatientSetupIntent = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'patientSetupIntent'>
);


export const PatientSetupIntentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PatientSetupIntent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientSetupIntent"}}]}}]} as unknown as DocumentNode;
export type PatientSetupIntentMutationFn = Apollo.MutationFunction<PatientSetupIntent, PatientSetupIntentVariables>;

/**
 * __usePatientSetupIntent__
 *
 * To run a mutation, you first call `usePatientSetupIntent` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientSetupIntent` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientSetupIntent, { data, loading, error }] = usePatientSetupIntent({
 *   variables: {
 *   },
 * });
 */
export function usePatientSetupIntent(baseOptions?: Apollo.MutationHookOptions<PatientSetupIntent, PatientSetupIntentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientSetupIntent, PatientSetupIntentVariables>(PatientSetupIntentDocument, options);
      }
export type PatientSetupIntentHookResult = ReturnType<typeof usePatientSetupIntent>;
export type PatientSetupIntentMutationResult = Apollo.MutationResult<PatientSetupIntent>;
export type PatientSetupIntentMutationOptions = Apollo.BaseMutationOptions<PatientSetupIntent, PatientSetupIntentVariables>;