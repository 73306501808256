import React, { FC, Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useUserSlackOnboarding } from 'common/query/patient/__generated__/get-user-slack-onboarding';
import { getSize } from 'lib/utils';
import { PATIENT_ROUTES } from 'lib/routes';
import { Badge, SlackLogo, LumoLogo } from './logo';
import { PlusIcon } from 'ui';

const SlackOnboardingBanner: FC = () => {
  const { data: onboarding, loading } = useUserSlackOnboarding();

  const showBanner = useMemo(() => {
    if (!onboarding || !onboarding.me) return false;
    if (!onboarding.me.company) return false;
    if (!onboarding.me.company.slackManager) return false;
    if (!onboarding.me.company.slackConfiguration) return false;
    if (!onboarding.me.company.slackConfiguration.isRequired) return false;
    if (onboarding.me.email === onboarding.me.company.manager.email)
      return !onboarding.me.company.slackConfiguration.isConfigured;
    if (onboarding.me.email === onboarding.me.company.slackManager.email)
      return !onboarding.me.company.slackConfiguration.isConfigured;
    return false;
  }, [onboarding, loading]);

  return (
    <Fragment>
      {showBanner && (
        <Content>
          <TextRow>
            <ConnectLogosRow>
              <Badge color={'var(--slack-white)'}>
                <SlackLogo />
              </Badge>
              <PlusIcon stroke={'#ffffff'} />
              <Badge color={'var(--purple)'}>
                <LumoLogo />
              </Badge>
            </ConnectLogosRow>
            <div>{'Start using Lumo Health inside your Slack workspace.'}</div>
          </TextRow>
          <ContinueLink to={PATIENT_ROUTES.COMPANY_SLACK_WORKSPACE_ONBOARDING}>
            {'Set up Slack'}
          </ContinueLink>
        </Content>
      )}
    </Fragment>
  );
};

const ConnectLogosRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${getSize(150)};
  justify-content: space-between;
  margin-right: 19px;
`;

const ContinueLink = styled(Link)`
  color: var(--slack-white);
  border: ${getSize(1)} solid var(--slack-white);
  border-radius: ${getSize(91)};
  padding: ${getSize(11)} ${getSize(28)};
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(19)};
  transition: 0.3s ease-in;

  &:hover {
    transition: 0.3s ease-out;
    background-color: var(--slack-white);
    color: var(--slack-aubergine);
  }
`;

const Content = styled.div`
  height: 136px;
  padding: 40px 42px;
  color: var(--slack-white);
  background: var(--slack-aubergine);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
  border-radius: 7px;
  margin-top: ${getSize(15)};
  margin-bottom: ${getSize(15)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextRow = styled.div`
  display: flex;
  align-items: center;
`;

export default SlackOnboardingBanner;
