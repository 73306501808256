import React from 'react';

function PaymentsIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="#E5E4E8"
      viewBox="0 0 24 25"
      {...rest}
    >
      <g clipPath="url(#payments-icon-svg)">
        <path d="M23.084 5.436L8.385 2.326a1.16 1.16 0 00-1.37.891l-.279 1.795L23.696 8.6l.28-1.795a1.16 1.16 0 00-.892-1.37zM16.902 8.981a1.17 1.17 0 00-1.436-.812l-2.79.775-6.578-1.392-.723 3.418-4.52 1.255a1.17 1.17 0 00-.813 1.435l2.548 9.18a1.17 1.17 0 001.436.812l14.612-4.055a1.17 1.17 0 00.812-1.436l-.437-1.576 1.638.346a1.159 1.159 0 001.37-.89l1.037-4.9-5.903-1.25-.253-.91zm1.688 4.666l.361-1.708a.477.477 0 01.564-.367l1.708.362c.256.054.42.308.367.564l-.362 1.707a.477.477 0 01-.563.367l-1.708-.361a.477.477 0 01-.367-.564zM1.109 13.14l4.043-1.121 9.542-2.648 1.026-.285a.22.22 0 01.265.15l.121.434.378 1.36L1.457 15.2l-.498-1.794a.219.219 0 01.15-.266zm17.425 5.275c.019.07-.001.127-.022.163a.21.21 0 01-.129.102L3.772 22.735a.22.22 0 01-.265-.15L2.02 17.231l15.027-4.17.917 3.302.57 2.052z" />
        <path d="M6.132 18.781a.477.477 0 00-.585-.33l-1.707.473a.477.477 0 00-.33.585l.473 1.707c.07.252.333.4.585.33l1.707-.473a.477.477 0 00.331-.585l-.474-1.707z" />
      </g>
      <defs>
        <clipPath id="payments-icon-svg">
          <path fill="#fff" d="M0 0h24v24H0z" transform="translate(0 .997)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PaymentsIcon;
