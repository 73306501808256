import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveUserSubCategoriesVariables = Types.Exact<{
  subCategoryIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type SaveUserSubCategories = (
  { __typename: 'Mutation' }
  & Pick<Types.Mutation, 'saveUserSubCategories'>
);


export const SaveUserSubCategoriesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SaveUserSubCategories"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"subCategoryIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveUserSubCategories"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"subCategoryIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"subCategoryIds"}}}]}]}}]} as unknown as DocumentNode;
export type SaveUserSubCategoriesMutationFn = Apollo.MutationFunction<SaveUserSubCategories, SaveUserSubCategoriesVariables>;

/**
 * __useSaveUserSubCategories__
 *
 * To run a mutation, you first call `useSaveUserSubCategories` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserSubCategories` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserSubCategories, { data, loading, error }] = useSaveUserSubCategories({
 *   variables: {
 *      subCategoryIds: // value for 'subCategoryIds'
 *   },
 * });
 */
export function useSaveUserSubCategories(baseOptions?: Apollo.MutationHookOptions<SaveUserSubCategories, SaveUserSubCategoriesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserSubCategories, SaveUserSubCategoriesVariables>(SaveUserSubCategoriesDocument, options);
      }
export type SaveUserSubCategoriesHookResult = ReturnType<typeof useSaveUserSubCategories>;
export type SaveUserSubCategoriesMutationResult = Apollo.MutationResult<SaveUserSubCategories>;
export type SaveUserSubCategoriesMutationOptions = Apollo.BaseMutationOptions<SaveUserSubCategories, SaveUserSubCategoriesVariables>;