import { getSize } from 'lib/utils';
import React, { FC, useCallback } from 'react';
import styled, { CSSProp } from 'styled-components';
import { ArrowDownIcon } from 'ui/icons';

interface ToggleAddressesProps {
  currentAddressIndex: number;
  setCurrentAddressIndex: (value: number) => void;
  addressesCount: number;
  rootCSS?: CSSProp;
}

const ToggleAddresses: FC<ToggleAddressesProps> = ({
  currentAddressIndex,
  setCurrentAddressIndex,
  addressesCount,
  rootCSS,
}) => {
  const onNextClick = useCallback(() => {
    if (currentAddressIndex >= addressesCount) {
      setCurrentAddressIndex(0);
    } else {
      setCurrentAddressIndex(currentAddressIndex + 1);
    }
  }, [currentAddressIndex, setCurrentAddressIndex, addressesCount]);

  const onPrevClick = useCallback(() => {
    if (currentAddressIndex <= 0) {
      setCurrentAddressIndex(addressesCount);
    } else {
      setCurrentAddressIndex(currentAddressIndex - 1);
    }
  }, [currentAddressIndex, setCurrentAddressIndex, addressesCount]);

  return (
    <ToggleAddressesWrapper $CSS={rootCSS}>
      <PrevAddress type="button" onClick={onPrevClick}>
        <ArrowIcon />
      </PrevAddress>
      <ToggleAddressButtonText>Next address</ToggleAddressButtonText>
      <NextAddress type="button" onClick={onNextClick}>
        <ArrowIcon />
      </NextAddress>
    </ToggleAddressesWrapper>
  );
};

const ToggleAddressesWrapper = styled.div<{ $CSS?: CSSProp }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: ${getSize(10)};
  right: ${getSize(10)};
  width: ${getSize(146)};
  height: ${getSize(34)};
  padding: ${getSize(6)} ${getSize(11)};
  box-shadow: 0px ${getSize(10)} ${getSize(30)} var(--blue-opacity2);
  border-radius: ${getSize(8)};
  background-color: var(--white);

  ${({ $CSS }) => $CSS}
`;

const ToggleAddressButtonText = styled.p`
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--black3);
`;

const ArrowIcon = styled(ArrowDownIcon)`
  stroke: var(--purple5);
  width: ${getSize(22)};
  height: ${getSize(22)};
  transition: 0.3s ease-out;

  &:hover {
    stroke: var(--purple);
  }
`;

const PrevAddress = styled.button`
  transform: rotate(90deg);
`;

const NextAddress = styled.button`
  transform: rotate(-90deg);
`;

export default ToggleAddresses;
