import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCategoriesAndSubcategoriesVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCategoriesAndSubcategories = (
  { __typename: 'Query' }
  & { getCategoriesAndSubcategories: Array<(
    { __typename: 'ResourcesCategoryDetailsModel' }
    & Pick<Types.ResourcesCategoryDetailsModel, 'id' | 'name' | 'imageUrl' | 'iconUrl'>
    & { subCategories: Array<(
      { __typename: 'ResourcesSubcategoryDetailsModel' }
      & Pick<Types.ResourcesSubcategoryDetailsModel, 'id' | 'name' | 'imageUrl'>
    )> }
  )> }
);


export const GetCategoriesAndSubcategoriesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCategoriesAndSubcategories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCategoriesAndSubcategories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"iconUrl"}},{"kind":"Field","name":{"kind":"Name","value":"subCategories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetCategoriesAndSubcategories__
 *
 * To run a query within a React component, call `useGetCategoriesAndSubcategories` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesAndSubcategories` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesAndSubcategories({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesAndSubcategories(baseOptions?: Apollo.QueryHookOptions<GetCategoriesAndSubcategories, GetCategoriesAndSubcategoriesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesAndSubcategories, GetCategoriesAndSubcategoriesVariables>(GetCategoriesAndSubcategoriesDocument, options);
      }
export function useGetCategoriesAndSubcategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesAndSubcategories, GetCategoriesAndSubcategoriesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesAndSubcategories, GetCategoriesAndSubcategoriesVariables>(GetCategoriesAndSubcategoriesDocument, options);
        }
export type GetCategoriesAndSubcategoriesHookResult = ReturnType<typeof useGetCategoriesAndSubcategories>;
export type GetCategoriesAndSubcategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesAndSubcategoriesLazyQuery>;
export type GetCategoriesAndSubcategoriesQueryResult = Apollo.QueryResult<GetCategoriesAndSubcategories, GetCategoriesAndSubcategoriesVariables>;