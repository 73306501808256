import React, { FC } from 'react';
import { getSize, validationRules } from 'lib/utils';
import styled from 'styled-components';
import { Button, Modal, Textarea } from 'ui';
import { Field, Form as FinalForm } from 'react-final-form';
import {
  EMAIL_INPUT_CSS,
  LABEL_CSS,
} from '../../features/auth/sign-in/sign-in';
import { ROOT_CSS } from '../../features/auth/sign-up-b2b/sign-up-b2b';

import { ReportAbuseInput } from '../../__generated__/types';

interface ReportResourceModalProps {
  isVisible: boolean;
  onClose: () => void;
  handleFormSubmit: (input: ReportAbuseInput) => void;
  isLoading?: boolean;
}

const ReportResourceModal: FC<ReportResourceModalProps> = ({
  isVisible,
  onClose,
  isLoading,
  handleFormSubmit,
}) => {
  return (
    <Modal title="Report abuse" isVisible={isVisible} onClose={onClose}>
      <Text>How does this file break the code of conduct</Text>
      <FinalForm
        onSubmit={handleFormSubmit}
        render={({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Field
                name="reason"
                label="Description"
                labelCSS={LABEL_CSS}
                inputCSS={EMAIL_INPUT_CSS}
                rootCSS={ROOT_CSS}
                component={Textarea}
                validate={validationRules.required}
                placeholder="Reason"
              />

              <ButtonConfirm
                isLoading={isLoading}
                theme="primary"
                type="submit"
              >
                Submit
              </ButtonConfirm>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

const Text = styled.p`
  font-size: ${getSize(18)};
  line-height: ${getSize(24)};
  margin-bottom: ${getSize(40)};
`;

const ButtonConfirm = styled(Button)`
  border: 1px solid #f0edfd;
  border-radius: 36px;
  margin-bottom: ${getSize(7)};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 0 ${getSize(20)};
`;

export default ReportResourceModal;
