import React from 'react';

const OpenBookIcon = () => {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.60058 0.00585938C2.60012 0.00585938 2.5996 0.00585938 2.59915 0.00585938C2.43836 0.00585938 2.28713 0.0685362 2.17294 0.182442C2.05749 0.297607 1.9939 0.450951 1.9939 0.614197V9.73612C1.9939 10.0706 2.2671 10.3434 2.60298 10.3443C4.01897 10.3477 6.39131 10.6428 8.02789 12.3554V2.80909C8.02789 2.6957 7.99893 2.58918 7.94427 2.50103C6.60103 0.337847 4.01977 0.00917925 2.60058 0.00585938Z"
        fill="#6C4FE7"
      />
      <path
        d="M15.0063 9.73619V0.614198C15.0063 0.450952 14.9428 0.297608 14.8273 0.182442C14.7131 0.0685363 14.5618 0.00585938 14.4012 0.00585938C14.4006 0.00585938 14.4001 0.00585938 14.3997 0.00585938C12.9805 0.00923649 10.3993 0.337904 9.05598 2.50109C9.00132 2.58924 8.97241 2.69576 8.97241 2.80915V12.3555C10.609 10.6428 12.9813 10.3477 14.3973 10.3443C14.7331 10.3434 15.0063 10.0706 15.0063 9.73619Z"
        fill="#6C4FE7"
      />
      <path
        d="M16.392 2.10938H15.9509V9.73599C15.9509 10.5901 15.255 11.2865 14.3996 11.2886C13.1986 11.2915 11.2182 11.5263 9.81567 12.8538C12.2414 12.2598 14.7985 12.6459 16.2558 12.978C16.4377 13.0195 16.6258 12.9766 16.7716 12.8605C16.9168 12.7445 17.0002 12.5713 17.0002 12.3853V2.7176C17.0002 2.38223 16.7273 2.10938 16.392 2.10938Z"
        fill="#6C4FE7"
      />
      <path
        d="M1.04931 9.73599V2.10938H0.608224C0.272917 2.10938 0 2.38223 0 2.7176V12.3851C0 12.5712 0.0833403 12.7444 0.228614 12.8603C0.374287 12.9764 0.562147 13.0194 0.744396 12.9778C2.20171 12.6457 4.75887 12.2597 7.18444 12.8536C5.78197 11.5262 3.8016 11.2914 2.60055 11.2885C1.74522 11.2865 1.04931 10.5901 1.04931 9.73599Z"
        fill="#6C4FE7"
      />
    </svg>
  );
};

export default OpenBookIcon;
