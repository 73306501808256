import React, { FC, ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

interface DownloadLinkProps {
  children: ReactNode;
  href: string;
  rootCSS?: CSSProp;
  onClick?: () => void;
  className?: string;
}

const DownloadLink: FC<DownloadLinkProps> = ({
  href,
  children,
  rootCSS,
  className,
  onClick,
}) => {
  return (
    <Link
      onClick={onClick}
      className={className}
      $CSS={rootCSS}
      target="_blank"
      href={href}
      download
    >
      {children}
    </Link>
  );
};

const Link = styled.a<{ $CSS?: CSSProp }>`
  ${({ $CSS }) => $CSS};
`;

export default DownloadLink;
