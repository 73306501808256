import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { getSize } from 'lib/utils';
import { Note } from '../note-item/note-item';
import { NotesButtons, NoteItem } from 'features/common';
import {
  DropDownFilter,
  Loader,
  Button,
  EmptyNotesIcon,
  ArrowDownIcon,
} from 'ui';
import { NoteModal } from '../note-modal';
import { NoteModalFormValues } from '../note-modal/note-modal';
import { NoteType } from '../../../__generated__/types';
import { GenerateDownloadLinkService } from '../../../lib/utils';
import { useWindowClick } from '../../../hooks';
import { Option } from 'ui/drop-down-filter/drop-down-filter';
import { useHistory } from 'react-router-dom';

export interface NotesScreenProps {
  notes: Note[];
  isTherapist?: boolean;
  isVisibleNoteModal: boolean;
  onFilterChange: (value: NoteType | NoteType[]) => void;
  onDeleteClick: () => void;
  onAddNoteSubmit: (values: NoteModalFormValues) => void;
  setIsVisibleNoteModal: (value: boolean) => void;
  onNoteDeleteClick: (is: string) => void;
  setNoteInitialValues: (values?: NoteModalFormValues) => void;
  noteInitialValues?: NoteModalFormValues;
  isFetchingNotes?: boolean;
  downloadAllLink: string;
  connectedTherapistsOptions?: Option[];
  selectedFilterType: NoteType;
  filterType?: NoteType;
}

const TYPES_OPTIONS = [
  { value: NoteType.All, label: 'All' },
  { value: NoteType.Shared, label: 'Shared' },
  { value: NoteType.OnlyMyNotes, label: 'Only my notes' },
];

function NotesScreen({
  notes,
  isTherapist,
  isVisibleNoteModal,
  onFilterChange,
  onDeleteClick,
  onAddNoteSubmit,
  setIsVisibleNoteModal,
  onNoteDeleteClick,
  setNoteInitialValues,
  selectedFilterType,
  noteInitialValues,
  isFetchingNotes,
  downloadAllLink,
  connectedTherapistsOptions,
  filterType,
}: NotesScreenProps) {
  const [noteIdWithOpenedDropdown, setNoteIdWithOpenedDropdown] = useState('');

  const { getOneTherapistNote, getOnePatientNote } =
    GenerateDownloadLinkService;

  const onCloseNoteNoteModal = useCallback(() => {
    setIsVisibleNoteModal(false);
    setNoteInitialValues(undefined);
  }, [setIsVisibleNoteModal, setNoteInitialValues]);

  useWindowClick(() => setNoteIdWithOpenedDropdown(''));

  const isEmptyNotes = notes.length === 0 && filterType === NoteType.All;

  const history = useHistory();

  return (
    <Wrapper $isTherapist={isTherapist}>
      {isTherapist && (
        <BackButton onClick={() => history.goBack()}>
          <Icon />
          Back
        </BackButton>
      )}
      <HeaderWrapper>
        <Title>My Notes</Title>
        <AddNoteButton
          theme="primary"
          onClick={() => setIsVisibleNoteModal(true)}
        >
          New Note
        </AddNoteButton>
        <FilterWrapper>
          <DropDownFilter<NoteType>
            title="Filter"
            options={TYPES_OPTIONS}
            single
            disabled={isEmptyNotes}
            wrapperCSS={filterWrapperCss}
            value={selectedFilterType}
            setValue={(values) => {
              onFilterChange(values);
            }}
          />

          <NotesButtons
            title="More"
            disabled={isEmptyNotes}
            deleteText="Delete All"
            downloadText="Download All"
            onDeleteClick={onDeleteClick}
            downloadLink={downloadAllLink}
            onAddClick={() => setIsVisibleNoteModal(true)}
            onlyAdd={isEmptyNotes}
          />
        </FilterWrapper>
      </HeaderWrapper>

      {isEmptyNotes === false ? (
        <NotesList $isTwoColumn={notes.length <= 2} $isTherapist={isTherapist}>
          {notes.map((note) => {
            const noteId = note.id;
            return (
              <NoteItem
                key={noteId}
                isSmall
                note={note}
                onDeleteClick={() => onNoteDeleteClick(noteId)}
                onEditClick={setNoteInitialValues}
                downloadLink={
                  isTherapist
                    ? getOneTherapistNote(noteId)
                    : getOnePatientNote(noteId)
                }
                isTherapist={isTherapist}
              />
            );
          })}
        </NotesList>
      ) : (
        <EmptyNotesWrapper>
          <EmptyNotesIcon />
          <EmptyText>
            Let’s start adding notes, click on
            <br />{' '}
            <AddButton onClick={() => setIsVisibleNoteModal(true)}>
              + New Note
            </AddButton>{' '}
            to start your journey
          </EmptyText>
        </EmptyNotesWrapper>
      )}
      {isFetchingNotes && <Loader />}
      <NoteModal
        isVisible={isVisibleNoteModal}
        onCloseNoteModal={onCloseNoteNoteModal}
        onAddNoteSubmit={onAddNoteSubmit}
        initialValues={noteInitialValues}
        isTherapist={isTherapist}
        connectedTherapistsOptions={connectedTherapistsOptions}
      />
    </Wrapper>
  );
}
interface WrapperProps {
  $isTherapist?: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  padding: ${({ $isTherapist }) =>
    $isTherapist
      ? `${getSize(12)} ${getSize(110)} ${getSize(102)} ${getSize(50)}`
      : 0};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 ${getSize(26)};
  padding-bottom: 22px;
`;

const FilterWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const filterWrapperCss = css`
  margin-right: 30px;
`;

const Title = styled.h1`
  font-family: Poppins;
  font-size: ${getSize(22)};
  font-weight: 600;
  line-height: ${getSize(33)};
  margin-right: 13px;
`;

const pseudo = css`
  content: '';
  display: block;
  width: 2px;
  height: 12px;
  border-radius: 2px;
  background: white;
  position: absolute;
  top: 50%;
  left: 19px;
  transform: translate(0, -50%);
`;

const AddNoteButton = styled(Button)`
  font-family: Poppins;
  font-size: ${getSize(12)};
  font-weight: 600;
  line-height: ${getSize(24)};
  background: var(--purple);
  position: relative;
  width: ${getSize(119)};
  height: ${getSize(35)};
  border-radius: 34px;
  padding-left: 35px;

  &:before {
    ${pseudo};
    transform: translate(0, -50%) rotate(-90deg);
  }

  &:after {
    ${pseudo};
  }
`;

const NotesList = styled.ul<{ $isTherapist?: boolean; $isTwoColumn?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${getSize(20)} ${getSize(20)};
  margin: 0 0 ${getSize(14)};
  ${({ $isTwoColumn }) => {
    return $isTwoColumn
      ? `grid-template-columns: 1fr 1fr;`
      : `grid-template-columns: 1fr 1fr 1fr;`;
  }}
`;

const EmptyNotesWrapper = styled.div`
  height: 100%;
  margin-top: 133px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyText = styled.p`
  font-family: Poppins;
  font-size: ${getSize(18)};
  font-weight: 500;
  line-height: ${getSize(24)};
  text-align: center;
  color: black;
  margin-top: 27px;
`;

const AddButton = styled.button`
  color: var(--purple);
  display: inline;
`;

const BackButton = styled.button<{ $isAssessmentOutput?: boolean }>`
  top: ${getSize(68)};
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--gray2);
  transition: color 0.2s ease-in;

  & > svg {
    transition: stroke 0.2s ease-in;
  }

  &:hover {
    color: var(--purple);
  }
  margin-bottom: ${getSize(15)};

  ${({ $isAssessmentOutput }) =>
    $isAssessmentOutput &&
    css`
      color: var(--black3);
      font-size: ${getSize(14)};
      line-height: ${getSize(24)};
      transition: color 0s;

      &:hover {
        color: var(--purple);

        svg {
          path {
            fill: var(--purple);
          }
        }
      }
    `}
`;

const Icon = styled(ArrowDownIcon)`
  transform: rotate(90deg);
  margin: 0 ${getSize(8)} 0 0;
  stroke: var(--black3);
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

export default NotesScreen;
