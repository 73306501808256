import * as Types from '../../../__generated__/types';

import { PatientNote } from '../../query/__generated__/get-patient-notes';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientNoteAddVariables = Types.Exact<{
  input: Types.PatientAddNoteInput;
}>;


export type PatientNoteAdd = (
  { __typename: 'Mutation' }
  & { patientNoteAdd: (
    { __typename: 'NotePayload' }
    & { note?: Types.Maybe<(
      { __typename: 'NoteModel' }
      & PatientNote
    )>, userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const PatientNoteAddDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PatientNoteAdd"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PatientAddNoteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientNoteAdd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"note"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PatientNote"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}},...PatientNote.definitions]} as unknown as DocumentNode;
export type PatientNoteAddMutationFn = Apollo.MutationFunction<PatientNoteAdd, PatientNoteAddVariables>;

/**
 * __usePatientNoteAdd__
 *
 * To run a mutation, you first call `usePatientNoteAdd` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientNoteAdd` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientNoteAdd, { data, loading, error }] = usePatientNoteAdd({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientNoteAdd(baseOptions?: Apollo.MutationHookOptions<PatientNoteAdd, PatientNoteAddVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientNoteAdd, PatientNoteAddVariables>(PatientNoteAddDocument, options);
      }
export type PatientNoteAddHookResult = ReturnType<typeof usePatientNoteAdd>;
export type PatientNoteAddMutationResult = Apollo.MutationResult<PatientNoteAdd>;
export type PatientNoteAddMutationOptions = Apollo.BaseMutationOptions<PatientNoteAdd, PatientNoteAddVariables>;