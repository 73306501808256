import { amplitudeService } from 'lib/amplitude';
import { gtmService } from 'lib/gtm';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

amplitudeService.init();
gtmService.init();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
