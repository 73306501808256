import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TherapistShortDetailsVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type TherapistShortDetails = (
  { __typename: 'Query' }
  & { therapistPublicById: (
    { __typename: 'TherapistPublicModel' }
    & Pick<Types.TherapistPublicModel, 'id' | 'title' | 'avatarUrl' | 'fullName' | 'professionalTitle'>
    & { coreTraining: (
      { __typename: 'CoreTrainingModel' }
      & Pick<Types.CoreTrainingModel, 'label'>
    ) }
  ) }
);


export const TherapistShortDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TherapistShortDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistPublicById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"avatarUrl"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"professionalTitle"}},{"kind":"Field","name":{"kind":"Name","value":"coreTraining"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"label"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useTherapistShortDetails__
 *
 * To run a query within a React component, call `useTherapistShortDetails` and pass it any options that fit your needs.
 * When your component renders, `useTherapistShortDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistShortDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTherapistShortDetails(baseOptions: Apollo.QueryHookOptions<TherapistShortDetails, TherapistShortDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistShortDetails, TherapistShortDetailsVariables>(TherapistShortDetailsDocument, options);
      }
export function useTherapistShortDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistShortDetails, TherapistShortDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistShortDetails, TherapistShortDetailsVariables>(TherapistShortDetailsDocument, options);
        }
export type TherapistShortDetailsHookResult = ReturnType<typeof useTherapistShortDetails>;
export type TherapistShortDetailsLazyQueryHookResult = ReturnType<typeof useTherapistShortDetailsLazyQuery>;
export type TherapistShortDetailsQueryResult = Apollo.QueryResult<TherapistShortDetails, TherapistShortDetailsVariables>;