import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'ui';

interface CancelModalProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirmClick: () => void;
  onRescheduleClick: () => void;
  isLoading?: boolean;
}

const CancelModal: FC<CancelModalProps> = ({
  isVisible,
  onClose,
  onConfirmClick,
  onRescheduleClick,
}) => {
  return (
    <Modal
      title={`Session Cancellation`}
      isVisible={isVisible}
      onClose={onClose}
    >
      <AreYouSureText>Are you sure?</AreYouSureText>
      <Description>Caution Description</Description>
      <RescheduleButton theme="outline" onClick={onRescheduleClick}>
        Reschedule session
      </RescheduleButton>
      <ConfirmButton theme="primary" onClick={onConfirmClick}>
        Confirm
      </ConfirmButton>
    </Modal>
  );
};

const AreYouSureText = styled.span`
  font-size: ${getSize(11)};
  font-weight: 400;
  line-height: ${getSize(18)};
  color: var(--gray7);
  margin-bottom: 20px;
`;

const Description = styled.span`
  font-size: ${getSize(12)};
  font-weight: 400;
  line-height: ${getSize(24)};
  padding-bottom: 18px;
  border-bottom: 1px solid var(--purple3);
  margin-bottom: 32px;
`;

const RescheduleButton = styled(Button)`
  margin-bottom: 8px;
  color: #444752;
`;

const ConfirmButton = styled(Button)`
  border-radius: 34px;
  font-size: ${getSize(12)};
  font-weight: 500;
  line-height: ${getSize(18)};
`;

export default CancelModal;
