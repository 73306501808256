import React, { useMemo } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { DropzoneInputProps } from 'react-dropzone';

import { UploadFile } from 'interfaces/files';
import { getSize } from 'lib/utils';

import { UploadButton } from '../upload-button';
import { CrossIcon } from 'ui/icons';

export interface FileItemProps {
  file?: UploadFile;
  placeholder: string;
  onDeleteClick: () => void;
  disabled?: boolean;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
  isLoading: boolean;
  isSecondaryThemeActive: boolean;
  uploadButtonCSS?: CSSProp;
}

function FileItem({
  file,
  placeholder,
  onDeleteClick,
  disabled,
  getInputProps,
  isLoading,
  isSecondaryThemeActive,
  uploadButtonCSS,
}: FileItemProps) {
  const hasFile = Boolean(file);
  const currentFileName = file?.name || '';
  const currentFileSize = file?.sizeInKb || 0;

  const fileName = useMemo(() => {
    return currentFileName.length >= 10
      ? `${currentFileName.slice(0, 10)}... `
      : currentFileName;
  }, [currentFileName]);

  const fileSize = useMemo(
    () =>
      new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(
        currentFileSize,
      ),
    [currentFileSize],
  );

  const labelContent = useMemo(() => {
    if (hasFile) {
      return isSecondaryThemeActive ? (
        <button type="button" onClick={onDeleteClick}>
          <CloseIcon />
        </button>
      ) : (
        <DeleteButton
          $hasAdditionalCSS={Boolean(uploadButtonCSS)}
          type="button"
          disabled={isLoading}
          onClick={onDeleteClick}
        >
          Delete
        </DeleteButton>
      );
    }

    return (
      <UploadButton
        isLoading={isLoading}
        getInputProps={getInputProps}
        rootCSS={uploadButtonCSS}
      />
    );
  }, [
    getInputProps,
    hasFile,
    isLoading,
    onDeleteClick,
    isSecondaryThemeActive,
    uploadButtonCSS,
  ]);

  return (
    <Wrapper $isSecondaryThemeActive={isSecondaryThemeActive}>
      <Placeholder
        $hasFile={hasFile}
        $isSecondaryThemeActive={isSecondaryThemeActive}
        $hasAdditionalCSS={Boolean(uploadButtonCSS)}
      >
        {file ? (
          <>
            {`${fileName}.${file.extension.toLowerCase()} `}
            <FileSize
              $isSecondaryThemeActive={isSecondaryThemeActive}
            >{`(${fileSize} kb)`}</FileSize>
          </>
        ) : (
          placeholder
        )}
      </Placeholder>

      {!disabled && labelContent}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $isSecondaryThemeActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${getSize(9)}
    ${({ $isSecondaryThemeActive }) =>
      $isSecondaryThemeActive ? getSize(9) : 0}
    ${getSize(9)} 0;
  ${({ $isSecondaryThemeActive }) =>
    $isSecondaryThemeActive &&
    `
    padding: 0 ${getSize(20)};
    background: var(--purple11);
    border-radius: ${getSize(8)};
  `}

  ${({ $isSecondaryThemeActive }) =>
    !$isSecondaryThemeActive &&
    `
    &:not(:last-of-type) {
      margin-bottom: ${getSize(5)};
    }
  `}
`;

const Placeholder = styled.p<{
  $hasFile: boolean;
  $isSecondaryThemeActive: boolean;
  $hasAdditionalCSS?: boolean;
}>`
  font-weight: ${({ $hasAdditionalCSS }) => ($hasAdditionalCSS ? 500 : 400)};
  font-size: ${({ $isSecondaryThemeActive, $hasAdditionalCSS }) =>
    getSize($isSecondaryThemeActive ? 12 : $hasAdditionalCSS ? 13 : 14)};
  line-height: ${getSize(24)};
  color: var(
    ${({ $hasFile, $hasAdditionalCSS }) =>
      $hasFile ? ($hasAdditionalCSS ? '--black4' : '--black3') : '--gray2'}
  );
`;

const FileSize = styled.span<{ $isSecondaryThemeActive: boolean }>`
  ${({ $isSecondaryThemeActive }) =>
    $isSecondaryThemeActive &&
    `
    margin: 0 ${getSize(13)} 0 0;
    font-weight: 400;
    font-size: ${getSize(11)};
    line-height: ${getSize(18)};
    color: var(--gray2);
  `}
`;

const CloseIcon = styled(CrossIcon)`
  stroke: var(--black3);

  &:hover {
    stroke: var(--purple);
  }
`;

const DeleteButton = styled.button<{ $hasAdditionalCSS?: boolean }>`
  ${({ $hasAdditionalCSS }) =>
    $hasAdditionalCSS
      ? css`
          flex-shrink: 0;
          padding: ${getSize(4)} ${getSize(12)};
          font-weight: 500;
          font-size: ${getSize(13)};
          line-height: ${getSize(20)};
          background: var(--white);
          border-radius: ${getSize(77)};
          border: 1px solid var(--gray15);
          color: var(--gray14);
        `
      : css`
          flex-shrink: 0;
          padding: ${getSize(4)} ${getSize(12)};
          font-weight: 400;
          font-size: ${getSize(12)};
          line-height: ${getSize(18)};
          background: var(--purple11);
          border-radius: ${getSize(8)};
          color: var(--red);
        `}
`;

export default FileItem;
