import { getSize } from 'lib/utils';
import React, { FC, ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';
import { ShadowWrapper } from 'ui/shadow-wrapper';

interface SettingsFieldsWrapperProps {
  children: ReactNode;
  title?: string;
  className?: string;
  rootCSS?: CSSProp;
  titleCSS?: CSSProp;
  isPatient?: boolean;
}

const SettingsFieldsWrapper: FC<SettingsFieldsWrapperProps> = ({
  children,
  title,
  className,
  rootCSS,
  titleCSS,
  isPatient,
}) => {
  return (
    <Wrapper className={className} $CSS={rootCSS} $isPatient={isPatient}>
      {title && <Title $CSS={titleCSS}>{title}</Title>}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(ShadowWrapper)<{ $CSS?: CSSProp; $isPatient?: boolean }>`
  width: ${({ $isPatient }) => ($isPatient ? getSize(742) : getSize(642))};
  padding: ${getSize(34)} ${getSize(32)};

  ${({ $CSS }) => $CSS}
`;

const Title = styled.p<{ $CSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(18)};
  line-height: ${getSize(32)};
  color: var(--black3);
  margin-bottom: ${getSize(24)};

  ${({ $CSS }) => $CSS}
`;

export default SettingsFieldsWrapper;
