import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function RetryIcon({ ...rest }) {
  return (
    <Icon
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.7854 9.22788C16.9662 10.9279 16.5476 12.6377 15.6019 14.0619C14.6563 15.4861 13.2429 16.5354 11.606 17.0285C9.96906 17.5216 8.21131 17.4275 6.63634 16.7626C5.06136 16.0976 3.76804 14.9035 2.97976 13.3865C2.19147 11.8695 1.95771 10.1249 2.31884 8.45384C2.67998 6.78283 3.61333 5.29039 4.95771 4.23429C6.30209 3.17819 7.97308 2.62474 9.68209 2.66951C11.3911 2.71429 13.0308 3.35449 14.318 4.47955L13.0203 4.48661C12.7772 4.48731 12.5443 4.58456 12.3729 4.75697C12.2015 4.92938 12.1056 5.16281 12.1063 5.40593C12.107 5.64904 12.2043 5.88192 12.3767 6.05333C12.5491 6.22474 12.7825 6.32064 13.0256 6.31994H13.0307L16.3357 6.30206C16.5787 6.30073 16.8113 6.20293 16.9823 6.03018C17.1532 5.85742 17.2486 5.62385 17.2473 5.38081L17.229 2.07586C17.2328 1.95422 17.2115 1.83311 17.1663 1.72008C17.1212 1.60706 17.0532 1.50456 16.9667 1.41899C16.8802 1.33342 16.7769 1.26661 16.6634 1.22275C16.5499 1.17888 16.4285 1.1589 16.3069 1.16406C16.0639 1.16549 15.8314 1.26337 15.6605 1.4362C15.4896 1.60902 15.3944 1.84263 15.3957 2.08567L15.4004 2.99895C13.7738 1.62899 11.7212 0.867864 9.59464 0.84618C7.46811 0.824495 5.40034 1.54361 3.74615 2.88011C2.09196 4.21661 0.954506 6.08717 0.528969 8.1708C0.103431 10.2544 0.416347 12.4212 1.41402 14.2993C2.41169 16.1774 4.03192 17.6497 5.99665 18.4636C7.96139 19.2774 10.1481 19.3821 12.1816 18.7597C14.2151 18.1372 15.9686 16.8264 17.1411 15.0522C18.3136 13.278 18.8321 11.151 18.6076 9.0363C18.5981 8.91418 18.5643 8.7952 18.508 8.68638C18.4518 8.57757 18.3743 8.48112 18.2802 8.40275C18.1861 8.32437 18.0772 8.26566 17.96 8.23007C17.8428 8.19449 17.7196 8.18275 17.5978 8.19555C17.476 8.20836 17.358 8.24544 17.2508 8.30462C17.1435 8.3638 17.0492 8.44387 16.9735 8.54011C16.8977 8.63634 16.842 8.74678 16.8096 8.86491C16.7772 8.98304 16.7688 9.10646 16.785 9.22788H16.7854Z"
        fill="#6B4EE6"
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(19)};
  height: ${getSize(20)};
`;

export default RetryIcon;
