import { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const mainWrapper = document.getElementsByTagName('main');

    if (mainWrapper.length) {
      mainWrapper[0].scrollTop = 0;
    }
  }, [pathname]);

  return null;
}

export default withRouter(ScrollToTop);
