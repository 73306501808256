import React from 'react';
import styled from 'styled-components';
import { Form as FinalForm, FormSpy, Field } from 'react-final-form';

import { getSize } from 'lib/utils';
import { ChatUserFragment } from 'components/chat/query/__generated__/users-for-start-new-chat';

import { Loader, Modal, Search } from 'ui';
import { InterlocutorItem, EmptyBlock } from './components';

export interface WriteMessageModalProps {
  isVisible: boolean;
  onClose: () => void;
  onFormSubmit: (search?: string) => void;
  interlocutors: ChatUserFragment[];
  onWriteClick: (interlocutorId: string) => void;
  isLoadingInterlocutor?: boolean;
  isStartNewTherapistChatLoading?: boolean;
  idOfUserWithNewChat: string;
  isGroupParticipants: boolean;
  userId?: string;
}

const HEADER_CSS = {
  margin: `0 0 ${getSize(16)}`,
  padding: `0 ${getSize(36)}`,
  flexShrink: 0,
};
const PAPER_CSS = {
  padding: `${getSize(34)} 0 ${getSize(20)}`,
  maxHeight: getSize(435),
  height: '100%',
};

function WriteMessageModal({
  isVisible,
  onClose,
  onFormSubmit,
  interlocutors,
  onWriteClick,
  isLoadingInterlocutor,
  isStartNewTherapistChatLoading,
  idOfUserWithNewChat,
  isGroupParticipants,
  userId,
}: WriteMessageModalProps) {
  function getContent(searchString?: string) {
    if (interlocutors.length === 0) {
      if (searchString) {
        return <EmptyBlock />;
      } else {
        return <EmptyText>Users not register yet</EmptyText>;
      }
    }

    return (
      <List>
        {interlocutors.map((interlocutor) => {
          const interlocutorId = interlocutor.id;
          const hasChatStartedWithInterlocutor =
            interlocutorId === idOfUserWithNewChat;

          return (
            <InterlocutorItem
              key={interlocutorId}
              interlocutor={interlocutor}
              searchString={searchString}
              isLoading={
                isStartNewTherapistChatLoading && hasChatStartedWithInterlocutor
              }
              isDisabled={
                isStartNewTherapistChatLoading &&
                !hasChatStartedWithInterlocutor
              }
              hasShowButton={
                !isGroupParticipants ||
                (isGroupParticipants && interlocutorId !== userId)
              }
              onWriteClick={() => onWriteClick(interlocutorId)}
            />
          );
        })}
      </List>
    );
  }

  return (
    <Modal
      title={
        isGroupParticipants
          ? `${interlocutors.length} Participants`
          : 'Write a message'
      }
      headerCSS={HEADER_CSS}
      paperCSS={PAPER_CSS}
      isVisible={isVisible}
      onClose={isStartNewTherapistChatLoading ? undefined : onClose}
    >
      {isLoadingInterlocutor ? (
        <Loader hasFillWholeBlock size={50} />
      ) : (
        <FinalForm
          onSubmit={() => {}}
          render={({ values }) => {
            const searchString = values.search;

            return (
              <Wrapper className="primary-scrollbar">
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values: { search } }) => onFormSubmit(search)}
                />
                <SearchInputWrapper>
                  <Field
                    name="search"
                    component={Search}
                    placeholder="Search"
                  />
                </SearchInputWrapper>

                {getContent(searchString)}
              </Wrapper>
            );
          }}
        />
      )}
    </Modal>
  );
}

const Wrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 ${getSize(8)} 0 0;
  padding: 0 ${getSize(28)} 0 ${getSize(36)};
  overflow: auto;
`;

const SearchInputWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  margin: 0 0 ${getSize(17)};
  background: white;
`;

const EmptyText = styled.p`
  margin: auto;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
`;

const List = styled.ul`
  width: 100%;
`;

export default WriteMessageModal;
