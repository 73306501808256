import React, { ReactElement } from 'react';
import {
  ArrowRightIcon,
  StarDiscoverIcon,
  ThreeDotsTriangleIcon,
} from '../../../../../../ui';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { getSize } from '../../../../../../lib/utils';
import { ALL_CONTENT, DISCOVER, isNavLinkActive } from '../nav-bar/nav-bar';
import { ROOT_ROUTES } from '../../../../../../lib/routes';
import { logout } from '../../../../../../lib/auth';
import { LogoutIcon } from '../../../../../../ui/icons/logout-icon';

const TeamsNavBar: () => ReactElement = () => {
  return (
    <NavBarContainer>
      <LinkList>
        <LinkWrapper>
          <StyledLink
            to={DISCOVER.pathBuild()}
            isActive={(match, location) =>
              isNavLinkActive(match, location, 'discover')
            }
          >
            <InnerLink>
              <StarDiscoverIcon />
              <LinkText>Discover</LinkText>
            </InnerLink>
          </StyledLink>
        </LinkWrapper>
        <LinkWrapper>
          <StyledLink
            to={ALL_CONTENT.pathBuild()}
            isActive={(match, location) =>
              isNavLinkActive(match, location, 'all-content')
            }
          >
            <InnerLink>
              <ThreeDotsTriangleIcon />
              <LinkText>All content</LinkText>
            </InnerLink>
          </StyledLink>
        </LinkWrapper>
      </LinkList>
      <LinkListOutsideLinks>
        <ExploreTherapyLink to={ROOT_ROUTES.HOME} target="_blank">
          <ExploreTherapyLinkText>
            Explore Therapy on Lumo Health
          </ExploreTherapyLinkText>
          <ArrowRightIcon />
        </ExploreTherapyLink>
        <LogoutButton onClick={() => logout()}>
          <LogoutIcon height={30} width={30} />
        </LogoutButton>
      </LinkListOutsideLinks>
    </NavBarContainer>
  );
};

const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

const LinkList = styled.ul`
  display: flex;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const LinkWrapper = styled.li`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(NavLink)`
  width: ${getSize(150)};
  height: ${getSize(49)};
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: #46494b;
  transition: 0.3s ease-out;

  padding: 15px 0 15px 15px;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    border: 1px solid #ededed;
    border-radius: 8px;
    background: white;
  }
`;

const InnerLink = styled.div`
  display: flex;
  align-items: center;
`;

const LinkText = styled.span`
  margin-left: 12px;
  font-weight: 500;
`;

const ExploreTherapyLink = styled(NavLink)`
  width: ${getSize(270)};
  height: ${getSize(32)};
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: white;
  background-color: #6b4ee6;
  transition: 0.3s ease-out;
  border-radius: 34px;

  padding: 15px 0 15px 15px;

  @media (max-width: 600px) {
    width: 100%;
    border-radius: unset;
    margin-bottom: 10px;
  }
`;

const ExploreTherapyLinkText = styled.span`
  margin-right: 5px;
`;

const LogoutButton = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
`;

const LinkListOutsideLinks = styled.div`
  width: 100%;
  display: flex;
  @media (min-width: 600px) {
    justify-content: flex-end;
  }
`;

export default TeamsNavBar;
