import React, { useCallback, useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import Select, { FormatOptionLabelMeta, Styles } from 'react-select';
import { AmplitudeEvents, amplitudeService } from 'lib/amplitude';
import { difference } from 'lodash';
import { ValueType } from 'react-select/src/types';
import { SelectComponents } from 'react-select/src/components';

import { getSize } from 'lib/utils';
import { customStyles } from './utils/styles';
import { Filters } from 'amplitude-analytics/enums';

import { ArrowDownIcon, CrossIcon } from 'ui/icons';

export interface Option {
  label: string | JSX.Element;
  value: string;
  data?: any;
}
export interface MultiSelectProps
  extends FieldRenderProps<string[] | undefined> {
  label?: string;
  rootCSS?: CSSProp;
  noOptionsMessage?: () => string;
  disabled?: boolean;
  options?: Option[];
  isSearchable?: boolean;
  placeholder?: string;
  CustomOptionLabel?: (
    option: Option,
    labelMeta: FormatOptionLabelMeta<Option, any>,
  ) => JSX.Element;
  customComponents?: Partial<SelectComponents<Option, any>>;
  maxSelectedItems?: number;
  notRemovableValues?: string[];
  filterType?: Filters;
  customSelectStyles?: Partial<Styles<any, any>>;
}

function MultiSelect({
  input,
  meta,
  label,
  rootCSS,
  noOptionsMessage,
  disabled,
  options = [],
  placeholder,
  isSearchable = true,
  customComponents,
  CustomOptionLabel,
  maxSelectedItems,
  notRemovableValues = [],
  filterType,
  customSelectStyles,
}: MultiSelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  const selectedOptions = useMemo(() => {
    return Array.isArray(input.value)
      ? input.value.map(
          (value) =>
            options?.find((option) => option.value === value) || ({} as Option),
        )
      : [];
  }, [options, input]);
  const handleChange = useCallback(
    (newSelectedOptions: ValueType<Option, any>) => {
      const selected = newSelectedOptions || [];

      const options = maxSelectedItems
        ? (selected as Option[])?.slice(0, maxSelectedItems)
        : (selected as Option[]);

      if (
        filterType === Filters.help_with ||
        filterType === Filters.therapist_approach
      ) {
        const lastSelectedElement = difference(
          options?.map((option) => option.label),
          selectedOptions?.map((option) => option.label),
        );
        if (lastSelectedElement.length > 0) {
          amplitudeService.logEvent(AmplitudeEvents.FILTER_ADDED, {
            type: filterType,
            value: lastSelectedElement[0],
          });
        }
      }

      input.onChange(options?.map((option) => option.value));
    },
    [input, maxSelectedItems],
  );

  const MultiValueRemoveItem = useCallback(
    ({ innerProps, data }) => {
      return !notRemovableValues.includes(data.value) ? (
        <div {...innerProps}>
          <CrossIconStylized />
        </div>
      ) : null;
    },
    [notRemovableValues],
  );

  return (
    <Wrapper as={label ? 'label' : 'div'} $CSS={rootCSS}>
      {label && <Text $disabled={disabled}>{label}</Text>}

      <Select
        isMulti
        components={{
          MultiValueRemove: MultiValueRemoveItem,
          DropdownIndicator: () => <ArrowDownIcon stroke="var(--black3)" />,
          ClearIndicator: () => null,
          ...customComponents,
        }}
        value={selectedOptions}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        noOptionsMessage={noOptionsMessage}
        styles={customSelectStyles || customStyles}
        isSearchable={isSearchable}
        isDisabled={disabled}
        isError={hasError}
        isOpen={isOpen}
        formatOptionLabel={CustomOptionLabel}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
      />

      {hasError && <ErrorText>{error || submitError || data?.error}</ErrorText>}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp }>`
  display: block;

  ${({ $CSS }) => $CSS}
`;

const CrossIconStylized = styled(CrossIcon)`
  width: ${getSize(12)};
  height: ${getSize(12)};
  stroke: var(--gray7);
`;

const Text = styled.span<{ $disabled?: boolean }>`
  display: block;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray7);
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default MultiSelect;
