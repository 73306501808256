import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function DoubleArrowIcon({ ...rest }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      fill="#E1DCFA"
      viewBox="0 0 18 18"
      {...rest}
    >
      <path
        fillRule="evenodd"
        d="M13.78 3.97a.75.75 0 010 1.06L9.81 9l3.97 3.97a.75.75 0 11-1.06 1.06l-4.5-4.5a.75.75 0 010-1.06l4.5-4.5a.75.75 0 011.06 0z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.78 3.97a.75.75 0 010 1.06L5.81 9l3.97 3.97a.75.75 0 11-1.06 1.06l-4.5-4.5a.75.75 0 010-1.06l4.5-4.5a.75.75 0 011.06 0z"
        clipRule="evenodd"
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(18)};
  height: ${getSize(18)};
`;

export default DoubleArrowIcon;
