import { getSize } from 'lib/utils';

const THEMES: any = {
  primary: ($isChecked: boolean) => `
    padding: ${getSize(8)} ${getSize(17)};
    font-weight: 400;
    font-size: ${getSize(14)};
    line-height: ${getSize(24)};
    color: var(${$isChecked ? '--white' : '--black3'});
    border: ${getSize(1)} solid
      var(${$isChecked ? '--purple' : '--purple3'});
    border-radius: ${getSize(10)};
    ${$isChecked ? 'background: var(--purple);' : ''}
    transition: 0.2s ease-in-out;

    ${
      !$isChecked
        ? `
      &:hover {
        border-color: var(--purple);
        color: var(--purple);
      }
    `
        : ''
    }
  `,
  secondary: ($isChecked: boolean) => `
    padding: ${getSize(9)} ${getSize(14)};
    font-weight: 400;
    font-size: ${getSize(12)};
    line-height: ${getSize(18)};
    color: var(${$isChecked ? '--black3' : '--gray2'});
    border: ${getSize(1)} solid var(--purple3);
    border-radius: ${getSize(10)};
    ${$isChecked ? 'background: var(--purple3);' : ''}
    transition: 0.2s ease-in-out;

    ${
      !$isChecked
        ? `
      &:hover {
        background-color: var(--purple3);
        color: var(--black3);
      }
    `
        : ''
    }
  `,
};

export default THEMES;
