import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { BaseStyleProps } from 'types';
import { AlertIcon } from 'ui';

const AttentionBlock: FC<AttentionBlockProps> = ({ text, className }) => {
  return (
    <AlertContainer className={className}>
      <AlertIconStylized />

      <AlertText>{text}</AlertText>
    </AlertContainer>
  );
};

interface AttentionBlockProps extends BaseStyleProps {
  text: string;
}

const AlertIconStylized = styled(AlertIcon)`
  width: ${getSize(16)};
  height: ${getSize(16)};
  margin-right: ${getSize(6)};
  flex-shrink: 0;
  align-self: center;
`;

const AlertText = styled.p`
  font-size: ${getSize(12)};
  font-weight: 400;
  line-height: ${getSize(24)};
  color: var(--purple);
`;

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default AttentionBlock;
