import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { ShadowWrapper } from 'ui/shadow-wrapper';

export interface LinkItem {
  href: string;
  text: string;
}
export interface NavigationProps {
  links: LinkItem[];
  className?: string;
  listCSS?: CSSProp;
  isPatient?: boolean;
}

function Navigation({ links, className, listCSS, isPatient }: NavigationProps) {
  return (
    <Wrapper className={className} $isPatient={isPatient}>
      <List $CSS={listCSS}>
        {links.map(({ href, text }) => (
          <li key={href}>
            <Link to={href} $isPatient={isPatient}>
              {text}
            </Link>
          </li>
        ))}
      </List>
    </Wrapper>
  );
}

const Wrapper = styled(ShadowWrapper)<{ $isPatient?: boolean }>`
  padding: ${({ $isPatient }) => ($isPatient ? getSize(24) : getSize(29))} 0;
`;

const List = styled.ul<{ $CSS?: CSSProp }>`
  display: flex;
  flex-direction: column;

  ${({ $CSS }) => $CSS}
`;

const Link = styled(NavLink)<{ $isPatient?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: ${({ $isPatient }) => ($isPatient ? getSize(18) : getSize(12))};
  line-height: ${({ $isPatient }) => ($isPatient ? getSize(32) : getSize(24))};
  padding: ${getSize(8)} ${getSize(34)};
  transition: 0.3s ease-out;
  color: var(--gray7);

  &:hover {
    color: var(--purple);
  }

  &.active {
    background-color: var(--purple11);
    color: var(--black3);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      height: 100%;
      width: ${getSize(5)};
      border-radius: ${getSize(5)} 0 0 ${getSize(5)};
      background-color: var(--purple);
    }
  }
`;

export default Navigation;
