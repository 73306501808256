import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Navigation } from './components';
import { THERAPIST_SETTINGS } from './utils/therapist-links';
import { PATIENT_SETTINGS } from './utils/patients-links';
import { getSize } from 'lib/utils';
interface AccountSettingsWrapperProps {
  className?: string;
  children?: ReactNode;
  isPatient?: boolean;
}

const AccountSettingsWrapper: FC<AccountSettingsWrapperProps> = ({
  className,
  children,
  isPatient,
}) => {
  return (
    <Wrapper className={className} $isPatient={isPatient}>
      <NavWrapper $isPatient={isPatient}>
        <Navigation
          links={isPatient ? PATIENT_SETTINGS : THERAPIST_SETTINGS}
          isPatient={isPatient}
        />
      </NavWrapper>
      <Inner>
        {children}
        <Padding /> {/* Pseudo padding for Safari browser */}
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isPatient?: boolean }>`
  display: flex;
  padding: ${({ $isPatient }) =>
    $isPatient
      ? `${getSize(45)} ${getSize(112)}`
      : `${getSize(45)} ${getSize(50)}`};
`;

const NavWrapper = styled.div<{ $isPatient?: boolean }>`
  margin-right: ${($isPatient) => getSize($isPatient ? 49 : 66)};
  width: 100%;
  width: ${({ $isPatient }) => getSize($isPatient ? 420 : 276)};
`;

const Inner = styled.div`
  max-width: ${getSize(747)};
`;

const Padding = styled.div`
  height: ${getSize(45)};
`;

export default AccountSettingsWrapper;
