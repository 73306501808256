import { getSize } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';

function CheckSuccessIcon({ ...rest }) {
  return (
    <Icon viewBox="0 0 131 131" fill="none" {...rest}>
      <path
        d="M65.5 125.926c33.372 0 60.426-27.053 60.426-60.426 0-33.373-27.054-60.426-60.427-60.426-33.372 0-60.426 27.053-60.426 60.426 0 33.373 27.054 60.426 60.426 60.426z"
        fill="#E1DCFA"
      />
      <path
        d="M65.5 131C29.386 131 0 101.619 0 65.5 0 29.386 29.386 0 65.5 0 101.619 0 131 29.386 131 65.5c0 36.119-29.381 65.5-65.5 65.5zm0-120.853c-30.523 0-55.353 24.83-55.353 55.353 0 30.523 24.83 55.353 55.353 55.353 30.523 0 55.353-24.835 55.353-55.353 0-30.523-24.83-55.353-55.353-55.353z"
        fill="#E1DCFA"
      />
      <path
        d="M57.586 89.6a5.068 5.068 0 01-3.618-1.517L35.247 69.057a5.073 5.073 0 117.235-7.113l14.957 15.2 30.943-34.08a5.076 5.076 0 017.164-.344 5.076 5.076 0 01.345 7.169L61.341 87.94a5.074 5.074 0 01-3.654 1.665c-.035-.005-.066-.005-.101-.005z"
        fill="#6B4EE6"
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(102)};
  height: ${getSize(102)};
`;

export default CheckSuccessIcon;
