import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { AUTH_ROUTES, PATIENT_B2B_ROUTES, THERAPIST_ROUTES } from 'lib/routes';
import { DecodeTokenService, getSize, StorageService } from 'lib/utils';
import { Role } from '__generated__/types';

import { Button, RoundCheckIcon } from 'ui';
import { TERMS_AND_CONDITIONAL_FOR_PRACTITIONERS } from 'lib/constants/common';
import { AuthContentLayout } from 'layouts';
import { useUpdateCompanyPlanToActive } from './mutation/__generated__/admin-update-company-to-active';
import { useSearchParams } from '../../../hooks';

function SubscriptionSuccess() {
  const history = useHistory();

  const handleClick = () => {
    history.push(AUTH_ROUTES.SIGN_UP_B2B_USER);
  };

  const [
    companyPlanToActive,
    { data: sessionId, loading: updateCompanyLoading },
  ] = useUpdateCompanyPlanToActive();

  const search = window.location.search;
  const params = new URLSearchParams(search);

  useMemo(() => {
    const updated = async () => {
      await companyPlanToActive({
        variables: {
          sessionId: params.get('session_id') || '',
        },
      });
    };
    updated().catch(console.error);
  }, []);

  return (
    <AuthContentLayout>
      <Title>Subscription Success</Title>
      <MainText>
        {`Your company has successfully activated monthly subscription!`}
      </MainText>

      {/* <RegistrationButton theme="tertiary" onClick={handleClick}>
        CONTINUE
      </RegistrationButton>

      <LastText>
        By proceeding to the registration form, you are agreeing to our{' '}
        <MailLink
          title="Privacy Policy "
          href={TERMS_AND_CONDITIONAL_FOR_PRACTITIONERS}>
          Terms
        </MailLink>
        {' & '}
        <MailLink
          title="Privacy Policy "
          href={TERMS_AND_CONDITIONAL_FOR_PRACTITIONERS}>
          Conditions for Therapists.
        </MailLink>
      </LastText> */}
    </AuthContentLayout>
  );
}

const Title = styled.p`
  font-weight: 600;
  font-size: ${getSize(32)};
  line-height: ${getSize(37)};
  color: var(--black4);
  text-align: center;
`;

const MainText = styled.p`
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: var(--gray13);
  text-align: center;
  margin: ${getSize(10)} 0 ${getSize(17)};
`;

const MailLink = styled.a`
  color: var(--gray2);
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
`;

const LastText = styled.p`
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  color: var(--gray2);
  font-size: ${getSize(10)};
  line-height: ${getSize(18)};
  max-width: ${getSize(330)};
`;

const RegistrationButton = styled(Button)`
  width: 100%;
  margin-bottom: ${getSize(20)};
`;

export default SubscriptionSuccess;
