import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { PATIENT_ROUTES, ROOT_ROUTES, THERAPIST_ROUTES } from 'lib/routes';
import { getSize } from 'lib/utils';
import { useUser } from 'common/query/__generated__/get-user';
import { Role } from '__generated__/types';
import { DEFAULT_AVATAR } from 'lib/constants/therapist';

import { ArrowDownIcon } from 'ui';
import { DropdownMenu } from './components';

export interface UserProfileDropdownProps {
  className?: string;
}

function UserProfileDropdown({ className }: UserProfileDropdownProps) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { data: user } = useUser();

  const userRole = user?.me?.role;

  const settingsRoute = useMemo(() => {
    switch (userRole) {
      case Role.Patient:
        return PATIENT_ROUTES.ACCOUNT_SETTINGS;
      case Role.Therapist:
        return THERAPIST_ROUTES.ACCOUNT_SETTINGS;
      default:
        return ROOT_ROUTES.HOME;
    }
  }, [user?.me?.role]);

  return (
    <Wrapper className={className}>
      <InfoWrapper
        onClick={() => setIsDropdownVisible((prevValue) => !prevValue)}
      >
        <UserName className="dropdown-user-name">
          {user?.me?.firstName} {user?.me?.lastName}
        </UserName>
        <Icon $isActive={isDropdownVisible} />
        <Image src={user?.me?.avatar?.source || DEFAULT_AVATAR} />
      </InfoWrapper>

      {isDropdownVisible && (
        <DropdownMenu
          userRole={userRole}
          settingsRoute={settingsRoute}
          onCloseDropdown={() => setIsDropdownVisible(false)}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  transition: 0.2s ease-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &:hover {
    .dropdown-user-name {
      color: var(--purple);
    }
  }
`;

const UserName = styled.p`
  margin: 0 ${getSize(8)} 0 0;
  white-space: nowrap;
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: var(--gray25);
`;

const Image = styled.img`
  width: ${getSize(34)};
  height: ${getSize(34)};
  object-fit: cover;
  border-radius: 50%;
`;

const Icon = styled(ArrowDownIcon)<{ $isActive: boolean }>`
  stroke: ${({ $isActive }) => ($isActive ? 'var(--purple)' : '#BDBDBD')};
  margin-right: ${getSize(10)};
  width: ${getSize(16)};
  height: ${getSize(16)};
`;

export default UserProfileDropdown;
