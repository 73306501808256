import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowDownIcon } from 'ui/icons';

interface BackButtonProps {
  title?: string;
  className?: string;
}

const BackButton: FC<BackButtonProps> = ({ title, className }) => {
  const history = useHistory();
  return (
    <Wrapper onClick={() => history.goBack()} className={className}>
      <Icon />
      {title || 'Back'}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--gray2);
  transition: color 0.2s ease-in;

  & > svg {
    transition: stroke 0.2s ease-in;
  }

  &:hover {
    color: var(--purple);

    & > svg {
      stroke: var(--purple);
    }
  }
`;

const Icon = styled(ArrowDownIcon)`
  transform: rotate(90deg);
  margin: 0 ${getSize(8)} 0 0;
  stroke: var(--black3);
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

export default BackButton;
