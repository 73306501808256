import { OfficeLocationFragment } from 'common/query/__generated__/get-therapist-availability';
import { ISO_DATE_FORMAT } from 'lib/constants/date';
import { Option } from 'lib/utils';
import { TimeUtil } from 'lib/utils/time-util';
import { AvailabilitySessionType } from '../../../__generated__/types';
import { SettingsNoticePeriod } from '../booking-form';

export interface AvailableDay {
  startDateUtc: string;
  endDateUtc: string;
  timeOptions: { label: string; value: string }[];
  sessionType: AvailabilitySessionType[];
  officeLocation?: OfficeLocationFragment | null;
}

interface GetTimeOfAvailabilityInMonthArgument {
  availableDays: AvailableDay[];
  monthOfYear: string;
  sessionType: AvailabilitySessionType;
  selectedTimezone: string;
  settingsNoticePeriod: SettingsNoticePeriod;
  selectedDuration: number;
}

export function getTimeOptionsOfAvailabilityInMonth({
  availableDays,
  monthOfYear,
  sessionType,
  selectedTimezone,
  settingsNoticePeriod,
  selectedDuration,
}: GetTimeOfAvailabilityInMonthArgument): Option[][] {
  const numberOfDaysInMonth = TimeUtil.getNumberOfDaysInMonth(monthOfYear);

  const daysObject = availableDays.reduce((acc, availabilityOfDay) => {
    const isSelectedSessionType =
      availabilityOfDay.sessionType.includes(sessionType);
    const isAllSessionTypes = availabilityOfDay.sessionType.includes(
      AvailabilitySessionType.All,
    );

    if (isSelectedSessionType || isAllSessionTypes) {
      availabilityOfDay.timeOptions.forEach((timeOption) => {
        const { value: timeOptionValue } = timeOption;

        const dateInTimezone = TimeUtil.parseWithZone(
          timeOptionValue,
          selectedTimezone,
          ISO_DATE_FORMAT,
        );
        const dayOfMonth = dateInTimezone.format('D');
        const currentMonthOfYear = dateInTimezone.format('YYYY-MM');

        let isSessionAvailableDependingOfSettings = true;

        const { minNoticePeriodDate, maxNoticePeriodDate } =
          settingsNoticePeriod;

        const minNoticePeriodDateUtc = minNoticePeriodDate?.toISOString();
        const maxNoticePeriodDateUtc = maxNoticePeriodDate
          ?.subtract(selectedDuration, 'minute')
          .toISOString();
        if (minNoticePeriodDateUtc && maxNoticePeriodDateUtc) {
          isSessionAvailableDependingOfSettings =
            timeOptionValue >= minNoticePeriodDateUtc &&
            timeOptionValue <= maxNoticePeriodDateUtc;
        } else if (minNoticePeriodDateUtc) {
          isSessionAvailableDependingOfSettings =
            timeOptionValue >= minNoticePeriodDateUtc;
        } else if (maxNoticePeriodDateUtc) {
          isSessionAvailableDependingOfSettings =
            timeOptionValue <= maxNoticePeriodDateUtc;
        }

        if (
          currentMonthOfYear === monthOfYear &&
          isSessionAvailableDependingOfSettings
        ) {
          if (acc[dayOfMonth]?.length) {
            acc[dayOfMonth].push(timeOption);
          } else {
            acc[dayOfMonth] = [timeOption];
          }
        }
      });
    }

    return acc;
  }, {} as { [dateNumber: string]: { label: string; value: string }[] });

  return [...Array(numberOfDaysInMonth)].map(
    (_, index) => daysObject[String(index + 1)] || [],
  );
}
