import { getSize } from 'lib/utils';
import React, { FC, ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

interface DashboardTabsButtonProps {
  onClick: () => void;
  isActive: boolean;
  children: ReactNode;
  rootCSS?: CSSProp;
}

const DashboardTabsButton: FC<DashboardTabsButtonProps> = ({
  onClick,
  isActive,
  children,
  rootCSS,
}) => {
  return (
    <TabButton $CSS={rootCSS} $isActive={isActive} onClick={onClick}>
      {children}
    </TabButton>
  );
};

const TabButton = styled.button<{ $CSS?: CSSProp; $isActive?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  font-size: ${getSize(18)};
  line-height: ${getSize(32)};
  padding: ${getSize(6)} ${getSize(16)};

  ${({ $isActive }) =>
    $isActive
      ? `&::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: ${getSize(-1)};
    width: 100%;
    height: ${getSize(3)};
    background-color: var(--purple);
  }
  color: var(--black3);
  font-weight: 600;

  `
      : 'color: var(--gray7);'}

  ${({ $CSS }) => $CSS}
`;

export default DashboardTabsButton;
