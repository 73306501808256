import React from 'react';
import styled from 'styled-components';
import { WeekdayElementProps } from 'react-day-picker';
import { getSize } from 'lib/utils';

function Weekday({
  weekday,
  className,
  localeUtils,
  locale,
}: WeekdayElementProps) {
  const weekName = localeUtils.formatWeekdayLong(weekday, locale);

  return (
    <Wrapper className={className} title={weekName}>
      {weekName.slice(0, 1)}
    </Wrapper>
  );
}

const Wrapper = styled.p`
  padding: 0;
  height: ${getSize(35)};
  font-weight: 400;
  font-size: ${getSize(14)};
  line-height: ${getSize(24)};
  color: var(--black3);

  &[title='Sunday'] {
    color: var(--gray2);
  }
`;

export default Weekday;
