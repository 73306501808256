import React from 'react';
import notesImage from './image/add-note-icon-small.png';
import styled from 'styled-components';

function AddNoteSmallIcon({ ...rest }) {
  return <Image src={notesImage} alt="add notes icon" {...rest} />;
}

const Image = styled.img`
  width: auto;
  height: auto;
  object-fit: cover;
`;

export default AddNoteSmallIcon;
