import { getSize } from 'lib/utils';
import React, { FC, useCallback, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components';

import { Button } from 'ui/button';
import { Modal } from 'ui/modal';
import { RangeInput } from './components';
import Resizer from 'react-image-file-resizer';

const resizeFile = (file: Blob) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      400,
      400,
      'PNG',
      50,
      0,
      (File) => {
        resolve(File);
      },
      'file',
    );
  });
interface EditAvatarModalProps {
  selectedImage?: File;
  isLoadingFile: boolean;
  onUpload: (value: File, onSuccess?: () => void) => void;
  onClose: () => void;
}

const EditAvatarModal: FC<EditAvatarModalProps> = ({
  selectedImage,
  onUpload,
  onClose,
  isLoadingFile,
}) => {
  const [zoom, setZoom] = useState(1);
  const avatarRef = useRef<AvatarEditor>(null);

  const onCloseModal = useCallback(() => {
    setZoom(1);
    onClose();
  }, []);

  const onSave = useCallback(() => {
    if (!avatarRef) return;

    const image = avatarRef.current?.getImage();
    image?.toBlob(async (blob) => {
      const file = blob as any;
      file.lastModifiedDate = new Date();
      file.name = selectedImage?.name;
      const newFile = await resizeFile(file);
      onUpload(newFile as File, onCloseModal);
    });
  }, [onCloseModal, onUpload, selectedImage]);

  if (!selectedImage) return null;

  return (
    <ModalWrapper
      isVisible={Boolean(selectedImage)}
      title="Upload a photo"
      onClose={onCloseModal}
    >
      <EditorWrapper>
        <Editor
          ref={avatarRef}
          image={selectedImage}
          border={0}
          borderRadius={10000000}
          scale={zoom}
        />
      </EditorWrapper>
      <RangeInput
        step={0.1}
        min={1}
        max={10}
        values={[zoom]}
        onChange={(values) => setZoom(values[0])}
      />
      <Button
        isLoading={isLoadingFile}
        type="button"
        theme="primary"
        onClick={onSave}
      >
        Save
      </Button>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(Modal)`
  max-width: 100%;
  width: auto;
`;

const EditorWrapper = styled.div`
  margin-bottom: ${getSize(36)};
  position: relative;
`;

const Editor = styled(AvatarEditor)`
  width: ${getSize(532)} !important;
  height: ${getSize(532)} !important;
`;

export default EditAvatarModal;
