import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';
import { PlusIcon, TrashSecondaryIcon } from 'ui';

interface DeleteMessagesProps {
  onResetSelectedMessagesForDeleting: () => void;
  onDeleteSelectedMessages: () => void;
  selectedMessagesCount: number;
}

const DeleteMessages: FC<DeleteMessagesProps> = ({
  onDeleteSelectedMessages,
  onResetSelectedMessagesForDeleting,
  selectedMessagesCount,
}) => {
  return (
    <MainWrapper>
      <Wrapper>
        <MessagesCountWrapper>
          <MessagesCount>{`${selectedMessagesCount} ${
            selectedMessagesCount === 1 ? 'message' : 'messages'
          }`}</MessagesCount>
          <button onClick={onResetSelectedMessagesForDeleting}>
            <MessagesCountIcon />
          </button>
        </MessagesCountWrapper>
        <button onClick={onDeleteSelectedMessages}>
          <TrashIcon />
        </button>
      </Wrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 ${getSize(8)} 0 ${getSize(34)};
  z-index: 100;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${getSize(14)} ${getSize(20)};
  background-color: var(--pink2);
  border-radius: ${getSize(8)};
`;

const MessagesCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MessagesCount = styled.p`
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  margin-right: ${getSize(8)};
`;

const MessagesCountIcon = styled(PlusIcon)`
  transform: rotate(45deg);
`;

const TrashIcon = styled(TrashSecondaryIcon)`
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

export default DeleteMessages;
