import React from 'react';

function InsuranceConfirmedIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="#79D0BB"
      {...rest}
    >
      <path d="M12.81 22c-5.524 0-10-4.477-10-10s4.476-10 10-10c5.522 0 10 4.477 10 10s-4.478 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-.998-4L7.57 11.756l1.414-1.415 2.83 2.83 5.655-5.657 1.415 1.414-7.07 7.07z" />
    </svg>
  );
}

export default InsuranceConfirmedIcon;
