import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { TeamsPrivateRoute } from '../../components/teams-private-route';

// FEATURES
import { Discover, AllContent } from 'features/patient/resources';
import { ChoseContent } from 'features/patient/resources/chose-content';

// TEST
import { ContentPreview } from 'features/patient/resources/content-preview';

// ROUTES
import { PATIENT_ROUTES, PATIENT_RESOURCES_ROUTES } from 'lib/routes';

const { RESOURCES } = PATIENT_ROUTES;
const { ALL_CONTENT, DISCOVER, CHOSE_CONTENT, CONTENT_PREVIEW } =
  PATIENT_RESOURCES_ROUTES;

const TeamsResourcesRoutes = () => {
  return (
    <Switch>
      <Route exact path={RESOURCES}>
        <Redirect to={DISCOVER.pathBuild()} />
      </Route>
      <TeamsPrivateRoute path={DISCOVER.PATH} component={Discover} />

      {/* Content Preview */}
      <TeamsPrivateRoute
        path={CONTENT_PREVIEW.PATH}
        component={ContentPreview}
      />

      {/* All content routes */}
      <TeamsPrivateRoute
        exact
        path={ALL_CONTENT.PATHS}
        component={AllContent}
      />

      <TeamsPrivateRoute path={CHOSE_CONTENT} component={ChoseContent} />
    </Switch>
  );
};

export default TeamsResourcesRoutes;
