import React from 'react';
import styled from 'styled-components';
import Plant from 'assets/images/plant.png';

const PlantBackground = () => {
  return <Root />;
};

const Root = styled.span`
  background-image: url(${Plant});
  position: fixed;
  background-size: cover;
  width: 225px;
  height: 259px;
  bottom: 0;
  right: 0;
  z-index: 0;
`;

export default PlantBackground;
