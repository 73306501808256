import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { css, CSSProp } from 'styled-components';

import { getSize } from 'lib/utils';

import { HideEyeIcon } from 'ui/icons';
import { StrengthLine } from './components';
import { BaseStyleProps } from '../../../types/common';

export type PasswordInputProps = {
  label?: string;
  rootCSS?: CSSProp;
  inputCSS?: CSSProp;
  placeholder?: string;
  disabled?: boolean;
  checkStrength?: boolean;
} & FieldRenderProps<string> &
  BaseStyleProps;

function PasswordInput({
  input,
  meta,
  label,
  rootCSS,
  inputCSS,
  placeholder,
  disabled,
  checkStrength,
  className,
}: PasswordInputProps) {
  const [isHidePassword, setIsHidePassword] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { value } = input;

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  return (
    <Wrapper as={label ? 'label' : 'div'} $CSS={rootCSS} className={className}>
      {label && <Text>{label}</Text>}

      <Inner>
        <InputStylized
          $CSS={inputCSS}
          $isHidePassword={isHidePassword}
          $hasValue={Boolean(value.length)}
          placeholder={placeholder}
          type={isHidePassword ? 'password' : undefined}
          {...input}
          disabled={disabled}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
        />
        <button
          type="button"
          onClick={() => setIsHidePassword((prevValue) => !prevValue)}
        >
          <EyeIcon fill={`var(${isHidePassword ? '--gray2' : '--purple'})`} />
        </button>
      </Inner>
      <Line $hasError={hasError} $isInputFocused={isInputFocused} />

      {hasError && <ErrorText>{error || submitError || data?.error}</ErrorText>}
      {checkStrength && <StrengthLine value={value} />}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp }>`
  display: block;

  ${({ $CSS }) => $CSS}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.span`
  display: block;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray7);
`;

const InputStylized = styled.input<{
  $CSS?: CSSProp;
  $isHidePassword: boolean;
  $hasValue: boolean;
}>`
  width: 100%;
  padding: ${getSize(10)} 0 ${getSize(8)};
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};

  &::placeholder {
    color: var(--gray2);
  }

  ${({ $isHidePassword, $hasValue }) =>
    $isHidePassword && $hasValue
      ? css`
          font-size: ${getSize(18)};
          color: var(--purple18);
        `
      : css`
          color: var(--black4);
        `}

  ${({ $CSS }) => $CSS}
`;

const EyeIcon = styled(HideEyeIcon)`
  margin: 0 0 0 ${getSize(10)};
  width: ${getSize(24)};
  height: ${getSize(24)};
`;

const Line = styled.div<{ $isInputFocused: boolean; $hasError: boolean }>`
  width: 100%;
  height: ${getSize(1)};
  border-radius: ${getSize(8)};
  background: var(
    ${({ $isInputFocused, $hasError }) => {
      if ($hasError) {
        return '--red';
      } else if ($isInputFocused) {
        return '--purple';
      } else {
        return '--purple3';
      }
    }}
  );
  transition: 0.3s ease-out;
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default PasswordInput;
