import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientCancelAllSessionsVariables = Types.Exact<{
  input: Types.PatientSessionCancelAllInput;
}>;


export type PatientCancelAllSessions = (
  { __typename: 'Mutation' }
  & { patientSessionCancelAll: (
    { __typename: 'SessionCommonPayload' }
    & Pick<Types.SessionCommonPayload, 'success'>
    & { userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const PatientCancelAllSessionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PatientCancelAllSessions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PatientSessionCancelAllInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientSessionCancelAll"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type PatientCancelAllSessionsMutationFn = Apollo.MutationFunction<PatientCancelAllSessions, PatientCancelAllSessionsVariables>;

/**
 * __usePatientCancelAllSessions__
 *
 * To run a mutation, you first call `usePatientCancelAllSessions` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientCancelAllSessions` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientCancelAllSessions, { data, loading, error }] = usePatientCancelAllSessions({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientCancelAllSessions(baseOptions?: Apollo.MutationHookOptions<PatientCancelAllSessions, PatientCancelAllSessionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientCancelAllSessions, PatientCancelAllSessionsVariables>(PatientCancelAllSessionsDocument, options);
      }
export type PatientCancelAllSessionsHookResult = ReturnType<typeof usePatientCancelAllSessions>;
export type PatientCancelAllSessionsMutationResult = Apollo.MutationResult<PatientCancelAllSessions>;
export type PatientCancelAllSessionsMutationOptions = Apollo.BaseMutationOptions<PatientCancelAllSessions, PatientCancelAllSessionsVariables>;