import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { EditIcon } from 'ui';

export interface WriteMessageButtonProps {
  onClick?: () => void;
  isExpanded: boolean;
}

function WriteMessageButton({ onClick, isExpanded }: WriteMessageButtonProps) {
  return (
    <Wrapper $isExpanded={isExpanded} onClick={onClick}>
      <Icon $isExpanded={isExpanded} />
      {!isExpanded && <Text>Write a message</Text>}
    </Wrapper>
  );
}

const Wrapper = styled.button<{ $isExpanded: boolean }>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ $isExpanded }) =>
    $isExpanded
      ? `${getSize(11)} ${getSize(10)} ${getSize(11)} ${getSize(12)}`
      : getSize(11)};
  background: var(--purple3);
  border-radius: ${getSize(8)};
  transition: 0.3s ease-out;
  white-space: nowrap;

  ${({ $isExpanded }) =>
    !$isExpanded &&
    `
  &:hover {
    color: var(--purple);
  }
  `}
`;

const Icon = styled(EditIcon)<{ $isExpanded: boolean }>`
  ${({ $isExpanded }) => !$isExpanded && `margin: 0 ${getSize(8)} 0 0;`}
`;

const Text = styled.p`
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);
  transition: 0.3s ease-out;
`;

export default WriteMessageButton;
