import * as Types from '../../../../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientGetOutcomeMeasureVariables = Types.Exact<{ [key: string]: never; }>;


export type PatientGetOutcomeMeasure = (
  { __typename: 'Query' }
  & { patientGetOutcomeMeasures: Array<(
    { __typename: 'OutcomeMeasureModel' }
    & PatientOutcomeMeasureFragment
  )> }
);

export type PatientOutcomeMeasureFragment = (
  { __typename: 'OutcomeMeasureModel' }
  & Pick<Types.OutcomeMeasureModel, 'id' | 'initial' | 'finished' | 'codeId' | 'therapistName' | 'createdAt'>
);

export const PatientOutcomeMeasureFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PatientOutcomeMeasureFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OutcomeMeasureModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"initial"}},{"kind":"Field","name":{"kind":"Name","value":"finished"}},{"kind":"Field","name":{"kind":"Name","value":"codeId"}},{"kind":"Field","name":{"kind":"Name","value":"therapistName"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]} as unknown as DocumentNode;
export const PatientGetOutcomeMeasureDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PatientGetOutcomeMeasure"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientGetOutcomeMeasures"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PatientOutcomeMeasureFragment"}}]}}]}},...PatientOutcomeMeasureFragment.definitions]} as unknown as DocumentNode;

/**
 * __usePatientGetOutcomeMeasure__
 *
 * To run a query within a React component, call `usePatientGetOutcomeMeasure` and pass it any options that fit your needs.
 * When your component renders, `usePatientGetOutcomeMeasure` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientGetOutcomeMeasure({
 *   variables: {
 *   },
 * });
 */
export function usePatientGetOutcomeMeasure(baseOptions?: Apollo.QueryHookOptions<PatientGetOutcomeMeasure, PatientGetOutcomeMeasureVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientGetOutcomeMeasure, PatientGetOutcomeMeasureVariables>(PatientGetOutcomeMeasureDocument, options);
      }
export function usePatientGetOutcomeMeasureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientGetOutcomeMeasure, PatientGetOutcomeMeasureVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientGetOutcomeMeasure, PatientGetOutcomeMeasureVariables>(PatientGetOutcomeMeasureDocument, options);
        }
export type PatientGetOutcomeMeasureHookResult = ReturnType<typeof usePatientGetOutcomeMeasure>;
export type PatientGetOutcomeMeasureLazyQueryHookResult = ReturnType<typeof usePatientGetOutcomeMeasureLazyQuery>;
export type PatientGetOutcomeMeasureQueryResult = Apollo.QueryResult<PatientGetOutcomeMeasure, PatientGetOutcomeMeasureVariables>;