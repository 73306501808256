import React from 'react';

function SavedIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      {...rest}
    >
      <path
        d="M15.4594 0C15.663 0 15.8578 0.031725 16.0438 0.0951752C16.3359 0.186825 16.5684 0.331351 16.741 0.528751C16.9137 0.726151 17 0.944701 17 1.1844V14.8156C17 15.0553 16.9137 15.2738 16.741 15.4712C16.5684 15.6686 16.3359 15.8132 16.0438 15.9048C15.8755 15.9612 15.6807 15.9894 15.4594 15.9894C15.0344 15.9894 14.6669 15.8766 14.357 15.651L8.5 11.1672L2.64297 15.651C2.32422 15.8837 1.95677 16 1.54063 16C1.33698 16 1.14219 15.9683 0.95625 15.9048C0.664062 15.8132 0.431641 15.6686 0.258984 15.4712C0.0863281 15.2738 0 15.0553 0 14.8156V1.1844C0 0.944701 0.0863281 0.726151 0.258984 0.528751C0.431641 0.331351 0.664062 0.186825 0.95625 0.0951752C1.14219 0.031725 1.33698 0 1.54063 0H15.4594Z"
        fill="#674FDE"
      />
    </svg>
  );
}

export default SavedIcon;
