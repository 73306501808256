import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { AmplitudeEvents, amplitudeService } from 'lib/amplitude';

import { getSize } from 'lib/utils';
import { SectionWithTitle } from 'features/common';
import { Button, Loader } from 'ui';
import { usePatientGetOutcomeMeasure } from './query/__generated__/patient-get-outcome-measure';
import { useHistory } from 'react-router-dom';
import { PATIENT_DASHBOARD_ROUTES } from 'lib/routes';

export interface QuestionnairesSmallProps {
  className?: string;
}

const TITLE_CSS = { margin: `0 0 ${getSize(29)}` };

function QuestionnairesSmall({ className }: QuestionnairesSmallProps) {
  const history = useHistory();

  const { data: outcomeMeasuresData, loading: isLoadingOutcomeMeasures } =
    usePatientGetOutcomeMeasure();

  const newInitialOutcomeMeasures = useMemo(
    () =>
      outcomeMeasuresData?.patientGetOutcomeMeasures.filter(
        ({ initial, finished }) => initial && !finished,
      ) || [],
    [outcomeMeasuresData],
  );

  const resendOutcomeMeasures = useMemo(
    () =>
      outcomeMeasuresData?.patientGetOutcomeMeasures.filter(
        ({ initial, finished }) => !initial && !finished,
      ) || [],
    [outcomeMeasuresData],
  );

  const handleStartOutcomeMeasure = useCallback(() => {
    if (newInitialOutcomeMeasures[0]) {
      const { id: outcomeMeasureId, codeId } = newInitialOutcomeMeasures[0];
      const params = {
        outcomeMeasureId,
        codeId,
      };
      amplitudeService.logEvent(AmplitudeEvents.QUESTIONNARE_STARTED, {
        type: codeId,
      });

      history.push(PATIENT_DASHBOARD_ROUTES.OUTCOME_MEASURES.pathBuild(params));
    } else if (resendOutcomeMeasures[0]) {
      const { id: outcomeMeasureId, codeId } = resendOutcomeMeasures[0];
      const params = {
        outcomeMeasureId,
        codeId,
      };
      amplitudeService.logEvent(AmplitudeEvents.QUESTIONNARE_STARTED, {
        type: codeId,
      });
      history.push(PATIENT_DASHBOARD_ROUTES.OUTCOME_MEASURES.pathBuild(params));
    }
  }, [newInitialOutcomeMeasures, resendOutcomeMeasures, history]);

  const therapistName =
    newInitialOutcomeMeasures[0]?.therapistName ||
    resendOutcomeMeasures[0]?.therapistName;

  if (isLoadingOutcomeMeasures) {
    return (
      <Wrapper title="My questionnaires">
        <Loader size={30} />
      </Wrapper>
    );
  }

  return (
    <Wrapper
      className={className}
      title="My questionnaires"
      titleCSS={TITLE_CSS}
    >
      <ImageWrapper>
        <EmptyImage src="/images/question.svg" />
      </ImageWrapper>

      <EmptyText>
        {therapistName
          ? `${therapistName} has sent you a questionnaire to complete.`
          : 'Useful questionnaires from the therapist will be shown here.'}
      </EmptyText>
      {(newInitialOutcomeMeasures.length !== 0 ||
        resendOutcomeMeasures.length !== 0) && (
        <StartButton
          type="button"
          onClick={handleStartOutcomeMeasure}
          theme="primary"
        >
          Start
        </StartButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(SectionWithTitle)`
  max-height: ${getSize(455)};
  padding-bottom: ${getSize(30)};
`;

const ImageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 ${getSize(16)};
  min-height: ${getSize(209)};
  background: var(--purple11);
  border-radius: ${getSize(8)};
`;

const EmptyImage = styled.img`
  width: ${getSize(108)};
  height: ${getSize(108)};
`;

const EmptyText = styled.p`
  max-width: ${getSize(280)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(22)};
  color: var(--gray2);
`;

const StartButton = styled(Button)`
  margin: ${getSize(12)} 0 0;
  width: 100%;
`;

export default QuestionnairesSmall;
