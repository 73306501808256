/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGetSessionMetaByIdLazyQuery } from 'common/query/__generated__/get-session-meta-by-id';
import { getSize } from 'lib/utils';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  CallIcon,
  MapFullscreenIcon,
  MicrophoneIcon,
  MicrophoneIconMuted,
  VideoAddNoteIcon,
  VideoCameraIcon,
  VideoCameraIconMuted,
} from 'ui';
import { FullScreenIcon } from 'ui/icons/full-screen-icon';
import { SmallScreenIcon } from 'ui/icons/small-screen-icon';
import { VideoSize } from 'ui/video-window/video-window';

interface VideoSessionButtonsProps {
  isSmallScreen?: boolean;
  isCallStarted?: boolean;
  onToggleStartVideoChat: () => void;
  toggleMicrophone: () => void;
  toggleCamera: () => void;
  isMicMuted: boolean;
  isCameraMuted: boolean;
  setVideoSizeButtonsVisible: (v: boolean) => void;
  isVideoSizeButtonsVisible: boolean;
  videoSize: VideoSize;
  setVideoSize: (v: VideoSize) => void;
  setIsSmallScreen: (v: boolean) => void;
  isVisible: boolean;
  isLobbyShow?: boolean;
  isPatient?: boolean;
  handleCloseClick(): void;
  openNote(): void;
  closeNote(): void;
  isNoteShow: boolean;
  isBothUserConnected?: boolean;
  isLostConnection?: boolean;
  patientName: string;
  sessionStartTime: string;
}

const VideoSessionButtons: FC<VideoSessionButtonsProps> = ({
  isSmallScreen,
  isCallStarted,
  onToggleStartVideoChat,
  toggleMicrophone,
  toggleCamera,
  isMicMuted,
  isCameraMuted,
  setVideoSizeButtonsVisible,
  isVideoSizeButtonsVisible,
  videoSize,
  setVideoSize,
  setIsSmallScreen,
  isVisible,
  isLobbyShow,
  isPatient,
  handleCloseClick,
  openNote,
  closeNote,
  isNoteShow,
  isBothUserConnected,
  isLostConnection,
  patientName,
  sessionStartTime,
}) => {
  const elem = document.documentElement;

  useEffect(() => {
    return () => {
      setVideoSize(VideoSize.middle);
      closeFullscreen();
    };
  }, [isVisible]);

  function closeFullscreen() {
    if (document.exitFullscreen && document.fullscreenElement) {
      document.exitFullscreen();
    } else if ((document as any)?.webkitExitFullscreen) {
      /* Safari */
      (document as any)?.webkitExitFullscreen();
    } else if ((document as any)?.msExitFullscreen) {
      /* IE11 */
      (document as any)?.msExitFullscreen();
    }
  }

  function setFullScreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((elem as any)?.webkitRequestFullscreen) {
      /* Safari */
      (elem as any)?.webkitRequestFullscreen();
    } else if ((elem as any)?.msRequestFullscreen) {
      /* IE11 */
      (elem as any)?.msRequestFullscreen();
    }
  }

  const setVideoSizeIcon = (type: VideoSize, size?: number) => {
    switch (type) {
      case VideoSize.small:
        return <SmallScreenIcon width={size} height={size} />;
      case VideoSize.large:
        return <FullScreenIcon width={size} height={size} />;
      default:
        return (
          <MapFullscreenIcon width={size} height={size} fill="var(--white)" />
        );
    }
  };
  const onEscapePress = () => {
    if (!document.fullscreenElement) {
      setVideoSize(VideoSize.middle);
    }
  };
  useEffect(() => {
    if (videoSize === VideoSize.large) {
      document.addEventListener('fullscreenchange', onEscapePress, false);
    }
    return () => {
      document.removeEventListener('fullscreenchange', onEscapePress, false);
    };
  }, [videoSize]);

  const handleVideoSize = (value: VideoSize) => {
    setVideoSizeButtonsVisible(false);
    if (value === VideoSize.large) {
      setVideoSize(value);
      setIsSmallScreen(false);
      setFullScreen();
    }
    if (value === VideoSize.middle) {
      setIsSmallScreen(false);
      videoSize === VideoSize.large && closeFullscreen();
      setVideoSize(value);
    }
    if (value === VideoSize.small) {
      setIsSmallScreen(true);
      videoSize === VideoSize.large && closeFullscreen();
      setVideoSize(value);
    }
  };

  const onNoteButtonClick = () => {
    isNoteShow ? closeNote() : openNote();
  };

  const onSizeButtonsClick = () => {
    closeNote();
    handleVideoSize(
      videoSize === VideoSize.small ? VideoSize.middle : VideoSize.small,
    );
  };

  const onOpenNoteButtonClick = () => {
    handleVideoSize(VideoSize.middle);
    onNoteButtonClick();
  };

  return (
    <Wrapper>
      {!isLobbyShow && isSmallScreen && (
        <StyledSizeButtons className="video-call__buttons">
          <VideoButton onClick={onSizeButtonsClick}>
            {setVideoSizeIcon(videoSize, 18)}
          </VideoButton>
        </StyledSizeButtons>
      )}
      <ButtonsWrapper
        className="video-call__buttons"
        $isSmallScreen={isSmallScreen}
      >
        {!isLobbyShow && (
          <OpenNoteButton
            onClick={onOpenNoteButtonClick}
            $isActive={isNoteShow}
            $isSmallScreen={isSmallScreen}
          >
            <VideoAddNoteIcon />
          </OpenNoteButton>
        )}
        {!isLobbyShow && !isSmallScreen && (
          <SizeButtons>
            <VideoButton onClick={onSizeButtonsClick}>
              {setVideoSizeIcon(videoSize, 18)}
            </VideoButton>
          </SizeButtons>
        )}

        {!isSmallScreen && (
          <>
            <VideoButton onClick={toggleCamera}>
              {isCameraMuted ? (
                <VideoCameraIconMuted className="video-button_icon" />
              ) : (
                <VideoCameraIcon className="video-button_icon" />
              )}
            </VideoButton>

            <VideoButton onClick={toggleMicrophone}>
              {isMicMuted ? (
                <MicrophoneIconMuted className="video-button_icon" />
              ) : (
                <MicrophoneIcon className="video-button_icon" />
              )}
            </VideoButton>
          </>
        )}

        {(isCallStarted || isSmallScreen || !isPatient) && !isLobbyShow && (
          <VideoSessionStart
            $isCallStarted={isCallStarted}
            onClick={handleCloseClick}
          >
            <CallIcon className="video-button_icon" />
          </VideoSessionStart>
        )}
      </ButtonsWrapper>

      {isLostConnection && isPatient && (
        <JoinBlock>
          <JoinText>
            something went wrong <br />
            please try again{' '}
          </JoinText>
          <ErrorButton theme="primary" onClick={handleCloseClick}>
            End Call
          </ErrorButton>
        </JoinBlock>
      )}

      {!isCallStarted && !isSmallScreen && !isPatient && (
        <JoinBlock>
          <JoinIndicator
            $isActive={!!isBothUserConnected}
            $isError={!!isLostConnection}
          />
          {isBothUserConnected ? (
            <JoinText>
              <AccentText>{patientName}</AccentText> <br />
              is in waiting room
            </JoinText>
          ) : (
            <>
              {isLostConnection ? (
                <>
                  <JoinText>
                    something went wrong <br />
                    please try again{' '}
                  </JoinText>
                </>
              ) : (
                <JoinText>
                  <AccentText>{sessionStartTime?.toUpperCase()}</AccentText>{' '}
                  session with <br />
                  <AccentText>{patientName}</AccentText> will start soon
                </JoinText>
              )}
            </>
          )}

          {isLostConnection ? (
            <ErrorButton theme="primary" onClick={handleCloseClick}>
              End Call
            </ErrorButton>
          ) : (
            <JoinButton
              theme="primary"
              onClick={onToggleStartVideoChat}
              isDisabled={!isBothUserConnected}
            >
              Admit Client
            </JoinButton>
          )}
        </JoinBlock>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div``;

const ButtonsWrapper = styled.div<{ $isSmallScreen?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: ${({ $isSmallScreen }) =>
    $isSmallScreen ? 'space-between' : 'center'};
  grid-gap: 24px;
  opacity: 0;
  transition: 0.4s ease-out;
  padding: 0 ${getSize(15)};
  will-change: opacity;
  align-items: flex-end;
  z-index: 99;
`;

const VideoButton = styled.button<{ $isCallStarted?: boolean }>`
  /* width: ${getSize(48)};
  height: ${getSize(48)}; */
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: rgba(68, 71, 82, 0.68);
  backdrop-filter: blur(${getSize(16)});
  z-index: 20000;
  transition: all 0.3s ease-out;

  .video-button_icon {
    /* stroke: var(--white);
    fill: var(--white); */
    /* width: ${getSize(25)};
    height: ${getSize(25)}; */
    transition: all 0.3s ease-out;
  }

  &:hover {
    opacity: 0.75;
  }
`;

interface OpenNoteButtonProps {
  $isActive: boolean;
  $isSmallScreen?: boolean;
}

const OpenNoteButton = styled(VideoButton)<OpenNoteButtonProps>`
  position: ${({ $isSmallScreen }) => ($isSmallScreen ? 'static' : 'absolute')};
  left: ${getSize(30)};
  background: ${({ $isActive }) =>
    $isActive ? '#6B4EE6' : 'rgba(68, 71, 82, 0.68)'};
`;

const VideoExpandedButton = styled(VideoButton)`
  /* width: ${getSize(40)};
  height: ${getSize(40)}; */
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
`;

const VideoSessionStart = styled(VideoButton)`
  margin: 0;
  width: 68px;
  height: 48px;
  border-radius: 25px;
  background: var(--red);

  /* background: var(
    ${({ $isCallStarted }) => ($isCallStarted ? '--red' : '--green')}
  );

  .video-button_icon {
    /* width: ${getSize(25)};
    height: ${getSize(25)}; */
    /* ${({ $isCallStarted }) =>
      !$isCallStarted && 'transform: rotate(-130deg)'}; */
  }
   */
`;

const JoinBlock = styled.div`
  margin: ${getSize(16)};
  padding: 50px 27px 30px 27px;
  background: var(--white);
  position: absolute;
  top: 0;
  right: 0;
  min-width: ${getSize(387)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: ${getSize(8)};
  z-index: 999;
`;

interface JoinIndicatorProps {
  $isActive: boolean;
  $isError: boolean;
}

const JoinIndicator = styled.div<JoinIndicatorProps>`
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid
    ${({ $isActive, $isError }) =>
      $isActive ? `#38E334` : $isError ? `#ED6C6C` : `#d9dc32`};
  transition: all 1s;

  &::after {
    content: '';
    position: absolute;
    width: 21px;
    height: 21px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ $isActive, $isError }) =>
      $isActive ? `#38E334` : $isError ? `#ED6C6C` : `#d9dc32`};
    border-radius: 50%;
  }
`;

const JoinText = styled.p`
  font-weight: 600;
  font-size: ${getSize(15)};
  line-height: ${getSize(22)};
  text-align: center;
  color: var(--black3);
  margin-top: ${getSize(12)};
  margin-bottom: ${getSize(35)};
`;

const AccentText = styled.span`
  color: #6c4fe7;
`;

const JoinButton = styled(Button)`
  width: 100%;
  border-radius: 35px;
`;

const ErrorButton = styled(JoinButton)`
  width: 100%;
  border-radius: 35px;
  background: #ed6c6c;

  &:hover {
    background: #ed6c6c;
    opacity: 0.7;
  }
`;

const SizeButtons = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: auto;
`;

const StyledSizeButtons = styled(SizeButtons)`
  position: absolute;
  top: 29px;
  right: 15px;
  opacity: 0;
  transition: all 0.3s;
  transform: translateY(-24px);
`;

export default VideoSessionButtons;
