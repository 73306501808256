import React from 'react';

function PlayIcon({ fill = 'white', ...rest }) {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M17 9.5L-8.30517e-07 19L0 -2.38451e-08L17 9.5Z" fill={fill} />
    </svg>
  );
}

export default PlayIcon;
