import React, { useMemo, useState } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import styled, { css } from 'styled-components';
import ImageMap from './../../assets/images/Map.png';

import { config, getSize } from 'lib/utils';

import { Loader } from 'ui';
import { OfficeLocationsInformation } from 'features/find-specialist/specialist-details/query/__generated__/get-therapist-details-query';
import { MapPinIcon, MapFullscreenIcon } from 'ui/icons';
import { Markers, ToggleAddresses } from './components';
import { getHiddenAddress } from './utils/get-hidden-address';
import { LocationMapModal } from 'ui/location-map-modal';

export interface MapProps {
  className?: string;
  locations: OfficeLocationsInformation[];
  isSession?: boolean;
  isDetailedMap?: boolean;
}

const CONTAINER_STYLE = {
  width: '100%',
  height: '100%',
};

function LocationsMap({
  className,
  locations,
  isSession,
  isDetailedMap = false,
}: MapProps) {
  const [isVisibleFullscreen, setIsVisibleFullscreen] = useState(false);
  const [currentAddressIndex, setCurrentAddressIndex] = useState(0);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.GOOGLE_API_KEY,
  });

  const defaultMapOptions = {
    fullscreenControl: false,
    draggable: false,
    draggableCursor: 'arrow',
  };

  const centerCoordinates = useMemo(
    () =>
      locations[currentAddressIndex]
        ? {
            lat: locations[currentAddressIndex].lat,
            lng: locations[currentAddressIndex].lng,
          }
        : undefined,
    [locations, currentAddressIndex],
  );

  const address = useMemo(() => {
    if (isDetailedMap) {
      return locations.map((location) => {
        const { fullAddress, hideFullAddress, postcode } = location;
        const district = getHiddenAddress(fullAddress);

        return hideFullAddress && !isSession
          ? `${district} ${postcode.split(' ')[0]}`
          : fullAddress;
      });
    }
    if (locations[currentAddressIndex]) {
      const { fullAddress, hideFullAddress, postcode } =
        locations[currentAddressIndex];
      const district = getHiddenAddress(fullAddress);

      return hideFullAddress && !isSession
        ? `${district} ${postcode.split(' ')[0]}`
        : fullAddress;
    }
  }, [locations, currentAddressIndex, isSession]);

  if (loadError) {
    return <ErrorText>Error loading maps</ErrorText>;
  }
  return (
    <Wrapper className={className} $isDetailedMap={isDetailedMap}>
      {isLoaded ? (
        <>
          <FakeMap src={ImageMap} />
          {!isDetailedMap && (
            <Markers
              currentAddressIndex={currentAddressIndex}
              locations={locations}
              isSession={isSession}
            />
          )}
          <FullScreenBtn
            $isDetailedMap={isDetailedMap}
            type="button"
            className="map-pin-button"
            onClick={() => setIsVisibleFullscreen(true)}
          >
            {isDetailedMap ? <MapPinIcon /> : <MapFullscreenIcon />}
          </FullScreenBtn>
          {locations.length > 1 && !isDetailedMap && (
            <ToggleAddresses
              currentAddressIndex={currentAddressIndex}
              setCurrentAddressIndex={setCurrentAddressIndex}
              addressesCount={locations.length - 1}
            />
          )}
          <LocationMapModal
            isVisible={isVisibleFullscreen}
            onClose={() => setIsVisibleFullscreen(false)}
            addressTitle={address}
            currentAddressIndex={currentAddressIndex}
            setCurrentAddressIndex={setCurrentAddressIndex}
            addressesCount={locations.length}
            locations={locations}
            isSession={isSession}
            isDetailedMap={isDetailedMap}
          />
        </>
      ) : (
        <Loader hasFillWholeBlock />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $isDetailedMap?: boolean }>`
  position: relative;
  display: flex;
  border-radius: ${getSize(8)};
  overflow: hidden;

  .gm-style-pbc {
    z-index: 5 !important;
  }

  ${({ $isDetailedMap }) =>
    $isDetailedMap &&
    css`
      transition: 0.3s ease-out;

      border: 1px solid var(--gray18);
      align-items: center;
      justify-content: center;
      &:hover {
        border-color: var(--purple);

        .map-pin-button {
          background-color: var(--purple27);
          & svg {
            & path:last-child {
              fill: var(--purple27);
            }
          }
        }
      }
    `}
`;

const FakeMap = styled.img`
  width: 90px;
  height: 69px;
`;

const FullScreenBtn = styled.button<{ $isDetailedMap?: boolean }>`
  position: absolute;
  top: ${getSize(10)};
  right: ${getSize(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${getSize(38)};
  height: ${getSize(38)};
  border-radius: ${getSize(8)};
  box-shadow: 0px ${getSize(8)} ${getSize(30)} var(--purple-opacity2);
  background-color: var(--white);

  ${({ $isDetailedMap }) =>
    $isDetailedMap &&
    css`
      transition: 0.3s ease-out;
      top: auto;
      right: auto;
      width: ${getSize(39)};
      height: ${getSize(39)};
      border-radius: ${getSize(50)};
      background-color: var(--purple);
      box-shadow: 0px ${getSize(4)} ${getSize(4)} rgba(0, 0, 0, 0.25);

      & svg {
        width: ${getSize(23)};
        height: ${getSize(23)};

        & path:first-child {
          fill: var(--white);
        }
        & path:last-child {
          fill: var(--purple);
        }
      }
    `}
`;

const ErrorText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--red);
`;

export default LocationsMap;
