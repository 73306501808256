import React from 'react';

function EmptyFilesIcon({ ...rest }) {
  return (
    <svg
      width="200"
      height="149"
      viewBox="0 0 200 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M159.308 59.0632C159.308 84.9115 142.64 106.88 119.426 114.888C110.082 118.093 100.085 118.936 90.3329 117.342C89.4074 117.191 88.4884 117.019 87.576 116.823C86.3399 116.562 85.1182 116.261 83.9112 115.921C58.9881 108.933 40.7208 86.1233 40.7208 59.0632C40.7208 26.4433 67.2674 0 100.015 0C132.762 0 159.308 26.4433 159.308 59.0632Z"
        fill="#E6E6E6"
      />
      <path
        d="M62.8768 77.2928C62.7724 77.3484 62.6945 77.443 62.6601 77.5559C62.6256 77.6687 62.6376 77.7905 62.6932 77.8946L84.9861 119.487C85.042 119.591 85.137 119.669 85.2502 119.703C85.3635 119.737 85.4858 119.726 85.5903 119.67L117.103 102.911C117.208 102.855 117.286 102.76 117.32 102.648C117.355 102.535 117.343 102.413 117.287 102.309L94.9942 60.7162C94.9383 60.6123 94.8433 60.5346 94.7301 60.5003C94.6168 60.466 94.4945 60.4779 94.39 60.5333L62.8768 77.2928Z"
        fill="#3F3D56"
      />
      <path
        d="M65.8061 78.5081L86.2064 116.569L114.174 101.695L93.774 63.6341L65.8061 78.5081ZM64.0339 79.4505L64.0378 79.4486L64.0334 79.4507L64.0339 79.4505Z"
        fill="white"
      />
      <path
        d="M72.8579 82.6926C72.4234 82.9237 72.3309 83.5982 72.6513 84.1961C72.9718 84.7939 73.5859 85.0927 74.0204 84.8616L94.504 73.968C94.9385 73.7369 95.031 73.0624 94.7105 72.4645C94.3901 71.8667 93.7759 71.5679 93.3414 71.799L72.8579 82.6926Z"
        fill="#6C63FF"
      />
      <path
        d="M75.3815 87.4011C74.947 87.6322 74.8545 88.3068 75.175 88.9046C75.4955 89.5025 76.1096 89.8012 76.5441 89.5701L97.0277 78.6766C97.4621 78.4455 97.5546 77.7709 97.2342 77.1731C96.9137 76.5752 96.2996 76.2765 95.8651 76.5076L75.3815 87.4011Z"
        fill="#6C63FF"
      />
      <path
        d="M77.9052 92.1098C77.4707 92.3409 77.3782 93.0154 77.6987 93.6133C78.0191 94.2111 78.6333 94.5099 79.0678 94.2788L99.5513 83.3852C99.9858 83.1541 100.078 82.4795 99.7579 81.8817C99.4374 81.2838 98.8233 80.9851 98.3888 81.2162L77.9052 92.1098Z"
        fill="#6C63FF"
      />
      <path
        d="M80.4289 96.8183C79.9944 97.0494 79.9019 97.724 80.2224 98.3218C80.5428 98.9196 81.157 99.2184 81.5915 98.9873L102.075 88.0937C102.51 87.8626 102.602 87.1881 102.282 86.5902C101.961 85.9924 101.347 85.6937 100.912 85.9247L80.4289 96.8183Z"
        fill="#6C63FF"
      />
      <path
        d="M82.9527 101.527C82.5182 101.758 82.4257 102.433 82.7462 103.03C83.0666 103.628 83.6808 103.927 84.1153 103.696L104.599 92.8023C105.033 92.5713 105.126 91.8967 104.805 91.2989C104.485 90.701 103.871 90.4023 103.436 90.6333L82.9527 101.527Z"
        fill="#6C63FF"
      />
      <path
        d="M86.9304 59.3232L105.491 60.4521L110.689 45.0112L94.4093 41.5848L86.9304 59.3232Z"
        fill="#2F2E41"
      />
      <path
        d="M100.316 55.4187C104.416 55.4187 107.739 52.1082 107.739 48.0244C107.739 43.9407 104.416 40.6301 100.316 40.6301C96.2164 40.6301 92.8929 43.9407 92.8929 48.0244C92.8929 52.1082 96.2164 55.4187 100.316 55.4187Z"
        fill="#FFB8B8"
      />
      <path
        d="M119.378 114.312L119.426 114.888C110.082 118.093 100.085 118.936 90.3329 117.342L87.576 116.823L90.1994 111.028L91.9192 107.229L91.3 94.087L91.2343 92.727L91.1534 90.9922L92.2736 90.9857L100.238 90.9377L107.828 90.8898L108.985 90.8832C116.243 99.7754 122.231 107.002 119.378 114.312Z"
        fill="#2F2E41"
      />
      <path
        d="M108.876 90.7743L91.1534 92.7358L90.497 80.5308C88.4171 75.2469 89.7901 70.5306 94.1066 66.2983L94.9842 62.8382C95.2145 61.93 95.6904 61.1021 96.3601 60.4446C97.0299 59.787 97.8678 59.325 98.7828 59.1087C101.684 57.4514 104.97 56.6614 108.657 56.7748C110.13 57.3475 111.378 58.3768 112.218 59.7107C113.058 61.0445 113.446 62.6121 113.323 64.182C112.56 73.7737 111.191 82.7524 108.876 90.7743Z"
        fill="#6C63FF"
      />
      <path
        d="M94.7006 39.2881C94.7006 39.2881 90.8623 40.6833 90.7638 45.7559C90.7583 46.6095 90.8727 47.4597 91.1036 48.2818C91.3525 49.214 91.5522 51.2369 89.8785 53.9333C88.7849 55.6835 87.7961 57.4967 86.9175 59.3632V59.3632L91.2393 59.7786L91.954 52.642C92.3005 49.182 93.1565 45.7917 94.4945 42.5801L94.5225 42.5131C94.5225 42.5131 96.5052 45.9458 100.274 45.8401L98.8898 44.5372C98.8898 44.5372 103.61 46.3077 106.176 46.6632C106.469 46.7038 106.745 46.8233 106.975 47.0087C107.205 47.1941 107.38 47.4385 107.48 47.7155C107.581 47.9924 107.604 48.2915 107.547 48.5805C107.489 48.8695 107.354 49.1375 107.155 49.3555C107.14 49.3719 107.125 49.3884 107.109 49.4049C105.539 51.059 102.416 54.9033 104.383 57.7998C106.351 60.6963 104.758 61.5459 104.758 61.5459L107.674 59.8541L108.997 59.012L109.027 60.0845L116.003 59.0837C116.003 59.0837 117.879 58.7628 113.713 54.318C113.713 54.318 112.597 52.5778 113.02 51.0381C113.371 49.8671 113.436 48.6299 113.212 47.4286C112.165 42.4276 108.334 32.534 94.7006 39.2881Z"
        fill="#2F2E41"
      />
      <path
        d="M100.015 148.455C114.757 148.455 126.708 147.772 126.708 146.93C126.708 146.087 114.757 145.404 100.015 145.404C85.2723 145.404 73.3214 146.087 73.3214 146.93C73.3214 147.772 85.2723 148.455 100.015 148.455Z"
        fill="#E6E6E6"
      />
      <path
        d="M0.162421 70.2945C0.10638 70.3094 0.0585754 70.3459 0.029499 70.3959C0.000422465 70.4459 -0.00755253 70.5054 0.00732755 70.5612L5.97831 92.8847C5.9933 92.9405 6.02991 92.9881 6.08011 93.0171C6.1303 93.046 6.18999 93.054 6.24606 93.0391L23.1597 88.5503C23.2157 88.5353 23.2636 88.4989 23.2926 88.4489C23.3217 88.3989 23.3297 88.3394 23.3148 88.2836L17.3438 65.9601C17.3288 65.9043 17.2922 65.8567 17.242 65.8277C17.1918 65.7988 17.1321 65.7908 17.0761 65.8056L0.162421 70.2945Z"
        fill="#F2F2F2"
      />
      <path
        d="M4.31861 73.9813C4.0854 74.0432 3.96563 74.3547 4.05145 74.6756C4.13728 74.9964 4.39678 75.2073 4.62998 75.1454L15.6239 72.2277C15.8571 72.1658 15.9768 71.8542 15.891 71.5334C15.8052 71.2125 15.5457 71.0016 15.3125 71.0635L4.31861 73.9813Z"
        fill="white"
      />
      <path
        d="M4.99451 76.5084C4.76131 76.5703 4.64153 76.8819 4.72736 77.2027C4.81319 77.5236 5.07269 77.7345 5.30589 77.6726L16.2998 74.7548C16.533 74.6929 16.6527 74.3814 16.5669 74.0605C16.4811 73.7397 16.2216 73.5288 15.9884 73.5907L4.99451 76.5084Z"
        fill="white"
      />
      <path
        d="M5.67054 79.0357C5.43733 79.0976 5.31756 79.4091 5.40338 79.73C5.48921 80.0508 5.74871 80.2617 5.98191 80.1998L16.9758 77.282C17.209 77.2202 17.3288 76.9086 17.2429 76.5878C17.1571 76.2669 16.8976 76.056 16.6644 76.1179L5.67054 79.0357Z"
        fill="white"
      />
      <path
        d="M6.34644 81.5628C6.11324 81.6247 5.99346 81.9362 6.07929 82.2571C6.16511 82.578 6.42462 82.7889 6.65782 82.727L17.6517 79.8092C17.8849 79.7473 18.0047 79.4358 17.9188 79.1149C17.833 78.794 17.5735 78.5832 17.3403 78.6451L6.34644 81.5628Z"
        fill="white"
      />
      <path
        d="M7.02246 84.09C6.78926 84.1519 6.66949 84.4635 6.75531 84.7843C6.84114 85.1052 7.10064 85.3161 7.33384 85.2542L18.3277 82.3364C18.5609 82.2745 18.6807 81.963 18.5949 81.6421C18.509 81.3213 18.2495 81.1104 18.0163 81.1723L7.02246 84.09Z"
        fill="white"
      />
      <path
        d="M7.69837 86.6172C7.46517 86.6791 7.34539 86.9906 7.43122 87.3115C7.51704 87.6324 7.77655 87.8433 8.00975 87.7814L19.0036 84.8636C19.2368 84.8017 19.3566 84.4902 19.2708 84.1693C19.1849 83.8484 18.9254 83.6376 18.6922 83.6994L7.69837 86.6172Z"
        fill="white"
      />
      <path
        d="M43.3865 148.949C43.3865 148.977 43.4845 149 43.6053 149H66.7977C66.9184 149 67.0165 148.977 67.0165 148.949V144.91C67.0165 144.882 66.9184 144.859 66.7977 144.859H43.6053C43.4845 144.859 43.3865 144.882 43.3865 144.91V148.949Z"
        fill="#F2F2F2"
      />
      <path
        d="M48.033 148.243C48.033 148.298 48.3043 148.344 48.6377 148.344C48.9711 148.344 49.2424 148.298 49.2424 148.243V145.617C49.2424 145.561 48.9711 145.516 48.6377 145.516C48.3043 145.516 48.033 145.561 48.033 145.617V148.243Z"
        fill="white"
      />
      <path
        d="M50.6584 148.243C50.6584 148.298 50.9298 148.344 51.2632 148.344C51.5966 148.344 51.8679 148.298 51.8679 148.243V145.617C51.8679 145.561 51.5966 145.516 51.2632 145.516C50.9298 145.516 50.6584 145.561 50.6584 145.617V148.243Z"
        fill="white"
      />
      <path
        d="M53.2841 148.243C53.2841 148.298 53.5554 148.344 53.8888 148.344C54.2221 148.344 54.4935 148.298 54.4935 148.243V145.617C54.4935 145.561 54.2221 145.516 53.8888 145.516C53.5554 145.516 53.2841 145.561 53.2841 145.617V148.243Z"
        fill="white"
      />
      <path
        d="M55.9095 148.243C55.9095 148.298 56.1809 148.344 56.5143 148.344C56.8476 148.344 57.119 148.298 57.119 148.243V145.617C57.119 145.561 56.8476 145.516 56.5143 145.516C56.1809 145.516 55.9095 145.561 55.9095 145.617V148.243Z"
        fill="white"
      />
      <path
        d="M58.5352 148.243C58.5352 148.298 58.8065 148.344 59.1399 148.344C59.4733 148.344 59.7446 148.298 59.7446 148.243V145.617C59.7446 145.561 59.4733 145.516 59.1399 145.516C58.8065 145.516 58.5352 145.561 58.5352 145.617V148.243Z"
        fill="white"
      />
      <path
        d="M61.1606 148.243C61.1606 148.298 61.432 148.344 61.7654 148.344C62.0987 148.344 62.3701 148.298 62.3701 148.243V145.617C62.3701 145.561 62.0987 145.516 61.7654 145.516C61.432 145.516 61.1606 145.561 61.1606 145.617V148.243Z"
        fill="white"
      />
      <path
        d="M0.782907 54.185C0.731761 54.2123 0.693563 54.2586 0.676697 54.3139C0.659832 54.3692 0.665676 54.4289 0.692946 54.4799L11.616 74.8595C11.6434 74.9104 11.6899 74.9484 11.7454 74.9652C11.8009 74.982 11.8609 74.9762 11.9121 74.9491L27.3529 66.7373C27.404 66.71 27.4422 66.6637 27.4591 66.6084C27.4759 66.5531 27.4701 66.4934 27.4428 66.4424L16.5198 46.0629C16.4924 46.0119 16.4459 45.9739 16.3903 45.9571C16.3348 45.9403 16.2749 45.9461 16.2237 45.9732L0.782907 54.185Z"
        fill="#E6E6E6"
      />
      <path
        d="M2.21817 54.7805L12.2139 73.4295L25.9176 66.1418L15.9219 47.4926L2.21817 54.7805ZM1.34982 55.2423L1.35175 55.2413L1.34961 55.2424L1.34982 55.2423Z"
        fill="white"
      />
      <path
        d="M5.67336 56.8308C5.46047 56.9441 5.41514 57.2746 5.57215 57.5675C5.72916 57.8605 6.03009 58.0068 6.24298 57.8936L16.2795 52.556C16.4924 52.4427 16.5377 52.1122 16.3807 51.8193C16.2237 51.5263 15.9228 51.38 15.7099 51.4932L5.67336 56.8308Z"
        fill="#6C63FF"
      />
      <path
        d="M6.90994 59.1379C6.69705 59.2511 6.65172 59.5817 6.80873 59.8746C6.96574 60.1675 7.26667 60.3139 7.47955 60.2007L17.5161 54.863C17.729 54.7498 17.7743 54.4193 17.6173 54.1264C17.4603 53.8334 17.1593 53.687 16.9465 53.8003L6.90994 59.1379Z"
        fill="#6C63FF"
      />
      <path
        d="M8.14651 61.4451C7.93362 61.5583 7.88829 61.8888 8.0453 62.1817C8.20231 62.4747 8.50324 62.621 8.71613 62.5078L18.7527 57.1702C18.9656 57.0569 19.0109 56.7264 18.8539 56.4335C18.6969 56.1405 18.3959 55.9942 18.1831 56.1074L8.14651 61.4451Z"
        fill="#6C63FF"
      />
      <path
        d="M9.38308 63.7522C9.17019 63.8654 9.12486 64.1959 9.28187 64.4889C9.43889 64.7818 9.73981 64.9282 9.9527 64.8149L19.9892 59.4773C20.2021 59.3641 20.2474 59.0336 20.0904 58.7406C19.9334 58.4477 19.6325 58.3013 19.4196 58.4145L9.38308 63.7522Z"
        fill="#6C63FF"
      />
      <path
        d="M10.6197 66.0593C10.4068 66.1725 10.3614 66.503 10.5184 66.796C10.6755 67.0889 10.9764 67.2353 11.1893 67.1221L21.2258 61.7844C21.4387 61.6712 21.484 61.3407 21.327 61.0477C21.17 60.7548 20.8691 60.6084 20.6562 60.7217L10.6197 66.0593Z"
        fill="#6C63FF"
      />
      <path
        d="M11.8562 68.3664C11.6433 68.4797 11.598 68.8102 11.755 69.1031C11.912 69.396 12.213 69.5424 12.4258 69.4292L22.4624 64.0915C22.6753 63.9783 22.7206 63.6478 22.5636 63.3549C22.4066 63.0619 22.1056 62.9156 21.8928 63.0288L11.8562 68.3664Z"
        fill="#6C63FF"
      />
      <path
        d="M150.823 106.265C150.773 106.235 150.714 106.227 150.658 106.241C150.601 106.256 150.553 106.292 150.524 106.341L138.725 126.23C138.695 126.28 138.687 126.34 138.701 126.396C138.716 126.452 138.752 126.5 138.802 126.529L153.871 135.399C153.921 135.429 153.98 135.437 154.037 135.423C154.093 135.408 154.141 135.372 154.17 135.323L165.97 115.434C165.999 115.384 166.008 115.324 165.993 115.269C165.979 115.213 165.942 115.165 165.893 115.135L150.823 106.265Z"
        fill="#E6E6E6"
      />
      <path
        d="M151.059 107.796L140.262 125.996L153.635 133.869L164.433 115.668L151.059 107.796ZM150.211 107.297L150.213 107.298L150.211 107.297L150.211 107.297Z"
        fill="white"
      />
      <path
        d="M151.097 111.802C150.889 111.679 150.582 111.813 150.412 112.098C150.243 112.384 150.273 112.716 150.481 112.839L160.276 118.605C160.484 118.727 160.791 118.594 160.961 118.308C161.13 118.022 161.099 117.69 160.892 117.567L151.097 111.802Z"
        fill="#6C63FF"
      />
      <path
        d="M149.761 114.053C149.553 113.931 149.246 114.064 149.077 114.35C148.907 114.636 148.938 114.968 149.146 115.09L158.941 120.856C159.148 120.978 159.455 120.845 159.625 120.559C159.795 120.273 159.764 119.941 159.556 119.819L149.761 114.053Z"
        fill="#6C63FF"
      />
      <path
        d="M148.425 116.305C148.217 116.182 147.91 116.316 147.741 116.602C147.571 116.887 147.602 117.22 147.81 117.342L157.605 123.108C157.812 123.23 158.119 123.097 158.289 122.811C158.459 122.525 158.428 122.193 158.22 122.071L148.425 116.305Z"
        fill="#6C63FF"
      />
      <path
        d="M147.089 118.556C146.882 118.434 146.575 118.567 146.405 118.853C146.235 119.139 146.266 119.471 146.474 119.594L156.269 125.359C156.477 125.482 156.784 125.348 156.953 125.062C157.123 124.777 157.092 124.444 156.884 124.322L147.089 118.556Z"
        fill="#6C63FF"
      />
      <path
        d="M145.754 120.808C145.546 120.686 145.239 120.819 145.069 121.105C144.9 121.391 144.93 121.723 145.138 121.845L154.933 127.611C155.141 127.733 155.448 127.6 155.618 127.314C155.787 127.028 155.756 126.696 155.549 126.574L145.754 120.808Z"
        fill="#6C63FF"
      />
      <path
        d="M144.418 123.06C144.21 122.937 143.903 123.07 143.733 123.356C143.564 123.642 143.595 123.974 143.803 124.097L153.597 129.863C153.805 129.985 154.112 129.852 154.282 129.566C154.451 129.28 154.421 128.948 154.213 128.825L144.418 123.06Z"
        fill="#6C63FF"
      />
      <path
        d="M96.4045 70.0695L96.177 71.473L93.7789 86.1974L92.2736 90.9857L91.7442 92.6704L91.3 94.087L85.9022 111.261L84.4213 114.727C83.6087 115.026 82.9104 115.571 82.4239 116.285C81.9375 117 81.6873 117.847 81.7083 118.71C81.7292 119.573 82.0204 120.407 82.541 121.097C83.0616 121.787 83.7856 122.298 84.6118 122.558C85.438 122.817 86.325 122.813 87.1489 122.546C87.9728 122.279 88.6923 121.763 89.2068 121.068C89.7213 120.374 90.0051 119.537 90.0185 118.674C90.0319 117.811 89.7742 116.965 89.2815 116.255L99.8462 91.7746L100.238 90.9377L101.437 88.3769L103.701 71.3575L103.844 70.2874L96.4045 70.0695Z"
        fill="#FFB8B8"
      />
      <path
        d="M105.156 71.813C101.718 70.5822 98.4191 70.4514 95.3104 71.813L96.3251 63.0527C96.2879 61.9587 96.6831 60.8939 97.4258 60.0871C98.1685 59.2804 99.1994 58.7962 100.297 58.7387C101.408 58.6804 102.496 59.0642 103.323 59.8055C104.15 60.5468 104.647 61.5849 104.706 62.6915L104.707 62.7059L105.156 71.813Z"
        fill="#6C63FF"
      />
    </svg>
  );
}

export default EmptyFilesIcon;
