import React from 'react';

function RecordingsIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 4.22857C29 2.99775 27.9904 2 26.745 2H3.25503C2.0096 2 1 2.99775 1 4.22857V25.7714C1 27.0023 2.0096 28 3.25503 28H26.745C27.9904 28 29 27.0023 29 25.7714V4.22857ZM9.55034 4.78571H13.5906V7.94286H9.55034V4.78571ZM6.73154 25.2143H3.81879V22.0571H6.73154V25.2143ZM6.73154 7.94286H3.81879V4.78571H6.73154V7.94286ZM13.5906 25.2143H9.55034V22.0571H13.5906V25.2143ZM12.557 17.5729V12.427C12.557 11.661 13.1084 11.3776 13.7569 11.7972L17.5376 14.2367C18.1861 14.6563 18.1913 15.343 17.5428 15.7627L13.7543 18.2027C13.1058 18.6224 12.557 18.339 12.557 17.5729ZM20.4497 25.2143H16.4094V22.0571H20.4497V25.2143ZM20.4497 7.94286H16.4094V4.78571H20.4497V7.94286ZM26.1812 25.2143H23.2685V22.0571H26.1812V25.2143ZM26.1812 7.94286H23.2685V4.78571H26.1812V7.94286Z"
        fill="white"
      />
    </svg>
  );
}

export default RecordingsIcon;
