import React from 'react';

function MicrophoneIconMuted({ ...rest }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_20_37)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.63062 4.77857C7.63062 2.17108 9.75629 0.0454102 12.3638 0.0454102C14.9713 0.0454102 17.0969 2.17108 17.0969 4.77857V5.28782L8.02451 14.3603C7.77124 13.7796 7.63062 13.1373 7.63062 12.4593V4.77857ZM11.0245 17.0171L17.0969 10.9447V12.4877C17.0969 15.0951 14.9713 17.2208 12.3638 17.2208C11.899 17.2158 11.4494 17.145 11.0245 17.0171Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.06388 15.3209C6.61163 14.477 6.35508 13.5128 6.35508 12.4892C6.35508 12.0074 5.98663 11.6389 5.50481 11.6389C5.02299 11.6389 4.65454 12.0074 4.65454 12.4892C4.65454 13.9801 5.07672 15.3818 5.81354 16.5712L7.06388 15.3209ZM11.5134 20.1699C10.5111 20.061 9.57214 19.7622 8.72983 19.3118L10.0192 18.0224C10.7397 18.3284 11.5321 18.4977 12.3636 18.4977C15.6797 18.4977 18.3722 15.8052 18.3722 12.4892C18.3722 12.0074 18.7406 11.6389 19.2225 11.6389C19.7043 11.6389 20.0727 12.0074 20.0727 12.4892C20.0727 16.4288 17.1251 19.7448 13.2139 20.1699V22.4373H16.3032C16.785 22.4373 17.1535 22.8058 17.1535 23.2876C17.1535 23.7694 16.785 24.1379 16.3032 24.1379H8.42406C7.94224 24.1379 7.57379 23.7694 7.57379 23.2876C7.57379 22.8058 7.94224 22.4373 8.42406 22.4373H11.5134V20.1699Z"
          fill="white"
        />
        <rect
          x="21.0919"
          y="2.70715"
          width="2"
          height="26"
          transform="rotate(45 21.0919 2.70715)"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_20_37">
          <rect
            width="24.0909"
            height="24.0909"
            fill="white"
            transform="translate(0.318115 0.0454102)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MicrophoneIconMuted;
