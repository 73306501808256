import { useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { userMeInfoVar } from '../lib/apollo-client/reactive-variables';

/** Constants & utils */
import { getPricesMapByDuration } from 'lib/utils/prices';
import {
  PaymentType,
  CompanyPlanType,
  PatientPmiStatus,
  SessionPriceType,
} from '__generated__/types';

/** Queries */
import { useGetPatientMeData } from 'common/query/__generated__/get-patient-me-data';
import { useGetSessionPriceLazyQuery } from 'common/query/__generated__/get-session-price';
import { useGetCompanyPlanAndSessionInfo } from 'common/query/patient/__generated__/get-company-plan-and-session-info';

/**
 * @NOTE: Only for patients.
 */
export function usePrices() {
  /** User info */
  const userInfo = useReactiveVar(userMeInfoVar);

  /** Get patient related data */
  const { data: patientData, loading: patientLoading } = useGetPatientMeData();
  const patient = patientData?.me?.patient;

  /** Get data about company plan and sessions */
  const { data: companyPlanInfoData } = useGetCompanyPlanAndSessionInfo();
  const companyPlanInfo = companyPlanInfoData?.planAndSessionsInfo;

  /** Get pricing type based on user plan and patient data */
  //const priceType = getCorrectPriceType(userInfo, companyPlanInfo, patient);
  const { priceType, avaliablePaymentOptions } =
    getPriceTypeAndAvaliablePaymentOptions(
      userInfo,
      companyPlanInfo,
      patient,
    ) || {};

  /** QUERY to get prices */
  const [getPrices, { data: pricesData, loading }] =
    useGetSessionPriceLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (priceType) {
      getPrices({
        variables: {
          priceType: priceType,
        },
      });
    }
  }, [priceType]);

  const sessionPriceId = pricesData?.getSessionPrice.id;

  /** Format prices */
  const prices = useMemo(() => {
    return (
      pricesData?.getSessionPrice &&
      getPricesMapByDuration(pricesData?.getSessionPrice)
    );
  }, [pricesData]);

  /**
   * @method
   * @description Method will return priceType and sessionPriceId based on payment type chosen by patient.
   * This method is used before booking a session as it will provider more accurate sessionPriceId and priceType.
   */
  const getSessionPriceIdBasedOnPaymentTypeAndPlan = async (
    paymentType: PaymentType,
  ) => {
    let newPriceType = null;
    const { isB2B } = userInfo;

    if (paymentType === PaymentType.Stripe) {
      newPriceType = isB2B
        ? SessionPriceType.B2BSelfPay
        : SessionPriceType.B2CSelfPay;
    } else if (paymentType === PaymentType.Enterprise) {
      newPriceType = SessionPriceType.B2B;
    } else if (paymentType === PaymentType.Insurance) {
      newPriceType = SessionPriceType.B2CInsurance;
    } else {
      newPriceType = SessionPriceType.B2CSelfPay;
    }

    /** Refetch prices for this price type */
    const sessionPriceData = await getPrices({
      variables: {
        priceType: newPriceType,
      },
    });

    return {
      sessionPriceId: sessionPriceData.data?.getSessionPrice.id,
      priceType: sessionPriceData.data?.getSessionPrice.priceType,
    };
  };

  return {
    prices,
    loading,
    priceType,
    sessionPriceId,
    avaliablePaymentOptions,
    getSessionPriceIdBasedOnPaymentTypeAndPlan,
  };
}

export function usePriceOptions() {
  const { prices } = usePrices();
  const { isB2B } = useReactiveVar(userMeInfoVar);

  if (!prices) {
    return [];
  }

  return [
    { label: isB2B ? '30 min' : `30 min - £ ${prices[30]}`, value: '30' },
    { label: isB2B ? '50 min' : `50 min - £ ${prices[50]}`, value: '50' },
    { label: isB2B ? '80 min' : `80 min - £ ${prices[80]}`, value: '80' },
  ];
}

/**
 * @method
 * @description Method to get avaliable payment options based on priceType for a user.
 * Method will return what payment types can be used with company plan and patient properties.
 */
const getPriceTypeAndAvaliablePaymentOptions = (
  userInfo: any,
  companyPlanInfo: any,
  patient: any,
) => {
  /** Guard */
  if (!userInfo || !patient) {
    return {
      priceType: null,
      avaliablePaymentOptions: [],
    };
  }

  const { isB2B } = userInfo;
  const { pmiStatus } = patient;

  /** Company plan data */
  const plan = companyPlanInfo?.plan;
  const sessionsLeft = companyPlanInfo?.sessionsLeft;

  /** Regular B2C customer */
  if (!isB2B || !plan) {
    return {
      priceType: SessionPriceType.B2CSelfPay,
      avaliablePaymentOptions: [
        PaymentType.Stripe,
        PaymentType.Insurance,
        PaymentType.Voucher,
      ],
    };
  }

  /** Check plans and sessions */
  if (plan.planType === CompanyPlanType.Unlimited) {
    return {
      priceType: SessionPriceType.B2B,
      avaliablePaymentOptions: [PaymentType.Enterprise],
    };
  } else if (
    plan.planType === CompanyPlanType.Insurance ||
    plan.planType === CompanyPlanType.InsuranceLimited
  ) {
    return {
      priceType: SessionPriceType.B2B,
      avaliablePaymentOptions: [PaymentType.Insurance],
    };
  } else if (
    plan.planType === CompanyPlanType.LimitedPerEmployee ||
    plan.planType === CompanyPlanType.LimitedPerCompany ||
    plan.planType === CompanyPlanType.SubscriptionPerCompany ||
    plan.planType === CompanyPlanType.SubscriptionPerEmployee
  ) {
    if (sessionsLeft > 0) {
      return {
        priceType: SessionPriceType.B2B,
        avaliablePaymentOptions: [PaymentType.Enterprise],
      };
    }

    return {
      priceType: SessionPriceType.B2BSelfPay,
      avaliablePaymentOptions: [PaymentType.Stripe],
    };
  } else if (
    plan.planType === CompanyPlanType.Mixed &&
    pmiStatus === PatientPmiStatus.NotCovered
  ) {
    if (sessionsLeft > 0) {
      return {
        priceType: SessionPriceType.B2B,
        avaliablePaymentOptions: [PaymentType.Insurance],
      };
    }

    return {
      priceType: SessionPriceType.B2BSelfPay,
      avaliablePaymentOptions: [PaymentType.Stripe],
    };
  } else if (
    plan.planType === CompanyPlanType.Mixed &&
    pmiStatus === PatientPmiStatus.Covered
  ) {
    return {
      priceType: SessionPriceType.B2BSelfPay,
      avaliablePaymentOptions: [PaymentType.Stripe, PaymentType.Insurance],
    };
  }
};

/**
 * @function
 * @name getCorrectPriceType
 * @description Helper function to determine what price type will be used based on user company, user company plan and sessions left.
 */
const getCorrectPriceType = (
  userInfo: any,
  companyPlanInfo: any,
  patient?: any,
) => {
  const { isB2B } = userInfo;
  const { pmiStatus } = patient;

  /** Company plan data */
  const plan = companyPlanInfo?.plan;
  const sessionsLeft = companyPlanInfo?.sessionsLeft;

  /** Regular B2C customer */
  if (!isB2B || !plan) {
    return SessionPriceType.B2CSelfPay;
  }

  /** Check plans and sessions */
  if (plan.planType === CompanyPlanType.Unlimited) {
    return SessionPriceType.B2B;
  } else if (
    plan.planType === CompanyPlanType.Insurance ||
    plan.planType === CompanyPlanType.InsuranceLimited
  ) {
    return SessionPriceType.B2CInsurance;
  } else if (plan.planType === CompanyPlanType.Mixed) {
    if (pmiStatus === PatientPmiStatus.NotCovered) {
      return sessionsLeft > 0
        ? SessionPriceType.B2B
        : SessionPriceType.B2BSelfPay;
    }

    return SessionPriceType.B2BSelfPay;
  } else if (
    plan.planType === CompanyPlanType.LimitedPerEmployee ||
    plan.planType === CompanyPlanType.LimitedPerCompany ||
    plan.planType === CompanyPlanType.SubscriptionPerCompany ||
    plan.planType === CompanyPlanType.SubscriptionPerEmployee
  ) {
    return sessionsLeft > 0
      ? SessionPriceType.B2B
      : SessionPriceType.B2BSelfPay;
  }

  return null;
};
