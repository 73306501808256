import React from 'react';

function CrossIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      stroke="#6B4EE6"
      viewBox="0 0 12 12"
      {...rest}
    >
      <path strokeLinecap="square" strokeWidth="2" d="M10 2l-8 8M2 2l8 8" />
    </svg>
  );
}

export default CrossIcon;
