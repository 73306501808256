import React, { FC, useCallback, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { usePatientSetupIntent } from './mutation/__generated__/patient-setup-intent';
import { notifyError, notifySuccess } from 'lib/utils/notification';
import { ArrowRightShortIcon, Button, Modal } from '../../../ui';
import styled, { css, CSSProp } from 'styled-components';
import { getSize } from '../../../lib/utils';
import { usePatientPaymentMethodSetDefault } from './mutation/__generated__/patient-payment-method-set-default';
import { GetPatientPaymentMethodsDocument } from '../../../common/query/__generated__/get-patient-payment-methods';
import { GetPatientMeDataDocument } from '../../../common/query/__generated__/get-patient-me-data';
import { StripeError } from '@stripe/stripe-js';

interface StripeCardModalProps {
  onSuccess?: () => void;
  onUnsuccess?: () => void;
  failedPayment?: string;
  isVisible: boolean;
  onClose: () => void;
  fromBooking?: boolean;
  className?: string;
}

const StripeCardModal: FC<StripeCardModalProps> = ({
  onSuccess,
  failedPayment,
  onUnsuccess,
  isVisible,
  onClose,
  fromBooking,
  className,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [patientSetupIntent] = usePatientSetupIntent();

  const [setDefaultPaymentMethod] = usePatientPaymentMethodSetDefault();

  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      try {
        setIsFetching(true);
        const { data: clientSecret } = await patientSetupIntent();
        const patientIntent = clientSecret?.patientSetupIntent;

        if (cardElement && patientIntent) {
          let error: StripeError | undefined;

          if (failedPayment) {
            const { error: paymentError } = await stripe.confirmCardPayment(
              failedPayment,
              {
                payment_method: {
                  card: cardElement,
                },
              },
            );

            error = paymentError;

            if (!error) {
              onSuccess?.();
            }
          } else {
            const { error: setupError, setupIntent } =
              await stripe.confirmCardSetup(patientIntent, {
                payment_method: {
                  card: cardElement,
                },
              });

            error = setupError;

            if (setupIntent?.payment_method) {
              const response = await setDefaultPaymentMethod({
                variables: {
                  paymentMethodId: setupIntent?.payment_method.toString(),
                },
                refetchQueries: [
                  { query: GetPatientPaymentMethodsDocument },
                  { query: GetPatientMeDataDocument },
                ],
              });

              if (!response?.data?.patientPaymentMethodSetDefault) {
                throw new Error('Something went wrong');
              }

              notifySuccess({
                title: 'Stripe service',
                text: 'Payment method by default set successfully',
              });
            }
          }

          if (error) {
            await onUnsuccess?.();
            notifyError({ text: error.message });
          } else {
            cardElement.clear();
            await onSuccess?.();
            onClose();
          }
        }
      } catch (error: any) {
        notifyError({ text: error.message });
      } finally {
        setIsFetching(false);
      }
    },
    [
      stripe,
      elements,
      patientSetupIntent,
      failedPayment,
      onSuccess,
      onUnsuccess,
      setDefaultPaymentMethod,
      onClose,
    ],
  );

  return (
    <Modal
      isVisible={isVisible}
      title={failedPayment ? 'Manual payment' : 'Set up payment method'}
      onClose={onClose}
      className={className}
    >
      <form onSubmit={handleSubmit}>
        <Wrapper $fromBooking={fromBooking}>
          <CardElement options={{ hidePostalCode: true }} />
        </Wrapper>
        <SaveButton
          theme={fromBooking ? 'purpleGradient' : 'primary'}
          type="submit"
          isLoading={isFetching}
          $fromBooking={fromBooking}
        >
          {fromBooking ? (
            <>
              {'Continue'} <ArrowRightShortIcon />
            </>
          ) : (
            'Save payment method'
          )}
        </SaveButton>
      </form>
    </Modal>
  );
};

const Wrapper = styled.div<{ $fromBooking?: boolean }>`
  margin-bottom: ${getSize(24)};

  ${({ $fromBooking }) =>
    $fromBooking &&
    css`
      padding: ${getSize(12)} ${getSize(17)};
      border: 1px solid var(--gray19);
      border-radius: ${getSize(8)};
    `};
`;

const SaveButton = styled(Button)<{ $fromBooking?: boolean }>`
  width: 100%;

  ${({ $fromBooking }) =>
    $fromBooking &&
    css`
      height: ${getSize(46)};
    `}
`;

export default StripeCardModal;
