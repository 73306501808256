import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled from 'styled-components';

interface SearchInputProps {
  placeholder?: string;
  onChange: (value: string) => void;
}

const SearchInput: FC<SearchInputProps> = ({ placeholder, onChange }) => {
  return (
    <Wrapper>
      <Input
        placeholder={placeholder || 'Search'}
        onChange={(e) => onChange(e.target.value)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  min-height: ${getSize(34)};
  min-width: ${getSize(415)};
  padding: ${getSize(9)} ${getSize(16)} ${getSize(8)};
  background-color: var(--purple11);
  font-size: ${getSize(11)};
  line-height: ${getSize(16)};
  color: var(--black3);
  border-radius: ${getSize(8)};
  transition: all 0.3s ease-out;

  &::placeholder {
    color: var(--gray7);
  }

  &:focus {
    background-color: var(--purple5);
  }
`;

export default SearchInput;
