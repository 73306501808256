import * as React from 'react';
import { SVGProps, memo } from 'react';

const ConfirmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m16 3-8.851 9L3 7.781"
      stroke="#02D255"
      strokeWidth={4}
      strokeLinecap="square"
    />
  </svg>
);

export default memo(ConfirmIcon);
