import { getSize } from 'lib/utils';

export const ICONS_CSS = {
  IN_PERSON: {
    '& path:first-child': {
      fill: 'var(--gray21)',
    },
    svg: {
      width: getSize(21),
      height: getSize(21),
    },
  },
  VIDEO_CALL: {
    '& path:nth-child(2)': {
      fill: 'var(--gray21)',
    },
    svg: {
      width: getSize(21),
      height: getSize(21),
    },
  },
  LIVE_CHAT: {
    '& path:first-child': {
      fill: 'var(--gray21)',
    },
    svg: {
      width: getSize(22),
      height: getSize(22),
    },
  },
};
