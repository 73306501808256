import * as React from 'react';

function SvgComponent({ ...props }) {
  return (
    <svg
      width={props.width || 14}
      height={props.height || 14}
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path stroke="#fff" strokeWidth={1.75} d="M.875.875h12.25v12.25H.875z" />
      <g clipPath="url(#prefix__clip0)" stroke="#fff" strokeWidth={0.778}>
        <path d="M8.944 2.722h2.334v2.334M11.278 2.722L8.556 5.444" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(8 2)" d="M0 0h4v4H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
