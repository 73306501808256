export function getDeepObject(obj: any, path: string | string[]): any {
  const parts = Array.isArray(path)
    ? path
    : path.replaceAll('[', '.').replaceAll(']', '').split('.');

  if (parts.length == 1) {
    return obj?.[parts[0]];
  }
  return getDeepObject(obj?.[parts[0]], parts.slice(1));
}
