import { Video } from '../video';
import { getSize } from 'lib/utils';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { VideoStopwatch } from './components';
import { TargetStream } from 'hooks/use-video-call';
import { HideSelfVideoActiveIcon, HideSelfVideoIcon } from 'ui';
import { MessageArrowIcon } from 'ui/icons/message-arrow-icon';

interface VideoSessionInfoProps {
  isSmallScreen?: boolean;
  localStream?: MediaStream;
  interlocutorName: string;
  targetStreams?: TargetStream[];
  isPatient?: boolean;
  isNoteShow?: boolean;
  isLobbyShow: boolean;
}

const VideoSessionInfo: FC<VideoSessionInfoProps> = ({
  isSmallScreen,
  localStream,
  interlocutorName,
  targetStreams,
  isPatient,
  isNoteShow,
  isLobbyShow,
}) => {
  const [isSelfVideoHide, setIsSelfVideoHide] = useState(false);
  const toggleSelfVideoVisible = () => {
    setIsSelfVideoHide(!isSelfVideoHide);
  };
  return (
    <Wrapper $isSmallScreen={isSmallScreen}>
      <VideoInfo className="video-call__info">
        <Name>{interlocutorName}</Name>
        <VideoStopwatch />
      </VideoInfo>

      {localStream && (
        <SmallVideo
          className="video-call__small-video"
          $isStreamStart={!!targetStreams?.length}
          $isNoteShow={isNoteShow}
          $isPatient={!!isPatient}
        >
          <VideoWrapper $isSelfVideoHide={isSelfVideoHide}>
            <Video srcObject={localStream} autoPlay muted />
          </VideoWrapper>

          {!isLobbyShow && isPatient && (
            <VideoButton
              onClick={toggleSelfVideoVisible}
              $isSelfVideoHide={isSelfVideoHide}
            >
              {isSelfVideoHide ? (
                <HideSelfVideoActiveIcon />
              ) : (
                <HideVideoIconContainer>
                  <HideSelfVideoIcon />
                  <HideVideoNotificationContainer>
                    <MessageArrowIcon />
                    <HideVideoIconNotification>
                      Hide video for yourself. Your therapist will still be able
                      to see you as this will not turn off your camera.
                    </HideVideoIconNotification>
                  </HideVideoNotificationContainer>
                </HideVideoIconContainer>
              )}
            </VideoButton>
          )}
        </SmallVideo>
      )}
    </Wrapper>
  );
};

interface VideoWrapperProps {
  $isSelfVideoHide: boolean;
}
const VideoWrapper = styled.div<VideoWrapperProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all 0.3s;
  opacity: ${({ $isSelfVideoHide }) => ($isSelfVideoHide ? 0 : 1)};
  border-radius: 8px;
`;

const VideoButton = styled.button<{
  $isCallStarted?: boolean;
  $isSelfVideoHide: boolean;
}>`
  position: absolute;
  top: ${({ $isSelfVideoHide }) => ($isSelfVideoHide ? '12px' : 'auto')};
  bottom: ${({ $isSelfVideoHide }) => ($isSelfVideoHide ? 'auto' : '12px')};
  right: ${({ $isSelfVideoHide }) => ($isSelfVideoHide ? '0' : '12px')};
  width: ${({ $isSelfVideoHide }) => ($isSelfVideoHide ? 38 : 48)}px;
  height: ${({ $isSelfVideoHide }) => ($isSelfVideoHide ? 38 : 48)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: rgba(68, 71, 82, 0.68);
  backdrop-filter: blur(${getSize(16)});
  z-index: 20000;
  transition: all 0.3s ease-out;

  .video-button_icon {
    /* stroke: var(--white);
    fill: var(--white); */
    /* width: ${getSize(25)};
    height: ${getSize(25)}; */
    transition: all 0.3s ease-out;
    opacity: 0;
  }

  &:hover {
    opacity: 0.75;
  }
`;

const Wrapper = styled.div<{ $isSmallScreen?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ $isSmallScreen }) => $isSmallScreen && 'opacity: 0'};

  &:hover {
    ${VideoButton} {
      opacity: 1;
    }
  }
`;

interface SmallVideoProps {
  $isStreamStart: boolean;
  $isPatient: boolean;
  $isNoteShow?: boolean;
}

const SmallVideo = styled.div<SmallVideoProps>`
  position: ${({ $isNoteShow }) => ($isNoteShow ? 'fixed' : 'absolute')};
  ${({ $isStreamStart, $isPatient, $isNoteShow }) =>
    !$isStreamStart && $isPatient
      ? `top: 0;
      left: 0;
      width: 100%;
      height: 100%;`
      : `top: ${getSize(16)};
      right: ${getSize(16)};
      width: 217px;
      height: 141px;`}
  border-radius: ${getSize(8)};
  transition: 0.4s ease-out;
  overflow: visible;
  z-index: 9;
  ${({ $isNoteShow }) => $isNoteShow && 'top: 32px; right: 32px'}
`;

const VideoInfo = styled.div`
  width: 100%;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition-timing-function: ease-out forward;
  transition-duration: 0.4s;
`;

const Name = styled.p`
  font-weight: 500;
  font-size: ${getSize(16)};
  line-height: ${getSize(18)};
  color: var(--white);
`;

const HideVideoNotificationContainer = styled.div`
  display: none;
  position: absolute;
  flex-direction: column;
  align-items: flex-end;
  top: calc(100% + 20px);
  right: 0;

  & svg {
    opacity: 0.61;
    position: relative;
    right: 18px;
  }
`;

const HideVideoIconNotification = styled.div`
  background-color: rgba(0, 0, 0, 0.61);
  padding: 20px 18px;
  border-radius: 8px;
  color: white;
  min-width: 186px;
  right: 0;
  font-size: 11px;
  text-align: left;
`;

const HideVideoIconContainer = styled.div`
  &:hover ${HideVideoNotificationContainer} {
    display: flex;
  }
`;

export default VideoSessionInfo;
