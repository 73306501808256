import { StripeCardModal } from 'features/common';
import { usePatientPaymentMethods } from 'hooks';
import { getSize } from 'lib/utils';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, CreditCardRadioButton, PlusIcon, RadioFields } from 'ui';

const CreditCardField: FC<CreditCardFieldProps> = ({ name }) => {
  const [isCardModalVisible, setIsCardModalVisible] = useState(false);

  const { paymentMethodOptions } = usePatientPaymentMethods();

  return (
    <>
      <RadioFieldsStyled
        name={name}
        options={paymentMethodOptions}
        radioRootCSS={RADIO_ROOT_CSS}
        radioButtonCSS={RADIO_BUTTON_CSS}
        component={CreditCardRadioButton}
        iconCSS={ICON_CSS}
      />

      <SetupCreditCardButton
        theme="secondary"
        type="button"
        onClick={() => setIsCardModalVisible(true)}
      >
        <PlusIconStyled strokeWidth={3} /> Add card
      </SetupCreditCardButton>

      <StyledStripeCardModal
        isVisible={isCardModalVisible}
        fromBooking
        onClose={() => setIsCardModalVisible(false)}
        onSuccess={() => setIsCardModalVisible(false)}
      />
    </>
  );
};

interface CreditCardFieldProps {
  name: string;
}

const RadioFieldsStyled = styled(RadioFields)`
  margin-bottom: ${getSize(20)};
`;

const SetupCreditCardButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${getSize(10)} ${getSize(27)};
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--purple);
  border-radius: ${getSize(8)};
  background-color: var(--purple11);
  border: 1px solid var(--purple);
`;

const PlusIconStyled = styled(PlusIcon)`
  width: ${getSize(10)};
  height: ${getSize(10)};
  margin-right: ${getSize(3)};
  stroke: var(--purple);
`;

const StyledStripeCardModal = styled(StripeCardModal)`
  width: ${getSize(606)};
  padding: ${getSize(25)} ${getSize(40)} ${getSize(48)};
`;

const RADIO_ROOT_CSS = css`
  padding: ${getSize(5)} ${getSize(13)};
  border: 1px solid var(--gray38);
  background: var(--white);
`;

const RADIO_BUTTON_CSS = css<{ $isActive?: boolean }>`
  min-width: ${getSize(15)};
  min-height: ${getSize(15)};
  margin-right: ${getSize(15)};
  border-width: ${({ $isActive }) => ($isActive ? getSize(5) : getSize(1))};
`;

const ICON_CSS = css`
  width: ${getSize(35)};
`;

export default CreditCardField;
