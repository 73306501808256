import React from 'react';
import { Route } from 'react-router-dom';
import useTeamsRedirect from '../../hooks/use-teams-redirect';
import { IPrivateRouteProps } from '../private-route/private-route';

const TeamsPrivateRoute: React.FC<IPrivateRouteProps> = (props) => {
  useTeamsRedirect();

  return <Route {...props} />;
};

export default TeamsPrivateRoute;
