import React from 'react';
import { getSize } from 'lib/utils';
import styled from 'styled-components';

function OutlookIcon({ ...rest }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 18"
      {...rest}
    >
      <path fill="#1E73BE" d="M11.225 18L0 16.03V1.97L11.225 0v18z" />
      <path
        fill="#fff"
        d="M5.588 11.58c-1.58 0-2.638-1.354-2.638-2.744 0-1.414 1.108-2.744 2.66-2.744 1.561 0 2.623 1.392 2.623 2.76 0 1.445-1.123 2.729-2.645 2.729zm-1.57-2.744c0 .965.604 1.815 1.577 1.815.976 0 1.578-.873 1.578-1.815 0-.898-.565-1.807-1.578-1.807-1 0-1.578.876-1.578 1.807z"
      />
      <path
        fill="#1E73BE"
        d="M16.594 4.663h-4.815v5.148l4.815-4.043V4.663zM11.78 10.447v2.89h4.814V6.403l-4.815 4.044z"
      />
    </Icon>
  );
}

const Icon = styled.svg`
  width: ${getSize(17)};
  height: ${getSize(18)};
`;

export default OutlookIcon;
