import React, { useCallback, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { getSize } from 'lib/utils';
import { useIsMessagingRoute, useUnreadCount } from './utils';

import { TextOfCount } from 'ui';
import { useTherapistSessionRequestsLazyQuery } from 'features/therapist/dashboard/home/query/__generated__/get-therapist-session-requests';
import { useGetTherapistSessionsNeededActionLazyQuery } from 'features/therapist/dashboard/home/query/__generated__/get-therapist-sessions-needed-action';
import { useChatChanged } from '../chat/subscription/__generated__/chat-changed';
import { updateChatListCacheAfterChanged } from '../chat/utils';

export enum NavigationLinkType {
  MESSAGING = 'messaging',
  HOME = 'home',
}
export interface LinkItem {
  href: string;
  text: string;
  type?: NavigationLinkType;
  Icon: (props: any) => JSX.Element;
  onClick?: () => void;
}
export interface NavigationProps {
  links: LinkItem[];
  className?: string;
  listCSS?: CSSProp;
  numberItemPerRow?: number;
  isHeader?: boolean;
  isTherapist?: boolean;
}

function Navigation({
  links,
  className,
  listCSS,
  numberItemPerRow = links.length,
  isHeader,
  isTherapist,
}: NavigationProps) {
  const { unreadCount } = useUnreadCount();
  const { isMessagingRoute } = useIsMessagingRoute(isTherapist);

  useChatChanged({
    onSubscriptionData: updateChatListCacheAfterChanged,
    skip: isMessagingRoute,
  });

  const [getTherapistRequests, therapistRequests] =
    useTherapistSessionRequestsLazyQuery();
  const [getTherapistActions, therapistActions] =
    useGetTherapistSessionsNeededActionLazyQuery();

  useEffect(() => {
    if (isTherapist) {
      getTherapistRequests();
      getTherapistActions();
    }
  }, [getTherapistRequests, getTherapistActions, isTherapist]);

  const therapistComeNoticeCount = useMemo(() => {
    if (!therapistActions.loading && !therapistRequests.loading) {
      const requests = therapistRequests.data?.me?.therapist?.sessionRequests;
      const actions = therapistActions.data?.therapistSessionsNeededAction;
      if (requests?.length && actions?.length) {
        return requests?.length + actions?.length;
      } else {
        return requests?.length || actions?.length;
      }
    }
  }, [therapistRequests, therapistActions]);

  const notificationsCountsRender = useCallback(
    (type?: NavigationLinkType) => {
      if (type === NavigationLinkType.MESSAGING && unreadCount > 0) {
        return <TextOfCountStylized>{unreadCount}</TextOfCountStylized>;
      } else if (
        type === NavigationLinkType.HOME &&
        therapistComeNoticeCount &&
        therapistComeNoticeCount > 0
      ) {
        return (
          <TextOfCountStylized>{therapistComeNoticeCount}</TextOfCountStylized>
        );
      }
    },
    [unreadCount, therapistComeNoticeCount],
  );

  return (
    <nav className={className}>
      <List
        CSS={listCSS}
        numberItemPerRow={numberItemPerRow}
        $isHeader={isHeader}
      >
        {links.map(({ href, text, Icon, type, onClick }) => (
          <li key={href}>
            <Link to={href} onClick={onClick} $isHeader={isHeader}>
              {!isHeader && <Icon fill={'var(--gray7)'} />}
              {text}

              {notificationsCountsRender(type)}
            </Link>
          </li>
        ))}
      </List>
    </nav>
  );
}

const List = styled.ul<{
  CSS?: CSSProp;
  numberItemPerRow?: number;
  $isHeader?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(
    ${({ numberItemPerRow }) => numberItemPerRow},
    auto
  );
  grid-gap: ${getSize(15)} ${getSize(40)};
  align-items: center;

  ${({ $isHeader }) => (!$isHeader ? 'width: 100%;' : '')}

  ${({ CSS }) => CSS}
`;

const Link = styled(NavLink)<{ $isHeader?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
  font-size: ${getSize(13)};
  padding: 0 ${getSize(12)};
  line-height: ${getSize(24)};
  color: ${({ $isHeader }) => ($isHeader ? 'var(--black3)' : 'var(--gray7)')};
  transition: 0.3s ease-out;

  ${({ $isHeader }) =>
    !$isHeader
      ? `
    padding: ${getSize(5)} ${getSize(10)};
    border-radius: ${getSize(8)};
    `
      : ''}

  & > svg {
    flex-shrink: 0;
    margin: 0 ${getSize(12)} 0 0;
    width: ${getSize(22)};
    height: ${getSize(22)};
  }

  &:hover {
    color: var(--purple);
  }

  &.active {
    color: #6b4ee6;
    ${({ $isHeader }) =>
      $isHeader
        ? `
    &::after {
      content: '';
      position: absolute;
      bottom: ${getSize(-21)};
      left: ${getSize(0)};
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--purple);
    }
    `
        : `
        width: 100%;
        background-color: var( --purple3);
        svg {
      fill: var(--purple);
    }`};
  }

  ${({ $isHeader }) =>
    $isHeader &&
    `
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};;`}
`;

const TextOfCountStylized = styled(TextOfCount)`
  margin: 0 0 0 ${getSize(7)};
`;

export default Navigation;
