import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMaterialIndicatorsVariables = Types.Exact<{
  filter: Types.MaterialIndicatorsFilter;
}>;


export type GetMaterialIndicators = (
  { __typename: 'Query' }
  & { getMaterialIndicators: (
    { __typename: 'MaterialIndicatorsModel' }
    & Pick<Types.MaterialIndicatorsModel, 'all'>
    & Indicators
  ) }
);

export type Indicators = (
  { __typename: 'MaterialIndicatorsModel' }
  & Pick<Types.MaterialIndicatorsModel, 'worksheetsCount' | 'diaryCount' | 'recordingCount'>
);

export const Indicators = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Indicators"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MaterialIndicatorsModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"worksheetsCount"}},{"kind":"Field","name":{"kind":"Name","value":"diaryCount"}},{"kind":"Field","name":{"kind":"Name","value":"recordingCount"}}]}}]} as unknown as DocumentNode;
export const GetMaterialIndicatorsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMaterialIndicators"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MaterialIndicatorsFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getMaterialIndicators"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Indicators"}},{"kind":"Field","name":{"kind":"Name","value":"all"}}]}}]}},...Indicators.definitions]} as unknown as DocumentNode;

/**
 * __useGetMaterialIndicators__
 *
 * To run a query within a React component, call `useGetMaterialIndicators` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialIndicators` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialIndicators({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMaterialIndicators(baseOptions: Apollo.QueryHookOptions<GetMaterialIndicators, GetMaterialIndicatorsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaterialIndicators, GetMaterialIndicatorsVariables>(GetMaterialIndicatorsDocument, options);
      }
export function useGetMaterialIndicatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaterialIndicators, GetMaterialIndicatorsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaterialIndicators, GetMaterialIndicatorsVariables>(GetMaterialIndicatorsDocument, options);
        }
export type GetMaterialIndicatorsHookResult = ReturnType<typeof useGetMaterialIndicators>;
export type GetMaterialIndicatorsLazyQueryHookResult = ReturnType<typeof useGetMaterialIndicatorsLazyQuery>;
export type GetMaterialIndicatorsQueryResult = Apollo.QueryResult<GetMaterialIndicators, GetMaterialIndicatorsVariables>;