import { UserError } from '__generated__/types';
import { getFormSubmitError } from './form';

export const transformValidateErrors = (
  userErrors: UserError[],
  preffics: string,
) => {
  const errors = userErrors.map((error) =>
    error.field === 'base'
      ? error
      : {
          field: `${preffics}.${error.field}`,
          messages: error.messages,
        },
  );

  return getFormSubmitError(errors);
};
