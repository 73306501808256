import ApiService from './api-service';
import { StorageService } from 'lib/utils';
import { getFileUrlWithoutAuthMeta } from './file-helper';

interface UploadFileToS3Args {
  apiUrl: string;
  file: File;
}

export function getSignedFileUrl(apiUrl: string) {
  const token = StorageService.getAccessToken();

  return ApiService.getRequest(apiUrl, {
    headers: { Authorization: `Bearer ${token}` },
  }).catch(() => {
    throw new Error('Signing file Error');
  });
}

export function uploadFileToS3({ apiUrl, file }: UploadFileToS3Args) {
  return getSignedFileUrl(apiUrl).then(({ data: signedUrl }) =>
    ApiService.putRequest(signedUrl, file).then(() =>
      getFileUrlWithoutAuthMeta(signedUrl),
    ),
  );
}
