import React, { useState } from 'react';
import styled from 'styled-components';
import { useDebounceCallback } from '@react-hook/debounce';
import { NetworkStatus } from '@apollo/client';

import { getChatById, isAdminChat } from './utils';
import { getSize } from 'lib/utils';
import { useUser } from 'common/query/__generated__/get-user';
import { MAX_VISIBLE_CHATS } from './hooks/use-chat-list-logic';
import {
  useChatSubscriptions,
  useReadChatMessages,
  useChatListLogic,
  useVideoCallSessionInChat,
  useStartNewChat,
  useChatMessagesLogic,
} from './hooks';

import {
  Dialog,
  DialogHeader,
  MessageSendForm,
  SideBar,
  WriteMessageModal,
  EmptyBlock,
  DeleteMessagesModal,
} from './components';
import { Loader, ShadowWrapper } from 'ui';
import { FilesPreviewModal } from 'ui/files-preview-modal';

export interface ChatProps {
  isTherapist?: boolean;
}

export const ADMIN_ID = 'admin_chat_bot';

function Chat({ isTherapist }: ChatProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const userQuery = useUser();
  const userId = userQuery.data?.me?.id;

  const { readChatMessages } = useReadChatMessages();

  const {
    selectedChatId,
    chatsList,
    selectedChat,
    chatsListQuery,
    currentUnreadCount,
    setCurrentUnreadCount,
    setSelectedChat,
    participantsCountOfSelectedChat,
  } = useChatListLogic({ isTherapist, readChatMessages });

  const {
    onVideoCallClick,
    isNearestSessionsLoading,
    hasVideoCallButtonVisible,
    checkEmptySessionInProgress,
  } = useVideoCallSessionInChat(selectedChatId);

  const {
    isWriteMessageModalVisible,
    idOfUserWithNewChat,
    setSearchInterlocutorString,
    isStartNewTherapistChatLoading,
    interlocutorForStartNewChat,
    onWriteMessageClick,
    onParticipantsClick,
    startNewChat,
    onWriteMessageModalClose,
    usersForStartNewChatQuery,
    groupChatParticipantsToShow,
  } = useStartNewChat({
    userId,
    selectedChat,
    onChatClick: handleChatClick,
  });

  const {
    onSearchMessageFormSubmit,
    messagesSearchPhrase,
    chatMessagesQuery,
    chatMessages,
    isMessagesSearchActive,
    fetchMessages,
    isFetchingNewMessages,
    messageListRef,
    onSendFormSubmit,
    attachmentForFullscreen,
    onMessageClick,
    isGroupSelectedChat,
    onAttachmentClick,
    onSearchCancelClick,
    openMessagesSearch,
    clearAttachmentForFullscreen,
    refetchMessagesByChatId,
    onSelectMessageForDeleting,
    onResetSelectedMessagesForDeleting,
    selectedMessagesForDeleting,
    onCloseDeleteMessageModal,
    onDeleteSelectedMessages,
    onOpenDeleteMessageModal,
    isOpenDeleteMessageModal,
  } = useChatMessagesLogic({
    firstChatId: chatsList[0]?.id,
    selectedChatId,
    participantsCountOfSelectedChat,
  });

  useChatSubscriptions({
    messageListRef,
    selectedChatId,
    setCurrentUnreadCount,
    readChatMessages,
    userId,
    onChatClick: handleChatClick,
  });

  function handleChatClick(chatId: string) {
    const newChat = getChatById(chatsList, chatId);

    checkEmptySessionInProgress();
    onResetSelectedMessagesForDeleting();

    if (newChat) {
      if (isMessagesSearchActive) {
        onSearchCancelClick();
      } else if (chatMessagesQuery.data?.chatMessages.pageInfo.hasNextPage) {
        refetchMessagesByChatId(selectedChatId);
      }

      if (selectedChat?.unreadCount) {
        readChatMessages({ chatId: selectedChatId });
      }

      setCurrentUnreadCount(newChat.unreadCount);
      setSelectedChat(chatId);
    }
  }

  const hasTextForSearchMessages = Boolean(messagesSearchPhrase);
  const debounceOnSearchMessageFormSubmit = useDebounceCallback(
    onSearchMessageFormSubmit,
    250,
  );

  function renderDialogHeader() {
    if (!chatMessagesQuery.data) {
      if (
        chatMessagesQuery.loading ||
        chatsListQuery.loading ||
        isNearestSessionsLoading
      ) {
        return <LoaderStylized size={50} hasFillWholeBlock />;
      } else if (chatMessagesQuery.called) {
        return <NoChatsText>No chats yet</NoChatsText>;
      }
      return;
    }

    if (chatMessages) {
      return (
        <>
          <DialogHeader
            selectedChat={selectedChat}
            isExpanded={isExpanded}
            hasVideoCallButtonVisible={hasVideoCallButtonVisible}
            onVideoCallClick={onVideoCallClick}
            onSearchCancelClick={onSearchCancelClick}
            onSearchFormSubmit={debounceOnSearchMessageFormSubmit}
            isSearchActive={isMessagesSearchActive}
            onSearchTriggerClick={openMessagesSearch}
            onParticipantsClick={onParticipantsClick}
            participantsCount={
              isGroupSelectedChat ? participantsCountOfSelectedChat : undefined
            }
          />
          {hasTextForSearchMessages && chatMessages.length === 0 && (
            <EmptyBlock text="No posts found for this request." />
          )}
        </>
      );
    } else if (!selectedChatId) {
      return <EmptyBlock text="Start a dialogue, select chat" />;
    }
  }

  const isSpecialAdminTextVisible =
    isAdminChat(selectedChat) &&
    chatMessages.length &&
    chatMessages[0].node.sender.id !== ADMIN_ID;

  return (
    <ChatWrapper>
      <SideBar
        onWriteMessageClick={onWriteMessageClick}
        isTherapist={isTherapist}
        chats={chatsList}
        fetchMoreChats={() =>
          chatsListQuery.fetchMore({
            variables: {
              limit: MAX_VISIBLE_CHATS,
              after: chatsListQuery.data?.listChats.pageInfo.endCursor,
            },
          })
        }
        isFetchingNewChats={
          chatsListQuery.networkStatus === NetworkStatus.fetchMore
        }
        hasMoreChats={chatsListQuery.data?.listChats.pageInfo.hasNextPage}
        selectedChatId={selectedChatId}
        isExpanded={isExpanded}
        isChatsLoadaing={chatsListQuery.networkStatus === NetworkStatus.loading}
        onChatClick={handleChatClick}
        onExpandClick={() => setIsExpanded((prevValue) => !prevValue)}
        userId={userId}
      />

      <MessagesWrapper>
        {renderDialogHeader()}

        {!!selectedChat && (
          <>
            <Dialog
              messageListRef={messageListRef}
              messages={chatMessages}
              userId={userId}
              isTherapist={isTherapist}
              isSideBarExpanded={isExpanded}
              isFetchingNewMessages={isFetchingNewMessages}
              hasPreviousPage={
                chatMessagesQuery.data?.chatMessages.pageInfo.hasPreviousPage
              }
              hasNextPage={
                chatMessagesQuery.data?.chatMessages.pageInfo.hasNextPage
              }
              fetchPreviousMessages={() => fetchMessages(0, false)}
              fetchNextMessages={(oldPosition?: number) =>
                fetchMessages(oldPosition, true)
              }
              unreadMessagesCount={currentUnreadCount}
              readChat={() => readChatMessages({ chatId: selectedChatId })}
              hasTextForSearchMessages={hasTextForSearchMessages}
              onMessageClick={onMessageClick}
              isGroupSelectedChat={isGroupSelectedChat}
              onAttachmentClick={onAttachmentClick}
              isMessagesLoading={
                selectedChatId !== chatMessages?.[0]?.node.chatId
              }
              onSelectMessageForDeleting={onSelectMessageForDeleting}
              selectedMessagesForDeleting={selectedMessagesForDeleting}
              onResetSelectedMessagesForDeleting={
                onResetSelectedMessagesForDeleting
              }
              onOpenDeleteMessageModal={onOpenDeleteMessageModal}
            />

            {!!isSpecialAdminTextVisible && (
              <SpecialAdminText>
                Thanks for getting in touch! We will respond within the next 48
                hours.
              </SpecialAdminText>
            )}
          </>
        )}

        {!isMessagesSearchActive && Number(chatMessages?.length) >= 0 && (
          <MessageSendForm
            onSubmit={onSendFormSubmit}
            isExpanded={isExpanded}
            selectedChatId={selectedChat?.id}
          />
        )}
      </MessagesWrapper>

      {!!isTherapist && (
        <WriteMessageModal
          isVisible={isWriteMessageModalVisible}
          onClose={onWriteMessageModalClose}
          onFormSubmit={setSearchInterlocutorString}
          interlocutors={interlocutorForStartNewChat}
          onWriteClick={startNewChat}
          isStartNewTherapistChatLoading={isStartNewTherapistChatLoading}
          isLoadingInterlocutor={usersForStartNewChatQuery.loading}
          idOfUserWithNewChat={idOfUserWithNewChat}
          isGroupParticipants={Boolean(groupChatParticipantsToShow)}
          userId={userId}
        />
      )}

      <FilesPreviewModal
        isVisible={Boolean(attachmentForFullscreen)}
        file={attachmentForFullscreen}
        onClose={clearAttachmentForFullscreen}
      />
      <DeleteMessagesModal
        isOpen={isOpenDeleteMessageModal}
        onClose={onCloseDeleteMessageModal}
        onDeleteMessages={onDeleteSelectedMessages}
      />
    </ChatWrapper>
  );
}

const ChatWrapper = styled(ShadowWrapper)`
  flex-grow: 1;
  display: flex;
  padding: 0;
  overflow: hidden;
`;

const LoaderStylized = styled(Loader)`
  margin: auto;
`;

const NoChatsText = styled.p`
  margin: auto;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--gray7);
`;

const MessagesWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: solid #e8e8e8;
  border-width: 1px 1px 1px 0;
  border-radius: 0 8px 8px 0;
`;

const SpecialAdminText = styled.p`
  margin: 0 0 ${getSize(28)};
  text-align: center;
  font-weight: 400;
  font-size: ${getSize(11)};
  line-height: ${getSize(18)};
  color: var(--gray7);
`;

export default Chat;
