import React from 'react';
import styled from 'styled-components';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { getSize } from 'lib/utils';
import { Button, NewSearchIcon, Search as SearchInput } from 'ui';

export interface SearchProps {
  isActive: boolean;
  onTriggerClick: () => void;
  onCancelClick: () => void;
  onFormSubmit: (search?: string) => void;
}

function Search({
  isActive,
  onTriggerClick,
  onCancelClick,
  onFormSubmit,
}: SearchProps) {
  return (
    <Wrapper $isActive={isActive}>
      {isActive ? (
        <>
          <FinalForm
            onSubmit={() => {}}
            render={() => (
              <>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values: { search } }) => onFormSubmit(search)}
                />
                <Field
                  name="search"
                  component={Input}
                  placeholder="Search by keywords"
                />
              </>
            )}
          />

          <CancelButton onClick={onCancelClick} theme="secondary">
            Cancel
          </CancelButton>
        </>
      ) : (
        <TriggerButton onClick={onTriggerClick}>
          <Icon className="search-icon" />
          <EmptySpace />
        </TriggerButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $isActive: boolean }>`
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ $isActive }) => ($isActive ? '100%' : getSize(24))};
  transform: translateY(-50%);
  transition: width 0.3s linear;
  ${({ $isActive }) => $isActive && 'background: white;'}
  overflow: hidden;
`;

const Input = styled(SearchInput)`
  flex-grow: 1;
  margin: 0 ${getSize(8)} 0 0;

  background: none;
  border: 1px solid #e9e9e9;
`;

const TriggerButton = styled.button`
  margin: 0 0 0 auto;
  display: flex;

  &:hover {
    .search-icon {
      stroke: var(--purple2);
    }
  }
`;

const Icon = styled(NewSearchIcon)`
  transition: 0.3s ease-out;
`;

const CancelButton = styled(Button)`
  min-width: ${getSize(67)};
  font-size: ${getSize(12)};
`;

const EmptySpace = styled.div`
  width: 13px;
`;

export default Search;
