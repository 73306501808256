import React from 'react';

function RefundIcon({ ...rest }) {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      fill="#444752"
      viewBox="0 0 24 18"
    >
      <path d="M23.174 0H2.189a.826.826 0 000 1.652h20.159v14.582H4.75a3.102 3.102 0 01-3.098-3.098c0-1.359.88-2.515 2.1-2.932l-.594.847A.826.826 0 104.512 12l1.735-2.478a.826.826 0 00-.203-1.151L3.566 6.635a.826.826 0 10-.948 1.353l.83.58A4.758 4.758 0 000 13.137c0 2.62 2.131 4.75 4.75 4.75h18.424c.456 0 .826-.37.826-.826V.826A.826.826 0 0023.174 0z"></path>
      <path d="M11.206 10.982a.826.826 0 00-1.653 0c0 1.351 1.163 2.486 2.721 2.788v.8a.826.826 0 001.653 0v-.8c1.558-.302 2.72-1.437 2.72-2.787 0-1.351-1.162-2.486-2.72-2.788V5.817c.62.205 1.068.624 1.068 1.084a.826.826 0 001.652 0c0-1.35-1.162-2.486-2.72-2.787v-.8a.826.826 0 00-1.653 0v.8c-1.558.301-2.72 1.436-2.72 2.787s1.162 2.486 2.72 2.788v2.377c-.621-.204-1.068-.624-1.068-1.084zm3.79 0c0 .46-.448.88-1.07 1.084V9.9c.622.204 1.07.624 1.07 1.084zm-3.79-4.08c0-.46.447-.88 1.068-1.085v2.168c-.621-.205-1.068-.624-1.068-1.084z"></path>
    </svg>
  );
}

export default RefundIcon;
