import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSessionPriceVariables = Types.Exact<{
  priceType: Types.SessionPriceType;
}>;


export type GetSessionPrice = (
  { __typename: 'Query' }
  & { getSessionPrice: (
    { __typename: 'SessionPriceModel' }
    & Pick<Types.SessionPriceModel, 'id' | 'priceType' | 'thirtyMin' | 'fiftyMin' | 'eightyMin'>
  ) }
);


export const GetSessionPriceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSessionPrice"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"priceType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SessionPriceType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSessionPrice"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"priceType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"priceType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"priceType"}},{"kind":"Field","name":{"kind":"Name","value":"thirtyMin"}},{"kind":"Field","name":{"kind":"Name","value":"fiftyMin"}},{"kind":"Field","name":{"kind":"Name","value":"eightyMin"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetSessionPrice__
 *
 * To run a query within a React component, call `useGetSessionPrice` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionPrice` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionPrice({
 *   variables: {
 *      priceType: // value for 'priceType'
 *   },
 * });
 */
export function useGetSessionPrice(baseOptions: Apollo.QueryHookOptions<GetSessionPrice, GetSessionPriceVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionPrice, GetSessionPriceVariables>(GetSessionPriceDocument, options);
      }
export function useGetSessionPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionPrice, GetSessionPriceVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionPrice, GetSessionPriceVariables>(GetSessionPriceDocument, options);
        }
export type GetSessionPriceHookResult = ReturnType<typeof useGetSessionPrice>;
export type GetSessionPriceLazyQueryHookResult = ReturnType<typeof useGetSessionPriceLazyQuery>;
export type GetSessionPriceQueryResult = Apollo.QueryResult<GetSessionPrice, GetSessionPriceVariables>;