import React from 'react';

function FourDots({ ...rest }) {
  return (
    <svg width={33} height={6} viewBox="0 0 33 6" fill="#444752" {...rest}>
      <g clipPath="url(#prefix__clip0)">
        <circle cx={3} cy={3} r={2.5} />
        <circle cx={12} cy={3} r={2.5} />
        <circle cx={21} cy={3} r={2.5} />
        <circle cx={30} cy={3} r={2.5} />
      </g>
    </svg>
  );
}

export default FourDots;
