export interface StringsDiff {
  noDiff: string[];
  added: string[];
  removed: string[];
}

export function getStringArrayDiff(newStrings: string[], oldStrings: string[]) {
  const diff = newStrings.reduce(
    (acc: StringsDiff, string) => {
      oldStrings.includes(string)
        ? acc.noDiff.push(string)
        : acc.added.push(string);

      return acc;
    },
    { added: [], noDiff: [], removed: [] },
  );

  diff.removed = oldStrings.reduce((acc: string[], string) => {
    const isStringRemoved = !diff.noDiff.includes(string);
    if (isStringRemoved) acc.push(string);
    return acc;
  }, []);

  return diff;
}

export function getStringFromEnum(enumString?: string | null) {
  return enumString
    ? enumString[0].toUpperCase() +
        enumString.slice(1).toLowerCase().replaceAll('_', ' ')
    : '';
}

export function getShortString(string: string, maxSymbols: number) {
  return string.length > maxSymbols
    ? string.slice(0, maxSymbols - 3) + '...'
    : string;
}

export function transformLongStringToShortWithDots(
  text: string,
  symbolNumber: number,
) {
  return text.length > symbolNumber
    ? `${text.slice(0, symbolNumber)}...`
    : text;
}

export const removeSlashAfter = (target: string) => {
  return target[target.length - 1].includes('/') ? target.slice(0, -1) : target;
};

export const addSlashBefore = (target: string) => {
  return target[0].includes('/') ? target : `/${target}`;
};

export const slashAgnostic = (leftPart: string, rightPart: string) => {
  return `${removeSlashAfter(leftPart)}${addSlashBefore(rightPart)}`;
};
