import { getSize } from 'lib/utils';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { SaveContentBadge } from '../../components/save-content-badge';
import { ReportAbusePopup } from '../../report-abuse-popup';
import { TeamsAppContext } from '../../../../../hooks/use-teams-app';

/**
 * @description Component will render video content.
 */
const VideoContent = (props: any) => {
  const { id, title, author, videoUrl } = props;
  const isTeamsApp = useContext(TeamsAppContext);

  return (
    <Container>
      <Title>{title}</Title>
      <Author>{author}</Author>
      {/* <SaveContentBadge /> */}
      <VideoContainer>
        <Video controls src={videoUrl} controlsList="nodownload"></Video>
      </VideoContainer>
      {isTeamsApp && <ReportAbusePopup postId={id} />}
    </Container>
  );
};

const Container = styled.div`
  padding-top: ${getSize(30)};
  max-width: ${getSize(700)};
`;

const Title = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
`;

const Author = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
`;

const VideoContainer = styled.div`
  margin-top: ${getSize(15)};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  max-height: ${getSize(449)};
  margin: 0 auto;
  border-radius: ${getSize(8)};
  object-fit: contain;
  background: linear-gradient(
    180deg,
    rgba(235, 231, 255, 0.19) 0%,
    #ebe7ff 100%
  );
`;

const ReportResourcesContainer = styled.div`
  margin-top: 10px;
  border-top: 2px solid #d9d9d9;
`;

export default VideoContent;
