import { capitalizeFirstLetter } from './capitalize-first-letter';
import { TherapistPreview } from '../../features/find-specialist/search/query/__generated__/get-therapist-search';

export const getSessionTypesDescription = (therapist: TherapistPreview) => {
  const sessionTypesArray =
    therapist?.nearestAvailabilities[0]?.sessionType || therapist.sessionTypes;
  if (sessionTypesArray.length === 1) {
    return capitalizeFirstLetter(sessionTypesArray[0]);
  }

  if (sessionTypesArray.length === 2) {
    return (
      capitalizeFirstLetter(sessionTypesArray[0]) +
      ' or ' +
      capitalizeFirstLetter(sessionTypesArray[1])
    );
  }

  if (sessionTypesArray.length === 3) {
    return (
      capitalizeFirstLetter(sessionTypesArray[0]) +
      ', ' +
      capitalizeFirstLetter(sessionTypesArray[1]) +
      ' or ' +
      capitalizeFirstLetter(sessionTypesArray[2])
    );
  }
};
