import * as Types from '../../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientRescheduleSessionVariables = Types.Exact<{
  input: Types.SessionRequestRescheduleInput;
}>;


export type PatientRescheduleSession = (
  { __typename: 'Mutation' }
  & { patientSessionRequestReschedule: (
    { __typename: 'SessionRequestCommonPayload' }
    & { userErrors: Array<(
      { __typename: 'ValidationError' }
      & Pick<Types.ValidationError, 'field' | 'messages'>
    )> }
  ) }
);


export const PatientRescheduleSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PatientRescheduleSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SessionRequestRescheduleInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientSessionRequestReschedule"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode;
export type PatientRescheduleSessionMutationFn = Apollo.MutationFunction<PatientRescheduleSession, PatientRescheduleSessionVariables>;

/**
 * __usePatientRescheduleSession__
 *
 * To run a mutation, you first call `usePatientRescheduleSession` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientRescheduleSession` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientRescheduleSession, { data, loading, error }] = usePatientRescheduleSession({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientRescheduleSession(baseOptions?: Apollo.MutationHookOptions<PatientRescheduleSession, PatientRescheduleSessionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientRescheduleSession, PatientRescheduleSessionVariables>(PatientRescheduleSessionDocument, options);
      }
export type PatientRescheduleSessionHookResult = ReturnType<typeof usePatientRescheduleSession>;
export type PatientRescheduleSessionMutationResult = Apollo.MutationResult<PatientRescheduleSession>;
export type PatientRescheduleSessionMutationOptions = Apollo.BaseMutationOptions<PatientRescheduleSession, PatientRescheduleSessionVariables>;