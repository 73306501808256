import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

// Layout for page
import { ResourcesLayout } from '../layout';

// Import custom components
import Content from './content';
import { SubPaths } from '../components/sub-paths';
import { TherapyBanner } from '../components/therapy-banner';

// Queries
import { useGetLatestPosts } from '../query/__generated__/get-latest-posts';
import { useGetPostsByType } from '../query/__generated__/get-posts-by-type';

// Constants
import { ResourcesPostTypeEnum } from '__generated__/types';
import { SlackOnboardingBanner } from 'components/slack-onboarding';

const subPaths = ['featured', 'videos', 'articles'];

const Discover = () => {
  /** Init */
  let posts: any[] = [];

  /** Get what contet do we server */
  const { subPath } = useParams<{ subPath: string }>();

  const { data: postsData, loading } = useGetPostsByType({
    variables: {
      type:
        subPath === 'videos'
          ? ResourcesPostTypeEnum.Video
          : ResourcesPostTypeEnum.Text,
    },
    skip: subPath === 'featured',
  });
  const { data: latestPostData, loading: latestPostsLoading } =
    useGetLatestPosts({
      skip: subPath !== 'featured',
    });

  if (subPath === 'featured') {
    posts = latestPostData?.getLatestPosts || [];
  } else if (subPath === 'videos' || subPath === 'articles') {
    posts = postsData?.getPostsByType || [];
  }

  return (
    <ResourcesLayout>
      <SlackOnboardingBanner />
      <TherapyBanner />
      <Description>Get the latest advice you need from our experts</Description>

      {/* Tab selector for content type and recomendations */}
      <div className="row">
        <SubPaths paths={subPaths} activeSubPath={subPath} />
      </div>

      {/* Content */}
      <Content posts={posts} isLoading={loading || latestPostsLoading} />
    </ResourcesLayout>
  );
};

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 20px;
`;

export default Discover;
