import React, { SVGAttributes } from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

function ArrowRightShortIcon({ color, ...rest }: ArrowRightShortIconProps) {
  return (
    <Icon
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.380199 4.16668H7.42819L4.44069 1.17918L5.61987 0L10.6199 5L5.61987 10L4.44069 8.82082L7.42819 5.83332H0.380199V4.16668Z"
        fill={color || '#6B4EE6'}
      />
    </Icon>
  );
}

type ArrowRightShortIconProps = SVGAttributes<HTMLOrSVGElement>;

const Icon = styled.svg`
  width: ${getSize(11)};
  height: ${getSize(10)};
`;

export default ArrowRightShortIcon;
