import React from 'react';

function Mp3Icon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      {...rest}
    >
      <path
        d="M19.122 1.003a.438.438 0 00-.31-.128H6.563A3.066 3.066 0 003.5 3.938v20.125a3.066 3.066 0 003.063 3.062h14.875a3.066 3.066 0 003.062-3.063v-17.5a.437.437 0 00-.128-.309l-5.25-5.25zm.128 1.366l3.756 3.756h-1.569a2.19 2.19 0 01-2.187-2.188V2.37zM6.562 1.75h11.813v2.188A3.066 3.066 0 0021.438 7h2.187v12.25H4.375V3.937A2.19 2.19 0 016.563 1.75zm14.875 24.5H6.563a2.19 2.19 0 01-2.187-2.188v-3.937h19.25v3.938a2.19 2.19 0 01-2.188 2.187z"
        fill="#7A60E9"
      />
      <path
        d="M11.813 21a.438.438 0 00-.31.128L10.5 22.13l-1.003-1.003a.438.438 0 00-.747.309v3.5a.438.438 0 00.875 0v-2.444l.566.566a.439.439 0 00.618 0l.566-.566v2.444a.438.438 0 00.875 0v-3.5a.437.437 0 00-.438-.438zM14.438 21h-.876a.438.438 0 00-.437.438v3.5a.438.438 0 00.875 0v-1.313h.438a1.313 1.313 0 000-2.625zm0 1.75H14v-.875h.438a.438.438 0 010 .875zM17.938 21h-.875a.438.438 0 000 .875h.875a.438.438 0 010 .875h-.875a.438.438 0 000 .875h.875a.438.438 0 010 .875h-.875a.438.438 0 000 .875h.875a1.312 1.312 0 00.977-2.188A1.312 1.312 0 0017.938 21zM10.938 16.625a.438.438 0 00.437-.438V8.313a.438.438 0 00-.875 0v7.876a.438.438 0 00.438.437zM14.438 14a.438.438 0 00.437-.438v-2.624a.438.438 0 00-.875 0v2.624a.438.438 0 00.438.438zM9.188 14.875a.438.438 0 00.437-.438v-4.374a.437.437 0 10-.875 0v4.374a.438.438 0 00.438.438zM12.688 14.875a.438.438 0 00.437-.438v-4.374a.438.438 0 00-.875 0v4.374a.438.438 0 00.438.438zM17.938 16.625a.438.438 0 00.437-.438V8.313a.438.438 0 00-.875 0v7.876a.438.438 0 00.438.437zM19.688 14.875a.438.438 0 00.437-.438v-4.374a.438.438 0 00-.875 0v4.374a.438.438 0 00.438.438zM16.188 14.875a.438.438 0 00.437-.438v-4.374a.438.438 0 00-.875 0v4.374a.438.438 0 00.438.438zM7.438 13.125a.438.438 0 00.437-.438v-.874a.438.438 0 00-.875 0v.874a.438.438 0 00.438.438zM21.438 13.125a.438.438 0 00.437-.438v-.874a.438.438 0 00-.875 0v.874a.438.438 0 00.438.438z"
        fill="#7A60E9"
      />
    </svg>
  );
}

export default Mp3Icon;
