import { createContext } from 'react';
import StorageService from '../lib/utils/storage-service';
//TODO add explanation why we need this
export const TeamsAppContext = createContext(
  (() => {
    let isTeamsApp = !!StorageService.getIsTeamsApp();

    if (!isTeamsApp) {
      const queryParams = new URLSearchParams(window.location.search);
      isTeamsApp = !!queryParams.get('teams_app');

      if (isTeamsApp) {
        StorageService.setIsTeamsApp(isTeamsApp);
      }
    }

    return isTeamsApp;
  })(),
);
