import { TimeUtil } from 'lib/utils';
import { useMemo } from 'react';

const LOCAL_TIMEZONE = TimeUtil.getClientTimezone();

export const useBookingHomeDefaultValues = ({
  clientType,
}: UseBookingHomeDefaultValuesParams) => {
  const defaultValues = useMemo(() => {
    return {
      timezone: LOCAL_TIMEZONE,
      clientType: clientType,
      email: '',
    };
  }, [clientType]);

  return { defaultValues };
};

export interface UseBookingHomeDefaultValuesParams {
  clientType?: string;
}
