import React from 'react';
import styled from 'styled-components';
import { getSize } from 'lib/utils';
import { ArrowDownIcon } from 'ui/icons/arrow-down-icon';

export interface PaginationProps {
  className?: string;
  totalPagesCount: number;
  currentPageIndex: number;
  onPageNumberClick: (pageIndex: number) => void;
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
}

function Pagination({
  className,
  totalPagesCount,
  currentPageIndex,
  onPageNumberClick,
  onLeftArrowClick,
  onRightArrowClick,
}: PaginationProps) {
  if (totalPagesCount <= 1) return null;

  const isLeftButtonVisible = currentPageIndex > 0;
  const isRightArrowVisible = currentPageIndex < totalPagesCount - 1;
  const visiblePageNumbers = [
    1,
    2,
    currentPageIndex,
    currentPageIndex + 1,
    currentPageIndex + 2,
    totalPagesCount - 1,
    totalPagesCount,
  ];

  function renderPageNumberContent() {
    return [...Array(totalPagesCount)].map((_, index) => {
      const isActive = currentPageIndex === index;
      const isVisibleButton =
        totalPagesCount === 5 || visiblePageNumbers.includes(index + 1);
      const isVisibleDot =
        index === currentPageIndex - 2 || index === currentPageIndex + 2;

      if (isVisibleButton) {
        return (
          <PageNumberButton
            key={index}
            $isActive={isActive}
            disabled={isActive}
            onClick={() => onPageNumberClick(index)}
          >
            {index + 1}
          </PageNumberButton>
        );
      } else if (isVisibleDot) {
        return <Dot key={`dot-${index}`} />;
      } else {
        return null;
      }
    });
  }

  return (
    <Wrapper className={className}>
      <NavigationButton
        $isActive={isLeftButtonVisible}
        onClick={onLeftArrowClick}
        disabled={!isLeftButtonVisible}
      >
        <ArrowIconStylized $isVisible={isLeftButtonVisible} />
        <span className="visually-hidden">Previous</span>
      </NavigationButton>

      <Inner>{renderPageNumberContent()}</Inner>

      <NavigationButton
        $isRight
        $isActive={isRightArrowVisible}
        onClick={onRightArrowClick}
        disabled={!isRightArrowVisible}
      >
        <ArrowIconStylized $isVisible={isRightArrowVisible} $isRight />
        <span className="visually-hidden">Next</span>
      </NavigationButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const NavigationButton = styled.button<{
  $isActive: boolean;
  $isRight?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ $isRight }) =>
    $isRight ? `0 0 0 ${getSize(20)}` : `0 ${getSize(20)} 0 0`};
  width: ${getSize(50)};
  border-radius: ${getSize(4)};
  ${({ $isActive }) => $isActive && 'background: var(--purple11);'}
  background: var(--purple11);

  &:hover {
    svg {
      stroke: var(--purple);
    }
  }
`;

const ArrowIconStylized = styled(ArrowDownIcon)<{
  $isVisible: boolean;
  $isRight?: boolean;
}>`
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  transform: rotate(${({ $isRight }) => ($isRight ? '-' : '')}90deg);
  stroke: var(--black3);
  width: ${getSize(20)};
  height: ${getSize(20)};
`;

const Inner = styled.div`
  display: flex;
`;

const PageNumberButton = styled.button<{ $isActive: boolean }>`
  padding: ${getSize(8)};
  min-width: ${getSize(44)};
  font-weight: ${({ $isActive }) => ($isActive ? 600 : 400)};
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);
  border-radius: ${getSize(4)};
  background: var(
    ${({ $isActive }) => ($isActive ? '--purple5' : '--purple11')}
  );

  ${({ $isActive }) =>
    !$isActive &&
    `
  &:hover {
    background: var(--purple5);
  }
  `}

  &:not(:last-of-type) {
    margin-right: ${getSize(7)};
  }
`;

const Dot = styled.span`
  width: ${getSize(16)};

  &::before {
    content: '...';
    font-weight: 400;
    font-size: ${getSize(14)};
    line-height: 150%;
    color: var(--gray3);
  }
`;

export default Pagination;
