import { useEffect, useMemo, useContext } from 'react';
import { SessionType } from '__generated__/types';
import { useGetNearestSessions } from '../query/__generated__/get-nearest-sessions';
import { ISO_DATE_FORMAT } from 'lib/constants/date';
import { useDynamicTimeNow } from 'hooks';
import { TimeUtil } from 'lib/utils';
import { FeedbackContext, FeedbackContextValues } from 'hooks/use-feedback';
import { useVideoCallWindow } from 'hooks/use-video-call';

export function useVideoCallSessionInChat(selectedChatId: string) {
  const { timeNow } = useDynamicTimeNow();
  const { onSetIsSessionInProgress } =
    useContext<FeedbackContextValues>(FeedbackContext);
  const { showVideoCallWindow } = useVideoCallWindow();

  const { data: nearestSessionsData, loading: isNearestSessionsLoading } =
    useGetNearestSessions({ pollInterval: 1800000 });

  const nearestSession = nearestSessionsData?.nearestSessions;

  const sessionInProgress = useMemo(
    () =>
      nearestSession?.find(({ startDateUtc, endDateUtc }) => {
        const startSessionTime = TimeUtil.parse(
          startDateUtc,
          ISO_DATE_FORMAT,
        ).subtract(10, 'minute');
        const endSessionTime = TimeUtil.parse(endDateUtc, ISO_DATE_FORMAT);

        if (timeNow >= startSessionTime && timeNow <= endSessionTime) {
          return true;
        }
      }),
    [nearestSession, timeNow],
  );

  const startedVideoCallSession = useMemo(() => {
    if (sessionInProgress) {
      const { sessionType, patientConnected } = sessionInProgress;

      if (
        sessionType === SessionType.VideoCall &&
        patientConnected.chatId === selectedChatId
      ) {
        return sessionInProgress;
      }
    }
  }, [selectedChatId, sessionInProgress]);

  useEffect(() => {
    if (sessionInProgress) onSetIsSessionInProgress(true);
    return () => onSetIsSessionInProgress(false);
  }, [onSetIsSessionInProgress, sessionInProgress]);

  function handleVideoCallClick() {
    if (startedVideoCallSession) {
      const { id: sessionId, startDateUtc } = startedVideoCallSession;
      showVideoCallWindow({ sessionId, startDateEncoded: btoa(startDateUtc) });
    }
  }

  function checkEmptySessionInProgress() {
    if (!sessionInProgress) {
      onSetIsSessionInProgress(false);
    }
  }

  return {
    onVideoCallClick: handleVideoCallClick,
    isNearestSessionsLoading,
    checkEmptySessionInProgress,
    hasVideoCallButtonVisible: Boolean(startedVideoCallSession),
  };
}
