import { useMemo } from 'react';
import { sortOptionsByAlphaBet, transformToOptions } from 'lib/utils';
import { useTherapistClientTypes } from 'common/query/__generated__/get-therapist-client-types';

export function useTherapistClientTypesOptions() {
  const therapistClientTypesQuery = useTherapistClientTypes();
  return useMemo(
    () =>
      sortOptionsByAlphaBet(
        transformToOptions(
          therapistClientTypesQuery.data?.therapistClientTypes,
        ),
      ),
    [therapistClientTypesQuery],
  );
}
