import React from 'react';
import styled from 'styled-components';

import { getSize } from 'lib/utils';

import { SessionsSection, SectionWithTitle } from 'features/common';
import {
  SessionFragment,
  SessionRequestPreviewFragment,
} from '../../../query/__generated__/get-patient-upcoming-sessions';
import { Role } from '__generated__/types';

export interface SessionsSmallSectionProps {
  upcomingSessions: (SessionFragment | SessionRequestPreviewFragment)[];
  pastSessions: SessionFragment[];
  canceledSessions: SessionFragment[];
  showAllLink: string;
  onCancelClick?: (session: SessionFragment) => void;
  onStartClick?: (session: SessionFragment) => void;
  onCancelRequest?: (request: string) => void;
}

const SESSIONS_TITLE_CSS = { margin: `0 0 ${getSize(16)}` };

function SessionsSmallSection({
  upcomingSessions,
  pastSessions,
  canceledSessions,
  showAllLink,
  onCancelClick,
  onStartClick,
  onCancelRequest,
}: SessionsSmallSectionProps) {
  return (
    <Wrapper title="My sessions" titleCSS={SESSIONS_TITLE_CSS}>
      <Sessions
        role={Role.Patient}
        isSmall
        upcomingSessions={upcomingSessions}
        pastSessions={pastSessions}
        canceledSessions={canceledSessions}
        showAllLink={showAllLink}
        onCancelClick={onCancelClick}
        onStartClick={onStartClick}
        onCancelRequest={onCancelRequest}
      />
    </Wrapper>
  );
}

const Wrapper = styled(SectionWithTitle)`
  grid-area: sessions;
  height: ${getSize(455)};
`;

const Sessions = styled(SessionsSection)`
  min-height: ${getSize(244)};
`;

export default SessionsSmallSection;
