import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Field, Form as FinalForm } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';

import {
  composeValidators,
  getSize,
  SignUpDataService,
  StorageService,
  validationRules,
} from 'lib/utils';
import { signUp, signIn, logout } from 'lib/auth';
import { notifyError } from 'lib/utils/notification';

import { Button, PasswordInput, Input } from 'ui';
import { Role } from '__generated__/types';
import { LABEL_CSS, EMAIL_INPUT_CSS } from '../sign-in/sign-in';
import { useValidateB2BRegistrationLazyQuery } from 'common/query/__generated__/get-validate-b2b-registration';
import { FormApi } from 'final-form';
import { PATIENT_ROUTES } from 'lib/routes';
import {
  TERMS_AND_CONDITIONAL_FOR_PRACTITIONERS,
  TERMS_AND_CONDITIONAL_FOR_CLIENT,
} from 'lib/constants/common';
import { AuthContentLayout } from 'layouts';
import { TeamsAppContext } from '../../../hooks/use-teams-app';

const ALREADY_EXIST_EMAIL_ERROR_CODE = 'invalid_signup';

const PRIVACY = 'https://www.iubenda.com/privacy-policy/29132364';
export const PATIENT_ROLE_TITLE = 'user';

const SignUpB2b = () => {
  const { role } = useParams<{ role?: string }>();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const isTeamsApp = useContext(TeamsAppContext);

  const history = useHistory();

  const currentRole =
    role === PATIENT_ROLE_TITLE ? Role.Patient.toLowerCase() : role;
  const isTherapist = currentRole === Role.Therapist.toLowerCase();

  const setRoleToSignUpDataService = useCallback(() => {
    SignUpDataService.setSignUpData(JSON.stringify({ role: currentRole }));
  }, [currentRole]);

  const formRef = useRef<FormApi<SignUpValues, Partial<SignUpValues>> | null>(
    null,
  );

  const accessToken = StorageService.getAccessToken();

  useEffect(() => {
    if (accessToken) {
      history.replace(PATIENT_ROUTES.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getValidateB2bRegistration] = useValidateB2BRegistrationLazyQuery({
    onError: (error) =>
      notifyError({
        text: error.message,
      }),
    onCompleted: () => {
      const formValues = formRef.current?.getState().values;
      if (!formValues?.companyKey) return;

      const requestSignUp = async ({
        firstName,
        lastName,
        email,
        password,
        companyKey,
      }: SignUpValues) => {
        const valuesWithTrim = {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.trim().toLowerCase(),
          password: password.trim(),
          companyKey: companyKey,
        };

        setIsDataLoading(true);
        return signUp({ ...valuesWithTrim, role: currentRole })
          .then(() => {
            setRoleToSignUpDataService();
            signIn(valuesWithTrim).catch((error) =>
              notifyError({ text: error?.description }),
            );
          })
          .catch((error) => {
            const isEmailAlreadyExist =
              error?.code === ALREADY_EXIST_EMAIL_ERROR_CODE;
            setIsDataLoading(false);

            if (isEmailAlreadyExist) {
              notifyError({ text: 'This email is already being used' });
              return { email: 'Already exist email' };
            } else {
              notifyError({
                text:
                  typeof error?.description === 'string'
                    ? error?.description
                    : error?.code,
              });
            }
          });
      };

      requestSignUp({
        firstName: formValues?.firstName || '',
        lastName: formValues?.lastName || '',
        email: formValues?.email || '',
        password: formValues?.password || '',
        companyKey: formValues?.companyKey || '',
      });
    },
  });

  const handelFormSubmit = ({ email, companyKey }: SignUpValues) => {
    getValidateB2bRegistration({
      variables: { email: email, companyKey: companyKey?.toString() },
    });
  };

  return (
    <AuthContentLayout title="Create your account">
      <FinalForm
        onSubmit={handelFormSubmit}
        render={({ handleSubmit, form }) => {
          formRef.current = form;
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <Field
                  name="firstName"
                  label="First name"
                  labelCSS={LABEL_CSS}
                  inputCSS={EMAIL_INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  component={Input}
                  validate={validationRules.required}
                  placeholder="Your first name"
                />
                <Field
                  name="lastName"
                  label="Last name"
                  labelCSS={LABEL_CSS}
                  inputCSS={EMAIL_INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  component={Input}
                  validate={validationRules.required}
                  placeholder="Your last name"
                />
                <Field
                  name="email"
                  label="Email"
                  labelCSS={LABEL_CSS}
                  inputCSS={EMAIL_INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  component={Input}
                  validate={composeValidators(
                    validationRules.required,
                    validationRules.email,
                  )}
                  placeholder="Your email"
                />
                <Field
                  name="password"
                  label="Password"
                  labelCSS={LABEL_CSS}
                  rootCSS={PASSWORD_ROOT_CSS}
                  component={PasswordInput}
                  validate={composeValidators(
                    validationRules.required,
                    validationRules.passStrength,
                  )}
                  placeholder="Enter password"
                  checkStrength={true}
                />

                <Field
                  name="companyKey"
                  label="AccessCode"
                  labelCSS={LABEL_CSS}
                  inputCSS={EMAIL_INPUT_CSS}
                  rootCSS={ROOT_CSS}
                  component={Input}
                  validate={composeValidators(
                    validationRules.required,
                    validationRules.minValue,
                  )}
                  placeholder="Enter organisation code"
                  type="number"
                  data-min="1"
                />

                <StyledButton isLoading={isDataLoading} theme="tertiary">
                  CREATE ACCOUNT
                </StyledButton>

                <PrivacyPolicy>
                  By clicking “Sign Up” I agree to the{' '}
                  <PrivacyPolicyLink
                    target="_blank"
                    href={
                      isTherapist
                        ? TERMS_AND_CONDITIONAL_FOR_PRACTITIONERS
                        : TERMS_AND_CONDITIONAL_FOR_CLIENT
                    }
                  >
                    Terms Of Service{' '}
                  </PrivacyPolicyLink>
                  and{' '}
                  <PrivacyPolicyLink target="_blank" href={PRIVACY}>
                    Privacy Policy
                  </PrivacyPolicyLink>
                </PrivacyPolicy>
              </Form>
            </>
          );
        }}
      />
    </AuthContentLayout>
  );
};

export interface SignUpValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyKey: string;
}

export const TITLE_CSS = {
  marginBottom: getSize(13),
  color: 'var(--black4)',
  fontSize: `${getSize(32)}`,
  lineHeight: `${getSize(37)}`,
};

export const INNER_CSS = {
  width: `${getSize(480)}`,
  padding: `${getSize(47)} ${getSize(50)} ${getSize(48)}`,
};

export const ROOT_CSS = {
  marginBottom: getSize(9),
};

export const PASSWORD_ROOT_CSS = {
  margin: `0 0 ${getSize(20)}`,
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 0 ${getSize(20)};
`;

const Text = styled.p`
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--gray2);
`;
const StyledButton = styled(Button)`
  margin-top: 49px;
`;

const PrivacyPolicy = styled(Text)`
  text-align: center;
  font-weight: 500;
  font-size: ${getSize(10)};
  line-height: ${getSize(18)};
  margin-top: ${getSize(15)};
`;

const PrivacyPolicyLink = styled.a`
  text-decoration: underline;
`;

export default SignUpB2b;
