import React from 'react';

const PuzzleIcon = () => {
  return (
    <svg
      width="57"
      height="40"
      viewBox="0 0 57 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.0781 23.4233C24.1528 23.4233 22.606 21.8306 22.7265 19.9123C22.833 18.2186 24.2718 16.8615 25.9975 16.8218C26.4392 16.8116 26.862 16.8853 27.2505 17.0279C27.8546 17.2496 28.5 16.8274 28.5 16.1935V6.1902H18.3232C17.6786 6.1902 17.2491 5.55605 17.4743 4.96234C17.6191 4.58035 17.694 4.16495 17.6835 3.73086C17.6425 2.03492 16.2623 0.621173 14.5399 0.516275C12.5884 0.397556 10.9681 1.91824 10.9681 3.81106C10.9681 4.22658 11.0464 4.62408 11.189 4.99021C11.415 5.57009 10.9608 6.1902 10.3292 6.1902H0.152466V34.0538H10.3293C10.9739 34.0538 11.4034 33.4196 11.1782 32.8259C11.0334 32.4439 10.9585 32.0285 10.9689 31.5944C11.01 29.8985 12.3901 28.4847 14.1125 28.3798C16.064 28.2611 17.6843 29.7818 17.6843 31.6746C17.6843 32.0901 17.6061 32.4876 17.4634 32.8538C17.2375 33.4337 17.6917 34.0538 18.3233 34.0538H28.5001V24.0511C28.5001 23.4298 27.8688 22.9831 27.2788 23.2056C26.906 23.3462 26.5013 23.4233 26.0781 23.4233Z"
        fill="#8DA0FF"
      />
      <path
        d="M56.8479 6.19141H46.6711C46.0265 6.19141 45.597 6.82555 45.8221 7.41937C45.9668 7.80125 46.0417 8.21654 46.0313 8.6504C45.9906 10.3463 44.6105 11.7603 42.8881 11.8653C40.9365 11.9843 39.3159 10.4635 39.3159 8.57065C39.3159 8.15514 39.3942 7.75763 39.5368 7.39151C39.7628 6.81162 39.3086 6.19152 38.677 6.19152H28.5003V14.2631V16.1947C28.5003 16.8286 27.8548 17.2507 27.2508 17.0291C26.8623 16.8866 26.44 16.8129 25.9986 16.8229C24.2731 16.8622 22.834 18.2187 22.7268 19.9121C22.6053 21.831 24.1525 23.4245 26.0783 23.4245C26.5014 23.4245 26.9061 23.3475 27.2788 23.2069C27.8689 22.9844 28.5003 23.4311 28.5003 24.0525V26.8825V34.0551H38.6771C39.3217 34.0551 39.7512 34.6892 39.526 35.283C39.3813 35.6647 39.3065 36.0799 39.3167 36.5135C39.3572 38.2093 40.7369 39.6234 42.4591 39.7289C44.411 39.8484 46.0321 38.3274 46.0321 36.4342C46.0321 36.0187 45.9539 35.6212 45.8112 35.2551C45.5853 34.6752 46.0395 34.0551 46.6711 34.0551H56.8479V6.19141Z"
        fill="#FFCB7C"
      />
    </svg>
  );
};

export default PuzzleIcon;
