import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { GenerateDownloadLinkService, getSize, Option } from 'lib/utils';

import { ShadowWrapper, Link } from 'ui';
import NoteItem, { Note } from '../note-item/note-item';
import { NotesButtons } from '../notes-buttons';
import { useWindowClick } from 'hooks';
import { NotesEmptyBlock } from '../notes-empty-block';
import { NoteModal } from '../note-modal';
import { NoteModalFormValues } from '../note-modal/note-modal';

export interface NotesSmallSectionProps {
  notes: Note[];
  className?: string;
  isTherapist?: boolean;
  showAllLink: string;
  onAddNoteSubmit: (values: NoteModalFormValues) => void;
  setIsVisibleNoteModal: (value: boolean) => void;
  isVisibleNoteModal: boolean;
  onDeleteAllClick: () => void;
  onDeleteClick: (id: string) => void;
  setNoteInitialValues: (values?: NoteModalFormValues) => void;
  noteInitialValues?: NoteModalFormValues;
  downloadAllLink: string;
  connectedTherapistsOptions?: Option[];
}

function NotesSmallSection({
  notes,
  className,
  isTherapist,
  showAllLink,
  isVisibleNoteModal,
  setIsVisibleNoteModal,
  onAddNoteSubmit,
  onDeleteAllClick,
  onDeleteClick,
  downloadAllLink,
  setNoteInitialValues,
  noteInitialValues,
  connectedTherapistsOptions,
}: NotesSmallSectionProps) {
  const currentNotes = useMemo(() => notes.slice(0, 3), [notes]);
  const isEmptyNotes = currentNotes.length === 0;
  const { getOnePatientNote, getOneTherapistNote } =
    GenerateDownloadLinkService;

  const onCloseNoteModal = useCallback(() => {
    setIsVisibleNoteModal(false);
    setNoteInitialValues(undefined);
  }, [setIsVisibleNoteModal, setNoteInitialValues]);
  return (
    <Wrapper
      className={className}
      $isTherapist={isTherapist}
      asElement="section"
    >
      <Header>
        <Title>{isTherapist ? 'Notes' : 'My notes'}</Title>
        <NotesButtons
          onAddClick={() => setIsVisibleNoteModal(true)}
          onDeleteClick={onDeleteAllClick}
          downloadLink={downloadAllLink}
          onlyAdd={isEmptyNotes}
          isTherapist={isTherapist}
          isHeaderButtons
        />
      </Header>

      {isEmptyNotes ? (
        <NotesEmptyBlock
          onAddClick={() => setIsVisibleNoteModal(true)}
          isSmall
        />
      ) : (
        <List>
          {currentNotes.map((note, index) => {
            const noteId = note.id;

            return (
              <NoteItemStylized
                key={noteId}
                note={note}
                isSmall
                asElement="div"
                hasShortText={index > 0}
                onDeleteClick={() => onDeleteClick(noteId)}
                downloadLink={
                  isTherapist
                    ? getOneTherapistNote(noteId)
                    : getOnePatientNote(noteId)
                }
                onEditClick={setNoteInitialValues}
                isTherapist={isTherapist}
              />
            );
          })}

          {notes.length > 1 && (
            <ShowAllLink to={showAllLink}>Show all</ShowAllLink>
          )}
        </List>
      )}
      <NoteModal
        isVisible={isVisibleNoteModal}
        onCloseNoteModal={onCloseNoteModal}
        onAddNoteSubmit={onAddNoteSubmit}
        isTherapist={isTherapist}
        initialValues={noteInitialValues}
        connectedTherapistsOptions={connectedTherapistsOptions}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ShadowWrapper)<{ $isTherapist?: boolean }>`
  ${({ $isTherapist }) =>
    $isTherapist
      ? `box-shadow: none;
     padding: 0;`
      : `
     display: flex;
  flex-direction: column;
  padding: ${getSize(32)} ${getSize(34)};
  `};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${getSize(30)};
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};
  color: var(--black3);
`;

const List = styled.div`
  height: 100%;
  display: grid;
  grid-template:
    'first second' auto
    'first third' auto
    'first show-all' auto / 1fr 1fr;
  grid-gap: ${getSize(16)} ${getSize(24)};
  margin: 0 0 ${getSize(14)};
`;

const NoteItemStylized = styled(NoteItem)`
  &:first-of-type {
    grid-area: first;
  }

  &:nth-of-type(2) {
    grid-area: second;
  }

  &:nth-of-type(3) {
    grid-area: third;
  }
`;

const ShowAllLink = styled(Link)`
  grid-area: show-all;
  justify-self: center;
  align-self: center;
  line-height: ${getSize(11)};
`;

export default NotesSmallSection;
