import React, { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { ShadowWrapper } from 'ui';

export interface SectionWithTitleProps {
  title: string;
  className?: string;
  children: ReactNode;
  titleCSS?: CSSProp;
  wrapperCSS?: CSSProp;
  isTherapist?: boolean;
}

function SectionWithTitle({
  children,
  className,
  title,
  titleCSS,
  wrapperCSS,
  isTherapist,
}: SectionWithTitleProps) {
  return (
    <Wrapper
      $CSS={wrapperCSS}
      className={className}
      $isTherapist={isTherapist}
      asElement="section"
    >
      <Title $titleCSS={titleCSS}>{title}</Title>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled(ShadowWrapper)<{
  $CSS?: CSSProp;
  $isTherapist?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: ${getSize(34)} ${getSize(32)};
  ${({ $isTherapist }) =>
    $isTherapist &&
    `box-shadow: none;
  padding: 0;`};

  ${({ $CSS }) => $CSS}
`;

const Title = styled.h2<{ $titleCSS?: CSSProp }>`
  font-weight: 600;
  font-size: ${getSize(22)};
  line-height: ${getSize(33)};

  ${({ $titleCSS }) => $titleCSS}
`;

export default SectionWithTitle;
