import React from 'react';
import { Field } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import { Checkbox } from 'ui';
import { getSize } from 'lib/utils';
import { CheckboxTheme } from '../checkbox/checkbox';
import { FieldValidator } from 'final-form';
import { Filters } from 'amplitude-analytics/enums';

export interface Option {
  value?: string | null;
  label?: string | null;
}
export interface CheckboxGroupProps {
  name: string;
  options: Option[];
  className?: string;
  theme?: CheckboxTheme;
  wrapCSS?: CSSProp;
  rootCSS?: CSSProp;
  checkboxCSS?: CSSProp;
  labelCSS?: CSSProp;
  innerCSS?: CSSProp;
  validate?: FieldValidator<any>;
  disabled?: boolean;
  filterType?: Filters;
  hasIndicator?: boolean;
  children?: React.ReactNode;
}

function CheckboxGroup({
  name,
  validate,
  options,
  className,
  theme,
  rootCSS,
  wrapCSS,
  disabled,
  filterType,
  checkboxCSS,
  labelCSS,
  innerCSS,
  hasIndicator,
  children,
}: CheckboxGroupProps) {
  return (
    <Wrapper $wrapCSS={wrapCSS} className={className}>
      {children}
      {options.map(({ value, label }) => (
        <Field
          key={value}
          name={name}
          value={value}
          label={label}
          type="checkbox"
          theme={theme}
          rootCSS={rootCSS}
          component={Checkbox}
          validate={validate}
          disabled={disabled}
          filterType={filterType}
          checkboxCSS={checkboxCSS}
          labelCSS={labelCSS}
          innerCSS={innerCSS}
          hasIndicator={hasIndicator}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $wrapCSS?: CSSProp }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSize(21)} 0;

  ${({ $wrapCSS }) => $wrapCSS}
`;

export default CheckboxGroup;
