import * as Types from '../../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTherapistSessionsNeededActionVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTherapistSessionsNeededAction = (
  { __typename: 'Query' }
  & { therapistSessionsNeededAction: Array<(
    { __typename: 'SessionMetaModel' }
    & TherapistSessionNeededAction
  )> }
);

export type TherapistSessionNeededAction = (
  { __typename: 'SessionMetaModel' }
  & Pick<Types.SessionMetaModel, 'id' | 'sessionType' | 'startDateUtc' | 'endDateUtc'>
  & { patientConnected: (
    { __typename: 'PatientConnectedModel' }
    & Pick<Types.PatientConnectedModel, 'id' | 'firstName' | 'lastName' | 'visitType'>
    & { avatar?: Types.Maybe<(
      { __typename: 'UserPublicFileModel' }
      & Pick<Types.UserPublicFileModel, 'source'>
    )> }
  ), session: (
    { __typename: 'SessionModel' }
    & Pick<Types.SessionModel, 'id'>
  ), clientType: (
    { __typename: 'TherapistClientTypeModel' }
    & Pick<Types.TherapistClientTypeModel, 'label'>
  ) }
);

export const TherapistSessionNeededAction = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TherapistSessionNeededAction"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SessionMetaModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sessionType"}},{"kind":"Field","name":{"kind":"Name","value":"startDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"endDateUtc"}},{"kind":"Field","name":{"kind":"Name","value":"patientConnected"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"visitType"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"source"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"session"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"clientType"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"label"}}]}}]}}]} as unknown as DocumentNode;
export const GetTherapistSessionsNeededActionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTherapistSessionsNeededAction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"therapistSessionsNeededAction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TherapistSessionNeededAction"}}]}}]}},...TherapistSessionNeededAction.definitions]} as unknown as DocumentNode;

/**
 * __useGetTherapistSessionsNeededAction__
 *
 * To run a query within a React component, call `useGetTherapistSessionsNeededAction` and pass it any options that fit your needs.
 * When your component renders, `useGetTherapistSessionsNeededAction` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTherapistSessionsNeededAction({
 *   variables: {
 *   },
 * });
 */
export function useGetTherapistSessionsNeededAction(baseOptions?: Apollo.QueryHookOptions<GetTherapistSessionsNeededAction, GetTherapistSessionsNeededActionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTherapistSessionsNeededAction, GetTherapistSessionsNeededActionVariables>(GetTherapistSessionsNeededActionDocument, options);
      }
export function useGetTherapistSessionsNeededActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTherapistSessionsNeededAction, GetTherapistSessionsNeededActionVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTherapistSessionsNeededAction, GetTherapistSessionsNeededActionVariables>(GetTherapistSessionsNeededActionDocument, options);
        }
export type GetTherapistSessionsNeededActionHookResult = ReturnType<typeof useGetTherapistSessionsNeededAction>;
export type GetTherapistSessionsNeededActionLazyQueryHookResult = ReturnType<typeof useGetTherapistSessionsNeededActionLazyQuery>;
export type GetTherapistSessionsNeededActionQueryResult = Apollo.QueryResult<GetTherapistSessionsNeededAction, GetTherapistSessionsNeededActionVariables>;