import React, { FC, useState, useRef, useMemo, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { SelectComponents } from 'react-select/src/components';
import styled, { css } from 'styled-components';
import StateManager, { components } from 'react-select';
import { notifyError, notifySuccess } from 'lib/utils/notification';
import { Button, DatePickerInput, Modal, ClockCutIcon, SingleSelect } from 'ui';
import { getNoticePeriodDate } from 'lib/utils';
import { Option } from 'ui/form-elements/single-select/single-select';
import { useTherapistAvailability } from 'common/query/__generated__/get-therapist-availability';
import { getAvailableTimesOptions } from 'lib/utils/get-available-times-options';
import { usePatientGetTherapistSetting } from 'common/query/__generated__/patient-get-therapist-setting';
import { Weekday } from 'ui/calendar-components';
import { customTimeAvailabilityStyles } from 'ui/form-elements/single-select/utils/styles';
import { getCurrentDayTimes } from 'components/booking-form/utils/get-current-day-times';
import { AvailabilitySessionType } from '__generated__/types';
import { getSize, TimeUtil } from 'lib/utils';
import { getTimeOptionsOfAvailabilityInMonth } from '../../../../../../components/booking-form/utils/get-time-options-of-availability-in-month';
import {
  FULL_YEAR_AND_MONTH,
  ISO_DATE_FORMAT,
  MONTH_DAY_FULLYEAR,
} from 'lib/constants/date';
import { Day } from 'components/booking-form/components/calendar/components';
import { BookingFormValues } from 'components/booking-form/booking-form';
import { usePatientRescheduleSession } from 'features/patient/dashboard/reschedule-session/mutation/__generated__/patient-reschedule-session';

interface RescheduleModalProps {
  isVisible?: boolean;
  onClose?: () => void;
  onRescheduleClick?: () => void;
  isLoading?: boolean;
  sessionId: string;
  startSessionTime: string;
  sessionType: string;
  therapistId: string;
  duration: number;
}

const DATE_INPUT_CSS = css`
  width: 100%;
  height: 100%;
  padding: 0;
  font-weight: 500;
  font-size: ${getSize(13)};
  line-height: ${getSize(24)};
  color: var(--black3);
  border: 0;

  &:not(:disabled) {
    cursor: pointer;
  }

  &::placeholder {
    color: var(--gray7);
  }
`;

const ROOT_SELECT_CSS = css`
  height: 100%;
`;

const DATEINPUT_ICON_CSS = css`
  width: ${getSize(20)};
  height: ${getSize(20)};
  right: ${getSize(12)};
`;

const DATEINPUT_ROOT_CSS = css`
  width: 140px;
`;

export const SESSION_DURATION_OPTIONS = [
  { label: '30 min', value: '30' },
  { label: '50 min', value: '50' },
  { label: '80 min', value: '80' },
];

const LOCAL_TIMEZONE = TimeUtil.getClientTimezone();

const customComponents: Partial<SelectComponents<Option, any>> = {
  Control: ({ children, ...rest }) => (
    <components.Control {...rest}>
      <StyledIcon /> {children}
    </components.Control>
  ),
  MenuList: ({ children, ...rest }) => (
    <components.MenuList {...rest}>
      <Suggestion>
        <TitleSuggestions>Select Time</TitleSuggestions>
        <TimeContainer>
          <OptionsWrapper>{children}</OptionsWrapper>
        </TimeContainer>
      </Suggestion>
    </components.MenuList>
  ),
};

const RescheduleModal: FC<RescheduleModalProps> = ({
  isVisible = false,
  onClose,
  onRescheduleClick,
  sessionId,
  startSessionTime,
  sessionType,
  therapistId,
  duration,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(TimeUtil.now().toDate());
  const [selectedDuration, setSelectedDuration] = useState(50);
  const [selectedDate, setSelectedDate] = useState(TimeUtil.getStartOFNowDay());
  const ref = useRef<StateManager<Option>>(null);

  const onDayClick = () => {
    ref.current?.focus();
    setIsFocused(true);
  };

  const startOfFromDate = useMemo(
    () =>
      TimeUtil.parse(selectedMonth.toISOString(), ISO_DATE_FORMAT).startOf(
        'month',
      ),
    [selectedMonth],
  );

  const startOfFromDateUTC = useMemo(
    () => startOfFromDate.subtract(1, 'day').toISOString(),
    [startOfFromDate],
  );

  const endOfToDateUTC = useMemo(
    () =>
      TimeUtil.parse(startOfFromDate.toISOString(), ISO_DATE_FORMAT)
        .add(1, 'month')
        .toISOString(),
    [startOfFromDate],
  );

  const { data: availabilityData } = useTherapistAvailability({
    variables: {
      therapistId,
      availabilityFilter: {
        startOfFromDateUTC,
        endOfToDateUTC,
      },
    },
  });

  const { data: therapistNoticeSettings } = usePatientGetTherapistSetting({
    variables: {
      id: therapistId,
    },
  });

  const availabilityIntervals =
    availabilityData?.therapistAvailability.availabilityIntervals;

  const availableDaysOptions = useMemo(() => {
    if (availabilityIntervals) {
      return availabilityIntervals.map((availability) => {
        return {
          startDateUtc: availability.startOfIntervalUtc,
          endDateUtc: availability.endOfIntervalUtc,
          timeOptions: getAvailableTimesOptions(
            availability.startOfIntervalUtc,
            availability.endOfIntervalUtc,
            selectedDuration,
            LOCAL_TIMEZONE,
          ),
          sessionType: availability.sessionType,
          officeLocation: availability.officeLocation,
        };
      });
    }

    return [];
  }, [selectedDuration, availabilityIntervals]);

  const settingsNoticePeriod = useMemo(() => {
    if (therapistNoticeSettings?.therapistPublicById.setting) {
      const {
        maxNoticePeriod,
        maxNoticePeriodType,
        minNoticePeriod,
        minNoticePeriodType,
      } = therapistNoticeSettings.therapistPublicById.setting;

      const minNoticePeriodDate = getNoticePeriodDate({
        noticePeriod: minNoticePeriod,
        noticePeriodType: minNoticePeriodType,
      });

      const maxNoticePeriodDate = getNoticePeriodDate({
        noticePeriod: maxNoticePeriod,
        noticePeriodType: maxNoticePeriodType,
      });

      return {
        minNoticePeriodDate,
        maxNoticePeriodDate,
      };
    }
    return {
      minNoticePeriodDate: null,
      maxNoticePeriodDate: null,
    };
  }, [therapistNoticeSettings]);

  const timeOptionsOfAvailabilityInMonth = useMemo(
    () =>
      getTimeOptionsOfAvailabilityInMonth({
        availableDays: availableDaysOptions,
        monthOfYear: TimeUtil.parse(
          selectedMonth.toISOString(),
          ISO_DATE_FORMAT,
        ).format(FULL_YEAR_AND_MONTH),
        sessionType: sessionType as AvailabilitySessionType,
        selectedTimezone: LOCAL_TIMEZONE,
        settingsNoticePeriod,
        selectedDuration,
      }),
    [
      selectedMonth,
      availableDaysOptions,
      settingsNoticePeriod,
      selectedDuration,
    ],
  );

  const renderDay = useCallback(
    (date: Date) => (
      <Day
        day={date}
        timeOfAvailabilityInMonth={timeOptionsOfAvailabilityInMonth}
      />
    ),
    [timeOptionsOfAvailabilityInMonth],
  );
  const [rescheduleSession, { loading }] = usePatientRescheduleSession({
    onError: ({ name, message }) => notifyError({ title: name, text: message }),
    update(cache) {
      cache.evict({
        fieldName: 'patientUpcomingSessions',
        broadcast: true,
      });
      cache.gc();
    },
  });
  const onSubmitReschedule = (values: BookingFormValues) => {
    const { selectedDay, timeUTC } = values;

    if (!!selectedDay && !!timeUTC) {
      rescheduleSession({
        variables: {
          input: {
            id: sessionId,
            startDateUtc: timeUTC,
          },
        },
      }).then(() => {
        notifySuccess({
          title: 'Request sent',
          text: 'Your session rescheduling request has been sent',
        });
        if (onClose) {
          onClose();
        }
      });
    }
  };

  return (
    <Modal title={`Reschedule Session`} isVisible={isVisible} onClose={onClose}>
      <Text>See next available slots</Text>
      <Container>
        <Form
          onSubmit={onSubmitReschedule}
          render={({ handleSubmit, values }) => {
            const currentDayTimes = getCurrentDayTimes(
              timeOptionsOfAvailabilityInMonth,
              values.selectedDay || new Date(),
            );
            return (
              <>
                <FieldsWrapper
                  $isFocused={isFocused}
                  onClick={() => {
                    setIsFocused(true);
                  }}
                >
                  <div onClick={(e) => e.stopPropagation()}>
                    <Field
                      name="selectedDay"
                      render={({ input, meta }) => (
                        <DatePickerInput
                          theme="secondary"
                          input={input}
                          meta={meta}
                          placeholder="Select date"
                          selectedDays={input.value}
                          onMonthChange={setSelectedMonth}
                          onDayClick={onDayClick}
                          timeOfAvailabilityInMonth={
                            timeOptionsOfAvailabilityInMonth
                          }
                          inputFormat={MONTH_DAY_FULLYEAR}
                          disabledPastDay
                          iconCSS={DATEINPUT_ICON_CSS}
                          rootCSS={DATEINPUT_ROOT_CSS}
                          hasBookingAvailability={true}
                          hasLine={false}
                          withCalendarIcon
                          inputCSS={DATE_INPUT_CSS}
                          weekdayElement={StyledWeekDay}
                          handleOuterWrapperFocus={(isInputFocused: boolean) =>
                            setIsFocused(isInputFocused)
                          }
                          renderDay={renderDay}
                          setSelectedDate={setSelectedDate}
                          selectedDate={selectedDate}
                        />
                      )}
                    />
                  </div>
                  <GridDelimiter />
                  <div onClick={(e) => e.stopPropagation()}>
                    <Field
                      name="timeUTC"
                      placeholder="Select Time"
                      innerRef={ref}
                      component={SingleSelect}
                      options={currentDayTimes}
                      customComponents={customComponents}
                      noOptionsMessage={() => 'No available time'}
                      rootCSS={ROOT_SELECT_CSS}
                      isSearchable={false}
                      customSelectStyles={customTimeAvailabilityStyles}
                      captureMenuScroll={false}
                      menuPlacement="top"
                      menuPosition="fixed"
                      selectRef={ref}
                      handleOuterWrapperFocus={(isInputFocused: boolean) =>
                        setIsFocused(isInputFocused)
                      }
                    />
                  </div>
                </FieldsWrapper>
                <ButtonContainer>
                  <SendButton
                    theme="primary"
                    onClick={handleSubmit}
                    isLoading={loading}
                    isDisabled={!values.timeUTC || !values.selectedDay}
                  >
                    Send Request
                  </SendButton>
                </ButtonContainer>
              </>
            );
          }}
        ></Form>
      </Container>
    </Modal>
  );
};

const Text = styled.span`
  font-size: ${getSize(11)};
  font-weight: 400;
  line-height: ${getSize(18)};
  margin-bottom: 22px;
`;
const SendButton = styled(Button)`
  margin-top: 28px;
  border-radius: 34px;
`;

const GridDelimiter = styled.div`
  width: 100%;
  height: 100%;
  background: var(--gray38);
  transition: 0.3s ease-out;
`;

const Container = styled.div`
  width: 100%;
  background-color: white;
  position: relative;
`;

const FieldsWrapper = styled.div<{ $isFocused?: boolean }>`
  width: 100%;
  height: 44px;
  cursor: pointer;
  border-radius: ${getSize(8)};
  border: 1px solid
    var(${({ $isFocused }) => ($isFocused ? '--purple' : '--gray38')});
  transition: 0.3s ease-out;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-gap: 1px;

  &:hover {
    border: 1px solid
      var(${({ $isFocused }) => ($isFocused ? '--purple' : '--gray22')});
    ${GridDelimiter} {
      background: var(--gray22);
    }
  }
`;

const TitleSuggestions = styled.p`
  height: ${getSize(49)};
  font-weight: 600;
  padding: ${getSize(14)} ${getSize(21)} ${getSize(11)};
  font-size: ${getSize(14)};
  text-align: left;
  line-height: ${getSize(24)};
  color: var(--black3);
  border-bottom: 1px solid var(--gray33);
`;

const TimeContainer = styled.div`
  height: calc(100% - ${getSize(49)});
  overflow: auto;
`;

const OptionsWrapper = styled.div`
  padding: ${getSize(17)};
  display: flex;
  flex-direction: column;
  gap: ${getSize(11)};
`;

const Suggestion = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: ${getSize(2)};
  background: white;
  width: ${getSize(296)};
  height: ${getSize(303)};
  border-radius: ${getSize(10)};
  cursor: auto;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
`;

const StyledIcon = styled(ClockCutIcon)`
  margin: 0 ${getSize(12)};
`;

const StyledWeekDay = styled(Weekday)`
  padding: 0;
  height: ${getSize(35)};
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--black3);

  &[title='Saturday'] {
    color: var(--gray2);
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default RescheduleModal;
