import React from 'react';

import { getSize } from 'lib/utils';

import { DashboardContainer, MaterialsSection } from 'features/common';
import { Material } from '../materials-section/components/material-item/material-item';
import { TherapistTitle } from './components';
import { MaterialTag } from '../../../__generated__/types';
import { useGetMaterialIndicators } from 'common/query/__generated__/get-material-indicators';
import { DashboardLayout } from 'features/patient/dashboard/layout';
import styled from 'styled-components';

export interface MaterialsScreenProps {
  materials: Material[];
  isTherapist?: boolean;
  onUploadClick?: () => void;
  backUrl?: string;
  activeTab: MaterialTag;
  setActiveTab: React.Dispatch<React.SetStateAction<MaterialTag>>;
  loading: boolean;
  onDeleteClick?: (materialId: string) => void;
  isFetchMoreLoading: boolean;
  patientId?: string;
  fetchMaterials?: () => void;
}

const TITLE_CSS = { padding: `0 ${getSize(32)}` };

function MaterialsScreen({
  materials,
  isTherapist,
  onUploadClick,
  activeTab,
  setActiveTab,
  loading,
  onDeleteClick,
  isFetchMoreLoading,
  patientId,
  fetchMaterials,
}: MaterialsScreenProps) {
  const { data: materialIndicators } = useGetMaterialIndicators({
    skip: !patientId,
    variables: {
      filter: { patientId: patientId as string },
    },
  });

  if (isTherapist) {
    return (
      <DashboardContainer
        isTherapist={isTherapist}
        title={isTherapist ? 'Materials' : 'My materials'}
        titleCSS={isTherapist ? undefined : TITLE_CSS}
        renderTitle={
          isTherapist ? (
            <TherapistTitle onUploadClick={onUploadClick} />
          ) : undefined
        }
      >
        <MaterialsSection
          materials={materials}
          isTherapist={isTherapist}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          loading={loading}
          onDeleteClick={onDeleteClick}
          isFetchMoreLoading={isFetchMoreLoading}
          materialIndicators={materialIndicators?.getMaterialIndicators}
        />
      </DashboardContainer>
    );
  }

  return (
    <DashboardLayout>
      <Title>My materials</Title>
      <MaterialsSection
        materials={materials}
        isTherapist={isTherapist}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        loading={loading}
        onDeleteClick={onDeleteClick}
        isFetchMoreLoading={isFetchMoreLoading}
        materialIndicators={materialIndicators?.getMaterialIndicators}
        fetchMaterials={fetchMaterials}
        fileNameLimit={60}
      />
    </DashboardLayout>
  );
}

const Title = styled.h1`
  font-family: Poppins;
  color: var(--black3);
  font-size: ${getSize(22)};
  font-weight: 600;
  line-height: ${getSize(33)};
  margin-bottom: 22px;
`;

export default MaterialsScreen;
