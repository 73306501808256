import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AUTH_ROUTES, ROOT_ROUTES } from 'lib/routes';
import { AuthLayout } from 'layouts/auth';
import { Callback, SignIn } from 'features/auth';
import useTeamsRedirect from '../../hooks/use-teams-redirect';

const { SIGN_IN, CALLBACK } = AUTH_ROUTES;

const TeamsAuthRoutes = () => {
  useTeamsRedirect();

  return (
    <AuthLayout>
      <Switch>
        <Route path={SIGN_IN} component={SignIn} />
        <Route path={CALLBACK} component={Callback} />
        <Route path="*">
          <Redirect to={SIGN_IN} />
        </Route>
      </Switch>
    </AuthLayout>
  );
};

export default TeamsAuthRoutes;
