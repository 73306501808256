import { SORT_TYPE } from 'interfaces/common';

export const sortedByType = (
  firstString: string | number,
  secondString: string | number,
  sortType: SORT_TYPE,
) => {
  const { ASC, DESC } = SORT_TYPE;

  if (sortType === ASC) {
    if (firstString < secondString) {
      return -1;
    }
    if (firstString > secondString) {
      return 1;
    }
    return 0;
  } else if (sortType === DESC) {
    if (firstString < secondString) {
      return 1;
    }
    if (firstString > secondString) {
      return -1;
    }
    return 0;
  } else {
    return 0;
  }
};
