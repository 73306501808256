import { REGISTRATION_TYPE } from 'features/auth/check-email/check-email';
import { AUTH_ROUTES } from 'lib/routes';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'ui/button';
import { logout } from 'lib/auth';

const { SIGN_IN, SIGN_UP, CHECK_EMAIL, WELCOME } = AUTH_ROUTES;
const checkEmailArray = CHECK_EMAIL.PATH.split('/');
const signUpArray = SIGN_UP.PATH.split('/');

const signUpRouteName = signUpArray.slice(0, signUpArray.length - 1).join('/');
const checkEmailRouteName = checkEmailArray
  .slice(0, checkEmailArray.length - 1)
  .join('/');
const ROUTES_WITH_SIGN_IN_BUTTON = [
  WELCOME,
  signUpRouteName,
  checkEmailRouteName,
];

const AuthNavigation = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const isRegistrationEmail = useMemo(
    () => pathname.split('/').includes(REGISTRATION_TYPE),
    [pathname],
  );

  const isRouteWithSignInButton = useMemo(
    () =>
      ROUTES_WITH_SIGN_IN_BUTTON.some((route) => pathname.startsWith(route)),
    [pathname],
  );

  if (
    (!isRegistrationEmail && pathname.startsWith(checkEmailRouteName)) ||
    pathname.startsWith(SIGN_IN)
  ) {
    return (
      <>
        <SignUpButton
          theme="outline"
          withIcon
          onClick={() => {
            history.push(AUTH_ROUTES.WELCOME);
          }}
        >
          Sign up as a therapist
        </SignUpButton>
      </>
    );
  } else if (isRouteWithSignInButton) {
    return (
      <Button theme="outline" withIcon onClick={() => logout()}>
        Login
      </Button>
    );
  } else {
    return null;
  }
};

const SignUpButton = styled(Button)`
  text-transform: none;
`;

export default AuthNavigation;
