import { UserModel } from '__generated__/types';

interface DataAttributes {
  email?: string;
  user_id?: string;
  user_hash?: string;
  name?: string;
  company?: {
    company_id: string;
  };
  verified?: boolean;
  onboarded?: boolean;
  therapist?: boolean;
}

const global = window as typeof window & {
  Intercom: (method: string, params?: unknown) => void;
};

const toBooleanOrUndefined = (value?: unknown) => {
  return value !== undefined ? Boolean(value) : undefined;
};

const toConcatOrUndefined = (...values: string[]) => {
  const str = values.filter((n) => Boolean(n)).join(' ');
  return str.length ? str : undefined;
};

export function boot(attr: DataAttributes) {
  global.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    ...attr,
  });
}

export function update(attr?: DataAttributes) {
  global.Intercom('update', attr);
}

export function shutdown() {
  global.Intercom('shutdown');
}

export function userFromModel(user: UserModel): DataAttributes {
  const company = user.companyId ? { company_id: user.companyId } : undefined;
  const name = toConcatOrUndefined(user.firstName, user.lastName);
  const verified = toBooleanOrUndefined(user.verified);
  const onboarded = toBooleanOrUndefined(user.onboarded);
  const therapist = toBooleanOrUndefined(user.therapist);

  return {
    user_id: user.id,
    email: user.email,
    name,
    company,
    verified,
    onboarded,
    therapist,
  };
}
