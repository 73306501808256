import { DEFAULT_AVATAR } from 'lib/constants/therapist';
import { getSize } from 'lib/utils';
import React, { FC } from 'react';
import styled, { CSSProp } from 'styled-components';

interface UserAvatarProps {
  onClick?: () => void;
  avatarUrl?: string | null;
  insuranceIconUrl?: string | null;
  width?: number;
  height?: number;
  rootCSS?: CSSProp;
  logoWrapperCSS?: CSSProp;
}

const UserAvatar: FC<UserAvatarProps> = ({
  onClick,
  avatarUrl,
  insuranceIconUrl,
  width = 34,
  height = 34,
  logoWrapperCSS,
  rootCSS,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      $isClickable={Boolean(onClick)}
      $width={width}
      $height={height}
      $CSS={rootCSS}
    >
      <MainImage src={avatarUrl || DEFAULT_AVATAR} />
      {insuranceIconUrl && (
        <InsuranceLogoWrapper $CSS={logoWrapperCSS}>
          <MainImage src={insuranceIconUrl} />
        </InsuranceLogoWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  $isClickable?: boolean;
  $width: number;
  $height: number;
  $CSS?: CSSProp;
}>`
  position: relative;
  width: ${({ $width }) => getSize($width)};
  height: ${({ $height }) => getSize($height)};
  border-radius: 100%;
  flex-shrink: 0;

  ${({ $isClickable }) => ($isClickable ? 'cursor: pointer;' : '')}

  ${({ $CSS }) => $CSS}
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
`;

const InsuranceLogoWrapper = styled.div<{ $CSS?: CSSProp }>`
  position: absolute;
  width: ${getSize(20)};
  width: ${getSize(20)};
  background-color: var(--white);
  border: 0.5px solid var(--purple3);
  border-radius: 100%;
  bottom: -25%;
  right: -25%;
  transform: translate(-25%, -25%);

  ${({ $CSS }) => $CSS}
`;

export default UserAvatar;
