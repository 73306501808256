import React from 'react';

function LiveChatIconWhite({ ...rest }) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.500273 8.50002C0.500273 8.50001 0.500273 8.5 0.500273 8.49999C0.500273 3.80557 4.30585 0 9.00026 0C13.6947 0 17.5002 3.80557 17.5002 8.49999C17.5002 13.1907 13.7006 16.994 9.01131 17V17H0.500008V8.50002H0.500273Z"
        fill="white"
      />
      <path
        d="M5 6.5C5 5.67157 5.55405 5 6.2375 5H12.7625C13.446 5 14 5.67157 14 6.5C14 7.32843 13.446 8 12.7625 8H6.2375C5.55405 8 5 7.32843 5 6.5Z"
        fill="#7F63F3"
      />
      <path
        d="M5 10.5C5 9.67157 5.58763 9 6.3125 9H10.6875C11.4124 9 12 9.67157 12 10.5C12 11.3284 11.4124 12 10.6875 12H6.3125C5.58763 12 5 11.3284 5 10.5Z"
        fill="#7F63F3"
      />
    </svg>
  );
}

export default LiveChatIconWhite;
