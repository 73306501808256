import StorageService from './storage-service';
import { config } from './config';
import { slashAgnostic } from './string';

class GenerateDownloadLinkService {
  apiUrl = config.API_URL || '';

  getOneTherapistNote = (noteId: string): string => {
    const token = StorageService.getAccessToken();

    return slashAgnostic(
      this.apiUrl,
      `notes/${noteId}/therapist?token=${token}`,
    );
  };

  getTherapistNotes = (patientId: string, sessionMetaId?: string): string => {
    const token = StorageService.getAccessToken();

    return slashAgnostic(
      this.apiUrl,
      `notes/many/therapist/${patientId}?token=${token}${
        sessionMetaId ? `&sessionMetaId=${sessionMetaId}` : ''
      }`,
    );
  };

  getOnePatientNote = (noteId: string): string => {
    const token = StorageService.getAccessToken();

    return slashAgnostic(this.apiUrl, `notes/${noteId}/patient?token=${token}`);
  };

  getPatientNotes = ({
    patientId,
    sessionMetaId,
  }: {
    patientId: string;
    sessionMetaId?: string;
  }): string => {
    const token = StorageService.getAccessToken();

    return slashAgnostic(
      this.apiUrl,
      `notes/many/patient/${patientId}?token=${token}${
        sessionMetaId ? `&sessionMetaId=${sessionMetaId}` : ''
      }`,
    );
  };
}

export default new GenerateDownloadLinkService();
