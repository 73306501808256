import React, { useCallback, useState, ClipboardEvent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import { getSize } from 'lib/utils';
import { Tooltip } from 'ui/tooltip';
import { BaseStyleProps } from 'types';

export type InputProps = {
  label?: string;
  rootCSS?: CSSProp;
  labelCSS?: CSSProp;
  inputCSS?: CSSProp;
  lineCSS?: CSSProp;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  hasErrorText?: boolean;
  isRemoveSpacesAfterPaste?: boolean;
  tooltip?: string;
  tooltipCSS?: CSSProp;
  tooltipWrapperCSS?: CSSProp;
  hasLine?: boolean;
} & FieldRenderProps<string> &
  BaseStyleProps;

function Input({
  input,
  meta,
  label,
  rootCSS,
  labelCSS,
  inputCSS,
  lineCSS,
  placeholder,
  disabled,
  maxLength,
  hasErrorText = true,
  isRemoveSpacesAfterPaste = true,
  tooltip,
  tooltipCSS,
  hasLine = true,
  tooltipWrapperCSS,
  className,
}: InputProps) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError =
    ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  const handlePaste = useCallback(
    (e: ClipboardEvent<HTMLInputElement>) => {
      const copiedText = e.clipboardData.getData('text').trim();

      e.preventDefault();
      input.onChange(copiedText);
    },
    [input],
  );

  return (
    <Wrapper as={label ? 'label' : 'div'} $CSS={rootCSS} className={className}>
      {label && (
        <LabelWrapper>
          <Text $CSS={labelCSS} $hasTooltip={Boolean(tooltip)}>
            {label}
          </Text>
          {tooltip && (
            <Tooltip
              textCSS={tooltipCSS}
              tooltipWrapperCSS={tooltipWrapperCSS}
              text={tooltip}
            />
          )}
        </LabelWrapper>
      )}

      <InputStylized
        $CSS={inputCSS}
        placeholder={placeholder}
        {...input}
        disabled={disabled}
        maxLength={maxLength}
        onPaste={isRemoveSpacesAfterPaste ? handlePaste : undefined}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
      />

      {hasLine && (
        <Line
          $CSS={lineCSS}
          hasError={hasError}
          isInputFocused={isInputFocused}
        />
      )}

      {hasErrorText && hasError && (
        <ErrorText>{error || submitError || data?.error}</ErrorText>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.label<{ $CSS?: CSSProp }>`
  display: block;

  ${({ $CSS }) => $CSS}
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span<{ $CSS?: CSSProp; $hasTooltip?: boolean }>`
  display: block;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--gray7);

  ${({ $hasTooltip }) => ($hasTooltip ? `margin-right: ${getSize(10)};` : '')}

  ${({ $CSS }) => $CSS}
`;

const InputStylized = styled.input<{ $CSS?: CSSProp }>`
  width: 100%;
  padding: ${getSize(10)} 0;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--black3);
  border: 0;

  &::placeholder {
    color: var(--gray7);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 ${getSize(30)} var(--white) inset !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }
  ${({ $CSS }) => $CSS}
`;

const Line = styled.div<{
  isInputFocused: boolean;
  hasError: boolean;
  $CSS?: CSSProp;
}>`
  width: 100%;
  height: ${getSize(1)};
  border-radius: ${getSize(8)};
  background: var(
    ${({ isInputFocused, hasError }) => {
      if (hasError) {
        return '--red';
      } else if (isInputFocused) {
        return '--purple19';
      } else {
        return '--purple3';
      }
    }}
  );
  transition: 0.3s ease-out;

  ${({ $CSS }) => $CSS}
`;

const ErrorText = styled.span`
  margin: ${getSize(2)} 0 0;
  font-weight: 400;
  font-size: ${getSize(10)};
  line-height: ${getSize(16)};
  color: var(--red);
`;

export default Input;
