import { getSize, TimeUtil } from 'lib/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ArrowRightShortIcon } from 'ui/icons';
import {
  Button,
  ClockCutIcon,
  VideoChatIcon,
  LiveChatIconWhite,
  InPersonIconWhite,
} from 'ui';
import {
  SessionFragment,
  SessionRequestPreviewFragment,
} from 'features/patient/dashboard/query/__generated__/get-patient-upcoming-sessions';
import { ISO_DATE_FORMAT, DATE_MONTH } from 'lib/constants/date';
import { SESSION_TYPE_TITLES } from 'lib/constants/therapist';
import { useIsDisabledSessionControlButtons } from 'hooks';
import { SessionType } from '__generated__/types';
import {
  RescheduleModal,
  SessionDetailsModal,
} from 'features/patient/dashboard/sessions/components';
import { THERAPIST_ROUTES } from 'lib/routes';
import { useHistory } from 'react-router-dom';
import { AmplitudeEvents, amplitudeService } from 'lib/amplitude';

export interface SessionItemProps {
  isTherapist?: boolean;
  isSmall?: boolean;
  isPast?: boolean;
  session: SessionFragment | SessionRequestPreviewFragment;
  className?: string;
  onCancelClick?: () => void;
  onStartClick?: () => void;
  onCancelRequest?: () => void;
  onOpenRescheduleModal?: () => void;
  onCloseRescheduleModal?: () => void;
  rescheduleModalVisible?: boolean;
}

function SessionItem({
  isTherapist,
  isPast,
  isSmall,
  className,
  onCancelClick,
  onStartClick,
  onCancelRequest,
  session,
  onOpenRescheduleModal,
  onCloseRescheduleModal,
  rescheduleModalVisible,
}: SessionItemProps) {
  const {
    startDateUtc,
    endDateUtc,
    sessionType,
    id: sessionId,
    therapistPublic: { title, fullName },
  } = session;

  const history = useHistory();

  const isRequest = session.__typename === 'SessionRequestModel';

  const { isDisabledStartButton } = useIsDisabledSessionControlButtons({
    startDateUtc,
    endDateUtc,
  });

  const date = useMemo(
    () => TimeUtil.parse(startDateUtc, ISO_DATE_FORMAT).format(DATE_MONTH),
    [startDateUtc],
  );

  const startTime = useMemo(
    () => TimeUtil.parse(startDateUtc, ISO_DATE_FORMAT).format('HH:mm A'),
    [startDateUtc],
  );

  const endTime = useMemo(
    () => TimeUtil.parse(endDateUtc, ISO_DATE_FORMAT).format('HH:mm A'),
    [endDateUtc],
  );

  const durationInMinutes = useMemo(
    () => TimeUtil.getDiffOfMinutes(startDateUtc, endDateUtc),
    [startDateUtc, endDateUtc],
  );

  const handleSessionRescheduleClick = () => {
    if (!isTherapist) {
      return closeSessionDetailModalAndOpenReschedule();
    }

    history.push(
      THERAPIST_ROUTES.RESCHEDULE_SESSION.pathBuild(
        session.id,
        btoa(session.startDateUtc),
      ),
    );
  };

  // Check if session is 48h from start
  const now = new Date();
  const nowString = now.toUTCString();
  const isSessionWithin48h =
    TimeUtil.getDiffOfHours(nowString, startDateUtc) < 48;

  const patient =
    session.__typename === 'SessionModel'
      ? session.patientConnected
      : session.patientPotential;

  const therapist = session.therapistPublic;

  const patientName =
    session.__typename === 'SessionModel'
      ? `${patient.firstName} ${patient.lastName}`
      : '';

  const userName = isTherapist ? patientName : `${title} ${fullName}`;
  const avatar = isTherapist
    ? patient.avatar?.source
    : session.therapistPublic.avatarUrl;
  const profession = isTherapist
    ? ''
    : `${therapist.professionalTitle} • ${therapist.coreTraining.label}`;

  const [isModalShow, setModalState] = React.useState(false);

  const handleCancelRequest = () => {
    setModalState(false);
    if (isRequest) {
      onCancelRequest?.();
    } else {
      onCancelClick?.();
    }
  };

  const onClickDetailButton = () => {
    setModalState(true);
  };

  const closeSessionDetailModalAndOpenReschedule = () => {
    setModalState(false);
    onOpenRescheduleModal?.();
  };

  const onStartSession = () => {
    amplitudeService.logEvent(AmplitudeEvents.SESSION_START);
    onStartClick?.();
  };

  return (
    <Wrapper className={className} $isSmall={isSmall}>
      <AvatarWrapper>
        <Avatar src={avatar} />
        <Badge>
          {sessionType === SessionType.VideoCall && <VideoBadge />}
          {sessionType === SessionType.InPerson && <InPersonBadge />}
          {sessionType === SessionType.LiveChat && <LiveCharBadge />}
        </Badge>
      </AvatarWrapper>
      <SessionInfoBlock>
        <FullName>{userName}</FullName>
        <DurationInfo>
          <StyledClockIcon />
          <DurationText>
            {date} {startTime} - {endTime}
          </DurationText>
        </DurationInfo>
      </SessionInfoBlock>
      <ButtonWrapper>
        <DetailsButton onClick={onClickDetailButton} theme="outline">
          Session Details
        </DetailsButton>
        {!isPast && (
          <StartNowButton
            onClick={onStartSession}
            theme="buble-secondary"
            isDisabled={isDisabledStartButton || isRequest}
          >
            Start Now
            <StyledArrow />
          </StartNowButton>
        )}
      </ButtonWrapper>
      <SessionDetailsModal
        profession={profession}
        avatarUrl={avatar}
        sessionType={SESSION_TYPE_TITLES[sessionType]}
        therapistName={userName}
        therapistId={session.therapistPublic.id}
        when={date + ' ' + startTime}
        isSessionWithin48h={isSessionWithin48h}
        duration={durationInMinutes}
        isVisible={isModalShow}
        onClose={() => setModalState(false)}
        isPast={isPast}
        isRequest={isRequest}
        onCancelRequest={handleCancelRequest}
        onOpenRescheduleModal={handleSessionRescheduleClick}
        isDisableCancelButton={false}
      />
      <RescheduleModal
        isVisible={rescheduleModalVisible}
        onClose={onCloseRescheduleModal}
        onRescheduleClick={() => {}}
        sessionId={sessionId}
        startSessionTime={startDateUtc}
        sessionType={sessionType}
        therapistId={session.therapistPublic.id}
        duration={durationInMinutes}
      />
    </Wrapper>
  );
}

const Wrapper = styled.li<{
  $isSmall?: boolean;
  $isRequest?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: ${getSize(16)};
  padding: 0 30px 0 24px;
  border: ${getSize(1)} solid rgba(0, 0, 0, 0.03);
  border-radius: ${getSize(7)};
  height: 136px;
  background: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);

  &:last-child {
    margin-bottom: 0;
  }

  ${({ $isSmall }) =>
    $isSmall
      ? `
      min-height: ${getSize(128)};
    `
      : ''};
`;

const AvatarWrapper = styled.div`
  width: ${getSize(84)};
  height: ${getSize(84)};
  border-radius: 50%;
  margin-right: 21.5px;
  flex-shrink: 0;
  position: relative;
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const Badge = styled.div`
  width: 30px;
  height: 30px;
  background: linear-gradient(180deg, #6b4ee6 0%, #947aff 100%);
  border-radius: 25px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullName = styled.p`
  font-family: Poppins;
  font-size: ${getSize(20)};
  font-weight: 500;
  line-height: ${getSize(21)};
  color: black;
`;

const SessionInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DurationInfo = styled.div`
  display: flex;
  align-items: center;
`;

const DurationText = styled.span`
  font-family: Poppins;
  font-size: ${getSize(13)};
  font-weight: 500;
  line-height: ${getSize(24)};
  color: #6f7276;
  margin-right: 12px;
`;

const StyledClockIcon = styled(ClockCutIcon)`
  margin-right: 9px;
`;

const VideoBadge = styled(VideoChatIcon)`
  width: 15px;
  height: 11px;
  transform: scale(2);
  & path:last-child {
    fill: white;
  }
`;

const InPersonBadge = styled(InPersonIconWhite)`
  width: 14px;
  height: 16px;
`;

const LiveCharBadge = styled(LiveChatIconWhite)`
  width: 17px;
  height: 17px;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const StartNowButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 119px;
  height: 36px;
  border-radius: 34px;
  font-weight: 600;
  margin-left: 12px;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  padding: 0 21px 0 0;
  border-color: transparent;
  background: var(--purple);
  color: white;
  &:hover:not([disabled]) {
    color: white;
    background: #8467ff;
    border-color: #8467ff;
  }

  &:disabled {
    background: #ededed;
    color: white;
    border-color: white;
    box-shadow: unset;
  }
`;

const DetailsButton = styled(Button)`
  border-color: var(--purple3);
  width: 130px;
  height: 36px;
  padding: 0;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  min-height: auto;
`;

const StyledArrow = styled(ArrowRightShortIcon)`
  margin-left: 5px;
  & path {
    fill: white;
  }
`;

export default SessionItem;
