import { FetchResult } from '@apollo/client';
import { useCallback } from 'react';

import { CancelTherapistAllFutureSession } from 'common/mutation/__generated__/cancel-therapist-all-future-session';
import { CancelTherapistSession } from 'common/mutation/__generated__/cancel-therapist-session';
import { useCancelTherapistSession } from 'common/mutation/__generated__/cancel-therapist-session';
import { useCancelTherapistAllFutureSession } from 'common/mutation/__generated__/cancel-therapist-all-future-session';
import { getSessionsAndPaymentsCacheEvictFunc } from 'lib/apollo-client/utils/evict-cache-helper';
import { showFetchErrorToaster } from 'lib/apollo-client/utils/apollo-helper';
import { AmplitudeEvents, amplitudeService } from 'lib/amplitude';

export interface UseCancelSessionArgs {
  startDataLoading: () => void;
  stopDataLoading: () => void;
  onSuccess: () => void;
}

interface HandleCancelSessionClickArgs {
  sessionId: string;
  startDateUtc: string;
  reason: string;
  hasAllFuture?: boolean;
}

function useCancelSession({
  startDataLoading,
  stopDataLoading,
  onSuccess,
}: UseCancelSessionArgs) {
  const [cancelSession] = useCancelTherapistSession({
    onError: showFetchErrorToaster,
    update: getSessionsAndPaymentsCacheEvictFunc<CancelTherapistSession>(),
  });
  const [cancelAllFutureSession] = useCancelTherapistAllFutureSession({
    onError: showFetchErrorToaster,
    update:
      getSessionsAndPaymentsCacheEvictFunc<CancelTherapistAllFutureSession>(),
  });

  const handleCancelSessionClick = useCallback(
    ({
      sessionId,
      startDateUtc,
      reason,
      hasAllFuture,
    }: HandleCancelSessionClickArgs) => {
      startDataLoading();

      const requestPromise: Promise<
        FetchResult<
          CancelTherapistSession | CancelTherapistAllFutureSession,
          Record<string, unknown>,
          Record<string, unknown>
        >
      > = hasAllFuture
        ? cancelAllFutureSession({
            variables: {
              input: { id: sessionId, startFromDateIso: startDateUtc, reason },
            },
          })
        : cancelSession({
            variables: { input: { id: sessionId, startDateUtc, reason } },
          });

      requestPromise
        .then(() => {
          amplitudeService.logEvent(AmplitudeEvents.SESSION_CANCELED, {
            by: 'therapist',
            type: hasAllFuture ? 'future' : 'single',
          });
          return onSuccess();
        })
        .finally(stopDataLoading);
    },
    [
      cancelSession,
      onSuccess,
      startDataLoading,
      stopDataLoading,
      cancelAllFutureSession,
    ],
  );

  return { cancelSession: handleCancelSessionClick };
}

export default useCancelSession;
