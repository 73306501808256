import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsuranceCompaniesVariables = Types.Exact<{ [key: string]: never; }>;


export type InsuranceCompanies = (
  { __typename: 'Query' }
  & { insuranceCompanies: Array<(
    { __typename: 'InsuranceCompanyModel' }
    & InsuranceCompanyFragment
  )> }
);

export type InsuranceCompanyFragment = (
  { __typename: 'InsuranceCompanyModel' }
  & Pick<Types.InsuranceCompanyModel, 'id' | 'label' | 'logoUrl' | 'logoRoundUrl' | 'explanation'>
);

export const InsuranceCompanyFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"InsuranceCompanyFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InsuranceCompanyModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"logoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"logoRoundUrl"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}}]} as unknown as DocumentNode;
export const InsuranceCompaniesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"InsuranceCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insuranceCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InsuranceCompanyFragment"}}]}}]}},...InsuranceCompanyFragment.definitions]} as unknown as DocumentNode;

/**
 * __useInsuranceCompanies__
 *
 * To run a query within a React component, call `useInsuranceCompanies` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceCompanies` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceCompanies({
 *   variables: {
 *   },
 * });
 */
export function useInsuranceCompanies(baseOptions?: Apollo.QueryHookOptions<InsuranceCompanies, InsuranceCompaniesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsuranceCompanies, InsuranceCompaniesVariables>(InsuranceCompaniesDocument, options);
      }
export function useInsuranceCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsuranceCompanies, InsuranceCompaniesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsuranceCompanies, InsuranceCompaniesVariables>(InsuranceCompaniesDocument, options);
        }
export type InsuranceCompaniesHookResult = ReturnType<typeof useInsuranceCompanies>;
export type InsuranceCompaniesLazyQueryHookResult = ReturnType<typeof useInsuranceCompaniesLazyQuery>;
export type InsuranceCompaniesQueryResult = Apollo.QueryResult<InsuranceCompanies, InsuranceCompaniesVariables>;