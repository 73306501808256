import React, { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { getSize } from 'lib/utils';

export interface LinkProps {
  className?: string;
  children: ReactNode;
  to: string | { pathname: string; state: { [key: string]: any } };
  onClick?: () => void;
}

function Link({ className, children, to, onClick }: LinkProps) {
  return (
    <LinkStylized className={className} to={to} onClick={onClick}>
      {children}
    </LinkStylized>
  );
}

const LinkStylized = styled(RouterLink)`
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(24)};
  color: var(--purple);
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.7;
  }
`;

export default Link;
