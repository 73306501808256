interface Option {
  label: string;
  value: string;
}

export const getCurrentDayTimes = (
  timeOfAvailabilityInMonth: Option[][],
  selectedDay: Date,
) => {
  const timeOfAvailabilityInDay =
    timeOfAvailabilityInMonth[selectedDay.getDate() - 1];

  return timeOfAvailabilityInDay
    ? [...timeOfAvailabilityInDay]
        .reduce<Option[]>((acc, curr) => {
          if (!acc.find((option) => option.value === curr.value)) {
            acc.push(curr);
          }
          return acc;
        }, [])
        .sort((a: Option, b: Option) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        })
    : [];
};
