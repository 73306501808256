import React, { useContext } from 'react';
import styled from 'styled-components';

import { getSize } from 'lib/utils';
import { TimeUtil } from 'lib/utils';

import ContainerWithoutCssReset from './container-without-css-reset';
import { ReportAbusePopup } from '../../report-abuse-popup';
import { TeamsAppContext } from '../../../../../hooks/use-teams-app';

/**
 * @description Component will render text html content.
 * Since new line is added like /n we will replace it with <br/> tags.
 */
const TextContent = (props: any) => {
  const { id, title, postText, createdAt } = props;

  const dateOfCreation = new Date(parseInt(createdAt));
  const parsedPostText = postText.replace(/\n/g, '<br />');
  const isTeamsApp = useContext(TeamsAppContext);

  return (
    <Container>
      <Title>{title}</Title>
      <RowContainer>
        <CreatedAt>
          {TimeUtil.getFormatted(dateOfCreation, 'Do MMMM YYYY')}
        </CreatedAt>
        <Dot />
        <Dot />
        <Dot />
        <Dot />
      </RowContainer>

      {/* HTML Content */}
      <ContainerWithoutCssReset
        dangerouslySetInnerHTML={{
          __html: postText,
        }}
      ></ContainerWithoutCssReset>
      {isTeamsApp && <ReportAbusePopup postId={id} />}
    </Container>
  );
};

const Container = styled.div`
  padding-top: ${getSize(30)};
  width: ${getSize(900)};
  max-width: ${getSize(1200)};

  @media (max-width: 600px) {
    width: 100%;
    padding: 10px;
  }

  img {
    @media (max-width: 600px) {
      height: unset !important;
      width: 100% !important;
    }
  }
`;

const Title = styled.p`
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 20px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`;

const CreatedAt = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-right: 15px;
`;

const Dot = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background: var(--gray27);
  margin-right: 3px;
  margin-left: 3px;
`;

export default TextContent;
