import { getSize } from 'lib/utils';
import React, { useRef } from 'react';
import styled, { CSSProp } from 'styled-components';
import { DownloadLink, ThreeDotsIcon } from 'ui';

export interface ControlDropdownProps {
  onDeleteClick?: () => void;
  onTriggerClick: () => void;
  isVisibleMenu: boolean;
  downloadLink: string;
  buttonCSS?: CSSProp;
  setIsVisible: React.Dispatch<React.SetStateAction<string>>;
}

function ControlDropdown({
  onDeleteClick,
  isVisibleMenu,
  onTriggerClick,
  downloadLink,
  setIsVisible,
  buttonCSS,
}: ControlDropdownProps) {
  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={rootRef} onClick={(e) => e.stopPropagation()}>
      <DotsButton onClick={onTriggerClick} $buttonCSS={buttonCSS}>
        <ThreeDotsIcon />
      </DotsButton>

      {isVisibleMenu && (
        <List>
          <Download
            href={downloadLink}
            $haveDeleteButton={Boolean(onDeleteClick)}
            onClick={() => setIsVisible('')}
          >
            Download
          </Download>
          {onDeleteClick && (
            <DeleteButton onClick={onDeleteClick}>Delete</DeleteButton>
          )}
        </List>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  margin: 0 0 0 auto;
`;

const List = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  padding: ${getSize(20)};
  background: white;
  box-shadow: 0 ${getSize(20)} ${getSize(90)} rgba(40, 31, 61, 0.06);
  border-radius: ${getSize(8)};
  z-index: 10000;
`;

const Button = styled.button`
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);
`;

const Download = styled(DownloadLink)<{ $haveDeleteButton?: boolean }>`
  display: block;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(18)};
  color: var(--black3);

  ${({ $haveDeleteButton }) =>
    $haveDeleteButton && `margin: 0 0 ${getSize(20)}`};
`;

const DotsButton = styled.button<{ $buttonCSS?: CSSProp }>`
  padding: ${getSize(6)};
  border-radius: ${getSize(8)};
  background-color: var(--purple11);
  ${({ $buttonCSS }) => $buttonCSS};
`;

const DeleteButton = styled(Button)`
  color: var(--red);
`;

export default ControlDropdown;
