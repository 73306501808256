import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientGetSessionMaterialsVariables = Types.Exact<{
  filter: Types.MaterialsFilter;
}>;


export type PatientGetSessionMaterials = (
  { __typename: 'Query' }
  & { patientGetOwnMaterials: (
    { __typename: 'MaterialConnectionModel' }
    & { nodes: Array<(
      { __typename: 'MaterialModel' }
      & MaterialModelFragment
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type MaterialModelFragment = (
  { __typename: 'MaterialModel' }
  & Pick<Types.MaterialModel, 'id' | 'fileType' | 'source' | 'name' | 'sizeInKb' | 'extension' | 'tag' | 'isRead' | 'createdAt' | 'therapistName'>
);

export const MaterialModelFragment = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MaterialModelFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MaterialModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fileType"}},{"kind":"Field","name":{"kind":"Name","value":"source"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"sizeInKb"}},{"kind":"Field","name":{"kind":"Name","value":"extension"}},{"kind":"Field","name":{"kind":"Name","value":"tag"}},{"kind":"Field","name":{"kind":"Name","value":"isRead"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"therapistName"}}]}}]} as unknown as DocumentNode;
export const PatientGetSessionMaterialsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PatientGetSessionMaterials"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MaterialsFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"patientGetOwnMaterials"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MaterialModelFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}}]}}]}},...MaterialModelFragment.definitions]} as unknown as DocumentNode;

/**
 * __usePatientGetSessionMaterials__
 *
 * To run a query within a React component, call `usePatientGetSessionMaterials` and pass it any options that fit your needs.
 * When your component renders, `usePatientGetSessionMaterials` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientGetSessionMaterials({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePatientGetSessionMaterials(baseOptions: Apollo.QueryHookOptions<PatientGetSessionMaterials, PatientGetSessionMaterialsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientGetSessionMaterials, PatientGetSessionMaterialsVariables>(PatientGetSessionMaterialsDocument, options);
      }
export function usePatientGetSessionMaterialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientGetSessionMaterials, PatientGetSessionMaterialsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientGetSessionMaterials, PatientGetSessionMaterialsVariables>(PatientGetSessionMaterialsDocument, options);
        }
export type PatientGetSessionMaterialsHookResult = ReturnType<typeof usePatientGetSessionMaterials>;
export type PatientGetSessionMaterialsLazyQueryHookResult = ReturnType<typeof usePatientGetSessionMaterialsLazyQuery>;
export type PatientGetSessionMaterialsQueryResult = Apollo.QueryResult<PatientGetSessionMaterials, PatientGetSessionMaterialsVariables>;