import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AUTH_ROUTES, PATIENT_ROUTES, ROOT_ROUTES } from 'lib/routes';
import { Loader } from '../ui';
import ScrollToTop from './scroll-to-top';
import { useShareSessionStorage } from 'hooks/use-share-session-storage';

import ResourcesRoutes from './patient/resources';
import PrivateRoute from '../components/private-route';
import { SignIn } from '../features/auth';
import TeamsAuthRoutes from './teams/auth';
import TeamsPatientRoutes from './teams/patient';

const { AUTH, PATIENT } = ROOT_ROUTES;

const TeamsRoutes = () => {
  useShareSessionStorage();

  return (
    <Router>
      <Suspense fallback={<Loader hasFillWholeBlock size={50} />}>
        <ScrollToTop />

        <Switch>
          <Route path={AUTH} component={TeamsAuthRoutes} />
          <Route path={PATIENT} component={TeamsPatientRoutes} />
          <Route path="*" component={TeamsAuthRoutes} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default TeamsRoutes;
